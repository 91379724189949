import { useState, useEffect } from "react";
import Renderer from "../block_rendering/Renderer";
import PropertyId from '../../types/enum/PropertyId';
import { getPropertyObject } from '../../utils/blockHelpers';
import root from 'react-shadow';
import { hexToRgb } from '../../utils/colorHelpers';
import BlockMapping from "../../types/BlockMapping";
import BuilderProps from "../../types/BuilderProps";

function Preview({ page, pageList, PreviewBlockId, blockSelected, isEditPage, cloneBlock, deleteBlock
    , dragDrop, selectedBlocks, hoverBlock, blockHovered, shiftHeld, toggleBlocksDisabled, scrollOffset}: BuilderProps) {
    const [renderData, setRenderData] = useState(() => {
        return { };
    });

    // Tracking a arbitrary value to force a new value into builderProps to cause all block wrappers
    // to re-render when the screen size is changed, so that the disabled block overlays
    // will recalculate position and size properly
    const [resizeDate, setResizeDate] = useState(new Date());

    const resized = () => {
        setResizeDate(new Date());
    }

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('resize', resized);

        return () => window.removeEventListener('resize', resized);
    }, [])

    if (!page) {
        return null;
    }

    const defaultColor = {"hex":"#FFFFFF","hsl":"0 0% 100%"};
    const defaultContent = {"hex":"#000000","hsl":"0 0% 0%"};
    const defaultFocus = {"hex":"#d9d9d9","hsl":"0 0% 85%"};

    const primaryColor = getPropertyObject(page.SiteProperties, PropertyId.PrimaryColor, defaultColor);
    const primaryColorRgb = hexToRgb(primaryColor.hex);
    const primaryFocus = getPropertyObject(page.SiteProperties, PropertyId.PrimaryFocus, defaultFocus);
    const primaryFocusRgb = hexToRgb(primaryFocus.hex);
    const primaryContent = getPropertyObject(page.SiteProperties, PropertyId.PrimaryContent, defaultContent);
    const primaryContentRgb = hexToRgb(primaryContent.hex);
    const secondaryColor = getPropertyObject(page.SiteProperties, PropertyId.SecondaryColor, defaultColor);
    const secondaryColorRgb = hexToRgb(secondaryColor.hex);
    const secondaryFocus = getPropertyObject(page.SiteProperties, PropertyId.SecondaryFocus, defaultFocus);
    const secondaryFocusRgb = hexToRgb(secondaryFocus.hex);
    const secondaryContent = getPropertyObject(page.SiteProperties, PropertyId.SecondaryContent, defaultContent);
    const secondaryContentRgb = hexToRgb(secondaryContent.hex);
    const accentColor = getPropertyObject(page.SiteProperties, PropertyId.AccentColor, defaultColor);
    const accentColorRgb = hexToRgb(accentColor.hex);
    const accentFocus = getPropertyObject(page.SiteProperties, PropertyId.AccentFocus, defaultFocus);
    const accentFocusRgb = hexToRgb(accentFocus.hex);
    const accentContent = getPropertyObject(page.SiteProperties, PropertyId.AccentContent, defaultContent);
    const accentContentRgb = hexToRgb(accentContent.hex);
    const primaryFont = getPropertyObject(page.SiteProperties, PropertyId.PrimaryFont);
    const secondaryFont = getPropertyObject(page.SiteProperties, PropertyId.SecondaryFont);

    const builderProps = {
        PreviewBlockId,
        onBlockHover: (block: BlockMapping, event: Event) => {
            event.stopPropagation();
            if (isEditPage) {
                if (blockHovered) {
                    blockHovered(block);
                }
            }
        },
        blockClicked: (block: BlockMapping, event: Event) => {
            event.stopPropagation();
            if (isEditPage && blockSelected) {
                blockSelected(block);
            }
        },
        dragDrop,
        hoverBlock: hoverBlock,
        selectedBlocks: selectedBlocks,
        cloneBlock: cloneBlock,
        deleteBlock: deleteBlock,
        reviewSummaries: page.ReviewSummaries,
        isEditPage,
        shiftHeld: shiftHeld,
        toggleBlocksDisabled: toggleBlocksDisabled,
        scrollOffset,
        resizeDate,
        pageList,
    };

    if (page && !page.Fonts) {
        page.Fonts = [];
    }

    let googleFontFamilies = "";

    if (primaryFont && secondaryFont) {
        page.Fonts = [];
        if (page.Fonts.filter(f => f.f === primaryFont.f && f.w === primaryFont.w).length === 0) {
            page.Fonts.push(primaryFont);
        }
        if (page.Fonts.filter(f => f.f === secondaryFont.f && f.w === secondaryFont.w).length === 0) {
            page.Fonts.push(secondaryFont);
        }
    }

    if (page?.Fonts) {
        for (let i = 0; i < page.Fonts.length; i++) {
            if (i > 0) {
                googleFontFamilies += "&";
            }

            googleFontFamilies += `family=${encodeURI(page.Fonts[i].f)}:wght@${page.Fonts[i].w}`;
        }
    }

    // NOTE: transform scale(1) is a fix to allow fixed positioning within our shadow dom
    let bodyStyle = {transform:"scale(1)"};

    const siteBackgroundColor = getPropertyObject(page.SiteProperties, PropertyId.SiteBackgroundColor);
    const pageBackgroundColor = getPropertyObject(page.PageProperties, PropertyId.PageBackgroundColor, siteBackgroundColor);
    const siteFontColor = getPropertyObject(page.SiteProperties, PropertyId.SiteFontColor);
    const pageFontColor = getPropertyObject(page.PageProperties, PropertyId.PageFontColor, siteFontColor);
    const pageBackgroundColorRGB = hexToRgb(pageBackgroundColor?.hex);
    const pageFontColorRGB = hexToRgb(pageFontColor?.hex);
    let bodyBackgroundColor = 'white';
    let bodyFontColor = 'black';

    if (pageBackgroundColorRGB.length) {
        bodyBackgroundColor = `rgba(${pageBackgroundColorRGB[0]}, ${pageBackgroundColorRGB[1]}, ${pageBackgroundColorRGB[2]}, ${pageBackgroundColor && pageBackgroundColor.a ? pageBackgroundColor.a : 1})`;
    }
    if (pageFontColorRGB.length) {
        bodyFontColor = `rgba(${pageFontColorRGB[0]}, ${pageFontColorRGB[1]}, ${pageFontColorRGB[2]}, ${pageFontColor && pageFontColor.a ? pageFontColor.a : 1})`;
    }

    return (
        <div id={'shadowRootContainer'} onMouseLeave={() => { if (blockHovered) { blockHovered(null) }}} style={{ overflow: "hidden" }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            {googleFontFamilies &&
                <link href={`https://fonts.googleapis.com/css2?${googleFontFamilies}&display=swap`} rel="stylesheet" />
            }
            <root.div>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined%7CMaterial+Icons+Two+Tone%7CMaterial+Icons+Round%7CMaterial+Icons+Sharp&display=swap" rel="stylesheet" crossOrigin="anonymous" />
                <link rel="stylesheet" href="https://unpkg.com/swiper@8/swiper-bundle.min.css" />

                <link href={page.StyleSheetUrl} rel="stylesheet" crossOrigin="anonymous" />

                <style>
                    {`
                    :host {
                        ${primaryColorRgb.length && `--pc-c: rgba(${primaryColorRgb[0]}, ${primaryColorRgb[1]}, ${primaryColorRgb[2]}, ${primaryColor.a ?? 1});`}
                        ${primaryFocusRgb.length && `--pc-f: rgba(${primaryFocusRgb[0]}, ${primaryFocusRgb[1]}, ${primaryFocusRgb[2]}, ${primaryFocus.a ?? 1});`}
                        ${primaryContentRgb.length && `--pc-t: rgba(${primaryContentRgb[0]}, ${primaryContentRgb[1]}, ${primaryContentRgb[2]}, ${primaryContent.a ?? 1});`}
                        ${secondaryColorRgb.length && `--sc-c: rgba(${secondaryColorRgb[0]}, ${secondaryColorRgb[1]}, ${secondaryColorRgb[2]}, ${secondaryColor.a ?? 1});`}
                        ${secondaryFocusRgb.length && `--sc-f: rgba(${secondaryFocusRgb[0]}, ${secondaryFocusRgb[1]}, ${secondaryFocusRgb[2]}, ${secondaryFocus.a ?? 1});`}
                        ${secondaryContentRgb.length && `--sc-t: rgba(${secondaryContentRgb[0]}, ${secondaryContentRgb[1]}, ${secondaryContentRgb[2]}, ${secondaryContent.a ?? 1});`}
                        ${accentColorRgb.length && `--ac-c: rgba(${accentColorRgb[0]}, ${accentColorRgb[1]}, ${accentColorRgb[2]}, ${accentColor.a ?? 1});`}
                        ${accentFocusRgb.length && `--ac-f: rgba(${accentFocusRgb[0]}, ${accentFocusRgb[1]}, ${accentFocusRgb[2]}, ${accentFocus.a ?? 1});`}
                        ${accentContentRgb.length && `--ac-t: rgba(${accentContentRgb[0]}, ${accentContentRgb[1]}, ${accentContentRgb[2]}, ${accentContent.a ?? 1});`}
                        --body-bg-color:  ` + bodyBackgroundColor + `;
                        --body-color: ` + bodyFontColor + `;

                        --p: ` + primaryColor?.hsl + `;
                        --pf: ` + primaryFocus?.hsl + `;
                        --pc: ` + primaryContent?.hsl + `;
                        --s: ` + secondaryColor?.hsl + `;
                        --sf: ` + secondaryFocus?.hsl + `;
                        --sc: ` + secondaryContent?.hsl + `;
                        --a: ` + accentColor?.hsl + `;
                        --af: ` + accentFocus?.hsl + `;
                        --ac: ` + accentContent?.hsl + `;

                        --n: 38.5% 0.024 262.83;
                        --nf: 30.06% 0.017 266.99;
                        --nc: 100% 0 0;
                        --b1: 100% 0 0;
                        --b2: 98.43% 0.002 247.84;
                        --b3: 87.15% 0.009 258.34;
                        --bc: 27.84% 0.03 256.85;
                        --in: 65.35% 0.171 249.52; 
                        --su: 59.8% 0.107 182.625;
                        --wa: 77.2% 0.174 64.552;
                        --er: 67.96% 0.212 36.29;

                        --fallback-pc-c: var(--pc-c);
                        --fallback-pc-f: var(--pc-f);
                        --fallback-pc-t: var(--pc-t);
                        --fallback-sc-c: var(--sc-c);
                        --fallback-sc-f: var(--sc-f);
                        --fallback-sc-t: var(--sc-t);
                        --fallback-ac-c: var(--ac-c);
                        --fallback-ac-f: var(--ac-f);
                        --fallback-ac-t: var(--ac-t);
                        --fallback-p: hsl(var(--p));
                        --fallback-pf: hsl(var(--pf));
                        --fallback-pc: hsl(var(--pc));
                        --fallback-s: hsl(var(--s));
                        --fallback-sf: hsl(var(--sf));
                        --fallback-sc: hsl(var(--sc));
                        --fallback-a: hsl(var(--a));
                        --fallback-af: hsl(var(--af));
                        --fallback-ac: hsl(var(--ac));
                    }
                    `}
                </style>

                {primaryFont && secondaryFont &&
                    <style>
                        {`
                        :host {
                            --pf-f: ` + primaryFont.f + `;
                            --pf-w: ` + primaryFont.w + `;
                            --sf-f: ` + secondaryFont.f + `;
                            --sf-w: ` + secondaryFont.w + `;
                            --fpf: ` + primaryFont.f + `;
                            --fpw: ` + primaryFont.w + `;
                            --fsf: ` + secondaryFont.f + `;
                            --fsw: ` + secondaryFont.w + `;
                        }
                        `}
                    </style>
                }

                <style>
                    {`
                    .preview-block {
                        border: 4px grey dashed;
                    }
                    .hover-block, .selected-block {
                        cursor: pointer;
                        box-shadow: inset 0px 0px 0px 6px #1b1aff !important;
                    }
                    .card > figure > img.hover-block, img.selected-block {
                        box-shadow: 0px 0px 0px 6px #1b1aff !important;
                    }
                    .hover-block * label, input, textarea {
                        cursor: pointer;
                    }
                    .block-menu {
                        width:100px;
                        height:30px;
                        position:absolute;
                        display:block;
                        background-color:blue;
                        bottom:-30px;
                        left:0;
                    }
                    `}
                </style>
                { isEditPage &&
                    <style>
                        {`
                        .googlemaps-builder-overlay {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }
                        .stickymobile {
                            display: block !important;
                        }
                        .stickymobile {
                            position: inherit;
                        }
                        `}
                    </style>
                }
                <div className="fs body-colors" style={bodyStyle}>
                    <Renderer page={page} builderProps={builderProps} renderData={renderData} setRenderData={setRenderData} />
                </div>
            </root.div>
        </div>
    );
}

export default Preview;
