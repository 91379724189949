const enum BlockId {
    Anchor = 1,
    FontAwesomeIcon = 2,
    PageHeader = 4,
    Div = 9,
    Heading = 18,
    Row = 19,
    Column = 20,
    Form = 21,
    Image = 24,
    MaterialIcon = 26,
    Divider = 33,
    Card = 38,
    Spacer = 40
}

export default BlockId;
