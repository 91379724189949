import SiteEditorStore from './SiteEditorStore';
import Loading from '../common/Loading';

export default function SiteEditorLoading() {
    const loading = SiteEditorStore.useStoreState((state) => state.loading);

    if (!loading) {
      return null;
    }

    return <Loading message="" />;
}