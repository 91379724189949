import { useEffect, useState } from 'react';
import useApiPostCallbackAsync from '../../hooks/useApiPostCallbackAsync';
import Loading from './Loading';
import { getFileNameParts, isImageFile } from '../../utils/fileHelpers';
import ImageEditor from "./ImageEditor"
import FileRecord from '../../types/FileRecord';
import FileType from '../../types/enum/FileType';

const fileTypeMimeTypes = {
    [FileType.Image]: ["image/bmp","image/gif","image/jpeg","image/png","image/svg+xml","image/tiff","image/vnd.microsoft.icon","image/webp"],
    [FileType.Video]: ["video/3gpp","video/3gpp2","video/mp2t","video/mp4","video/mpeg","video/ogg","video/webm","video/x-msvideo"],
    [FileType.Document]: ["application/msword","application/pdf","application/rtf","application/vnd.ms-excel","application/vnd.ms-powerpoint","application/vnd.oasis.opendocument.presentation","application/vnd.oasis.opendocument.spreadsheet","application/vnd.oasis.opendocument.text","application/vnd.openxmlformats-officedocument.presentationml.presentation","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/x-abiword","text/csv","text/plain"],
    [FileType.Archive]: ["application/gzip","application/vnd.rar","application/x-7z-compressed","application/x-bzip","application/x-bzip2","application/x-freearc","application/x-tar","application/zip"],
    [FileType.Audio]: ["audio/aac","audio/midi audio/x-midi","audio/mpeg","audio/ogg","audio/opus","audio/wav","audio/webm"],
    [FileType.Code]: ["text/css","text/html","text/javascript"],
    [FileType.Font]: ["font/otf","font/ttf","font/woff","font/woff2"],
    [FileType.Unknown]: ["*"],
};

export interface FileUploaderProps {
    fileInput: React.RefObject<HTMLInputElement>;
    siteId?: string;
    setError?: React.Dispatch<React.SetStateAction<string | null>>;
    uploadFinished: (uploadedFile: FileRecord) => void;
    acceptFileTypes: FileType[];
    maxFileSizeKb?: number;
}

export default function FileUploader({ fileInput, siteId, setError, uploadFinished, acceptFileTypes, maxFileSizeKb }: FileUploaderProps) {
    // start state and function definitions
    const [uploadStarted, setUploadStarted] = useState(false);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [imageEditorFiles, setImageEditorFiles] = useState<File[]>([]);

    const acceptedFileExtensions = acceptFileTypes.flatMap(fileType => fileTypeMimeTypes[fileType]).join(',');

    const [{data, isLoading, isError}, uploadFiles] = useApiPostCallbackAsync(
        `/site/${siteId}/files`,
        null
    ) as any;

    useEffect(() => {
        // component flow on render
        if (uploadStarted && !isLoading) {
            setUploadStarted(false);
            if (!isError) {
                uploadFinished(data);
                setShowImageEditor(false);
            } else {
                setError?.('Something went wrong while uploading files. Please try again.');
            }
        }
    }, [uploadStarted, isLoading, isError, setError, uploadFinished, data]);

    // file input allows only 1 file to be uploaded at a time by default
    // if a file gets selected, it will come thru as a FileList
    // so we'll need to select the first one each time
    const startUpload = (requests: {
        FileType: string;
        FileName: string;
        FileExtension: string | undefined;
        IsPublic: boolean;
        FileContents: any;
    }[]) => {
        uploadFiles(requests.length > 0 ? requests[0] : null);
        setUploadStarted(true);
    }

    const startUploadingFiles = (files: File[]) => {
        const requests = [];

        for (const file of files) {
            const {name, extension} = getFileNameParts(file.name)
            const request = {
                FileType: file.type,
                FileName: name,
                FileExtension: extension,
                IsPublic: true,
                // start at beginning, skip past data header (5), type length, and encoding details (8) then take the rest
                FileContents: (file as any).encodedData.slice(5 + file.type.length + 8)
            }
            requests.push(request);
        }
        startUpload(requests);
    };

    const processFiles = (files: File[], count = 0) => {
        const filesReady = files.every((f: any) => f.isLoaded);
        if (!filesReady && count < 50) {
            setTimeout(() => { processFiles(files, count + 1)}, 100);
        } else if (filesReady) {
            if (files.some(f => isImageFile(f))) {
                setImageEditorFiles(files);
                setShowImageEditor(true);
            } else {
                startUploadingFiles(files);
            }
        } else {
            setError?.('Something went wrong while preparing file to upload. Please try again.');
        }
    }

    // file input elements by default allow 1 file at a time
    // but will pass in a FileList object
    const uploadFile = (files: File[]) => {
        const allFileTypesValid = acceptFileTypes.includes(FileType.Unknown)
            || files.every(file => acceptedFileExtensions.includes(file.type));

        if (!allFileTypesValid) {
            setError?.("Invalid file type selected.");
            return;
        }

        if (maxFileSizeKb) {
            const maxFileSizeBytes = maxFileSizeKb * 1024;
            if (!files.every(f => f.size < maxFileSizeBytes)) {
                setError?.(`File size must be less than ${(maxFileSizeKb / 1024).toFixed(0)}MB`)
                return;
            }
        }

        var reader = new FileReader();
        for (const file of files) {
            if (file && isImageFile(file)) {
                reader.onload = (event) => {
                    var i = new Image() as any;
                    i.onload = () => {
                        (file as any).originalHeight = i.height;
                        (file as any).originalWidth = i.width;
                        (file as any).isLoaded = true;
                        (file as any).encodedData = reader.result;
                    };
                    i.src = event?.target?.result?.toString() || "";
                }
                reader.readAsDataURL(file);
            } else {
                reader.onload = (_event) => {
                    (file as any).isLoaded = true;
                    (file as any).encodedData = reader.result;
                };
                reader.readAsDataURL(file);
            }
        };

        processFiles(files);
    }

    const editingFinished = (files: File[], _saveAsNewFile?: boolean) => {
        setShowImageEditor(false);
        startUploadingFiles(files);
    };

    const cancelEditing = () => {
        setShowImageEditor(false);
    };



    return (
        <>
            <input
                type={"file"}
                accept={acceptedFileExtensions}
                ref={fileInput}
                style={{display: 'none'}}
                onClick={(e) => (e.target as HTMLInputElement).value = ""}
                onChange={(e) => uploadFile(Array.from((e.target as HTMLInputElement).files || []))}
                data-testid="file-uploader-input"
            />
            { showImageEditor &&
                <ImageEditor files={Array.from(imageEditorFiles || [])} editingFinished={editingFinished} cancelEditing={cancelEditing} />
            }
            {(isLoading || uploadStarted) &&
                <Loading message="Uploading..." />
            }
        </>
    )
}

