import { User } from "@auth0/auth0-react"
import LogData from "../types/LogData";


function log(data: any, level: string) {
    const logData: LogData = {
        Application: (window as any).CONFIG.Logging.Application,
        Version: (window as any).CONFIG.Logging.Version,
        Environment: (window as any).CONFIG.Logging.Environment,
        Level: level,
        CorrelationId: data?.correlationId,
        SessionId: data?.sessionId,
        Host: window.location.host,
        Port: window.location.port,
        Path: window.location.pathname,
        Protocol: window.location.protocol.replace(':', ''),
        Url: window.location.href,
        Message: data?.message,
        Log: data?.log,
        LogSource: data?.logSource,
        Event: "ClientRuntimeError",
        OriginatingUser: null,
        AuthenticatedUser: null,
        Exception: null
    };

    if (data?.user) {
        logData.OriginatingUser = data.user.email;
        logData.AuthenticatedUser = data.user.sub;
    }
    
    if (data?.error && data?.info) {
        logData.Exception = {
            "Msg": data.error,
            "St": data.info.componentStack
        }
    }

    console.log(logData);

    if ((window as any).CONFIG.Logging.LogToApi) {
        fetch(
            `${(window as any).CONFIG.ApiBaseUrl}/logging/v1/logs`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${data?.accessToken}`
                },
                body: JSON.stringify(logData)
            }
        );
    }
}

export interface LogErrorProps {
    error: Error;
    info: React.ErrorInfo;
    user?: User;
    correlationId: string | null;
    sessionId: string | null;
    accessToken: string;
    log: string;
    logSource: string;
}

export function logError(data: LogErrorProps) {
    log(data, "Error");
}