import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import AdminPage from "./components/admin_page/AdminPage";
import SharedContentManager from "./components/shared_content_manager/SharedContentManager";
import CustomBlockEditor from "./components/custom_block_editor/CustomBlockEditor";
import SiteEditor from "./components/site_editor/SiteEditor";
import SiteWizard from "./components/wizard/SiteWizard";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/site/:siteId" element={<SiteEditor/>} />
      <Route path="/site/:siteId/page/:pageId" element={<SiteEditor/>} />
      <Route path="/site/:siteId/wizard" element={<SiteWizard/>} />
      <Route path="/site/:siteId/admin" element={<AdminPage/>} />
      <Route path="/sharedcontentmanager/:section?" element={<SharedContentManager/>} />
      <Route path="/customblock/:id" element={<CustomBlockEditor/>} />
      <Route element={<NotFound/>} />
    </Routes>
  );
}
