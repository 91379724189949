import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Popper from '@mui/material/Popper';
import {Crop32Outlined} from '@mui/icons-material';
import {BrowserNotSupportedOutlined} from '@mui/icons-material';
import BuilderProps from '../../types/BuilderProps';

export interface BlockActionMenuProps {
    blockId: string,
    builderProps: BuilderProps,
    anchorEl: HTMLElement | null,
    blockEnabled: boolean
}

export default function BlockActionMenu({blockId, builderProps, anchorEl, blockEnabled}: BlockActionMenuProps) {
    const doCloneBlock = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (builderProps.cloneBlock) {
            builderProps.cloneBlock(blockId);
        }
    }

    const doDeleteBlock = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (builderProps.deleteBlock) {
            builderProps.deleteBlock(blockId);
        }
    }
    const toggleBlockDisable = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (builderProps.toggleBlocksDisabled) {
            builderProps.toggleBlocksDisabled();
        }
    }

    return (
        <Popper 
            id={blockId}
            open={true}
            anchorEl={anchorEl}
            placement={'bottom-start'}
            onMouseOver={(e: React.SyntheticEvent) => {e.stopPropagation();}}
        >
            <div style={{ backgroundColor:'#1b1aff', minWidth:'70px', height:'30px', textAlign:'center' }}>
                <ContentCopyIcon onClick={doCloneBlock} aria-label="clone" sx={{color:'white', mx:'4px', my:'2px', cursor:'pointer'}} />
                {!blockEnabled && <Crop32Outlined onClick={toggleBlockDisable} aria-label="disable" sx={{color:'white', mx:'4px', my:'2px', cursor:'pointer'}} />}
                {blockEnabled && <BrowserNotSupportedOutlined onClick={toggleBlockDisable} aria-label="enable" sx={{color:'white', mx:'4px', my:'2px', cursor:'pointer'}} />}
                <DeleteIcon onClick={doDeleteBlock} aria-label="delete" sx={{color:'white', mx:'4px', my:'2px', cursor:'pointer'}} />
            </div>
        </Popper>
    )
}
