import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, Chip, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import BuilderDialog from "../../../../common/BuilderDialog";
import useApiPostCallbackAsync from "../../../../../hooks/useApiPostCallbackAsync";
import FormValidationError from "../../../../common/FormValidationError";
import { ResponseError } from "../../../../../utils/apiHelpers";
import Feature from "../../../../../types/Feature";
import ErrorMessage from "../../../../common/ErrorMessage";
import SidebarState from "../../../../../types/enum/SidebarState";
import Type from "../../../../../types/Type";
import Loading from "../../../../common/Loading";
import useApiGetCallbackAsync from "../../../../../hooks/useApiGetCallbackAsync";
import { updateTypeNameAsDisplayNameAndSort } from "../../../../../utils/typeHelpers";
import TemplateTypes from "../../../../../types/enum/TemplateTypes";
import SuccessMessage from "../../../../common/SuccessMessage";
import IndustryType from "../../../../../types/IndustryType";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageIcon from '@mui/icons-material/Image';
import { getSanitizedUrlForBackgroundImage } from "../../../../../utils/blockHelpers";
import { getFileNameParts, isImageFile } from "../../../../../utils/fileHelpers";
import PageInfo from "../../../../../types/PageInfo";
import SiteEditorStore from "../../../SiteEditorStore";
import PageStatus from "../../../../../types/enum/PageStatus";

interface CreateTemplateModalProps {
    showModal: boolean,
    setShowModal: (open: boolean) => void,
    templateType: TemplateTypes,
    siteId?: string,
    pageId?: string
}

const uncategorizedCategoryTypeId = -1;

export default function CreateTemplateModal({
    showModal,
    setShowModal,
    templateType,
    siteId,
    pageId
}: CreateTemplateModalProps){
    const [formSubmitted, setFormSubmitted] = useState(false);

    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const pages = templateType === TemplateTypes.Site 
        ? pageList.filter(p => p.StatusTypeId !== PageStatus.Deleted)
        : []
    const [templateNameValid, setTemplateNameValid] = useState<boolean | null>(null);
    const [templateName, setTemplateName] = useState('');
    const [description, setDescription] = useState('');
    const [newSectionCategory, setNewSectionCategory] = useState(-1);
    const [sectionCategoryValid, setSectionCategoryValid] = useState<boolean | null>(null);
    const [selectedTags, setSelectedTags] = useState<Feature[]>([]);
    const [selectedIndustryIds, setSelectedIndustryIds] = useState<number[]>([]);
    const [industriesValid, setIndustriesValid] = useState<boolean | null>(null);
    const [screenshot, setScreenshot] = useState<any>(null);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [screenshotValid, setScreenshotValid] = useState<boolean | null>(null);
    const [fileTypeValid, setFileTypeValid] = useState<boolean | null>(null);
    const [selectedPageIds, setSelectedPageIds] = useState<string[]>(pages?.map(p => p.Id) ?? []);
    const [pagesValid, setPagesValid] = useState<boolean | null>((pages && pages.length > 0) || null);
    const [submitError, setSubmitError] = useState<ResponseError | null>(null);
    const [showCreateSuccess, setShowCreateSuccess] = useState(false);
    const [orderedCategories, setOrderedCategories] = useState<Type[] | null>(null);
    const updateSidebarState = SiteEditorStore.useStoreActions((actions) => actions.updateSidebarState);
    const refreshBlocksAndSections = SiteEditorStore.useStoreActions((actions) => actions.refreshBlocksAndSections);
    const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);

    const fileInput = useRef<HTMLInputElement>(null);
    
    const [, loadSitePreselectedTagsCallback]
        = useApiGetCallbackAsync("/feature/site/" + siteId, null) as any;
        
    const [{ isLoading: pagePreselectedTagsLoading }, loadPagePreselectedTagsCallback]
        = useApiGetCallbackAsync("/feature/page/" + pageId, null) as any;

    const [{ isLoading: sectionPreselectedTagsLoading }, loadSectionPreselectedTagsCallback]
        = useApiPostCallbackAsync("/feature/blockmappings", null) as any;

    const [{ data: tags, isLoading: tagsLoading, isError: tagsError, errorResponse: tagsApiError }, loadTagsCallback]
        = useApiGetCallbackAsync("/feature", null) as any;

    const [{ isLoading: categoriesLoading, isError: categoriesError, errorResponse: categoriesApiError },
        loadCategoriesCallback] = useApiGetCallbackAsync("/type/200", []) as any;

    const [{ data: industries, isLoading: industriesLoading, isError: industriesError, errorResponse: industriesApiError },
        loadIndustriesCallback] = useApiGetCallbackAsync("/type/100", []) as any;

    const [ , submitNewSiteTemplateCallback]
        = useApiPostCallbackAsync("/template/site", []) as any;
        
    const [ , submitNewPageTemplateCallback]
        = useApiPostCallbackAsync("/template/page", []) as any;
    
    const [ , submitNewSectionCallback]
        = useApiPostCallbackAsync("/template/section", []) as any;

    const selectedBlockIds = templateType === TemplateTypes.Section ?
        selectedBlocks?.map(b => b.Id) :
        [];

    useEffect(() => {
        if (showModal) {
            loadTagsCallback(() => {});

            if (templateType === TemplateTypes.Site) {
                loadSitePreselectedTagsCallback(
                    (res: any) => {
                        setSelectedTags(res);
                    });

                loadIndustriesCallback((res: any) => {});
            }
            else if (templateType === TemplateTypes.Page) {
                loadPagePreselectedTagsCallback(
                    (res: any) => {
                        setSelectedTags(res);
                    });

                loadIndustriesCallback((res: any) => {});
            }
            else if (templateType === TemplateTypes.Section) {
                
                loadSectionPreselectedTagsCallback({BlockMappingIds: selectedBlockIds},
                    (res: any) => {
                        setSelectedTags(res);
                    });

                loadCategoriesCallback((res: any) => {
                    let organized = updateTypeNameAsDisplayNameAndSort(res, true);
                    if (selectedBlocks && selectedBlocks.length > 1)
                    {
                        organized = organized.filter((v,i,a) => v.ParentTypeId === 202);
                    }
    
                    setOrderedCategories(organized);

                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const getTitle = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "New Site Template";
            case TemplateTypes.Page:
                return "New Page Template";
            case TemplateTypes.Section:
                return "New Section";
            default:
                return "";
        }
    }
    
    const getSubmitErrorMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Error creating site template.";
            case TemplateTypes.Page:
                return "Error creating page template.";
            case TemplateTypes.Section:
                return "Error creating section template.";
            default:
                return "";
        }
    }

    const getTemplateNameLabel = () => {
        switch (templateType) {
            case TemplateTypes.Site:
            case TemplateTypes.Page:
                return "Template Name";
            case TemplateTypes.Section:
                return "Section Name";
            default:
                return "";
        }
    }

    const getTemplateNameValidMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
            case TemplateTypes.Page:
                return "Template name is required.";
            case TemplateTypes.Section:
                return "Section name is required.";
            default:
                return "";
        }
    }

    const getDescriptionLabel = () => {
        switch(templateType) {
            case TemplateTypes.Site:
            case TemplateTypes.Page:
                return "Template Description";
            case TemplateTypes.Section:
                return "Section Description";
            default:
                return "";
        }
    }
    
    const getSuccessMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Site template created successfully!";
            case TemplateTypes.Page:
                return "Page template created successfully!";
            case TemplateTypes.Section:
                return "Section template created successfully!";
            default:
                return "";
        }
    }

    const updateTemplateName = (name: string) => {
        setTemplateName(name);
        setTemplateNameValid(!!name);
    };

    const updateSectionCategory = (cat: string) => {
        const catInt = parseInt(cat);
        setNewSectionCategory(catInt);
        setSectionCategoryValid(catInt !== uncategorizedCategoryTypeId);
    };

    const updateSelectedIndustries = (value: any) => {
        setSelectedIndustryIds(value ?? []);
        setIndustriesValid(value && value.length > 0);
    }

    const updateSelectedPages = (value: string[]) => {
        setSelectedPageIds(value ?? []);
        setPagesValid(value && value.length > 0);
    }

    const openFilepicker = () => {
        fileInput.current && fileInput.current.click();
    }

    const updateScreenshot = (files: FileList | null) => {
        var file : any = (files && files[0]);
        if (file && isImageFile(file)) {
            var reader = new FileReader();
            reader.onload = (event) => {
                var i = new Image();
                i.onload = () => {
                    
                    const {name, extension} = getFileNameParts(file.name)
                    let fileData = {
                        FileType: file.type,
                        FileName: name,
                        FileExtension: extension,
                        // start at beginning, skip past data header (5), type length, and encoding details (8) then take the rest
                        FileContents: reader.result?.slice(5 + file.type.length + 8)
                    };
                    
                    setScreenshot(fileData);
                    setImageUrl(i.src);
                    setScreenshotValid(true);
                    setFileTypeValid(true);
                };
                i.src = event.target?.result as string;
            }
            reader.readAsDataURL(file);
        }
        else {
            file.isLoaded = true;

            const {name, extension} = getFileNameParts(file.name)
            let fileData = {
                FileType: file.type,
                FileName: name,
                FileExtension: extension
            };
            setScreenshot(fileData);
            setImageUrl('');
            setScreenshotValid(true);
            setFileTypeValid(false);
        }
    }

    const clearImage = () => {
        setScreenshot(null);
        setImageUrl('');
        setScreenshotValid(false);
        setFileTypeValid(true);
    }
    
    const handleSectionTagsChange = (event: SyntheticEvent<Element, Event>, value: string[]) => {
        let mappedFeatures: Feature[] = value.map((v) => {
            let feature:Feature = {Id:null,Name:v.trim()};
            let foundFeature = tags.find((t: any) => t.Name.toLowerCase() === v.toLowerCase());
            if (foundFeature) {
                return foundFeature;
            }
            return feature;
        });

        setSelectedTags(mappedFeatures.filter((a, i) => 
            mappedFeatures.findIndex((s) => a.Name.toLowerCase() === s.Name.toLowerCase()) === i));
    };

    const submitForm = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                if (templateNameValid === null) {
                    setTemplateNameValid(false);
                }
                if (industriesValid === null) {
                    setIndustriesValid(false);
                }
                if (screenshotValid === null) {
                    setScreenshotValid(false);
                    setFileTypeValid(true);
                }
                if (pagesValid === null) {
                    setPagesValid(false);
                }
                
                if (templateNameValid && industriesValid && screenshotValid && fileTypeValid && pagesValid) {
                    
                    setFormSubmitted(true);
                    setSubmitError(null);

                    submitNewSiteTemplateCallback({
                        Name: templateName,
                        Description: description,
                        Features: selectedTags,
                        SiteId: siteId,
                        PageIds: selectedPageIds,
                        Screenshot: screenshot,
                        IndustryIds: selectedIndustryIds
                    }, (res: string) => {
                        closeModal();
                        setShowCreateSuccess(true);
                        setFormSubmitted(false);
                    }, (err: ResponseError) => {
                        setFormSubmitted(false);
                        setSubmitError(err);
                    });

                }

                break;
            case TemplateTypes.Page:
                if (templateNameValid === null) {
                    setTemplateNameValid(false);
                }
                if (industriesValid === null) {
                    setIndustriesValid(false);
                }
                if (screenshotValid === null) {
                    setScreenshotValid(false);
                    setFileTypeValid(true);
                }
                
                if (templateNameValid && industriesValid && screenshotValid && fileTypeValid) {
                    
                    setFormSubmitted(true);
                    setSubmitError(null);

                    submitNewPageTemplateCallback({
                        Name: templateName,
                        Description: description,
                        Features: selectedTags,
                        PageId: pageId,
                        Screenshot: screenshot,
                        IndustryIds: selectedIndustryIds
                    }, (res: string) => {
                        closeModal();
                        setShowCreateSuccess(true);
                        setFormSubmitted(false);
                    }, (err: ResponseError) => {
                        setFormSubmitted(false);
                        setSubmitError(err);
                    });

                }

                break;
            case TemplateTypes.Section:
                if (templateNameValid === null) {
                    setTemplateNameValid(false);
                }
                if (sectionCategoryValid === null) {
                    setSectionCategoryValid(false);
                }
                if (templateNameValid && sectionCategoryValid) {
                    setFormSubmitted(true);
                    setSubmitError(null);
            
                    submitNewSectionCallback({
                        Name: templateName,
                        Description: description,
                        Features: selectedTags,
                        BlockCategoryId: newSectionCategory,
                        BlockMappingIds: selectedBlockIds
                    }, (res: string) => {
                        closeModal();
                        setShowCreateSuccess(true);
                        setFormSubmitted(false);
                        openBlockLibraryMenu();
                    }, (err: ResponseError) => {
                        setFormSubmitted(false);
                        setSubmitError(err);
                    });
                }
                break;
        }
    };

    const resetForm = () => {
        setTemplateName('');
        setTemplateNameValid(null);
        setDescription('');
        setSectionCategoryValid(null);
        setNewSectionCategory(uncategorizedCategoryTypeId);
        setSelectedTags([]);
        setSelectedIndustryIds([]);
        setIndustriesValid(null);
        setScreenshot(null);
        setImageUrl('');
        setScreenshotValid(null);
        setFileTypeValid(null);
        setSelectedPageIds(pages?.map(p => p.Id) ?? []);
        setPagesValid((pages && pages.length > 0) || null);
        setSubmitError(null);
        setShowCreateSuccess(false);
    };

    const closeModal = () => {
        resetForm();
        setShowModal(false);
    }
    
    const openBlockLibraryMenu = () => {
        refreshBlocksAndSections();
        updateSidebarState(SidebarState.BlocksOpen);
    };

    return (
        <>
            {!tagsLoading && !sectionPreselectedTagsLoading && !categoriesLoading &&
                <BuilderDialog
                    title={getTitle()}
                    message=""
                    action={submitForm}
                    actionName="Confirm"
                    open={showModal}
                    onClose={closeModal}
                    showCancel={true}
                >
                    <div style={{ marginBottom: "32px" }}>
                        <label htmlFor="template-name">{getTemplateNameLabel()}</label>
                        <TextField
                            id="template-name"
                            value={templateName}
                            onChange={(e) => updateTemplateName(e.target.value) }
                            label=""
                            variant="outlined"
                            fullWidth
                            sx={{ marginTop: "8px" }}
                            inputProps={{ maxLength: 200 }}
                            color="secondary" />
                        <FormValidationError
                            valid={(templateNameValid === null && !formSubmitted) || !!templateNameValid}
                            message={getTemplateNameValidMessage()} />
                    </div>
                    <div style={{ marginBottom: "32px" }}>
                        <label htmlFor="template-description">{getDescriptionLabel()}</label>
                        <TextField
                            id="template-description"
                            value={description}
                            multiline={true}
                            minRows={3}
                            onChange={(e) => setDescription(e.target.value) }
                            label=""
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                            fullWidth
                            sx={{ marginTop: "8px" }}
                            color="secondary" />
                    </div>
                    {templateType === TemplateTypes.Section && <div style={{ marginBottom: "32px" }}>
                        <label htmlFor="template-category">Category</label>
                        <Select
                            id="template-category"
                            value={newSectionCategory > 0 ? newSectionCategory.toString() : ''}
                            label=""
                            fullWidth
                            onChange={(e: SelectChangeEvent) => updateSectionCategory(e.target.value) }
                            sx={{ marginTop: "8px" }}
                        >
                            { orderedCategories && orderedCategories.map((c: Type) =>
                                <MenuItem value={c.Id} key={c.Id}>{c.Name}</MenuItem> )}
                        </Select>
                        <FormValidationError
                            valid={(sectionCategoryValid === null && !formSubmitted) || !!sectionCategoryValid}
                            message="A section category must be selected." />
                    </div>}
                    {(templateType === TemplateTypes.Site || templateType === TemplateTypes.Page) && <>
                        <div style={{ marginBottom: "32px" }}>
                            <label htmlFor="template-industry">Industry</label>
                            <Select
                                id="template-industry"
                                // @ts-ignore
                                value={selectedIndustryIds}
                                renderValue={(selected) =>
                                    industries.filter((i: IndustryType) => selected.includes(i.Id.toString())).map((i: IndustryType) => i.Name).join(', ')}
                                label=""
                                fullWidth
                                multiple
                                onChange={(e: SelectChangeEvent) => updateSelectedIndustries(e.target.value) }
                                input={<OutlinedInput />}
                                sx={{ marginTop: "8px" }}
                            >
                                {industries && industries.map((c: IndustryType) =>
                                    <MenuItem value={c.Id} key={c.Id}>
                                        <Checkbox checked={selectedIndustryIds && selectedIndustryIds.includes(c.Id)} />
                                        <ListItemText primary={c.Name} />
                                    </MenuItem>
                                )}
                            </Select>
                            <FormValidationError
                                valid={(industriesValid === null && !formSubmitted) || !!industriesValid}
                                message="At least one industry must be selected." />
                        </div>
                    </>}
                    <div style={{ marginBottom: "32px" }}>
                        <label htmlFor="template-tags">Tags</label>
                        <Autocomplete
                            // @ts-ignore
                            onChange={handleSectionTagsChange}
                            multiple
                            id="template-tags"
                            options={(tags || []).map((t: any) => t.Name)}
                            // @ts-ignore
                            value={selectedTags?.map((t) => t.Name)}
                            freeSolo
                            filterSelectedOptions
                            // @ts-ignore
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    sx={{ marginTop: "8px" }} 
                                    inputProps={{ ...params.inputProps, maxLength: 50}}
                                    />)
                            }
                        />
                    </div>
                    {(templateType === TemplateTypes.Site || templateType === TemplateTypes.Page) && <>
                        <div style={{ marginBottom: "32px" }}>
                            <label htmlFor="template-screenshot">Screenshot</label>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "8px",
                                marginBottom: "16px"
                            }}>
                                <Box sx={{ display: "flex", justifyContent: "left" }}>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            margin: "0 0 0 0",
                                            width: "56px",
                                            height: "56px",
                                            border: "1px solid #292929",
                                            minWidth: "56px",
                                            borderRadius: "3.5px",
                                            backgroundSize: "contain",
                                            backgroundImage: imageUrl ? `url('${getSanitizedUrlForBackgroundImage(imageUrl)}')`: "",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundColor: !imageUrl ? "secondary.main" : "",
                                            '&:hover': {
                                                backgroundColor: !imageUrl ? "secondary.main" : "",
                                            }
                                        }}
                                        onClick={openFilepicker}>
                                            { !imageUrl && <ImageIcon sx={{ color: "white" }} />}
                                    </Button>
                                </Box>
                                <TextField 
                                    id="template-screenshot"
                                    sx={{ marginLeft: "8px" }}
                                    value={(screenshot ? screenshot.FileName + screenshot.FileExtension : "Click to set an image")} 
                                    variant="outlined" 
                                    onClick={openFilepicker}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth />
                                <DeleteOutlineIcon sx={{
                                        cursor: "pointer",
                                        color: "#CF1F2E",
                                        fontSize: "25px",
                                        marginLeft: "16px",
                                        visibility: screenshot ? undefined : "hidden"
                                    }} 
                                    onClick={clearImage}
                                />
                                <input 
                                    type={"file"} 
                                    accept="image/*" 
                                    ref={fileInput} 
                                    style={{display: 'none'}} 
                                    multiple={false}
                                    onClick={(e) => (e.target as HTMLInputElement).value = ''} 
                                    onChange={(e) => updateScreenshot(e.target.files)} 
                                />
                            </Box>
                            <FormValidationError
                                valid={(screenshotValid === null && !formSubmitted) || !!screenshotValid}
                                message="Screenshot is required." />
                            <FormValidationError
                                valid={(fileTypeValid === null && !formSubmitted) || !!fileTypeValid}
                                //message="File type must be JPEG, PNG, or WEBP." />  // Revert to this after ARR-1374
                                message="File type must be JPEG or PNG." />
                        </div>

                    </>}
                    {(templateType === TemplateTypes.Site) && <>
                        <div style={{ marginBottom: "30px" }}>
                            <div>
                                <label htmlFor="template-pages">Pages to include</label>
                            </div>
                            <Select
                                id="template-pages"
                                // @ts-ignore
                                value={selectedPageIds}
                                renderValue={(selected) =>
                                    pages?.filter(i => selected.includes(i.Id)).map(i => i.Name).join(', ')}
                                label=""
                                fullWidth
                                multiple
                                onChange={(e: SelectChangeEvent) => updateSelectedPages(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) }
                                input={<OutlinedInput />}
                                sx={{ marginTop: "8px" }}
                            >
                                {pages && pages.map((c: PageInfo) =>
                                    <MenuItem value={c.Id} key={c.Id}>
                                        <Checkbox checked={selectedPageIds && selectedPageIds.includes(c.Id)} />
                                        <ListItemText primary={c.Name} />
                                    </MenuItem>
                                )}
                            </Select>
                            <FormValidationError
                                    valid={(pagesValid === null && !formSubmitted) || !!pagesValid}
                                    message="At least one page must be selected." />
                        </div>
                    </>}

                </BuilderDialog>
            }
            { (tagsLoading || sectionPreselectedTagsLoading || pagePreselectedTagsLoading || categoriesLoading
                || industriesLoading || formSubmitted) && <Loading />}
            <ErrorMessage 
                message="Error loading feature tags. Please try again later." 
                duration={15000}
                dismissable={true}
                visible={tagsError}
                apiError={tagsApiError}
            />
            <ErrorMessage 
                message="Error loading block categories. Please try again later." 
                duration={15000}
                dismissable={true}
                visible={categoriesError}
                apiError={categoriesApiError}
            />
            <ErrorMessage 
                message="Error loading industries. Please try again later." 
                duration={15000}
                dismissable={true}
                visible={industriesError}
                apiError={industriesApiError}
            />
            <ErrorMessage
                message={getSubmitErrorMessage()}
                duration={15000}
                dismissable={true}
                visible={submitError != null}
                apiError={submitError}
            />
            <SuccessMessage
                message={getSuccessMessage()}
                dismissable={true}
                duration={3000}
                visible={showCreateSuccess}
            />
        </>
    )
}