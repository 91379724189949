import { Switch } from "@mui/material";
import React, { useState } from "react";
import Property from "../../types/Property";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DataSwapModal from "./DataSwapModal";
import DataSwap from "../../types/DataSwap";
import DataSourceFieldMappingValueTypes from "../../types/enum/DataSourceFieldMappingValueTypes";
import { isSwapValue } from '../../utils/valueSwapHelpers';
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import { PropertyNameLabel } from "../common/PropertyNameLabel";

export function Editor({property, propertyUpdated, populatedDataSources, siteProperties, page}: PropertyEditorProps) {
    const [showDataSwaps, setShowDataSwaps] = useState(false);

    const updateValue = (value?: string | null, renderedValue?: string | null) => {
        if (property) {
            property.Value = value;
            property.RenderedValue = renderedValue;
            propertyUpdated(property);
        }
    };

    const handleSwitchOnChange = (event: React.ChangeEvent, checked: boolean) => {
        updateValue(checked.toString(), checked.toString());
    };

    const handleDataSwapSelected = (ds: DataSwap) => { 
        updateValue(ds.swapValue, ds.value); 
        setShowDataSwaps(false); 
    };

    const checked = property?.RenderedValue?.toLowerCase() === "true";
    const usesSwapValue = isSwapValue(property?.Value);
    const configObj = property?.Configuration ? JSON.parse(property?.Configuration) : {};
    const disableSwapping: boolean = configObj.DisableSwapping ?? false;

    return (
        <>
        <PropertyNameLabel 
            labelId={`label-${property?.PropertyId}`}
            property={property}
        ></PropertyNameLabel>
        <div style={{ display: "flex" }}>
            <div style={{ flex: "1" }}>
                <Switch
                    checked={checked}
                    onChange={handleSwitchOnChange}
                    disabled={usesSwapValue}
                    data-testid="switch-control"
                />
            </div>
            {!disableSwapping &&
                <div>
                    <AccountTreeOutlinedIcon 
                        sx={{
                            cursor: "pointer",
                            fontSize: "25px",
                            color: usesSwapValue ? "secondary.main" : undefined,
                        }}
                        onClick={() => setShowDataSwaps(true) }
                        data-testid="swap-icon"
                    />
                    { usesSwapValue &&
                        <DeleteOutlineIcon 
                            sx={{
                                cursor: "pointer",
                                color: "#CF1F2E",
                                fontSize: "25px",
                                marginLeft: "16px",
                            }} 
                            onClick={() => updateValue(property?.RenderedValue, property?.RenderedValue)}
                            data-testid="delete-icon"
                        />
                    }
                </div>
            }
        </div>     
        { !disableSwapping &&
            <DataSwapModal 
                populatedDataSources={populatedDataSources || []} 
                siteProperties={siteProperties || []} 
                pageProperties={page?.PageProperties ?? []}
                showModal={showDataSwaps} 
                onClose={() => setShowDataSwaps(false) }
                dataSwapSelected={handleDataSwapSelected}
                selectedToken={usesSwapValue ? property?.Value : null}
                propertyValueTypeId={property?.ValueTypeId}
                allowedDataSourceValueTypes={[DataSourceFieldMappingValueTypes.Boolean]}
            />
        }           
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    return false;
}
