import * as React from 'react';
import { useState, useContext, ReactNode } from 'react';
import User from './types/User';
import UserData from './types/UserData';

const UserContext = React.createContext<User>({
    currentUser: null,
    sessionId: null,
    correlationId: null
});

export interface UserContextProviderProps {
    user: UserData | null;
    children: ReactNode;
}

const UserContextProvider = ({ user, children }: UserContextProviderProps) => {
    const [ currentUser, setCurrentUser ] = useState(user ? {name: user.name, nickname: user.nickname} : null);
    const [ sessionId, setSessionId ] = useState<string | null>(null);
    const [ correlationId, setCorrelationId ] = useState<string | null>(null);


    return (
        <UserContext.Provider value={{ 
            currentUser, setCurrentUser, 
            sessionId, setSessionId,
            correlationId, setCorrelationId
        }}>
            { children }
        </UserContext.Provider>
    )
}


const useUserContext = () => useContext(UserContext);

export { UserContext, UserContextProvider, useUserContext }
