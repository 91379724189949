import { useEffect, useState } from 'react';
import { Editor as ImageProperty } from '../../property_editors/ImageProperty';
import PropertyId from '../../../types/enum/PropertyId';
import Property from '../../../types/Property';
import { Editor as VideoProperty } from '../../property_editors/VideoProperty';
import { Editor as ImageCarouselSlidesProperty } from '../../property_editors/ImageCarouselSlidesProperty';
import { Editor as DropdownProperty } from '../../property_editors/DropdownProperty';
import { Editor as BooleanProperty } from '../../property_editors/BooleanProperty';
import BlockMapping from '../../../types/BlockMapping';
import Site from '../../../types/Site';
import Page from '../../../types/Page';
import './CTABackgroundStep.css';

export interface CTABackgroundStepProps {
    blockmapping: BlockMapping;
    site: Site;
    templateData: Page | null;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    updatePreviewBlockId: React.Dispatch<React.SetStateAction<string | null>>;
}

enum MediaType {
    SingleImage = "",
    MultipleImages = "m",
    Video = "v"
}

function CTABackgroundStep({ blockmapping, site, templateData, setTemplateData, setError, updatePreviewBlockId }: CTABackgroundStepProps) {
    const [selectedMediaType, setSelectedMediaType] = useState<MediaType | null>(null);
    const [mediaDisplayTypeProperty, setMediaDisplayTypeProperty] = useState<Property | undefined | null>(null);
    const [singleImageProperty, setSingleImageProperty] = useState<Property | undefined | null>(null);
    const [multipleImagesProperty, setMultipleImagesProperty] = useState<Property | undefined | null>(null);
    const [imageScrollSpeedProperty, setImageScrollSpeedProperty] = useState<Property | undefined | null>(null);
    const [videoProperty, setVideoProperty] = useState<Property | undefined | null>(null);
    const [loopVideoProperty, setLoopVideoProperty] = useState<Property | undefined | null>(null);
    const [videoPosterProperty, setVideoPosterProperty] = useState<Property | undefined | null>();

    useEffect(() => {
        updatePreviewBlockId(blockmapping.Id);
        for (let block of (templateData ? templateData.BlockMappings : [])) {
            if (block.Id === blockmapping.Id) {
                setMediaDisplayTypeProperty(block.Properties.find(p => p.EnumId === PropertyId.MediaDisplayType));
                setSingleImageProperty(block.Properties.find(p => p.EnumId === PropertyId.CtaBackgroundImage));
                setMultipleImagesProperty(block.Properties.find(p => p.EnumId === PropertyId.BackgroundImages));
                setImageScrollSpeedProperty(block.Properties.find(p => p.EnumId === PropertyId.ImageScrollSpeed));
                setVideoProperty(block.Properties.find(p => p.EnumId === PropertyId.BackgroundVideo));
                setLoopVideoProperty(block.Properties.find(p => p.EnumId === PropertyId.PlayVideoOnLoop));
                setVideoPosterProperty(block.Properties.find(p => p.EnumId === PropertyId.BackgroundVideoPoster));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        propertyUpdated(mediaDisplayTypeProperty, selectedMediaType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMediaType]);

    const propertyUpdated = (property?: Property | undefined | null, content?: any) => {
        if (mediaDisplayTypeProperty) {
            mediaDisplayTypeProperty.Value = selectedMediaType;
            mediaDisplayTypeProperty.RenderedValue = selectedMediaType;
            mediaDisplayTypeProperty.ValueUpdatedInWizard = true;
        }
        if (singleImageProperty) {
            singleImageProperty.ValueUpdatedInWizard = true;
        }
        if (multipleImagesProperty) {
            multipleImagesProperty.ValueUpdatedInWizard = true;
        }
        if (imageScrollSpeedProperty) {
            imageScrollSpeedProperty.ValueUpdatedInWizard = true;
        }
        if (videoProperty) {
            videoProperty.ValueUpdatedInWizard = true;
        }
        if (loopVideoProperty) {
            loopVideoProperty.ValueUpdatedInWizard = true;
        }
        if (videoPosterProperty) {
            videoPosterProperty.ValueUpdatedInWizard = true;
        }
        setTemplateData(Object.assign({}, templateData));
    };

    return (
        <>
            <div id="cta-background-step-container" className="wizard-step-header-container">
                <h1 className="headline4">Set a CTA background</h1>
                <p>Select a media display type</p>
                <div className="cta-background-step-radios">
                    <label>
                        <input
                            type="radio"
                            value={MediaType.SingleImage}
                            checked={selectedMediaType === MediaType.SingleImage}
                            onChange={() => setSelectedMediaType(MediaType.SingleImage)}
                        />
                        Single image
                    </label>
                    <br/>
                    <label>
                        <input
                            type="radio"
                            value={MediaType.MultipleImages}
                            checked={selectedMediaType === MediaType.MultipleImages}
                            onChange={() => setSelectedMediaType(MediaType.MultipleImages)}
                        />
                        Multiple images
                    </label>
                    <br/>
                    <label>
                        <input
                            type="radio"
                            value={MediaType.Video}
                            checked={selectedMediaType === MediaType.Video}
                            onChange={() => setSelectedMediaType(MediaType.Video)}
                        />
                        Video
                    </label>
                </div>
            </div>
            <div className="wizard-step-section">
                { selectedMediaType === MediaType.SingleImage &&
                    <>
                        <p className="body1">Rectangular images work best.</p>
                        {singleImageProperty && <ImageProperty siteId={site.Id} property={singleImageProperty} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} /> }
                    </>
                }
                { selectedMediaType === MediaType.MultipleImages &&
                    <>
                        <p className="body1">Rectangular images work best.</p>
                        {multipleImagesProperty && <ImageCarouselSlidesProperty siteId={site.Id} property={multipleImagesProperty} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} /> }
                        <div className="image-scroll-speed-container">
                            {imageScrollSpeedProperty && <DropdownProperty siteId={site.Id} property={imageScrollSpeedProperty} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} />}
                        </div>
                    </>
                }
                { selectedMediaType === MediaType.Video &&
                    <>
                        <p className="body1">Rectangular videos work best. Max file size for videos is 25MB.</p>
                        {videoProperty && <VideoProperty siteId={site.Id} property={videoProperty} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} /> }
                        {videoPosterProperty &&
                            <>
                                <p>Background Video Poster</p>
                                <ImageProperty siteId={site.Id} property={videoPosterProperty} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} />
                            </>}
                        {loopVideoProperty && <BooleanProperty siteId={site.Id} property={loopVideoProperty} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} />}
                    </>
                }
            </div>
        </>
    );
}

export default CTABackgroundStep;
