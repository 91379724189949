export const StarRating = ({ rating }: { rating: number }) =>
    <>
        {[0,1,2,3,4].map((starNumber) => {
            if (rating > starNumber + 0.71) {
                return (
                    <span className="review-star material-icons-round text-3xl" title="star icon filled" aria-hidden={true} key={`star-${starNumber}`}>star</span>
                );
            } else if (rating > starNumber + 0.28) {
                return (
                    <div className="review-half-star" key={`star-${starNumber}`}>
                        <span className="review-star unfilled material-icons-round text-3xl" title="star icon outlined" aria-hidden={true}>star_border</span>
                        <span className="review-star filled-half-star material-icons-round text-3xl" title="star icon" aria-hidden={true}>star</span>
                    </div>
                );
            } else {
                return (
                    <span className="review-star unfilled material-icons-round text-3xl" title="star icon outlined" aria-hidden={true} key={`star-${starNumber}`}>star_border</span>
                );
            }
        })}
    </>

export default StarRating;