import { useEffect, useState } from 'react';
import NormalizedPage from '../../types/NormalizedPage';
import { convertNormalizedPageToPage } from '../../utils/pageHelpers';
import Page from '../../types/Page';
import useGetAndSet from '../useGetAndSet';

export default function useGetWizardPage(templateId: string | null | undefined, siteId: string, setter: (page: Page) => void) {
    const [normalizedPage, setNormalizedPage] = useState<NormalizedPage | null>(null);

    useEffect(() => {
        if (normalizedPage) {
            setter(convertNormalizedPageToPage(normalizedPage)); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [normalizedPage]);

    useGetAndSet(`/wizard/template/normalized/${templateId}/${siteId}?skipDisplayRules=${true}`,
        setNormalizedPage, !!templateId);
} 
