import { useState, useContext } from "react";
import useGetAndSet from "../hooks/useGetAndSet";
import useGetCallbackAndSet from "../hooks/useGetCallbackAndSet";
import useApiPostCallbackAsync from "../hooks/useApiPostCallbackAsync";
import { Button, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Loading from "./common/Loading";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import Site from "../types/Site";
import IndustryType from "../types/IndustryType";

function DevTestUtility() {
    const [industries, setIndustries] = useState<IndustryType[]>();
    const [domain, setDomain] = useState<string | null>(null);
    const [industryId, setIndustryId] = useState<number | null>(null);
    const [sites, setSites] = useState([]);
    const [creatingSite, setCreatingSite] = useState(false);
    const {currentUser} = useContext(UserContext);
    const [resetSiteId, setResetSiteId] = useState<number | null>(null);
    const [resettingSite, setResettingSite] = useState(false);
    //const [userSitesOnly, setUserSitesOnly] = useState(false);
    const navigate = useNavigate();

    useGetAndSet(
        `/type/100`,
        setIndustries);

    useGetAndSet(
        `/sites`,
        setSites);

    const loadSites = useGetCallbackAndSet(
        `/sites`,
        setSites);

    const [{ isLoading }, ] = useApiPostCallbackAsync(
        '/sites',
        null) as any;

    const [{ isLoading: resetIsLoading }, resetCallback] = useApiPostCallbackAsync(
        `/sites/${resetSiteId}/status/2`,
        null) as any;

    /*
    const createSite = () => {
        setCreatingSite(true);
        createSiteCallback({
            Domain: domain,
            Industry: industryId,
            CMS: 1
        });
    }

    const resetSite = (siteId) => {
        setResetSiteId(siteId);
    }
    */

    if (resetSiteId && !resettingSite && !resetIsLoading) {
        setResettingSite(true);
        resetCallback();
        setResetSiteId(null);
    }

    if (resetSiteId && resettingSite && !resetIsLoading) {
        setResetSiteId(null);
    }

    if (!resetSiteId && resettingSite && !resetIsLoading) {
        setResettingSite(false);
        // @ts-ignore
        loadSites();
    }

    const setDomainName = () => {
        setDomain("www." + currentUser?.nickname + "-" + uuidv4() + ".com");
    }

    if (domain == null && currentUser) {
        setDomainName();
    }

    if (creatingSite && !isLoading) {
        setCreatingSite(false);
        setDomainName();
        // @ts-ignore
        loadSites();
    }

    if (!industryId && industries) {
        setIndustryId(industries[0].Id);
    }

    /*
    const updateUserSites = (event) => {
        setUserSitesOnly(event.target.checked);
    };

    let selectedSite = null;

    if (siteId && sites) {
        selectedSite = sites.find(s => s.Id === siteId);
    }
    */

    // const displaySites = userSitesOnly ? sites.filter((s: Site) => s.Domain.includes(currentUser?.nickname)) : sites;
    const displaySites = sites.filter((s: Site) => s.IndustryName);

    return (
        <Box sx={{ width: '100%' }}>
            { isLoading && <Loading />}
            {/* <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ padding: "10px" }}
                >
                { selectedSite && <Button sx={{ marginBottom: "10px" }} variant="contained" onClick={() => window.open(`${(window as any).CONFIG.RendererBaseUrl}?domain=${selectedSite.Domain}`, "_blank")}>Open Site</Button>}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField fullWidth id="outlined-basic" label="" variant="outlined" value={domain} onChange={(e) => { setDomain(e.target.value)}} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <Select
                                data-testid="industrySelect"
                                value={industryId}
                                onChange={(e) => { setIndustryId(e.target.value)}}
                                variant="outlined"
                                >
                                {industries?.map(ind =>
                                    <MenuItem key={ind.Id} value={ind.Id}>{ind.Name} </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <Button sx={{ marginLeft: "10px", marginTop: "8px" }} variant="contained" onClick={createSite}>Create Site</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginLeft: '15px' }}><input type="checkbox" checked={userSitesOnly} onChange={updateUserSites} /> My Sites Only</Box> */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Domain</TableCell>
                        <TableCell>Industry</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {displaySites.map((site: Site) => (
                            <TableRow
                                key={site.Id}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: site.Domain.includes(currentUser?.nickname) ? '#EEEEEE' : '#FFFFFF' }}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: '#FFFFFF' }}
                            >
                            <TableCell component="th" scope="row">
                                {site.Id}
                            </TableCell>
                            <TableCell>{site.Domain}</TableCell>
                            <TableCell>{site.IndustryName}</TableCell>
                            <TableCell>{site.StatusTypeId === 3 && site.PublishedVersionId !== null && site.PublishedVersionDate !== null ? "Published" : site.StatusName}</TableCell>
                            <TableCell sx={{whiteSpace: "nowrap" }}>
                                {/* {<Button sx={{marginLeft: "5px" }} onClick={() => navigate(`/site/${site.Id}/admin`)}>Admin</Button>} */}
                                {<Button sx={{marginLeft: "5px" }} onClick={() => navigate(`/site/${site.Id}`)}>Edit</Button>}
                                {/* {site.StatusTypeId === 2 && <Button sx={{marginLeft: "5px" }} variant="contained" onClick={() => navigate(`/site/${site.Id}`)}>Start Wizard</Button>}
                                {site.StatusTypeId === 3 && <Button sx={{marginLeft: "5px" }} onClick={() => navigate(`/site/${site.Id}/wizard`)}>Wizard</Button>}
                                {site.StatusTypeId === 3 && <Button sx={{marginLeft: "5px" }} variant="contained" onClick={() => window.open(`${(window as any).CONFIG.RendererBaseUrl}?domain=${site.Domain}`, "_blank")}>Open Site</Button> } */}
                            </TableCell>
                            </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default DevTestUtility;
