import PropertyId from "../../../types/enum/PropertyId";
import { getPropertyValue, getDynamicAttributes } from "../../../utils/blockHelpers";
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from "../../../utils/blockRendererDefaults";
import BlockFilterData from "../../../types/BlockFilterData";

const isConfiguredProperly = (block: BlockMapping) => {
    const icon = JSON.parse(getPropertyValue(block.Properties, PropertyId.MaterialIcon) || '{}');
    return icon && icon.Name;
};

export function Block({page, block, children, builderProps, blockWrapperData}: BlockProps) {
    if (!isConfiguredProperly(block)) {
        return null;
    }
    const icon = JSON.parse(getPropertyValue(block.Properties, PropertyId.MaterialIcon) || '{"Class":"","Name":""}');
    const iconClass = icon.Class ? `${icon.Class} material-icons`  : "material-icons";
    const iconTitle = getPropertyValue(block.Properties, PropertyId.Title)
        || icon.Name.replace('_', ' ');

    return (
        <span {...getDynamicAttributes({block, builderProps, className: iconClass, blockWrapperData})} title={iconTitle} aria-hidden="true">{icon.Name}</span>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const icon = JSON.parse(getPropertyValue(block.Properties, PropertyId.MaterialIcon) || '{}');

    return icon?.Class ? <span data-testid='maticon' className={icon.Class}></span>  : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const icon = JSON.parse(getPropertyValue(block.Properties, PropertyId.MaterialIcon) || '{}');

    return (!!icon.Class && (icon.Class.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}