import { useDrag, useDrop } from "react-dnd";
import { useRef } from 'react';
import HoverData from '../../types/HoverData';
import DragDropData from '../../types/DragDropData';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import "./NavigationItem.css";
import NavigationItemValue from "../../types/NavigationItemValue";
import WarningIconWithTooltip from "./WarningIconWithTooltip";
import PageInfo from "../../types/PageInfo";
import { hasValidItems } from "../../utils/navItemsHelpers";
import PageStatus from "../../types/enum/PageStatus";

export interface NavigationItemProps {
  navItem: NavigationItemValue,
  parentIndex: number,
  childIndex: number | null,
  pageList?: PageInfo[],
  openEditDialog: (navItem: NavigationItemValue, parentIndex: number, childIndex: number | null) => void,
  openDeleteDialog: (parentIndex: number, childIndex: number | null) => void,
  dragDrop: DragDropData | null,
  inDisabledFolder?: boolean,
}

export default function NavigationItem({
  navItem,
  parentIndex,
  childIndex,
  pageList,
  openEditDialog,
  openDeleteDialog,
  dragDrop,
  inDisabledFolder = false
}: NavigationItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'NavItem',
    collect(monitor) {
        return {
            handlerId: monitor.getHandlerId(),
        };
    },
    hover(item: any, monitor) {
        if (!ref.current) {
            return;
        }
        if (monitor.isOver({ shallow: true })) {
            const rect = ref.current?.getBoundingClientRect();
            const mouse = monitor.getClientOffset();
            const topPercent = (mouse!.y - rect.top) / rect.height;
            const bottomPercent = (rect.bottom - mouse!.y) / rect.height;
            const isBefore = topPercent <= .3;
            const isAfter = bottomPercent <= .3;
            const isInto = !isBefore && !isAfter;
            const hoverData: HoverData = {
                type: 'NavItem',
                rect,
                mouse,
                isBefore,
                isAfter,
                isInto,
                hoveredParentIndex: parentIndex,
                hoveredChildIndex: childIndex,
                parentIndex: item.parentIndex,
                childIndex: item.childIndex,
                hoveredId: null,
                blockMappingId: null,
                blockId: null,
                templateId: null,
                componentId: null,
            };
            dragDrop?.hovering(hoverData);
        }
    },
    drop(item, monitor) {
        dragDrop?.finished(dragDrop?.hoverData);
    }
  });
  const [, drag] = useDrag({
      type: 'NavItem',
      item: () => {
          dragDrop?.started({ type: 'NavItem' });
          return {
            parentIndex: parentIndex,
            childIndex: childIndex
          }
      },
      end: (item, monitor) => {
          dragDrop?.finished(null);
      },
      collect: (monitor) => ({
          isDragging: monitor.isDragging()
      }),
  });

  drag(drop(ref));

  const foundPage: PageInfo | undefined = navItem.p !== null
    ? pageList?.find(p => p.Id === navItem.p) : undefined;


  if (navItem.d === 'f' && navItem.c) {
    inDisabledFolder = !hasValidItems(navItem.c, pageList ?? []);
  }

  return (
    <>
      <div
        ref={ref}
        data-testid="NavigationItem"
        style={{
          height: "54px",
          lineHeight: "54px",
          display: "flex",
          alignItems: "center",
          cursor: 'move'
        }} >
        <DragIndicatorIcon
            sx={{
              marginLeft: childIndex === null ? '0px' : '30px',
              cursor: 'move',
              marginRight: '28px'
            }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          {navItem?.i && <i
              className={navItem.i}
              style={{
                  width: '20px',
                  height: '20px',
                  fontSize: '20px',
                  lineHeight: '20px'
              }}
          />}
          { navItem?.l &&
            <div
              style={{
                display: "block",
                height: "20px",
                lineHeight: "20px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "auto",
                maxWidth: "200px",
                marginLeft: navItem.l && navItem.i ? '18px' : '0px',
                opacity: navItem.d !== 'f' && inDisabledFolder ? '0.6' : '1.0'
              }}
            >
              { navItem.l }
            </div> }
            {navItem.d === 'f' && navItem.c && navItem.c.length !== 0 &&
            <FolderOpenOutlinedIcon
              sx={{
                marginLeft: "18px"
              }}
            />}
            {/* no folder children */}
            {navItem.d === 'f' && !navItem.c &&
              <WarningIconWithTooltip tooltipMessage="Empty folders are hidden from the navigation bar." />
            }
            {/* folder with no valid children - tooltip on folder, children grayed */}
            {inDisabledFolder && navItem.d === 'f' &&
              <WarningIconWithTooltip tooltipMessage="Folders without any enabled items are hidden from the navigation bar." />
            }
            {/* disabled navItem */}
            {!inDisabledFolder && navItem.d !== 'f' && foundPage && foundPage.StatusTypeId !== PageStatus.Active &&
              <WarningIconWithTooltip tooltipMessage="Disabled pages are hidden from the navigation bar." />
            }
        </div>
        <SettingsIcon
            sx={{
              marginLeft: '0px',
              color: "#1B1AFF",
              cursor: "pointer"
            }}
            onClick={() => openEditDialog(navItem, parentIndex, childIndex)}
          />
        <DeleteOutlineIcon
            sx={{
              marginLeft: '12px',
              color: '#CF1F2E',
              cursor: "pointer"
            }}
            onClick={() => openDeleteDialog(parentIndex, childIndex)}
          />
      </div>
      {navItem.c && navItem.c.length > 0 && navItem.c.map((item: any, index: number) =>
        <NavigationItem
          key={`${parentIndex}-${index}`}
          navItem={item}
          parentIndex={parentIndex}
          childIndex={index}
          openEditDialog={openEditDialog}
          openDeleteDialog={openDeleteDialog}
          dragDrop={dragDrop}
          pageList={pageList}
          inDisabledFolder={inDisabledFolder}
        ></NavigationItem>
      )}
    </>);
}