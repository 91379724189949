import { createContextStore, action, Action, thunk, Thunk } from 'easy-peasy';
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { ResponseError, callApi, httpMethods } from './utils/apiHelpers';
import UserSessionData from './types/UserSessionData';
import FileRecord from './types/FileRecord';
import GoogleFont from './types/GoogleFont';

export interface BuilderStoreModel {
  siteId: string | null,
  siteFiles: FileRecord[],
  googleFonts: GoogleFont[],
  errorMessage: string | null,
  errorResponse: ResponseError | null,
  getAccessTokenSilently: ((options?: GetTokenSilentlyOptions | undefined) => Promise<string>) | null,
  userSession: UserSessionData | null,
  updateGetAccessTokenSilently: Action<BuilderStoreModel, (options?: GetTokenSilentlyOptions | undefined) => Promise<string>>,
  updateUserSession: Action<BuilderStoreModel, UserSessionData | null>,
  setSiteId: Thunk<BuilderStoreModel, string | null>,
  updateSiteId: Action<BuilderStoreModel, string | null>,
  updateGoogleFonts: Action<BuilderStoreModel, GoogleFont[] | null>,
  updateSiteFiles: Action<BuilderStoreModel, FileRecord[] | null>,
  updateErrorMessage: Action<BuilderStoreModel, string | null>,
  updateErrorResponse: Action<BuilderStoreModel, ResponseError | null>,
  refreshGoogleFonts: Thunk<BuilderStoreModel>,
  refreshSiteFiles: Thunk<BuilderStoreModel>,
  loadInitialData: Thunk<BuilderStoreModel, { getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>, userSession: UserSessionData }>,
}

export const builderStoreDefaultModel: BuilderStoreModel = {
  siteId: null,
  siteFiles: [],
  googleFonts: [],
  errorMessage: null,
  errorResponse: null,
  getAccessTokenSilently: null,
  userSession: null,
  updateUserSession: action((state, userSession) => {
    state.userSession = userSession;
  }),
  updateGetAccessTokenSilently: action((state, getAccessTokenSilently) => {
    state.getAccessTokenSilently = getAccessTokenSilently;
  }),
  updateSiteId: action((state, siteId) => {
    state.siteId = siteId;
  }),
  updateErrorMessage: action((state, errorMessage) => {
    state.errorMessage = errorMessage;
  }),
  updateErrorResponse: action((state, errorResponse) => {
    state.errorResponse = errorResponse;
  }),
  updateGoogleFonts: action((state, googleFonts) => {
    state.googleFonts = googleFonts ?? [];
  }),
  updateSiteFiles: action((state, siteFiles) => {
    state.siteFiles = siteFiles ?? [];
  }),
  setSiteId: thunk((actions, updatedSiteId, { getState }) => {
    const { siteId } = getState();

    if (updatedSiteId !== siteId) {
      actions.updateSiteId(updatedSiteId);
      actions.refreshSiteFiles();
    }
  }),
  refreshGoogleFonts: thunk(async (actions, payload, { getState }) => {
    const { userSession, getAccessTokenSilently } = getState();
    try {
      const fonts = await callApi('/google/fonts', httpMethods.get
        , userSession, getAccessTokenSilently, null, (window as any).CONFIG.GoogleApiPrefix);

      actions.updateGoogleFonts(fonts);
    } catch (error: any) {
      actions.updateErrorMessage("Error loading Google fonts.");
      actions.updateErrorResponse(error);
    }
  }),
  refreshSiteFiles: thunk(async (actions, payload, { getState }) => {
    const { siteId, userSession, getAccessTokenSilently } = getState();

    if (siteId) {
      try {
        const files = await callApi(`/site/${siteId}/files/search`, httpMethods.post
          , userSession, getAccessTokenSilently, {});

        actions.updateSiteFiles(files);
      } catch (error: any) {
        actions.updateErrorMessage("Error loading site files.");
        actions.updateErrorResponse(error);
      }
    }
  }),
  loadInitialData: thunk(async (actions, payload) => {

    actions.updateGetAccessTokenSilently(payload.getAccessTokenSilently);
    actions.updateUserSession(payload.userSession);

    try {
      const refreshFonts = actions.refreshGoogleFonts();
      await refreshFonts;
    } catch (error: any) {
      actions.updateErrorMessage("Error loading initial builder state.");
      actions.updateErrorResponse(error);
    }
  })
};
  
const BuilderStore = createContextStore<BuilderStoreModel>((runtimeModel) => (runtimeModel ?? builderStoreDefaultModel));

export default BuilderStore;