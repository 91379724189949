export function uniqueArrayValues(array: string[] | null): string[] | null {
    if (!array) {
        return null;
    }
    var onlyUnique = (value: string, index: number, array: string[]) => {
        return array.indexOf(value) === index;
    }

    return array.filter(onlyUnique);
}

export function areArrayValuesEquivalent(array1: string[] | null, array2: string[] | null): boolean {
    if (!array1 || !array2) {
        return false;
    }

    return JSON.stringify(array1.sort()) === JSON.stringify(array2.sort());
}
