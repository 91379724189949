import SiteEditorPropertyList from './SiteEditorPropertyList';
import PropertySideBarContent from '../../../types/PropertySideBarContent';
import PageNameEditor from './PageNameEditor';
import PageRouteEditor from './PageRouteEditor';
import TabPanel from "../../common/TabPanel";
import TemplateTypes from "../../../types/enum/TemplateTypes";
import SwapTemplate from "./builder_actions/swap_template/SwapTemplate";
import CreateTemplate from "./builder_actions/create_template/CreateTemplate";
import SiteEditorStore from "../SiteEditorStore";
import { filterProperties } from "../../../utils/propertyHelpers";
import NoResultsFound from "../../common/NoResultsFound";
import property_renderers from '../../../utils/propertyRenderers';

export default function SiteEditorPropertiesPageProperties() {
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const propertyTab = SiteEditorStore.useStoreState((state) => state.propertyTab);
    const searchFilter = SiteEditorStore.useStoreState((state) => state.propertySearchCriteria);

    const pageContent: PropertySideBarContent[] = [];

    if (page) {
      if (!searchFilter || "page name".includes(searchFilter)) {
        pageContent.push({
          CategoryTypeId: 738,
          Content: <PageNameEditor />
        });
      }
      if (!searchFilter || "page slug".includes(searchFilter)) {
        pageContent.push({
          CategoryTypeId: 738,
          Content: <PageRouteEditor /> 
        });
      }
      if(!searchFilter || "create page template".includes(searchFilter)) {
        pageContent.push({
          CategoryTypeId: 999,
          Content: 
            <CreateTemplate
              id={"pageproperties-createpagetemplate-button"}
              templateType={TemplateTypes.Page}
              pageId={page.PageId}
            />
        });
      }
      if(!searchFilter || "swap page template".includes(searchFilter)) {
        pageContent.push({
          CategoryTypeId: 999,
          Content: 
            <SwapTemplate
              id={"pageproperties-swappagetemplate-button"}
              templateType={TemplateTypes.Page}
            />
        });
      }
    }

    let filteredPageProperties = page ? page.PageProperties : [];
    let noResultsFound = false;

    if (searchFilter) {
      filteredPageProperties = filterProperties(filteredPageProperties, searchFilter, { pageList }
        , property_renderers);
      noResultsFound = filteredPageProperties.length === 0 && pageContent.length === 0;
    }

    return (
      <TabPanel value={propertyTab} name="page-tab" index={1} height="calc(100% - 227px)">
        { !noResultsFound &&
          <SiteEditorPropertyList
            properties={filteredPageProperties}
            extraContent={pageContent}
          />
        }
        <NoResultsFound display={noResultsFound} />
      </TabPanel>

    );
}