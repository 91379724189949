import * as AnchorBlockSiteStyle1 from "../components/block_rendering/sitestyle1/AnchorBlock";
import * as FontAwesomeIconBlockSiteStyle1 from '../components/block_rendering/sitestyle1/FontAwesomeIconBlock';
import * as StickyMobileBlockSiteStyle1 from '../components/block_rendering/sitestyle1/StickyMobileBlock';
import * as PageHeaderBlockSiteStyle1 from '../components/block_rendering/sitestyle1/PageHeaderBlock';
import * as AddressBlockSiteStyle1 from '../components/block_rendering/sitestyle1/AddressBlock';
import * as CtaBlockSiteStyle1 from '../components/block_rendering/sitestyle1/CtaBlock';
import * as TableBlockSiteStyle1 from '../components/block_rendering/sitestyle1/TableBlock';
import * as TableHeadBlockSiteStyle1 from '../components/block_rendering/sitestyle1/TableHeadBlock';
import * as TableBodyBlockSiteStyle1 from '../components/block_rendering/sitestyle1/TableBodyBlock';
import * as TableRowBlockSiteStyle1 from '../components/block_rendering/sitestyle1/TableRowBlock';
import * as TableCellBlockSiteStyle1 from '../components/block_rendering/sitestyle1/TableCellBlock';
import * as TableHeaderBlockSiteStyle1 from '../components/block_rendering/sitestyle1/TableHeaderBlock';
import * as GoogleMapBlockSiteStyle1 from '../components/block_rendering/sitestyle1/GoogleMapBlock';
import * as FooterBlockSiteStyle1 from '../components/block_rendering/sitestyle1/FooterBlock';
import * as DivBlockSiteStyle1 from '../components/block_rendering/sitestyle1/DivBlock';
import * as SpanBlockSiteStyle1 from '../components/block_rendering/sitestyle1/SpanBlock';
import * as ParagraphBlockSiteStyle1 from '../components/block_rendering/sitestyle1/ParagraphBlock';
import * as HeadingBlockSiteStyle1 from '../components/block_rendering/sitestyle1/HeadingBlock';
import * as RowBlockSiteStyle1 from '../components/block_rendering/sitestyle1/RowBlock';
import * as ColumnBlockSiteStyle1 from '../components/block_rendering/sitestyle1/ColumnBlock';
import * as ContactFormBlockSiteStyle1 from '../components/block_rendering/sitestyle1/ContactFormBlock';
import * as InputBlockSiteStyle1 from '../components/block_rendering/sitestyle1/InputBlock';
import * as SubmitButtonBlockSiteStyle1 from '../components/block_rendering/sitestyle1/SubmitButtonBlock';
import * as ImageBlockSiteStyle1 from '../components/block_rendering/sitestyle1/ImageBlock';
import * as FeaturedMessageBlockSiteStyle1 from '../components/block_rendering/sitestyle1/FeaturedMessageBlock';
import * as MaterialIconBlockStyle1 from '../components/block_rendering/sitestyle1/MaterialIconBlock';
import * as UnorderedListBlockStyle1 from '../components/block_rendering/sitestyle1/UnorderedListBlock';
import * as ListItemBlockStyle1 from '../components/block_rendering/sitestyle1/ListItemBlock';
import * as ExternalLinksBlockStyle1 from '../components/block_rendering/sitestyle1/ExternalLinksBlock';
import * as ContainerBlockStyle1 from '../components/block_rendering/sitestyle1/ContainerBlock';
import * as NapBlockStyle1 from '../components/block_rendering/sitestyle1/NapBlock';
import * as AddressDisplayBlockStyle1 from "../components/block_rendering/sitestyle1/AddressDisplayBlock";
import * as DividerBlockStyle1 from "../components/block_rendering/sitestyle1/DividerBlock";
import * as HoursBlockStyle1 from "../components/block_rendering/sitestyle1/HoursBlock";
import * as SocialIconsContainerBlockStyle1 from "../components/block_rendering/sitestyle1/SocialIconsContainerBlock";
import * as YearBlockStyle1 from "../components/block_rendering/sitestyle1/YearBlock";
import * as GoogleRecaptchaBlockStyle1 from "../components/block_rendering/sitestyle1/GoogleRecaptchaBlock";
import * as ImageCarouselBlockStyle1 from "../components/block_rendering/sitestyle1/ImageCarouselBlock";
import * as CardBlockStyle1 from "../components/block_rendering/sitestyle1/CardBlock";
import * as SpacerBlockStyle1 from "../components/block_rendering/sitestyle1/SpacerBlock";
import * as LocationHeadingBlockStyle1 from "../components/block_rendering/sitestyle1/LocationHeadingBlock";
import * as ServiceAreasDisplayBlockStyle1 from "../components/block_rendering/sitestyle1/ServiceAreasDisplayBlock";
import * as ReviewSummaryBlockStyle1 from "../components/block_rendering/sitestyle1/ReviewSummaryBlock";
import * as ReviewShowcaseBlockStyle1 from "../components/block_rendering/sitestyle1/ReviewShowcaseBlock";
import * as CheckinFormBlockStyle1 from "../components/block_rendering/sitestyle1/CheckinFormBlock";
import * as NavigationBlockStyle1 from "../components/block_rendering/sitestyle1/NavigationBlock";

import * as CustomBlock from "../components/block_rendering/CustomBlock";
import BlockId from "../types/enum/BlockId";

const mappings: { [key: string]: any } =  {
    "1-1": AnchorBlockSiteStyle1,
    "1-2": FontAwesomeIconBlockSiteStyle1,
    "1-3": StickyMobileBlockSiteStyle1,
    "1-4": PageHeaderBlockSiteStyle1,
    "1-5": AddressBlockSiteStyle1,
    "1-6": CtaBlockSiteStyle1,
    "1-7": GoogleMapBlockSiteStyle1,
    "1-8": FooterBlockSiteStyle1,
    "1-9": DivBlockSiteStyle1,
    "1-10": SpanBlockSiteStyle1,
    "1-11": TableBlockSiteStyle1,
    "1-12": TableHeadBlockSiteStyle1,
    "1-13": TableBodyBlockSiteStyle1,
    "1-14": TableRowBlockSiteStyle1,
    "1-15": TableCellBlockSiteStyle1,
    "1-16": TableHeaderBlockSiteStyle1,
    "1-17": ParagraphBlockSiteStyle1,
    "1-18": HeadingBlockSiteStyle1,
    "1-19": RowBlockSiteStyle1,
    "1-20": ColumnBlockSiteStyle1,
    "1-21": ContactFormBlockSiteStyle1,
    "1-22": InputBlockSiteStyle1,
    "1-23": SubmitButtonBlockSiteStyle1,
    "1-24": ImageBlockSiteStyle1,
    "1-25": FeaturedMessageBlockSiteStyle1,
    "1-26": MaterialIconBlockStyle1,
    "1-27": UnorderedListBlockStyle1,
    "1-28": ListItemBlockStyle1,
    "1-29": ExternalLinksBlockStyle1,
    "1-30": ContainerBlockStyle1,
    "1-31": NapBlockStyle1,
    "1-32": AddressDisplayBlockStyle1,
    "1-33": DividerBlockStyle1,
    "1-34": HoursBlockStyle1,
    "1-35": SocialIconsContainerBlockStyle1,
    "1-36": YearBlockStyle1,
    "1-37": GoogleRecaptchaBlockStyle1,
    "1-38": CardBlockStyle1,
    "1-39": ImageCarouselBlockStyle1,
    "1-40": SpacerBlockStyle1,
    "1-41": ReviewSummaryBlockStyle1,
    "1-42": ReviewShowcaseBlockStyle1,
    "1-43": LocationHeadingBlockStyle1,
    "1-44": ServiceAreasDisplayBlockStyle1,
    "1-45": CheckinFormBlockStyle1,
    "1-46": NavigationBlockStyle1
};

export function getBlockRenderer(siteStyle: number, enumId: BlockId | null | undefined, blockContentId: string | null | undefined) {

    if (siteStyle && enumId) {
        return mappings[`${siteStyle}-${enumId || 0}`];
    } else if(blockContentId) {
        return CustomBlock;
    }
}
