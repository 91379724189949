import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import PropertyEditorProps from "../../types/PropertyEditorProps";
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import PageStatus from "../../types/enum/PageStatus";
import { Editor as TextEditorProperty } from './TextEditorProperty';
import PropertyValueType from '../../types/enum/PropertyValueType';
import './AnchorConfigProperty.css';
import PageInfo from '../../types/PageInfo';
import Property from '../../types/Property';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import FormValidationError from '../common/FormValidationError';
import { isValidUrl } from '../../utils/urlHelpers';
import validateEmail from '../../utils/emailHelpers';
import validatePhone from '../../utils/phoneHelpers';
import { isSwapValue } from '../../utils/valueSwapHelpers';
import { getAllIdPropertyValues } from '../../utils/blockHelpers';
import { BaseProperty } from '../../utils/propertyHelpers';
import { FilePropertyEditor } from '../common/FilePropertyEditor';
import FileType from '../../types/enum/FileType';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileRecord from '../../types/FileRecord';
import { HelpTooltip } from '../common/HelpTooltip';

const fileDownloadType = "f";

export function Editor({property, propertyUpdated, page, pageList, isPageEditor, validationChanged, setShowBehavior, populatedDataSources, siteProperties}: PropertyEditorProps) {
    const [selectedType, setSelectedType] = useState<string | null>();
    const [selectedValue, setSelectedValue] = useState<string | null>();
    const [renderedValue, setRenderedValue] = useState<string | null>();
    const [selectedDestination, setSelectedDestination] = useState<{ label: string, route: string, id: string } | null>(null);
    const [sectionList, setSectionList] = useState<Array<{ BlockMappingId: string, Id: string}>>([]);
    const [selectedSection, setSelectedSection] = useState<{ BlockMappingId: string, Id: string } | null>(null);

    const [propertyNeedsUpdated, setPropertyNeedsUpdated] = useState(false);

    const externalItem = { "label": "External...", "route": '', "id": 'EXTERNAL' };
    const currentPageItem = { "label": "Current Page", "route": '', "id": 'CURRENTPAGE' };
    const fileDownloadItem = { "label": "File Download", "route": '', "id": 'FILEDOWNLOAD' };
    const privacyPageItem = { "label": "Privacy", "route": '/privacy', "id": 'PRIVACY' };
    const termsPageItem = { "label": "Terms of Service", "route": '/termsofservice', "id": 'TERMSOFSERVICE' };

    useEffect(() => {
        let anchorValues = JSON.parse(property?.Value || '{"t":"ex"}');
        let anchorRenderedValues = JSON.parse(property?.RenderedValue || '{"t":"ex"}');

        let destinationPage = null;
        let type = null;
        let value = null;
        let renderedValue = null;
        let section = null;

        // update old Type/Value format to new t/v
        if (anchorValues.hasOwnProperty("Type") || anchorValues.hasOwnProperty("Value"))
        {
            anchorValues = {
                t: anchorValues.Type,
                v: anchorValues.Value
            }
        }

        // handle legacy Type == 'page', Value == '#section-id'
        if (anchorValues.t === 'page') {
            if (anchorValues.v) {
                if (anchorValues.v.length > 0 && anchorValues.v[0] === '#'){
                    destinationPage = currentPageItem;
                    const isTopOfPage = anchorValues.v.length === 1;
                    section = { BlockMappingId: isTopOfPage ? topOfPageSection.BlockMappingId : '', Id: anchorValues.v.slice(1)};
                } else {
                    const pageInfo = pageList?.find(p => p.Id === page?.PageId);
                    destinationPage = destinationList.find(d => d.id === pageInfo?.Id);
                    section = pageInfo?.PageSections.find(s => anchorValues.v === ('#' + s.Id));
                }
            }
        }
        else if (anchorValues.t === 'email') {
            type = 'e';
            value = anchorValues.v;
            renderedValue = anchorRenderedValues.v;
        }
        else if (anchorValues.t === 'telephone') {
            type = 't';
            value = anchorValues.v;
            renderedValue = anchorRenderedValues.v;
        }
        else if (anchorValues.t === 'sms') {
            type = 's';
            value = anchorValues.v;
            renderedValue = anchorRenderedValues.v;
        }
        //handle legacy Type== "internal" Value:"/route"
        else if (anchorValues.t === 'internal') {
            if (anchorValues.v) {
                destinationPage = destinationList.find(p => p.route === anchorValues.v);
            }
        }
        //handle legacy Type== "external" Value:"url.com"
        else if (anchorValues.t === 'external')
        {
            type = 'ex';
            value = anchorValues.v;
            renderedValue = anchorRenderedValues.v;
        }
        // handle values in new format.
        else if (!anchorValues.t) {
            // internal guid page
            if (anchorValues.p) {
                const pageInfo = pageList?.find(p => p.Id === anchorValues.p);
                destinationPage = destinationList.find(d => d.id === pageInfo?.Id);

                if (anchorValues.s) {
                    section = pageInfo!.PageSections.find(s => anchorValues.s === s.BlockMappingId);
                }
            }
            // internal static page or 'current page'
            else if (anchorValues.v) {
                if(anchorValues.v.length > 0 && anchorValues.v[0] === '/') {
                    destinationPage = destinationList.find(p => p.route === anchorValues.v);
                } else if (anchorValues.v.length > 0 && anchorValues.v[0] === '#'){
                    destinationPage = currentPageItem;
                    const isTopOfPage = anchorValues.v.length === 1;
                    section = { BlockMappingId: isTopOfPage ? topOfPageSection.BlockMappingId : '', Id: anchorValues.v.slice(1)};
                } else {
                    value = anchorValues.v;
                    renderedValue = anchorRenderedValues.v;
                }
            }
        } else {
            //external
            type = anchorValues.t;
            value = anchorValues.v;
            renderedValue = anchorRenderedValues.v;
        }

        let initialValid = false;
        if (destinationPage) {
            changeDestination({ label: destinationPage.label, route: destinationPage.route, id: destinationPage.id });

            if (section) {
                setSelectedSection(section);
            }

            initialValid = true;
        }
        else if (type === fileDownloadType) {
            changeDestination(fileDownloadItem);
            if (value) {
                setSelectedValue(value);
                setRenderedValue(renderedValue);
            }

            initialValid = validateValue(type, value);
        }
        else {
            changeDestination(externalItem);

            if (type) {
                setSelectedType(type);
            }

            if (value) {
                setSelectedValue(value);
                setRenderedValue(renderedValue);
            }

            initialValid = validateValue(type, value);
        }

        setShowBehavior?.((selectedDestination && selectedDestination.id !== externalItem.id) || !type || type === 'ex');

        if (validationChanged) {
            validationChanged(initialValid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property])

    const typeList = [
        { label: "Link", value: "ex" },
        { label: "Email", value: "e" },
        { label: "Telephone", value: "t" },
        { label: "Text Message", value: "s"}
    ];

    const typeToolTipText =
    <>
        <div>Link: Takes the user to an external website.</div>
        <div>Email: Opens the users default email client with the "To" field populated.</div>
        <div>Telephone: Starts a phone call with the phone number provided. This type is best suited for mobile devices as support is limited on desktop computers.</div>
        <div>Text Message: Opens the users default text message client with the recipient populated. This type is best suited for mobile devices as support is limited on desktop computers.</div>
    </>

    const sortedPageList = pageList?.filter(p => p.StatusTypeId !== PageStatus.Deleted)
        .sort((a: PageInfo, b: PageInfo) => (a.Route > b.Route) ? 1 : -1);
    const destinationList = [
        externalItem, currentPageItem, fileDownloadItem
    ].concat(
        sortedPageList ? sortedPageList.map(p => { return { "label": p.Name, "route": p.Route, "id": p.Id } }) : []
    ).concat(
        [privacyPageItem, termsPageItem]
    );

    const topOfPageSection = { BlockMappingId: 'TOPOFPAGE', Id: '' };

    const changeDestination = (newDestination: { label: string, route: string, id: string } | null) => {
        setSelectedDestination(newDestination);
        setSelectedValue('');
        setRenderedValue('');
        if (newDestination?.id) {
            if (newDestination.id === externalItem.id) {
                setSelectedType('ex');
            } else {
                setSelectedType('');
                const pageInfo = pageList?.find(p => p.Id === newDestination.id)

                if (newDestination.id === currentPageItem.id) {
                    let ids = getAllIdPropertyValues(page?.BlockMappings ?? []).map((id) => {
                        return {
                            BlockMappingId: '',
                            Id: id
                        }
                    });
                    let sections = [topOfPageSection].concat(ids)
                    setSectionList(sections);
                    setSelectedSection(topOfPageSection);
                }
                else if (pageInfo) {
                    let sections = [topOfPageSection].concat(pageInfo.PageSections)
                    setSectionList(sections);
                    setSelectedSection(topOfPageSection);
                } else {
                    setSectionList([]);
                }
            }
        }
    }

    const changeDestinationAndSave = (newDestination: { label: string, route: string, id: string } | null) => {
        changeDestination(newDestination);

        if (newDestination) {
            setShowBehavior?.(newDestination.id !== externalItem.id || selectedType === 'ex' || selectedType === '');
        }

        setPropertyNeedsUpdated(true);
    }

    const typeChanged = (e: any) => {
        setSelectedType(e.target.value);
        setSelectedValue('');
        setRenderedValue('');

        setShowBehavior?.(e.target.value === 'ex');

        setPropertyNeedsUpdated(true);
    };

    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);

    const valueChanged = (property: Property) => {
        setSelectedValue(property.Value);
        setRenderedValue(property.RenderedValue);

        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            setPropertyNeedsUpdated(true);
        }, 300));
    };

    const clearFile = () => {
        setSelectedValue(null);
        setRenderedValue(null);
        setPropertyNeedsUpdated(true);
    }

    const fileSelected = (file: FileRecord) => {
        setSelectedValue(file.URL);
        setRenderedValue(file.URL);
        setPropertyNeedsUpdated(true);
    }

    const changeSection = (newSection: { Id: string, BlockMappingId: string } | null) => {
        setSelectedSection(newSection);
        setPropertyNeedsUpdated(true);
    }

    const formatSectionName = (name: string) => {
        if (name === '') {
            return 'Top of Page';
        }

        return name.replaceAll('-', ' ')
            .split(' ')
            .map((s) => s === "of" ? s : s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const validateValue = (type: string, value: string | null | undefined) => {

        if (!value) {
            return false;
        }

        if (isSwapValue(value)) {
            return true;
        }
        switch(type) {
            case 'ex':
                return isValidUrl(value);
            case 'e':
                return validateEmail(value);
            case 't':
            case 's':
                return validatePhone(value);
        }

        return false;
    }

    useEffect(() => {
        if (propertyNeedsUpdated) {
            let isValid = true;
            let newAnchorItem: any = {}
            let newAnchorRenderedItem: any = {}

            if (selectedDestination) {

                if (selectedDestination.id === externalItem.id) {
                    if (selectedType) {
                        newAnchorItem.t = selectedType;
                        newAnchorRenderedItem.t = selectedType;

                        if (selectedValue) {
                            newAnchorItem.v = selectedValue;
                            newAnchorRenderedItem.v = renderedValue;
                        }

                        isValid = validateValue(selectedType, selectedValue);
                    } else {
                        isValid = false;
                    }
                }
                else if (selectedDestination.id === currentPageItem.id) {

                    if (selectedSection) {
                        if (selectedSection.BlockMappingId === topOfPageSection.BlockMappingId) {
                            newAnchorItem.v = '#';
                            newAnchorRenderedItem.v = '#';
                        } else {
                            newAnchorItem.v = '#' + selectedSection.Id;
                            newAnchorRenderedItem.v = '#' + selectedSection.Id;
                        }
                    } else {
                        isValid = false;
                    }
                }
                else if (selectedDestination.id === fileDownloadItem.id) {
                    newAnchorItem.t = fileDownloadType;
                    if (selectedValue) {
                        newAnchorItem.v = selectedValue;
                        newAnchorRenderedItem.v = renderedValue;
                    } else {
                        isValid = false;
                    }
                }
                else if (selectedDestination.id === privacyPageItem.id) {
                    newAnchorItem.v = privacyPageItem.route;
                    newAnchorRenderedItem.v = privacyPageItem.route;
                    isValid = true;
                }
                else if (selectedDestination.id === termsPageItem.id) {
                    newAnchorItem.v = termsPageItem.route;
                    newAnchorRenderedItem.v = termsPageItem.route;
                    isValid = true;
                } else {
                    newAnchorItem.p = selectedDestination.id;
                    newAnchorRenderedItem.p = selectedDestination.id;
                    isValid = true;

                    if (selectedSection && selectedSection.BlockMappingId !== topOfPageSection.BlockMappingId) {
                        newAnchorItem.s = selectedSection.BlockMappingId;
                        newAnchorRenderedItem.s = selectedSection.BlockMappingId;
                    }
                }
            }

            const newVal = JSON.stringify(newAnchorItem);
            const newRenderedVal = JSON.stringify(newAnchorRenderedItem);

            if (property) {
                property.Value = newVal;
                property.RenderedValue = newRenderedVal;
                propertyUpdated(property);
            }

            setPropertyNeedsUpdated(false);
            if (validationChanged) {
                validationChanged(isValid);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyNeedsUpdated])

    let valueLabel = '';
    let valueValid = false;
    let valueValidationMessage = '';
    if (selectedType === 't' || selectedType === 's') {
        valueLabel = 'Telephone Number';
        valueValid = !!renderedValue && validatePhone(renderedValue);
        valueValidationMessage = "Telephone Number may not be valid.";
    }
    else if (selectedType === 'e') {
        valueLabel = 'Email Address';
        valueValid = !!renderedValue && validateEmail(renderedValue);
        valueValidationMessage = "Email Address may not be valid.";
    }
    else if (selectedType === 'ex') {
        valueLabel = 'External URL';
        valueValid = !!renderedValue && isValidUrl(renderedValue);
        valueValidationMessage = "External URL may not be valid.";
    }

    return (
        <>
            { isPageEditor &&
                <label>Destination</label>
            }
            <Autocomplete
                disableClearable={true}
                options={destinationList}
                value={selectedDestination as ({ label: string, route: string, id: string } | undefined)}
                sx={{ marginTop: '8px', marginBottom: '20px', width: 'calc(100% - 2px)' }}
                data-testid="DestinationAutocomplete"
                renderOption={(props, option) => {
                    return (
                        <>
                            <li {...props}>
                                {option.label}
                                <sub style={{ color: '#ADADAD'}}>
                                    &emsp;
                                    {option.route}
                                </sub>
                                { selectedDestination?.id === option.id &&
                                    <CheckIcon sx={{ color: '#1B1AFF', marginLeft: 'auto'}}/>
                                }
                            </li>

                            { option.id === externalItem.id && (
                                <Divider />
                            ) }
                        </>
                )}}
                renderInput={(params) => <TextField {...params}
                    placeholder="Select a destination..."
                    label={isPageEditor ? undefined : 'Destination'}
                />}
                onChange={(event, value) => {
                    changeDestinationAndSave(value);
                }}
                getOptionLabel={o => o.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            { (selectedDestination?.id === externalItem.id) &&
                <>
                    { isPageEditor &&
                        <div style={{position: 'relative'}}>
                            <label id="anchor-config-destination-target-label">Type</label>
                            <HelpTooltip tooltipText={typeToolTipText}></HelpTooltip>
                        </div>
                    }
                    <FormControl fullWidth>
                        { !isPageEditor &&
                            <InputLabel id="anchor-config-destination-target-label">Type</InputLabel>
                        }
                        <Select
                            data-testid="anchor-config-property-select"
                            className={isPageEditor ? 'mt1 mb2' : ''}
                            value={selectedType}
                            onChange={typeChanged}
                            labelId="anchor-config-destination-target-label"
                            label={isPageEditor ? undefined : 'Type'}
                        >
                            {typeList.map((type) =>
                                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {(selectedType === 't' || selectedType === 'e' || selectedType === 'ex' || selectedType === 's') &&
                        <>
                            { isPageEditor && valueLabel &&
                                <label>{valueLabel}</label>
                            }
                            <div style={{ marginTop: isPageEditor ? '0px' : '20px' }}>
                                <TextEditorProperty
                                    property={{
                                        ...BaseProperty,
                                        ValueTypeId: PropertyValueType.Text,
                                        Value: selectedValue ?? null,
                                        RenderedValue: renderedValue ?? null
                                    }}
                                    propertyUpdated={valueChanged}
                                    isPageEditor={isPageEditor}
                                    populatedDataSources={populatedDataSources}
                                    siteProperties={siteProperties}
                                    label={isPageEditor ? undefined : valueLabel}
                                    pageList={pageList}
                                />
                            </div>
                            <FormValidationError valid={valueValid} message={valueValidationMessage} />
                        </>
                    }
                </>
            }
            { (selectedDestination?.id && selectedDestination.id !== externalItem.id && sectionList && sectionList.length > 0) &&
                <>
                    { isPageEditor &&
                        <label>Page Section</label>
                    }
                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                        <Autocomplete
                            disableClearable={true}
                            options={sectionList}
                            value={selectedSection ?? undefined}
                            isOptionEqualToValue={(option: any, value: any) => {
                                return option.BlockMappingId === value.BlockMappingId
                                    && option.Id === value.Id;
                            }}
                            sx={{ marginTop: '8px', width: 'calc(100% - 2px)' }}
                            renderOption={(props, option) => {
                                const selected = selectedSection?.BlockMappingId === option.BlockMappingId
                                    && selectedSection?.Id === option.Id;
                                return (
                                <li {...props} >
                                    <span>{formatSectionName(option.Id)}</span>
                                    { option.BlockMappingId === topOfPageSection.BlockMappingId
                                        && <span className='default-pill'>Default</span>
                                    }
                                    { selected &&
                                        <CheckIcon sx={{ color: '#1B1AFF', marginLeft: 'auto'}}/>
                                    }
                                </li>
                                )}
                            }
                            renderInput={(params) => <TextField
                                {...params}
                                label={isPageEditor ? undefined : 'Page Section'}
                                placeholder="Select a page section..."
                            />
                            }
                            onChange={(event, value) => {
                                changeSection(value);
                            }}
                            getOptionLabel={(option) => formatSectionName(option.Id)}
                        ></Autocomplete>

                    </FormControl>
                </>
            }
            { selectedDestination?.id === fileDownloadItem.id &&
                <FilePropertyEditor
                    onPropertySave={(property: Property, file: FileRecord) => {}}
                    onFileSave={fileSelected}
                    onClearProperty={clearFile}
                    fileUrl={selectedValue ?? null}
                    label="File"
                    linkedPropertyId={null}
                    placeholderText='Click to set a file'
                    selectButtonText='SELECT A FILE'
                    clearButtonText='CLEAR FILE'
                    selectIcon={<FileOpenIcon sx={{ color: "white" }} />}
                    property={property}
                    isPageEditor={isPageEditor}
                    allowedFileTypes={[FileType.Image, FileType.Document]}
                    allowedSitePropertyValueTypeIds={[]}
                />
            }
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    const anchorValues = JSON.parse(property.Value || '{}');
    const anchorRenderedValues = JSON.parse(property.RenderedValue || '{}');

    if ("destination".includes(value)) {
        return true;
    }

    const anchorType = anchorRenderedValues.t;
    const isExternalUrl = anchorType === "ex" || !property.Value;
    const isExternalTelephone = anchorType === "t";
    const isExternalSMS = anchorType === "s";
    const isExternalEmail = anchorType === "e";

    if (isExternalUrl || isExternalEmail || isExternalTelephone || isExternalSMS) {
        if ("external...".includes(value)) {
            return true;
        }
        if ("type".includes(value)) {
            return true;
        }
    }
    if (isExternalUrl) {
        if ("link".includes(value)) {
            return true;
        }
        if ("external url".includes(value)) {
            return true;
        }
    }
    if (isExternalTelephone || isExternalSMS) {
        // Check telephone type in type dropdown, and telephone number label below it
        if ("telephone number".includes(value)) {
            return true;
        }
    }
    if (isExternalSMS) {
        if ("sms".includes(value)) {
            return true;
        }
    }
    if (isExternalEmail) {
        if ("email address".includes(value)) {
            return true;
        }
    }
    if (anchorValues.p) {
        if ("page section".includes(value)) {
            return true;
        }
        if (!anchorValues.s && "top of page".includes(value)) {
            return true;
        }
    }

    if ((anchorRenderedValues.Value || anchorRenderedValues.v)?.toLowerCase().includes(value)) {
        return true;
    }

    const pages = filterData?.pageList;

    if (pages && pages.length > 0) {
        if (!anchorValues.t && anchorValues.p) {

            //find page
            const page = pages.find(p => p.Id === anchorValues.p);

            if (page && page.Name.toLowerCase().includes(value)) {
                return true;
            }

            if (page && page.PageSections && page.PageSections.length > 0 && anchorValues.s) {

                const section = page.PageSections.find(s => anchorValues.s === s.BlockMappingId);

                if (section && section.Id.replaceAll('-', ' ').includes(value)) {
                    return true;
                }
            }
        }
    }

    return false;
}