import { Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import BuilderDialog from '../common/BuilderDialog';
import useApiPost from '../../hooks/useApiPost';
import ErrorMessage from '../common/ErrorMessage';
import SuccessMessage from "../common/SuccessMessage";
import useApiPutCallbackAsync from "../../hooks/useApiPutCallbackAsync";
import { ResponseError } from "../../utils/apiHelpers";
import TabPanel from '../common/TabPanel';
import SharedContentList from './SharedContentList';

export default function Templates() {
    //const [searchFilter, setSearchFilter] = useState(''); // ARR-1971
    const [page, setPage] = useState(1);
    const [templateType, setTemplateType] = useState(1201);
    const [deleteTemplateId, setDeleteTemplateId] = useState<string | null>(null);
    const [deleteTemplateError, setDeleteTemplateError] = useState<ResponseError | null>(null);
    const [showDeleteTemplateSuccess, setShowDeleteTemplateSuccess] = useState(false);

    const [searchRequest, setSearchRequest] = useState({
        //"Filter": "", // ARR-1971
        "StatusTypeIds": [1101],
        "TemplateTypeId": 1201,
        //"EndDate": new Date(), // ARR-1971
        "CreatedByUser": null,
        "Page": 1,
        "PageSize": 30
    });

    const [{ data: templates, isLoading: templatesLoading, isError: templatesError }]
        = useApiPost("/template/search", null, searchRequest);
    const [, deleteTemplateCallback] = useApiPutCallbackAsync("", []) as any;

    const refreshSearch = () => {
        setSearchRequest(r => {
            return { 
              ...r,
              Page: 1,
              EndDate: new Date() 
            }
        });
    };

    const submitDeleteTemplate = () => {
        setShowDeleteTemplateSuccess(false);
        if (deleteTemplateId) {
            setPage(1);
            deleteTemplateCallback({
                }, (res: string) => {
                    setShowDeleteTemplateSuccess(true);
                    setDeleteTemplateId(null);
                    refreshSearch();
                }, (err: ResponseError) => {
                    setDeleteTemplateError(err);
                },
                `/template/${deleteTemplateId}/status/1102`
            );
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTemplateType(newValue);
        setPage(1);

        setSearchRequest(r => {
            return { 
              ...r,
              TemplateTypeId: newValue,
              Page: 1,
              EndDate: new Date() 
            }
        });
      };

    return (
        <>
        <div style={{ display: "flex", flexDirection: "column", height: "calc(100% - 128px)", padding: "64px 0px 64px 32px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingRight: "32px" }}>
                <h5 className="headline5" style={{ margin: "16px 0px 16px 0px " }}>Templates</h5>
            </div>
            <Box id="templates-tabs-wrapper" sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '16px', marginRight: '32px' }}>
                    <Tabs value={templateType} onChange={handleTabChange} aria-label="Property tabs" TabIndicatorProps={{style: { background:'#cf1f2e' }}} >
                        <Tab label="Site" id="site-tab" value={1201} aria-controls="site-tab-pane" />
                        <Tab label="Page" id="page-tab" value={1202} aria-controls="page-tab-pane" />
                        <Tab label="Section" id="section-tab" value={1203} aria-controls="section-tab-pane" />
                        
                    </Tabs>
                </Box>
                {/* ARR-1971 */}
                {/* <div style={{ marginTop: "32px", marginBottom: "32px", paddingRight: "32px" }}>
                <SearchFilterInput
                    placeholder='Search Templates'
                    value={searchFilter}
                    id={'TemplatesSearchFilter'}
                    inputMaxWidth={500}
                    onChange={function (newValue: string, originalCase: string): void {
                        setSearchFilter(newValue);
                        setPage(1);
                        setSearchRequest(r => {
                            return { 
                              ...r, 
                              Page: 1,
                              Filter: newValue,
                              EndDate: new Date() 
                            }
                        });
                    }}
                    noResults={!templatesLoading && (templates === null || templates.Results.length == 0)}
                />
            </div> */}
            <TabPanel value={templateType} name="site-tab" index={1201}>
                <SharedContentList
                    list={templates}
                    listLoading={templatesLoading}
                    setSearchRequest={setSearchRequest}
                    handleDelete={setDeleteTemplateId}
                    page={page}
                    setPage={setPage}
                ></SharedContentList>
            </TabPanel>
            <TabPanel value={templateType} name="site-tab" index={1202}>
                <SharedContentList
                    list={templates}
                    listLoading={templatesLoading}
                    setSearchRequest={setSearchRequest}
                    handleDelete={setDeleteTemplateId}
                    page={page}
                    setPage={setPage}
                ></SharedContentList>
            </TabPanel>
            <TabPanel value={templateType} name="site-tab" index={1203}>
                <SharedContentList
                    list={templates}
                    listLoading={templatesLoading}
                    setSearchRequest={setSearchRequest}
                    handleDelete={setDeleteTemplateId}
                    page={page}
                    setPage={setPage}
                ></SharedContentList>
            </TabPanel>
        </div>
        <BuilderDialog 
            title="Delete Template" 
            message="Are you sure you wish to delete this template? This is a permanent action and cannot be undone."
            action={submitDeleteTemplate}
            actionName="Delete"
            open={deleteTemplateId != null}
            onClose={() => setDeleteTemplateId(null) }
            danger={true}
            showCancel={true}/>
        <ErrorMessage message={"Error searching templates"} visible={templatesError} duration={3000} />
        <ErrorMessage
          message={"Error deleting template."}
          dismissable={true}
          visible={deleteTemplateError != null}
          apiError={deleteTemplateError}
        />
        <SuccessMessage
          message={"Template Deleted!"}
          dismissable={true}
          duration={3000}
          visible={showDeleteTemplateSuccess}
        />
        </>
    )
}