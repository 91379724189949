import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore, stripHtml } from '../../../utils/blockHelpers';
import DangerousText from '../DangerousText';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

const isConfiguredProperly = (block: BlockMapping) => {
    return getPropertyValue(block.Properties, PropertyId.ContentHTML)
        ? true : false;
};

export function Block({page, block, children, builderProps, blockWrapperData}: BlockProps) {
    if (!isConfiguredProperly(block)) {
        return null;
    }

    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    return (
        <li {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
            {isChildrenPositionBefore(block) && children}
            <DangerousText html={content}/>
            {!isChildrenPositionBefore(block) && children}
        </li>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const contentHtml = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    if (contentHtml) {
        return <>{stripHtml(contentHtml)}</>;
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const contentHtml = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    return (!!contentHtml && (contentHtml.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}