import './SocialImagesStep.css';
import PropertyId from '../../../types/enum/PropertyId';
import { Editor as ImageProperty } from '../../property_editors/ImageProperty';
import Page from '../../../types/Page';
import Site from '../../../types/Site';
import Property from '../../../types/Property';
import { useEffect } from 'react';

export interface SocialImagesStepProps {
    site: Site;
    setFavicon: React.Dispatch<React.SetStateAction<string | null>>;
    setShareImage: React.Dispatch<React.SetStateAction<string | null>>;
    setSimulateShareImageFormatting: React.Dispatch<React.SetStateAction<boolean>>;
    templateData: Page | null;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    updatePreviewBlockId: React.Dispatch<React.SetStateAction<string | null>>;
}

const SocialImagesStep: React.FC<SocialImagesStepProps> = ({
    site,
    setFavicon,
    setShareImage,
    setSimulateShareImageFormatting,
    templateData,
    setTemplateData,
    setError,
    updatePreviewBlockId
}) => {
    const logoProperty = templateData?.SiteProperties.find(p => p.EnumId === PropertyId.Logo);
    const faviconProperty = templateData?.SiteProperties.find(p => p.EnumId === PropertyId.SiteFavicon);
    const shareImageProperty = templateData?.SiteProperties.find(p => p.EnumId === PropertyId.SiteShareImage);
    const logoValue = JSON.parse(logoProperty?.Value || '{}');
    const faviconValue = JSON.parse(faviconProperty?.Value || '{}');
    const shareImageValue = JSON.parse(shareImageProperty?.Value || '{}');

    useEffect(() => {
        if (faviconValue?.u) {
            setFavicon(faviconValue.u);
        } else {
            // init favicon to logo
            setFavicon(logoValue?.u);
        }
        updatePreviewBlockId(null);
    }, [logoValue, setFavicon, updatePreviewBlockId, faviconValue]);


    useEffect(() => {
        if (shareImageValue?.u) {
            setShareImage(shareImageValue.u);
        } else {
            // init share image to logo
            setShareImage(logoValue?.u);
            setSimulateShareImageFormatting(true);
        }
        updatePreviewBlockId(null);
    }, [logoValue, setShareImage, setSimulateShareImageFormatting, updatePreviewBlockId, shareImageValue]);

    const faviconUpdated = (property?: Property) => {
        if (faviconProperty) {
            faviconProperty.ValueUpdatedInWizard = true;
        }
        const faviconValue = JSON.parse(faviconProperty?.Value || '{}');
        setFavicon(faviconValue?.u);
        setTemplateData(Object.assign({}, templateData));
    };

    const shareImageUpdated = (property?: Property) => {
        if (shareImageProperty) {
            shareImageProperty.ValueUpdatedInWizard = true;
        }
        const shareImageValue = JSON.parse(shareImageProperty?.Value || '{}');
        setShareImage(shareImageValue?.u);
        setSimulateShareImageFormatting(false);
        setTemplateData(Object.assign({}, templateData));
    };

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Favicon image</h1>
                <p className="step-instructions">
                    By default, the logo image is used. You can replace it with a square image of at least 16x16.
                </p>
            </div>
            <ImageProperty siteId={site.Id} property={faviconProperty ?? null} propertyUpdated={faviconUpdated} setError={setError} pageList={[]} />
            <div className="wizard-step-header-container">
                <h1 className="headline4">Share image</h1>
                <p className="step-instructions">
                    This image appears when the site is shared on social media. The recommended size for this image is 1,200x630.
                </p>
            </div>
            <ImageProperty siteId={site.Id} property={shareImageProperty ?? null} propertyUpdated={shareImageUpdated} setError={setError} pageList={[]} />
        </>
    );
}

export default SocialImagesStep;