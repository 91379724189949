/* eslint-disable jsx-a11y/anchor-is-valid */
import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyObject, getDynamicAttributes } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import { containsValue } from '../../property_editors/NavigationProperty';
import BlockFilterData from '../../../types/BlockFilterData';
import { hasValidItems } from '../../../utils/navItemsHelpers';
import NavigationItemValue from '../../../types/NavigationItemValue';
import PageInfo from '../../../types/PageInfo';

export function Block(props: BlockProps) {
    const navItems = getPropertyObject(props.block.Properties, PropertyId.NavigationItems) as NavigationItemValue[];
    const pageList: PageInfo[] = props.builderProps?.pageList || [];

    if (navItems == null) {
        return <></>;
    }
    
    const renderItem = (item: NavigationItemValue, index: number) => {
        if (!item || !item.v) {
            return <></>;
        }

        return (
            <li key={index}>
                <a href={item.v} className={item.d === "b" ? "primary-color" : ""} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                    {item.i &&
                        <span className={item.i} aria-hidden={true}></span>
                    }
                    {item.l &&
                        <span>{item.l}</span>
                    }
                </a>
            </li>
        )
    };

    const folderHasItemsToRender = (c: NavigationItemValue[]) => {
        if (!c || c.length === 0) {
            return false;
        }
        return hasValidItems(c, pageList);
    };

    const renderItemOrList = (navItem: NavigationItemValue, index: number) => {
        if ( navItem.d !== "f") {
            return renderItem(navItem, index)
        }
        if (navItem.d === "f" && navItem.c && folderHasItemsToRender(navItem.c)) {
            return (
                <li tabIndex={0} key={index}>
                    <details className="dropdown">
                        <summary>
                            {navItem.i &&
                                <span className={navItem.i} aria-hidden={true}></span>
                            }
                            {navItem.l}
                        </summary>
                        <ul className="dropdown-content rounded-box p-2 shadow-xl nav-folder-list">
                            {navItem.c.map((navItem: NavigationItemValue, index: number) => renderItem(navItem, index))}
                        </ul>
                    </details>
                </li>
            );
        }
    };

    const renderListItems = () => {
        return navItems.map((navItem: NavigationItemValue, index: number) => { return renderItemOrList(navItem, index) });
    };

    return (
        <>
                <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: "nav-block hidden lg:inline-block", blockWrapperData: props.blockWrapperData})}>
                    <ul className="menu menu-horizontal rounded-box px-2 py-3 hidden lg:flex">
                        {renderListItems()}
                    </ul>
                </div>
                <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: `nav-block lg:hidden`, blockWrapperData: props.blockWrapperData, idSuffix: "-mobile"})}>
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth="2" 
                                    d="M4 6h16M4 12h8m-8 6h16" 
                                />
                            </svg>
                        </label>
                        <ul tabIndex={0} className="menu menu-md dropdown-content dropdown-right mt-3 p-2 shadow rounded-box w-52">
                            {renderListItems()}
                        </ul>
                    </div>
                </div>
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    const navItems = getPropertyObject(block.Properties, PropertyId.NavigationItems);

    return navItems != null && navItems.length > 0
        && (
            hasValidItems(navItems, [])
            || navItems.filter((navItem: NavigationItemValue) => navItem.d === "f" && navItem.c && hasValidItems(navItem.c, [])).length > 0
        )
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const property = block?.Properties.find(p => p.EnumId === PropertyId.NavigationItems);

    return defaultFilter(block, filterText)
        || (property !== undefined && property !== null 
            && containsValue(property, filterText, filterData?.propertyEditorFilterData));
}