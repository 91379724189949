import BlockMapping from '../../../types/BlockMapping';
import BlockTitle from "../BlockTitle";
import ResponsiveDeviceIconSet from "../ResponsiveDeviceIconSet";
import SiteEditorStore from "../SiteEditorStore";

export default function SiteEditorPropertiesBlockTitle() {
    const propertyTab = SiteEditorStore.useStoreState((state) => state.propertyTab);
    const blocksAndSections = SiteEditorStore.useStoreState((state) => state.blocksAndSections);
    const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);

    let selectedBlock: BlockMapping | null = null;

    if (selectedBlocks.length === 1 && blocksAndSections?.length > 0) {
      selectedBlock = selectedBlocks[0];
    }

    if (!selectedBlock || propertyTab !== 2) {
      return null;
    }

    return (
      <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "20px"
        }}>
        <BlockTitle 
          blockMapping={selectedBlock} 
          style={{ paddingRight: '6px' }} 
          className="headline6"
          />
        <ResponsiveDeviceIconSet blockMapping={selectedBlock}/>
      </div>
    );
}