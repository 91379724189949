import { useEffect, useContext } from "react";
import SiteEditorNavBar from './navbar/SiteEditorNavBar';
import SiteEditorPendingDialog from './SiteEditorPendingDialog';
import { useParams } from "react-router-dom";
import "./SiteEditor.css"
import SiteEditorProperties from "./right_sidebar/SiteEditorProperties";
import SiteEditorStore from "./SiteEditorStore";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from '../../UserContext';
import SiteEditorLoading from "./SiteEditorLoading";
import SiteEditorError from "./SiteEditorError";
import SiteEditorKeyListener from "./SiteEditorKeyListener";
import SiteEditorSideBar from "./left_sidebar/SiteEditorSideBar";
import SiteEditorPreview from "./SiteEditorPreview";
import BuilderStore from "../../BuilderStore";
import { usePreview } from 'react-dnd-preview'
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import TemplateBoxDragHandle from "./left_sidebar/blocks_and_sections/TemplateBoxDragHandle";

export const BlockDragPreview = () => {
    const preview = usePreview();
    const showCustomDragPreview = SiteEditorStore.useStoreState((state) => state.showCustomDragPreview);

    if (!preview.display || !showCustomDragPreview) {
      return null
    }

    const { item, style }: any = preview;

    return (
        <div style={{ background: 'white', paddingLeft: '1rem', paddingRight: '1rem', boxShadow: '0px 0px 20px rgba(0,0,0,.5)', ...style }}>
            <TemplateBoxDragHandle name={item?.name}></TemplateBoxDragHandle>
        </div>
    );
  }

export function SiteEditor() {
    const { siteId, pageId } = useParams<{siteId: string; pageId: string}>();
    const loadInitialData = SiteEditorStore.useStoreActions((actions) => actions.loadInitialData);
    const setSiteAndPageId = SiteEditorStore.useStoreActions((actions) => actions.setSiteAndPageId);
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);
    const setSiteId = BuilderStore.useStoreActions((actions) => actions.setSiteId);

    useEffect(() => {
        loadInitialData({
            getAccessTokenSilently,
            userSession: {
                currentUser,
                sessionId,
                correlationId
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAccessTokenSilently, currentUser, sessionId, correlationId]);

    useEffect(() => {
        setSiteAndPageId({ siteId: siteId || '', pageId: pageId || '' });
        setSiteId(siteId || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId, pageId]);

    return (
        <div id="sitePage" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <SiteEditorLoading />
            <SiteEditorError />
            <SiteEditorNavBar />
            <div style={{ height: "calc(100% - 48px)", display: "flex" }}>
                <DndProvider backend={HTML5Backend} context={window}>
                    <SiteEditorSideBar />
                    <SiteEditorPreview />
                    <BlockDragPreview />
                </DndProvider>
                <SiteEditorProperties />
            </div>
            <SiteEditorKeyListener />
            <SiteEditorPendingDialog />
        </div>
    );
}

function SiteEditorWrapper() {
    return (
        <SiteEditorStore.Provider>
            <SiteEditor />
        </SiteEditorStore.Provider>
    )
}

export default SiteEditorWrapper;