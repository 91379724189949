import { useState } from 'react';
import { Button, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getSanitizedUrlForBackgroundImage } from '../../utils/blockHelpers';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropertyValueType from "../../types/enum/PropertyValueType";
import Property from '../../types/Property';
import FileRecord from '../../types/FileRecord';
import BuilderStore from '../../BuilderStore';
import { FileGalleryDialog } from '../common/FileGalleryDialog';
import { findFileByUrl } from '../../utils/fileHelpers';
import FileType from '../../types/enum/FileType';
import { PropertyNameLabel } from './PropertyNameLabel';

export interface FilePropertyEditorProps {
    onPropertySave: (property: Property, file: FileRecord) => void,
    onFileSave: (file: FileRecord) => void,
    onClearProperty: () => void,
    fileUrl: string | null,
    linkedPropertyId: string | null,
    placeholderText: string,
    selectButtonText: string,
    clearButtonText: string,
    selectIcon: JSX.Element,
    property: Property | null,
    isPageEditor?: boolean,
    siteProperties?: Property[],
    allowedFileTypes: FileType[],
    allowedSitePropertyValueTypeIds?: PropertyValueType[],
    centered?: boolean,
    label?: string,
    maxFileSizeKb?: number
}

export function FilePropertyEditor({ property, isPageEditor, siteProperties
    , onPropertySave, onFileSave, onClearProperty, fileUrl, linkedPropertyId, placeholderText
    , allowedFileTypes, allowedSitePropertyValueTypeIds, centered = false
    , selectButtonText, clearButtonText, selectIcon, label, maxFileSizeKb }: FilePropertyEditorProps) {
    const [openFileGalleryDialog, setOpenFileGalleryDialog] = useState(false);
    const siteFiles = BuilderStore.useStoreState((state) => state.siteFiles);

    const selectedSiteProperty = siteProperties?.find(p => p.PropertyId === linkedPropertyId);

    const openDialog = () => {
        setOpenFileGalleryDialog(true);
    };

    const cancelDialog = () => {
        setOpenFileGalleryDialog(false);
    };

    const saveSelectedProperty = (property: Property, file: FileRecord) => {
        onPropertySave(property, file);
        setOpenFileGalleryDialog(false);
    }

    const saveSelectedFile = (file: FileRecord) => {
        onFileSave(file);
        setOpenFileGalleryDialog(false);
    }

    const clearFile = () => {
        onClearProperty();
    };

    const fileDeleted = (file: FileRecord) => {
        if (file.URL === fileUrl) {
            onClearProperty();
        }
    };

    let propertyFile = null;

    if (fileUrl) {
        propertyFile = findFileByUrl(fileUrl, siteFiles);
    }

    return (
        <>
            { isPageEditor && 
                <PropertyNameLabel property={property} labelOverride={label}></PropertyNameLabel>
            }
            <Box sx={ !isPageEditor ? {} : {
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                        marginBottom: "16px"
                    }}>
                <Box sx={{ display: "flex", justifyContent: centered ? "center" : "left" }}>
                    <Button
                        variant="outlined"
                        sx={{ 
                            margin: isPageEditor ? "0 0 0 0" : '0 0 32px 0', 
                            width: isPageEditor ? "56px" : "200px", 
                            height: isPageEditor ? "56px" : "200px", 
                            border: isPageEditor ? "1px solid #292929" : "1px solid rgba(0, 0, 0, 0.12)", 
                            minWidth: isPageEditor ? "56px" : undefined,
                            borderRadius: isPageEditor ? "3.5px" : undefined,
                            backgroundSize: "contain", 
                            backgroundImage: propertyFile ? `url('${getSanitizedUrlForBackgroundImage(propertyFile.PreviewURL)}')`: "", 
                            backgroundPosition: "center", 
                            backgroundRepeat: "no-repeat", 
                            backgroundColor: !fileUrl && isPageEditor ? "secondary.main" : undefined,
                            '&:hover': {
                                backgroundColor: !fileUrl && isPageEditor ? "secondary.main" : undefined,
                            }
                            }}
                        onClick={openDialog}>
                        { !fileUrl && !isPageEditor && <span>{selectButtonText}</span> }
                        { !fileUrl && isPageEditor && selectIcon }
                    </Button>
                </Box>
                { isPageEditor &&
                    <TextField 
                        sx={{ marginLeft: "8px" }}
                        value={selectedSiteProperty ? selectedSiteProperty.Name : (propertyFile ? propertyFile.FileName + propertyFile.FileExtension : placeholderText)} 
                        variant="outlined" 
                        onClick={openDialog}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth />
                }
                { isPageEditor &&
                    <DeleteOutlineIcon sx={{
                            cursor: "pointer",
                            color: "#CF1F2E",
                            fontSize: "25px",
                            marginLeft: "16px",
                            visibility: propertyFile || linkedPropertyId ? undefined : "hidden"
                        }} 
                        onClick={clearFile}
                    />
                }
            </Box>
            { fileUrl && !isPageEditor && 
                <Box sx={{ display: "flex", justifyContent: centered ? "center" : "left" }}>
                    <Button
                        variant="outlined"
                        sx={{ marginBottom: '32px', width: "200px" }}
                        onClick={clearFile}>
                        {clearButtonText}
                    </Button>
                </Box>
            }
            { openFileGalleryDialog && 
                <FileGalleryDialog 
                    siteProperties={siteProperties}
                    onFileDeleted={fileDeleted}
                    onCancel={cancelDialog}
                    allowSelectingFile={true}
                    selectedFileUrl={fileUrl}
                    selectedSitePropertyId={linkedPropertyId}
                    allowedSitePropertyValueTypeIds={allowedSitePropertyValueTypeIds}
                    onFileSelected={saveSelectedFile}
                    onSitePropertySelected={saveSelectedProperty}
                    allowedFileTypes={allowedFileTypes}
                    maxFileSizeKb={maxFileSizeKb}
                />
            }
        </>
    );
}
