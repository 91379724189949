import { Box } from '@mui/material';
import NavBar from '../common/NavBar';
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import CustomBlocks from './CustomBlocks';
import Templates from './Templates';

const blocksSection = 'customblocks';
const templatesSection = 'templates';

export default function SharedContentManager() {
    const { section } = useParams<{ section: string }>();
    const navigate = useNavigate();

    const menuStyle = { padding: "16px", '&:hover': { backgroundColor: "#EEEEEE" }, cursor: "pointer" };
    const activeStyle = { padding: "16px", backgroundColor: 'secondary.main', color: 'white' };

    useEffect(() => {
        if (!section) {
            goToCustomBlocks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section]);

    const goToCustomBlocks = () => {
        navigate(`/sharedcontentmanager/${blocksSection}`)
    };
    const goToTemplates = () => {
        navigate(`/sharedcontentmanager/${templatesSection}`)
    };

    return (
        <>
        <NavBar
            title="Shared Content Manager"
            zIndex={9999}
        />
        <div style={{ position: "relative", display: "flex", height: "calc(100% - 48px)" }}>
            <div style={{ flex: "0 0 336px", padding: "16px" }}>
                <h5 className="headline5" style={{ marginTop: "16px", marginBottom: "32px" }}>Shared Content</h5>
                <div>
                    <Box
                        onClick={goToCustomBlocks}
                        sx={section === blocksSection ? activeStyle : menuStyle }
                        className="subtitle2"
                        data-testid="custom-blocks-menu-item"
                    >
                        Custom Blocks
                    </Box>
                    <Box
                        onClick={goToTemplates}
                        sx={section === templatesSection ? activeStyle : menuStyle }
                        className="subtitle2"
                    >
                        Templates
                    </Box>
                </div>
            </div>
            <div style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
                { section === blocksSection && <CustomBlocks /> }
                { section === templatesSection && <Templates /> }
            </div>
        </div>
        </>
    )
}