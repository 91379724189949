import { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { Add } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BlockId from '../../../types/enum/BlockId';
import PropertyId from '../../../types/enum/PropertyId';
import BlockMappingStatusId from '../../../types/enum/BlockMappingStatusId';
import { getPropertyValue } from '../../../utils/blockHelpers';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Editor as TextEditorProperty } from '../../property_editors/TextEditorProperty';
import { Editor as AnchorConfigProperty } from '../../property_editors/AnchorConfigProperty';
import './CTAStep.css';
import { getAnchor } from "../../../utils/blockTemplates";
import { Editor as NumberProperty } from '../../property_editors/NumberProperty';
import BuilderDialog from "../../common/BuilderDialog";
import Page from '../../../types/Page';
import BlockMapping from '../../../types/BlockMapping';
import Property from '../../../types/Property';
import { v4 as uuidv4 } from 'uuid';

export interface CTAStepProps {
    templateData: Page;
    originalTemplateData: Page | BlockMapping | null;
    blockmapping: BlockMapping;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
    updatePreviewBlockId: React.Dispatch<React.SetStateAction<string | null>>;
}

function CTAStep({ templateData, originalTemplateData, blockmapping, setTemplateData, updatePreviewBlockId }: CTAStepProps) {
    const [messageOverridden, setMessageOverridden] = useState<boolean>();
    const [submessageOverridden, setSubmessageOverridden] = useState<boolean>();
    const [editButtonAnchorConfigProperty, setEditButtonAnchorConfigProperty] = useState<Property | null>(null);
    const [editButtonContentProperty, setEditButtonContentProperty] = useState<Property | null>(null);
    const [openEditButtonDialog, setOpenEditButtonDialog] = useState(false);
    const [buttonBeingEdited, setButtonBeingEdited] = useState<number | null>(null);
    const [openAddButtonDialog, setOpenAddButtonDialog] = useState(false);
    const [buttonBeingAdded, setButtonBeingAdded] = useState<BlockMapping | null>(null);
    const [addButtonAnchorConfigProperty, setAddButtonAnchorConfigProperty] = useState<Property | null>(null);
    const [addButtonContentProperty, setAddButtonContentProperty] = useState<Property | null>(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const findButtons = (template: Page) => {
        const buttons = [];

        if (template?.BlockMappings) {
            for (let block of template.BlockMappings) {
                // Find our block mapping for CTA based on the passed in version of the template
                // (the modified version, or the original version)
                if (block.Id === blockmapping?.Id && block.BlockMappings) {
                    for (let child of block.BlockMappings) {
                        if (child.EnumId === BlockId.Anchor) {
                            const displayType = getPropertyValue(child.Properties, PropertyId.DisplayType);
                            if (displayType === "Button")
                            {
                                buttons.push(child);
                            }
                        }
                    }
                }
            }
        }
        return buttons;
    };

    // Find the buttons in the current and original template
    const templateButtons = findButtons(templateData);
    const buttonList = [];

    const findBlockByEnumId = (data: Page | BlockMapping | null, enumId: number): BlockMapping | null => {
        if (data?.BlockMappings) {
            for (let block of data.BlockMappings) {
                if (block.EnumId === enumId) {
                    return block;
                }

                let foundChild = findBlockByEnumId(block, enumId);

                if (foundChild) {
                    return foundChild;
                }
            }
        }

        return null;
    };

    const updateFormHeaderIfNeeded = (btnContentProperty: Property, btnConfigProperty: Property) => {
        const anchorConfig = JSON.parse(btnConfigProperty.Value || '');

        // If we are linking on the page, and we find a form with a heading block,
        // update the form's heading to match the button's text
        
        const value = anchorConfig.Value ?? anchorConfig.v ?? '';

        if (anchorConfig.Type === 'page' // legacy page type
            || (value.length > 1 && value[0] === '#')) { // Current Page link in new format

            const formBlock = findBlockByEnumId(templateData, BlockId.Form);
            const formId = formBlock?.Properties?.find(p => p.EnumId === PropertyId.ID);

            if (`#${formId?.Value}` === anchorConfig.Value
                || `#${formId?.Value}` === value) {

                const headingBlock = findBlockByEnumId(formBlock, BlockId.Heading);

                if (headingBlock) {
                    const contentProperty = headingBlock.Properties?.find(p => p.EnumId === PropertyId.Content);

                    if (contentProperty) {
                        contentProperty.Value = btnContentProperty.Value;
                        contentProperty.RenderedValue = btnContentProperty.RenderedValue;
                        contentProperty.ValueUpdatedInWizard = true;
                    }
                }
            }
        }
    };

    const addButtonToTemplate = (newButton: BlockMapping) => {
        for (let block of templateData.BlockMappings) {
            if (block.Id === blockmapping?.Id) {
                for (let i = 0; i < block.BlockMappings!.length; i++) {
                    if (block.BlockMappings![i].EnumId === BlockId.Anchor) {
                        const displayType = getPropertyValue(block.BlockMappings![i].Properties, PropertyId.DisplayType);
                        if (displayType === "Button")
                        {
                            block.BlockMappings!.splice(i + 1, 0, newButton);
                            return;
                        }
                    }
                }

                // If we get here, the current location of block mappings is gone, so we are adding to the end
                block.BlockMappings!.push(newButton);
                return;
            }
        }
    };

    const updateButtonProperties = (btn: BlockMapping, content?: Property | null, anchorConfig?: Property | null) => {
        let contentProperty = btn.Properties.find(p => p.EnumId === PropertyId.Content);
        let anchorConfigProperty = btn.Properties?.find(p => p.EnumId === PropertyId.AnchorConfig);
        let googleAnalyticsEventProperty = btn.Properties?.find(p => p.EnumId === PropertyId.GoogleAnalyticsEvent);
        let googleAnalyticsLabelProperty = btn.Properties?.find(p => p.EnumId === PropertyId.GoogleAnalyticsLabel)
        if (contentProperty) {
            contentProperty.RenderedValue = content?.RenderedValue;
            contentProperty.Value = content?.Value;
            contentProperty.ValueUpdatedInWizard = true;
        }
        if (anchorConfigProperty) {
            anchorConfigProperty.RenderedValue = anchorConfig?.RenderedValue;
            anchorConfigProperty.Value = anchorConfig?.Value;
            anchorConfigProperty.ValueUpdatedInWizard = true;
        }
        if (googleAnalyticsLabelProperty) {
            googleAnalyticsLabelProperty.RenderedValue = "CTA " + content?.RenderedValue;
            googleAnalyticsLabelProperty.Value = "CTA " + content?.Value;
            googleAnalyticsLabelProperty.ValueUpdatedInWizard = true;
        }
        if (googleAnalyticsEventProperty) {
            googleAnalyticsEventProperty.Value = "cta_click";
            googleAnalyticsEventProperty.RenderedValue = "cta_click";
            googleAnalyticsEventProperty.ValueUpdatedInWizard = true;
        }
        if (contentProperty && anchorConfigProperty) {
            updateFormHeaderIfNeeded(contentProperty, anchorConfigProperty);
        }
    };

    const saveAddButton = (btn: BlockMapping, content?: Property | null, anchorConfig?: Property | null) => {
        updateButtonProperties(btn, content, anchorConfig);
        addButtonToTemplate(btn);
        setTemplateData({...templateData});
        cancelAdd();
    };

    const saveEditButton = (btn: BlockMapping, content: Property | null, anchorConfig: Property | null) => {
        updateButtonProperties(btn, content, anchorConfig);
        setTemplateData({...templateData});
        cancelEdit();
    };

    useEffect(() => {
        updatePreviewBlockId(blockmapping?.Id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // Look at the CTA's children for an anchor block that is of type telephone
    let phoneNumberBlock: BlockMapping | null = null;
    if (blockmapping?.BlockMappings) {
        for (let child of blockmapping.BlockMappings) {
            if (child.EnumId === BlockId.Anchor) {
                const anchorConfig = getPropertyValue(child.Properties, PropertyId.AnchorConfig);
                const configObj = anchorConfig != null ? JSON.parse(anchorConfig) : null;
                const displayType = getPropertyValue(child.Properties, PropertyId.DisplayType);
                if ((configObj?.Type === "telephone" ||  configObj?.t === 't') && displayType !== "Button")
                {
                    phoneNumberBlock = child;
                    break;
                }
            }
        }
    }

    const phoneNumberChecked = phoneNumberBlock?.StatusTypeId === BlockMappingStatusId.Active;

    const togglePhoneNumber = () => {
        if (phoneNumberBlock?.StatusTypeId === BlockMappingStatusId.Active) {
            phoneNumberBlock.StatusTypeId = BlockMappingStatusId.Inactive;
        }
        else if (phoneNumberBlock?.StatusTypeId === BlockMappingStatusId.Inactive) {
            phoneNumberBlock.StatusTypeId = BlockMappingStatusId.Active;
        }

        setTemplateData({...templateData});
    };

    const cloneObj = (obj?: Property | BlockMapping) => {
        return JSON.parse(JSON.stringify(obj));
    };

    const opacityProperty = blockmapping?.Properties?.find(p => p.EnumId === PropertyId.CtaOverlayOpacity);
    // Property configuration fix for when TemplateState does not have configuration value.
    if (opacityProperty)
    {
        opacityProperty.Configuration = opacityProperty.Configuration ?? '{"Min":0,"Max":100,"Step":1,"Type":"whole","Unit":"%"}';
    }

    const opacityUpdated = (property: Property) => {
        if (opacityProperty) {
            opacityProperty.ValueUpdatedInWizard = true;
        }
        setTemplateData({...templateData});
    }

    const findDivMessageBlock = (template: Page | BlockMapping | null, nthNumber: number) => {
        let foundNumber = 0;

        if (template?.BlockMappings) {
            for (let block of template.BlockMappings) {
                // Find our block mapping for CTA based on the passed in version of the template
                // (the modified version, or the original version)
                if (block.Id === blockmapping?.Id && block.BlockMappings) {
                    // Find the nth child div we are looking for
                    for (let child of block.BlockMappings) {
                        if (child.EnumId === BlockId.Div) {
                            foundNumber++;
                            if (foundNumber === nthNumber) {
                                return child;
                            }
                        }
                    }
                }
            }
        }
    };

    const messageBlock = findDivMessageBlock(templateData, 1);
    const messageContentProperty = messageBlock?.Properties.find(p => p.EnumId === PropertyId.ContentHTML);
    const submessageBlock = findDivMessageBlock(templateData, 2);
    const submessageContentProperty = submessageBlock?.Properties.find(p => p.EnumId === PropertyId.ContentHTML);
    const originalMessageBlock = findDivMessageBlock(originalTemplateData, 1);
    const originalMessageContentProperty = originalMessageBlock?.Properties.find(p => p.EnumId === PropertyId.ContentHTML);
    const originalSubmessageBlock = findDivMessageBlock(originalTemplateData, 2);
    const originalSubmessageContentProperty = originalSubmessageBlock?.Properties.find(p => p.EnumId === PropertyId.ContentHTML);

    // Set initial state of overrides
    if (messageOverridden === null && messageContentProperty) {
        setMessageOverridden(messageContentProperty?.RenderedValue !== originalMessageContentProperty?.RenderedValue);
    }
    if (submessageOverridden === null && submessageContentProperty) {
        setSubmessageOverridden(submessageContentProperty?.RenderedValue !== originalSubmessageContentProperty?.RenderedValue);
    }

    const updateMessage = (value?: string | null) => {
        if (messageContentProperty) {
            messageContentProperty.Value = value;
            messageContentProperty.RenderedValue = value;
            messageContentProperty.ValueUpdatedInWizard = true;
        }
        setTemplateData({...templateData});
    };

    const updateSubmessage = (value?: string | null) => {
        if (submessageContentProperty) {
            submessageContentProperty.Value = value;
            submessageContentProperty.RenderedValue = value;
            submessageContentProperty.ValueUpdatedInWizard = true;
        }
        setTemplateData({...templateData});
    };

    const updateOverrideMessage = () => {
        const override = !messageOverridden;
        setMessageOverridden(override);

        if (!override) {
            if (messageContentProperty) {
                messageContentProperty.Value = originalMessageContentProperty?.Value;
                messageContentProperty.RenderedValue = originalMessageContentProperty?.RenderedValue;
                messageContentProperty.ValueUpdatedInWizard = false;
            }
            setTemplateData({...templateData});
        }
    };

    const updateOverrideSubmessage = () => {
        const override = !submessageOverridden;
        setSubmessageOverridden(override);
        
        if (!override) {
            if (submessageContentProperty) {
                submessageContentProperty.Value = originalSubmessageContentProperty?.Value;
                submessageContentProperty.RenderedValue = originalSubmessageContentProperty?.RenderedValue;
                submessageContentProperty.ValueUpdatedInWizard = false;
            }
            setTemplateData({...templateData});
        }
    };

    for (let btn of templateButtons) {
        const config = getPropertyValue(btn.Properties, PropertyId.AnchorConfig);
        const configObj = config != null ? JSON.parse(config) : null;
        const content = getPropertyValue(btn.Properties, PropertyId.Content);
        
        buttonList.push({
            Id: btn.Id,
            Url: configObj.Value ?? configObj.v,
            Display: content
        });
    }

    const removeButton = (index: number) => {
        let btnIndex = 0;
        for (let block of templateData.BlockMappings) {
            if (block.Id === blockmapping?.Id) {
                for (let i = 0; i < block.BlockMappings!.length; i++) {
                    if (block.BlockMappings![i].EnumId === BlockId.Anchor) {
                        const displayType = getPropertyValue(block.BlockMappings![i].Properties, PropertyId.DisplayType);
                        if (displayType === "Button")
                        {
                            if (btnIndex === index) {
                                block.BlockMappings!.splice(i, 1);
                                setTemplateData({...templateData});
                                return;
                            }

                            btnIndex++;
                        }
                    }
                }
            }
        } 
    };

    const editButton = (index: number) => {
        const anchorConfigProperty = cloneObj(templateButtons[index].Properties?.find(p => p.EnumId === PropertyId.AnchorConfig));
        const contentProperty = cloneObj(templateButtons[index].Properties?.find(p => p.EnumId === PropertyId.Content));
        setEditButtonAnchorConfigProperty(anchorConfigProperty);
        setEditButtonContentProperty(contentProperty);
        setButtonBeingEdited(index);
        setOpenEditButtonDialog(true);
    };

    const cancelEdit = () => {
        setOpenEditButtonDialog(false);
        setEditButtonAnchorConfigProperty(null);
        setEditButtonContentProperty(null);
        setButtonBeingEdited(null);
    };

    const editButtonContentPropertyUpdated = (property: Property) => {
        setEditButtonContentProperty(Object.assign({}, editButtonContentProperty));
    };

    const editButtonAnchorConfigPropertyUpdated = (property: Property) => {
        setEditButtonAnchorConfigProperty(Object.assign({}, editButtonAnchorConfigProperty));
    };

    const addButton = () => {
        const addBtn = templateButtons.length ? cloneObj(templateButtons[0]) : getAnchor();

        if (templateButtons.length) {
            const anchorBlockMappingId = uuidv4();
            addBtn.Id = anchorBlockMappingId;
            addBtn.Properties = addBtn.Properties.map((prop: Property) => {
                return {
                    ...prop,
                    BlockMappingId: anchorBlockMappingId,
                    ValueUpdatedInWizard: true,
                }
            });
        }

        addBtn.BlockMappings = [];
        const config = addBtn.Properties?.find((p: Property) => p.EnumId === PropertyId.AnchorConfig);
        const configObj = JSON.parse(config?.RenderedValue || '');
        configObj.t = 'ex';
        configObj.v = '';
        const newConfigObj = JSON.stringify(configObj);
        if (config) {
            config.RenderedValue = newConfigObj;
            config.Value = newConfigObj;
            config.ValueUpdatedInWizard = true;
        }
        const content = addBtn.Properties?.find((p: Property) => p.EnumId === PropertyId.Content);
        if (content) {
            content.RenderedValue = '';
            content.Value = '';
            content.ValueUpdatedInWizard = true;
        }
        const displayType = addBtn.Properties?.find((p: Property) => p.EnumId === PropertyId.DisplayType);
        if (displayType) {
            displayType.RenderedValue = "Button";
            displayType.Value = "Button";
            displayType.ValueUpdatedInWizard = true;
        }
        setAddButtonAnchorConfigProperty(config || null);
        setAddButtonContentProperty(content || null);
        setButtonBeingAdded(addBtn);
        setOpenAddButtonDialog(true);
    };

    const cancelAdd = () => {
        setButtonBeingAdded(null);
        setAddButtonAnchorConfigProperty(null);
        setAddButtonContentProperty(null);
        setOpenAddButtonDialog(false);
    };

    const addButtonContentPropertyUpdated = (property: Property) => {
        setAddButtonContentProperty(Object.assign({}, addButtonContentProperty));
    };

    const addButtonAnchorConfigPropertyUpdated = (property: Property) => {
        setAddButtonAnchorConfigProperty(Object.assign({}, addButtonAnchorConfigProperty));
    };

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Customize CTA</h1>
                <p className="body1">The CTA let's users know where they are and what actions they can take.</p>
            </div>

            { phoneNumberBlock && 
                <div className="wizard-step-section">
                    <div className="headline6 mb2">Show phone number?</div>
                    <Switch checked={phoneNumberChecked} onChange={togglePhoneNumber} />
                </div>
            }

            {opacityProperty && 
                <div className="wizard-step-section">
                    <div className="headline6">Overlay Opacity</div>
                    <p className="body1 mt1 mb2">Adjust the contrast between CTA text and the CTA background.</p>
                    <NumberProperty property={opacityProperty} propertyUpdated={opacityUpdated} isPageEditor={false} pageList={[]} /> 
                </div>
            }

            {messageContentProperty && 
                <div className="wizard-step-section">
                    <div className="headline6">CTA Messages</div>
                    <p className="body1 mt1">By default, the CTA messages are dynamically driven. You can choose to override them here.</p>
                    <FormGroup className="mt2 mb2">
                        <FormControlLabel control={<Checkbox checked={messageOverridden} onChange={updateOverrideMessage} />} label="Override CTA message" />
                    </FormGroup>
                    {messageOverridden && <TextField id="outlined-basic" value={messageContentProperty?.RenderedValue ?? ''} onChange={(e) => updateMessage(e.target.value)} label="CTA Message" variant="filled" fullWidth /> }
                    {!messageOverridden && <TextField id="outlined-basic" value={originalMessageContentProperty?.RenderedValue ?? ''} label="CTA Message" variant="filled" disabled fullWidth /> }
                    {submessageContentProperty && 
                        <>
                        <FormGroup className="mt2 mb2">
                            <FormControlLabel control={<Checkbox checked={submessageOverridden} onChange={updateOverrideSubmessage} />} label="Override CTA submessage" />
                        </FormGroup>
                        {submessageOverridden && <TextField id="outlined-basic" value={submessageContentProperty?.RenderedValue ?? ''} onChange={(e) => updateSubmessage(e.target.value)} label="CTA Submessage" variant="filled" fullWidth /> }
                        {!submessageOverridden && <TextField id="outlined-basic" value={originalSubmessageContentProperty?.RenderedValue ?? ''} label="CTA Submessage" variant="filled" disabled fullWidth /> }
                        </>
                    }
                </div>
            }

            <div className="wizard-step-section" style={{ marginBottom: '32px' }}>
                <div className="headline6 mb2">CTA Buttons</div>
                <p className="body1">Use CTA buttons to invite users to send a text, make a phone call or visit a website.</p>
            </div>
                
            <Button
                variant="outlined"
                sx={{ marginBottom: '16px', width: fullScreen ? '100%' : 'inherit' }}
                startIcon={<Add/>}
                disabled={templateButtons.length >= 2}
                onClick={addButton}>
                ADD CTA BUTTON
            </Button>

            {buttonList.map((btn, index) => (
                <Box 
                    key={index} 
                    style={{ 
                        position: 'relative', 
                        maxWidth: '600px', 
                        marginTop: '16px', 
                        marginBottom: '16px',
                        display: 'grid',
                        gridAutoColumns: 'auto',
                        gridAutoFlow: 'column' 
                    }}
                >
                    <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
                        <span className="caption">{btn.Url}</span>
                        <br />
                        <span className="body1">{btn.Display}</span>
                    </Box>
                    <Box sx={{textAlign: 'right', position: 'relative', top: '5px'}}>
                        <Button className="button-edit-button" onClick={() => editButton(index)}>
                            <EditIcon /> Edit
                        </Button>
                        <DeleteOutlineIcon sx={{ height: '18px', color: '#B00020', position: 'relative', top: '3px', marginLeft: '32px', cursor: 'pointer'}} onClick={() => removeButton(index)} />
                    </Box>
                </Box>
            ))}

            {buttonBeingEdited !== null && 
                <BuilderDialog
                    title={"Edit CTA button"}
                    open={openEditButtonDialog}
                    aria-labelledby="responsive-dialog-title"
                    action={() => saveEditButton(templateButtons[buttonBeingEdited], editButtonContentProperty, editButtonAnchorConfigProperty)}
                    actionName="Save"
                    onClose={cancelEdit}
                    showCancel={true}
                >
                    <DialogContent
                        sx={{
                            overflow: 'visible'
                        }}>
                        <Box>
                            <Box sx={{marginTop: '40px' }}>
                                <TextEditorProperty property={editButtonContentProperty} propertyUpdated={editButtonContentPropertyUpdated} label="CTA button text" isPageEditor={false} pageList={[]} />
                            </Box>
                            <Box sx={{marginTop: '40px' }}>
                                <AnchorConfigProperty property={editButtonAnchorConfigProperty} propertyUpdated={editButtonAnchorConfigPropertyUpdated} page={templateData} pageList={[]} />
                            </Box>
                        </Box>
                    </DialogContent>
                </BuilderDialog>
            }

            {buttonBeingAdded !== null && 
                <BuilderDialog
                    title={"Create CTA button"}
                    open={openAddButtonDialog}
                    aria-labelledby="responsive-dialog-title"
                    action={() => saveAddButton(buttonBeingAdded, addButtonContentProperty, addButtonAnchorConfigProperty)}
                    actionName="Create"
                    onClose={cancelAdd}
                    showCancel={true}
                >
                    <DialogContent
                        sx={{
                            overflow: 'visible'
                        }}>
                        <Box>
                            <Box sx={{marginTop: '40px' }}>
                                <TextEditorProperty property={addButtonContentProperty} propertyUpdated={addButtonContentPropertyUpdated} label="CTA button text" isPageEditor={false} pageList={[]} />
                            </Box>
                            <Box sx={{marginTop: '40px' }}>
                                <AnchorConfigProperty property={addButtonAnchorConfigProperty} propertyUpdated={addButtonAnchorConfigPropertyUpdated} page={templateData} pageList={[]} />
                            </Box>
                        </Box>
                    </DialogContent>
                </BuilderDialog>
            }
        </>
    );
}

export default CTAStep;
