import { useState } from 'react';
import { TextField, Select, MenuItem, FormControlLabel, Switch }  from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Property from '../../types/Property';
import Type from '../../types/Type';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import PropertyValueType from '../../types/enum/PropertyValueType';
import './CustomPropertyForm.css';
import property_renderers from '../../utils/propertyRenderers';
import CustomPropertyUpdate from '../../types/CustomPropertyUpdate';

interface CustomPropertyFormProps {
    property: Property | null,
    valueTypes: Type[] | null,
    propertyCategories: Type[] | null,
    valuesUpdated: (values: CustomPropertyUpdate) => void,
}

const uncategorizedCategoryTypeId = -1;

export default function CustomPropertyForm({property, valueTypes, propertyCategories, valuesUpdated}: CustomPropertyFormProps) {
    const [propertyTitle, setPropertyTitle] = useState(property?.Name ?? "");
    const [propertyRequired, setPropertyRequired] = useState(property?.Required ?? false);
    const [propertyValueType, setPropertyValueType] = useState<number>(property?.ValueTypeId ? property.ValueTypeId as number : PropertyValueType.Text);
    const [propertyCategoryType, setPropertyCategoryType] = useState<number>(property?.CategoryTypeId ?? uncategorizedCategoryTypeId);
    const [propertyDefaultValue, setPropertyDefaultValue] = useState<string | null>(property?.Value ?? null);
    const [propertyDefaultRenderedValue, setPropertyDefaultRenderedValue] = useState<string | null>(property?.RenderedValue ?? null);

    const editing = property != null;

    const handleValuesUpdated = (title?: string | undefined, required?: boolean | undefined
        , valueTypeId?: number | undefined, categoryTypeId?: number | undefined | null, defaultValue?: string | null | undefined) => {

        let category = categoryTypeId === undefined ? propertyCategoryType : categoryTypeId;

        if (category === uncategorizedCategoryTypeId) {
            category = null;
        }

        const updatedValues = {
            title: title === undefined ? propertyTitle : title,
            required: required === undefined ? propertyRequired : required,
            valueTypeId: editing ? property.ValueTypeId : (valueTypeId === undefined ? propertyValueType : valueTypeId),
            categoryTypeId: category,
            defaultValue: defaultValue === undefined ? propertyDefaultValue : defaultValue
        };

        valuesUpdated(updatedValues);
    };

    const propertyUpdated = (p: Property) => {
        setPropertyDefaultValue(p.Value || null);
        setPropertyDefaultRenderedValue(p.RenderedValue || null);
        handleValuesUpdated(undefined, undefined, undefined, undefined, p.Value);
    };
    
    const PropertyRenderer = property_renderers[propertyValueType]?.Editor;
    const editorProps: PropertyEditorProps = {
        siteProperties: [],
        property: {
            ValueTypeId: propertyValueType,
            Value: propertyDefaultValue,
            RenderedValue: propertyDefaultRenderedValue,
            Configuration: null,
            ValueUpdatedInWizard: false,
            BlockMappingId: null,
            PropertyId: "",
            CategoryTypeId: propertyCategoryType,
            SiteId: null,
            PageId: null,
            Name: propertyTitle,
            Required: propertyRequired,
            Shared: false,
            PropertyOrder: 1,
        },
        isPageEditor: true,
        propertyUpdated: propertyUpdated,
        populatedDataSources: [],
        label: "",
        pageList: []
    };

    const handleTitleChange = (title: string) => {
        setPropertyTitle(title);
        handleValuesUpdated(title);
    };
    
    const handledRequiredChanged = (required: boolean) => {
        setPropertyRequired(required);
        handleValuesUpdated(undefined, required);
    };

    const handleValueTypeChanged = (valueType: number) => {
        setPropertyValueType(valueType);
        handleValuesUpdated(undefined, undefined, valueType);
    };

    const handleCategoryChanged = (category: number) => {
        setPropertyCategoryType(category);
        handleValuesUpdated(undefined, undefined, undefined, category);
    };
    
    const valueTypeName = valueTypes?.find(v => v.Id === propertyValueType)?.Name;

    return (
       <div>
            <div style={{ marginBottom: "32px" }}>
                <label htmlFor="property-title">Property Title</label>
                <TextField 
                    id="property-title" 
                    value={propertyTitle} 
                    onChange={(e) => handleTitleChange(e.target.value) } 
                    label="" 
                    variant="outlined" 
                    fullWidth 
                    sx={{ marginTop: "8px" }}
                    inputProps={{ maxLength: 200 }}
                    color="secondary" 
                />
            </div>
            <div style={{ marginBottom: "32px", marginLeft: "12px" }}>
                <FormControlLabel 
                    control={<Switch 
                        checked={propertyRequired}
                        onChange={(e) => handledRequiredChanged(e.target.checked)} 
                        color="secondary" 
                    />} 
                    label="Property is required" 
                />
            </div>
            <div style={{ marginBottom: "32px" }}>
                <label htmlFor="property-type">Property Type</label>
                { editing && 
                    <TextField 
                        id="property-type" 
                        value={valueTypeName} 
                        disabled={true}
                        label="" 
                        variant="outlined" 
                        fullWidth 
                        sx={{ marginTop: "8px" }}
                        inputProps={{ maxLength: 200 }}
                        color="secondary" 
                    />
                }
                { !editing && 
                    <Select
                        id="property-type"
                        fullWidth
                        value={valueTypes && valueTypes.length > 0 ? (propertyValueType ? propertyValueType.toString() : (PropertyValueType.Text as number).toString()) : ''}
                        color="secondary"
                        onChange={(e: SelectChangeEvent) => handleValueTypeChanged(parseInt(e.target.value))}
                        sx={{ marginTop: "8px" }}
                    >
                        {valueTypes?.filter((v) => v.Id === PropertyValueType.Text).map((v) => <MenuItem value={v.Id} key={v.Id}>{v.Name}</MenuItem>)}
                    </Select>
                }
            </div>
            <div style={{ marginBottom: "32px" }}>
                <label>Default Value</label>
                <PropertyRenderer {...editorProps} />
            </div>
            <div style={{ marginBottom: "32px" }}>
                <label htmlFor="property-category">Category</label>
                <Select
                    id="property-category"
                    fullWidth
                    value={propertyCategories && propertyCategories.length > 0 && propertyCategoryType ? propertyCategoryType.toString() : ""}
                    color="secondary"
                    onChange={(e: SelectChangeEvent) => { handleCategoryChanged(parseInt(e.target.value))}}
                    sx={{ marginTop: "8px" }}
                >
                    <MenuItem value={uncategorizedCategoryTypeId} key={uncategorizedCategoryTypeId}>Uncategorized</MenuItem>
                    {propertyCategories?.map((v) => <MenuItem value={v.Id} key={v.Id}>{v.Name}</MenuItem>)}
                </Select>
            </div>
       </div> 
    )
}