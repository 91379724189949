import NavigationItemValue from "../types/NavigationItemValue";
import Property from "../types/Property";

export const GLOBAL_NAVIGATION_PROPERTY_ID = 'd3dfc224-0b63-4742-9cd8-6e78246272ac';

export function pageExistsInGlobalNav(pageId: string, siteProperties: Property[]): boolean {
    
  const globalNavProp = siteProperties?.find(p => p.PropertyId === GLOBAL_NAVIGATION_PROPERTY_ID);
  
  if (!globalNavProp) {
    return false;
  }

  const navItems: Array<NavigationItemValue> = JSON.parse(globalNavProp.Value || "[]");

  return !!navItems.find(n => n.p === pageId
    || n.c?.find(c => c.p === pageId));
}