import NavBar from "../../common/NavBar";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import "./SiteEditorNavBar.css";
import Site  from '../../../types/Site';
import PublishMenu from "./PublishMenu";
import VersionsMenu from "./versions_menu/VersionsMenu";
import { useEffect, useState } from "react";
import useApiPostCallbackAsync from "../../../hooks/useApiPostCallbackAsync";
import InfoMessage from "../../common/InfoMessage";
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import SiteEditorStore from "../SiteEditorStore";

const POLLING_INTERVAL = 2000;
const MAX_POLL_COUNT = 90;

function SiteEditorNavBar() {
    const [queueMessage, setQueueMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showPublishSuccessToast, setShowPublishSuccessToast] = useState(false);
    const [showPublishQueuedToast, setShowPublishQueuedToast] = useState(false);
    const [showPublishError, setShowPublishError] = useState(false);
    const [showStatusError, setShowStatusError] = useState(false);
    const [pollingSite, setPollingSite] = useState(false);
    const [pollCount, setPollCount] = useState(0);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const [currentLastPublishedDate, setCurrentLastPublishedDate] = useState(site?.LastPublishedDate ?? null);
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const refreshSite = SiteEditorStore.useStoreActions((actions) => actions.refreshSite);
    
    const [{ errorResponse: versionError }, publishCallback] = useApiPostCallbackAsync(`/site/${site?.Id}/version`, null) as any;
    const [{ errorResponse: statusError }, statusCallback] = useApiPostCallbackAsync(`/sites/${site?.Id}/status`, null) as any;

    const resetPublishMenuState = () => {
        setShowPublishError(false);
        setShowStatusError(false);
        setShowPublishSuccessToast(false);
        setShowPublishQueuedToast(false);
        setPollCount(0);
    };

    useEffect(() => {
        let sitePollingTimeout: any;

        if (pollingSite && pollCount < MAX_POLL_COUNT) {
            sitePollingTimeout = setTimeout(() => {
                refreshSite();
            }, POLLING_INTERVAL);

        }

        return () => sitePollingTimeout && clearTimeout(sitePollingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollingSite, pollCount, setPollCount]);
    
    const startUpdateStatus = (
        site: Site,
        data: { Status: number, PublishedVersionId: string | null, RedirectUrl: string | null }) => {

        setCurrentLastPublishedDate(site.LastPublishedDate);
        statusCallback(data, () => {
            setShowPublishQueuedToast(true);
            setPollingSite(true);
            refreshSite();
        }
        , () => { setShowStatusError(true) });
    };

    useEffect(() => {
        if (site && pollingSite) {
            if (site.LastPublishedDate !== null 
                && (currentLastPublishedDate === null ||
                    site.LastPublishedDate > currentLastPublishedDate)) {
                setPollingSite(false);
                setPollCount(0);
                setCurrentLastPublishedDate(site.LastPublishedDate);
                setShowPublishQueuedToast(false);
                setShowPublishSuccessToast(true);
            } else {
                setPollCount(pollCount + 1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site]);
    
    const updateStatus = async (data: { Status: number, PublishedVersionId: string | null, RedirectUrl: string | null }
        , queueMessage: string, successMessage: string, pollSite: boolean = false) => {
        // we currently can't have a queued and success message as we can't determine when the
        // publish has finished, so for now show the queued message as success.
        // TODO: We will implement a way to know when the publish has succeeded regardless of status
        setQueueMessage(queueMessage);
        //setSuccessMessage("Site unpublished!");
        setSuccessMessage(successMessage);
        //resetPublishMenuState();

        if (pollSite && site) {
            // Retrieve latest site record first so we can compare against updates
            // to know when the publish has succeeded
            const latestSite = await refreshSite();
            startUpdateStatus(latestSite, data);
        } else {
            statusCallback(data, async () => {
                await refreshSite();
                setShowPublishSuccessToast(true);
            });
        }
    };

    const openSite = (fullRoute: boolean = false) => {
        let domain = site?.Domain;
        let route = "";

        if (fullRoute && page) {
            route = page.Url.replace(site?.Domain ?? "", "");
        }

        if ((window as any).CONFIG.UseDomainQueryString) {
            window.open(`${(window as any).CONFIG.RendererBaseUrl}?domain=${domain}${route ? "&route=" + route : ""}`, "_blank");
        } else {
            window.open(`https://${domain}${route}`, "_blank");
        }
    };

    const getTitle = () => {
        return (
            <span 
                onClick={() => openSite(true)}
                style={{ cursor: "pointer" }}
                >
                {page?.Url ?? site?.Domain}
                <Box sx={{ position: "relative", display: "inline-block", top: "-6px", left: "5px", fontSize: "11px" }}>
                    <OpenInNewIcon fontSize="inherit" />
                </Box>
            </span>
        );
    };

    return (
        <>
            <NavBar
                title={getTitle()}
                rightContent={
                    <>
                        <VersionsMenu
                            setQueueMessage={setQueueMessage}
                            setSuccessMessage={setSuccessMessage}
                            setShowPublishQueued={setShowPublishQueuedToast}
                            setShowPublishError={setShowPublishError}
                            setPollingSite={setPollingSite}
                            setCurrentLastPublishedDate={setCurrentLastPublishedDate}
                            resetPublishMenuState={resetPublishMenuState}
                            />
                        <PublishMenu
                            viewSite={() => openSite() }
                            setQueueMessage={setQueueMessage}
                            setSuccessMessage={setSuccessMessage}
                            setShowPublishQueuedToast={setShowPublishQueuedToast}
                            setShowPublishError={setShowPublishError}
                            setPollingSite={setPollingSite}
                            currentLastPublishedDate={currentLastPublishedDate}
                            setCurrentLastPublishedDate={setCurrentLastPublishedDate}
                            publishCallback={publishCallback}
                            resetPublishMenuState={resetPublishMenuState}
                            updateStatus={updateStatus}
                            pollingSite={pollingSite}
                            />
                    </>
                }
                zIndex={9999}
            />
            <InfoMessage
                message={queueMessage}
                duration={3000}
                dismissable={true}
                visible={showPublishQueuedToast && !showPublishSuccessToast}
            />
            <SuccessMessage
                message={successMessage}
                dismissable={true}
                duration={3000}
                visible={showPublishSuccessToast}
            />
            <ErrorMessage
                message="We've run into an issue while publishing the site. Please try again later."
                duration={15000}
                visible={showPublishError}
                apiError={versionError}
            />
            <ErrorMessage
                message="We've run into an issue while unpublishing the site. Please try again later."
                duration={15000}
                visible={showStatusError}
                apiError={statusError}
            />
        </>
    );
}

export default SiteEditorNavBar;