import { MouseEventHandler, ReactNode, useState } from 'react';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import { Switch, Dialog, DialogActions, DialogContent, Button, Box, Select, MenuItem, InputLabel } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ContactFormConfigProperty.css';
import { Editor as TextEditorProperty } from './TextEditorProperty';
import PropertyValueType from '../../types/enum/PropertyValueType';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import Property from '../../types/Property';
import PopulatedDataSource from '../../types/PopulatedDataSource';
import { BaseProperty } from '../../utils/propertyHelpers';
import PageInfo from '../../types/PageInfo';
import ContactFormConfig from '../../types/ContactFormConfig';

const configModel = '{"ConfirmationEmail": {"Enabled": false,"ReplyTo": "","Subject": "","Body": ""},"LeadEmail": {"ToRecipients": [],"BccRecipients": [],"ReplyTo": "","Subject": "","Body": "","From": ""},"GoogleAnalytics": {"Label": ""},"Reveal": {"LeadCode": ""},"API": {"Type": "","ServiceMinderKey": "","ServiceMinderNoteTitle": "","Url": "","Username": "","Password": ""}}';

export interface ContractConfigAccordionProps {
    name: string;
    children: ReactNode[];
    message?: string;
    expanded?: boolean;
}

function ContactConfigAccordion({ name, children, message, expanded=false }: ContractConfigAccordionProps) {
    return (
        <Accordion defaultExpanded={expanded} sx={{
            padding: "0px",
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ marginLeft: "8px" }} className="headline6">{name}</Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: "32px" }}>
                { message &&
                    <Box className="body1 mb4">{message}</Box>
                }
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export interface ContactConfigTextFieldProps {
    value?: string | null;
    renderedValue?: string | null;
    updated: (val?: string | null, renderedVal?: string | null) => void;
    label?: string;
    isPageEditor?: boolean;
    populatedDataSources: PopulatedDataSource[];
    siteProperties: Property[];
    isHtml?: boolean;
    deleted?: () => void;
    mb?: string;
    hideDelete?: boolean;
    placeholder?: string;
    pageList: PageInfo[]
}

function ContactConfigTextField({value, renderedValue, updated, label, isPageEditor
    , populatedDataSources, siteProperties, isHtml=false, deleted, mb, pageList, hideDelete=false, placeholder=''}: ContactConfigTextFieldProps) {

    mb = mb ?? "mb4";

    const propertyUpdated = (property: Property) => {
        updated(property.Value, property.RenderedValue);
    };

    return (
        <>
        {label && <label htmlFor="">{label}</label>}
        <Box className={`${mb} mt1`} sx={{ display: "flex", alignItems: "center", width: "100%"}}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <TextEditorProperty
                    property={{
                        ...BaseProperty,
                        ValueTypeId: isHtml ? PropertyValueType.HTML : PropertyValueType.Text,
                        Value: value,
                        RenderedValue: renderedValue
                    }}
                    propertyUpdated={propertyUpdated}
                    isPageEditor={isPageEditor}
                    populatedDataSources={populatedDataSources}
                    siteProperties={siteProperties}
                    placeholder={placeholder}
                    pageList={pageList}
                />
            </Box>
            {deleted &&
                <Box
                    sx={{
                        marginLeft: "8px",
                        marginTop: "8px",
                        color: "#CF1F2E",
                        minWidth: "24px",
                        cursor: hideDelete ? "" : "pointer"
                    }}
                    onClick={(hideDelete ? null : deleted) as MouseEventHandler}
                >
                    {!hideDelete && <DeleteOutlineOutlinedIcon /> }
                </Box>
            }
        </Box>
        </>
    );
}

export function Editor({property, propertyUpdated, label, isPageEditor, pageList, populatedDataSources = [], siteProperties = []}: PropertyEditorProps) {
    const [editorOpen, setEditorOpen] = useState(false);
    const [config, setConfig] = useState<ContactFormConfig | null>(null);
    const [renderedConfig, setRenderedConfig] = useState<ContactFormConfig | null>(null);
    const configuration = JSON.parse(property?.Configuration || '{}');

    const openEditor = () => {
        const cfg: ContactFormConfig = JSON.parse(property?.Value ?? configModel);
        const renderedCfg: ContactFormConfig = JSON.parse(property?.RenderedValue ?? configModel);
        cfg.LeadEmail = cfg.LeadEmail ?? {};
        renderedCfg.LeadEmail = renderedCfg.LeadEmail ?? {};
        cfg.LeadEmail.ToRecipients = cfg.LeadEmail.ToRecipients ?? [];
        renderedCfg.LeadEmail.ToRecipients = renderedCfg.LeadEmail.ToRecipients ?? [];
        cfg.LeadEmail.BccRecipients = cfg.LeadEmail.BccRecipients ?? [];
        renderedCfg.LeadEmail.BccRecipients = renderedCfg.LeadEmail.BccRecipients ?? [];

        // Set empty Type to "NONE" so that select will work with display value
        cfg.API = { ...cfg.API, Type: cfg?.API?.Type ? cfg.API.Type : "NONE" };

        setConfig(cfg);
        setRenderedConfig(renderedCfg);
        setEditorOpen(true);
    };

    const closeEditor = () => {
        setEditorOpen(false);
        setConfig(null);
        setRenderedConfig(null);
    };

    const updateConfigs = () => {
        setConfig({...config});
        setRenderedConfig({...renderedConfig});
    };

    const confirmationEmailEnabledChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (config?.ConfirmationEmail && renderedConfig?.ConfirmationEmail) {
            config.ConfirmationEmail.Enabled = e.target.checked;
            renderedConfig.ConfirmationEmail.Enabled = e.target.checked;
            updateConfigs();
        }
    };

    const confirmUpdate = () => {
        // Set NONE back to empty
        if (config?.API && renderedConfig?.API && property) {
            config.API.Type = config.API.Type === "NONE" ? "" : config.API.Type;
            renderedConfig.API.Type = config.API.Type === "NONE" ? "" : config.API.Type;
            property.Value = JSON.stringify(config);
            property.RenderedValue = JSON.stringify(renderedConfig);
            propertyUpdated(property);
            closeEditor();
        }
    };

    const textEditorProps = {isPageEditor, populatedDataSources, siteProperties, pageList};

    return (
        <>
            <label
                className="body1"
            >
                {property?.Name}
            </label>
            <Box className="mt1">
                <Button onClick={openEditor} variant="outlined" fullWidth>{configuration.ConfigButtonLabel || `Configure ${property?.Name}`}</Button>
            </Box>
            <Dialog
                fullScreen={false}
                open={editorOpen}
                onClose={() => {}}
                aria-labelledby="responsive-dialog-title"
                maxWidth='md'
                fullWidth={true}
                PaperProps={{
                    style: { borderRadius: '3.5px' }
                }}
                    >
                <DialogContent style={{padding:'16px'}}>
                    <h6 className="headline6" style={{ margin: 0 }}>Configure contact form</h6>
                    { config && renderedConfig &&
                    <Box className="contact-form-config-sections" sx={{ marginTop: "20px", maxHeight: "65vh", overflowY: "auto", overflowX: "hidden", height: "800px" }}>
                        <ContactConfigAccordion
                            name="Client Email"
                            message="The email that results from a user filling out the contact form."
                        >
                            <label htmlFor="">Recipient email addresses</label>
                            {config?.LeadEmail?.ToRecipients?.map((r, i) =>
                                <ContactConfigTextField
                                    key={i}
                                    value={config?.LeadEmail?.ToRecipients ? config.LeadEmail.ToRecipients[i] : null}
                                    renderedValue={renderedConfig?.LeadEmail?.ToRecipients ? renderedConfig.LeadEmail.ToRecipients[i] : null}
                                    updated={(val, renderedVal) => {
                                        if (config?.LeadEmail?.ToRecipients && val && renderedConfig?.LeadEmail?.ToRecipients && renderedVal) {
                                            config.LeadEmail.ToRecipients[i] = val;
                                            renderedConfig.LeadEmail.ToRecipients[i] = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    mb="mb2"
                                    deleted={() => {
                                        if (config?.LeadEmail?.ToRecipients && renderedConfig?.LeadEmail?.ToRecipients) {
                                            config.LeadEmail.ToRecipients.splice(i, 1);
                                            renderedConfig.LeadEmail.ToRecipients.splice(i, 1);
                                            updateConfigs();
                                        }
                                    }}
                                    hideDelete={i === 0}
                                    placeholder="recipient@revlocal.com"
                                    {...textEditorProps}
                                />
                            )}
                            <Box className="mb4 mt1">
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        if (config?.LeadEmail?.ToRecipients && renderedConfig?.LeadEmail?.ToRecipients) {
                                            config.LeadEmail.ToRecipients.push("");
                                            renderedConfig.LeadEmail.ToRecipients.push("");
                                            updateConfigs();
                                        }
                                    }}
                                    variant="text">
                                    Add Recipient
                                </Button>
                            </Box>
                            <label htmlFor="">BCC recipients</label>
                            {config?.LeadEmail?.BccRecipients?.map((r, i) =>
                                <ContactConfigTextField
                                    key={i}
                                    value={config?.LeadEmail?.BccRecipients ? config.LeadEmail.BccRecipients[i] : null}
                                    renderedValue={renderedConfig?.LeadEmail?.BccRecipients ? renderedConfig.LeadEmail.BccRecipients[i] : null}
                                    updated={(val, renderedVal) => {
                                        if (config?.LeadEmail?.BccRecipients && val && renderedConfig?.LeadEmail?.BccRecipients && renderedVal) {
                                            config.LeadEmail.BccRecipients[i] = val;
                                            renderedConfig.LeadEmail.BccRecipients[i] = renderedVal;
                                        }
                                        updateConfigs();
                                    }}
                                    mb="mb2"
                                    placeholder="bcc-recipient@revlocal.com"
                                    deleted={() => {
                                        if (config?.LeadEmail?.BccRecipients && renderedConfig?.LeadEmail?.BccRecipients) {
                                            config.LeadEmail.BccRecipients.splice(i, 1);
                                            renderedConfig.LeadEmail.BccRecipients.splice(i, 1);
                                            updateConfigs();
                                        }
                                    }}
                                    {...textEditorProps}
                                />
                            )}
                            <Box className="mb4 mt1">
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        if (config?.LeadEmail?.BccRecipients && renderedConfig?.LeadEmail?.BccRecipients) {
                                            config.LeadEmail.BccRecipients.push("");
                                            renderedConfig.LeadEmail.BccRecipients.push("");
                                            updateConfigs();
                                        }
                                    }}
                                    variant="text">
                                    Add BCC Recipient
                                </Button>
                            </Box>
                            <ContactConfigTextField
                                value={config?.LeadEmail?.From}
                                renderedValue={renderedConfig?.LeadEmail?.From}
                                updated={(val, renderedVal) => {
                                    if (config?.LeadEmail && val && renderedConfig?.LeadEmail && renderedVal) {
                                        config.LeadEmail.From = val;
                                        renderedConfig.LeadEmail.From = renderedVal;
                                        updateConfigs();
                                    }
                                }}
                                label="From email address"
                                placeholder="no-reply@revlocal.com"
                                {...textEditorProps}
                            />
                            <ContactConfigTextField
                                value={config?.LeadEmail?.Subject}
                                renderedValue={renderedConfig?.LeadEmail?.Subject}
                                updated={(val, renderedVal) => {
                                    if (config?.LeadEmail && val && renderedConfig?.LeadEmail && renderedVal) {
                                        config.LeadEmail.Subject = val;
                                        renderedConfig.LeadEmail.Subject = renderedVal;
                                        updateConfigs();
                                    }
                                }}
                                label="Subject"
                                placeholder="Confirming your message to..."
                                {...textEditorProps}
                            />
                            <ContactConfigTextField
                                value={config?.LeadEmail?.Body}
                                renderedValue={renderedConfig?.LeadEmail?.Body}
                                updated={(val, renderedVal) => {
                                    if (config?.LeadEmail && val && renderedConfig?.LeadEmail && renderedVal) {
                                        config.LeadEmail.Body = val;
                                        renderedConfig.LeadEmail.Body = renderedVal;
                                        updateConfigs();
                                    }
                                }}
                                label="Body"
                                placeholder="Thank you for contacting..."
                                isHtml={true}
                                {...textEditorProps}
                            />
                        </ContactConfigAccordion>
                        <ContactConfigAccordion
                            name="Confirmation message"
                            message="An email automatically sent to the user confirming that their message was received."
                        >
                            <label htmlFor="">Enable confirmation message?</label>
                            <Box className="mb4">
                                <Switch
                                  checked={renderedConfig?.ConfirmationEmail?.Enabled}
                                  onChange={confirmationEmailEnabledChanged}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {renderedConfig?.ConfirmationEmail?.Enabled &&
                                <>
                                <ContactConfigTextField
                                    value={config?.ConfirmationEmail?.ReplyTo}
                                    renderedValue={renderedConfig.ConfirmationEmail.ReplyTo}
                                    updated={(val, renderedVal) => {
                                        if (config?.ConfirmationEmail && val && renderedConfig?.ConfirmationEmail && renderedVal) {
                                            config.ConfirmationEmail.ReplyTo = val;
                                            renderedConfig.ConfirmationEmail.ReplyTo = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="Reply to email address"
                                    placeholder="no-reply@revlocal.com"
                                    {...textEditorProps}
                                />
                                <ContactConfigTextField
                                    value={config?.ConfirmationEmail?.Subject}
                                    renderedValue={renderedConfig.ConfirmationEmail.Subject}
                                    updated={(val, renderedVal) => {
                                        if (config?.ConfirmationEmail && val && renderedConfig?.ConfirmationEmail && renderedVal) {
                                            config.ConfirmationEmail.Subject = val;
                                            renderedConfig.ConfirmationEmail.Subject = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="Subject"
                                    placeholder="Confirming your message to..."
                                    {...textEditorProps}
                                />
                                <ContactConfigTextField
                                    value={config?.ConfirmationEmail?.Body}
                                    renderedValue={renderedConfig.ConfirmationEmail.Body}
                                    updated={(val, renderedVal) => {
                                        if (config?.ConfirmationEmail && val && renderedConfig?.ConfirmationEmail && renderedVal) {
                                            config.ConfirmationEmail.Body = val;
                                            renderedConfig.ConfirmationEmail.Body = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="Body"
                                    placeholder="Thank you for contacting..."
                                    isHtml={true}
                                    {...textEditorProps}
                                />
                            </>
                            }
                        </ContactConfigAccordion>
                        <ContactConfigAccordion name="Advanced">
                            <ContactConfigTextField
                                value={config?.GoogleAnalytics?.Label}
                                renderedValue={renderedConfig?.GoogleAnalytics?.Label}
                                updated={(val, renderedVal) => {
                                    if (config?.GoogleAnalytics && val && renderedConfig?.GoogleAnalytics && renderedVal) {
                                        config.GoogleAnalytics.Label = val;
                                        renderedConfig.GoogleAnalytics.Label = renderedVal;
                                        updateConfigs();
                                    }
                                }}
                                label="Google analytics label"
                                placeholder="Contact Form"
                                {...textEditorProps}
                            />
                            <Box className="mb4">
                                <label htmlFor="">API type</label>
                                <FormControl fullWidth sx={{ marginTop: "8px" }}>
                                  <InputLabel id="apitype-label"></InputLabel>
                                  <Select
                                    labelId="apitype-label"
                                    id="apitype-select"
                                    value={config?.API?.Type}
                                    label={undefined}
                                    onChange={(e) => {
                                        if (config?.API && renderedConfig?.API) {
                                            config.API.Type = e.target.value;
                                            renderedConfig.API.Type = e.target.value;
                                            updateConfigs();
                                        }
                                    }}
                                  >
                                    <MenuItem value={"NONE"}>Select a type</MenuItem>
                                    <MenuItem value={"JsonPost"}>JSON Post</MenuItem>
                                    <MenuItem value={"FormEncodedPost"}>Form Encoded Post</MenuItem>
                                    <MenuItem value={"Get"}>Get</MenuItem>
                                    <MenuItem value={"ServiceMinder"}>Service Minder</MenuItem>
                                  </Select>
                                </FormControl>
                            </Box>
                            { config?.API?.Type === 'ServiceMinder' &&
                                <>
                                <ContactConfigTextField
                                    value={config?.API?.ServiceMinderKey}
                                    renderedValue={renderedConfig?.API?.ServiceMinderKey}
                                    updated={(val, renderedVal) => {
                                        if (config.API && renderedConfig.API) {
                                            config.API.ServiceMinderKey = val;
                                            renderedConfig.API.ServiceMinderKey = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="Service Minder key"
                                    {...textEditorProps}
                                />
                                <ContactConfigTextField
                                    value={config?.API?.ServiceMinderNoteTitle}
                                    renderedValue={renderedConfig?.API?.ServiceMinderNoteTitle}
                                    updated={(val, renderedVal) => {
                                        if (config.API && renderedConfig.API) {
                                            config.API.ServiceMinderNoteTitle = val;
                                            renderedConfig.API.ServiceMinderNoteTitle = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="Service Minder note title"
                                    {...textEditorProps}
                                />
                                </>
                            }
                            { config?.API?.Type && config.API.Type !== 'ServiceMinder' && config.API.Type !== "NONE" &&
                                <>
                                <ContactConfigTextField
                                    value={config?.API?.Url}
                                    renderedValue={renderedConfig?.API?.Url}
                                    updated={(val, renderedVal) => {
                                        if (config.API && renderedConfig.API) {
                                            config.API.Url = val;
                                            renderedConfig.API.Url = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="API URL"
                                    {...textEditorProps}
                                />
                                <ContactConfigTextField
                                    value={config?.API?.UserName}
                                    renderedValue={renderedConfig?.API?.UserName}
                                    updated={(val, renderedVal) => {
                                        if (config.API && renderedConfig.API) {
                                            config.API.UserName = val;
                                            renderedConfig.API.UserName = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="API username"
                                    {...textEditorProps}
                                />
                                <ContactConfigTextField
                                    value={config?.API?.Password}
                                    renderedValue={renderedConfig?.API?.Password}
                                    updated={(val, renderedVal) => {
                                        if (config.API && renderedConfig.API) {
                                            config.API.Password = val;
                                            renderedConfig.API.Password = renderedVal;
                                            updateConfigs();
                                        }
                                    }}
                                    label="API password"
                                    {...textEditorProps}
                                />
                                </>
                            }
                        </ContactConfigAccordion>
                    </Box>
                    }
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between", padding:'16px' }}>
                    <Button onClick={closeEditor} variant="text">Cancel</Button>
                    <Button onClick={confirmUpdate} variant="outlined" autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export function containsValue(property: Property, value: string) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const obj = JSON.parse(property.RenderedValue);
        return obj.ConfirmationEmail?.ReplyTo?.toLowerCase().includes(value)
            || obj.ConfirmationEmail?.Subject?.toLowerCase().includes(value)
            || obj.ConfirmationEmail?.Body?.toLowerCase().includes(value)
            || obj.LeadEmail?.ToRecipients?.filter((r: string) => r.toLowerCase().includes(value)).length > 0
            || obj.LeadEmail?.BccRecipients?.filter((r: string) => r.toLowerCase().includes(value)).length > 0
            || obj.LeadEmail?.ReplyTo?.toLowerCase().includes(value)
            || obj.LeadEmail?.Subject?.toLowerCase().includes(value)
            || obj.LeadEmail?.Body?.toLowerCase().includes(value)
            || obj.LeadEmail?.From?.toLowerCase().includes(value)
            || obj.GoogleAnalytics?.Label?.toLowerCase().includes(value)
            || obj.Reveal?.LeadCode?.toLowerCase().includes(value)
            || obj.API?.Type?.toLowerCase().includes(value)
            || obj.API?.ServiceMinderKey?.toLowerCase().includes(value)
            || obj.API?.ServiceMinderNoteTitle?.toLowerCase().includes(value)
            || obj.API?.Url?.toLowerCase().includes(value)
            || obj.API?.UserName?.toLowerCase().includes(value)
            || obj.API?.Password?.toLowerCase().includes(value);
    }
}