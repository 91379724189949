import { useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { UserContext } from './UserContext';
import AppRoutes from "./AppRoutes";
import ErrorBoundary from "./components/ErrorBoundary";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { AppBar, Toolbar, Typography } from "@mui/material";
import Loading from "./components/common/Loading";
import UserData from "./types/UserData";
import BuilderStore from "./BuilderStore";

const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#646464'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          top: "48px"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          top: "48px"
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#6200EE',
      light: '#BB86FC'
    },
    secondary: {
      main: '#1B1AFF'
    },
    error: {
      main: '#CF1F2E'
    }
  },
  zIndex: {
    modal: 10000
  }
});

const sessionIdVal = uuidv4();

const AppLoading = () => (
  <AppBar position="static" style={{ background: '#cf1f2e' }}>
    <Toolbar variant="dense">
        <Typography sx={{ fontSize: "24px", lineHeight: "24px" }}>Website Builder</Typography>
    </Toolbar>
    <Loading />
  </AppBar>
);

function App() {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const { currentUser, setCurrentUser, sessionId, setSessionId, correlationId, setCorrelationId } = useContext(UserContext);
  const loadInitialData = BuilderStore.useStoreActions((actions) => actions.loadInitialData);

  useEffect(() => {
    loadInitialData({
        getAccessTokenSilently,
        userSession: {
            currentUser,
            sessionId,
            correlationId
        }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, currentUser, sessionId, correlationId]);

  useEffect(() => {
    if (user && setCurrentUser) {
      setCurrentUser(user as UserData);
    }
    if (setSessionId) {
      setSessionId(sessionIdVal);
    }

    (window as any).pendo.initialize({
      visitor: {
        id:             user?.email, // Required if user is logged in, default creates anonymous ID
        email:          user?.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name:      user?.given_name + " " + user?.family_name // Recommended if using Pendo Feedback
        // role:         // Optional (i.e. Strategist, Designer, etc.) Might require additional mapping in Auth0

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: { //Everyone's account name should be “RevLocal”
        id:              'ACCOUNT-UNIQUE-ID',// Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        name:          'RevLocal'// Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  });

  useEffect(() => {
    if (setCorrelationId) {
      setCorrelationId(uuidv4());
    }
  }, [location, setCorrelationId]);

  const errorProps = {
    sessionId: sessionIdVal,
    correlationId
  };

  if (isLoading || !currentUser) {
    return <AppLoading />
  }

  return (
    <ErrorBoundary {...errorProps}>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <AppLoading />,
});
