import { useState, useEffect, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NavBar from '../common/NavBar';
import CloseIcon from '@mui/icons-material/Close';
import { Editor as ImageProperty } from './ImageProperty';
import { Slide } from './Slide';
import Property from '../../types/Property';
import { getFileNameFromUrl } from '../../utils/urlHelpers';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import ImageCarouselSlide from '../../types/ImageCarouselSlide';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const MAX_SLIDES = 10;

function ImageCarouselSlidesEditor({property, propertyUpdated, siteId, isPageEditor, pageList}: PropertyEditorProps) {
    const [slideBeingEdited, setSlideBeingEdited] = useState<ImageCarouselSlide | null>(null);
    const [slideIndexBeingEdited, setSlideIndexBeingEdited] = useState<number | null>(null);
    const [imageProperty, setImageProperty] = useState<any>(null);
    const [openAddSlideDialog, setOpenAddSlideDialog] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalButtonText, setModalButtonText] = useState<string>("");
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    const slidesConfig = JSON.parse(property?.RenderedValue ?? "[]");
    const slidesList = Array.isArray(slidesConfig) ? slidesConfig : [slidesConfig];
    const propertyConfig = JSON.parse(property?.Configuration ?? "{}");
    const showCaption = propertyConfig?.ShowCaption ?? true;
    const defaultImageConfiguration = JSON.stringify({
        ShowAlt: propertyConfig?.ShowAlt ?? true,
        ShowCaption: showCaption
    });

    const setImageRenderedValue = (RenderedValue: any) => {
        setImageProperty({
            RenderedValue,
            Configuration: defaultImageConfiguration
        });
    }

    const label = propertyConfig?.LabelOverride ?? "Slide";

    const updateSlidesList = (updatedList: any) => {
        let serializedSlides = JSON.stringify(updatedList);
        if (property) {
            property.Value = serializedSlides;
            property.RenderedValue = serializedSlides;
            propertyUpdated(property);
        }
    };

    const editSlide = (index: number) => {
        setSlideBeingEdited(slidesList[index]);
        setImageRenderedValue(JSON.stringify({ u: slidesList[index].u, a: slidesList[index].a }))
        setSlideIndexBeingEdited(index);
        setModalTitle(`Edit ${label}`);
        setModalButtonText("Save");
        setOpenAddSlideDialog(true);

    };
    const deleteSlide = (index: number) => {
        slidesList.splice(index, 1);
        updateSlidesList(slidesList);
    };

    const addSlide = () => {
        setSlideBeingEdited({});
        setImageRenderedValue(null);
        setSlideIndexBeingEdited(null);
        setModalTitle(`Add a New ${label}`);
        setModalButtonText("Create");
        setOpenAddSlideDialog(true);
    };

    const imagePropertyUpdated = (property: any, file: any) => {
        const imageConfig = JSON.parse(property.Value);

        if (slideBeingEdited && file) {
            slideBeingEdited.u = imageConfig.u;
            slideBeingEdited.t = file.PreviewURL;
            slideBeingEdited.a = imageConfig.a;
            setSlideBeingEdited({...slideBeingEdited});
        }
        else if (slideBeingEdited) {
            slideBeingEdited.a = imageConfig?.a;
            setSlideBeingEdited({...slideBeingEdited});
        }
    };

    const saveSlide = () => {
        if (slideIndexBeingEdited !== null) {
            slidesList[slideIndexBeingEdited] = slideBeingEdited;
            updateSlidesList(slidesList);
        } else {
            slidesList.push(slideBeingEdited);
            updateSlidesList(slidesList);
        }
        cancelEdit();
    };

    const cancelEdit = () => {
        setOpenAddSlideDialog(false);
    };

    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);
    const updateCaption = (text: string) => {
        if (slideBeingEdited) {
            slideBeingEdited.c = text;
        }
        if (updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            setSlideBeingEdited({...slideBeingEdited});
        }, 300));
    };

    const moveSlide = useCallback((dragIndex: number, hoverIndex: number) => {
        const slide = slidesList[dragIndex];
        slidesList.splice(dragIndex, 1);
        slidesList.splice(hoverIndex, 0, slide);
        updateSlidesList(slidesList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slidesList]);

    const renderSlide = (slide: any, index: number) => {
        return (<Slide key={index} index={index} id={index} slide={slide} moveSlide={moveSlide} editSlide={editSlide} deleteSlide={deleteSlide} data-testid={`slide-list-item-${slide?.u}`} />);
    };

    return (
        <div data-testid="edit-slides">
            <Button
                data-testid="add-slide-button"
                variant="outlined"
                startIcon={<Add/>}
                disabled={slidesList.length >= MAX_SLIDES}
                onClick={addSlide}
                sx={{ marginBottom: '32px', width: isMobileView ? '100%' : 'inherit' }}
            >
                Add {label}
            </Button>
            <Box data-testid="slide-list" sx={isPageEditor ? { marginTop: "40px", overflowY: "auto", maxHeight: "470px" } : {}}>
                <DndProvider backend={HTML5Backend} context={window}>
                    {slidesList.map((slide: any, i: number) => renderSlide(slide, i))}
                </DndProvider>
            </Box>

            {slideBeingEdited !== null &&
                <Dialog
                    fullScreen={isMobileView}
                    open={openAddSlideDialog}
                    onClose={cancelEdit}
                    aria-labelledby="responsive-dialog-title"
                    data-testid="add-a-new-slide-dialog"
                >
                    {!isMobileView &&
                        <DialogTitle id="responsive-dialog-title">
                            <div className="subtitle1" style={{ textAlign: 'center', width: '100%' }}>{modalTitle}</div>
                        </DialogTitle>
                    }
                    {isMobileView &&
                        <NavBar
                            leftContent={<CloseIcon sx={{marginRight: '32px', cursor: 'pointer'}} onClick={cancelEdit} />}
                            rightContent={<Button sx={{ color: 'white' }} onClick={() => saveSlide()}>{modalButtonText}</Button>}
                            title={modalTitle}
                        />
                    }
                    <DialogContent>
                        <Box sx={{ minWidth: !isMobileView ? 550 : 0 }}>
                            <Box sx={{ marginBottom: "32px" }}>
                                {imageProperty && <ImageProperty siteId={siteId} property={imageProperty}
                                    propertyUpdated={imagePropertyUpdated} setError={() => {}}
                                    renderOptions={{ centered: true }} isPageEditor={false} pageList={pageList} /> }
                            </Box>
                            {showCaption &&
                                <TextField
                                    sx={{ width: '100%' }}
                                    value={slideBeingEdited.c}
                                    onChange={(e: any) => updateCaption(e.target.value)}
                                    label="Caption"
                                    variant="outlined" />
                            }
                        </Box>
                    </DialogContent>
                    {!isMobileView &&
                        <DialogActions>
                            <Button autoFocus onClick={cancelEdit}>
                                Cancel
                            </Button>
                            <Button onClick={() => saveSlide()} autoFocus variant="outlined">
                                {modalButtonText}
                            </Button>
                        </DialogActions>
                    }
                </Dialog>
            }
        </div>
    );
}

export function Editor({property, propertyUpdated, siteId, isPageEditor, pageList}: PropertyEditorProps) {
    const [propertyBeingEdited, setPropertyBeingEdited] = useState<Property | null>(property);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        setPropertyBeingEdited(Object.assign({}, property));
    }, [property]);

    const label = `Edit ${JSON.parse(property?.Configuration || '{}').LabelOverride || 'Slide'}s`;

    const cancelEdit = () => {
        setPropertyBeingEdited(Object.assign({}, property));
        setShowEditor(false);
    };

    const save = () => {
        setShowEditor(false);
        if (propertyBeingEdited) {
            propertyUpdated(propertyBeingEdited);
        }
    };

    const editorPropertyUpdated = (property: any) => {
        setPropertyBeingEdited({...property});
    };

    return (
        <>
        {!isPageEditor &&
            <ImageCarouselSlidesEditor property={property} propertyUpdated={propertyUpdated}
                siteId={siteId} isPageEditor={isPageEditor} pageList={pageList} />
        }
        { isPageEditor &&
            <Button
                data-testid="edit-slides-button"
                variant="outlined"
                fullWidth={true}
                sx={{ border: "1px solid rgba(0, 0, 0, 0.12)"}}
                onClick={() => setShowEditor(true)}
            >
                {label}
            </Button>
        }
        { showEditor &&
            <Dialog
                open={true}
                onClose={cancelEdit}
                aria-labelledby="responsive-dialog-title"
                data-testid="edit-slides-dialog"
            >
                <DialogTitle id="responsive-dialog-title">
                    <div className="subtitle1" style={{ textAlign: 'center', width: '100%' }}>
                        {label}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 550 }}>
                        <ImageCarouselSlidesEditor property={propertyBeingEdited} propertyUpdated={editorPropertyUpdated}
                            siteId={siteId} isPageEditor={isPageEditor} pageList={pageList} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={cancelEdit}>
                        Cancel
                    </Button>
                    <Button onClick={save} autoFocus variant="outlined">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        }
        </>
    );
}


export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const list = JSON.parse(property.RenderedValue);

        for (let slide of list) {
            if (getFileNameFromUrl(slide.u?.toLowerCase())?.includes(value)
                || getFileNameFromUrl(slide.t?.toLowerCase())?.includes(value)
                || slide.a?.toLowerCase().includes(value)
                || slide.c?.toLowerCase().includes(value)) {
                return true;
            }
        }

        return false;
    }
}
