import { Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface NavBarButtonProps {
    buttonClick: (e:any) => void,
    label: string,
    icon: JSX.Element | null,
    status: string | null,
    menuRef: React.MutableRefObject<undefined>
}

export default function NavBarButton({buttonClick, label, icon, status, menuRef}: NavBarButtonProps) {
    return (
        <Box 
            data-testid="menu-btn"
            ref={menuRef}
            sx={{ 
                borderLeft: "1px solid white", 
                height: "100%", 
                display: "flex", 
                paddingLeft: "20px", 
                paddingRight: "20px", 
                alignItems: "center", 
                justifyContent: "center",
                cursor: "pointer",
                zIndex: 1400
            }}
            onClick={buttonClick}
        >
            <Box sx={{ textAlign: "right" }}>
                <Box sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: "1.25px",
                    textTransform: "uppercase"
                }}>{label}</Box>
                <Box className="caption" sx={{ display: "flex", alignItems: "center" }}>
                    {icon}{status}
                </Box>
            </Box>
            <Box sx={{ marginLeft: "8px" }}>
                <ExpandMoreIcon />
            </Box>
        </Box>
    );
}