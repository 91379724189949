import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, propertyHasValue } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

const isConfiguredProperly = (block: BlockMapping) => {
    const hasIframeOverride = propertyHasValue(block.Properties, PropertyId.GoogleMapIFrameOverrideHTML);
    const hasApiKey = propertyHasValue(block.Properties, PropertyId.GoogleMapAPIKey);
    const hasQuery = propertyHasValue(block.Properties, PropertyId.GoogleMapQuery);

    return hasIframeOverride || (hasApiKey && hasQuery);
};

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const iframeOverride = getPropertyValue(block.Properties, PropertyId.GoogleMapIFrameOverrideHTML);
    const apiKey = getPropertyValue(block.Properties, PropertyId.GoogleMapAPIKey);
    const query = getPropertyValue(block.Properties, PropertyId.GoogleMapQuery);
    const queryParams = new URLSearchParams({
        key: apiKey,
        q: query
      } as any);
    const url = "https://www.google.com/maps/embed/v1/place?" + queryParams.toString();
    
    return (
        <>
            <div {...getDynamicAttributes({block, builderProps, className: "googlemaps", blockWrapperData})}>
                <div className="googlemaps-builder-overlay"></div>
                { iframeOverride &&
                    <div style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{__html: iframeOverride}} />
                }
                { !iframeOverride && apiKey && query &&
                    <iframe src={url} frameBorder="0" marginHeight={0} marginWidth={0} scrolling="no" title="Google Maps"></iframe>
                }
            </div>
            {children}
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block) || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}