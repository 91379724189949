const enum PropertyId {
    AnchorConfig = 1,
    Content = 2,
    DisplayType = 4,
    ChildrenPosition = 5,
    FontAwesomeIconClass = 6,
    CtaBackgroundImage = 7,
    CtaOverlayOpacity = 8,
    GoogleMapQuery = 9,
    GoogleMapIFrameOverrideHTML = 10,
    GoogleMapAPIKey = 11,
    ClassName = 12,
    ContentHTML = 13,
    ColorTheme = 14,
    FontTheme = 15,
    Logo = 16,
    SiteFavicon = 17,
    HeadingLevel = 18,
    ColSpan = 20,
    RowSpan = 21,
    ID = 22,
    InputType = 24,
    Required = 25,
    FieldName = 26,
    LabelText = 27,
    Value = 28,
    ImageSource = 29,
    Header = 31,
    MaterialIcon = 32,
    GoogleRecaptchaSiteKey = 35,
    GoogleRecaptchaSecretKey = 36,
    RequiredBlocks = 41,
    AddressLine1 = 42,
    AddressLine2 = 43,
    City = 44,
    State = 45,
    Zip = 46,
    HideAddressLine = 47,
    AddressDisplay = 48,
    Vertical = 49,
    Hours = 50,
    AdditionalHoursText = 51,
    RowXSmallColumns = 52,
    RowSmallColumns = 53,
    RowMediumColumns = 54,
    RowLargeColumns = 55,
    RowXLargeColumns = 56,
    Row2XLargeColumns = 57,
    RowGap = 58,
    GoogleRecaptchaSize = 59,
    GoogleRecaptchaTheme = 60,
    PixelId = 61,
    GoogleAnalyticsEvent = 63,
    GoogleAnalyticsCategory = 64,
    GoogleAnalyticsLabel = 65,
    RemovePadding = 67,
    BusinessName = 68,
    Schedule = 90,
    ImageLayout = 91,
    ButtonAlignment = 92,
    TextAlignment = 93,
    Title = 94,
    AnchorTarget = 95,
    ImageCarouselSlides = 96,
    ContentTextArea = 97,
    AriaLabel = 100,
    IsDestinationTarget = 101,
    CssTextTransform = 102,
    MobileHeight = 103,
    TabletHeight = 104,
    DesktopHeight = 105,
    PrimaryColor = 106,
    PrimaryFocus = 107,
    PrimaryContent = 108,
    SecondaryColor = 109,
    SecondaryFocus = 110,
    SecondaryContent = 111,
    AccentColor = 112,
    AccentFocus = 113,
    AccentContent = 114,
    PrimaryFont = 115,
    SecondaryFont = 116,
    SiteReviewsId = 117,
    ReviewsId = 118,
    Font = 120,
    Width = 121,
    Height = 122,
    BackgroundColor = 123,
    TextColor = 124,
    IsServiceArea = 126,
    ServiceAreas = 127,
    TextAlign = 128,
    BorderRadius = 129,
    Display = 130,
    FlexDirection = 131,
    AlignItems = 132,
    JustifyContent = 133,
    BackgroundImage = 134,
    BackgroundSize = 135,
    BackgroundPosition = 136,
    FontSize = 137,
    MarginLeft = 138,
    MarginRight = 139,
    MarginTop = 140,
    MarginBottom = 141,
    PaddingLeft = 142,
    PaddingRight = 143,
    PaddingTop = 144,
    PaddingBottom = 145,
    CardWidth = 146,
    ImageScaling = 147,
    HolidayHoursTitle = 151,
    HolidayHours = 152,
    FlexWrap = 154,
    FlexBasis = 155,
    FlexGrow = 156,
    FlexShrink = 157,
    MinWidth = 158,
    MaxWidth = 159,
    MinHeight = 160,
    MaxHeight = 161,
    Opacity = 162,
    BorderColor = 163,
    Overflow = 164,
    OverflowWrap = 167,
    Float = 168,
    Top = 169,
    Bottom = 170,
    Left = 171,
    Right = 172,
    ZIndex = 173,
    Position = 174,
    VerticalAlign = 175,
    AlignContent  = 176,
    AlignSelf = 177,
    BackgroundAttachment = 178,
    BackgroundRepeat = 179,
    LineHeight = 180,
    WordBreak = 181,
    WordSpacing = 182,
    TextDecorationColor = 183,
    TextDecorationLine = 184,
    TextDecorationStyle = 185,
    FontStyle = 186,
    ListStyleType = 187,
    ListStylePosition = 188,
    BorderWidth = 189,
    BorderStyle = 190,
    NavigationItems = 192,
    HideBlock = 193,
    InputItems = 196,
    PlaceholderText = 197,
    CtaOverlayColor = 200,
    SiteBackgroundColor = 201,
    SiteFontColor = 202,
    PageBackgroundColor = 203,
    PageFontColor = 204,
    ReviewShowcaseLayout = 205,
    SiteShareImage = 207,
    SiteReviewsLocationIds = 209,
    ReviewsLocationIds = 210,
    ReviewsDisableReadMore = 211,
    ReviewsReadMoreLinkColor = 212,
    ReviewsShowcaseCellBorderColor = 213,
    ReviewsShowcaseCellBackgroundColor = 214,
    ReviewsStarColor = 215,
    MediaDisplayType = 226,
    ImageScrollSpeed = 227,
    BackgroundImages = 228,
    BackgroundVideo = 229,
    BackgroundVideoPoster = 230,
    PlayVideoOnLoop = 231
}

export default PropertyId;
