const enum PropertyValueType {
    Text = 801,
    Color = 802,
    ColorTheme = 803,
    FontTheme = 804,
    Image = 805,
    Dropdown = 806,
    Boolean = 807,
    IconAnchorList = 808,
    FontAwesomeIcon = 809,
    Number = 810,
    HTML = 811,
    Column = 812,
    MaterialIcon = 813,
    AnchorConfig = 814,
    ContactFormConfig = 815,
    RequiredBlocks = 816,
    HoursConfig = 817,
    CSV = 818,
    GeoCoordinates = 819,
    DateRange = 820,
    ImageCarouselSlides = 821,
    TextArea = 822,
    Font = 823,
    DynamicallyCalculated = 824,
    HolidayHours = 825,
    NavigationItems = 826,
    Checkboxes = 827,
    KeyValuePair = 828,
    Radios = 830,
    DataSourceScope = 831,
    Video = 832
}

export default PropertyValueType;
