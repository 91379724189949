import './TemplateListItem.scss';
import { Box, Chip, Button } from "@mui/material";
import Template from "../../../types/Template";

export default function TemplateListItem({template, handleSelect}: { template: Template, handleSelect: (templateId: string) => void}) {

    return (
        <Box
            sx={{width: "100%", maxWidth: "500px"}}
        >
            <Box
                data-testid="templateListItem"
                onClick={() => handleSelect(template.Id)}
                sx={{
                    cursor: "pointer",
                    height: "325px",
                    width: "100%",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    backgroundImage: `url('${template.ScreenshotUrl}')`,
                    backgroundSize: "cover"
                }}
            >
                <Box data-testid="template-select-overlay" className="template-select-overlay">
                    <Box sx={{ maxWidth: "300px" }}>{template.Description}</Box>
                    <Button
                        variant="outlined"
                        data-testid={`select-template-${template.Id}`}
                        sx={{
                            marginTop: "32px",
                            borderColor: "white",
                            color: "white",
                            '&:hover': {
                                borderColor: 'white'
                            }
                        }}
                    >
                        Select Template
                    </Button>
                </Box>
            </Box>
            <Box sx={{ margin: "16px 0px 16px 0px" }} className="headline5">
                {template.Name}
            </Box>
            <Box sx={{ display: "flex", overflowX: "auto" }} className="hideScrollbars">
                {template.Features.map(f => <Chip key={f.Id} label={f.Name} sx={{marginRight: "8px" }} />)}
            </Box>
        </Box>
    )
}