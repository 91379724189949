import './LogoStep.css';
import PropertyId from '../../../types/enum/PropertyId';
import { Editor as ImageProperty } from '../../property_editors/ImageProperty';
import BlockId from '../../../types/enum/BlockId';
import { useEffect } from 'react';
import Site from '../../../types/Site';
import Page from '../../../types/Page';
import Property from '../../../types/Property';
import BlockMapping from '../../../types/BlockMapping';

export interface LogoStepProps {
    site: Site;
    templateData: Page | null;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    updatePreviewBlockId: React.Dispatch<React.SetStateAction<string | null>>;
}

function LogoStep({ site, templateData, setTemplateData, setError, updatePreviewBlockId }: LogoStepProps) {

    const logoProperty = templateData?.SiteProperties.find(p => p.EnumId === PropertyId.Logo);

    useEffect(() => {
        updateHeaderLogo(logoProperty, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const updateHeaderLogo = (property?: Property, setValue?: boolean) => {
        const setHeaderAnchorImageToLogo = (child: BlockMapping, property?: Property, setValue?: boolean) => {
            if (child.EnumId === BlockId.Anchor) {
                const logo: BlockMapping | null = child.BlockMappings.length > 0 ? child.BlockMappings[0] : null;
                if (logo?.EnumId === BlockId.Image) {
                    const source = logo.Properties.find(p => p.EnumId === PropertyId.ImageSource);
                    if (source) {
                        if (setValue) {
                            source.RenderedValue = property?.RenderedValue ?? null;
                        }
                        return true;
                    }
                }
            }

            return false;
        }

        for (let block of (templateData || {BlockMappings: []}).BlockMappings) {
            if (block.EnumId === BlockId.PageHeader) {
                let logoFound = false;
                // Look for anchor with logo image as immediate child of page header
                for (let child of block.BlockMappings) {
                    logoFound = setHeaderAnchorImageToLogo(child, property, setValue);

                    if (logoFound) {
                        updatePreviewBlockId(block.Id);
                        break;
                    }
                }
                // Look for anchor with logo image as a child or row/column
                for (let rowChild of block.BlockMappings) {
                    if (rowChild.EnumId === BlockId.Row) {
                        for (let columnChild of rowChild.BlockMappings) {
                            if (columnChild.EnumId === BlockId.Column) {
                                for (let child of columnChild.BlockMappings) {
                                    logoFound = setHeaderAnchorImageToLogo(child, property, setValue);

                                    if (logoFound) {
                                        updatePreviewBlockId(block.Id);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
                if (logoFound) {
                    break;
                }
            }
        }
    }

    const propertyUpdated = (property?: Property) => {
        if (logoProperty) {
            logoProperty.ValueUpdatedInWizard = true;
        }
        updateHeaderLogo(property, true);
        setTemplateData(Object.assign({}, templateData));
    };

    const instructionText = `The image will be automatically used for the website Favicon. Square images work best.`

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Set a logo image</h1>
                <p className="step-instructions">{instructionText}</p>
            </div>
            <div className="wizard-step-section">
                <ImageProperty siteId={site.Id} property={logoProperty ?? null} propertyUpdated={propertyUpdated} setError={setError} pageList={[]} />
            </div>
        </>
    );
}

export default LogoStep;