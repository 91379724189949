import FileRecord from "../types/FileRecord";

export function getFileSizeDisplay(bytes: number) {
    const calculatedFileSizeKB = parseFloat((bytes / 1024).toString())
    const calculatedFileSizeMB = parseFloat((bytes / 1024 / 1024).toString())
    let fileSize = `${calculatedFileSizeKB.toFixed(2)} KB`;
    if (calculatedFileSizeKB > 1000) {
        fileSize = `${calculatedFileSizeMB.toFixed(2)} MB`;
    }
    return fileSize;
}

export function isImageFile(file: File) {
    const imageTypes = [
        "image/jpeg",
        "image/png",
        //"image/webp"  // disabled until ARR-1374 is done
    ];
    return imageTypes.indexOf(file.type) > -1;
}

export function getFileNameParts(filename: string): {name: string, extension: string | undefined} {
    const parts = filename.split('.');
    return {
        name: parts.slice(0, -1).join('.'),
        extension: '.' + parts.pop()
    };
}

export function findFileByUrl(url: string, files: FileRecord[]) {
    if (!url || !files) {
        return null
    }
    return files.find(i => i.URL === url);
}