import BlockMapping from "../../../../types/BlockMapping";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { getBlockRenderer } from "../../../../utils/blockMappings";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDrag, useDrop } from "react-dnd";
import { useRef, useState } from "react";
import BlockFilterData from "../../../../types/BlockFilterData";
import HierarchyContextMenu from "./HierarchyContextMenu";
import BlockTitle from "../../BlockTitle";
import ResponsiveDeviceIconSet from "../../ResponsiveDeviceIconSet";
import SiteEditorStore from "../../SiteEditorStore";

const componentStyle = { border: "2px dotted gray", margin: "5px" };

export interface HierarchyListItemProps {
  siteStyleId: number,
  indentLevel: number,
  blockMapping: BlockMapping,
  parentBlockMapping: BlockMapping | null
}

export default function HierarchyListItem({
  siteStyleId,
  indentLevel,
  blockMapping,
  parentBlockMapping
}: HierarchyListItemProps) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const closeSidebars = SiteEditorStore.useStoreActions((actions) => actions.closeSidebars);
  const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
  const blocksAndSections = SiteEditorStore.useStoreState((state) => state.blocksAndSections);
  const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);
  const blockSelected = SiteEditorStore.useStoreActions((actions) => actions.blockSelected);
  const updateHoverBlock = SiteEditorStore.useStoreActions((actions) => actions.updateHoverBlock);
  const draggingOnHover = SiteEditorStore.useStoreActions((actions) => actions.draggingOnHover);
  const draggingFinished = SiteEditorStore.useStoreActions((actions) => actions.draggingFinished);
  const draggingStarted = SiteEditorStore.useStoreActions((actions) => actions.draggingStarted);
  const draggingHoverData = SiteEditorStore.useStoreState((state) => state.draggingHoverData);
  const updateHierarchyFilterHasResults = SiteEditorStore.useStoreActions((actions) => actions.updateHierarchyFilterHasResults);
  const filterText = SiteEditorStore.useStoreState((state) => state.hierarchyFilterText);
  const hierarchyFilterHasResults = SiteEditorStore.useStoreState((state) => state.hierarchyFilterHasResults);
  const updateHierarchyContextMenuOpen = SiteEditorStore.useStoreActions((actions) => actions.updateHierarchyContextMenuOpen);
  const hierarchyContextMenuOpen = SiteEditorStore.useStoreState((state) => state.hierarchyContextMenuOpen);
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'HierarchyListItem',
    collect(monitor) {
        return {
            handlerId: monitor.getHandlerId(),
        };
    },
    hover(item: any, monitor) {
        if (!ref.current) {
            return;
        }
        if (monitor.isOver({ shallow: true })) {
            const rect = ref.current?.getBoundingClientRect();
            const mouse = monitor.getClientOffset() || {x: 0, y: 0};
            const topPercent = (mouse.y - rect.top) / rect.height;
            const bottomPercent = (rect.bottom - mouse.y) / rect.height;
            const isBefore = topPercent <= .2;
            const isAfter = bottomPercent <= .2;
            const isInto = !isBefore && !isAfter;
            const hoverData = {
                type: 'HierarchyListItem',
                rect,
                mouse,
                isBefore,
                isAfter,
                isInto,
                hoveredId: blockMapping.Id,
                blockMappingId: item.blockMappingId,
                templateId: item.templateId,
                blockId: item.blockId,
                hoveredParentIndex: 0,
                componentId: item.componentId
            };
            draggingOnHover(hoverData);
        }
    },
    drop() {
      draggingFinished(draggingHoverData);
    }
  });
  const [{ isDragging }, drag] = useDrag({
      type: 'HierarchyListItem',
      item: () => {
        draggingStarted({ type: 'HierarchyListItem' });
        return { blockMappingId: blockMapping.Id }
      },
      end: () => {
        draggingFinished(null);
      },
      collect: (monitor) => ({
          isDragging: monitor.isDragging()
      }),
  });

  drag(drop(ref));

  const isSelected = selectedBlocks.find(b => b.Id === blockMapping.Id) !== undefined;

  const blockRenderer: any = getBlockRenderer(siteStyleId, blockMapping?.EnumId, blockMapping?.BlockContentId);;

  let showContent = false;
  let showBlock = false;
  let HierarchyContent = <></>;
  if (blockRenderer) {
    const hierarchyContentFunc = blockRenderer.HierarchyContent;
    if(hierarchyContentFunc) {
      HierarchyContent = hierarchyContentFunc(blockMapping);
      showContent = HierarchyContent != null;
    }
  }

  const filterBlock: (block: BlockMapping) => boolean = (block: BlockMapping) => {

    // if there are children, filter them recursively
    block?.BlockMappings?.forEach(child => {
      showBlock = showBlock || filterBlock(child);
    });

    if (showBlock) {
      return true;
    }

    // if no children, test this block's name and blockRenderer Filter method
    const scopedName = blocksAndSections.find(b => b.b === block.BlockId)?.n;
    showBlock = !!scopedName && (scopedName.toLowerCase().includes(filterText));

    if (showBlock) {
      return true;
    }

    const scopedBlockRenderer: any = getBlockRenderer(siteStyleId, block?.EnumId, block?.BlockContentId);

    if (scopedBlockRenderer) {
      const filterFunc = scopedBlockRenderer.filter;
      if (filterFunc) {
        const filterData: BlockFilterData = {
          propertyEditorFilterData: {
            pageList: pageList
          }
        }
        showBlock = filterFunc(block, filterText, filterData);
      }
    }
    return showBlock;
  }

  filterBlock(blockMapping);

  if (!hierarchyFilterHasResults && showBlock) {
    updateHierarchyFilterHasResults(true);
  }

  const closeContextMenu = () => {
    setContextMenuOpen(false);
    updateHierarchyContextMenuOpen(false);
  }

  const closeMenus = () => {
    closeContextMenu();
  }

  return (
    <> {showBlock && <div style={blockMapping.ComponentBlockMappingId ? componentStyle : {}}>
      <div
          data-testid="hierarchyListItem"
          style={{
            minHeight: "54px",
            lineHeight: "54px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: indentLevel <= 6 ? "412px" : `${412 + (indentLevel-6)*36}px`,
            maxWidth: "100%",
            backgroundColor: isSelected ? '#d1d0e7' : 'white'
          }}
          className={isSelected ? '' : 'hierarchy-item-hoverable'}
          >
        <div
          ref={ref}
          style={{
            minHeight: "54px",
            lineHeight: "54px",
            display: "flex",
            alignItems: "center",
            paddingRight: "0px",
            paddingLeft: "20px",
            paddingTop:"4px",
            paddingBottom:"4px",
            width: indentLevel <= 6 ? "363px" : `${363 + (indentLevel-6)*36}px`,
          }}
          onMouseOver={() => {
            if(!isDragging) {
              updateHoverBlock(blockMapping);
            }
          }}
          onClick={(e: any) => {
            switch(e.detail) {
              case 1:
                blockSelected(blockMapping);
                break;
              case 2:
                closeSidebars();
                setTimeout(() => {
                  const shadowRoot = document.getElementById('shadowRootContainer')?.shadowRoot;
                  if (shadowRoot) {
                    const selectedElement = shadowRoot.querySelector('.selected-block');

                    selectedElement?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center'
                    });
                  }
                }, 100);
                break;
            }
          }}
        >
        <DragIndicatorIcon
            sx={{
              cursor: 'move',
              paddingLeft: `${(indentLevel) * 30}px`,
              paddingRight: "20px",
              opacity: '60%'
            }}
          />
          <ResponsiveDeviceIconSet blockMapping={blockMapping}/>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              overflow: "hidden",
              maxWidth: `${318 - (indentLevel*13)}px`
            }}
          >
            <BlockTitle
              blockMapping={blockMapping}
              style={{
                lineHeight: "18px",
                fontSize: "16px",
                fontWeight: "500",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
              className={""}/>
            { showContent &&
              <div
                style={{
                  display: "block",
                  height: "20px",
                  lineHeight: "20px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}>
                  {HierarchyContent}
              </div> }
          </div>
        </div>
        <div
              id={blockMapping.Id}
              style={{ width: "42px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer",
                marginLeft: 'auto',
                color: !contextMenuOpen ? undefined : isSelected ? '#EEEEEE' : 'secondary.main'
              }}
              className={hierarchyContextMenuOpen ? '' : isSelected ? 'hierarchy-context-hoverable' : 'hierarchy-context-hoverable-blue'}
              onClick={(e: any) => {
                e.stopPropagation();
                if (contextMenuOpen) {
                  closeContextMenu();
                } else if (!hierarchyContextMenuOpen) {
                  setContextMenuOpen(true);
                  updateHierarchyContextMenuOpen(true);
                }
              }}
              ref={contextMenuRef}
            >
            <MoreVertOutlinedIcon/>
            {contextMenuOpen &&
              <HierarchyContextMenu
                  blockMapping={blockMapping}
                  parentBlockMapping={parentBlockMapping}
                  contextMenuOpen={contextMenuOpen}
                  contextMenuRef={contextMenuRef}
                  closeMenus={closeMenus}
              />
            }
        </div>
      </div>
      {blockMapping?.BlockMappings?.map((b: BlockMapping, index: number) =>
        <HierarchyListItem
          indentLevel={indentLevel + 1}
          siteStyleId={siteStyleId}
          blockMapping={b}
          parentBlockMapping={blockMapping}
          key={`${b.Id}-${index}`}
          />
      )}
      </div>}</>
  )
}