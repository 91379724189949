import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Property from "../../types/Property";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import { PropertyNameLabel } from "../common/PropertyNameLabel";

export function Editor({property, propertyUpdated}: PropertyEditorProps) {
    const renderedValue = JSON.parse(property?.RenderedValue || "[]");

    const updateValue = (values: string[]) => {
        const newVal = JSON.stringify(values);
        if (property) {
            property.Value = newVal;
            property.RenderedValue = newVal;
            propertyUpdated(property);
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && !renderedValue.includes(event.target.value)) {
            renderedValue.push(event.target.value);
            updateValue(renderedValue);
        } else if (renderedValue.includes(event.target.value)) {
            var index = renderedValue.indexOf(event.target.value);
            if (index !== -1) {
                renderedValue.splice(index, 1);
                updateValue(renderedValue);
            }
        }
    };

    const configObj = property?.Configuration ? JSON.parse(property?.Configuration) : {};
    const options: [string,string][] = configObj.KeyValuePairs ? Object.entries(configObj.KeyValuePairs) : [];

    return (
        <>
        <PropertyNameLabel 
            labelId={`label-${property?.PropertyId}`}
            property={property}
        ></PropertyNameLabel>
        {options.map(([name, key]) => (
            <FormGroup key={key}>
                <FormControlLabel 
                    control={(
                        <Checkbox
                            color="secondary"
                            checked={renderedValue.includes(key)}
                            value={key}
                            onChange={handleOnChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{ paddingTop: "4px", paddingBottom: "4px" }}
                        />
                    )} 
                    label={name} 
                />
            </FormGroup>
        ))}
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    const configuration: [string, string][]
        = Object.entries(JSON.parse(property.Configuration || "{}").KeyValuePairs || []);

    for (const config of configuration) {
        if (config[0].toLowerCase().includes(value)) {
            return true;
        }
    }

    return false;
}