import { useRef, useEffect, useState, ReactNode } from 'react';
import './FileGalleryDialog.css';
import FileGridItem from './FileGridItem';
import { Button, Box, Fab, Tab, Tabs } from '@mui/material';
import { Upload } from '@mui/icons-material';
import FileUploader from './FileUploader';
import Loading from './Loading';
import useApiDeleteCallbackAsync from '../../hooks/useApiDeleteCallbackAsync';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import NavBar from './NavBar';
import CloseIcon from '@mui/icons-material/Close';
import FileInfoPanel from './FileInfoPanel';
import PropertyValueType from "../../types/enum/PropertyValueType";
import Property from '../../types/Property';
import FileRecord from '../../types/FileRecord';
import BuilderStore from '../../BuilderStore';
import ErrorMessage from './ErrorMessage';
import { findFileByUrl } from '../../utils/fileHelpers';
import FileType from '../../types/enum/FileType';

interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;
    className?: string;
    style?: React.CSSProperties;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

export interface FileGalleryDialogProps {
    onFileDeleted?: (file: FileRecord) => void,
    onCancel: () => void,
    allowSelectingFile?: boolean,
    selectedFileUrl?: string | null,
    selectedSitePropertyId?: string | null,
    siteProperties?: Property[],
    allowedFileTypes: FileType[],
    allowedSitePropertyValueTypeIds?: PropertyValueType[],
    onFileSelected?: (file: FileRecord) => void,
    onSitePropertySelected?: (siteProperty: Property, file: FileRecord) => void,
    maxFileSizeKb?: number
}

export function FileGalleryDialog({ selectedFileUrl, selectedSitePropertyId, siteProperties, onFileDeleted, onCancel
    , allowedSitePropertyValueTypeIds, allowSelectingFile = false, onFileSelected, onSitePropertySelected
    , allowedFileTypes, maxFileSizeKb }: FileGalleryDialogProps) {
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [fileBeingDeleted, setFileBeingDeleted] = useState<FileRecord | null>(null);
    const [selectedFile, setSelectedFile] = useState<FileRecord | null | undefined>(null);
    const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
    const uploaderInput = useRef<HTMLInputElement>(null);
    const [tabValue, setTabValue] = useState(0);
    const [newFile, setNewFile] = useState<FileRecord | null>(null);
    const siteFiles = BuilderStore.useStoreState((state) => state.siteFiles);
    const siteId = BuilderStore.useStoreState((state) => state.siteId);
    const refreshSiteFiles = BuilderStore.useStoreActions((actions) => actions.refreshSiteFiles);

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    let selectedSiteProperty: Property | null | undefined = null;

    if (siteProperties && selectedSitePropertyId && allowedSitePropertyValueTypeIds) {
        selectedSiteProperty = siteProperties.find(p => allowedSitePropertyValueTypeIds.includes(p.ValueTypeId)
            && p.PropertyId === selectedSitePropertyId);
    }

    const browseFiles = () => {
        setError(null);

        if (uploaderInput && uploaderInput.current) {
            uploaderInput.current.click();
        }
    }

    const [, executeDelete] = useApiDeleteCallbackAsync() as any;

    const deleteSuccessCallback = () => {
        if (fileBeingDeleted?.Id === selectedFile?.Id) {
            setSelectedFile(null);
            setSelectedProperty(null);
        }

        setDeleting(false);
        setFileBeingDeleted(null);
        setSelectedFile(null);
        updateFiles();
        
        if (fileBeingDeleted && onFileDeleted) {
            onFileDeleted(fileBeingDeleted);
        }
    };

    const deleteErrorCallback = (errorResponse: any) => {
        setDeleting(false);
        setFileBeingDeleted(null);

        const errors = errorResponse?.body?.Errors;

        if (errors && errors.length > 0) {
            setError?.(errors[0].Message);
        } else {
            setError?.('Error deleting file. Please try again.');
        }
    };

    const deleteFile = (file: FileRecord) => {
        if (window.confirm(`Are you sure you wish to delete ${file.FileName}${file.FileExtension}?`) === true) {
            setFileBeingDeleted(file);
            setDeleting(true);

            executeDelete(`/site/${siteId}/files/${file.Id}`, deleteSuccessCallback, deleteErrorCallback);
        }
    }

    // select uploaded image if no current selection or no image value
    useEffect(() => {
        if (!selectedFile && !selectedFileUrl && newFile) {
            setSelectedFile(newFile);
            setNewFile(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFile, siteFiles]);

    const fileUploaded = (uploadedImage: FileRecord) => {
        updateFiles(uploadedImage);
        setTabValue(0);
    }

    // call callback on demand to refresh file list
    const updateFiles = (uploadedFile: FileRecord | null = null) => {
        setNewFile(uploadedFile);
        setError?.(null);
        refreshSiteFiles();
    }

    // call callback on initial render to populate image list
    useEffect(() => {
        if (selectedSitePropertyId) {
            if (selectedSiteProperty) {
                setSelectedProperty(selectedSiteProperty);
                setSelectedFile(null);
                setTabValue(1);
            }
        }
        else if (selectedFileUrl) {
            setSelectedFile(findFileByUrl(selectedFileUrl, siteFiles));
            setSelectedProperty(null);
            setTabValue(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFileUrl, selectedSitePropertyId]);

    const cancelDialog = () => {
        setSelectedFile(null);
        setSelectedProperty(null);
        onCancel();
    };

    const saveSelection = () => {
        if (selectedProperty && selectedFile) {
            if (onSitePropertySelected) {
                onSitePropertySelected(selectedProperty, selectedFile);
            }
        }
        else if (selectedFile && onFileSelected) {
            onFileSelected(selectedFile);
        }
    };

    const clearSelection = () => {
        setSelectedFile(null);
        setSelectedProperty(null);
    };

    const handleTabChange = (event: React.SyntheticEvent<Element>, newValue: number) => {
        setTabValue(newValue);
    };

    const sitePropertyFiles: { property: Property, file: FileRecord}[] = [];

    // If we have site images and are enabled for showing site properties
    // find all site properties that have an image configured
    if (siteFiles && siteProperties && allowedSitePropertyValueTypeIds) {
        const allowedSiteProperties = siteProperties
            .filter(p => allowedSitePropertyValueTypeIds.includes(p.ValueTypeId));

        for (const siteProperty of allowedSiteProperties) {
            const sitePropertyFile = siteFiles.find((i: FileRecord) => siteProperty.RenderedValue && siteProperty.RenderedValue.includes(i.URL));

            if (sitePropertyFile) {
                sitePropertyFiles.push({
                    property: siteProperty,
                    file: sitePropertyFile
                });
            }
        }
    }

    if (!siteId) {
        return null;
    }

    const filteredFiles = allowedFileTypes 
        ? siteFiles.filter(f => f.FileTypeId && allowedFileTypes.includes(f.FileTypeId))
        : siteFiles;

    let currentTabValue = tabValue;

    const showSitePropertiesTab = sitePropertyFiles && sitePropertyFiles.length > 0;

    if (!showSitePropertiesTab && currentTabValue === 1) {
        currentTabValue = 0;
    }

    return (
        <>
            { deleting && 
                <Loading message="Deleting..." />
            }
            <Dialog
                fullScreen={isMobileView}
                open={true}
                onClose={cancelDialog}
                aria-labelledby="responsive-dialog-title"
                maxWidth='lg'
                fullWidth={true}
                PaperProps={{
                    style: { borderRadius: isMobileView ? 0 : '10px' }
                }}
                data-testid="file-gallery-dialog"
            >
                <div style={{display:'flex', flexFlow:'row nowrap'}}>
                    <div style={{ 
                        display: 'flex', 
                        flexFlow: 'column wrap', 
                        width:'100%', 
                        justifyContent:'space-between' }}
                    >
                        <div style={{
                                display: 'flex', 
                                flexFlow: 'column nowrap', 
                                height: isMobileView ? '100vh' : 'unset'
                            }}>
                            {!isMobileView && 
                                <Box 
                                    id="responsive-dialog-title" 
                                    sx={{ margin: '16px 16px 0 16px' }}
                                >
                                    <Box 
                                        sx={{ 
                                            display: 'flex', 
                                            justifyContent: "space-between", 
                                            float: 'right' 
                                        }}
                                    >
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            startIcon={<Upload />}
                                            sx={{ width: isMobileView ? '100%' : 'inherit' }}
                                            onClick={browseFiles}>
                                            Upload
                                        </Button>
                                    </Box>
                                </Box>
                            }
                            {isMobileView && 
                                <NavBar
                                    title="File Handler"
                                    leftContent={
                                        <CloseIcon sx={{marginRight: '32px', cursor: 'pointer'}} 
                                            onClick={cancelDialog} 
                                        />
                                    }
                                />
                            }
                            {!(isMobileView && selectedFile) &&
                                <>
                                    <Box sx={{ 
                                        marginTop: '32px', 
                                        marginLeft: '16px', 
                                        marginRight: '16px', 
                                        borderBottom: 1, 
                                        borderColor: 'divider' }}
                                    >
                                        <Tabs
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            variant="fullWidth"
                                            value={currentTabValue}
                                            onChange={handleTabChange}>
                                            <Tab label="Client Files" />
                                            { sitePropertyFiles && sitePropertyFiles.length > 0 &&
                                                <Tab label="Sitewide Files" />
                                            }
                                        </Tabs>
                                    </Box>
                                    <TabPanel 
                                        value={currentTabValue} 
                                        index={0} 
                                        className="tab-panel" 
                                        style={{ 
                                            display: 'flex', 
                                            maxHeight: isMobileView ? 'calc(100vh - 130px)' : '60vh' 
                                        }}
                                    >
                                        <Box sx={{ 
                                                overflowY: 'auto', 
                                                flex: '1 1 auto', 
                                                minWidth: isMobileView ? 0 : 500,
                                                height: isMobileView ? undefined : '50vh'
                                            }}
                                        >
                                            {filteredFiles &&
                                                <Box
                                                    sx={{ 
                                                        gap: '16px', 
                                                        flexFlow: 'row wrap', 
                                                        display: 'flex', 
                                                        justifyContent: 'flex-start' 
                                                    }}
                                                >
                                                    { filteredFiles.length > 0 &&
                                                        filteredFiles.map((file: FileRecord) => (
                                                            <FileGridItem
                                                                key={file.Id}
                                                                file={file}
                                                                handleSelect={(file: FileRecord) => {
                                                                    setSelectedFile(file);
                                                                    setSelectedProperty(null);
                                                                }}
                                                                handleDelete={deleteFile}
                                                                selected={!selectedProperty && file.URL === (selectedFile ? selectedFile.URL : selectedFileUrl)}
                                                            />
                                                        ))
                                                    }
                                                </Box>
                                            }
                                            {(!filteredFiles || filteredFiles.length === 0) &&
                                                <Box sx={{
                                                    textAlign: 'center',
                                                    minHeight: '400px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: isMobileView ? 'top' : 'center'}}
                                                >
                                                    <h5 className='headline5' 
                                                        style={{
                                                            opacity: '0.6',
                                                            textAlign: 'center',
                                                            marginTop: isMobileView ? '16px' : 0 }}
                                                    >
                                                        No files available for this client.
                                                    </h5>
                                                    <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={browseFiles}>
                                                        Upload File
                                                    </Button>
                                                </Box>
                                            }
                                        </Box>
                                    </TabPanel>
                                    { sitePropertyFiles && sitePropertyFiles.length > 0 &&
                                        <TabPanel 
                                            value={currentTabValue} 
                                            index={1} 
                                            className="tab-panel" 
                                            style={{ 
                                                display: 'flex', 
                                                maxHeight: isMobileView ? 'calc(100vh - 130px)' : '60vh' 
                                            }}
                                        >
                                            <Box sx={{ 
                                                    overflowY: 'auto', 
                                                    flex: '1 1 auto', 
                                                    minWidth: isMobileView ? 0 : 500,
                                                    height: isMobileView ? undefined : '50vh'
                                                }}
                                            >
                                                <Box
                                                    sx={{ 
                                                        gap: '16px', 
                                                        flexFlow: 'row wrap', 
                                                        display: 'flex', 
                                                        justifyContent: 'flex-start' 
                                                    }}
                                                >
                                                    {
                                                        sitePropertyFiles.map(sp => (
                                                            <FileGridItem
                                                                key={sp.file.Id}
                                                                file={sp.file}
                                                                handleSelect={(file: FileRecord) => {
                                                                    setSelectedFile(file);
                                                                    setSelectedProperty(sp.property);
                                                                }}
                                                                handleDelete={deleteFile}
                                                                name={sp.property.Name}
                                                                selected={selectedProperty?.PropertyId === sp.property.PropertyId}
                                                            />
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </TabPanel>
                                    }
                                </>
                            }
                            {isMobileView && selectedFile &&
                                <FileInfoPanel
                                    infoFile={selectedFile}
                                    siteId={siteId}
                                    isMobileView={isMobileView} 
                                    clearSelection={clearSelection}
                                    saveSelection={saveSelection}
                                    updateImages={updateFiles}
                                    deleteFile={deleteFile}
                                    setError={setError}
                                    hideControls={!!selectedProperty}
                                    name={selectedProperty?.Name}
                                />
                            }
                            </div>
                        {!isMobileView &&
                            <DialogActions sx={{ display: 'flex', justifyContent: "space-between" }}>
                                <Button 
                                    sx={{ color: "#292929" }} 
                                    autoFocus 
                                    onClick={cancelDialog}
                                >
                                    Cancel
                                </Button>
                                { allowSelectingFile && 
                                    <Button 
                                        disabled={!selectedFile && !selectedProperty} 
                                        color="secondary" 
                                        onClick={saveSelection} 
                                        autoFocus 
                                        variant="outlined"
                                    >
                                        Confirm
                                    </Button>
                                }
                            </DialogActions>
                        }
                        {isMobileView && !selectedFile && !selectedProperty &&
                            <Fab
                                color="secondary"
                                variant="extended"
                                sx={{ position: 'fixed', bottom: '16px', right: '16px' }}
                                onClick={browseFiles}>
                                <Upload sx={{ mr: 1 }} 
                            />
                                Upload
                            </Fab>
                        }
                    </div>
                    {!isMobileView && (selectedFile || selectedFileUrl) &&
                        <FileInfoPanel
                            infoFile={selectedFile ? selectedFile : findFileByUrl(selectedFileUrl ?? '', siteFiles)}
                            siteId={siteId}
                            isMobileView={isMobileView} 
                            clearSelection={clearSelection}
                            saveSelection={saveSelection}
                            updateImages={updateFiles}
                            deleteFile={deleteFile}
                            setError={setError}
                            hideControls={!!selectedProperty}
                            name={selectedProperty?.Name}
                        />
                    }
                </div>
                <FileUploader
                    fileInput={uploaderInput}
                    siteId={siteId}
                    setError={setError}
                    uploadFinished={fileUploaded}
                    acceptFileTypes={allowedFileTypes}
                    maxFileSizeKb={maxFileSizeKb}
                />
                { error && 
                    <ErrorMessage 
                        message={error} 
                        action={() => setError(null)}
                        actionName={'Dismiss'} 
                        visible={true}
                    />
                }
            </Dialog>
        </>
    );
}
