import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }: {children: ReactNode}) => {
      const domain =  (window as any).CONFIG.Auth0.Domain;
      const clientId =  (window as any).CONFIG.Auth0.ClientId;
      const audience =  (window as any).CONFIG.Auth0.Audience;

      const navigate = useNavigate();

      const onRedirectCallback = (appState: any) => {
              navigate(appState?.returnTo || window.location.pathname);
            };

      return (
              <Auth0Provider
                domain={domain}
                clientId={clientId}
                onRedirectCallback={onRedirectCallback}
                authorizationParams={{
                  redirect_uri: window.location.origin,
                  audience: audience
                }}
              >
                {children}
              </Auth0Provider>
            );
};

export default Auth0ProviderWithHistory;