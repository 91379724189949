import Typography from '@mui/material/Typography';

function NotFound() {
    return (
        <Typography variant="h2">
          Page not found
        </Typography>
      );
}

export default NotFound;