import { Box, Button } from "@mui/material";
import "./SiteWizardActions.scss";
import classNames from "classnames";

export interface SiteWizardActionsProps {
    activeStep: number;
    readonly steps: any[];
    handleReset: () => void;
    handleBack: () => void;
    handleNext: () => void;
    sendRequest: () => void;
    isSubmitting: boolean;
}

const SiteWizardActions: React.FC<SiteWizardActionsProps> = ({
    activeStep,
    steps,
    handleReset,
    handleBack,
    handleNext,
    sendRequest,
    isSubmitting,
}) => (
    <div
        className={classNames({
            "site-wizard-actions": true,
            "review": activeStep === steps.length - 1
        })}
    >
        <Box sx={{ display: "flex", flexDirection: "row", p: 2 }}>
            {activeStep === steps.length ? (
                <>
                    <div style={{ flex: "1 1 auto" }}></div>
                    <Button onClick={handleReset} variant="contained">
                        Reset
                    </Button>
                </>
            ) : (
                <>
                    <Box
                        sx={{ width: "100%", display: { xs: "none", sm: "none", md: "flex" } }}
                    >
                        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Back
                        </Button>
                        <div style={{ flex: "1 1 auto" }}></div>
                        {activeStep < steps.length - 1 && (
                            <Button onClick={handleNext} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}
                        {activeStep < steps.length - 1 && (
                            <Button onClick={handleNext} variant="contained">
                                Next
                            </Button>
                        )}
                        {activeStep === steps.length - 1 && (
                            <Button
                                onClick={sendRequest}
                                disabled={isSubmitting}
                                variant="contained"
                            >
                                Publish
                            </Button>
                        )}
                    </Box>
                    <Box
                        sx={{ width: "100%", display: { xs: "flex", sm: "flex", md: "none" } }}
                    >
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1, width: "100%" }}
                            variant="outlined"
                        >
                            Back
                        </Button>
                        <div style={{ flex: "1 1 auto" }}></div>
                        {activeStep < steps.length - 1 && (
                            <Button
                                onClick={handleNext}
                                variant="contained"
                                sx={{ width: "100%" }}
                            >
                                Next
                            </Button>
                        )}
                        {activeStep === steps.length - 1 && (
                            <Button
                                onClick={sendRequest}
                                disabled={isSubmitting}
                                variant="contained"
                                sx={{ width: "100%" }}
                            >
                                Publish
                            </Button>
                        )}
                    </Box>
                </>
            )}
        </Box>
    </div>
);

export default SiteWizardActions;
