import BlockMapping from "../../../types/BlockMapping";
import Page from "../../../types/Page";
import { getDynamicAttributes } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import { defaultFilter, DefaultHierarchyContent } from "../../../utils/blockRendererDefaults";
import BlockFilterData from "../../../types/BlockFilterData";

export function Block(props: BlockProps) {
    const year = new Date().getFullYear();

    return <span {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, blockWrapperData: props.blockWrapperData})}>{year}</span>;
}

export function hasContentToRender(block: BlockMapping | null, page: Page, childrenHaveContentToRender:boolean) {
    return true;
}

export function usePlaceholder(block: BlockMapping | null, page: Page | null, childrenHaveContentToRender: boolean) {
    return false;
}

export function Placeholder(props: BlockProps) {
    return null;
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const year = `${new Date().getFullYear()}`;

    return (!!year && (year.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}