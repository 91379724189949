import PropertyId from '../../../types/enum/PropertyId';
import { getHoverAttributes, getPropertyValue, getPropertyBooleanValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';
import { KeyValuePair } from '../../property_editors/KeyValuePairProperty';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {

    const labelText = getPropertyValue(block.Properties, PropertyId.LabelText);
    const inputType = getPropertyValue(block.Properties, PropertyId.InputType) ?? "text";
    const requiredBool = getPropertyBooleanValue(block.Properties, PropertyId.Required);
    const fieldName = getPropertyValue(block.Properties, PropertyId.FieldName);
    const htmlFor = getPropertyValue(block.Properties, PropertyId.ID);

    const placeholderText = getPropertyValue(block.Properties, PropertyId.PlaceholderText);

    const hoverAttributes = getHoverAttributes({block, builderProps, classes: "form-control"});

    const disabledStyleOverrides = {
        borderColor: '#1f293733',
        cursor: 'pointer',
        opacity: 1
    };

    const renderDropdownOptions = () => {
        const propVal = JSON.parse(getPropertyValue(block.Properties, PropertyId.InputItems) ?? '{ "t": false, "i": [] }');
        const adjustItemValues = propVal.t;
        const wellConfiguredItems = propVal.i.filter((item: KeyValuePair) => item.k && (!adjustItemValues || item.v));

        return (
            <>
            {placeholderText && <option value="" disabled>{placeholderText}</option>}
            {wellConfiguredItems.map((option: KeyValuePair) => 
                <option key={option.k} value={adjustItemValues ? option.v : option.k}>{option.k}</option>
            )}
            </>
        );
    }

    const renderRadioAndCheckboxes = () => {
        const propVal = JSON.parse(getPropertyValue(block.Properties, PropertyId.InputItems) ?? '{ "t": false, "i": [] }');
        const adjustItemValues = propVal.t;
        const wellConfiguredItems = propVal.i.filter((item: KeyValuePair) => item.k && (!adjustItemValues || item.v));

        let type = inputType;

        if (inputType === "check") {
            type = "checkbox";
        }

        return (
            <>
            {wellConfiguredItems.map((option: KeyValuePair, index: number) => 
                <label className="label cursor-pointer checkbox-label">
                    <input {...getDynamicAttributes({block, builderProps, className: type, includeHover: false, blockWrapperData})} 
                        type={type} 
                        name={fieldName}
                        checked={type === "radio" && index === 0} 
                    />
                    <span className="label-text">{option.k}</span>
                </label>
            )}
            </>
        );
    }

    return (
        <div {...hoverAttributes}>
            {labelText &&
                <label  htmlFor={htmlFor ?? undefined} className="label"><span className="label-text">{labelText}</span></label>
            }
            {inputType === "textarea" ?
                <textarea
                    value={""}
                    placeholder={placeholderText}
                    {...getDynamicAttributes({block, builderProps, className: "textarea h-24 textarea-bordered mb-2", includeHover: false, blockWrapperData})}
                    name={fieldName}
                    required={requiredBool}
                    onChange={() => {}}
                    style={disabledStyleOverrides}
                    disabled>
                </textarea>
            : inputType === "dropdown" ?
                <select
                    value={""}
                    {...getDynamicAttributes({block, builderProps, className: "select select-bordered mb-2", includeHover: false, blockWrapperData})}
                    name={fieldName}
                    style={disabledStyleOverrides}
                    disabled={!hoverAttributes?.className?.includes('selected-block')}
                    required={requiredBool}
                    onChange={() => {}}>
                        {renderDropdownOptions()}
                </select>
            : inputType === "radio" || inputType === "check" ?
                renderRadioAndCheckboxes()
            : inputType === "file" ?
                <input
                    value={""}
                    {...getDynamicAttributes({block, builderProps, className: "file-input file-input-bordered", includeHover: false, blockWrapperData})}
                    type={inputType}
                    name={"files"}
                    onChange={() => {}}
                    onClick={(e) => {e.preventDefault()}}
                    style={disabledStyleOverrides}
                    required={requiredBool}
                    multiple
                    />
            :
                <input
                    value={""}
                    placeholder={placeholderText}
                    {...getDynamicAttributes({block, builderProps, className: "input input-bordered", includeHover: false, blockWrapperData})}
                    type={inputType}
                    name={fieldName}
                    onChange={() => {}}
                    required={requiredBool}
                    style={disabledStyleOverrides}
                    disabled/>
            }
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return true;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return false;
}

export function Placeholder(props: BlockProps) {
    return null;
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const labelText = getPropertyValue(block.Properties, PropertyId.LabelText);

    if (labelText) {
        return <>{labelText}</>;
    }
    
    const id = getPropertyValue(block.Properties, PropertyId.ID);

    if (id) {
        return <>#{id}</>;
    }

    const inputType = getPropertyValue(block.Properties, PropertyId.InputType);
    
    if (inputType) {
        return <>{inputType}</>;
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const labelText = getPropertyValue(block.Properties, PropertyId.LabelText);

    return (!!labelText && (labelText.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}