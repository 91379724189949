
import { useState, useEffect } from "react";
import TemplateFilters from './TemplateFilters';
import TemplateListItem from './TemplateListItem';
import { ErrorAlertMessage } from '../AlertMessage';
import { Box, CircularProgress, Drawer } from '@mui/material';
import useApiGet from "../../../hooks/useApiGet";
import useApiPostCallbackAsync from "../../../hooks/useApiPostCallbackAsync";
import IndustryType from "../../../types/IndustryType";
import Site from "../../../types/Site";
import InfiniteScroll from "react-infinite-scroll-component";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Template from "../../../types/Template";

interface TemplateSelectorProps {
    site: Site,
    drawerOpen: boolean,
    setDrawerOpen?: (open: boolean) => void,
    templateSelected: (template: Template) => void,
    templateTypeId: number,
    keepFiltersOpen?: boolean
}

export default function TemplateSelector({ templateSelected, site, drawerOpen, setDrawerOpen, templateTypeId, keepFiltersOpen}: TemplateSelectorProps) {
    const [siteIndustryId, setSiteIndustryId] = useState(site.IndustryTypeId);
    const [selectedFeatureIds, setSelectedFeatureIds] = useState<string[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [templateList, setTemplateList] = useState<Template[]>([]);

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    function selectTemplate(templateId: string) {
        const selectedTemplate = templateList.find((tmpl: Template) => {
            return tmpl.Id === templateId
        });
        
        if (selectedTemplate) {
            templateSelected(selectedTemplate);
        }
    }

    const [{ data: industryFilters }] = useApiGet("/type/100", []);
    const [{ data: featureFilters }] = useApiGet("/feature", []);
    const [{ isLoading: templatesLoading }, templateSearchCallback] = useApiPostCallbackAsync('/template/search', null) as any;


    useEffect(() => {
        setPageNumber(1);
        setTotalPages(0);
        templateSearchCallback({
                TemplateIndustryId: siteIndustryId,
                FeatureIds: selectedFeatureIds,
                TemplateTypeId: templateTypeId,
                StatusTypeIds: [1101],
                CmsIds: site.CmsIds,
                Page: 1,
                PageSize: 20
            }, (result: any) => {
                if (result?.Results) {
                    setTotalPages(result.TotalPages);
                    setTemplateList(result.Results as Template[]);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteIndustryId, selectedFeatureIds]);

    useEffect(() => {
        setSiteIndustryId(site.IndustryTypeId);
    }, [site])

    const loadMoreTemplates = () => {
        templateSearchCallback({
                TemplateIndustryId: siteIndustryId,
                FeatureIds: selectedFeatureIds,
                TemplateTypeId: templateTypeId,
                StatusTypeIds: [1101],
                CmsIds: site.CmsIds,
                Page: pageNumber + 1,
                PageSize: 20
            }, (result: any) => {
                if (result?.Results) {
                    setTotalPages(result.TotalPages);
                    setTemplateList([...templateList, ...result.Results]);
                }
            });
        setPageNumber(pageNumber + 1);
    };

    if (!industryFilters) return null;
    if (!featureFilters) return null;

    const siteIndustry = industryFilters.find((x: IndustryType) => x.Id === siteIndustryId) as IndustryType;

    return (
        <>
            <Drawer
                id="drawerComp"
                sx={{ display: { md: 'none' } }}
                anchor={'left'}
                open={drawerOpen}
                onClose={() => {
                    if (setDrawerOpen) {
                        setDrawerOpen(false)
                    }
                }}
                >
                    <Box sx={{ padding: "16px" }}>
                        <TemplateFilters
                            siteIndustry={siteIndustry}
                            industryFilters={industryFilters}
                            featureFilters={featureFilters}
                            setSiteIndustryId={setSiteIndustryId}
                            selectedFeatureIds={selectedFeatureIds}
                            setSelectedFeatureIds={setSelectedFeatureIds}
                            stacked={true}
                        />
                    </Box>
            </Drawer>
            <Box id="templateSelector" data-testid="templateSelector" sx={{height: "100%"}}>
                { (!isMobileView || keepFiltersOpen) && 
                    <TemplateFilters
                        siteIndustry={siteIndustry}
                        industryFilters={industryFilters}
                        featureFilters={featureFilters}
                        selectedFeatureIds={selectedFeatureIds}
                        setSiteIndustryId={setSiteIndustryId}
                        setSelectedFeatureIds={setSelectedFeatureIds}
                        stacked={false}
                    />
                }
                { templatesLoading && <div style={{textAlign:"center", marginBottom:"49px"}}>
                    <CircularProgress /> 
                </div>}

                {!templatesLoading && templateList.length > 0 &&
                    <Box sx={{ height: isMobileView ? "100%" : "calc(100% - 105px)" }}>
                        <Box 
                            id="templateScrollDiv"
                            className="hideScrollbars"
                            sx={{
                                width: "100%",
                                overflowY: "auto",
                                height: "100%",
                                marginTop: "32px"
                            }}
                        >
                            <InfiniteScroll
                                    dataLength={templateList.length}
                                    next={loadMoreTemplates}
                                    hasMore={totalPages > pageNumber}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="templateScrollDiv"
                                    style={{ display: "flex", flexWrap: "wrap", gap: "32px", justifyContent: "space-around" }}
                                >
                                    {templateList.map((tmpl: any) => 
                                        <TemplateListItem
                                            key={tmpl.Id}
                                            template={tmpl}
                                            handleSelect={selectTemplate}
                                        />
                                    )}
                            </InfiniteScroll>
                        </Box>
                    </Box>
                }
                {!templatesLoading && templateList.length === 0 &&
                    <Box sx={{ width: "100%", marginTop: "64px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <ErrorAlertMessage 
                            message="No templates found, please adjust your filters." 
                        />
                    </Box>
                }
            </Box>
        </>
    );
}