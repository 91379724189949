import Site from "../../../../../types/Site";
import Template from "../../../../../types/Template";
import BuilderDialog from "../../../../common/BuilderDialog";
import TemplateSelector from "../../../../common/template_selector/TemplateSelector";
import TemplateTypes from "../../../../../types/enum/TemplateTypes";
import { SwapTemplateSteps } from "./SwapTemplate";

interface SwapTemplateStepOneModalProps {
    showModal: boolean,
    setCurrentStep: (step: SwapTemplateSteps) => void,
    templateType: TemplateTypes,
    site: Site | null,
    cancel: () => void,
    setSelectedTemplate: (tmpl: Template) => void
}

export default function SwapTemplateStepOneModal({
    showModal,
    setCurrentStep,
    templateType,
    site,
    cancel,
    setSelectedTemplate
}: SwapTemplateStepOneModalProps){
    

    const getTitle = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Pick a New Site Template";
            case TemplateTypes.Page:
                return "Pick a New Page Template";
            default:
                return "";
        }
    }

    const previousStep = () => {
        setCurrentStep(SwapTemplateSteps.StepOne);
    }

    const templateSelected = (tmpl: Template) => {
        setSelectedTemplate(tmpl);
        setCurrentStep(SwapTemplateSteps.StepThree);
    };

    return (
        <BuilderDialog
            title={getTitle()}
            open={showModal}
            onClose={cancel}
            showCancel={true}
            backAction={previousStep}
            maxWidth={"xl"}
        >
            
        <div style={{ padding: "16px", height: "75vh" }}>
          { site && 
            <TemplateSelector templateSelected={templateSelected} site={site} drawerOpen={false} 
              setDrawerOpen={() => {}} templateTypeId={templateType} keepFiltersOpen={true} />
          }
        </div>

        </BuilderDialog>
    )
}