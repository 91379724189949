import { useState } from "react";
import { Paper, Popper, Typography } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

export interface WarningIconWithTooltipProps {
    tooltipMessage: string
}

export default function WarningIconWithTooltip({tooltipMessage}: WarningIconWithTooltipProps) {
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(false);

    return (
        <>
            <WarningIcon
                sx={{
                    color: "#CF1F2E",
                    cursor: "help",
                    marginLeft: "18px"
                }}
                ref={setAnchorEl}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)} />
            {tooltipMessage &&
                <Popper
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    placement={'left'}
                    modifiers={[{
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    }]}
                >
                    <span
                        className='arrow'
                        ref={setArrowRef} />
                    <Paper sx={{
                        backgroundColor: '#1B1AFF',
                        color: "white",
                        marginRight: '16px'
                    }}>
                        <Typography sx={{ p: 2 }}>{tooltipMessage}</Typography>
                    </Paper>
                </Popper>
            }
        </>
    );
}