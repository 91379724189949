import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

const isConfiguredProperly = (page: Page | null) : Boolean => {
    const siteKey = getPropertyValue(page ? page.SiteProperties : [], PropertyId.GoogleRecaptchaSiteKey);

    return siteKey ? true : false;
};

export function Block(props: BlockProps) {
    const size = getPropertyValue(props.block.Properties, PropertyId.GoogleRecaptchaSize);
    const theme = getPropertyValue(props.block.Properties, PropertyId.GoogleRecaptchaTheme);
    return (
        <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: "grecaptcha", blockWrapperData: props.blockWrapperData})}>
            { !size && !theme && <img src="/images/recaptcha.png" alt="Google Recaptcha" style={{ width: '300px', maxWidth: '300px' }} className="g-recaptcha" /> }
            { !size && theme === "d" && <img src="/images/recaptcha_dark.png" alt="Google Recaptcha" style={{ width: '300px', maxWidth: '300px' }} className="g-recaptcha" /> }
            { size === "c" && !theme && <img src="/images/recaptcha_compact.png" alt="Google Recaptcha" style={{ width: '164px', maxWidth: '144px' }} className="g-recaptcha" /> }
            { size === "c" && theme === "d" && <img src="/images/recaptcha_compact_dark.png" alt="Google Recaptcha" style={{ width: '164px', maxWidth: '144px' }} className="g-recaptcha" /> }
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(page);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}