import { useState } from 'react';
import NavBar from '../common/NavBar';
import { Button }  from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BuilderDialog from '../common/BuilderDialog';
import CustomBlock from '../../types/CustomBlock';

interface CustomBlockEditorNavBarProps {
    block: CustomBlock | null,
    originalBlock: CustomBlock | null,
    goToCustomBlocks: () => void,
    saveBlock: (onSaveCallback?: () => void | undefined) => void
}

export default function CustomBlockEditorNavBar({block, originalBlock, goToCustomBlocks, saveBlock}: CustomBlockEditorNavBarProps) {
    const [showExitDialog, setShowExitDialog] = useState(false);

    const hasChanges = () => {
        return block?.Name !== originalBlock?.Name
            || block?.Description !== originalBlock?.Description
            || block?.CategoryTypeId !== originalBlock?.CategoryTypeId
            || block?.HTML !== originalBlock?.HTML;
    };

    const handleOnExit = () => {
        if (hasChanges()) {
            setShowExitDialog(true);
        } else {
            goToCustomBlocks();
        }
    };

    const saveAndExit = () => {
        saveBlock(() => {
            setShowExitDialog(false);
            goToCustomBlocks();
        });
    };

    return (
        <>
        <NavBar
            title=""
            hideMenu={true}
            zIndex={1}
            leftContent={
                <Button
                    sx={{ color: "white", paddingLeft: "0px" }}
                    onClick={handleOnExit}
                    variant="text"
                    data-testid="exit-btn"
                    startIcon={<ExitToAppIcon />}
                >
                    Exit Block Designer
                </Button>
            }
            rightContent={
                block ? <div
                    data-testid="save-menu-btn"
                    style={{
                        borderLeft: "1px solid white",
                        height: "48px",
                        display: "flex",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        zIndex: 1400
                    }}
                    onClick={() => saveBlock() }
                >
                    <div style={{ marginRight: "8px" }}>
                        <SaveOutlinedIcon />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <div style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "16px",
                            letterSpacing: "1.25px",
                            textTransform: "uppercase"
                        }}>
                            Save Block
                        </div>
                    </div>
                </div>
                : undefined
            }
        />
        <BuilderDialog
            title="Exit Block Designer"
            message="Would you like to save your changes to this block before exiting?"
            action={saveAndExit}
            actionName="Save"
            open={showExitDialog}
            actionButtonType="contained"
            onClose={() => setShowExitDialog(false) }
            showCancel={true}
            secondaryActionName="Discard Changes"
            secondaryActionButtonType="text"
            secondaryAction={goToCustomBlocks}
            secondaryActionColor="error"
        />
        </>
    )
}