import Property from '../types/Property';
import BlockMapping from '../types/BlockMapping';
import BuilderProps from '../types/BuilderProps';
import PropertyId from '../types/enum/PropertyId';
import { hexToRgb } from './colorHelpers';
import BlockWrapperData from '../types/BlockWrapperData';
import BlockMappingStatusId from '../types/enum/BlockMappingStatusId';
import HoverData from '../types/HoverData';
import Page from '../types/Page';
import BlockTemplate from '../types/BlockTemplate';

export function getPropertyValue(properties: Property[] | undefined, propertyId: PropertyId) {
    return properties?.find(p => p.EnumId === propertyId)?.RenderedValue;
}

export function getPropertyObject(properties: Property[] | undefined, propertyId: PropertyId, defaultValue: any | null = null) {
    const value = getPropertyValue(properties, propertyId);
    return value ? JSON.parse(value) : defaultValue;
}

export function propertyHasValue(properties: Property[] | undefined, propertyId: PropertyId) {
    const value = getPropertyValue(properties, propertyId);
    return value && value.length > 0;
}

export function getPropertyUnrenderedValue(properties: Property[] | undefined, propertyId: PropertyId) {
    return properties?.find(p => p.EnumId === propertyId)?.Value;
}

export function getPropertyBooleanValue(properties: Property[] | undefined, propertyId: PropertyId) {
    let value = getPropertyValue(properties, propertyId);

    return !!(value && (/^(true|1|on|yes)$/i).test(value));
}

export function doesValueMatchString(properties: Property[] | undefined, propertyId: PropertyId, matchValue: any) {
    let value = getPropertyValue(properties, propertyId);

    return value === matchValue.toString();
}

export function getImageUrlWithPlaceholder(imageConfig: any) {
    if (!imageConfig?.u && imageConfig?.p) {
        return '/placeholder.png'
    }

    return imageConfig?.u;
}

function addStyle(styles: any, block: BlockMapping, cssName: string, propertyId: PropertyId) {
    const value = getPropertyValue(block.Properties, propertyId);

    if (value) {
        styles[cssName] = value;
    }
}

function addFontStyle(styles: any, block: BlockMapping, propertyId: PropertyId) {
    const value = getPropertyValue(block.Properties, propertyId);
    const font = JSON.parse(value || "{}")

    if (font?.f && font?.w) {
        styles["fontFamily"] = font.f;
        styles["fontWeight"] = font.w;
    }
}

function addImageStyle(styles: any, block: BlockMapping, propertyId: PropertyId) {
    const value = getPropertyValue(block.Properties, propertyId);
    const image = JSON.parse(value || "{}")
    const url = getImageUrlWithPlaceholder(image);
    if (url) {
        styles["backgroundImage"] = "url('" + url.replaceAll("'", '%27') + "')";
    }
}

function addColorStyle(styles: any, block: BlockMapping, cssName: string, propertyId: PropertyId) {
    const value = getPropertyValue(block.Properties, propertyId);
    const color = JSON.parse(value || "{}")

    if (color?.hex) {
        const [r, g, b] = hexToRgb(color.hex)!;
        const a = color.a || 1;
        if ((r || r === 0) && (g || g === 0) && (b || b === 0)) {
            styles[cssName] = "rgba(" + [r, g, b, a].join(", ") + ")";
        }
    }
}

function addNumberStyle(styles: any, block: BlockMapping, cssName: string, propertyId: PropertyId, unit: string) {
    const value = getPropertyValue(block.Properties, propertyId);
    const number = JSON.parse(value || "{}")

    if (value) {
        styles[cssName] = number + unit;
    }
}

function getStyles(block: BlockMapping) {
    const styles: any = {};

    addStyle(styles, block, "textTransform", PropertyId.CssTextTransform);
    addNumberStyle(styles, block, "width", PropertyId.Width, "px");
    addNumberStyle(styles, block, "height", PropertyId.Height, "px");
    addColorStyle(styles, block, "backgroundColor", PropertyId.BackgroundColor);
    addColorStyle(styles, block, "color", PropertyId.TextColor);
    addFontStyle(styles, block, PropertyId.Font);
    addStyle(styles, block, "textAlign", PropertyId.TextAlign);
    addStyle(styles, block, "borderRadius", PropertyId.BorderRadius);
    addNumberStyle(styles, block, "borderWidth", PropertyId.BorderWidth, "px");
    addStyle(styles, block, "borderStyle", PropertyId.BorderStyle);
    addStyle(styles, block, "display", PropertyId.Display);
    addStyle(styles, block, "flexDirection", PropertyId.FlexDirection);
    addStyle(styles, block, "alignItems", PropertyId.AlignItems);
    addStyle(styles, block, "justifyContent", PropertyId.JustifyContent);
    addImageStyle(styles, block, PropertyId.BackgroundImage);
    addStyle(styles, block, "backgroundSize", PropertyId.BackgroundSize);
    addStyle(styles, block, "backgroundPosition", PropertyId.BackgroundPosition);
    addStyle(styles, block, "fontSize", PropertyId.FontSize);
    addStyle(styles, block, "marginLeft", PropertyId.MarginLeft);
    addStyle(styles, block, "marginRight", PropertyId.MarginRight);
    addStyle(styles, block, "marginTop", PropertyId.MarginTop);
    addStyle(styles, block, "marginBottom", PropertyId.MarginBottom);
    addStyle(styles, block, "paddingLeft", PropertyId.PaddingLeft);
    addStyle(styles, block, "paddingRight", PropertyId.PaddingRight);
    addStyle(styles, block, "paddingTop", PropertyId.PaddingTop);
    addStyle(styles, block, "paddingBottom", PropertyId.PaddingBottom);

    addStyle(styles, block, "flexWrap", PropertyId.FlexWrap);
    addNumberStyle(styles, block, "flexBasis", PropertyId.FlexBasis, "px");
    addStyle(styles, block, "flexGrow", PropertyId.FlexGrow);
    addStyle(styles, block, "flexShrink", PropertyId.FlexShrink);
    addNumberStyle(styles, block, "minWidth", PropertyId.MinWidth, "px");
    addNumberStyle(styles, block, "maxWidth", PropertyId.MaxWidth, "px");
    addNumberStyle(styles, block, "minHeight", PropertyId.MinHeight, "px");
    addNumberStyle(styles, block, "maxHeight", PropertyId.MaxHeight, "px");
    addStyle(styles, block, "opacity", PropertyId.Opacity);
    addColorStyle(styles, block, "borderColor", PropertyId.BorderColor);
    addStyle(styles, block, "overflow", PropertyId.Overflow);
    addStyle(styles, block, "overflowWrap", PropertyId.OverflowWrap);
    addStyle(styles, block, "float", PropertyId.Float);
    addNumberStyle(styles, block, "top", PropertyId.Top, "px");
    addNumberStyle(styles, block, "bottom", PropertyId.Bottom, "px");
    addNumberStyle(styles, block, "left", PropertyId.Left, "px");
    addNumberStyle(styles, block, "right", PropertyId.Right, "px");
    addStyle(styles, block, "zIndex", PropertyId.ZIndex);
    addStyle(styles, block, "position", PropertyId.Position);
    addStyle(styles, block, "verticalAlign", PropertyId.VerticalAlign);
    addStyle(styles, block, "alignContent", PropertyId.AlignContent);
    addStyle(styles, block, "alignSelf", PropertyId.AlignSelf);
    addStyle(styles, block, "backgroundAttachment", PropertyId.BackgroundAttachment);
    addStyle(styles, block, "backgroundRepeat", PropertyId.BackgroundRepeat);
    addNumberStyle(styles, block, "lineHeight", PropertyId.LineHeight, "px");
    addStyle(styles, block, "wordBreak", PropertyId.WordBreak);
    addNumberStyle(styles, block, "wordSpacing", PropertyId.WordSpacing, "px");
    addColorStyle(styles, block, "textDecorationColor", PropertyId.TextDecorationColor);
    addStyle(styles, block, "textDecorationLine", PropertyId.TextDecorationLine);
    addStyle(styles, block, "textDecorationStyle", PropertyId.TextDecorationStyle);
    addStyle(styles, block, "fontStyle", PropertyId.FontStyle);
    addStyle(styles, block, "listStyleType", PropertyId.ListStyleType);
    addStyle(styles, block, "listStylePosition", PropertyId.ListStylePosition);

    return styles;
}

export interface DynamicAttributesProps {
    block: BlockMapping,
    builderProps?: BuilderProps,
    className?: string | null | undefined,
    includeHover?: boolean,
    includeId?: boolean,
    styles?: object | null | undefined,
    blockWrapperData?: BlockWrapperData,
    idSuffix?: string | undefined
}

export function getDynamicAttributes({block, builderProps, className, includeHover = true, styles, blockWrapperData, idSuffix}: DynamicAttributesProps): any {
    const attributes = includeHover 
        ? getHoverAttributes({block, builderProps}) 
        : { className: "" };

    attributes.id = getPropertyValue(block.Properties, PropertyId.ID);
    
    if (attributes.id && idSuffix) {
        attributes.id = attributes.id + idSuffix;
    }

    attributes['aria-label'] = getPropertyValue(block.Properties, PropertyId.AriaLabel);
    let propertyStyles: any = getStyles(block);

    if (block.StatusTypeId === BlockMappingStatusId.Inactive) {
        propertyStyles["opacity"] = .4;
    }

    attributes.style = {...propertyStyles, ...styles};
    attributes.className += getSharedClassNames(block, className);

    return {...attributes, ...getBlockWrapperAttributes(blockWrapperData)};
}

export function getBlockWrapperAttributes(blockWrapperData?: BlockWrapperData): any {
    const attributes: any = {};
    attributes.ref = blockWrapperData?.ref;
    attributes["data-handler-id"] = blockWrapperData?.handlerId;
    return attributes;
}


export function isChildrenPositionBefore(block: BlockMapping) {
    const value = getPropertyValue(block.Properties, PropertyId.ChildrenPosition);
    if (value) {
        return value.toLowerCase() === "before";
    } else {
        return false;
    }
}

export interface HoverAttributesProps {
    block: BlockMapping,
    builderProps?: BuilderProps,
    classes?: string | null | undefined
}

export function getHoverAttributes({block, builderProps, classes}: HoverAttributesProps): any {
    let className = "";
    if (!builderProps?.dragDrop?.isDragging && 
        builderProps?.hoverBlock 
        && block.Id === builderProps.hoverBlock.Id) {
        className += 'hover-block ';
    }
    if (!builderProps?.dragDrop?.isDragging && 
        builderProps?.selectedBlocks && 
        builderProps.selectedBlocks.findIndex(b => b.Id === block.Id) > -1) {
        className += 'selected-block ';
    }
    if (classes) {
        className += classes
    }

    const attributes = {
        className,
        onMouseOver: (e: any) => { 
            e.stopPropagation();
            if (builderProps?.onBlockHover) {
                builderProps.onBlockHover(block, e);
            }
        },
        onClick: (e: any) => { 
            e.stopPropagation(); 
            if (builderProps?.blockClicked) {
                builderProps.blockClicked(block, e); 
            }
        }
    }

    return attributes;
}

export function getSharedClassNames(block: BlockMapping, initialClassNames: string | null | undefined, builderProps?: BuilderProps) {
    let classNames = "";
    if (initialClassNames) {
        classNames += initialClassNames;
    }
    if (builderProps?.PreviewBlockId && block.Id === builderProps.PreviewBlockId) {
        classNames += ' preview-block';
    }
    return classNames;
}

export function getAllIdPropertyValues(blocks: BlockMapping[] | undefined, filterDestinationTargets = true) {
    let ids: string[] = [];
    if (blocks) {
        for (let block of blocks) {
            const id = getPropertyValue(block.Properties, PropertyId.ID);
            const isDestinationTarget = getPropertyBooleanValue(block.Properties, PropertyId.IsDestinationTarget);

            if (id && (!filterDestinationTargets || (filterDestinationTargets && isDestinationTarget))) {
                ids.push(id);
            } 

            ids = ids.concat(getAllIdPropertyValues(block.BlockMappings));
        }
    }
    return ids;
}

export function getSanitizedUrlForBackgroundImage(url: string) {
    if (!url)
    {
        return "";
    }
    return url.replace(/[ '()]/g, m => "%" + m.charCodeAt(0).toString(16));
}

export function stripHtml(contentHTML: string) {
    return contentHTML.replaceAll(/<.*?>/g, " ").replaceAll(/&#?[a-z0-9]+;/g, " ");
}

export function blocksAreSiblings(blocks: BlockMapping[]) {
    var firstParent = blocks[0].ParentId;

    return !blocks.find(b => b.ParentId !== firstParent);
}

export const appendAllChildrenIds = (block: BlockMapping, results: string[]) => {
    if (block.BlockMappings && block.BlockMappings.length > 0) {
        for(const child of block.BlockMappings) {
            results.push(child.Id);
            appendAllChildrenIds(child, results);
        }
    }
}

export const getBlockMapping = (blockMappingId: string, blockMappings: BlockMapping[] | undefined): BlockMapping | null => {
    if (blockMappings) {
        for (var blockMapping of blockMappings) {
            if (blockMapping.Id === blockMappingId) {
                return blockMapping;
            }

            const childBlockMapping = getBlockMapping(blockMappingId, blockMapping.BlockMappings);

            if (childBlockMapping !== null) {
                return childBlockMapping;
            }
        }
    }

    return null;
}

export const findAndRemoveBlockMapping = (blockMappingId: string, blockMappings: BlockMapping[], parentBlockMappingId: string | null)
    : { blockMapping: BlockMapping, hoverBlockMappingId: string | null, insertBefore: boolean, insertAfter: boolean, insertInto: boolean } | null => {
    if (blockMappingId && blockMappings) {
        let foundIndex = -1;
        let foundBlockMapping = null;
        let insertBefore = false;
        let insertAfter = false;
        let insertInto = false;
        let hoverBlockMappingId = null;

        for (let i = 0; i < blockMappings.length; i++) {
            if (blockMappings[i].Id === blockMappingId) {
                foundIndex = i;
                foundBlockMapping = blockMappings[i];
                // If the block is the only or last child of this parent, the retry operation
                // will use insertInto with the parent id, otherwise we will use insert before
                // or after a sibling
                if (i + 1 === blockMappings.length) {
                    hoverBlockMappingId = parentBlockMappingId;
                    insertInto = true;
                } 
                else if (i === 0 && blockMappings.length > 1) {
                    hoverBlockMappingId = blockMappings[i + 1].Id;
                    insertBefore = true;
                }
                else {
                    hoverBlockMappingId = blockMappings[i - 1].Id;
                    insertAfter = true;
                }
                break;
            }

            const found = findAndRemoveBlockMapping(blockMappingId, blockMappings[i].BlockMappings || [], blockMappings[i].Id);

            if (found) {
                return found;
            }
        }

        if (foundIndex >= 0 && foundBlockMapping) {
            blockMappings.splice(foundIndex, 1);
            // We are going to track where this was at, so we can undo this operation if the move
            // call to the API fails
            return {
                blockMapping: foundBlockMapping,
                hoverBlockMappingId,
                insertAfter,
                insertBefore,
                insertInto
            };
        }
    }

    return null;
}

const insertBeforeOrAfter = (blockMapping: BlockMapping | null, blockMappings: BlockMapping[]
    , hoverBlockMappingId: string, insertBefore: boolean | undefined, insertAfter: boolean | undefined
    , parentBlockMappingId: string | null, doInsert: boolean, parentBlockMapping: BlockMapping | null)
    : { ParentBlockMappingId: string | null, BlockOrder: number, ParentBlockMapping: BlockMapping | null } | null => {
    let childIndex = -1;
    for (let i = 0; i < blockMappings.length; i++) {
        if (blockMappings[i].Id === hoverBlockMappingId) {
            childIndex = i;
            break;
        }
    }

    if (childIndex >= 0) {
        if (doInsert && blockMapping) {
            if (insertBefore) {
                blockMappings.splice(childIndex, 0, blockMapping);
            }
            else {
                blockMappings.splice(childIndex + 1, 0, blockMapping);
            }
        }
        return {
            ParentBlockMappingId: parentBlockMappingId,
            BlockOrder: insertBefore ? childIndex + 1 : childIndex + 2,
            ParentBlockMapping: parentBlockMapping
        };
    }

    return null;
};

export const insertBlockMapping = (blockMapping: BlockMapping | null, blockMappings: BlockMapping[]
    , hoverBlockMappingId: string, insertInto: boolean | undefined, insertBefore: boolean | undefined
    , insertAfter: boolean | undefined, isRoot: boolean, doInsert: boolean)
    : { ParentBlockMappingId: string | null, BlockOrder: number, ParentBlockMapping: BlockMapping | null } | null => {
    if ((blockMapping || !doInsert) && blockMappings && hoverBlockMappingId) {
        if (isRoot && (insertBefore || insertAfter)) {
            const parentAndPosition = insertBeforeOrAfter(blockMapping, blockMappings
                , hoverBlockMappingId, insertBefore, insertAfter, null, doInsert, null);

            if (parentAndPosition) {
                return parentAndPosition;
            }
        }
        for (let i = 0; i < blockMappings.length; i++) {

            // If inserting directly into this block, add
            if (insertInto && blockMappings[i].Id === hoverBlockMappingId) {
                if (doInsert && blockMapping) {
                    blockMappings[i].BlockMappings?.push(blockMapping);
                }
                return {
                    ParentBlockMappingId: blockMappings[i].Id,
                    BlockOrder: (blockMappings[i]?.BlockMappings?.length || 0) + 1,
                    ParentBlockMapping: blockMappings[i]
                };
            }

            // If inserting before or after a block mapping, check this parent to see if the
            // block mapping is a child of this block mapping
            if (insertBefore || insertAfter) {
                const parentAndPosition = insertBeforeOrAfter(blockMapping, blockMappings[i].BlockMappings || []
                    , hoverBlockMappingId, insertBefore, insertAfter, blockMappings[i].Id, doInsert
                    , blockMappings[i]);

                if (parentAndPosition) {
                    return parentAndPosition;
                }
            }

            // Recursively check children
            const parentAndPosition = insertBlockMapping(blockMapping, blockMappings[i].BlockMappings || []
                , hoverBlockMappingId, insertInto, insertBefore, insertAfter, false, doInsert);

            if (parentAndPosition) {
                return parentAndPosition;
            }
        }
    }

    return null;
}

export const getParentBlockMapping = (blockMappingId: string, blockMappings: BlockMapping[], parentBlockMapping: BlockMapping | null): BlockMapping | null => {
    if (blockMappings) {
        for (var blockMapping of blockMappings) {
            if (blockMapping.Id === blockMappingId) {
                return parentBlockMapping;
            }

            const childBlockMapping = getParentBlockMapping(blockMappingId, blockMapping.BlockMappings, blockMapping);

            if (childBlockMapping != null) {
                return childBlockMapping;
            }
        }
    }

    return null;
}

export const blockMappingsContainTarget = (blockMappingId: string, blockMappings: BlockMapping[]): boolean => {
    if (blockMappings) {
        for (var blockMapping of blockMappings) {
            if (blockMapping.Id === blockMappingId) {
                return true;
            }

            let found = blockMappingsContainTarget(blockMappingId, blockMapping.BlockMappings);
            if (found) {
                return found;
            }
        }
    }

    return false;
}

export const blockCanBeDroppedOnParent = (page: Page, blocksAndSections: BlockTemplate[], hoverData: HoverData) => {
    if ((!hoverData.blockMappingId && !hoverData.blockId && !hoverData.templateId && !hoverData.componentId) 
        || !hoverData.hoveredId || !page) {
        return false;
    }

    let blockId: string | null | undefined = null;
    let blockMapping: BlockMapping | null = null;
    const templateId = hoverData.templateId;

    if (hoverData.blockMappingId) {
        blockMapping = getBlockMapping(hoverData.blockMappingId, page.BlockMappings);

        // blockMapping is already a child of parent, no need to move
        if (blockMapping && blockMapping.ParentId === hoverData.hoveredId)
        {
            return false;
        }
        
        // target is a child or decendant of the blockMapping, move not allowed
        if (blockMapping && blockMappingsContainTarget(hoverData.hoveredId, blockMapping.BlockMappings))
        {
            return false
        }

        if (blockMapping) {
            blockId = blockMapping.BlockId;
        }
    }
    else if (hoverData.blockId)
    {
        blockId = hoverData.blockId;
    }

    let parentBlock: BlockMapping | null = null;

    // If dropping into, get the hovered block's block ID
    // else, get the hovered block's parent's block ID
    if (hoverData.isInto) {
        parentBlock = getBlockMapping(hoverData.hoveredId, page.BlockMappings);
    } else {
        parentBlock = getParentBlockMapping(hoverData.hoveredId, page.BlockMappings, null);
    }

    let blockTemplate = null;

    if (blockId) {
        blockTemplate = blocksAndSections?.find((b: BlockTemplate) => b.b === blockId);
    } else {
        blockTemplate = blocksAndSections?.find((b: BlockTemplate) => b.t === templateId);
    }

    if (blockTemplate && blockTemplate.p && blockTemplate.p.length > -1) {
        for (const allowedParentId of blockTemplate.p) {
            if (allowedParentId === parentBlock?.BlockId) {
                return true;
            }
        }

        return false;
    }
    
    if (parentBlock !== null) {
        let parentBlockTemplate = blocksAndSections?.find((b: BlockTemplate) => b.b === parentBlock?.BlockId);

        if (parentBlockTemplate && !parentBlockTemplate.ic) {
            return false;
        }
    }

    // Only allow component blocks to be moved within the component
    if (blockMapping) {
        const blockMappingsParentBlock = getParentBlockMapping(blockMapping.Id, page.BlockMappings, null);

        // If the block being moved is inside a component (its parent has a component ID), only allow it to be
        // moved to another parent with the same component id
        if (blockMappingsParentBlock?.ComponentId && blockMappingsParentBlock.ComponentId !== parentBlock?.ComponentId) {
            return false;
        }

        // If the block being moved is not inside a component, don't allow it to be moved into a component
        if (!blockMappingsParentBlock?.ComponentId && parentBlock?.ComponentId) {
            return false;
        }
    }

    return true;
}
