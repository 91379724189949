import NavBar from "./common/NavBar";
import { Box } from "@mui/system";
import DevTestUtility from "./DevTestUtility";

function Home() {
    return (
        <>
            <NavBar title="Website Builder" />
            <Box sx={{display: "flex", flexDirection: "row", height: "calc(100% - 48px)", overflow: "auto"}}>
                <DevTestUtility />
            </Box>
        </>
    );
}

export default Home;