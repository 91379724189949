
import MonitorIcon from '@mui/icons-material/Monitor';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import BlockMapping from '../../types/BlockMapping';
import { getPropertyObject } from '../../utils/blockHelpers';
import PropertyId from '../../types/enum/PropertyId';

export interface ResponsiveDeviceIconSetProps {
    blockMapping: BlockMapping
}

export default function ResponsiveDeviceIconSet({
    blockMapping
}: ResponsiveDeviceIconSetProps) {

  const hideBlock = getPropertyObject(blockMapping.Properties, PropertyId.HideBlock);

  const showMobileIcon = hideBlock != null && hideBlock.length > 0 && hideBlock.length < 3 && !hideBlock.includes("m");
  const showTabletIcon = hideBlock != null && hideBlock.length > 0 && hideBlock.length < 3 && !hideBlock.includes("t");
  const showDesktopIcon = hideBlock != null && hideBlock.length > 0 && hideBlock.length < 3 && !hideBlock.includes("d");

return (
    <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}
        >
        {showDesktopIcon && <MonitorIcon
            data-testid="DesktopIcon"
            sx={{
                height:'28px',
                width: '28px',
                paddingLeft: '1px',
                paddingRight: '1px'
            }}
        />}
        {showTabletIcon && <TabletMacIcon
            data-testid="TabletIcon"
            sx={{
                height:'22px',
                width: '22px',
                paddingLeft: '5px',
                paddingRight: '3px'
            }}
        />}
        {showMobileIcon && <SmartphoneIcon
            data-testid="MobileIcon"
            sx={{
                height:'24px',
                width: '24px',
                paddingLeft: '3px',
                paddingRight: '3px'
            }}
        />}
    </div>
    );
}