import { useState } from 'react';
import { Box, Dialog } from '@mui/material';
import useApiGetCallbackAsync from '../../../../hooks/useApiGetCallbackAsync';
import PageInfo from '../../../../types/PageInfo';
import TemplateSelector from "../../../common/template_selector/TemplateSelector";
import TemplateTypes from "../../../../types/enum/TemplateTypes";
import Template from '../../../../types/Template';
import SidebarState from '../../../../types/enum/SidebarState';
import SiteEditorSideBarAddPageProperties from "./SiteEditorSideBarAddPageProperties";
import SiteEditorStore from '../../SiteEditorStore';

export default function SiteEditorSideBarAddPage() {
  const [selectedPageTemplate, setSelectedPageTemplate] = useState<Template | null>(null);
  const [addPage, setAddPage] = useState<PageInfo | null>(null);
  const updateSidebarState = SiteEditorStore.useStoreActions((actions) => actions.updateSidebarState);
  const site = SiteEditorStore.useStoreState((state) => state.site);
  const page = SiteEditorStore.useStoreState((state) => state.page);
  const sidebarState = SiteEditorStore.useStoreState((state) => state.sidebarState);

  const [, getTemplatePageInfoCallback] = useApiGetCallbackAsync("", []) as any;

  const templateSelected = (tmpl: Template) => {
    updateSidebarState(SidebarState.AddPageFormOpen);
    getTemplatePageInfoCallback((templateInfo: PageInfo) => {
      setAddPage(templateInfo);
      setSelectedPageTemplate(tmpl);
    }, null, `/template/page/${tmpl?.Id}/${site?.Id}`);
  };

  const exitAddPage = () => {
    setAddPage(null);
    setSelectedPageTemplate(null);
    updateSidebarState(SidebarState.PagesOpen);
  };

  return (
    <>
      <Dialog
          fullScreen={false}
          open={sidebarState === SidebarState.PageTemplatesOpen}
          onClose={() => { updateSidebarState(SidebarState.PagesOpen); }}
          fullWidth={true}
          PaperProps={{
              sx: {
                  maxWidth: "1300px"
              }
          }}
      >
        <Box sx={{ padding: "16px", height: "80vh" }}>
          { site &&
            <TemplateSelector templateSelected={templateSelected} site={site} drawerOpen={false}
              setDrawerOpen={() => {}} templateTypeId={TemplateTypes.Page} keepFiltersOpen={true} />
          }
        </Box>
      </Dialog>
      { (page || addPage) && selectedPageTemplate &&
        <SiteEditorSideBarAddPageProperties
          addPage={addPage}
          open={sidebarState === SidebarState.AddPageFormOpen}
          cancel={exitAddPage}
          complete={exitAddPage}
          errorMessage={"Error creating page"}
          callbackUri={`/site/${site?.Id}/page/template/${selectedPageTemplate?.Id}`}
        />
      }
    </>
  );
}