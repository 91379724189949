export function truncateFilename(filename: string, maxLength: number = 20) {
    if (!filename) {
        return "";
    }

    const lastPeriodIndex = filename.lastIndexOf('.'); 
    const name = filename.slice(0, lastPeriodIndex);
    const ext = filename.slice(lastPeriodIndex);
    const nameLength = name.length;

    //Two characters are removed to made room for the "..."
    const segmentLength = Math.floor((maxLength - 2) / 2);

    if (nameLength > maxLength) {
        return name.substring(0, segmentLength) + "..." + 
            name.substring(nameLength - segmentLength, nameLength) + ext;
    }

    return filename;
}
