interface TabPanelProps {
    children?: React.ReactNode;
    name: string;
    value: number;
    index: number;
    hideScrollbars?: boolean;
    height?: string;
}

export default function TabPanel({value, index, name, children, height = "100%", hideScrollbars = true}: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-pane`}
      aria-labelledby={`${name}`}
      data-testid={name}
      className={hideScrollbars ? "hideScrollbars" : ""}
      style={{ overflowY: hideScrollbars ? "auto" : undefined, height }}
    >
      {children}
    </div>
  );
}