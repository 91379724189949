import { useEffect } from 'react';
import SiteEditorStore from './SiteEditorStore';

export default function SiteEditorKeyListener() {
    const updateShiftHeld = SiteEditorStore.useStoreActions((actions) => actions.updateShiftHeld);

    function downHandler({key}: KeyboardEvent) {
        if (key === 'Shift') {
            updateShiftHeld(true);
        }
    }

    function upHandler({key}: KeyboardEvent) {
        if (key === 'Shift') {
            updateShiftHeld(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}