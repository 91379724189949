import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, getPropertyBooleanValue } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import { areArrayValuesEquivalent, uniqueArrayValues } from '../../../utils/arrayHelpers';
import { generateRgbaValue } from '../../../utils/colorHelpers';
import StarRating from '../StarRating';

export function Block({block, builderProps, blockWrapperData}: BlockProps) {
    const locationIdsValue = getPropertyValue(block.Properties, PropertyId.ReviewsLocationIds);
    const locationIds = locationIdsValue ? uniqueArrayValues(JSON.parse(locationIdsValue)) : null;
    const disableReadMore = getPropertyBooleanValue(block.Properties, PropertyId.ReviewsDisableReadMore);
    const readMoreLinkColorProperty = JSON.parse(getPropertyValue(block.Properties, PropertyId.ReviewsReadMoreLinkColor) || '{}');
    const starColorProperty = JSON.parse(getPropertyValue(block.Properties, PropertyId.ReviewsStarColor) || '{}');
    const readMoreLinkStyles: React.CSSProperties = {
        color: generateRgbaValue(readMoreLinkColorProperty),
    };
    const starStyles: React.CSSProperties = {
        color: generateRgbaValue(starColorProperty),
    };
    const reviewSummary = builderProps?.reviewSummaries?.find(r => r.l && areArrayValuesEquivalent(r.l, locationIds));

    if (!reviewSummary) {
        return <></>;
    }

    return (
        <div {...getDynamicAttributes({block, builderProps, className: "review-summary", blockWrapperData})}>
            {reviewSummary.a &&
                <>
                    <div className="review-stars-container mb-2 flex" style={starStyles}>
                        <StarRating rating={reviewSummary.a} />
                    </div>
                    <div>{reviewSummary.a.toFixed(1)} stars | {`${reviewSummary.c}`} reviews</div>
                </>
            }
            {!reviewSummary.a &&
                <div>{`${reviewSummary.c}`} reviews</div>
            }
            {!disableReadMore &&
                <label className="mt-2 reviews-read-more" style={readMoreLinkStyles}>
                    Read More Reviews
                    <span className="material-icons ml-1" title="Arrow Forward" aria-hidden="true">arrow_forward</span>
                </label>
            }
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    const locationIdsValue = getPropertyValue(block.Properties, PropertyId.ReviewsLocationIds);
    const locationIds = locationIdsValue ? JSON.parse(locationIdsValue) : null;

    return locationIds?.length > 0;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string): boolean {
    return defaultFilter(block, filterText);
}