import { useEffect, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';


export default function useApiGet(uri: string, initialData: any, apiPrefix?: string, successCallback?: (data: any) => void) {
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            setErrorResponse(null);

            try {
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                const data = await makeApiCall(uri, httpMethods.get, identityHeaders, null, apiPrefix);

                setData(data);

                if (successCallback) {
                    successCallback(data);
                }
            } catch (error: any) {
                console.log(error);
                setIsError(true);
                setErrorResponse(error);
            }

            setIsLoading(false);
        };

        if (uri) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uri, getAccessTokenSilently, currentUser, sessionId, correlationId]);

    return [{ data, isLoading, isError, errorResponse }];
} 
