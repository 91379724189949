import { getDynamicAttributes, getPropertyValue } from '../../../utils/blockHelpers';
import PropertyId from '../../../types/enum/PropertyId';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block(props: BlockProps) {
    const siteKey = getPropertyValue(props.page?.SiteProperties, PropertyId.GoogleRecaptchaSiteKey);
    const secretKey = getPropertyValue(props.page?.SiteProperties, PropertyId.GoogleRecaptchaSecretKey);

    if (!siteKey && !secretKey) {
        return <></>;
    }

    return (
        <form 
            {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: "container max-w-2xl mx-auto px-8 md:px-20 py-10 text-center", blockWrapperData: props.blockWrapperData})} 
        >
            {props.children}
        </form>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}