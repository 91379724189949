import SearchFilterInput from '../../../common/SearchFilterInput';
import SiteEditorStore from '../../SiteEditorStore';

export default function HierarchyFilter() {
  const hierarchyFilter = SiteEditorStore.useStoreState((state) => state.hierarchyFilter);
  const hierarchyFilterHasResults = SiteEditorStore.useStoreState((state) => state.hierarchyFilterHasResults);
  const updateHierarchyFilter = SiteEditorStore.useStoreActions((actions) => actions.updateHierarchyFilter);

  return (
    <div style={{padding: "20px"}}>
      <SearchFilterInput
        placeholder='Search page blocks'
        value={hierarchyFilter}
        id={'HierarchySearch'}
        onChange={function (newValue: string, originalCase: string): void {
          updateHierarchyFilter(originalCase);
        }}
        noResults={!hierarchyFilterHasResults}
        />
    </div>
  );

}