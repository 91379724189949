import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory';
import { UserContextProvider } from './UserContext';
import BuilderStore from './BuilderStore';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import { FeatureToggleDefaults } from './components/config/FeatureToggleDefaults';

fetch('/config.json', { headers: { Accept: 'application/json' } }).then((response) => {
  response.json().then((data) => {
    (window as any).CONFIG = data;

    const container = document.getElementById('root');
    const root = createRoot(container as HTMLElement);

    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
              <UserContextProvider user={null}>
                <BuilderStore.Provider>
                  <FlagsmithProvider options={{
                    environmentID: data.Flagsmith.EnvironmentId,
                    defaultFlags: FeatureToggleDefaults
                  }} flagsmith={flagsmith}>
                    <App />
                  </FlagsmithProvider>
                </BuilderStore.Provider>
              </UserContextProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
});
