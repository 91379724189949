import CategoryType from '../types/CategoryType';
import BlockTemplate from '../types/BlockTemplate';

export function organizeCategories(categories: CategoryType[] | null): CategoryType[] {
  const categoryList: CategoryType[] = [];
  let sortedCategories: any[] = [];

  const addBlocksToList = (category: CategoryType) => {
    const childCategories = sortedCategories.filter((c: any) => c.ParentTypeId === category.Id);

    for (let childCategory of childCategories) {
      const newPropertyCategory = {
        Id: childCategory.Id,
        Name: childCategory.Name,
        Children: []
      };
      category.Children.push(newPropertyCategory);
      addBlocksToList(newPropertyCategory);
    } 
  };

  if (categories) {
    sortedCategories = categories.sort((a: any, b: any) => a.Name.localeCompare(b.Name));
    const parentCategories = sortedCategories.filter((c: any) => c.ParentTypeId === null)
      .concat({
        Id: 999,
        Name: 'Builder Actions',
        Children: []
    });

    for (let category of parentCategories) {
      const newPropertyCategory = {
        Id: category.Id,
        Name: category.Name,
        Children: []
      };
      categoryList.push(newPropertyCategory);
      addBlocksToList(newPropertyCategory);
    }
  }

  return categoryList;
}

export function isBlockTemplateCategoryUsed(category: CategoryType, blockTemplates: BlockTemplate[]) : boolean {
    if (!category || !blockTemplates) {
      return false;
    }
  
    if (blockTemplates.filter((t) => t?.c === category.Id).length > 0) {
      return true;
    }
  
    for (let childCategory of category.Children) {
      if (isBlockTemplateCategoryUsed(childCategory, blockTemplates)) {
        return true;
      }
    }
  
    return false;
}