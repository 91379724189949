import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore } from '../../../utils/blockHelpers';
import DangerousText from '../DangerousText';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    return (
        <address {...getDynamicAttributes({block, builderProps, className: "secondary-color p-4 text-center nap", blockWrapperData})}>
            {isChildrenPositionBefore(block) && children}
            <DangerousText html={content}/>
            {!isChildrenPositionBefore(block) && children}
        </address>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return false;
}

export function Placeholder(props: BlockProps) {
    return null;
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}