import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import ImageIcon from '@mui/icons-material/Image';
import PropertyValueType from "../../types/enum/PropertyValueType";
import Property from '../../types/Property';
import { getFileNameFromUrl } from '../../utils/urlHelpers';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import FileRecord from '../../types/FileRecord';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import FileType from '../../types/enum/FileType';
import { FilePropertyEditor } from '../common/FilePropertyEditor';

export function Editor({ property, propertyUpdated, renderOptions, isPageEditor, siteProperties, setMostRecentValue}: PropertyEditorProps) {
    renderOptions = renderOptions ?? {};
    const imageObject = JSON.parse(property?.RenderedValue || '{}');
    const [altText, setAltText] = useState(imageObject?.a || '');
    const [imageUrl, setImageUrl] = useState(imageObject?.u);

    const config = JSON.parse(property?.Configuration || '{}');
    const swapPropertyId = property && property.Value && property.Value.startsWith("[P:") ?
                property.Value.replace('[P:', '').replace(']', '').toLowerCase() : null;

    const showAlt = swapPropertyId ? false : config.ShowAlt ?? true;

    const stringifyImage = (url: string | null, alt: string) => {
        return JSON.stringify({
            u: url,
            a: alt
        });
    };

    useEffect(() => {
        if (imageUrl !== imageObject?.u) {
            setImageUrl(imageObject?.u);
        }
        if (altText !== imageObject?.a) {
            setAltText(imageObject?.a || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property]);

    const setImagePropertyValue = (url:string | null, alt:string | null, file?: FileRecord | null) => {
        if (property) {
            let propValue: string | null = null;

            if (url) {
                propValue = stringifyImage(url, alt ?? "");
            }

            property.Value = propValue;
            property.RenderedValue = propValue;

            if (propertyUpdated) {
                propertyUpdated(property, file);
            }
        } 
    }

    const updateImagePropertyValue = (img: FileRecord | null) => {
        setImagePropertyValue(img?.URL ?? null, altText, img);
    };

    const updateImagePropertySwapValue = (selectedProperty: Property, file: FileRecord) => {
        if (property && selectedProperty) {
            property.Value = `[P:${selectedProperty.PropertyId}]`;
            const siteProperty = siteProperties?.find(p => p.PropertyId === selectedProperty.PropertyId);
            property.RenderedValue = siteProperty?.RenderedValue;
            if (propertyUpdated) {
                propertyUpdated(property, file);
            }
        }
    };

    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);
    const updateAltText = (text: string) => {
        setAltText(text);
        if (setMostRecentValue) {
            setMostRecentValue(JSON.stringify({u: imageObject.u, a: text}));
        }

        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            setImagePropertyValue(imageObject?.u ?? null, text);
        }, 300));
    }

    const clearImage = () => {
        updateImagePropertyValue(null);
    };

    return (
        <div data-testid="image-property">
            <FilePropertyEditor
                onPropertySave={updateImagePropertySwapValue}
                onFileSave={updateImagePropertyValue}
                onClearProperty={clearImage}
                fileUrl={imageObject?.u}
                linkedPropertyId={swapPropertyId}
                placeholderText='Click to set an image'
                selectButtonText='SELECT AN IMAGE'
                clearButtonText='CLEAR IMAGE'
                selectIcon={<ImageIcon sx={{ color: "white" }} />}
                property={property}
                isPageEditor={isPageEditor}
                siteProperties={siteProperties}
                allowedFileTypes={[FileType.Image]}
                allowedSitePropertyValueTypeIds={[PropertyValueType.Image]}
                centered={renderOptions?.centered}
            />
            {showAlt && (!isPageEditor || imageObject?.u) && 
                <>
                { isPageEditor && 
                    <label
                        className="body1"
                    >
                        {property?.Name} Alt Text
                    </label>
                }
                <TextField
                    sx={{ width: '100%', marginTop: "8px" }}
                    value={altText}
                    onChange={(e) => updateAltText(e.target.value)}
                    label={isPageEditor ? undefined : "Describe the contents of the image (Alt Text)"}
                    variant="outlined" />
                </>
            }
        </div>
    );
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const obj = JSON.parse(property.RenderedValue);
        return getFileNameFromUrl(obj.u?.toLowerCase())?.includes(value)
            || obj.a?.toLowerCase().includes(value);
    }
}