import Version from "../../../../types/Version";
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format } from 'date-fns';

export interface VersionListItemProps {
    version: Version, 
    handleSelect: (versionId: string) => void
}

export default function VersionListItem({version, handleSelect}: VersionListItemProps) {

    let previewLabel = "";
    if (version.PreviewDaysRemaining !== null) {
        previewLabel = "Preview ends ";
        if (version.PreviewDaysRemaining === 0) {
            previewLabel += "today";
        } else if (version.PreviewDaysRemaining === 1) {
            previewLabel += "in 1 day";
        } else {
            previewLabel += `in ${version.PreviewDaysRemaining} days`;
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", width:"100%" }} key={version.Id}
            data-testid="versionListItem">
            <div style={{fontWeight:"700",marginBottom:"8px"}}>{version.Name}</div>
            <div style={{ display: "flex", flexDirection: "row", width:"100%" }}>
                <div style={{ display: "flex", width: "50%", flexDirection: "column", paddingRight: "32px", overflow:"hidden" }}>
                    <div style={{fontSize:"12px",wordBreak:"break-all"}}>{format(new Date(version.VersionDate), "M/d/yyyy h:mm aa")}<br/>{version.CreatedByUser}</div>
                </div>
                <div style={{ display:"flex", width:"50%", fontSize:"12px", justifyContent:"end" }}>
                    {version.PreviewDaysRemaining !== null &&
                        <>
                            <span>{previewLabel}</span> 
                            <a href={version.PreviewUrl || ''} style={{color:"secondary.main", marginRight:"16px"}} target="_blank" rel="noreferrer">
                                <LinkIcon sx={{position:"relative",top:"-5px",marginLeft:"4px"}} />
                            </a>
                        </>
                        
                    }
                    <div
                        style={{ cursor: "pointer", position:"relative", top:"-5px" }}
                        onClick={ (e: any) => {
                        e.stopPropagation();
                        handleSelect(version.Id);
                        } }
                    >
                        <MoreVertIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}