import { useState, useEffect } from "react";
import { Box } from '@mui/material';
import CategoryType from '../../../types/CategoryType';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./CategorizedList.css";
import CategorizedItem from "../../../types/CategorizedItem";

interface CategoryProps {
  category: CategoryType, 
  items: CategorizedItem[], 
  isParent: boolean, 
  expandCategories: boolean
}

interface PropertyItemProps {
  id: string,
  content: JSX.Element | null
}

const isCategoryUsed = (category: CategoryType, items: CategorizedItem[]) : boolean => {
  if (!category || !items) {
    return false;
  }

  if (items.filter((p) => p?.CategoryTypeId === category.Id).length > 0) {
    return true;
  }

  for (let childCategory of category.Children) {
    if (isCategoryUsed(childCategory, items)) {
      return true;
    }
  }

  return false;
}

function PropertyItem({id, content}: PropertyItemProps) {
  return <Box id={id} key={id} sx={{ marginBottom: "20px" }}>{content}</Box>
}

function Category({category, items, isParent, expandCategories}: CategoryProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (!isExpanded && expandCategories) {
      setIsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandCategories]);

  const getSubCategories = () => {
    return category.Children.filter((c) => isCategoryUsed(c, items))
      .map((c: CategoryType, i: number) => 
        <Category 
          category={c} 
          key={i} 
          items={items} 
          isParent={false} 
          expandCategories={expandCategories}
        />
      );
  };

  const filteredItems = items.filter(p => p.CategoryTypeId === category.Id);
  const subCategories = getSubCategories();

  return (
    <>
      { (filteredItems.length > 0 || subCategories.length > 0) && category.Id &&
        <Accordion 
          expanded={isExpanded}
          onChange={(event: React.SyntheticEvent, expanded: boolean) => { setIsExpanded(expanded) }}
          sx={{ 
            padding: "0px", 
            '&:before': {
              display: 'none',
            } 
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${category.Name}-content`}
            id={`${category.Name}-header`}
          >
            <span className={isParent ? 'headline6' : ''} style={{ marginLeft: '10px' }}>{category.Name}</span>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }}>
            {filteredItems.map((i: CategorizedItem) => <PropertyItem id={i.Id} key={i.Id} content={i.Content}></PropertyItem>)}
            {subCategories}
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
}

export interface CategorizedListProps {
    items: CategorizedItem[], 
    categories: CategoryType[], 
    expandCategories: boolean
}

export default function CategorizedList({items, categories, expandCategories}: CategorizedListProps) {
  const nullCategoryItems = items.filter((i) => i.CategoryTypeId === null);
  const usedCategories = categories.filter((c) => isCategoryUsed(c, items));

  return (
    <>
      {nullCategoryItems && 
        nullCategoryItems.map((i: CategorizedItem) => <PropertyItem key={i.Id} id={i.Id} content={i.Content}></PropertyItem>)
      }
      {usedCategories.map((c: CategoryType) => 
        <Category 
          category={c} 
          key={c.Id} 
          items={items} 
          isParent={true}
          expandCategories={expandCategories}
        />
      )}
    </>
  );
}