import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getPropertyBooleanValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import { defaultFilter } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({block, builderProps, blockWrapperData}: BlockProps) {
    let level = getPropertyValue(block.Properties, PropertyId.HeadingLevel);
    level = level ? level : "1";
    const isServiceArea = getPropertyBooleanValue(block.Properties, PropertyId.IsServiceArea);
    const content = isServiceArea ? "Service Area" : "Contact Us";

    return (
        <>
            {level === "1" && 
            <h1 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {content}
            </h1>
            }
            {level === "2" && 
            <h2 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {content}
            </h2>
            }
            {level === "3" && 
            <h3 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {content}
            </h3>
            }
            {level === "4" && 
            <h4 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {content}
            </h4>
            }
            {level === "5" && 
            <h5 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {content}
            </h5>
            }
            {level === "6" && 
            <h6 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {content}
            </h6>
            }
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return true;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return false;
}

export function Placeholder(props: BlockProps) {
    return null;
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const isServiceArea = getPropertyBooleanValue(block.Properties, PropertyId.IsServiceArea);

    return isServiceArea ? <>Service Area</> : <>Contact Us</>;
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const isServiceArea = getPropertyBooleanValue(block.Properties, PropertyId.IsServiceArea);
    const searchStr = isServiceArea ? 'Service Area' : 'Contact Us';

    return (!!searchStr && (searchStr.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}