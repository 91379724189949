import PropertyId from '../../../types/enum/PropertyId';
import { getDynamicAttributes, getPropertyValue } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

const isProperlyConfigured = (block: BlockMapping) : boolean => {
    return !!(getPropertyValue(block.Properties, PropertyId.Header) || getPropertyValue(block.Properties, PropertyId.Content));
};

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    if (!isProperlyConfigured(block)) {
        return null;
    }

    const content = getPropertyValue(block.Properties, PropertyId.Content);
    const header = getPropertyValue(block.Properties, PropertyId.Header);

    return (
        <div {...getDynamicAttributes({block, builderProps, className: "fmsg bg-base-200 text-black", blockWrapperData})}>
            <div className="fmsg-container">
                {header &&
                    <div className="fmsg-header">
                        <span className="material-icons-outlined fmsg-icon" title="info" aria-hidden="true">info</span>
                        {header}
                    </div>
                }
                {content && <div className="fmsg-content">{content}</div>}
            </div>
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isProperlyConfigured(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    
    const header = getPropertyValue(block.Properties, PropertyId.Header);
    const content = getPropertyValue(block.Properties, PropertyId.Content);

    if (header && content) {
        return <>{`${header}: ${content}`}</>;
    }
    
    if (header) {
        return <>{header}</>;
    }

    if (content) {
        return <>{content}</>;
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const header = getPropertyValue(block.Properties, PropertyId.Header);
    const content = getPropertyValue(block.Properties, PropertyId.Content);

    return (!!header && (header.toLowerCase().includes(filterText)))
        || (!!content && (content.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}