import BuilderDialog from "../../../../common/BuilderDialog";

import TemplateTypes from "../../../../../types/enum/TemplateTypes";
import { SwapTemplateSteps } from "./SwapTemplate";
import Template from "../../../../../types/Template";
import { useEffect, useState } from "react";
import { Checkbox, CircularProgress, ListItemText, MenuItem, OutlinedInput, Select, Switch } from "@mui/material";
import PageInfo from "../../../../../types/PageInfo";
import FormValidationError from "../../../../common/FormValidationError";
import useApiGetCallbackAsync from "../../../../../hooks/useApiGetCallbackAsync";
import ErrorMessage from "../../../../common/ErrorMessage";

interface SwapTemplateStepThreeModalProps {
    showModal: boolean,
    setCurrentStep: (step: SwapTemplateSteps) => void,
    templateType: TemplateTypes,
    selectedTemplate: Template | null,
    cancel: () => void,
    submit: (templateType: TemplateTypes) => void,
    selectedPageIds: string[],
    setSelectedPageIds: (ids: string[]) => void,
    includeNewPagesInGlobalNav: boolean,
    setIncludeNewPagesInGlobalNav: (include: boolean) => void
}

export default function SwapTemplateStepThreeModal({
    showModal,
    setCurrentStep,
    templateType,
    selectedTemplate,
    cancel,
    submit,
    selectedPageIds,
    setSelectedPageIds,
    includeNewPagesInGlobalNav,
    setIncludeNewPagesInGlobalNav
}: SwapTemplateStepThreeModalProps){
    
    const [pageList, setPageList] = useState<PageInfo[]>([]);
    
    const [getPagesError, setGetPagesError] = useState<boolean>(false);

    const [{ isLoading: pagesLoading }, getPagesCallback] = useApiGetCallbackAsync('', null) as any;

    useEffect(() => {
        if (templateType === TemplateTypes.Site && selectedTemplate) {
            getPagesCallback(
                (data: PageInfo[]) => {
                    setPageList(data);
                    setSelectedPageIds(data.map(p => p.Id));
                    setGetPagesError(!data || data.length === 0);
                },
                (err: any) => {
                    setPageList([]);
                    setSelectedPageIds([]);
                    setGetPagesError(true);
                },
                `/site/${selectedTemplate.SiteId}/page/list`);
        } else {
            setPageList([]);
            setSelectedPageIds([]);
            setGetPagesError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplate]);

    const getTitle = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Swap Site Template";
            case TemplateTypes.Page:
                return "Swap Page Template";
            default:
                return "";
        }
    }

    const getMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Are you sure you wish to replace the current site template with a new site template? This action may result in the loss of all blocks that were added after the initial template was selected. Only pages that are common to both templates will be affected.";
            case TemplateTypes.Page:
                return "Are you sure you wish to replace the current page template with a new page template? This action may result in the loss of all blocks that were added after the initial template was selected.";
            default:
                return "";
        }
    }

    const previousStep = () => {
        setGetPagesError(false);
        setCurrentStep(SwapTemplateSteps.StepTwo);
    }

    return (
        <BuilderDialog
            title={getTitle()}
            message={getMessage()}
            action={() => submit(templateType)}
            actionName="Apply New Template"
            actionDisabled={templateType === TemplateTypes.Site && (!selectedPageIds || selectedPageIds.length === 0)}
            actionButtonType="contained"
            open={showModal}
            backAction={previousStep}
            onClose={cancel}
            showCancel={true}
        >
            <div 
                data-testid="template"
                style={{
                    marginTop: "32px",
                    height: "325px",
                    width: "auto",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    backgroundImage: `url('${selectedTemplate?.ScreenshotUrl}')`,
                    backgroundSize: "cover"
                }}
            />
            <div style={{ margin: "16px 0px 16px 0px" }} className="headline5">
                {selectedTemplate?.Name}
            </div>
            {templateType === TemplateTypes.Site &&
                <>
                { pagesLoading && <div style={{textAlign:"center", marginBottom:"49px"}}>
                    <CircularProgress /> 
                </div>}
                { !pagesLoading && !getPagesError && <>
                    <div style={{ marginTop: "32px", marginBottom: "32px" }}>
                        <label htmlFor="swap-template-pages">Pages to include</label>
                        <Select
                            id="swap-template-pages"
                            value={selectedPageIds}
                            renderValue={(selected) =>
                                pageList.filter(i => selected.includes(i.Id)).map(i => i.Name).join(', ')}
                            label=""
                            fullWidth
                            multiple
                            onChange={(e: any) => setSelectedPageIds(e.target.value) }
                            input={<OutlinedInput />}
                            sx={{ marginTop: "8px" }}
                        >
                            {pageList && pageList.map((c: PageInfo) =>
                                <MenuItem value={c.Id} key={c.Id}>
                                    <Checkbox checked={selectedPageIds && selectedPageIds.includes(c.Id)} />
                                    <ListItemText primary={c.Name} />
                                </MenuItem>
                            )}
                        </Select>
                        <FormValidationError
                            valid={selectedPageIds && selectedPageIds.length > 0}
                            message="At least one page must be selected." />
                    </div>
                    
                    <div className="body1" style={{ marginBottom: "8px", marginTop: "32px" }}>
                        <label htmlFor="add-to-global-nav">Include New Pages in Global Navigation</label>
                    </div>
                    <div>
                        <Switch
                            id="add-to-global-nav"
                            checked={includeNewPagesInGlobalNav}
                            onChange={() => {setIncludeNewPagesInGlobalNav(!includeNewPagesInGlobalNav)}}
                            data-testid="IncludeInGlobalNavToggleSwitch"
                        />
                    </div>    
                </>}
                </>
            }
            <ErrorMessage 
                message="Error retrieving page list for selected site template. Please try again later."
                duration={15000}
                dismissable={true}
                visible={getPagesError}
            />

        </BuilderDialog>
    )
}