import { useState, useEffect } from 'react';
import useApiGet from '../../hooks/useApiGet';
import ErrorMessage from '../common/ErrorMessage';
import Loading from '../common/Loading';
import { Tabs, Tab, Select, MenuItem, Button, Checkbox, FormControlLabel }  from '@mui/material';
import Divider from '@mui/material/Divider';
import { SelectChangeEvent } from '@mui/material/Select';
import Type from '../../types/Type';
import useApiPutCallbackAsync from '../../hooks/useApiPutCallbackAsync';
import SuccessMessage from '../common/SuccessMessage';
import BuilderDialog from '../common/BuilderDialog';
import AddIcon from '@mui/icons-material/Add';
import SearchFilterInput from '../common/SearchFilterInput';
import Property from '../../types/Property';
import CategorizedList from '../common/categorized_list/CategorizedList';
import { organizeCategories } from '../../utils/categoryHelpers';
import { updateTypeNameAsDisplayNameAndSort } from '../../utils/typeHelpers';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import EditIcon from '@mui/icons-material/Edit';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CustomPropertyForm from './CustomPropertyForm';
import useApiPostCallbackAsync from '../../hooks/useApiPostCallbackAsync';
import CustomBlock from '../../types/CustomBlock';
import TabPanel from '../common/TabPanel';
import property_renderers from '../../utils/propertyRenderers';
import CustomPropertyUpdate from '../../types/CustomPropertyUpdate';
import CategorizedItem from '../../types/CategorizedItem';

interface SharedPropertyCheckbox {
    PropertyId: string,
    Name: string,
    Checked: boolean,
    CategoryTypeId: number,
    AddedToBlock: boolean
}

interface CustomBlockEditorPropertiesProps {
    block: CustomBlock,
    loadBlockProperties: () => void,
    setBlock: (block: CustomBlock) => void,
    previewMode?: boolean,
    previewProperties: Property[],
    setPreviewProperties: (properties: Property[]) => void
}

export default function CustomBlockEditorProperties({block, loadBlockProperties, setBlock, previewMode, previewProperties, setPreviewProperties}: CustomBlockEditorPropertiesProps) {
    const [addPropertyTabValue, setAddPropertyTabValue] = useState(0);
    const [showCreatePropertyError, setShowCreatePropertyError] = useState(false);
    const [showCreatePropertySuccess, setShowCreatePropertySuccess] = useState(false);
    const [showUpdatePropertyError, setShowUpdatePropertyError] = useState(false);
    const [showUpdatePropertySuccess, setShowUpdatePropertySuccess] = useState(false);
    const [showDeletePropertyError, setShowDeletePropertyError] = useState(false);
    const [showDeletePropertySuccess, setShowDeletePropertySuccess] = useState(false);
    const [showDeleteProperty, setShowDeleteProperty] = useState(false);
    const [showPropertiesAdded, setShowPropertiesAdded] = useState(false);
    const [showPropertiesUpdated, setShowPropertiesUpdated] = useState(false);
    const [showAddProperties, setShowAddProperties] = useState(false);
    const [sharedPropertiesSelectedCategory, setSharedPropertiesSelectedCategory] = useState<number>(0);
    const [sharedPropertiesFilter, setSharedPropertiesFilter] = useState<string>('');
    const [selectAll, setSelectAll] = useState(false);
    const [checkboxes, setCheckboxes] = useState<SharedPropertyCheckbox[]>([]);
    const [filteredCheckboxes, setFilteredCheckboxes] = useState<SharedPropertyCheckbox[]>([]);
    const [editProperty, setEditProperty] = useState<Property | null>(null);
    const [editingProperties, setEditingProperties] = useState(false);
    const [customPropertyUpdatedValues, setCustomPropertyUpdatedValues] = useState<CustomPropertyUpdate | null>(null);
    const [orderedPropertyCategories, setOrderedPropertyCategories] = useState<Type[] | null>(null);

    const [{ isLoading: createPropertyLoading, errorResponse: createPropertyApiError }, createPropertyCallback]
        = useApiPostCallbackAsync(block?.Id ? `/block/custom/${block.Id}/property` : "", null) as any;
    const [{ isLoading: updatePropertyLoading, errorResponse: updatePropertyApiError }, updatePropertyCallback]
        = useApiPutCallbackAsync(block?.Id ? `/block/custom/${block.Id}/property/${editProperty?.PropertyId}` : "", null) as any;
    const [{ isLoading: updatePropertyStatusLoading, errorResponse: updatePropertyStatusApiError }, updatePropertyStatusCallback] 
        = useApiPutCallbackAsync('', []) as any;
    const [{ data: propertyCategories }] = useApiGet("/type/700", []);
    const [{ data: valueTypes }] = useApiGet("/type/800", []);
    const [{ data: sharedProperties }] = useApiGet("/properties/shared", null);

    useEffect(() => {
        if (propertyCategories) {
            const organized = updateTypeNameAsDisplayNameAndSort(propertyCategories);
            setOrderedPropertyCategories(organized);
        }
    }, [propertyCategories]);

    useEffect(() => {
        if (block && sharedProperties) {
            const sharedPropertyCheckboxes: SharedPropertyCheckbox[] = [];

            for (let property of sharedProperties)
            {
                const blockProp = block?.Properties.find((p: Property) => p.PropertyId === property.PropertyId);
                sharedPropertyCheckboxes.push({
                    PropertyId: property.PropertyId,
                    Name: property.Name,
                    Checked: !!(selectAll || blockProp),
                    CategoryTypeId: property.CategoryTypeId,
                    AddedToBlock: !!blockProp
                });
            }

            setCheckboxes(sharedPropertyCheckboxes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedProperties, block, showAddProperties]);
    
    useEffect(() => {
        const filteredCheckboxes: SharedPropertyCheckbox[] = checkboxes.filter((p: SharedPropertyCheckbox) => 
            (sharedPropertiesSelectedCategory === 0 || p.CategoryTypeId === sharedPropertiesSelectedCategory)
            && (sharedPropertiesFilter === '' || p.Name.toLocaleLowerCase().includes(sharedPropertiesFilter)));
        setFilteredCheckboxes([...filteredCheckboxes]);
    }, [checkboxes, sharedPropertiesSelectedCategory, sharedPropertiesFilter]);
        
    const handlePropertyTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setAddPropertyTabValue(newValue);
    };

    const deleteProperty = () => {
        setShowDeletePropertyError(false);
        setShowDeletePropertySuccess(false);

        updatePropertyStatusCallback({}
            , () => {
                setShowDeletePropertySuccess(true);
                setShowDeleteProperty(false);
                handleCloseAddEditPropertiesModal();
                loadBlockProperties();
            }
            , () => {
                setShowDeletePropertyError(true);
            }
            , `/block/custom/${block.Id}/property/${editProperty?.PropertyId}/status/1002`
        );
    };

    const handleSelectAll = (all: boolean) => {
        setSelectAll(all);

        for (let checkbox of checkboxes) {
            if (all) {
                checkbox.Checked = true;
            } else {
                const existingProp = block?.Properties.find((p: Property) => p.PropertyId === checkbox.PropertyId);

                if (!existingProp || editingProperties) {
                    checkbox.Checked = false;
                }
            }
        }

        setCheckboxes([...checkboxes]);
    }

    const toggleCheckbox = (checkbox: SharedPropertyCheckbox) => {
        for (let box of checkboxes) {
            if (checkbox.PropertyId === box.PropertyId) {
                box.Checked = !box.Checked;
                if (selectAll && !box.Checked) {
                    setSelectAll(false);
                }
                break;
            }         
        }

        setCheckboxes([...checkboxes]);
    }

    const handleCreateNewPropertyClick = () => {
        setShowAddProperties(true);
        setShowPropertiesAdded(false);
        setShowPropertiesUpdated(false);
        setShowCreatePropertyError(false);
        setShowCreatePropertySuccess(false);
        setShowUpdatePropertyError(false);
        setShowUpdatePropertySuccess(false);
    };

    const handleCloseAddEditPropertiesModal = () => {
        setShowAddProperties(false);
        setSelectAll(false);
        setSharedPropertiesFilter("");
        setEditProperty(null);
        setCustomPropertyUpdatedValues(null);
        setEditingProperties(false);
    };

    const handleAddCreateProperties = () => {
        // Handle add/edit of presets/shared properties
        if (addPropertyTabValue === 0) {
            if (block) {
                for (let filteredCheckbox of filteredCheckboxes) {
                    const checkbox = checkboxes.find((c: SharedPropertyCheckbox) => c.PropertyId === filteredCheckbox.PropertyId);

                    if (checkbox) {
                        if (checkbox.Checked && (editingProperties || !checkbox.AddedToBlock)) {
                            const prop = sharedProperties.find((p: Property) => p.PropertyId === checkbox.PropertyId);
                            const existingProp = block?.Properties.find((p: Property) => p.PropertyId === checkbox.PropertyId);
                            checkbox.AddedToBlock = true;
                            if (!existingProp) {
                                block?.Properties.push(prop);
                            }
                        } else if (!checkbox.Checked) {
                            checkbox.AddedToBlock = false;
                            block.Properties = block.Properties.filter((p: Property) => p.PropertyId !== checkbox.PropertyId);
                        }
                    }
                } 

                setBlock({...block});
                setCheckboxes([...checkboxes]);

                if (!editingProperties) {
                    setShowPropertiesAdded(true);
                } else {
                    setShowPropertiesUpdated(true);
                }
            }

            handleCloseAddEditPropertiesModal();
        } 
        // Handle add/edit of custom properties
        else if (addPropertyTabValue === 1) {
            if (editProperty != null && block) {
                setShowUpdatePropertyError(false);
                setShowUpdatePropertySuccess(false);
                updatePropertyCallback({
                    Name: customPropertyUpdatedValues?.title,
                    Value: customPropertyUpdatedValues?.defaultValue,
                    CategoryTypeId: customPropertyUpdatedValues?.categoryTypeId,
                    Required: customPropertyUpdatedValues?.required
                }
                , () => {
                    setShowUpdatePropertySuccess(true);
                    // Change any data swaps with old name to new name
                    block.HTML = block.HTML ? block.HTML.replaceAll(`[P:${editProperty.Name}]`,`[P:${customPropertyUpdatedValues?.title}]`) : null;
                    handleCloseAddEditPropertiesModal();
                    loadBlockProperties();
                }
                , () => { setShowUpdatePropertyError(true) });
            } else {
                setShowCreatePropertyError(false);
                setShowCreatePropertySuccess(false);
                createPropertyCallback({
                    Name: customPropertyUpdatedValues?.title,
                    Value: customPropertyUpdatedValues?.defaultValue,
                    CategoryTypeId: customPropertyUpdatedValues?.categoryTypeId,
                    ValueTypeId: customPropertyUpdatedValues?.valueTypeId,
                    Required: customPropertyUpdatedValues?.required
                }
                , () => {
                    setShowCreatePropertySuccess(true);
                    handleCloseAddEditPropertiesModal();
                    loadBlockProperties();
                }
                , () => { setShowCreatePropertyError(true) });
            }
        }
    };

    const showEditProperty = (property: Property | null) => {
        setEditProperty(property);
        setCustomPropertyUpdatedValues(null);
        setAddPropertyTabValue(property == null ? 0 : 1);
        setEditingProperties(true);
        setShowAddProperties(true);
    };

    const getPropertyContent = (name: string, valueType: string, isSharedProperty: boolean, swapValue: string, editAction: (() => void) | null) => {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", flexDirection: "column", color: "rgba(0, 0, 0, 0.6)", flex: 1 }}>
                    <div className="subtitle2">{name}</div>
                    <div className="caption" style={{ display: "flex" }}>
                        {valueType}
                        { isSharedProperty &&
                            <div style={{ marginLeft: "8px", display: "flex", alignItems: "center" }}><AccountTreeOutlinedIcon fontSize='inherit' /></div>
                        }
                    </div>
                </div>
                { editAction != null &&
                    <div>
                        <Button onClick={editAction} color="secondary" startIcon={<EditIcon />}>Edit</Button>
                    </div>
                }
                <div style={{ marginLeft: "8px" }}>
                    <Button 
                        variant="outlined" 
                        color="secondary" 
                        startIcon={<ContentPasteIcon />} 
                        style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                        onClick={() => navigator.clipboard.writeText(swapValue)}
                    >
                        Copy
                    </Button>
                </div>
            </div>
        );
    }

    const filteredPropertyCategories = sharedProperties && orderedPropertyCategories
        ? orderedPropertyCategories.filter((c: Type) => sharedProperties.find((p: Property) => p.CategoryTypeId === c.Id) != null)
        : [];

        
    const organizedPropertyCategories = organizeCategories(propertyCategories);

    const categorizedItems: CategorizedItem[] = [];

    if (block) {
        if (!previewMode && propertyCategories) {
            categorizedItems.push({
                Id: "dynamic-attributes",
                CategoryTypeId: 703,
                Content: getPropertyContent("[DYNAMIC_ATTRIBUTES]", "", false, "[DYNAMIC_ATTRIBUTES]", null)
            });
            categorizedItems.push({
                Id: "children",
                CategoryTypeId: 703,
                Content: getPropertyContent("[CHILDREN]", "", false, "[CHILDREN]", null)
            });
        }
        for (const prop of block.Properties) {
            let valueType = valueTypes?.find((t: Type) => t.Id === prop.ValueTypeId)?.Name;
            let sharedProperty = sharedProperties?.find((p: Property) => p.PropertyId === prop.PropertyId);

            let content: JSX.Element | null = null;
            if (!previewMode) {
                content = getPropertyContent(prop.Name || '', valueType, !!sharedProperty, `[P:${prop.Name}]`
                    , () => { showEditProperty( sharedProperty ? null : prop) });
            } else {
                if (prop.ValueTypeId in property_renderers) {
                    const editorProperty = previewProperties.find(p => p.PropertyId === prop.PropertyId);

                    if (editorProperty) {
                        const PropertyRenderer = property_renderers[prop.ValueTypeId]?.Editor;
                        content = <PropertyRenderer
                            blockMapping={null}
                            siteProperties={[]}
                            property={editorProperty}
                            propertyUpdated={(updatedProperty: Property) => {
                                const blockProp = previewProperties.find(p => p.PropertyId === updatedProperty.PropertyId);
                                if (blockProp) {
                                    blockProp.RenderedValue = updatedProperty.RenderedValue;
                                }
                                setPreviewProperties([...previewProperties]);
                            }}
                            siteId={null}
                            setError={() => {}}
                            isPageEditor={true}
                            page={null}
                            populatedDataSources={[]}
                        />
                    }
                }
            }

            categorizedItems.push({
                Id: prop.PropertyId,
                CategoryTypeId: prop.CategoryTypeId || null,
                Content: content
            });
        }
    }

    const allFilteredCheckboxesSelected = filteredCheckboxes?.filter(c => !c.Checked || !c.AddedToBlock).length > 0 ? false : true;
    const allCheckboxesSelected = filteredCheckboxes?.filter(c => !c.Checked).length > 0 ? false : true;

    return (
        <>
        <div style={{ flex: "0 0 400px", padding: "16px" }}>
            <h5 className="headline6" style={{ marginTop: "16px", marginBottom: "32px" }}>Properties</h5>
            <div style={{ height: "calc(100% - 72px)", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1, overflowY: "auto", marginBottom: "32px" }} className="hideScrollbars">
                    <CategorizedList
                        items={categorizedItems}
                        categories={organizedPropertyCategories}
                        expandCategories={true}
                    />
                </div>
                { !previewMode &&
                    <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleCreateNewPropertyClick}>Create New Property</Button>
                }
            </div>
        </div>
        { (createPropertyLoading || updatePropertyLoading || updatePropertyStatusLoading) && <Loading />}
        <BuilderDialog 
            title={ editingProperties ? (addPropertyTabValue === 0 ? "Edit Preset Properties" : "Edit Property") : "Add Property" } 
            message=""
            action={handleAddCreateProperties}
            actionName={ editingProperties ? "Update" : (addPropertyTabValue === 0 ? "Add Properties" : "Create")}
            actionDisabled={addPropertyTabValue === 0 && !editingProperties && allFilteredCheckboxesSelected}
            open={showAddProperties}
            onClose={handleCloseAddEditPropertiesModal}
            showCancel={true}
            maxWidth="md"
            fullWidth={true}
        >
            <div style={{ height: "100%", flexDirection: "column", display: "flex" }}>
                <div style={{ display: editingProperties ? "none" : "flex", justifyContent: "center", marginBottom: "32px" }}>
                    <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={addPropertyTabValue} onChange={handlePropertyTabChange} aria-label="Property tabs" indicatorColor='secondary' TabIndicatorProps={{ style: { color:'black' } }}>
                            <Tab id="preset-property-tab" aria-controls="preset-property-tab-pane" sx={{ width: "167px" }} label={<span style={{ color: '#292929' }}>Preset</span>} />
                            <Tab id="custom-property-tab" aria-controls="custom-property-tab-pane" sx={{ width: "167px" }} label={<span style={{ color: '#292929' }}>Custom</span>} />
                        </Tabs>
                    </div>
                </div>
                <TabPanel value={addPropertyTabValue} name="preset-property-tab" index={0}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "176px" }}>
                            <div style={{ marginBottom: "8px" }}>
                                <label htmlFor="property-category">Category</label>
                            </div>
                            <Select
                                id="property-category"
                                value={sharedPropertiesSelectedCategory?.toString()}
                                label=""
                                fullWidth
                                displayEmpty={true}
                                onChange={(e: SelectChangeEvent) => setSharedPropertiesSelectedCategory(parseInt(e.target.value)) }
                                color="secondary"
                            >
                                <MenuItem value={0}>All</MenuItem>
                                { filteredPropertyCategories && filteredPropertyCategories.map((c: Type) => <MenuItem value={c.Id} key={c.Id}>{c.Name}</MenuItem> )}
                            </Select>
                        </div>
                        <div style={{ width: "100%", marginLeft: "32px" }}>
                            <div style={{ marginBottom: "8px" }}>
                                <label htmlFor="property-search">Search</label>
                            </div>
                            <SearchFilterInput
                                placeholder='Search properties...'
                                value={sharedPropertiesFilter}
                                id={'property-search'}
                                onChange={function (newValue: string, originalCase: string): void {
                                    setSharedPropertiesFilter(newValue);
                                }}
                                noResults={false}
                            />
                        </div>
                    </div>
                    <div style={{ padding: "32px", marginTop: "32px" }}>
                        <FormControlLabel 
                            control={<Checkbox 
                                checked={(addPropertyTabValue === 0 && allCheckboxesSelected) || selectAll} 
                                color="secondary" 
                                disabled={(addPropertyTabValue === 0 && !editingProperties && allFilteredCheckboxesSelected)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectAll(event.target.checked)} 
                            />} 
                            label="Select All" 
                        />
                    </div>
                    <div style={{ padding: "0px 32px 32px 32px", height: "300px", display: "flex", flexWrap: "wrap", overflowY: "auto", alignItems: "flex-start", alignContent: "flex-start" }}>
                        { filteredCheckboxes && filteredCheckboxes.map((p: SharedPropertyCheckbox) => 
                            <div style={{ flex: "0 0 33%" }} key={p.PropertyId}>
                                <FormControlLabel control={<Checkbox checked={p.Checked} disabled={!editingProperties && p.AddedToBlock} color="secondary" onClick={() => toggleCheckbox(p) } />} label={p.Name} />
                            </div>
                        )}
                    </div>
                </TabPanel>
                <TabPanel value={addPropertyTabValue} name="custom-property-tab" index={1}>
                    <div style={{ position: "relative" }}>
                        <CustomPropertyForm 
                            property={editProperty} 
                            valueTypes={valueTypes} 
                            propertyCategories={orderedPropertyCategories}
                            valuesUpdated={setCustomPropertyUpdatedValues} 
                        />
                        { editProperty &&
                            <div style={{ marginBottom: "16px" }}>
                                <Divider sx={{ marginTop: "32px", marginBottom: "32px" }} />
                                <div className="subtitle2">Delete This Property</div>
                                <div className="body2" style={{ marginTop: "8px", marginBottom: "16px"}}>This action is permanent and cannot be undone! All references to this property in the code will break.</div>
                                <Button variant="contained" color="error" onClick={() => setShowDeleteProperty(true)}>DELETE THIS PROPERTY</Button>
                            </div>
                        }
                    </div>
                </TabPanel>
            </div>
        </BuilderDialog>
        <BuilderDialog 
            title="Delete Property" 
            message="Are you sure you wish to delete this Property? This is a permanent action and cannot be undone. All references to this property in the code will break."
            action={deleteProperty}
            actionName="Delete"
            open={showDeleteProperty}
            onClose={() => setShowDeleteProperty(false) }
            danger={true}
            showCancel={true}
        />
        <ErrorMessage 
            message="Error Creating Custom Property." 
            duration={15000}
            visible={showCreatePropertyError}
            apiError={createPropertyApiError}
            dismissable={true}
        />
        <SuccessMessage 
            message={"Custom Property Created!"} 
            dismissable={true}
            duration={3000}
            visible={showCreatePropertySuccess}
        />
        <ErrorMessage 
            message="Error Deleting Property." 
            duration={15000}
            visible={showDeletePropertyError}
            apiError={updatePropertyStatusApiError}
            dismissable={true}
        />
        <SuccessMessage 
            message={"Property Deleted!"} 
            dismissable={true}
            duration={3000}
            visible={showDeletePropertySuccess}
        />
        <ErrorMessage 
            message="Error Updating Custom Property." 
            duration={15000}
            visible={showUpdatePropertyError}
            apiError={updatePropertyApiError}
            dismissable={true}
        />
        <SuccessMessage 
            message={"Custom Property Updated!"} 
            dismissable={true}
            duration={3000}
            visible={showUpdatePropertySuccess}
        />
        <SuccessMessage 
            message={"Properties Added!"} 
            dismissable={true}
            duration={3000}
            visible={showPropertiesAdded}
        />
        <SuccessMessage 
            message={"Properties Updated!"} 
            dismissable={true}
            duration={3000}
            visible={showPropertiesUpdated}
        />
        </>
    )
}