import { useState, MouseEvent, ReactNode } from "react";
import { TextField } from "@mui/material";
import BuilderDialog from '../../../common/BuilderDialog';
import FormValidationError from '../../../common/FormValidationError';
import SiteEditorStore from "../../SiteEditorStore";

interface EditComponentNameModalProps {
    title: string,
    actionName: string,
    currentName?: string | null,
    action: (e: MouseEvent<HTMLButtonElement>, componentName: string) => void,
    onClose: (e: MouseEvent<HTMLButtonElement>) => void,
    children?: ReactNode
}

export default function EditComponentNameModal({
    title,
    actionName,
    currentName,
    action,
    onClose,
    children
}: EditComponentNameModalProps) {
    const [componentName, setComponentName] = useState(currentName ?? '');
    const [nameInUse, setNameInUse] = useState(false);
    const blocksAndSections = SiteEditorStore.useStoreState((state) => state.blocksAndSections);

    const updateComponentName = (name: string) => {
        setComponentName(name);

        if (name && name !== currentName && blocksAndSections && blocksAndSections.filter(f => f.ci && f.n?.toLocaleLowerCase() === name.toLocaleLowerCase()).length > 0) {
            setNameInUse(true);
        } else {
            setNameInUse(false);
        }
    }

    return (
        <BuilderDialog 
            title={title} 
            action={(e: MouseEvent<HTMLButtonElement>) => { if (componentName && !nameInUse) { action(e, componentName); } }}
            actionName={actionName}
            actionDisabled={!componentName || nameInUse}
            open={true}
            actionButtonType="contained"
            onClose={onClose}
            showCancel={true}
        >
            <label htmlFor="component-name">Name</label>
            <TextField
                id="component-name"
                value={componentName}
                onChange={(e) => updateComponentName(e.target.value) }
                label=""
                variant="outlined"
                fullWidth
                sx={{ marginTop: "8px", marginBottom: "8px" }}
                inputProps={{ maxLength: 200 }}
                color="secondary" />
            <FormValidationError valid={!nameInUse} message="Name Already In Use" />
            {children}
        </BuilderDialog>
    );
}