import { useState, useEffect } from "react";
import { TextField } from '@mui/material';
import useApiPutCallbackAsync from "../../../hooks/useApiPutCallbackAsync";
import ErrorMessage from "../../common/ErrorMessage";
import SiteEditorStore from "../SiteEditorStore";

export default function PageNameEditor() {
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const pageInfo = pageList.find((p) => p.Id === page?.PageId);
    const [pageName, setPageName] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const refreshPageList = SiteEditorStore.useStoreActions((actions) => actions.refreshPageList);
    const updateLastPropertyUpdateDate = SiteEditorStore.useStoreActions((actions) => actions.updateLastPropertyUpdateDate);

    const [{errorResponse}, updateCallback] = useApiPutCallbackAsync(`/site/${pageInfo?.SiteId}/page/${pageInfo?.Id}/name`) as any;

    useEffect(() => {
        if (page && pageList) {
            const info = pageList.find((p) => p.Id === page.PageId);
            setPageName(info?.Name ?? '');
            setIsUpdating(false); 
        }
    }, [page, pageList]);

    useEffect(() => {
        let timeOutId: NodeJS.Timeout;

        if (pageInfo && pageName !== pageInfo.Name) {
            timeOutId = setTimeout(() => {
                setIsUpdating(true);
                updateLastPropertyUpdateDate();
                updateCallback({ Name: pageName}, () => 
                { 
                    refreshPageList(); 
                }, () => 
                { 
                    setIsUpdating(false); 
                    setPageName(pageInfo.Name); 
                });
            }, 2000);
        }

        return () => timeOutId && clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName]);

    const updateName = (name: string) => {
        if (!isUpdating) {
            setPageName(name);
        }
    }

    if (!page || !pageInfo) {
        return null;
    }

    return (<>
        <label className="body1" htmlFor="page-name-prop">Page Name</label>
        <ErrorMessage 
            message={"Error updating page name"} 
            dismissable={true}
            visible={errorResponse != null} 
            apiError={errorResponse}
        />
        <TextField 
            id="page-name-prop" 
            variant="outlined" 
            fullWidth 
            value={pageName} 
            onChange={(e) => { updateName(e.target.value); }} 
            inputProps={{
                maxLength: 200,
                onKeyDown: (event) => { 
                    if (isUpdating) { 
                        event.preventDefault(); 
                    }
                }
            }}
        />
    </>);
}