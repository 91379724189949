import { useContext, useEffect } from 'react';
import PropertyId from '../../../types/enum/PropertyId';
import PropertyValueType from '../../../types/enum/PropertyValueType';
import { Editor as FontProperty } from '../../property_editors/FontProperty';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import usePreventBodyScroll from "../../../hooks/usePreventBodyScroll";
import Box from '@mui/material/Box';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './FontStep.css';
import Page from '../../../types/Page';
import Property from '../../../types/Property';
import Font from '../../../types/FontTheme';
// @ts-ignore
import { publicApiType } from 'react-horizontal-scrolling-menu/dist/types/createApi';

export interface FontStepProps {
    siteId: string;
    templateData: Page | null;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
}

export interface GenerateFontThemePropertyProps {
    propertyId: string;
    siteId: string;
    properties: Property[];
    enumId: PropertyId | null | undefined;
    value: string;
    pageId: string
}

const generateFontThemeProperty = ({propertyId, siteId, properties, enumId, value, pageId}: GenerateFontThemePropertyProps) => {
    properties.push({
        PropertyId: propertyId,
        BlockMappingId: null,
        Value: value,
        RenderedValue: value,
        ValueTypeId: PropertyValueType.FontTheme,
        Configuration: null,
        EnumId: enumId,
        ValueUpdatedInWizard: true,
        CategoryTypeId: 718,
        SiteId: siteId,
        PageId: pageId,
        Name: "",
        PropertyOrder: 1,
    });
}

function FontStep({siteId, templateData, setTemplateData}: FontStepProps) {
    const { disableScroll, enableScroll } = usePreventBodyScroll();
    const fontThemeProperty = templateData?.SiteProperties.find(p => p.EnumId === PropertyId.FontTheme);

    let fontTheme = fontThemeProperty && fontThemeProperty.RenderedValue
        ? JSON.parse(fontThemeProperty.RenderedValue)
        : {};

    useEffect(() => {
        if (templateData && !templateData.SiteProperties.find(p => p.EnumId === PropertyId.PrimaryFont)) {
            generateFontThemeProperty({ propertyId: 'a1a66196-56db-4384-8f92-9cd46b59ed94', siteId, properties: templateData.SiteProperties, enumId: PropertyId.PrimaryFont, value: JSON.stringify(fontTheme.p), pageId: templateData.PageId});
            generateFontThemeProperty({ propertyId: '0b59aca4-626c-485d-a390-98f69bc8839c', siteId, properties: templateData.SiteProperties, enumId: PropertyId.SecondaryFont, value: JSON.stringify(fontTheme.s), pageId: templateData.PageId});
            setTemplateData({...templateData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData])

    if (!templateData) {
        return null;
    }

    const primaryFontProperty = templateData.SiteProperties.find(p => p.EnumId === PropertyId.PrimaryFont);
    const secondaryFontProperty = templateData.SiteProperties.find(p => p.EnumId === PropertyId.SecondaryFont);

    if (!primaryFontProperty || !secondaryFontProperty) {
        return null;
    }

    const setFontProperty = (property: Property, isPrimary: boolean) => {
        const font = property && property.RenderedValue
        ? JSON.parse(property.RenderedValue)
        : {};

        isPrimary ? fontTheme.p = font : fontTheme.s = font;

        property.Value = property.RenderedValue
            = JSON.stringify(font);
        property.ValueUpdatedInWizard = true;

        if (fontThemeProperty) {
            fontThemeProperty.Value = JSON.stringify(fontTheme);
            fontThemeProperty.RenderedValue = JSON.stringify(fontTheme);
            fontThemeProperty.ValueUpdatedInWizard = true;
        }

        setTemplateData({...templateData });
    }

    const selectPreset = (item: Font) => {
        const primaryFontValue = {
            f: item.primary.family,
            w: item.primary.weight
        };
        const secondaryFontValue = {
            f: item.secondary.family,
            w: item.secondary.weight
        };
        const fontThemeValue = {
            p: primaryFontValue,
            s: secondaryFontValue
        };
        if (fontThemeProperty) {
            fontThemeProperty.Value = fontThemeProperty.RenderedValue
                = JSON.stringify(fontThemeValue);
        }
        primaryFontProperty.Value = primaryFontProperty.RenderedValue
            = JSON.stringify(primaryFontValue);
        secondaryFontProperty.Value = secondaryFontProperty.RenderedValue
            = JSON.stringify(secondaryFontValue);
        primaryFontUpdated();
        secondaryFontUpdated();
    };

    const primaryFontUpdated = () => {
        setFontProperty(primaryFontProperty, true);
    };

    const secondaryFontUpdated = () => {
        setFontProperty(secondaryFontProperty, false);
    }

    const fonts: Font[] = [
        {
            primary: {
                family: 'EB Garamond',
                weight: 400
            },
            secondary: {
                family: 'Quicksand',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Open Sans',
                weight: 700
            },
            secondary: {
                family: 'Open Sans',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Playfair Display',
                weight: 700
            },
            secondary: {
                family: 'Lato',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Merriweather',
                weight: 900
            },
            secondary: {
                family: 'Josefin Sans',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Raleway',
                weight: 700
            },
            secondary: {
                family: 'Open Sans',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Montserrat',
                weight: 500
            },
            secondary: {
                family: 'Lato',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Roboto',
                weight: 700
            },
            secondary: {
                family: 'PT Serif',
                weight: 400
            }
        },
        {
            primary: {
                family: 'PT Serif',
                weight: 700
            },
            secondary: {
                family: 'PT Sans',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Oswald',
                weight: 500
            },
            secondary: {
                family: 'Raleway',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Lobster',
                weight: 400
            },
            secondary: {
                family: 'Open Sans',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Dancing Script',
                weight: 700
            },
            secondary: {
                family: 'Josefin Sans',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Great Vibes',
                weight: 400
            },
            secondary: {
                family: 'Raleway',
                weight: 400
            }
        },
        {
            primary: {
                family: 'Playfair Display',
                weight: 700
            },
            secondary: {
                family: 'Source Sans Pro',
                weight: 400
            }
        }
    ]

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=EB+Garamond&family=Great+Vibes&family=Josefin+Sans&family=Lato&family=Lobster&family=Merriweather:wght@900&family=Montserrat&family=Open+Sans:wght@400;700&family=Oswald:wght@500&family=PT+Sans&family=PT+Serif:wght@400;700&family=Playfair+Display:wght@700&family=Quicksand&family=Raleway:wght@400;700&family=Roboto:wght@700&family=Source+Sans+Pro&display=swap" rel="stylesheet"></link>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Pick a font theme</h1>
                <p className="body1">Pick a suggested font pairing, or customize the fonts based on the client's website.</p>
            </div>
            <p style={{ fontFamily: 'Roboto', fontWeight: 'bold', marginBottom: '24px' }}>Suggested font pairings:</p>
            <div onMouseEnter={disableScroll} onMouseLeave={enableScroll} style={{ marginBottom: '72px' }}>
                <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    onWheel={onWheel}
                >
                    {fonts.map((item) => (
                        <Card
                            itemId={item.primary.family + item.secondary.family} // NOTE: itemId is required for track items
                            key={item.primary.family + item.secondary.family}
                            item={item}
                            selectPreset={selectPreset}
                        />
                    ))}
                </ScrollMenu>
            </div>
            <FontProperty property={primaryFontProperty} propertyUpdated={primaryFontUpdated} text="Primary font" helpText="Headers" pageList={[]} />
            <FontProperty property={secondaryFontProperty} propertyUpdated={secondaryFontUpdated} text="Secondary font" helpText="Content" pageList={[]} />
        </>
    );
}

function onWheel(apiObj: publicApiType, ev: React.WheelEvent) {
    const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isTouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

    return (
      <Box onClick={() => scrollPrev()} sx={{ position: 'relative', width: '40px', display: { xs: 'none', sm: 'none', md: 'block' } }}>
        {!isFirstItemVisible &&
            <Box className="vertical-center" sx={{ cursor: 'pointer' }}>
                <ChevronLeftIcon style={{ color: '#CF1F2E' }} />
            </Box>
        }
      </Box>
    );
  }

function RightArrow() {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

    return (
      <Box onClick={() => scrollNext()} sx={{ position: 'relative', right: '0px', width: '40px', display: { xs: 'none', sm: 'none', md: 'block' } }}>
        {!isLastItemVisible &&
                <Box className="vertical-center" sx={{ width: '100%', cursor: 'pointer' }}>
                    <ChevronRightIcon style={{ color: '#CF1F2E' }}  sx={{ float: 'right' }} />
                </Box>
        }
      </Box>
    );
}

export interface CardProps {
    itemId: string,
    item: Font,
    selectPreset: (item: Font) => void
};

function Card({ itemId, item, selectPreset }: CardProps) {
    return (
        <div
            style={{
                backgroundColor: '#FAFAFA',
                width: '250px',
                height: '100px',
                display: 'inline-block',
                marginRight: '32px',
                padding: '10px 20px',
                borderRadius: '2px',
                cursor: 'pointer'
            }}
            onClick={() => selectPreset(item)}
        >
            <div className="font-preview" style={{ fontFamily: item.primary.family, fontWeight: item.primary.weight}}>{item.primary.family}</div>
            <div className="font-preview" style={{ fontFamily: item.secondary.family, fontWeight: item.secondary.weight}}>{item.secondary.family}</div>
        </div>
    );
}

export default FontStep;