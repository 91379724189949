import { MouseEvent } from "react";
import BuilderDialog from '../../../common/BuilderDialog';
import SiteEditorStore from "../../SiteEditorStore";
import useApiDeleteCallbackAsync from "../../../../hooks/useApiDeleteCallbackAsync";
import ErrorMessage from "../../../common/ErrorMessage";
import Loading from "../../../common/Loading";

interface DeleteComponentModalProps {
    componentId: string,
    onClose: () => void
}

export default function DeleteComponentModal({
    componentId,
    onClose
}: DeleteComponentModalProps) {
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const refreshBlocksAndSections = SiteEditorStore.useStoreActions((actions) => actions.refreshBlocksAndSections);
    const refreshPage = SiteEditorStore.useStoreActions((actions) => actions.refreshPage);

    const [{ isLoading: deleting, isError: deleteError, errorResponse: deleteErrorResponse }, deleteCallback] 
        = useApiDeleteCallbackAsync() as any;

    const deleteComponent = () => {
        deleteCallback(
            `/sites/${site?.Id}/components/${componentId}`
            , () => {
                refreshBlocksAndSections();
                refreshPage();
                onClose();
            }
        );
    };

    return (
        <>
            <BuilderDialog 
                title="Delete Component" 
                message="You are about to delete this component entirely. Deleting this will remove all linked instances of it from the site and from being able to add it from the Blocks & Sections sidebar. Are you sure you want to continue?"
                action={(e: MouseEvent<HTMLButtonElement>) => { deleteComponent(); }}
                actionName="Delete"
                open={true}
                danger={true}
                onClose={() => { onClose(); }}
                showCancel={true}
            >
                { deleting && <Loading /> }
                <ErrorMessage message="Error deleting component" apiError={deleteErrorResponse} visible={deleteError} />
            </BuilderDialog>
        </>
    );
}