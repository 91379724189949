import { useCallback, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';


export default function useGetCallbackAsync(url: string, setFunction: React.Dispatch<React.SetStateAction<any>>) {
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);

    return useCallback((onSuccess: Function, onError: Function) => {
        const callFetch = async () => {
            try {
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                const data = await makeApiCall(url, httpMethods.get, identityHeaders);

                setFunction(data);

                if (onSuccess) {
                    onSuccess(data);
                }
            } catch (error) {
                if (onError) {
                    onError(error);
                }
                console.log(error);
                throw error;
            }
        };

        callFetch();
    },[url, setFunction, getAccessTokenSilently, currentUser, sessionId, correlationId])
}
