import { Box, Fab } from '@mui/material';
import PageInfo from '../../../../types/PageInfo';
import AddIcon from '@mui/icons-material/Add';
import SidebarState from '../../../../types/enum/SidebarState';
import PageListItem from './PageListItem';
import { useState } from 'react';
import PageStatus from '../../../../types/enum/PageStatus';
import SiteEditorStore from '../../SiteEditorStore';

  
export default function SiteEditorSideBarPages() {
  const [anyPageContextMenuOpen, setAnyPageContextMenuOpen] = useState(false);
  const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
  const page = SiteEditorStore.useStoreState((state) => state.page);
  const updateSidebarState = SiteEditorStore.useStoreActions((actions) => actions.updateSidebarState);

  const sortedPageList = pageList.filter(p => p.StatusTypeId !== PageStatus.Deleted).sort((a: PageInfo, b: PageInfo) => (a.Route > b.Route) ? 1 : -1);
  const rootRoutes: string[] = sortedPageList
    .filter((p: PageInfo) => (p.Route.match(/\//g) || []).length === 1)
    .map((p: PageInfo) => p.Route);

  return (
    <Box sx={{ padding: "20px", height:"calc(100% - 136px)" }}>
      <Box className="headline6">Pages</Box>
      <Box className="hideScrollbars" sx={{ overflowY: "auto", marginTop: "16px", paddingBottom: "95px", maxHeight: "calc(100% - 68px)" }}>
        {sortedPageList.map((p: PageInfo) =>
          <PageListItem
            key={p.Id}
            pageInfo={p}
            isSelected={p.Id === page?.PageId}
            hasLeftPadding={rootRoutes.filter((r: string) => r.length > 1 && p.Route.startsWith(r + '/') && p.Route !== r).length === 1}
            anyPageContextMenuOpen={anyPageContextMenuOpen}
            setAnyPageContextMenuOpen={setAnyPageContextMenuOpen}
          />
        )}
      </Box>
      <Box sx={{ position: "absolute", bottom: "20px" }}>
        <Fab 
          variant="extended" 
          color="secondary" 
          aria-label="add page" 
          onClick={() => { updateSidebarState(SidebarState.PageTemplatesOpen); }}>
          <AddIcon sx={{ mr: 1 }} />
          Page
        </Fab>
      </Box>
    </Box>
  );
}