import SearchOffIcon from '@mui/icons-material/SearchOff';

export interface NoResultsFoundProps {
    display: boolean
}

export default function NoResultsFound({display}: NoResultsFoundProps) {

    if (!display) {
        return null;
    }
    
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            <SearchOffIcon sx={{ fontSize: "40px", marginBottom: "15px" }} />
            <div className="body1">No results found</div>
        </div>
    );
}