import { getDynamicAttributes } from "../../utils/blockHelpers";
import BlockProps from '../../types/BlockProps';

export default function MisconfiguredBlock(props: BlockProps) {

    return (
        <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, blockWrapperData: props.blockWrapperData, styles: { minHeight: "100px", margin: "2px", padding: "2px", border: "2px dotted red" }})}>
            {props.children}        
        </div>
    );
}