import { useEffect, useState } from 'react';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PropertyValueType from "../../types/enum/PropertyValueType";
import Property from '../../types/Property';
import { getFileNameFromUrl } from '../../utils/urlHelpers';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import FileRecord from '../../types/FileRecord';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import FileType from '../../types/enum/FileType';
import { FilePropertyEditor } from '../common/FilePropertyEditor';

export function Editor({ property, propertyUpdated, renderOptions, isPageEditor, siteProperties }: PropertyEditorProps) {
    renderOptions = renderOptions ?? {};
    const videoObject = JSON.parse(property?.RenderedValue || '{}');
    const [videoUrl, setVideoUrl] = useState(videoObject?.u);
    const config = JSON.parse(property?.Configuration || '{}');
    const maxFileSizeKb = config?.MaxFileSizeKb;
    const swapPropertyId = property && property.Value && property.Value.startsWith("[P:") ?
                property.Value.replace('[P:', '').replace(']', '').toLowerCase() : null;

    useEffect(() => {
        if (videoUrl !== videoObject?.u) {
            setVideoUrl(videoObject?.u);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property]);

    const setVideoPropertyValue = (url:string | null, file?: FileRecord | null) => {
        if (property) {
            const stringifiedValue = url ? JSON.stringify({ u: url }) : null;
            property.Value = stringifiedValue;
            property.RenderedValue = stringifiedValue;

            propertyUpdated(property, file);
        }
    }

    const updateVideoPropertyValue = (video: FileRecord | null) => {
        setVideoPropertyValue(video?.URL ?? null, video);
    };

    const updateVideoPropertySwapValue = (selectedProperty: Property, file: FileRecord) => {
        if (property && selectedProperty) {
            property.Value = `[P:${selectedProperty.PropertyId}]`;
            const siteProperty = siteProperties?.find(p => p.PropertyId === selectedProperty.PropertyId);
            property.RenderedValue = siteProperty?.RenderedValue;
            propertyUpdated(property, file);
        }
    };

    const clearVideo = () => {
        setVideoPropertyValue(null, null);
    };

    return (
        <div data-testid="video-property">
            <FilePropertyEditor
                onPropertySave={updateVideoPropertySwapValue}
                onFileSave={updateVideoPropertyValue}
                onClearProperty={clearVideo}
                fileUrl={videoObject?.u}
                linkedPropertyId={swapPropertyId}
                placeholderText='Click to set a video'
                selectButtonText='SELECT A VIDEO'
                clearButtonText='CLEAR VIDEO'
                selectIcon={<PlayCircleOutlinedIcon sx={{ color: "white" }} />}
                property={property}
                isPageEditor={isPageEditor}
                siteProperties={siteProperties}
                allowedFileTypes={[FileType.Video]}
                allowedSitePropertyValueTypeIds={[PropertyValueType.Video]}
                centered={renderOptions?.centered}
                maxFileSizeKb={maxFileSizeKb}
            />
        </div>
    );
}

export function containsValue(property: Property, value: string, _filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const obj = JSON.parse(property.RenderedValue);
        return getFileNameFromUrl(obj.u?.toLowerCase())?.includes(value)
            || obj.a?.toLowerCase().includes(value);
    }
}
