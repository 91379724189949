import { useEffect, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';


export default function useApiPost(uri: string, initialData: any, requestData: any) {
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                const data = await makeApiCall(uri, httpMethods.post, identityHeaders, requestData);

                setData(data);
            } catch (error) {
                console.log(error);
                setIsError(true);
            }

            setIsLoading(false);
        };

        if (uri) {
            fetchData();
        }
    }, [uri, getAccessTokenSilently, requestData, currentUser, sessionId, correlationId]);

    return [{ data, isLoading, isError }];
} 
