import { Box, Button, IconButton, List, ListItem } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { isSwapValue } from '../../utils/valueSwapHelpers';
import { BaseProperty } from '../../utils/propertyHelpers';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import { PropertyNameLabel } from '../common/PropertyNameLabel';
import { useEffect, useState } from 'react';
import DataSwapModal from './DataSwapModal';
import DataSwap from '../../types/DataSwap';
import { Editor as TextEditorProperty } from './TextEditorProperty';
import PropertyValueType from '../../types/enum/PropertyValueType';
import Property from '../../types/Property';
import { DeleteButton } from '../common/DeleteButton';
import { stripHtml } from '../../utils/blockHelpers';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';

export function Editor({property, propertyUpdated, isPageEditor, pageList, populatedDataSources, siteProperties, page}: PropertyEditorProps) {
    const isSitePropertySwap = isSwapValue(property?.Value);
    const config = property?.Configuration ? JSON.parse(property?.Configuration) : {};
    const values = property?.Value && !isSitePropertySwap ? JSON.parse(property.Value) : [""];
    const renderedValues = property?.RenderedValue && !isSitePropertySwap ? JSON.parse(property.RenderedValue) : [""];
    const [textListValues, setTextListValues] = useState<(string | null | undefined)[]>([""]);
    const [textListRenderedValues, setTextListRenderedValues] = useState<(string | null | undefined)[]>([""]);
    const [showDataSwaps, setShowDataSwaps] = useState(false);
    const [validationErrors, setValidationErrors] = useState({} as Record<number, string>);

    useEffect(() => {
        setTextListValues(!!values.length ? values : [""]);
        setTextListRenderedValues(!!renderedValues.length ? renderedValues : [""]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property])

    const updateValue = (value?: string | null, renderedValue?: string | null) => {
        if (property) {
            property.Value = value;
            property.RenderedValue = renderedValue;
            propertyUpdated(property);
        }
    };

    const addNewItem = () => {
        const updatedValues = [...textListValues, ""];
        const updatedRenderedValues = [...textListRenderedValues, ""];
        setTextListValues(updatedValues);
        setTextListRenderedValues(updatedRenderedValues);
    };

    const removeItem = (index: number) => {
        const updatedValues = [...textListValues.slice(0, index), ...textListValues.slice(index + 1)];
        const updatedRenderedValues = [...textListRenderedValues.slice(0, index), ...textListRenderedValues.slice(index + 1)];
        const updatedValidationErrors = { ...validationErrors };
        delete updatedValidationErrors[index];
        setTextListValues(updatedValues);
        setTextListRenderedValues(updatedRenderedValues);
        setValidationErrors(updatedValidationErrors);
        updateValue(JSON.stringify(updatedValues), JSON.stringify(updatedRenderedValues));
    };

    const onSitePropertyDataSwapSelected = (ds: DataSwap) => {
        updateValue(ds.swapValue, ds.value);
        setShowDataSwaps(false);
    };

    const resetValue = () => {
        setTextListValues([""]);
        setTextListRenderedValues([""]);
        updateValue(JSON.stringify([]), JSON.stringify([]));
    };

    const onTextListItemChanged = (textListItem: Property, index: number) => {
        const updatedValues = [...textListValues];
        const updatedRenderedValues = [...textListRenderedValues];
        updatedValues[index] = textListItem.Value;
        updatedRenderedValues[index] = textListItem.RenderedValue;
        setTextListValues(updatedValues);
        setTextListRenderedValues(updatedRenderedValues);
        updateValue(JSON.stringify(updatedValues), JSON.stringify(updatedRenderedValues));
    };

    const validate = (values: (string | null | undefined)[]) => {
        let validationErrors = {};

        values.forEach((renderedValue, index) => {
            if (config.AllowDuplicates === false && renderedValue !== "" && values.indexOf(renderedValue) !== index) {
                validationErrors = {
                    ...validationErrors,
                    [index]: "Duplicate values are not allowed. Please enter a unique value."
                };
            }

            if (config.AllowEmptyValues === false && renderedValue === "" && index !== values.length - 1) {
                validationErrors = {
                    ...validationErrors,
                    [index]: "Blank values are not allowed. Please enter a non-blank value."
                };
            }
        });

        setValidationErrors(validationErrors);
    };

    const usesSwapValue = isSwapValue(property?.Value);

    useEffect(() => {
        validate(textListRenderedValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textListRenderedValues]);

    return (
        <>
            <PropertyNameLabel
                property={property}
            ></PropertyNameLabel>

            <Box sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                marginTop: '16px'
            }}>
                {usesSwapValue &&
                    <List sx={{ flexGrow: 1 }}>
                        {JSON.parse(property?.RenderedValue || "[]").map((locationId: string) => (
                            <ListItem key={locationId}>{locationId}</ListItem>
                        ))}
                    </List>
                }
                <Box sx={{ marginTop: '8px' }}>
                    {config.EnableSitePropertySwap &&
                        <IconButton
                            color={ usesSwapValue ? "secondary" : undefined }
                            onClick={() => setShowDataSwaps(true)}
                            data-testid="select-site-property-data-swap"
                            aria-label="Select site property data swap"
                        >
                            <AccountTreeOutlinedIcon />
                        </IconButton>
                    }
                    {usesSwapValue &&
                        <DeleteButton
                            onClick={resetValue}
                            ariaLabel="Remove data swap"
                            dataTestId="remove-text-list-data-swap"
                        />
                    }
                </Box>
            </Box>

            {!usesSwapValue &&
                <>
                    {textListValues.map((value, index) => (
                        <Box key={index} sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextEditorProperty
                                    key={index}
                                    property={{
                                        ...BaseProperty,
                                        ValueTypeId: PropertyValueType.Text,
                                        Value: value,
                                        RenderedValue: textListRenderedValues[index]
                                    }}
                                    propertyUpdated={property => onTextListItemChanged(property, index)}
                                    isPageEditor={isPageEditor}
                                    populatedDataSources={populatedDataSources}
                                    siteProperties={siteProperties}
                                    label={undefined}
                                    pageList={pageList}
                                    hasError={!!validationErrors[index]}
                                    helperText={validationErrors[index]}
                                    page={page}
                                />
                            </Box>
                            { textListValues.length > 1 &&
                                <Box sx={{ marginLeft: "8px", marginTop: "16px" }}>
                                    <DeleteButton
                                        onClick={() => removeItem(index)}
                                        ariaLabel={`Remove ${property} value number ${index + 1}`}
                                        dataTestId={`remove-text-list-item-${index + 1}`}
                                    />
                                </Box>
                            }
                        </Box>
                    ))}

                    <div style={{marginTop: '16px'}}>
                        <Button
                            startIcon={<AddCircleOutlineIcon/>}
                            color='secondary'
                            onClick={addNewItem}
                            fullWidth
                            data-testid="add-text-list-item">
                            ADD ITEM
                        </Button>
                    </div>
                </>
            }

            <DataSwapModal
                populatedDataSources={populatedDataSources || []}
                siteProperties={siteProperties || []}
                pageProperties={page?.PageProperties ?? []}
                showModal={showDataSwaps}
                onClose={() => setShowDataSwaps(false) }
                dataSwapSelected={onSitePropertyDataSwapSelected}
                selectedToken={usesSwapValue ? property?.Value : null}
                propertyValueTypeId={property?.ValueTypeId}
                allowedDataSourceValueTypes={[]}
            />
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        return stripHtml(property?.RenderedValue).toLowerCase().includes(value);
    }
}
