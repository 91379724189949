import { useState, useRef } from "react";
import { AppBar, Toolbar, Box, MenuItem, IconButton, Divider, Popper, Grow, Paper, MenuList, Drawer, ClickAwayListener, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NavBarProps from '../../types/NavBarProps';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import "./NavBar.css";

function NavBar({ title, leftContent, rightContent, zIndex = 9999, hideMenu = false }: NavBarProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [buildMenuOpen, setBuildMenuOpen] = useState(false);
    const menuButtonRef = useRef(null);
    const buildButtonRef = useRef(null);

    const theme = useTheme();
    const menuItemStyle = { position: "absolute", right: "16px", color: 'secondary.main' };
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();

    const goToDashboard = () => {
        navigate("/")
    };

    const goToCustomBlocks = () => {
        navigate("/sharedcontentmanager/customblocks")
    };

    const goToTemplates = () => {
        navigate("/sharedcontentmanager/templates")
    };

    const handleMenuToggle = () => {
        if (menuOpen) {
            handleCloseMenus();
        } else {
            setMenuOpen(true);
        }
    }
    const displayBuildMenu = (event: any) => {
        closeSubMenus();
        setBuildMenuOpen(true);
    };
    const closeSubMenus = () => {
        setBuildMenuOpen(false);
    };
    const handleCloseMenus = () => {
        closeSubMenus();
        setMenuOpen(false);
    };

    const getMenu = () => {
        return (
            <>
            { !hideMenu &&
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, backgroundColor: menuOpen ? "rgba(0, 0, 0, 0.04)" : "inherit" }}
                    ref={menuButtonRef}
                    id="menu-button"
                    aria-controls="nav-menu"
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    data-testid="menu-icon"
                    onClick={handleMenuToggle}
                >
                    <MenuIcon />
                </IconButton>
            }
            {!isMobileView &&
                <Box className="popperContainer">
                    <Popper
                        open={menuOpen}
                        anchorEl={menuButtonRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                    {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom'
                        }}
                        >
                            <Paper sx={{ width: "500px", maxWidth: "100%", marginTop: "10px" }}>
                                <ClickAwayListener onClickAway={handleCloseMenus}>
                                <MenuList
                                        autoFocusItem={menuOpen}
                                        id="nav-menu"
                                        aria-labelledby="menu-button"
                                        sx={{ paddingTop: "0px" }}
                                    >
                                    <Box sx={{ padding: "16px", backgroundColor: "rgba(33, 33, 33, 0.08)" }}>
                                        <Button
                                            variant="outlined"
                                            onClick={goToDashboard}
                                            color="secondary"
                                            sx={{
                                                color: "black",
                                                borderColor: "black",
                                                '&:hover': {
                                                    borderColor: "black",
                                                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                                                }
                                            }}
                                            startIcon={<HomeOutlinedIcon />}
                                        >
                                            Dashboard
                                        </Button>
                                    </Box>
                                    <MenuItem
                                        onMouseOver={displayBuildMenu}
                                        ref={buildButtonRef}
                                        id="build-button"
                                        aria-controls="build-menu"
                                        aria-haspopup="true"
                                        aria-expanded={buildMenuOpen ? 'true' : undefined}
                                        sx={{ padding: "16px" }}
                                        >
                                            Build <ArrowRightIcon sx={menuItemStyle} />
                                    </MenuItem>
                                </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                    </Popper>
                    <Box className="popperContainer">
                        <Popper
                            open={buildMenuOpen}
                            anchorEl={buildButtonRef.current}
                            role={undefined}
                            placement="right-start"
                            transition
                            disablePortal
                            modifiers={[
                                {
                                    name: 'offset',
                                    enabled: true,
                                    options: {
                                        offset: [-5, 0]
                                    },
                                }
                            ]}
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'right top',
                            }}
                            >
                                <Paper sx={{ width: "500px", maxWidth: "100%" }}>
                                    <MenuList
                                            autoFocusItem={buildMenuOpen}
                                            id="build-menu"
                                            aria-labelledby="build-button"
                                        >
                                        <MenuItem onClick={goToCustomBlocks} sx={{ padding: "0px" }}>
                                            <Box sx={{ padding: "15px" }}>Custom Blocks</Box>
                                        </MenuItem>
                                        <MenuItem onClick={goToTemplates} sx={{ padding: "0px" }}>
                                            <Box sx={{ padding: "15px" }}>Templates</Box>
                                        </MenuItem>
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                        </Popper>
                    </Box>
                </Box>
            }
            </>
        );
    };

    return (
        <>
        <AppBar position="relative" style={{ background: '#cf1f2e', zIndex }}>
            <Toolbar variant="dense">
                {getMenu()}
                {leftContent}
                <Box display="flex" flexGrow={1} sx={{ color: "white", fontSize: "24px", lineHeight: "24px", whiteSpace: "nowrap", overflow: "hidden" }}>
                    {title}
                </Box>
                {rightContent}
            </Toolbar>
        </AppBar>
        {isMobileView &&
            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={handleCloseMenus}
            >
                <MenuList
                        id="mobile-menu"
                        aria-labelledby="menu-button"
                        sx={{ width: "330px" }}
                    >
                    <MenuItem onClick={goToDashboard} sx={{ padding: "0px" }}><Box sx={{ padding: "15px" }}>Dashboard</Box></MenuItem>
                    <h6 className="headline6 m2">Build</h6>
                    <Divider />
                    <MenuItem onClick={goToCustomBlocks} sx={{ padding: "0px" }}><Box sx={{ padding: "15px" }}>Custom Blocks</Box></MenuItem>
                </MenuList>
            </Drawer>
        }
        </>
    );
}

export default NavBar;