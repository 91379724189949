import BlockMapping from '../../../../types/BlockMapping';
import HierarchyListItem from './HierarchyListItem';
import { useEffect } from 'react';
import "./Hierarchy.css";
import SiteEditorStore from '../../SiteEditorStore';
import HierarchyFilter from './HierarchyFilter';
import HierarchyDragStyle from './HierarchyDragStyle';

export default function Hierarchy() {
  const page = SiteEditorStore.useStoreState((state) => state.page);
  const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);

  useEffect(() => {
    if (selectedBlocks.length > 0 && selectedBlocks[0]?.BlockId) {
      const element = document.getElementById(selectedBlocks[0]?.Id)
      element?.scrollIntoView({
        block: 'center',
        inline: 'end'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HierarchyDragStyle />
      <div style={{padding: "20px 0px 20px 0px", height:"calc(100% - 136px)"}}>
     
        <div style={{paddingLeft: "20px"}} className="headline6">
          Page Blocks
        </div>
            
        <HierarchyFilter />

        <div className="" style={{ overflowY: "auto", overflowX:"auto", marginTop: "16px", paddingBottom: "95px", maxHeight: "calc(100% - 115px)" }}>
          {page?.BlockMappings?.map((b: BlockMapping, index: number) =>
            <HierarchyListItem
              siteStyleId={page.SiteStyleId}
              indentLevel={0}
              blockMapping={b}
              parentBlockMapping={null}
              key={`${b.Id}-${index}`}
              />
          )}
        </div>
      </div>
    </>
  );

}