import { MouseEvent } from 'react';
import { Dialog, Button } from '@mui/material';
import { ResponseError } from '../../utils/apiHelpers';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export interface BuilderDialogProps {
    title: string,
    message?: string,
    action?: (e: MouseEvent<HTMLButtonElement>) => void,
    actionName?: string,
    actionButtonType?: "outlined" | "text" | "contained" | undefined,
    actionDisabled?: boolean | undefined,
    actionTestId?: string,
    open: boolean,
    onClose: (e: MouseEvent<HTMLButtonElement>) => void,
    showCancel?: boolean,
    cancelTestId?: string,
    danger?: boolean,
    children?: any,
    apiError?: ResponseError | null,
    errorMessage?: string | null,
    secondaryAction?: () => void,
    secondaryActionName?: string,
    secondaryActionButtonType?: "outlined" | "text" | "contained" | undefined,
    secondaryActionColor?: "primary" | "secondary" | "inherit" | "success" | "error" | "info" | "warning" | undefined,
    secondaryActionTestId?: string,
    fullWidth?: boolean | undefined,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined,
    boldenMessage?: boolean,
    backAction?: () => void
}

export default function BuilderDialog({
    title,
    message,
    action = undefined,
    actionName = "",
    open,
    onClose,
    showCancel = false,
    cancelTestId,
    danger = false,
    children = null,
    apiError = null,
    errorMessage = null,
    actionButtonType = 'outlined',
    actionTestId,
    secondaryAction,
    secondaryActionName,
    secondaryActionButtonType = "text",
    secondaryActionColor,
    secondaryActionTestId,
    fullWidth = true,
    maxWidth = 'sm',
    boldenMessage = false,
    actionDisabled = false,
    backAction = undefined,
}: BuilderDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={(e: any) => !showCancel && onClose(e) }
            onClick={(e: any) => { e.stopPropagation(); }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}>
            {backAction && <div style={{position: "absolute", top: "16px", left:"16px", height:"24px", cursor: "pointer"}}
                onClick={backAction}>
                <ChevronLeftIcon/>
            </div>}
            <div style={{ margin: "16px 16px 32px 16px", minWidth: "515px", textAlign: "center" }} className="headline6">
                {title}
            </div>
            <div className="body1" style={{ margin: children ? "0px 16px 0px 16px" : "0px 16px 200px 16px", color: "black",
                fontWeight: boldenMessage ? "bold" : "normal", overflowY: "auto" }}>
                { errorMessage &&
                    <div style={{ color: "#CF1F2E" }}>{errorMessage}</div>
                }
                { apiError && apiError.body?.Errors?.length > 0 &&
                    <ul style={{ paddingLeft: "20px", color: "#CF1F2E"}}>
                        {apiError.body.Errors.map((e: any, i: number) => <li key={i}>{e.Message}</li>)}
                    </ul>
                }
                {message}
                {children}
            </div>
            <div style={{ margin: "16px" }}>
                { showCancel &&
                    <Button
                        variant="text"
                        data-testid={cancelTestId}
                        color="secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                }
                { action &&
                    <Button
                        data-testid={actionTestId}
                        disabled={actionDisabled}
                        variant={ danger ? 'contained' : actionButtonType }
                        color={ danger ? 'error' : 'secondary' }
                        sx={{ float: "right"}}
                        onClick={action}
                        autoFocus
                    >
                        {actionName}
                    </Button>
                }
                { secondaryAction && secondaryActionName &&
                    <Button
                        data-testid={secondaryActionTestId}
                        variant={secondaryActionButtonType}
                        color={secondaryActionColor}
                        sx={{ float: "right", marginRight: "16px" }}
                        onClick={secondaryAction}
                    >
                        {secondaryActionName}
                    </Button>
                }
            </div>
        </Dialog>
    );
}