import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore, stripHtml } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import DangerousText from '../DangerousText';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import { getFileNameFromUrl } from '../../../utils/urlHelpers';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block(props: BlockProps) {
    const content = getPropertyValue(props.block.Properties, PropertyId.ContentHTML);
    const className = getPropertyValue(props.block.Properties, PropertyId.ClassName);

    return (
        <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className, blockWrapperData: props.blockWrapperData})}>
            {isChildrenPositionBefore(props.block) && props.children}
            <DangerousText html={content}/>
            {!isChildrenPositionBefore(props.block) && props.children}
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return getPropertyValue(block.Properties, PropertyId.ContentHTML)
        || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    if (content) {
        return <>{stripHtml(content)}</>;
    }

    const id = getPropertyValue(block.Properties, PropertyId.ID);

    if (id) {
        return <>#{id}</>;
    }

    const bgImageProp = getPropertyValue(block.Properties, PropertyId.BackgroundImage);

    if (bgImageProp) {
        const bgImagePropValue = JSON.parse(bgImageProp);
        const fileName = getFileNameFromUrl(bgImagePropValue.u);
        if (fileName) {
            return <>{fileName}</>;
        }
    }

    const bgColorProp = getPropertyValue(block.Properties, PropertyId.BackgroundColor);

    const colorCode = bgColorProp ? JSON.parse(bgColorProp)?.hex : null;

    if (colorCode) {
        return <>{colorCode}</>;
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);
    const bgImageProp = getPropertyValue(block.Properties, PropertyId.BackgroundImage);
    let fileName: string | null = '';
    if (bgImageProp) {
        const bgImagePropValue = JSON.parse(bgImageProp);
        fileName = getFileNameFromUrl(bgImagePropValue.u);
    }
    const bgColorProp = getPropertyValue(block.Properties, PropertyId.BackgroundColor);
    const colorCode = bgColorProp ? JSON.parse(bgColorProp)?.hex : null;

    return (!!content && (content.toLowerCase().includes(filterText)))
        || (!!fileName && (fileName.toLowerCase().includes(filterText)))
        || (!!colorCode && (colorCode.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}