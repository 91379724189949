
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box } from '@mui/material';
import React from 'react';

export interface TemplateBoxDragHandleProps {
    name: string | null;
    children?: React.ReactNode;
}

const TemplateBoxDragHandle: React.FC<TemplateBoxDragHandleProps> = ({
    name,
    children
}) => {
    return (
        <Box sx={{
            height: "54px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "440px",
            cursor: "move"
        }}>
            <Box sx={{ flex: 0, marginRight: "16px" }}>
            <DragIndicatorIcon sx={{ float: "left", opacity: "60%" }} />
            </Box>
            <Box sx={{ flex: 1 }}>
                {name}
            </Box>
            { children }
        </Box>
    );
};

export default TemplateBoxDragHandle;
