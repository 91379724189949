import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';

export interface HelpTooltipProps {
    tooltipText: string | JSX.Element,
    iconTestId?: string | null
}


export function HelpTooltip({ tooltipText, iconTestId }: HelpTooltipProps ) {

    return (
        <Tooltip title={tooltipText} placement="left" arrow>
            <HelpIcon 
                data-testid={iconTestId || "tooltip-icon"} 
                sx={{
                    cursor: "pointer",
                    color: "#4C808D;",
                    fontSize: "20px",
                    position:"absolute",
                    top:"2px",
                    right:"3px"
                    }} 
            />
        </Tooltip>
    );
}