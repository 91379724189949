import { Box } from "@mui/material"
import './FileGridItem.css';
import { getFileSizeDisplay } from '../../utils/fileHelpers';
import FileRecord from "../../types/FileRecord";
import { truncateFilename } from "../../utils/stringHelpers";

export interface FileGridItemProps {
    file: FileRecord;
    handleSelect: (file: FileRecord) => void;
    handleDelete: (file: FileRecord) => void;
    selected: boolean;
    name?: string;
}

export default function FileGridItem({file, handleSelect, handleDelete, selected, name}: FileGridItemProps) {
    const fileSize = getFileSizeDisplay(file.FileSize);
    const imageSize = file.ImageWidth && file.ImageHeight ? `${file.ImageWidth}x${file.ImageHeight}` : '';
    const imageTitle = `${file.FileName}${file.FileExtension}`;
    const nameLabel = truncateFilename(name ?? imageTitle);

    return (
        <Box sx={{
            flex: '0 1 150px',
            position: 'relative',
            minHeight: '176px',
            width: '147px',
            cursor: "pointer",
            border: 2,
            borderRadius: 1,
            borderColor: selected ? 'secondary.main' : 'transparent',
            '&:hover': {
                borderColor: selected ? 'secondary.main' : '#f2f2f2'
            }
        }}
        onClick={() => {
            handleSelect(file);
        }} >
            <Box sx={{ padding: '4px', textAlign: 'center' }}>
                <Box sx={{
                        height: '100px',
                        width: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 auto'
                    }}>
                    <img 
                        style={{ border: '1px solid #f2f2f2', borderRadius: '4px', maxHeight: '100%', maxWidth: '100%' }} 
                        src={file.PreviewURL ? file.PreviewURL : file.URL} 
                        alt={file.FileName} >
                    </img>
                </Box>
                <Box className="body2 content-color" sx={{
                    maxWidth: '100%',
                    margin: '8px auto 0 auto',
                    textAlign: 'center',
                    overflowWrap: 'anywhere',
                    lineHeight: '1.43em'}}>
                    { nameLabel}
                </Box>
                <Box className="caption content-color" sx={{
                    opacity: '0.7',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    margin: '8px auto 0 auto',
                    textAlign: 'center'}}>
                    {fileSize}
                </Box>
                <Box className="caption content-color" sx={{
                    opacity: '0.7',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    margin: '0 auto 0 auto',
                    textAlign: 'center'}}>
                    {imageSize}
                </Box>
            </Box>
        </Box>
    )
}