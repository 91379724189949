import BlockMapping from '../../types/BlockMapping';
import DisabledLabel from './DisabledLabel';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import BlockMappingStatusIds from '../../types/enum/BlockMappingStatusId';
import SiteEditorStore from './SiteEditorStore';

export interface BlockTitleProps {
    style?: any,
    className?: string,
    blockMapping: BlockMapping | null,
}

export default function BlockTitle({blockMapping, style, className}: BlockTitleProps) {
    const blocksAndSections = SiteEditorStore.useStoreState((state) => state.blocksAndSections);
    const block = blocksAndSections.find(b => blockMapping != null && b.b === blockMapping.BlockId);
    let blockName = block?.n ?? "";
    let isBlockStatusActive = blockMapping?.StatusTypeId === BlockMappingStatusIds.Active;
    const blockDisplayName = blockMapping?.ComponentBlockMappingId ? `${blockName} (${blockMapping.ComponentName})`
        : blockName;

    return (
      <div className={className} style={style}>
          {!isBlockStatusActive &&
            <DisabledLabel label={"Block Disabled"} />
          }
          <div data-testid="properties-block-name">
              <div style={{ display: "flex", alignItems: "center"}}>
                  { blockMapping?.ComponentBlockMappingId &&
                      <SettingsInputComponentIcon sx={{ marginRight: "8px" }} />
                  }
                  {blockDisplayName}
              </div>
          </div>
      </div>
    );
}