import { CSSProperties, useRef } from 'react';
import { getBlockRenderer } from '../../utils/blockMappings';
import BlockMappingStatusId from '../../types/enum/BlockMappingStatusId';
import BlockActionMenu from "./BlockActionMenu";
import { useDrag, useDrop } from 'react-dnd';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { clamp } from '../../utils/numberHelpers';
import BlockMapping from '../../types/BlockMapping';
import Page from '../../types/Page';
import BuilderProps from '../../types/BuilderProps';
import HoverData from '../../types/HoverData';

export interface BlockWrapperProps {
    page: Page;
    blockmapping: BlockMapping;
    builderProps?: BuilderProps;
    renderData: any;
    setRenderData?: React.Dispatch<React.SetStateAction<{}>>;
}

export default function BlockWrapper({page, blockmapping, builderProps, renderData, setRenderData }: BlockWrapperProps) {
    const ref = useRef<HTMLElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'BlockMapping',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            if (monitor.isOver({ shallow: true })) {
                const rect = ref.current?.getBoundingClientRect();
                const mouse = monitor.getClientOffset() || {x: 0, y: 0};
                const leftPercent = (mouse.x - rect.left) / rect.width;
                const rightPercent =  (rect.right - mouse.x) / rect.width;
                const topPercent = (mouse.y - rect.top) / rect.height;
                const bottomPercent = (rect.bottom - mouse.y) / rect.height;
                const isBefore = (leftPercent <= .2 && topPercent <= .5)
                    || (rightPercent <= .2 && topPercent <= .5)
                    || topPercent <= .2;
                const isAfter = (leftPercent <= .2 && topPercent > .5)
                    || (rightPercent <= .2 && topPercent > .5)
                    || bottomPercent <= .2;
                const isInto = !isBefore && !isAfter;
                const hoverData: HoverData = {
                    rect,
                    mouse,
                    isBefore,
                    isAfter,
                    isInto,
                    hoveredId: blockmapping.Id,
                    blockMappingId: item.blockMappingId,
                    templateId: item.templateId,
                    blockId: item.blockId,
                    hoveredParentIndex: null,
                    componentId: item.componentId
                };

                if (builderProps?.dragDrop?.hovering) {
                    builderProps.dragDrop.hovering(hoverData);
                }
            }
        },
        drop(item, monitor) {
            if (builderProps?.dragDrop?.finished) {
                builderProps.dragDrop.finished(builderProps.dragDrop.hoverData);
            }
        }
    });
    const [, drag] = useDrag({
        type: 'BlockMapping',
        item: () => {
            if (builderProps?.dragDrop?.started) {
                builderProps.dragDrop.started({ type: 'BlockMapping' });
            }
            return { blockMappingId: blockmapping.Id }
        },
        end: (item, monitor) => {
            if (builderProps?.dragDrop?.finished) {
                builderProps.dragDrop.finished(null);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
    });


    const blockWrapperData =
    {
        ref,
        handlerId
    };

    if (builderProps?.isEditPage) {
        drag(drop(ref));
    }

    const blockRenderer = getBlockRenderer(page?.SiteStyleId, blockmapping?.EnumId, blockmapping?.BlockContentId);

    if (!blockRenderer) {
        return null;
    } else if (!blockRenderer.Block || !blockRenderer.Placeholder || !blockRenderer.hasContentToRender || !blockRenderer.usePlaceholder) {
        throw new Error("Block renderer misconfigured");
    }

    const Block = blockRenderer.Block;

    if (!Block) {
        return null;
    }

    const childrenHaveContentToRender = (block: BlockMapping) => {
        let childrenHaveContentToRender = false;

        if (block.BlockMappings) {
            for (const child of block.BlockMappings) {
                const childHasContent = hasContentToRender(child);

                if (childHasContent) {
                    childrenHaveContentToRender = true;
                    break;
                }
            }
        }

        return childrenHaveContentToRender;
    };

    const hasContentToRender = (block: BlockMapping) => {
        let scopedBlockRenderer = getBlockRenderer(page?.SiteStyleId, block?.EnumId, block?.BlockContentId);;

        const hasContentToRenderFunc = scopedBlockRenderer?.hasContentToRender;

        if (!hasContentToRenderFunc) {
            return false;
        }

        let childrenHaveContent = childrenHaveContentToRender(block);

        return hasContentToRenderFunc(block, page, childrenHaveContent);
    };

    const childrenHaveContent = childrenHaveContentToRender(blockmapping);

    const placeholderFunc = blockRenderer?.usePlaceholder;
    const PlaceholderBlock = blockRenderer?.Placeholder;
    let usePlaceholderBlock = false;
    if (placeholderFunc && PlaceholderBlock && placeholderFunc(blockmapping, page, childrenHaveContent)) {
        usePlaceholderBlock = true;
    }

    let iconSize = 100;

    const refBox = ref?.current?.getBoundingClientRect();
    let disabledStyle: CSSProperties | null = null;

    if (refBox) {
        disabledStyle = {
            position:"fixed",
            top: refBox.top - 48 + (builderProps?.scrollOffset || 0),
            left: refBox.left - 75,
            height:refBox.height,
            width:refBox.width,
            backgroundColor:"rgba(61,68,81,0.2)",
            display:"flex",
            border:"2px dashed rgba(34,34,34,0.38)",
            alignItems:"center",
            justifyContent:"center",
            backdropFilter:"blur(3px)",
            cursor:"pointer",
            margin:0,
            padding:0
        };
        iconSize = clamp((refBox.width > refBox.height ? refBox.height : refBox.width) * .5, 0, 100);
    }

    return (
        <>
            { !usePlaceholderBlock &&
                <Block
                    page={page}
                    block={blockmapping}
                    builderProps={builderProps}
                    renderData={renderData}
                    setRenderData={setRenderData}
                    childrenHaveContentToRender={childrenHaveContent}
                    blockWrapperData={blockWrapperData}
                    style={{opacity:".2"}}
                >
                    {blockmapping?.BlockMappings && blockmapping.BlockMappings.map((b: BlockMapping, index: number) =>
                        <BlockWrapper
                            key={`${b.Id}-${index}`}
                            page={page}
                            blockmapping={b}
                            builderProps={builderProps}
                            renderData={renderData}
                            setRenderData={setRenderData}
                        />
                    )}
                </Block>
            }
            { usePlaceholderBlock &&
                <PlaceholderBlock
                    page={page}
                    block={blockmapping}
                    builderProps={builderProps}
                    renderData={renderData}
                    setRenderData={setRenderData}
                    childrenHaveContentToRender={childrenHaveContent}
                    blockWrapperData={blockWrapperData}>
                    {blockmapping?.BlockMappings && blockmapping.BlockMappings.map((b: BlockMapping, index: number) =>
                        <BlockWrapper
                            key={`${b.Id}-${index}`}
                            page={page}
                            blockmapping={b}
                            builderProps={builderProps}
                            renderData={renderData}
                            setRenderData={setRenderData}
                        />
                    )}
                </PlaceholderBlock>
            }
            {Block !== null && blockmapping.StatusTypeId === BlockMappingStatusId.Inactive && disabledStyle &&
                <div
                    id={`disabled-block-overlay-${blockmapping.Id}`}
                    style={disabledStyle}
                    onClick={(e) => { builderProps?.blockClicked && builderProps.blockClicked(blockmapping, e)}}
                    onMouseOver={(e) => {builderProps?.onBlockHover && builderProps.onBlockHover(blockmapping, e)}}
                >
                    <ChangeHistoryIcon style={{maxWidth:`${iconSize}px`,opacity:".38"}} />
                </div>
            }
            {builderProps?.selectedBlocks &&
                !builderProps.dragDrop?.isDragging &&
                builderProps.selectedBlocks.length === 1 &&
                blockmapping.Id === builderProps.selectedBlocks[0].Id &&
                !builderProps.shiftHeld &&
                <BlockActionMenu
                    builderProps={builderProps}
                    anchorEl={ref?.current}
                    blockId={blockmapping.Id}
                    blockEnabled={blockmapping.StatusTypeId === BlockMappingStatusId.Active}
                ></BlockActionMenu>
            }
        </>
    );
}