import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useApiGetCallbackAsync from "../../hooks/useApiGetCallbackAsync";
import useApiPostCallbackAsync from "../../hooks/useApiPostCallbackAsync";
import Box from '@mui/material/Box';
import Loading from '../common/Loading';
import PropertyId from '../../types/enum/PropertyId'
import { ErrorAlertMessage } from '../common/AlertMessage';
import NavBar from "../common/NavBar";
import { Editor as TextEditorProperty } from '../property_editors/TextEditorProperty';
import Button from '@mui/material/Button';
import './AdminPage.css'
import Property from "../../types/Property";

function AdminPage() {
    const { siteId } = useParams<{siteId: string}>();
    const navigate = useNavigate();
    const [doReturn, setDoReturn] = useState(false);

    const [{ data:site, isLoading: siteLoading, isError:siteError }, getSiteCallback]
        = useApiGetCallbackAsync(`/sites/${siteId}`, null) as any;

    const [{ data:properties, isLoading:propertiesSearchLoading }, searchSitePropertiesCallback]
        = useApiPostCallbackAsync(`/properties/site/${siteId}/search`, null) as any;

    const [{ isLoading:upsertingProperties, isError:upsertError }, upsertCallback] =
        useApiPostCallbackAsync(`/properties/site/${siteId}/upsert`, null) as any;

    useEffect(() => {
        getSiteCallback(() => {
            searchSitePropertiesCallback({
                EnumIds: [
                    PropertyId.GoogleRecaptchaSiteKey,
                    PropertyId.GoogleRecaptchaSecretKey,
                    PropertyId.PixelId
                ]
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    useEffect(() => {
        if (doReturn) {
            navigate(`/site/${siteId}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doReturn]);

    const recaptchaSiteKeyProperty = properties && properties.find((x: Property) => x.EnumId === PropertyId.GoogleRecaptchaSiteKey);
    const recaptchaSecretKeyProperty = properties && properties.find((x: Property) => x.EnumId === PropertyId.GoogleRecaptchaSecretKey);
    const pixelIdProperty = properties && properties.find((x: Property) => x.EnumId === PropertyId.PixelId);

    const handleBack = () => {
        setDoReturn(true);
    };

    const handleSave = () => {
        if (upsertingProperties) {
            return;
        }

        upsertCallback(properties, () => {
            setDoReturn(true);
        });
    };

    return (
        <>
            <NavBar title={site?.Domain ?? 'Admin'} />
            { siteError && <ErrorAlertMessage message='An error has occurred.' />}
            { upsertError &&
                <ErrorAlertMessage message={'Failed to submit, please try again.'} actionName={'Retry'} action={handleSave}/>
            }
            { (siteLoading || propertiesSearchLoading || upsertingProperties) &&
                <Loading /> }
            { !siteLoading && !site &&
                <h1>Site not found</h1> }
            { site && properties &&
                <>
                    <Box id="admin-container" sx={{paddingLeft: "32px", paddingRight: "32px" }}>
                        <h1 className="headline4">Administrative Setup</h1>
                        <Box className="admin-setup-section">
                            <h3>Google Recaptcha</h3>
                            <TextEditorProperty property={recaptchaSiteKeyProperty} propertyUpdated={() => {}}
                                label="Recaptcha Site Key" isPageEditor={false} pageList={[]} />
                            <TextEditorProperty property={recaptchaSecretKeyProperty} propertyUpdated={() => {}}
                                label="Recaptcha Secret Key" isPageEditor={false} pageList={[]} />
                        </Box>
                        <Box className="admin-setup-section">
                            <h3>Facebook Pixel</h3>
                            <TextEditorProperty property={pixelIdProperty} propertyUpdated={(() => {})} label="Pixel ID" isPageEditor={false} pageList={[]} />
                        </Box>
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 0, width: '100%', borderTop: 'thin solid rgba(0, 0, 0, 0.12)'}}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                            <Box sx={{ width: '100%', display: { xs: 'flex', sm: 'flex', md: 'flex'}}}>
                                <Button onClick={handleBack} sx={{ mr: 1 }}>Cancel</Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleSave} disabled={upsertingProperties} variant="contained">Save</Button>
                            </Box>
                        </Box>
                    </Box>
                </>}
        </>
    )
}

export default AdminPage;
