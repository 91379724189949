import BlockFilterData from '../../../types/BlockFilterData';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { getPropertyValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import PropertyId from '../../../types/enum/PropertyId';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';

function getTailwindSize(size: string | null | undefined) {
    switch(size) {
        case 'xs':
            return 4;
        case 's':
            return 8;
        case 'l':
            return 20;
        case 'xl':
            return 24;
        default:
            return 16;
    }
}

function getClass(responsiveSize: string | null, marginSize: string | null | undefined) {
    var className = " ";
    if (responsiveSize) {
        className += responsiveSize;
        className += ":";
    }
    className += "mt-";
    className += getTailwindSize(marginSize);
    return className;
}

function getDynamicClasses(block: BlockMapping) {
    const mobile = getPropertyValue(block.Properties, PropertyId.MobileHeight);
    const tablet = getPropertyValue(block.Properties, PropertyId.TabletHeight);
    const desktop = getPropertyValue(block.Properties, PropertyId.DesktopHeight);

    let classes = "";
    classes += getClass(null, mobile);
    classes += getClass("md", tablet);
    classes += getClass("lg", desktop);

    return classes;
};

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const classes = `spacer${getDynamicClasses(block)}`

    return <div {...getDynamicAttributes({block, builderProps, className: classes, blockWrapperData})}>{children}</div>;
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return false;
}

export function Placeholder(props: BlockProps) {
    return null;
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}