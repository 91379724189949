import React from "react";
import { logError } from "../utils/errorLogger";
import Typography from '@mui/material/Typography';
import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';

export interface ErrorBoundaryProps {
  sessionId: string | null;
  correlationId: string | null;
  auth0: Auth0ContextInterface;
  children?: React.ReactNode;
}

class Boundary extends React.Component<ErrorBoundaryProps, any> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      this.setState({ hasError: true });

      this.props.auth0.getAccessTokenSilently()
      .then(accessToken => {
        logError({
          error,
          info,
          user: this.props.auth0.user,
          correlationId: this.props.correlationId,
          sessionId: this.props.sessionId,
          accessToken,
          log: "RevSite.Builder.ErrorBoundary",
          logSource: "RevSite.Builder.ErrorBoundary.componentDidCatch"
        });
      });
    }

    render() {
      if (this.state.hasError) {
        return (
          <Typography variant="h2">
            Something went wrong
          </Typography>
        );
      }
      return this.props.children;
    }
}

const ErrorBoundary = withAuth0(Boundary);
export default ErrorBoundary;