import { getBlockWrapperAttributes, getDynamicAttributes, getHoverAttributes } from '../../utils/blockHelpers';
import BlockProps from '../../types/BlockProps';
import BlockMapping from '../../types/BlockMapping';
import Page from '../../types/Page';
import MisconfiguredBlock from './MisconfiguredBlock';
import { defaultFilter, DefaultHierarchyContent } from '../../utils/blockRendererDefaults';
import { createElement } from 'react';

import parse, { attributesToProps, domToReact } from 'html-react-parser';
import PropertyValueType from '../../types/enum/PropertyValueType';

export function isConfiguredProperly(block: BlockMapping, childrenHaveContentToRender: boolean) {
    let result = true;
    block.Properties.forEach(p => {
        if (p.Required && !p.RenderedValue) {
            result = false;
        }
    })
    return result;
};

export function Block(props: BlockProps) {
    var customBlockHtml = props.page.BlockContent.find(x => x.Id === props.block.BlockContentId)?.Html

    if (!isConfiguredProperly(props.block, !!props.childrenHaveContentToRender) || !customBlockHtml) {
        return <></>;
    }

    const propSwapRegex = /\[P:(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}\]/gm;

    const matches = Array.from(customBlockHtml.matchAll(propSwapRegex)).map(p => p[0]);
    const propSwaps = matches?.filter((v: string, i: number, a: string[]) => a.indexOf(v) === i); //filter to unique prop ids

    propSwaps.forEach((propSwap: string) => {
        const propId = propSwap.replace('[P:', '').replace(']', '');
        const prop = props.block.Properties.find(p => p.PropertyId === propId);
        customBlockHtml = customBlockHtml?.replaceAll(propSwap, prop?.RenderedValue || '');
    });

    const childrenPlaceholder = '<div id="children"></div>';
    const dynamicAttributesPlaceholder = ' id="dynamicAttributes" ';

    const html = customBlockHtml
        .replace('[CHILDREN]', childrenPlaceholder)
        .replace('[DYNAMIC_ATTRIBUTES]', dynamicAttributesPlaceholder);

    const options = {
        replace: (domNode: any) => {
            if (domNode.attribs) {
                if (domNode.attribs.id === 'children') {
                    return <>{props.children}</>;
                } 
                else if (!domNode.parent) {
                    let builderAttributes: any = {};
                    if (domNode.attribs.id === 'dynamicAttributes') {
                        builderAttributes = getDynamicAttributes({
                            block: props.block,
                            builderProps: props.builderProps,
                            blockWrapperData: props.blockWrapperData,
                            className: domNode.attribs.class
                        });
                    }
                    else {
                        builderAttributes = {
                            ...getHoverAttributes({
                                block: props.block,
                                builderProps: props.builderProps,
                                classes: domNode.attribs.class
                            }), 
                            ...getBlockWrapperAttributes(props.blockWrapperData)
                        };
                    }
                    
                    const hardCodedAttributes = attributesToProps(domNode.attribs);

                    return createElement(domNode.name, {...hardCodedAttributes, ...builderAttributes}, domToReact(domNode.children, options));
                } 
            }
        }
    };

    return (
        <>
            {parse(html, options)}
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block, childrenHaveContentToRender) || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !isConfiguredProperly(block, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string): boolean {

    const searchableProps = block.Properties?.filter(p => 
        p.ValueTypeId === PropertyValueType.HTML
        || p.ValueTypeId === PropertyValueType.Text
        || p.ValueTypeId === PropertyValueType.TextArea
    );

    let found = false;
    searchableProps.forEach(p => {
        if (p.RenderedValue?.toLowerCase().includes(filterText)) {
            found = true;
        }
    });

    return found 
        || defaultFilter(block, filterText);
}