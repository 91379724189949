import Type from '../types/Type';

export function updateTypeNameAsDisplayNameAndSort(types: Type[], omitGroupingTypes: boolean = false) : Type[] {
  let sortedTypes: Type[] = [];

  const getTypeDisplayValue = (type: Type, allTypes: Type[]): string => {
    const parent = allTypes.find((c: Type) => type.ParentTypeId && c.Id === type.ParentTypeId);

    let name = allTypes.find((c: Type) => c.Id === type.Id)?.Name ?? '';

    if (parent) {
        name = `${getTypeDisplayValue(parent, types)} - ${name}`;
    }

    return name;
  };

  for (let type of types) {
    if (!omitGroupingTypes || type.ParentTypeId !== null) {
      sortedTypes.push({
        Id: type.Id,
        Name: getTypeDisplayValue(type, types),
        ParentTypeId: type.ParentTypeId
      })
    }
  }
  sortedTypes.sort(function(a,b) {return (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0);});

  return sortedTypes;
}
