import * as DropdownProperty from '../components/property_editors/DropdownProperty';
import * as AnchorConfigProperty from '../components/property_editors/AnchorConfigProperty';
import * as FontAwesomeIconProperty from '../components/property_editors/FontAwesomeIconProperty';
import * as ScheduleProperty from '../components/property_editors/ScheduleProperty';
import * as ImageCarouselSlidesProperty from '../components/property_editors/ImageCarouselSlidesProperty';
import * as ImageProperty from '../components/property_editors/ImageProperty';
import * as FontProperty from '../components/property_editors/FontProperty';
import * as ColorProperty from '../components/property_editors/ColorProperty';
import * as NumberProperty from '../components/property_editors/NumberProperty';
import * as TextEditorProperty from '../components/property_editors/TextEditorProperty';
import * as ContactFormConfigProperty from '../components/property_editors/ContactFormConfigProperty';
import * as CheckboxesProperty from '../components/property_editors/CheckboxesProperty';
import * as BooleanProperty from '../components/property_editors/BooleanProperty';
import * as NavigationProperty from '../components/property_editors/NavigationProperty';
import * as RequiredBlocksProperty from '../components/property_editors/RequiredBlocksProperty';
import * as KeyValuePairProperty from '../components/property_editors/KeyValuePairProperty';
import * as TextListProperty from '../components/property_editors/TextListProperty';
import * as RadiosProperty from '../components/property_editors/RadiosProperty';
import * as DataSourceScopeProperty from '../components/property_editors/DataSourceScopeProperty';
import * as VideoProperty from '../components/property_editors/VideoProperty';

const property_renderers: any[] = [];

property_renderers[801] = TextEditorProperty;
property_renderers[802] = ColorProperty;
property_renderers[805] = ImageProperty;
property_renderers[806] = DropdownProperty;
property_renderers[807] = BooleanProperty;
property_renderers[809] = FontAwesomeIconProperty;
property_renderers[810] = NumberProperty;
property_renderers[811] = TextEditorProperty;
property_renderers[814] = AnchorConfigProperty;
property_renderers[815] = ContactFormConfigProperty;
property_renderers[816] = RequiredBlocksProperty;
property_renderers[820] = ScheduleProperty;
property_renderers[821] = ImageCarouselSlidesProperty;
property_renderers[822] = TextEditorProperty;
property_renderers[823] = FontProperty;
property_renderers[826] = NavigationProperty;
property_renderers[827] = CheckboxesProperty;
property_renderers[828] = KeyValuePairProperty;
property_renderers[829] = TextListProperty;
property_renderers[830] = RadiosProperty;
property_renderers[831] = DataSourceScopeProperty;
property_renderers[832] = VideoProperty;
/*
	Unimplemented types
	(808, 'Icon Anchor List', 800, null),
	(813, 'Material Icon', 800, null),
	(816, 'Required Blocks', 800, null),
	(817, 'Hours Config', 800, null),
	(818, 'CSV', 800, null),
	(819, 'Geo Coordinates', 800, null),
*/
export default property_renderers;
