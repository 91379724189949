import { useCallback, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';

export default function useApiDeleteCallbackAsync() {
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);

    const callback = useCallback((
        uri: string,
        successCallback: Function,
        failureCallback: Function,
        overrideUri?: string
    ) => {
        const postUri = overrideUri ?? uri;

        const deleteRecord = async () => {
            setIsError(false);
            setIsLoading(true);
            setSuccess(false);
            try {
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                await makeApiCall(postUri, httpMethods.delete, identityHeaders);

                setSuccess(true);
                if (successCallback) {
                    successCallback();
                }
            } catch (error) {
                console.log(error);
                setIsError(true);
                if (failureCallback) {
                    failureCallback(error);
                }
            }

            setIsLoading(false);
        };

        if (postUri) {
            deleteRecord();
        }
    }, [getAccessTokenSilently, currentUser, sessionId, correlationId]);

    return [{ success, isLoading, isError }, callback];
}
