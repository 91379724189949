import { InfoAlertMessage } from "./AlertMessage";
import { Box } from "@mui/material";

export interface InfoMessageProps {
    message: string,
    actionName?: string,
    action?: () => void,
    visible?: boolean,
    duration?: number,
    dismissable?: boolean
}
export default function InfoMessage({message, actionName, action, visible, duration, dismissable}: InfoMessageProps) {

    if (!visible)
    {
        return null;
    } 

    return (
        <Box sx={{ maxWidth: "400px", margin: "0 auto", padding: "1rem", position: "fixed", top: "50px", left: 0, right: 0, zIndex: 9999 }}>
            <InfoAlertMessage filled={true} message={message} actionName={actionName} action={action} visible={visible} duration={duration} dismissable={dismissable} />
        </Box>
    );
}

