import BlockFilterData from '../../../types/BlockFilterData';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { getDynamicAttributes } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    return <thead {...getDynamicAttributes({block, builderProps, blockWrapperData})}>{children}</thead>;
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}