import { ErrorAlertMessage } from "../../common/AlertMessage";
import "./WizardErrorMessage.scss";

export interface WizardErrorMessageProps {
    message: string;
    actionName: string;
    action: () => void;
}

export default function WizardErrorMessage({message, actionName, action}: WizardErrorMessageProps) {

    return (
        <div className="wizard-error-container">
            <div style={{ maxWidth: "400px", margin: "0 auto", padding: "1rem" }}>
                <ErrorAlertMessage filled={true} message={message} actionName={actionName} action={action}/>
            </div>
        </div>
    );
}

