import { useEffect, useState } from 'react';
import { Box, OutlinedInput, Slider } from '@mui/material';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import InputAdornment from '@mui/material/InputAdornment';
import NumberConfiguration from '../../types/NumberConfiguration';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Property from '../../types/Property';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import { PropertyNameLabel } from '../common/PropertyNameLabel';

export function Editor({property, propertyUpdated, label, isPageEditor, setMostRecentValue}: PropertyEditorProps) {
    const [inputValue, setInputValue] = useState<string | null>(null);
    const [propertyValue, setPropertyValue] = useState<string | null | undefined>(property?.RenderedValue);
    const [hasUpdatedValue, setHasUpdatedValue] = useState<boolean>(false);
    
    const config: NumberConfiguration 
        = JSON.parse(property?.Configuration || "{}");

    useEffect(() => {
        initializeInputValue();
        setPropertyValue(property?.RenderedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property]);

    useEffect(() => {
        let timeOutId: NodeJS.Timer;

        if (setMostRecentValue) {
            setMostRecentValue(propertyValue);
        }

        timeOutId = setTimeout(() => {
            if (hasUpdatedValue) {
                updateProperty();
                setHasUpdatedValue(false);
            }
        }, 250);
        return () => timeOutId && clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyValue]);

    const displaySliderMode = (config.IsSlider === true || (config.IsSlider === undefined && !isPageEditor));

    const initializeInputValue = () => {
        if(!hasUpdatedValue) {
            if (property?.RenderedValue) {
                let initialValue = Number(property.RenderedValue);
                if (config.Unit === '%') {
                    setInputValue(Math.round(initialValue * 100).toString());
                } 
                else {
                    setInputValue(initialValue.toString());
                }
            }
            else if (displaySliderMode) {
                    setInputValue("0");
            }
            else {
                setInputValue(null);
            }
        }
    }

    const updateProperty = () => {
        if (property) {
            property.Value = propertyValue;
            property.RenderedValue = propertyValue;
            propertyUpdated(property);
        }
    }

    const sliderChanged = (e: any) => {
        inputChanged(e.target.value);
    };

    const inputChanged = (value: string) => {
        setInputValue(value);
        if(value) {
            let valueAsNumber = Number(value);
            if (config.Type === 'whole') {
                valueAsNumber = Math.trunc(valueAsNumber);
            }

            const clampedNumber = clampValue(valueAsNumber);
            if (inputValue !== clampedNumber.toString()) {
                setInputValue(clampedNumber.toString());
            }
            setHasUpdatedValue(true);
            if (config.Unit === '%') {
                const valueAsPercent = clampedNumber / 100;
                setPropertyValue(valueAsPercent.toString());
            }
            else {
                setPropertyValue(clampedNumber.toString());
            }
        } 
    };

    const clearInput = () => {
        setInputValue(null);
        setPropertyValue(null);
        setHasUpdatedValue(true);
    }

    const clampValue = (num: number) => {
        if (config.Min || config.Min === 0) {
            num = Math.max(num, config.Min);
        }
        if (config.Max) {
            num = Math.min(num, config.Max);
        }
        return num;
    }

    const doBlurEvent = () => {
        initializeInputValue();
    }


    return (
        <>
            {isPageEditor &&
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "100%" }}>
                        <PropertyNameLabel 
                            property={property}
                        ></PropertyNameLabel>
                    </Box>
                    { !propertyValue &&
                        <Box>
                            <AddCircleOutlineIcon sx={{
                                    cursor: "pointer",
                                    fontSize: "26.6px",
                                    marginLeft: "16px",
                                    float: "right"
                                }}
                                color="secondary"
                                onClick={() => {
                                    inputChanged("0");
                                }}
                            />
                        </Box>
                    }
                </Box>
            }
            <Box sx={{ display: "flex", alignItems: "center", width: isPageEditor ? "100%" : "auto", marginTop: isPageEditor ? "8px" : "0" }}>
                {displaySliderMode && inputValue !== null &&
                    <>
                        <Slider 
                            value={Number(inputValue)} 
                            onChange={sliderChanged} 
                            valueLabelDisplay="auto" 
                            sx={{ marginLeft: '10px', marginRight: '10px', width: isPageEditor ? '100%' : '90px' }} 
                        />
                    </>
                }
                {displaySliderMode && !isPageEditor && inputValue !== null &&
                    <OutlinedInput
                        value={inputValue}
                        onChange={(e) => inputChanged(e.target.value)}
                        endAdornment={config.Unit && <InputAdornment position="end">{config.Unit}</InputAdornment>}
                        size="small"
                        type="number"
                        inputProps={{ min: config.Min, max: config.Max, step: config.Step }}
                        sx={{width:"100px", marginLeft:"24px"}}
                        onBlur={doBlurEvent}
                    />
                }
                {(!displaySliderMode || (displaySliderMode && isPageEditor)) && inputValue !== null &&
                    <Box sx={{ width: displaySliderMode ? "auto" : "100%" }}>
                        <OutlinedInput 
                            label={label}
                            value={inputValue}
                            onChange={(e) => inputChanged(e.target.value)}
                            endAdornment={config.Unit && <InputAdornment position="end">{config.Unit}</InputAdornment>}
                            type="number"
                            inputProps={{ min: config.Min, max: config.Max, step: config.Step }}
                            sx={{width:"110px", marginLeft: displaySliderMode ? "24px" : "0" }}
                            onBlur={doBlurEvent}
                        ></OutlinedInput>
                    </Box>
                }
                {isPageEditor && propertyValue &&
                    <Box>
                        <DeleteOutlineIcon sx={{
                                cursor: "pointer",
                                color: "#CF1F2E",
                                fontSize: "25px",
                                marginLeft: "16px",
                                visibility: property?.Value ? undefined : "hidden",
                                float: "right"
                            }} 
                            onClick={clearInput}
                        />
                    </Box>
                }
            </Box>
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        return property?.RenderedValue.toLowerCase().includes(value);
    }
}