import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import TextField from '@mui/material/TextField';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import { Editor as TinyMceEditorReact } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Dialog, DialogActions, DialogContent, Button, Box } from '@mui/material';
import PropertyValueType from '../../types/enum/PropertyValueType';
import './TextEditorProperty.scss';
import { lookupTokenValue } from '../../utils/valueSwapHelpers';
import { stripHtml } from '../../utils/blockHelpers';
import DataSwapModal from './DataSwapModal';
import Property from '../../types/Property';
import DataSourceFieldMappingValueTypes from '../../types/enum/DataSourceFieldMappingValueTypes';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import { PropertyNameLabel } from '../common/PropertyNameLabel';


function InputInnerContent(valueType?: PropertyValueType, content?: string | null) {
    switch (valueType) {
        case PropertyValueType.Text:
        case PropertyValueType.TextArea:
            return (
                <Box className="input-inner">
                    {content?.replace('&nbsp;', ' ')}
                </Box>
            );
        case PropertyValueType.HTML:
            return (
                <Box className="input-inner">
                    {content?.replace(/<[^>]*>/gm, ' ')}
                </Box>
            );
    }
}

export function Editor({
    property,
    propertyUpdated,
    label,
    isPageEditor,
    populatedDataSources = [],
    siteProperties = [],
    placeholder,
    hasError,
    helperText,
    page
}: PropertyEditorProps) {
    const [propValue, setPropValue] = useState(property?.Value);
    const [renderedValue, setRenderedValue] = useState(property?.RenderedValue);
    const [isEditorMode, setIsEditorMode] = useState(false);
    const [showDataSwaps, setShowDataSwaps] = useState(false);
    const [selectedDataSwapNode, setSelectedDataSwapNode] = useState<Element | null>(null);
    const [hasUpdatedValue, setHasUpdatedValue] = useState<boolean>(false);

    if (!isPageEditor) {
        label = label ?? 'Content';
    }

    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);

    const editorRef = useRef<TinyMCEEditor | null>(null);

    const isTextProperty = property?.ValueTypeId === PropertyValueType.Text;
    const isHtmlProperty = property?.ValueTypeId === PropertyValueType.HTML;
    const isTextAreaProperty = property?.ValueTypeId === PropertyValueType.TextArea;

    const replaceDataSwapsWithTokens = (value: string) => {
        const getOutput = (dataSwap: HTMLElement) => dataSwap.getAttribute("data-token") || "";
        return swappedValue(value, getOutput);
    }

    const replaceDataSwapsWithRenderedText = (value: string) => {
        const getOutput = (dataSwap: HTMLElement) => {
            const inner = dataSwap.querySelector(".inner-data-swap");
            return inner ? inner.innerHTML : ""
        }
        return swappedValue(value, getOutput);
    };

    const swappedValue = (value: string, getOutput: (element: HTMLElement) => string) => {
        const element = document.createElement("span");
        element.innerHTML = value;
        const dataSwaps: HTMLElement[] = Array.from(element.querySelectorAll(".data-swap"));
        for (var i = 0; i < dataSwaps.length; i++) {
            dataSwaps[i].outerHTML = getOutput(dataSwaps[i])
        }
        return element.innerHTML;
    }

    const updateProperty = () => {
        if (property) {
            property.Value = propValue;
            property.RenderedValue = renderedValue;
            propertyUpdated(property);
        }
    };

    useEffect(() => {
        if (hasUpdatedValue) {
            updateProperty();
            setHasUpdatedValue(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderedValue]);

    useEffect(() => {
        setPropValue(property?.Value);
        setRenderedValue(property?.RenderedValue);
    }, [property]);

    //Note: updateValue is used in wizard mode when the TextField value is changed (!isPageEditor)
    const updateValue = (value: string) => {
        value = value.replace(/(\n)/gm, ''); // prohibit newlines by removing them

        const withTokens = replaceDataSwapsWithTokens(value);
        const withRenderedText = replaceDataSwapsWithRenderedText(value);
        setPropValue(withTokens);
        setRenderedValue(withRenderedText);

        setHasUpdatedValue(true);

        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => updateProperty, 300));
    };

    //Note: confirmEditor is used to save the value of the tinymce editor (isPageEditor)
    const confirmEditor = () => {
        let originalEditorContent: string = editorRef?.current?.getContent() ?? '';

        let propertyVal = '';
        let propertyRenderedVal = '';

        if (isTextProperty || isTextAreaProperty) {
            const withTokens = replaceDataSwapsWithTokens(originalEditorContent);
            const withRenderedText = replaceDataSwapsWithRenderedText(originalEditorContent);

            // Set the editor with data swaps replaced with tokens, and then retrieve as text
            editorRef?.current?.setContent(withTokens);
            propertyVal = editorRef?.current?.getContent({format: 'text'}) ?? '';

            // Set the editor with data swaps replaced with rendered text, and then retrieve as text
            editorRef?.current?.setContent(withRenderedText);
            propertyRenderedVal = editorRef?.current?.getContent({format: 'text'}) ?? '';
        } else {
            let editorContent = originalEditorContent.replace(/(^<p>)/gm, ''); //removes editor root block beginnings
            editorContent = editorContent.replace(/(<\/p>)$(\n)/gm, '<br>'); //replaces editor root block endings followed by newline with a <br>
            editorContent = editorContent.replace(/(<\/p>)$/gm, ''); //removes final editor root block ending
            editorContent = editorContent.replace(/(\n)/gm, ''); //removes any remaining newlines

            propertyVal = replaceDataSwapsWithTokens(editorContent);
            propertyRenderedVal = replaceDataSwapsWithRenderedText(editorContent);
        }

        setPropValue(propertyVal);
        setRenderedValue(propertyRenderedVal);

        setHasUpdatedValue(true);
        setIsEditorMode(false);
    };

    const exitEditorMode = () => {
        setIsEditorMode(false);
        setSelectedDataSwapNode(null);
    };

    const propValueForEditor = () => {
        if (!populatedDataSources) {
            return "";
        }
        let value = propValue || '';
        var reg = new RegExp(/\[(p|d):([a-f0-9-]+):?([^\]]*)\]/, 'gmi');
        value = (isTextProperty || isTextAreaProperty) ? new Option(value).innerHTML : value; // Escape HTML for text properties
        var pageProperties = page?.PageProperties || [];
        value = value.replace(reg, (m,s,t,k) => {return renderSwapElement(m, lookupTokenValue(populatedDataSources, siteProperties, pageProperties, s,t,k));});
        return value;
    };

    const renderSwapElement = (token:string, content:string) => {
        var dataSwap = document.createElement("span");
        dataSwap.setAttribute('data-token', token);
        dataSwap.classList.add('data-swap');
        dataSwap.classList.add('mceNonEditable')
        dataSwap.setAttribute('contenteditable', 'true');
        var inner = document.createElement("span");
        inner.setAttribute('data-token', token);
        inner.classList.add('inner-data-swap');
        inner.classList.add('mceNonEditable');
        const escapedContent = new Option(content).innerHTML;
        inner.innerHTML = `${escapedContent || "VALUE NOT SET"}`;
        dataSwap.appendChild(inner);
        return dataSwap.outerHTML;
    }

    const closeContextToolbar = () => {
        editorRef?.current?.selection.select(editorRef?.current?.getBody());
        editorRef?.current?.selection.collapse();
        editorFocus();
    }

    // For the tinymce Editor properties
    let editorInit = {};
    let editorId = '';

    const tinymceSetup = (editor: any) => {
        const textFormatCommands = [
            'mceToggleFormat',
            'FontSize',
            'Bold',
            'Italic',
            'Underline',
            'Strikethrough',
            'Superscript',
            'Subscript'
        ];
        let selectedBefore: HTMLElement;
        editor.on('BeforeExecCommand', (e: any) => {
            // Prevent format options for text fields
            if ((isTextProperty || isTextAreaProperty) && textFormatCommands.includes(e.command)) {
                return false;
            }

            const selectedNode = editor.selection.getNode();
            let content = editor.selection.getContent();

            const formats = [
                { value: "bold", search: '<strong>', tag: 'strong' },
                { value: "italic", search: '<em>', tag: 'em' },
                { value: "underline", search: 'text-decoration: underline;', tag: 'span' },
                { value: "strikethrough", search: '<s>', tag: 's' }
            ];

            // Fix issues with tinymce + data swaps by intercept the removal of bold/italic/strikethrough
            // and handling it ourselves
            for (let format of formats) {
                if (content.includes(format.search)
                    && ((e.command === 'mceToggleFormat' && e.value === format.value)
                        || (e.command === 'Bold' && format.value === 'bold')
                        || (e.command === 'Italic' && format.value === 'italic')
                        || (e.command === 'Strikethrough' && format.value === 'strikethrough')
                        )) {
                    let elements = selectedNode.getElementsByTagName(format.tag);
                    for (let element of elements) {
                        if (e.value !== 'underline' || element.attributes.style?.value === format.search) {
                            element.outerHTML = element.innerHTML;
                        }
                    }
                    return false;
                }
            }

            if (selectedNode?.classList.contains('inner-data-swap')) {
                editor.selection.select(selectedNode.parentElement)
            }

            selectedBefore = editor.selection.getNode();
            if (textFormatCommands.indexOf(e.command) !== -1) {
                const dataSwaps: HTMLElement[] = editor.getBody().getElementsByClassName('data-swap')
                for (var i = 0; i < dataSwaps.length; i++) {
                    dataSwaps[i].classList.remove('mceNonEditable');
                    dataSwaps[i].removeAttribute('contenteditable');
                }
            }
        })
        editor.on('ExecCommand', (e: any) => {
            if (textFormatCommands.indexOf(e.command) !== -1) {
                if (selectedBefore?.classList.contains('data-swap')) {
                    editor.selection.select(selectedBefore)
                }
            }
            if (e.command === "mceInsertContent") {
                var valueParent = document.createElement('span');
                valueParent.innerHTML = e.value;
                const value: HTMLElement = valueParent.children[0] as HTMLElement;
                if (value?.classList.contains('inner-data-swap')) {
                    const innerDataSwaps: HTMLElement[] = editor.getBody().querySelectorAll('.inner-data-swap')
                    for (var i = 0; i < innerDataSwaps.length; i++) {
                        const dataToken = innerDataSwaps[i].getAttribute('data-token') || "";
                        const copy: HTMLElement = innerDataSwaps[i].cloneNode(true) as HTMLElement;
                        var outer = document.createElement('span');
                        outer.appendChild(copy);
                        outer.setAttribute('data-token', dataToken);
                        outer.classList.add('data-swap');
                        innerDataSwaps[i].outerHTML = outer.outerHTML;
                    }
                }
            }
            const dataSwaps: HTMLElement[] = editor.getBody().getElementsByClassName('data-swap')
            for (var y = 0; y < dataSwaps.length; y++) {
                dataSwaps[y].classList.add('mceNonEditable');
                dataSwaps[y].setAttribute('contenteditable', 'false');
            }
        })
        if (isTextProperty || isTextAreaProperty) {
            editor.on('keydown', (e: any) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            });
        }
        editor.ui.registry.addIcon('data-swap',
            '<svg height="24" width="24"><path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3h7zM7 9H4V5h3v4zm10 6h3v4h-3v-4zm0-10h3v4h-3V5z"></path></svg>');
        editor.ui.registry.addButton('dataswap', {
            icon: 'data-swap',
            tooltip: 'Data Swap',
            onAction: () => {setShowDataSwaps(true);}
        });
        editor.ui.registry.addButton('editdataswap', {
            icon: 'edit-block',
            tooltip: 'Edit Swap',
            onAction: () => {
                setSelectedDataSwapNode(editor.selection.getNode());
                setShowDataSwaps(true);
                closeContextToolbar();
            }
        });
        editor.ui.registry.addButton('removedataswap', {
            icon: 'remove',
            tooltip: 'Remove Swap',
            onAction: () => {
                const nodeToDelete = editor.selection.getNode();
                editorRef?.current?.selection.setCursorLocation(nodeToDelete, 1);
                editorRef?.current?.dom.remove(nodeToDelete);
                var deleteElement = document.createElement("span");
                deleteElement.classList.add('data-deleteme');
                deleteElement.classList.add('mceNonEditable')
                editorRef?.current?.selection.setContent(deleteElement.outerHTML);
                setSelectedDataSwapNode(null);
                editorFocus();
            }
        });
        editor.ui.registry.addContextToolbar('dataswapcontext', {
            predicate: (node: Element) => { return node.classList.contains('data-swap') },
            items: 'editdataswap removedataswap',
            position: 'node',
            scope: 'node'
        });
    };
    const contentStyle = '.data-swap {text-decoration:underline;text-decoration-color:#1B1AFF;} .data-swap:hover {text-decoration:none;background-color:#1B1AFF1E;cursor:pointer;} ul, ol { list-style-position: inside; }';

    switch (property?.ValueTypeId) {
        case PropertyValueType.Text:
            editorInit = {
                height:106,
                menubar:false,
                plugins:[],
                toolbar:'dataswap | undo redo',
                statusbar:false,
                branding:false,
                setup: tinymceSetup,
                content_style: contentStyle,
                paste_as_text: true
            };
            editorId = 'tinyText';
            break;
        case PropertyValueType.TextArea:
            editorInit = {
                height:300,
                menubar:false,
                plugins:[],
                toolbar:'dataswap | undo redo',
                statusbar:false,
                branding:false,
                setup: tinymceSetup,
                content_style: contentStyle,
                paste_as_text: true
            };
            editorId = 'tinyText';
            break;
        case PropertyValueType.HTML:
            editorInit = {
                height:300,
                menubar:false,
                plugins:['lists', 'link'],
                toolbar:'fontsize | bold italic strikethrough underline | bullist numlist | alignleft aligncenter alignright alignjustify | link | dataswap | undo redo',
                statusbar:false,
                branding:false,
                setup: tinymceSetup,
                content_style: contentStyle
            };
            editorId = 'tinyHTML';
            break;
    }

    // Fix to be able to edit fields in link dialog box
    document.addEventListener('focusin', (e) => {
        if ((e.target as Element).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            e.stopImmediatePropagation();
        }
    });

    const editorFocus = () => {
        setTimeout(() => { editorRef?.current?.focus() });
    }

    const dataSwapSelected = (selectedSwapValue: any) => {
        if (selectedDataSwapNode) {
            editorRef?.current?.selection.setCursorLocation(selectedDataSwapNode, 1);
            editorRef?.current?.dom.remove(selectedDataSwapNode);
            editorRef?.current?.selection.setContent(renderSwapElement(selectedSwapValue.swapValue, selectedSwapValue.value));
            setSelectedDataSwapNode(null);
        }
        else {
            if (editorRef?.current?.selection.getRng().commonAncestorContainer.nodeName === 'BODY') {
                let pNode = editorRef?.current?.getContent();
                const insertVal = `$1${renderSwapElement(selectedSwapValue.swapValue, selectedSwapValue.value)}$2`;
                editorRef?.current?.setContent(pNode?.replace(/^(.+)(<\/p>)$/g, insertVal));
            } else {
                editorRef?.current?.insertContent(renderSwapElement(selectedSwapValue.swapValue, selectedSwapValue.value));
            }
        }

        editorFocus();
        closeValueSwapModal();
    };

    const closeValueSwapModal = () => {
        setShowDataSwaps(false);
        setSelectedDataSwapNode(null);
        closeContextToolbar();
    }

    const allowedPropertyValueTypeId = property?.ValueTypeId === PropertyValueType.HTML
        ? [PropertyValueType.HTML, PropertyValueType.Text, PropertyValueType.TextArea]
        : [PropertyValueType.Text, PropertyValueType.TextArea];

    return (
        <>
            {!isPageEditor && isTextProperty &&
                <TextField
                    data-testid='tf'
                    value={propValue ?? ''}
                    sx={{ marginTop: "0px" }}
                    onChange={(e) => updateValue(e.target.value)}
                    label={ isPageEditor ? undefined : label}
                    variant="outlined"
                    error={hasError}
                    helperText={helperText}
                    fullWidth />
            }
            {!isPageEditor &&
                (isTextAreaProperty || isHtmlProperty) &&
                <TextField
                    data-testid='ta'
                    value={propValue ?? ''}
                    onChange={(e) => updateValue(e.target.value)}
                    label={label}
                    multiline
                    minRows={3}
                    maxRows={3}
                    variant="outlined"
                    error={hasError}
                    helperText={helperText}
                    fullWidth />

            }
            {isPageEditor &&
                <>
                    <PropertyNameLabel
                        property={property}
                    ></PropertyNameLabel>
                    <Box
                        data-testid='pe-box'
                        className={clsx({
                            "outlined-input": true,
                            "input-option": isTextProperty,
                            "textarea-option": !isTextProperty,
                            "error": hasError
                        })}
                        color={"secondary"}
                        sx={{
                            opacity: !renderedValue && placeholder ? ".5" : "1"
                        }}
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsEditorMode(true);}}
                        >
                            {!renderedValue && placeholder}
                            {InputInnerContent(property?.ValueTypeId, renderedValue)}
                    </Box>
                    {hasError && <p data-testid="text-editor-error" className="text-editor-error">{helperText}</p>}
                    <Dialog
                        fullScreen={false}
                        open={isEditorMode}
                        onClose={exitEditorMode}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth='md'
                        fullWidth={true}
                        PaperProps={{
                            style: { borderRadius: '3.5px' }
                        }}
                            >
                        <DialogContent style={{padding:'16px'}}>
                            <TinyMceEditorReact
                                data-testid={editorId}
                                id={editorId}
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={propValueForEditor()}
                                init={editorInit}
                            />
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "space-between", padding:'16px' }}>
                            <Button onClick={exitEditorMode} variant="text">Cancel</Button>
                            <Button onClick={confirmEditor} variant="outlined" autoFocus>Confirm</Button>
                        </DialogActions>
                    </Dialog>
                    <DataSwapModal
                        populatedDataSources={populatedDataSources}
                        siteProperties={siteProperties}
                        pageProperties={page?.PageProperties ?? []}
                        showModal={showDataSwaps}
                        onClose={closeValueSwapModal}
                        dataSwapSelected={dataSwapSelected}
                        selectedToken={selectedDataSwapNode?.getAttribute('data-token')}
                        propertyValueTypeId={allowedPropertyValueTypeId}
                        allowedDataSourceValueTypes={[DataSourceFieldMappingValueTypes.Text]}
                    />
                </>
            }
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        return stripHtml(property?.RenderedValue).toLowerCase().includes(value);
    }
}