import { createContextStore, action, Action, thunk, Thunk, Computed, computed } from 'easy-peasy';
import PageInfo from '../../types/PageInfo';
import Site from '../../types/Site';
import Page from '../../types/Page';
import SidebarState from '../../types/enum/SidebarState';
import CategoryType from '../../types/CategoryType';
import BlockTemplate from '../../types/BlockTemplate';
import BlockMapping from '../../types/BlockMapping';
import PopulatedDataSource from '../../types/PopulatedDataSource';
import BlockMappingStatusId from '../../types/enum/BlockMappingStatusId';
import Property from '../../types/Property';
import { blockCanBeDroppedOnParent, findAndRemoveBlockMapping, getBlockMapping, insertBlockMapping } from '../../utils/blockHelpers';
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { ResponseError, callApi, httpMethods } from '../../utils/apiHelpers';
import UserSessionData from '../../types/UserSessionData';
import NormalizedPage from '../../types/NormalizedPage';
import { convertNormalizedPageToPage } from '../../utils/pageHelpers';
import SiteStatus from '../../types/enum/SiteStatus';
import HoverData from '../../types/HoverData';
import { organizeCategories } from '../../utils/categoryHelpers';
import PageStatus from '../../types/enum/PageStatus';

export interface SiteEditorStoreModel {
  siteId: string | null,
  pageId: string | null,
  propertyTab: number,
  pageList: PageInfo[],
  site: Site | null,
  page: Page | null,
  pageRefreshTimeout: NodeJS.Timeout | null,
  siteProperties: Property[],
  sidebarState: SidebarState,
  blockCategories: CategoryType[],
  blocksAndSections: BlockTemplate[],
  blocksFilter: string,
  hierarchyFilter: string,
  lastPropertyUpdateDate: Date | null,
  hierarchyFilterText: Computed<SiteEditorStoreModel, string>,
  hierarchyContextMenuOpen: boolean,
  hierarchyFilterHasResults: boolean,
  scrollToPropertyId: string | null,
  navBarButtonActive: "versions" | "publish" | null,
  shiftHeld: boolean,
  loading: boolean,
  movingBlock: boolean,
  insertingBlock: boolean,
  refreshingPage: boolean,
  refreshingPageList: boolean,
  isDragging: boolean,
  showCustomDragPreview: boolean,
  dragType: string | null,
  propertySearchCriteria: string,
  expandPropertyCategories: boolean,
  selectedBlocks: BlockMapping[],
  draggingHoverData: HoverData | null,
  populatedDataSources: PopulatedDataSource[],
  allSelectedBlocksDisabled: Computed<SiteEditorStoreModel, boolean>,
  hoverBlock: BlockMapping | null,
  errorMessage: string | null,
  errorResponse: ResponseError | null,
  insertedBlockId: string | null,
  propertyCategories: CategoryType[],
  getAccessTokenSilently: ((options?: GetTokenSilentlyOptions | undefined) => Promise<string>) | null,
  userSession: UserSessionData | null,
  updatePageRefreshTimeout: Action<SiteEditorStoreModel, NodeJS.Timeout | null>,
  updatePropertyTab: Action<SiteEditorStoreModel, number>,
  updatePageList: Action<SiteEditorStoreModel, PageInfo[]>,
  updatePage: Action<SiteEditorStoreModel, Page | null>,
  updateSidebarState: Action<SiteEditorStoreModel, SidebarState>,
  closeSidebars: Action<SiteEditorStoreModel>,
  updateBlockCategories: Action<SiteEditorStoreModel, CategoryType[]>,
  updateBlocksFilter: Action<SiteEditorStoreModel, string>,
  updateHierarchyFilter: Action<SiteEditorStoreModel, string>,
  scrollToProperty: Action<SiteEditorStoreModel, { propertyTab: number, propertyId: string }>,
  clearScrollToPropertyId: Action<SiteEditorStoreModel>,
  updateSite: Action<SiteEditorStoreModel, Site | null>,
  updateNavBarButtonActive: Action<SiteEditorStoreModel, "versions" | "publish" | null>,
  refreshSite: Thunk<SiteEditorStoreModel>,
  refreshPageList: Thunk<SiteEditorStoreModel>,
  refreshSiteProperties: Thunk<SiteEditorStoreModel>,
  refreshDataSources: Thunk<SiteEditorStoreModel>,
  refreshBlocksAndSections: Thunk<SiteEditorStoreModel>,
  refreshPage: Thunk<SiteEditorStoreModel, boolean | undefined>,
  updateShiftHeld: Action<SiteEditorStoreModel, boolean>,
  updateBlocksAndSections: Action<SiteEditorStoreModel, BlockTemplate[] | null>,
  updateSelectedBlocks: Action<SiteEditorStoreModel, BlockMapping[] | null>,
  updatePopulatedDataSources: Action<SiteEditorStoreModel, PopulatedDataSource[] | null>,
  updateSiteProperties: Action<SiteEditorStoreModel, Property[] | null>,
  updateHoverBlock: Action<SiteEditorStoreModel, BlockMapping | null>,
  blockSelected: Thunk<SiteEditorStoreModel, BlockMapping>,
  updateGetAccessTokenSilently: Action<SiteEditorStoreModel, (options?: GetTokenSilentlyOptions | undefined) => Promise<string>>,
  updateUserSession: Action<SiteEditorStoreModel, UserSessionData | null>,
  setSiteAndPageId: Thunk<SiteEditorStoreModel, { siteId: string, pageId: string}>,
  updateSiteId: Action<SiteEditorStoreModel, string | null>,
  updatePageId: Action<SiteEditorStoreModel, string | null>,
  updateLoading: Action<SiteEditorStoreModel, boolean>,
  updateRefreshingPage: Action<SiteEditorStoreModel, boolean>,
  updateRefreshingPageList: Action<SiteEditorStoreModel, boolean>,
  cloneBlock: Thunk<SiteEditorStoreModel, string>,
  deleteBlock: Thunk<SiteEditorStoreModel, string>,
  deleteSelectedBlocks: Thunk<SiteEditorStoreModel>,
  updateErrorMessage: Action<SiteEditorStoreModel, string | null>,
  updateErrorResponse: Action<SiteEditorStoreModel, ResponseError | null>,
  moveBlockMapping: Thunk<SiteEditorStoreModel, {
    blockMappingId: string,
    hoverBlockMappingId: string | null | undefined,
    insertInto: boolean | undefined,
    insertBefore: boolean | undefined,
    insertAfter: boolean | undefined,
    callApi: boolean
  }>,
  updateMovingBlock: Action<SiteEditorStoreModel, boolean>,
  insertNewBlockMapping: Thunk<SiteEditorStoreModel, {
    blockId: string | null,
    templateId: string | null,
    hoverBlockMappingId: string | null | undefined,
    insertInto: boolean | undefined,
    insertBefore: boolean | undefined,
    insertAfter: boolean | undefined,
    callApi: boolean,
    componentId: string | null
  }>,
  updateInsertingBlock: Action<SiteEditorStoreModel, boolean>,
  updateInsertedBlockId: Action<SiteEditorStoreModel, string | null>,
  toggleSelectedBlocksStatus: Thunk<SiteEditorStoreModel>,
  toggleBlockStatus: Thunk<SiteEditorStoreModel, { blockId: string, currentBlockStatus?: BlockMappingStatusId }>,
  convertBlockMappingToComponent: Thunk<SiteEditorStoreModel, { blockMappingId: string, name: string }>,
  unlinkBlockMappingFromComponent: Thunk<SiteEditorStoreModel, string>,
  updateIsDragging: Action<SiteEditorStoreModel, boolean>,
  updateShowCustomDragPreview: Action<SiteEditorStoreModel, boolean>,
  updateDragType: Action<SiteEditorStoreModel, string | null>,
  updateDraggingHoverData: Action<SiteEditorStoreModel, HoverData | null>,
  draggingOnHover: Thunk<SiteEditorStoreModel, HoverData>,
  draggingFinished: Thunk<SiteEditorStoreModel, HoverData | null>,
  draggingStarted: Thunk<SiteEditorStoreModel, { type: string, showCustomDragPreview?: boolean }>,
  setPageRefreshTimeout: Thunk<SiteEditorStoreModel, boolean>,
  updatePropertySearchCriteria: Action<SiteEditorStoreModel, string>,
  updateExpandPropertyCategories: Action<SiteEditorStoreModel, boolean>,
  loadInitialData: Thunk<SiteEditorStoreModel, { getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>, userSession: UserSessionData }>,
  updatePropertyCategories: Action<SiteEditorStoreModel, CategoryType[] | null>,
  updateHierarchyFilterHasResults: Action<SiteEditorStoreModel, boolean>,
  updateHierarchyContextMenuOpen: Action<SiteEditorStoreModel, boolean>,
  updateLastPropertyUpdateDate: Action<SiteEditorStoreModel>
}

export const siteEditorStoreDefaultModel: SiteEditorStoreModel = {
  siteId: null,
  pageId: null,
  propertyTab: 0,
  pageList: [],
  site: null,
  page: null,
  pageRefreshTimeout: null,
  siteProperties: [],
  sidebarState: SidebarState.Closed,
  blockCategories: [],
  blocksAndSections: [],
  blocksFilter:"",
  hierarchyFilter: "",
  lastPropertyUpdateDate: null,
  hierarchyFilterText: computed((state) => state.hierarchyFilter?.toLocaleLowerCase()),
  hierarchyFilterHasResults: true,
  hierarchyContextMenuOpen: false,
  draggingHoverData: null,
  scrollToPropertyId: null,
  navBarButtonActive: null,
  shiftHeld: false,
  selectedBlocks: [],
  populatedDataSources: [],
  allSelectedBlocksDisabled: computed((state) => state.selectedBlocks.findIndex(b => b.StatusTypeId === BlockMappingStatusId.Active) === -1),
  hoverBlock: null,
  getAccessTokenSilently: null,
  userSession: null,
  loading: false,
  movingBlock: false,
  insertingBlock: false,
  refreshingPage: false,
  refreshingPageList: false,
  isDragging: false,
  showCustomDragPreview: false,
  dragType: null,
  errorMessage: null,
  errorResponse: null,
  insertedBlockId: null,
  propertySearchCriteria: "",
  propertyCategories: [],
  expandPropertyCategories: false,
  updatePropertyTab: action((state, tab) => {
    state.propertyTab = tab;
  }),
  updatePageRefreshTimeout: action((state, timeout) => {
    state.pageRefreshTimeout = timeout;
  }),
  updateLastPropertyUpdateDate: action((state) => {
    state.lastPropertyUpdateDate = new Date();
  }),
  updatePage: action((state, page) => {
    state.page = page;

    if (page) {
      state.siteProperties = page.SiteProperties;

      const updatedSelectedBlocks: BlockMapping[] = [];
      for (const selectedBlock of state.selectedBlocks) {
        const foundBlockMapping = getBlockMapping(selectedBlock.Id, page.BlockMappings);

        if (foundBlockMapping) {
          updatedSelectedBlocks.push(foundBlockMapping);
        }
      }

      state.selectedBlocks = updatedSelectedBlocks;
    }
  }),
  updateSidebarState: action((state, sidebarState) => {
    state.sidebarState = sidebarState;
  }),
  closeSidebars: action((state) => {
    state.sidebarState = SidebarState.Closed;
  }),
  updateBlockCategories: action((state, blockCategories) => {
    state.blockCategories = blockCategories;
  }),
  updateBlocksFilter: action((state, filter) => {
    state.blocksFilter = filter;
  }),
  updateHierarchyFilter: action((state, filter) => {
    state.hierarchyFilter = filter;
    if (filter) {
      state.hierarchyFilterHasResults = false;
    } else {
      state.hierarchyFilterHasResults = true;
    }
  }),
  updateHierarchyFilterHasResults: action((state, hierarchyFilterHasResults) => {
    state.hierarchyFilterHasResults = hierarchyFilterHasResults;
  }),
  updateHierarchyContextMenuOpen: action((state, hierarchyContextMenuOpen) => {
    state.hierarchyContextMenuOpen = hierarchyContextMenuOpen;
  }),
  scrollToProperty: action((state, props) => {
    state.propertyTab = props.propertyTab;
    state.scrollToPropertyId = props.propertyId;
  }),
  clearScrollToPropertyId: action((state) => {
    state.scrollToPropertyId = null;
  }),
  updateSite: action((state, site) => {
    state.site = site;
  }),
  updateNavBarButtonActive: action((state, button) => {
    state.navBarButtonActive = button;
  }),
  updatePageList: action((state, pageList) => {
    pageList = pageList ?? [];
    state.pageList = pageList.filter(page => page.StatusTypeId !== PageStatus.Deleted);
  }),
  updateUserSession: action((state, userSession) => {
    state.userSession = userSession;
  }),
  updateInsertedBlockId: action((state, insertedBlockId) => {
    state.insertedBlockId = insertedBlockId;
  }),
  refreshPageList: thunk(async (actions, payload, { getState }) => {
    const { userSession, siteId, getAccessTokenSilently } = getState();

    try {
      actions.updateRefreshingPage(true);
      const pageList: PageInfo[] = await callApi(`/site/${siteId}/page/list`, httpMethods.get, userSession, getAccessTokenSilently);
      actions.updatePageList(pageList);
      actions.updateRefreshingPage(false);
      actions.refreshPage(true);
    } catch (error: any) {
      actions.updateRefreshingPage(false);
      actions.updateErrorMessage("Error retrieving page list");
      actions.updateErrorResponse(error);
    }
  }),
  refreshSite: thunk(async (actions, payload, { getState }) => {
    const { userSession, siteId, getAccessTokenSilently } = getState();

    let site: Site | null = null;

    try {
      site = await callApi(`/sites/${siteId}`, httpMethods.get, userSession, getAccessTokenSilently);
      actions.updateSite(site);

      actions.refreshBlocksAndSections();

      if (site?.StatusTypeId === SiteStatus.PendingProvisioning) {
        actions.refreshSiteProperties();
      }
    } catch (error: any) {
      actions.updateErrorMessage("Error retrieving site");
      actions.updateErrorResponse(error);
    }

    return site;
  }),
  refreshSiteProperties: thunk(async (actions, payload, { getState }) => {
    const { userSession, siteId, getAccessTokenSilently } = getState();

    if (siteId) {
      try {
        const siteProperties: Property[] = await callApi(`/properties/site/${siteId}/search`, httpMethods.post, userSession, getAccessTokenSilently, {"EnumIds":[]});
        // temporary fix to assign siteId to all properties, to be removed with ARR-2547
        siteProperties.forEach(p => p.SiteId = siteId);
        actions.updateSiteProperties(siteProperties);
      } catch (error: any) {
        actions.updateErrorMessage("Error retrieving site properties");
        actions.updateErrorResponse(error);
      }
    }
  }),
  refreshDataSources: thunk(async (actions, payload, { getState }) => {
    const { userSession, siteId, getAccessTokenSilently } = getState();

    if (siteId) {
      try {
        const dataSources: PopulatedDataSource[] = await callApi(`/datasources/site/${siteId}`, httpMethods.get, userSession, getAccessTokenSilently);
        actions.updatePopulatedDataSources(dataSources);
      } catch (error: any) {
        actions.updateErrorMessage("Error retrieving data sources");
        actions.updateErrorResponse(error);
      }
    }
  }),
  refreshBlocksAndSections: thunk(async (actions, payload, { getState }) => {
    const { userSession, site, getAccessTokenSilently } = getState();

    try {
      const blocksAndSections: BlockTemplate[] = await callApi('/template/blocks/search', httpMethods.post
        , userSession, getAccessTokenSilently
        , {
          CmsIds: site?.CmsIds,
          SiteId: site?.Id
        });

      actions.updateBlocksAndSections(blocksAndSections);
    } catch (error: any) {
      actions.updateErrorMessage("Error retrieving blocks and sections");
      actions.updateErrorResponse(error);
    }
  }),
  setPageRefreshTimeout: thunk((actions, showLoader, { getState }) => {
    const { pageRefreshTimeout } = getState();

    if (pageRefreshTimeout) {
      clearTimeout(pageRefreshTimeout);
    }

    const timeout = setTimeout(() => {
      actions.updatePageRefreshTimeout(null);
      actions.refreshPage(showLoader);
    }, 1000);
    actions.updatePageRefreshTimeout(timeout);
  }),
  refreshPage: thunk(async (actions, showLoader, { getState }) => {
    const { userSession, pageId, siteId, getAccessTokenSilently, refreshingPage, refreshingPageList, insertedBlockId, pageRefreshTimeout, site, pageList } = getState();

    if (pageRefreshTimeout || refreshingPage || refreshingPageList) {
      actions.setPageRefreshTimeout(!!showLoader);
      return;
    }

    if (!pageList.length) {
      actions.updatePage(null);
      actions.updateRefreshingPage(false);
      return;
    }

    let routeQuery = '';
    let routePageId = pageId ?? '';

    if (!routePageId) {
      if (pageList.find(page => page.Route === '/')) {
        routeQuery = '?route=/';
      } else {
        routePageId = pageList[0].Id;
      }
    } else if (!pageList.find(page => page.Id === routePageId)) {
      actions.updateErrorMessage('This page does not exist. Please choose a valid page.');
      actions.updateRefreshingPage(false);
      return;
    }

    if (siteId) {
      actions.updateRefreshingPage(true);
      const refreshDate = new Date();

      if (showLoader) {
        actions.updateLoading(true);
      }

      if (site && site?.StatusTypeId !== SiteStatus.PendingProvisioning)
      {
        try {
          const normalizedPage: NormalizedPage = await callApi(`/site/${siteId}/page/normalized/${routePageId}${routeQuery}`, httpMethods.get
            , userSession, getAccessTokenSilently);

          const page = convertNormalizedPageToPage(normalizedPage);

          const { lastPropertyUpdateDate } = getState();

          if (!lastPropertyUpdateDate || refreshDate > lastPropertyUpdateDate) {
            actions.updatePage(page);

            if (page && insertedBlockId) {
                const findNewSelectedBlock = (blockMappings: BlockMapping[]) => {
                    if (blockMappings) {
                        for (let blockMapping of blockMappings) {
                            if (blockMapping.Id === insertedBlockId) {
                                actions.updateInsertedBlockId(null);
                                actions.blockSelected(blockMapping);
                                break;
                            }
                            findNewSelectedBlock(blockMapping.BlockMappings || []);
                        }
                    }
                };
                findNewSelectedBlock(page.BlockMappings);
            }
          } else if (lastPropertyUpdateDate) {
            actions.setPageRefreshTimeout(!!showLoader);
          }

          actions.updateRefreshingPage(false);
        } catch (error: any) {
          if (typeof error?.body !== 'string' && error?.body.Errors && error?.body.Errors[0].Code === 'RecordDoesNotExist') {
            actions.updateErrorMessage('This page does not exist. Please choose a valid page.');
          } else {
            actions.updateErrorMessage('Error retrieving page');
          }
          actions.updateErrorResponse(error);
          actions.updateRefreshingPage(false);
        }
      }
      else if (site?.StatusTypeId === SiteStatus.PendingProvisioning) {
        actions.updateRefreshingPage(false);
        actions.refreshSiteProperties();
      } else {
        actions.updateRefreshingPage(false);
      }

      if (showLoader) {
        actions.updateLoading(false);
      }
    }
  }),
  updateShiftHeld: action((state, held) => {
    state.shiftHeld = held;
  }),
  updateBlocksAndSections: action((state, blocksAndSections) => {
    state.blocksAndSections = blocksAndSections ?? [];
  }),
  updatePopulatedDataSources: action((state, populatedDataSources) => {
    state.populatedDataSources = populatedDataSources ?? [];
  }),
  updateSiteProperties: action((state, siteProperties) => {
    state.siteProperties = siteProperties ?? [];
  }),
  updateSelectedBlocks: action((state, selectedBlocks) => {
    const updatedBlocks = selectedBlocks ?? [];
    state.selectedBlocks = updatedBlocks;

    if (updatedBlocks.length === 0 && state.propertyTab === 2) {
      state.propertyTab = 0;
    }
  }),
  updateHoverBlock: action((state, block) => {
    state.hoverBlock = block
  }),
  blockSelected: thunk((actions, block, { getState }) => {
    const { shiftHeld, selectedBlocks } = getState();

    if (selectedBlocks.length > 0 && shiftHeld) {
        const foundIndex = selectedBlocks.findIndex(b => b.Id === block.Id);

        if (foundIndex === -1) {
          actions.updateSelectedBlocks([...selectedBlocks, block]);
        }
        else {
          selectedBlocks.splice(foundIndex, 1);
          actions.updateSelectedBlocks([...selectedBlocks]);
        }
    }
    else {
        actions.updateSelectedBlocks([block]);
    }

    actions.updatePropertyTab(2);
  }),
  updateGetAccessTokenSilently: action((state, getAccessTokenSilently) => {
    state.getAccessTokenSilently = getAccessTokenSilently;
  }),
  setSiteAndPageId: thunk(async (actions, payload, { getState }) => {
    const { siteId, pageId, propertyTab } = getState();

    actions.updateSiteId(payload.siteId);
    actions.updatePageId(payload.pageId);

    if (payload.siteId && payload.siteId !== siteId) {
      await Promise.all([
        actions.refreshSite(),
        actions.refreshPageList(),
        actions.refreshDataSources()
      ]);
    }

    if (payload.pageId !== pageId && propertyTab === 2) {
      actions.updatePropertyTab(0);
    }

    actions.refreshPage(true);
  }),
  updatePageId: action((state, pageId) => {
    state.pageId = pageId;
  }),
  updateSiteId: action((state, siteId) => {
    state.siteId = siteId;
  }),
  updateLoading: action((state, loading) => {
    state.loading = loading;
  }),
  updateRefreshingPage: action((state, refreshing) => {
    state.refreshingPage = refreshing;
  }),
  updateRefreshingPageList: action((state, refreshing) => {
    state.refreshingPageList = refreshing;
  }),
  convertBlockMappingToComponent: thunk(async (actions, payload, { getState }) => {
    const { userSession, getAccessTokenSilently } = getState();

    actions.updateLoading(true);
    try {
      await callApi(`/blockmappings/${payload.blockMappingId}/converttocomponent`, httpMethods.post
        , userSession, getAccessTokenSilently, {
          Name: payload.name
        });
      actions.refreshPage(true);
      actions.refreshBlocksAndSections();
    } catch (error: any) {
      actions.updateErrorMessage("Error converting block to component. Please try again.");
      actions.updateErrorResponse(error);
      actions.updateLoading(false);
    }
  }),
  unlinkBlockMappingFromComponent: thunk(async (actions, blockMappingId, { getState }) => {
    const { userSession, getAccessTokenSilently } = getState();

    actions.updateLoading(true);
    try {
      await callApi(`/blockmappings/${blockMappingId}/unlink`, httpMethods.post
        , userSession, getAccessTokenSilently);
      actions.refreshPage(true);
      actions.refreshBlocksAndSections();
    } catch (error: any) {
      actions.updateErrorMessage("Error unlinking block from component. Please try again.");
      actions.updateErrorResponse(error);
      actions.updateLoading(false);
    }
  }),
  cloneBlock: thunk(async (actions, blockId, { getState }) => {
    const { userSession, getAccessTokenSilently } = getState();

    actions.updateLoading(true);
    try {
      await callApi(`/blockmappings/${blockId}/clone`, httpMethods.post
        , userSession, getAccessTokenSilently);
      actions.refreshPage(true);
    } catch (error: any) {
      actions.updateErrorMessage("Error cloning block. Please try again.");
      actions.updateErrorResponse(error);
      actions.updateLoading(false);
    }
  }),
  deleteBlock: thunk(async (actions, blockId, { getState }) => {
    const { userSession, getAccessTokenSilently, selectedBlocks } = getState();

    actions.updateLoading(true);

    try {
      await callApi(`/blockmappings/${blockId}`, httpMethods.delete
        , userSession, getAccessTokenSilently);

      if (selectedBlocks.length === 1 && blockId === selectedBlocks[0].Id) {
        actions.updateSelectedBlocks([]);
        actions.updatePropertyTab(0);
      }

      actions.refreshPage(true);
    } catch (error: any) {
      actions.updateErrorMessage("Error deleting block. Please try again.");
      actions.updateErrorResponse(error);
      actions.updateLoading(false);
    }
  }),
  deleteSelectedBlocks: thunk(async (actions, payload, { getState }) => {
    const { userSession, getAccessTokenSilently, selectedBlocks } = getState();

    actions.updateLoading(true);

    try {
      for (const selectedBlock of selectedBlocks) {
        await callApi(`/blockmappings/${selectedBlock.Id}`, httpMethods.delete
          , userSession, getAccessTokenSilently);
      }

      actions.updateSelectedBlocks([]);
      actions.updatePropertyTab(0);
    } catch (error: any) {
      actions.updateErrorMessage(selectedBlocks && selectedBlocks.length > 1
        ? 'Error deleting blocks. Please try again.'
        : 'Error deleting block. Please try again.');
      actions.updateErrorResponse(error);
    }

    actions.refreshPage(true);
  }),
  toggleSelectedBlocksStatus: thunk(async (actions, payload, { getState }) => {
    const { userSession, getAccessTokenSilently, selectedBlocks, allSelectedBlocksDisabled } = getState();

    actions.updateLoading(true);

    try {
      let enableDisableBlockIds: string[] = [];
      let status = BlockMappingStatusId.Active;

      if (allSelectedBlocksDisabled) {
          enableDisableBlockIds = selectedBlocks.map(b => b.Id);
      }
      else {
          const blocksToDisable = selectedBlocks.filter(b => b.StatusTypeId === BlockMappingStatusId.Active);
          enableDisableBlockIds = blocksToDisable.map(b => b.Id);
          status = BlockMappingStatusId.Inactive;
      }

      for (const id of enableDisableBlockIds) {
        await callApi(`/blockmappings/${id}/status/${status}`, httpMethods.put
          , userSession, getAccessTokenSilently);
      }
    } catch (error: any) {
      actions.updateErrorMessage(selectedBlocks && selectedBlocks.length > 1
        ? 'Error toggling block statuses. Please try again.'
        : 'Error toggling block status. Please try again.');
      actions.updateErrorResponse(error);
    }

    actions.refreshPage(true);
  }),
  toggleBlockStatus: thunk(async (actions, payload, { getState }) => {
    const { userSession, getAccessTokenSilently, } = getState();

    actions.updateLoading(true);

    try {
      let status = BlockMappingStatusId.Active;

      if (payload.currentBlockStatus === BlockMappingStatusId.Active) {
        status = BlockMappingStatusId.Inactive;
      }

      await callApi(`/blockmappings/${payload.blockId}/status/${status}`, httpMethods.put
        , userSession, getAccessTokenSilently);
    } catch (error: any) {
      actions.updateErrorMessage('Error toggling block status. Please try again.');
      actions.updateErrorResponse(error);
    }

    actions.refreshPage(true);
  }),
  updateErrorMessage: action((state, errorMessage) => {
    state.errorMessage = errorMessage;
  }),
  updateErrorResponse: action((state, errorResponse) => {
    state.errorResponse = errorResponse;
  }),
  updateMovingBlock: action((state, movingBlock) => {
    state.movingBlock = movingBlock;
  }),
  updateInsertingBlock: action((state, insertingBlock) => {
    state.insertingBlock = insertingBlock;
  }),
  updateIsDragging: action((state, isDragging) => {
    state.isDragging = isDragging;
  }),
  updateShowCustomDragPreview: action((state, showCustomDragPreview) => {
    state.showCustomDragPreview = showCustomDragPreview;
  }),
  updateDragType: action((state, dragType: string | null) => {
    state.dragType = dragType;
  }),
  moveBlockMapping: thunk(async (actions, payload, { getState }) => {
    const { page, movingBlock, userSession, getAccessTokenSilently } = getState();

    if (page && !movingBlock) {
        actions.updateMovingBlock(true);

        var blockMappingResult = findAndRemoveBlockMapping(payload.blockMappingId, page.BlockMappings, null);

        if (blockMappingResult) {
            let parentAndPosition = null;
            if (!payload.hoverBlockMappingId) {
                page.BlockMappings.push(blockMappingResult.blockMapping);
                parentAndPosition = {
                    ParentBlockMappingId: null,
                    BlockOrder: page.BlockMappings.length + 1
                }
            } else {
                parentAndPosition = insertBlockMapping(blockMappingResult.blockMapping, page.BlockMappings, payload.hoverBlockMappingId
                    , payload.insertInto, payload.insertBefore, payload.insertAfter, true, true);
            }

            if (parentAndPosition && payload.callApi) {
                let ParentBlockMappingId = parentAndPosition.ParentBlockMappingId;
                const BlockOrder = parentAndPosition.BlockOrder;

                // If the parent block is a linked block mapping, the move operation will be happening on the linked component's
                // block mapping, and not the linked block mapping itself
                if (parentAndPosition.ParentBlockMappingId && parentAndPosition.ParentBlockMapping?.ComponentBlockMappingId) {
                    ParentBlockMappingId = parentAndPosition.ParentBlockMapping.ComponentBlockMappingId;
                }

                try {
                  await callApi(`/blockmappings/${payload.blockMappingId}/move`, httpMethods.post
                    , userSession, getAccessTokenSilently
                    , {
                      ParentBlockMappingId,
                      BlockOrder
                    });

                  actions.refreshPage();
                  actions.updateMovingBlock(false);
                } catch (error: any) {
                  actions.updateMovingBlock(false);
                  actions.updateErrorMessage("Error moving block. Please try again.");
                  actions.updateErrorResponse(error);
                  // Move the block back to where it started
                  const props = {
                    blockMappingId: payload.blockMappingId,
                    hoverBlockMappingId: blockMappingResult?.hoverBlockMappingId,
                    insertInto: blockMappingResult?.insertInto,
                    insertBefore: blockMappingResult?.insertBefore,
                    insertAfter: blockMappingResult?.insertAfter,
                    callApi: false
                  };
                  actions.moveBlockMapping(props);
                }
            } else {
              actions.updateMovingBlock(false);
            }
        } else {
            actions.updateMovingBlock(false);
        }
    }
  }),
  insertNewBlockMapping: thunk(async (actions, payload, { getState }) => {
    const { page, site, insertingBlock, userSession, getAccessTokenSilently } = getState();

    if (page && site) {
        let parentAndPosition = null;
        if (!payload.hoverBlockMappingId) {
            parentAndPosition = {
                ParentBlockMappingId: null,
                BlockOrder: page.BlockMappings.length + 1
            }
        } else {
            parentAndPosition = insertBlockMapping(null, page.BlockMappings, payload.hoverBlockMappingId
                , payload.insertInto, payload.insertBefore, payload.insertAfter, true, false);
        }

        if (parentAndPosition && payload.callApi && !insertingBlock) {
            actions.updateInsertingBlock(true);

            let ParentBlockMappingId = parentAndPosition.ParentBlockMappingId;
            let PageId: string | null = page.PageId;

            // If the parent being dropped into is a linked component block mapping, add the child to the component's root
            // block mapping and not to the link block mapping
            if (parentAndPosition.ParentBlockMapping && parentAndPosition.ParentBlockMapping.ComponentBlockMappingId) {
                ParentBlockMappingId = parentAndPosition.ParentBlockMapping.ComponentBlockMappingId;
            }

            // If adding to a component, don't add page
            if (parentAndPosition.ParentBlockMapping && parentAndPosition.ParentBlockMapping.ComponentId) {
                PageId = null;
            }

            var requestData = {
                SiteId: site.Id,
                PageId,
                ParentBlockMappingId,
                BlockOrder: parentAndPosition.BlockOrder,
                BlockId: payload.blockId,
                TemplateId: payload.templateId,
                ComponentId: payload.componentId
            };

            try {
              const insertedBlockMappingIds = await callApi(`/blockmappings`, httpMethods.post
                , userSession, getAccessTokenSilently
                , requestData);

              actions.updateSelectedBlocks([]);
              actions.updateInsertedBlockId(insertedBlockMappingIds[0]);
              actions.refreshPage();
              if (payload.templateId) {
                actions.refreshBlocksAndSections();
              }
              actions.updateInsertingBlock(false);
            } catch (error: any) {
              actions.updateInsertingBlock(false);
              actions.updateErrorMessage("Error inserting block. Please try again.");
              actions.updateErrorResponse(error);
            }
        }
    }
  }),
  updateDraggingHoverData: action((state, hoverData) => {
    state.draggingHoverData = hoverData;
  }),
  draggingOnHover: thunk((actions, hoverData, { getState }) => {
    const { page, blocksAndSections } = getState();
    if (page && hoverData && hoverData.blockMappingId !== hoverData.hoveredId
        && blockCanBeDroppedOnParent(page, blocksAndSections, hoverData)) {
      actions.updateDraggingHoverData(hoverData);
    } else {
      actions.updateDraggingHoverData(null);
    }
  }),
  draggingFinished: thunk((actions, hoverData) => {
    if (hoverData && hoverData.blockMappingId) {
      actions.moveBlockMapping({
          blockMappingId: hoverData.blockMappingId,
          hoverBlockMappingId: hoverData.hoveredId,
          insertInto: hoverData.isInto,
          insertBefore: hoverData.isBefore,
          insertAfter: hoverData.isAfter,
          callApi: true
      });
    } else if (hoverData && (hoverData.blockId || hoverData.templateId || hoverData.componentId)) {
        actions.insertNewBlockMapping({
            blockId: hoverData.blockId,
            templateId: hoverData.templateId,
            hoverBlockMappingId: hoverData.hoveredId,
            insertInto: hoverData.isInto,
            insertBefore: hoverData.isBefore,
            insertAfter: hoverData.isAfter,
            callApi: true,
            componentId: hoverData.componentId
        });
    }
    actions.updateIsDragging(false);
    actions.updateDragType(null);
    actions.updateShowCustomDragPreview(false);
    actions.updateDraggingHoverData(null);
  }),
  draggingStarted: thunk((actions, payload) => {
    actions.updateIsDragging(true);
    actions.updateShowCustomDragPreview(!!payload.showCustomDragPreview);
    actions.updateDragType(payload.type);
  }),
  updatePropertySearchCriteria: action((state, propertySearchCriteria) => {
    state.propertySearchCriteria = propertySearchCriteria;
  }),
  updateExpandPropertyCategories: action((state, expandPropertyCategories) => {
    state.expandPropertyCategories = expandPropertyCategories;
  }),
  loadInitialData: thunk(async (actions, payload) => {

    actions.updateGetAccessTokenSilently(payload.getAccessTokenSilently);
    actions.updateUserSession(payload.userSession);

    try {
      const propertyCategoriesPromise = callApi('/type/700', httpMethods.get
        , payload.userSession, payload.getAccessTokenSilently);
      const blockCategoriesPromise = callApi('/type/200', httpMethods.get
        , payload.userSession, payload.getAccessTokenSilently);

      const propertyCategories = await propertyCategoriesPromise;
      const blockCategories = await blockCategoriesPromise;

      actions.updatePropertyCategories(propertyCategories);
      actions.updateBlockCategories(blockCategories);
    } catch (error: any) {
      actions.updateErrorMessage("Error loading initial editor state.");
      actions.updateErrorResponse(error);
    }
  }),
  updatePropertyCategories: action((state, propertyCategories) => {
    const categories = propertyCategories ?? [];
    state.propertyCategories = organizeCategories(categories);
  })
};

const SiteEditorStore = createContextStore<SiteEditorStoreModel>((runtimeModel) => (runtimeModel ?? siteEditorStoreDefaultModel));

export default SiteEditorStore;