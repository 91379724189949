import { CSSProperties } from 'react';
import SiteEditorStore from '../../SiteEditorStore';

export default function HierarchyDragStyle() {
  const draggingHoverData = SiteEditorStore.useStoreState((state) => state.draggingHoverData);

  let dragPreviewStyle: CSSProperties = {};

  if (draggingHoverData) {
      dragPreviewStyle = {
          position: 'absolute',
          pointerEvents: 'none',
          height: draggingHoverData.rect.height - 20,
          top: draggingHoverData.rect.top - 48, // minus navbar height
          left: draggingHoverData.rect.left - 75, // minus sidebar width
          width: draggingHoverData.rect.width + 22, // minus 20 padding, plus 42 right hand context menu ellipsis width
          zIndex: 99,
      };

      if (draggingHoverData.isAfter || draggingHoverData.isBefore) {
          dragPreviewStyle.height = "10px";
          dragPreviewStyle.width = draggingHoverData.rect.width + 42; // plus ellipsis
          dragPreviewStyle.backgroundColor = "blue";
          dragPreviewStyle.borderRadius = "25px";
      }

      if (draggingHoverData.isAfter) {
          dragPreviewStyle.top = draggingHoverData.rect.bottom - 10 - 48; // minus navbar height
      }

      if (draggingHoverData.isInto) {
          dragPreviewStyle.border = "10px solid blue";
      }
  }

  if (!draggingHoverData) {
    return null;
  }

  return (
    <div style={{
      position: "absolute",
      pointerEvents: "none",
      width: "426px",
      height: "100%",
      overflow: "hidden"
    }}>
      <span style={dragPreviewStyle}></span>
    </div>
  );

}