import BlockMapping from "../types/BlockMapping";
import BlockProps from "../types/BlockProps";
import Page from "../types/Page";
import { getPropertyValue } from "./blockHelpers";
import MisconfiguredBlock from "../components/block_rendering/MisconfiguredBlock";
import PropertyId from "../types/enum/PropertyId";

export function defaultBlock(props: BlockProps) {
    return <></>;
}

export function defaultHasContentToRender(block: BlockMapping, page: Page, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function defaultUsePlaceholder(block: BlockMapping, page: Page, childrenHaveContentToRender: boolean) {
    return !defaultHasContentToRender(block, page, childrenHaveContentToRender);
}

export function DefaultPlaceholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function DefaultHierarchyContent(block: BlockMapping): JSX.Element | null {
    const id = block && getPropertyValue(block.Properties, PropertyId.ID);
    
    if (id) {
        return <>#{id}</>;
    }

    const className = block && getPropertyValue(block.Properties, PropertyId.ClassName);

    if (className) {
        return <>.{className}</>;
    }

    return null;
}

export function defaultFilter(block: BlockMapping, filterText: string): boolean {
    const id = getPropertyValue(block.Properties, PropertyId.ID);

    return !!id && (id.toLowerCase().includes(filterText));
}