import { useState } from "react";
import {TextField } from '@mui/material';
import BuilderDialog from "../../../../common/BuilderDialog";
import FormValidationError from "../../../../common/FormValidationError";

import TemplateTypes from "../../../../../types/enum/TemplateTypes";
import { SwapTemplateSteps } from "./SwapTemplate";
import ErrorMessage from "../../../../common/ErrorMessage";

interface SwapTemplateStepOneModalProps {
    showModal: boolean,
    setCurrentStep: (step: SwapTemplateSteps) => void,
    templateType: TemplateTypes,
    versionName: string,
    setVersionName: (name: string) => void,
    cancel: () => void
}

export default function SwapTemplateStepOneModal({
    showModal,
    setCurrentStep,
    templateType,
    versionName,
    setVersionName,
    cancel
}: SwapTemplateStepOneModalProps){

    const [versionNameValid, setVersionNameValid] = useState<boolean | null>(true);

    const getTitle = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Swap Site Template";
            case TemplateTypes.Page:
                return "Swap Page Template";
            default:
                return "";
        }
    }

    const getMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Are you sure you wish to replace the current site template with a new site template? This action may result in the loss of all blocks that were added after the initial template was selected. Only pages that are common to both templates will be affected.";
            case TemplateTypes.Page:
                return "Are you sure you wish to replace the current page template with a new page template? This action may result in the loss of all blocks that were added after the initial template was selected.";
            default:
                return "";
        }
    }

    const updateVersionName = (name: string) => {
        setVersionName(name);
        setVersionNameValid(!!name);
    }

    const nextStep = () => {
        setCurrentStep(SwapTemplateSteps.StepTwo);
    }

    const cancelClicked = () => {
        setVersionNameValid(true);
        cancel();
    };

    return (
        <BuilderDialog
            title={getTitle()}
            message={getMessage()}
            action={nextStep}
            actionDisabled={!versionNameValid}
            actionName="Select New Template"
            actionTestId="select-new-template-confirm"
            cancelTestId="select-new-template-cancel"
            actionButtonType="contained"
            open={showModal}
            onClose={cancelClicked}
            showCancel={true}
        >

            <div style={{ marginTop: "32px", marginBottom: "32px" }}>
                <label htmlFor="version-name">Save a version of your current site</label>
                <TextField
                    id="version-name"
                    value={versionName}
                    placeholder={"Version name"}
                    onChange={(e) => updateVersionName(e.target.value) }
                    label=""
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "8px" }}
                    inputProps={{ maxLength: 200 }}
                    />
                <FormValidationError
                    valid={(versionNameValid === null) || versionNameValid}
                    message={"Version name is required."} />
            </div>
            <ErrorMessage
                message="Now hold on! Please correct the following errors."
                dismissable={true}
                visible={!versionNameValid}
            />

        </BuilderDialog>

    )
}