const enum FileType {
    Image = 1301,
    Video = 1302,
    Document = 1303,
    Archive = 1304,
    Audio = 1305,
    Code = 1306,
    Font = 1307,
    Unknown = 1308
}

export default FileType;