import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore, propertyHasValue } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({page, block, children, builderProps, blockWrapperData}: BlockProps) {
    const content = getPropertyValue(block.Properties, PropertyId.Content);
    let level = getPropertyValue(block.Properties, PropertyId.HeadingLevel);
    level = level ? level : "1";
    

    return (
        <>
            {level === "1" && 
            <h1 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {isChildrenPositionBefore(block) && children}
                {content}
                {!isChildrenPositionBefore(block) && children}
            </h1>
            }
            {level === "2" && 
            <h2 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {isChildrenPositionBefore(block) && children}
                {content}
                {!isChildrenPositionBefore(block) && children}
            </h2>
            }
            {level === "3" && 
            <h3 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {isChildrenPositionBefore(block) && children}
                {content}
                {!isChildrenPositionBefore(block) && children}
            </h3>
            }
            {level === "4" && 
            <h4 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {isChildrenPositionBefore(block) && children}
                {content}
                {!isChildrenPositionBefore(block) && children}
            </h4>
            }
            {level === "5" && 
            <h5 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {isChildrenPositionBefore(block) && children}
                {content}
                {!isChildrenPositionBefore(block) && children}
            </h5>
            }
            {level === "6" && 
            <h6 {...getDynamicAttributes({block, builderProps, blockWrapperData})}>
                {isChildrenPositionBefore(block) && children}
                {content}
                {!isChildrenPositionBefore(block) && children}
            </h6>
            }
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return propertyHasValue(block.Properties, PropertyId.Content)
        || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const content = getPropertyValue(block.Properties, PropertyId.Content);
    
    return content ? <>{content}</> : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const content = getPropertyValue(block.Properties, PropertyId.Content);

    return (!!content && (content.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}