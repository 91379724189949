import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, getPropertyBooleanValue } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';
import SwiperCore from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Review from '../../../types/Review';
import { areArrayValuesEquivalent, uniqueArrayValues } from '../../../utils/arrayHelpers';
import { generateRgbaValue } from '../../../utils/colorHelpers';
import { formatTimeSinceDate } from '../../../utils/dateTimeHelpers';
import StarRating from '../StarRating';
import RecommendedSvg from '../RecommendedSvg';
import NotRecommendedSvg from '../NotRecommendedSvg';

export function Block({block, builderProps, page, blockWrapperData}: BlockProps) {
    const locationIdsValue = getPropertyValue(block.Properties, PropertyId.ReviewsLocationIds);
    const locationIds = locationIdsValue ? uniqueArrayValues(JSON.parse(locationIdsValue)) : null;
    const reviewSummary = builderProps?.reviewSummaries?.find(r => r.l && areArrayValuesEquivalent(r.l, locationIds));
    const minimumRating = 4;
    const reviewShowcaseDisplayTypeScroll = getPropertyValue(block.Properties, PropertyId.ReviewShowcaseLayout);
    const disableReadMore = getPropertyBooleanValue(block.Properties, PropertyId.ReviewsDisableReadMore);
    const readMoreLinkColorProperty = JSON.parse(getPropertyValue(block.Properties, PropertyId.ReviewsReadMoreLinkColor) || '{}');
    const starColorProperty = JSON.parse(getPropertyValue(block.Properties, PropertyId.ReviewsStarColor) || '{}');
    const cellBorderColorProperty = JSON.parse(getPropertyValue(block.Properties, PropertyId.ReviewsShowcaseCellBorderColor) || '{}');
    const cellBackgroundColorProperty = JSON.parse(getPropertyValue(block.Properties, PropertyId.ReviewsShowcaseCellBackgroundColor) || '{}');
    const readMoreLinkStyles: React.CSSProperties = {
        color: generateRgbaValue(readMoreLinkColorProperty)
    };
    const starStyles: React.CSSProperties = {
        color: generateRgbaValue(starColorProperty)
    };
    const cellStyles: React.CSSProperties = {
        borderColor: generateRgbaValue(cellBorderColorProperty),
        backgroundColor: generateRgbaValue(cellBackgroundColorProperty),
    };
    SwiperCore.use([Autoplay, Navigation]);

    if (!reviewSummary || !reviewSummary.r) {
        return <></>;
    }

    const filteredReviews = reviewSummary.r
        .filter(r => (r.r && r.r >= minimumRating) || r.rc)
        .slice(0, 6);
    const swiperEnabled = filteredReviews.length > 1;

    const reviewHeading = (review: Review) => {
        if (review.s) {
            return (
                <div className="flex">
                    {reviewSource(review)}
                    <div className="flex flex-col justify-center">
                        <div className="fp review-author">{review.n}</div>
                        <div className="fs review-date">
                            {formatTimeSinceDate(review.d)}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const reviewSource = (review: Review) => {
        if (review.s) {
            return (
                <div className="shrink-0 mr-4">
                    {review.siu &&
                        <a href={review.u || undefined} rel="noreferrer" target="_blank">
                            <img className="review-source-icon" style={{height: "50px", width: "50px"}} src={review.siu || undefined} alt={`${review.sdn} icon`} />
                        </a>
                    }
                </div>
            );
        }
    };

    const reviewContent = ({ c }: Review) => {
        const content = c && c.length > 230 ? c.substring(0, c.indexOf(' ', 227)) + "..." : c;

        return content ? <div className="fs review-content">{content}</div> : <></>;
    };

    return (
        reviewShowcaseDisplayTypeScroll ? (
            <div {...getDynamicAttributes({block, builderProps, className: "review-showcase-scroll flex items-center", blockWrapperData})}>
                <Swiper
                    loop={true}
                    enabled={swiperEnabled}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }}
                    className={`swiper`}
                >
                    {filteredReviews.map((review: Review, index: number) => {
                        return (
                            <SwiperSlide key={index} className='p-5'>
                                {reviewHeading(review)}
                                <div className="review-showcase-scroll-review-content text-center mt-4 md:mb-4 md:mt-8">
                                    {reviewContent(review)}
                                </div>
                                {review.r && review.r > 0 ?
                                    <div className="review-stars-container my-4 flex" style={starStyles}>
                                        <StarRating rating={review.r} />
                                    </div>
                                    :
                                    <div className="my-3" style={starStyles}>
                                        {review.rc != null && review.rc ? <RecommendedSvg /> : <NotRecommendedSvg />}
                                    </div>
                                }
                                {!disableReadMore &&
                                    <label className="mt-4 mx-auto reviews-read-more" style={readMoreLinkStyles}>
                                        Read More Reviews
                                        <span className="material-icons ml-1" title="Arrow Forward" aria-hidden="true">arrow_forward</span>
                                    </label>
                                }
                            </SwiperSlide>
                        );
                    })}
                    {swiperEnabled &&
                        <>
                            <div className="swiper-button-prev review-showcase-swipe-button prev"></div>
                            <div className="swiper-button-next review-showcase-swipe-button next"></div>
                        </>
                    }
                </Swiper>
            </div>
        ) : (
            <div {...getDynamicAttributes({block, builderProps, className: "review-showcase my-16 lg:p-16 md:p-8 p-4 bg-base-200 text-black", blockWrapperData})}>
                <div className="review-showcase-grid grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-14 mb-8 md:mb-10 max-w-screen-2xl">
                    {filteredReviews.map((review: Review, index: number) => {
                        return (
                            <div
                                key={index}
                                data-testid={`review-showcase-review-${index + 1}`}
                                style={cellStyles}
                                className={`review-showcase-cell p-4${(index >= 3 ? " hidden md:block" : "")}`}
                            >
                                {reviewHeading(review)}
                                {review.r && review.r > 0 ?
                                    <div className="review-stars-container my-4 flex" style={starStyles}>
                                        <StarRating rating={review.r} />
                                    </div>
                                    :
                                    <div className="my-3" style={starStyles}>
                                        {review.rc ? <RecommendedSvg /> : <NotRecommendedSvg />}
                                    </div>
                                }
                                <div className="mt-2">
                                    {reviewContent(review)}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="inline-block w-full">
                {!disableReadMore &&
                    <label className="float-right primary-bg-ascolor mt-2 reviews-read-more" style={readMoreLinkStyles}>
                        Read More Reviews
                        <span className="material-icons ml-1" title="Arrow Forward" aria-hidden="true">arrow_forward</span>
                    </label>
                }
                </div>
            </div>
        )
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    const locationIdsValue = getPropertyValue(block.Properties, PropertyId.ReviewsLocationIds);
    const locationIds = locationIdsValue ? JSON.parse(locationIdsValue) : null;

    return locationIds?.length > 0;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}