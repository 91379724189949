import { Box, IconButton, InputAdornment, OutlinedInput, TextField, Popover
    , useMediaQuery} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { hexToHslString, invertColor } from "../../utils/colorHelpers";
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import PropertyId from '../../types/enum/PropertyId';
import ColorPicker from './ColorPicker';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Property from "../../types/Property";
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import { PropertyNameLabel } from "../common/PropertyNameLabel";
const tinycolor = require("tinycolor2");

export function Editor({property, propertyUpdated, text, helpText, isPageEditor, siteProperties}
    : PropertyEditorProps) {

    const getColorThemeObject = (colorEnumId: PropertyId, focusEnumId: PropertyId, contentEnumId: PropertyId) => {

        const colorProperty = siteProperties?.find(p => p.EnumId === colorEnumId);
        const focusProperty = siteProperties?.find(p => p.EnumId === focusEnumId);
        const contentProperty = siteProperties?.find(p => p.EnumId === contentEnumId);
        const colorValues = JSON.parse(colorProperty?.RenderedValue || '{"hex": "#000000", "a": 1}');
        const focusValues = JSON.parse(focusProperty?.RenderedValue || '{"hex": "#000000", "a": 1}');
        const contentValues = JSON.parse(contentProperty?.RenderedValue || '{"hex": "#000000", "a": 1}');

        return {
            color: {
                hex: colorValues.hex,
                alpha: colorValues.a,
                property: colorProperty
            },
            focus: {
                hex: focusValues.hex,
                alpha: focusValues.a,
                property: focusProperty
            },
            content: {
                hex: contentValues.hex,
                alpha: contentValues.a,
                property: contentProperty
            } 
        };
    };

    const config = property?.Configuration ? JSON.parse(property.Configuration) : {};

    const colorTheme = config.ShowThemes ? {
        primary: getColorThemeObject(PropertyId.PrimaryColor, PropertyId.PrimaryFocus, PropertyId.PrimaryContent),
        secondary: getColorThemeObject(PropertyId.SecondaryColor, PropertyId.SecondaryFocus, PropertyId.SecondaryContent),
        accent: getColorThemeObject(PropertyId.AccentColor, PropertyId.AccentFocus, PropertyId.AccentContent),
    } : null;

    const color = property?.RenderedValue
        ? JSON.parse(property.RenderedValue)
        : null;
    const colorHex = color?.hex;
    const tinyColorObj = color ? tinycolor(colorHex) : null;
    const rgbValues = tinyColorObj?.toRgb();
    const alpha = color?.a ?? 1;

    let hslObj = null;

    if (color?.hsl) {
        const hslParts = color.hsl.split(' ');
        hslObj = {
            h: parseInt(hslParts[0]),
            s: parseInt(hslParts[1].replace('%', '')) / 100,
            l: parseInt(hslParts[2].replace('%', '')) / 100
        };
    }

    const [inputColor, setInputColor] = useState(colorHex?.toUpperCase());
    const [currentColor, setCurrentColor] = useState(colorHex);

    const linkedToThemeProperty = property?.Value?.startsWith('[P:');
    let themeName: string | undefined = '';

    if (linkedToThemeProperty) {
        const linkedPropertyId = property?.Value?.replace('[P:', '').replace(']', '');
        const linkedProperty = siteProperties?.find(p => p.PropertyId === linkedPropertyId);
        themeName = linkedProperty?.Name;
    }

    const invert = colorHex ? invertColor(colorHex) : null;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [colorSelectorOpen, setColorSelectorOpen] = useState(false);

    // Refresh state colors when property changes
    useEffect(() => {
        const color = property?.RenderedValue
            ? JSON.parse(property.RenderedValue)
            : null;
        const colorHex = color?.hex;
        setInputColor(colorHex?.toUpperCase());
        setCurrentColor(colorHex);
    }, [property])

    const inputEl = useRef<HTMLInputElement>(null);
    const colorPickerInput = useRef<HTMLInputElement>(null);

    const updateTheProperty = (hexCode: string, hslString: string, typeValue: string, alphaValue: number) => {
        if (property) {
            let color = JSON.stringify({
                hex: hexCode,
                hsl: hslString,
                t: typeValue,
                a: alphaValue
            });
            
            setInputColor(hexCode?.toUpperCase());
            property.RenderedValue = color;
            property.Value = color;
            propertyUpdated(property);
        }
    };

    // Use this for old wizard picker
    useEffect(() => {
        let timeOutId: NodeJS.Timer;
        if (currentColor && !isPageEditor) {
            timeOutId = setTimeout(() => {
                const hslString = hexToHslString(currentColor);
                updateTheProperty(currentColor, hslString, 'hex', 1);
            }, 250);
        }
        return () => timeOutId && clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentColor]);

    const deleteColor = () => {
        if (property) {
            property.RenderedValue = null;
            property.Value = null;
            setInputColor(null);
            setCurrentColor(null);
            propertyUpdated(property);
        }
    };

    const addColor = () => {
        if (property) {
            const defaultColor = '{"hex":"#FFFFFF","hsl":"0 0% 100%","t":"hex","a":1}';
            property.RenderedValue = defaultColor;
            property.Value = defaultColor;
            setInputColor("#FFFFFF");
            setCurrentColor("#FFFFFF");
            propertyUpdated(property);
            setColorSelectorOpen(true);
        }
    };

    const themeSelected = (themeProperty: Property) => {
        if (property) {
            setColorSelectorOpen(false);
            const themeColor = JSON.parse(themeProperty.RenderedValue || '');
            property.RenderedValue = themeProperty.RenderedValue;
            property.Value = `[P:${themeProperty.PropertyId}]`;
            setInputColor(themeColor.hex.toUpperCase());
            setCurrentColor(themeColor.hex);
            propertyUpdated(property);
        }
    };

    const editColor = () => {
        if (inputEl.current)
            inputEl.current.click();
    };

    const colorChangedEvent = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLTextAreaElement;
        setInputColor(target.value.toUpperCase());
        setCurrentColor(target.value);
    };

    const inputColorChanged = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLTextAreaElement;
        let value = target.value?.toUpperCase();
        setInputColor(value);

        let valid = /^#?([0-9A-F]{3}){1,2}$/i.test(value);
        if (valid) {
            if (!value.startsWith('#')) {
                value = '#' + value;
            }

            if (value.length === 4) {
                value = value[0] + value[1] + value[1] + value[2] + value[2] + value[3] + value[3];
            }

            setCurrentColor(value);
        }
    };
    const resetInputColor = () => {
        setInputColor(currentColor?.toUpperCase());
    };

    if (!isPageEditor) {
        return (
            <>
            { isMobile &&
                <Box sx={{position: "relative", maxWidth: "581px", marginBottom: "8px" }}>
                    <span className="caption">{helpText}</span>
                    <br/>
                    <span className="body1">{text}</span>
                </Box>
            }
            <Box sx={{position: "relative", maxWidth: "581px", marginBottom: "32px", display: "flex", gap: isMobile ? "16px" : "32px" }}>
                <Box sx={{ flex: "0 0 50px", position: "relative", display: "flex", alignItems: "center" }} className="color-input-container">
                    <input
                        type="color"
                        value={currentColor}
                        ref={inputEl}
                        onChange={colorChangedEvent}
                        style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "2px",
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: "0px",
                            cursor: "pointer"
                        }}
                    />
                    <PaletteOutlinedIcon
                        onClick={editColor}
                        sx={{
                            position: "absolute",
                            height: "20px",
                            width: "20px",
                            top: "18px",
                            left: "15px",
                            display: "none",
                            color: invert,
                            cursor: "pointer"
                        }}
                    />
                </Box>
                {!isMobile &&
                    <Box sx={{ flex: "auto", display: "flex", alignItems: "center" }}>
                        <Box>
                            <span className="caption">{helpText}</span>
                            <br/>
                            <span className="body1">{text}</span>
                        </Box>
                    </Box>
                }
                <Box sx={{ flex: !isMobile ? "0 0 200px" : "auto" }}>
                    <OutlinedInput
                        type="text"
                        sx={{ width: !isMobile ? "200px" : "100%" }}
                        value={inputColor}
                        onChange={inputColorChanged}
                        onBlur={resetInputColor}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                color="primary"
                                onClick={editColor}
                                edge="end"
                            >
                                <PaletteOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </Box>
            </Box>
            </>
        );
    } else {
        return (
            <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PropertyNameLabel 
                    property={property}
                ></PropertyNameLabel>
                { !colorHex &&
                    <Box>
                        <AddCircleOutlineIcon sx={{
                                cursor: "pointer",
                                fontSize: "26.6px",
                                marginLeft: "16px",
                                float: "right"
                            }}
                            color="secondary"
                            onClick={addColor}
                        />
                    </Box>
                }
            </Box>
            { colorHex &&
                <>
                <Box sx={{ display: "flex", marginTop: '8px', alignItems: 'center' }}>
                    <Box sx={{ 
                            height: '56px',
                            width: '56px',
                            minWidth: '56px',
                            minHeight: '56px',
                            position: "relative", 
                            display: "flex", 
                            alignItems: "center",
                            backgroundColor: `rgba(${rgbValues?.r}, ${rgbValues?.g}, ${rgbValues?.b}, ${alpha})`,
                            border: "1px solid #292929",
                            borderRadius: '3.5px',
                            cursor: 'pointer'
                        }} 
                        onClick={(e: any) => {
                            setColorSelectorOpen(true);
                        }}
                    >
                        <PaletteOutlinedIcon
                            onClick={editColor}
                            sx={{
                                position: "absolute",
                                height: "20px",
                                width: "20px",
                                top: "18px",
                                left: "15px",
                                display: "none",
                                color: invert,
                                cursor: "pointer"
                            }}
                        />
                    </Box>
                    <Box sx={{ width: "100%", marginLeft: '8px' }}>
                        <TextField
                            type="text"
                            value={linkedToThemeProperty ? themeName : inputColor}
                            ref={colorPickerInput}
                            fullWidth
                            onClick={(e: any) => {
                                setColorSelectorOpen(true);
                            }}
                            InputProps={{
                                readOnly: true,
                                sx: { cursor: 'pointer' },
                                endAdornment: linkedToThemeProperty ? null : (
                                <InputAdornment position="end">
                                    {alpha * 100}%
                                </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    { config.AllowDelete &&
                        <DeleteOutlineIcon sx={{
                                cursor: "pointer",
                                color: "#CF1F2E",
                                fontSize: "25px",
                                marginLeft: "16px"
                            }} 
                            onClick={deleteColor}
                        />
                    }
                </Box>
                <Popover
                  id={'color-popover'}
                  open={colorSelectorOpen}
                  anchorEl={colorPickerInput.current}
                  onClose={() => setColorSelectorOpen(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                    <Box sx={{ maxHeight: "660px", maxWidth: "645px", padding: '16px' }}>
                        <Box className="headline6" sx={{ textAlign: 'center', userSelect: 'none' }}>Color Picker</Box>
                        {colorSelectorOpen && 
                            <ColorPicker
                                hexCode={colorHex}
                                hslObj={hslObj}
                                alpha={alpha}
                                colorTheme={colorTheme}
                                themeSelected={themeSelected}
                                type={color?.t ? color.t : 'hex'}
                                colorChanged={(updatedColor: any, updatedEditorType: string, updatedAlpha: number) => {
                                    updateTheProperty('#' + updatedColor.hex
                                        , `${Math.round(updatedColor.hsl.h)} ${Math.round(updatedColor.hsl.s * 100)}% ${Math.round(updatedColor.hsl.l * 100)}%`
                                        , updatedEditorType
                                        , updatedAlpha);
                                  }
                                }
                              />
                          }
                      </Box>
                </Popover>
                </>
            }
            </>
        );
    }
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const obj = JSON.parse(property.RenderedValue);
        return obj.hex?.toLowerCase().includes(value);
    }
}