import { CSSProperties, useState } from 'react';
import { Button, Switch } from "@mui/material";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getAnchor, getFontAwesomeIcon } from '../../utils/blockTemplates';
import PropertyId from '../../types/enum/PropertyId';

import NavigationItem from './NavigationItem';
import AddOrEditDialog from './AddOrEditDialog';
import Property from '../../types/Property';
import NavigationItemValue from '../../types/NavigationItemValue';

import { containsValue as anchorContainsValue } from './AnchorConfigProperty';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import BuilderDialog from '../common/BuilderDialog';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { GLOBAL_NAVIGATION_PROPERTY_ID } from '../../utils/globalNavHelpers';
import DragDropData from '../../types/DragDropData';
import HoverData from '../../types/HoverData';
import { BaseProperty } from '../../utils/propertyHelpers';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const globalNavSwapToken = `[P:${GLOBAL_NAVIGATION_PROPERTY_ID}]`;

export function Editor({property, propertyUpdated, page, pageList, navigateToProperty, siteProperties}: PropertyEditorProps) {
    const [parentIndex, setParentIndex] = useState<number | null>(null);
    const [childIndex, setChildIndex] = useState<number | null>(null);

    const [addOrEditDialogOpen, setAddOrEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const iconProp = getFontAwesomeIcon().Properties?.find(p => p.EnumId === PropertyId.FontAwesomeIconClass)!;
    const anchorProp = getAnchor().Properties?.find(p => p.EnumId === PropertyId.AnchorConfig)!;

    const [iconProperty, setIconProperty] = useState<Property>(iconProp);
    const [labelValue, setLabelValue] = useState<string | undefined>('');
    const [appearanceValue, setAppearanceValue] = useState('l');
    const [anchorProperty, setAnchorProperty] = useState<Property>(anchorProp);
    const [behaviorValue, setBehaviorValue] = useState('');

    let navItems: Array<NavigationItemValue> = [];

    const syncedToGlobalNav = property?.Value === globalNavSwapToken;

    if (!syncedToGlobalNav) {
        navItems = JSON.parse(property?.Value || "[]");
    }

    const isGlobalNavigation = property?.PropertyId === GLOBAL_NAVIGATION_PROPERTY_ID;

    const [isDragging, setIsDragging] = useState(false);
    const [draggingHoverData, setDraggingHoverData] = useState<HoverData | null>(null);

    const toggleSync = () => {
        var siteProperty = siteProperties?.find(p => p.PropertyId === GLOBAL_NAVIGATION_PROPERTY_ID);

        if (property) {
            if (syncedToGlobalNav) {
                property.Value = siteProperty?.Value ?? null;
                property.RenderedValue = siteProperty?.RenderedValue ?? null;
            }
            else {
                property.Value = globalNavSwapToken;
                property.RenderedValue = siteProperty?.RenderedValue ?? null;
            }

            propertyUpdated(property);
        }
    }

    const openAddDialog = () => {
        setEditMode(false);
        setParentIndex(null);
        setChildIndex(null);
        iconProp.Value = null;
        setIconProperty(iconProp);
        setLabelValue('');
        setAppearanceValue('l');
        anchorProp.Value = null;
        setAnchorProperty(anchorProp);
        setBehaviorValue('');

        setAddOrEditDialogOpen(true);
    };

    const openEditDialog = (navItem: NavigationItemValue, parentIndex: number, childIndex: number | null) => {
        setEditMode(true);
        setParentIndex(parentIndex);
        setChildIndex(childIndex);
        iconProp.Value = navItem.i ?? '';
        setIconProperty(iconProp);
        setLabelValue(navItem.l);
        setAppearanceValue(navItem.d || 'l');
        anchorProp.Value = JSON.stringify({
            t: navItem.t,
            p: navItem.p,
            v: navItem.v,
            s: navItem.s
        });
        setAnchorProperty(anchorProp);
        setBehaviorValue(navItem.b || '');

        setAddOrEditDialogOpen(true);

    }

    const cancelDialog = () => {
        setAddOrEditDialogOpen(false);
    };

    const openDeleteDialog = (parentIndex: number, childIndex: number | null) => {
        setParentIndex(parentIndex);
        setChildIndex(childIndex);
        setDeleteDialogOpen(true);
    }

    const confirmDelete = () => {
        if (childIndex !== null)
        {
            navItems[parentIndex!].c?.splice(childIndex, 1);
        }
        else
        {
            var orphans = navItems[parentIndex!].c;

            navItems.splice(parentIndex!, 1);

            if (orphans && orphans.length > 0)
            {
                navItems = navItems.concat(orphans);
            }
        }

        updateProperty();

        setDeleteDialogOpen(false);
    }

    const getNewNavItem = () => {
        let newNavItem: any = {};

        if (labelValue)
        {
            newNavItem.l = labelValue;
        }
        if (iconProperty && iconProperty.Value)
        {
            newNavItem.i = iconProperty.Value;
        }

        if (appearanceValue === 'f')
        {
            newNavItem.d = 'f';
            if (editMode) {
                newNavItem.c = navItems[parentIndex!].c;
            }
        } else {
            if (appearanceValue === 'l')
            {
                //link - no value set
            }
            else if (appearanceValue === 'b')
            {
                newNavItem.d = 'b';
            }

            let anchorValue = JSON.parse(anchorProperty.Value || '{}');

            if (anchorValue.t)
            {
                newNavItem.t = anchorValue.t;
            }
            if (anchorValue.p)
            {
                newNavItem.p = anchorValue.p;
            }
            if (anchorValue.v)
            {
                newNavItem.v = anchorValue.v;
            }
            if (anchorValue.s)
            {
                newNavItem.s = anchorValue.s;
            }

            if (behaviorValue === 'n') {
                newNavItem.b = 'n';
            }
        }

        return newNavItem;
    }

    const saveClicked = () => {
        if (editMode)
        {
            if (childIndex !== null)
            {
                if (appearanceValue === 'f') {
                    // changing child into a folder
                    navItems[parentIndex!].c?.splice(childIndex, 1);

                    const newNavItem = getNewNavItem();
                    newNavItem.c = null;
                    navItems.push(newNavItem);
                } else {
                    navItems[parentIndex!].c?.splice(childIndex, 1, getNewNavItem());
                }
            }
            else
            {
                var newNavItem = getNewNavItem();

                var orphans = (newNavItem.d !== 'f' && navItems[parentIndex!].d === 'f')
                    ? navItems[parentIndex!].c : [];

                navItems.splice(parentIndex!, 1, getNewNavItem());

                if (orphans && orphans.length > 0)
                {
                    navItems = navItems.concat(orphans);
                }
            }
        }
        else
        {
            navItems.push(getNewNavItem());
        }
        updateProperty();

        setAddOrEditDialogOpen(false);
    };

    const updateProperty = () => {
        const value = JSON.stringify(navItems);

        if (property) {
            property.Value = value;
            property.RenderedValue = value;
            propertyUpdated(property);
        }
    }

    const navItemCanBeDropped = (data: HoverData) => {
        if (data.parentIndex === null || data.hoveredParentIndex === null) {
            return false
        }

        //cant drop on itself
         if (data.parentIndex === data.hoveredParentIndex
            && (data.childIndex === null
                || data.childIndex === data.hoveredChildIndex))
        {
            return false;
        }

        if (data.isInto)
        {
            if (data.hoveredChildIndex !== null)
            {
                return false;
            }

            if (navItems[data.hoveredParentIndex].d !== 'f')
            {
                return false;
            }

            if (data.childIndex === null && (data.parentIndex || data.parentIndex === 0)
                && navItems[data.parentIndex]?.d === 'f')
            {
                return false;
            }
        }
        else if (data.childIndex === null && (data.parentIndex || data.parentIndex === 0)
            && navItems[data.parentIndex]?.d === 'f'
            && data.hoveredChildIndex !== null)
        {
            return false;
        }

        return true;
    }

    const moveItem = (
        dragParentIndex: number,
        dragChildIndex: number | null,
        hoverParentIndex: number,
        hoverChildIndex: number | null,
        isBefore: boolean,
        isInto: boolean,
        isAfter: boolean
        ) =>
    {
        let movingItem: NavigationItemValue | undefined;

        // 1. remove item being dragged
        if (dragChildIndex !== null)
        {
            movingItem = navItems[dragParentIndex].c?.splice(dragChildIndex, 1)[0];
        }
        else
        {
            movingItem = navItems.splice(dragParentIndex, 1)[0];
        }

        // 2. update target indices if needed
        if (hoverChildIndex === null && dragChildIndex === null
            && hoverParentIndex > dragParentIndex)
        {
            // root level, moving item down in list, adjust index for removed item
            hoverParentIndex--;
        }
        else if (hoverChildIndex !== null && dragChildIndex !== null
            && hoverParentIndex === dragParentIndex
            && hoverChildIndex > dragChildIndex)
        {
            // within same parent folder, moving child item down, adjust index for removed item
            hoverChildIndex--;
        }

        // 3. insert item
        if (isBefore)
        {
            if (hoverChildIndex === null)
            {
                navItems.splice(hoverParentIndex, 0, movingItem!);
            }
            else
            {
                if (!navItems[hoverParentIndex].c)
                {
                    navItems[hoverParentIndex].c = [];
                }
                navItems[hoverParentIndex].c?.splice(hoverChildIndex, 0, movingItem!);
            }
        }
        else if (isInto)
        {
            if (!navItems[hoverParentIndex].c)
            {
                navItems[hoverParentIndex].c = [];
            }
            navItems[hoverParentIndex].c?.push(movingItem!);
        }
        else if (isAfter)
        {
            if (hoverChildIndex === null)
            {
                navItems.splice(hoverParentIndex + 1, 0, movingItem!);
            }
            else
            {
                if (!navItems[hoverParentIndex].c)
                {
                    navItems[hoverParentIndex].c = [];
                }
                navItems[hoverParentIndex].c?.splice(hoverChildIndex + 1, 0, movingItem!);
            }
        }

        // 4. save change to backend
        updateProperty();
    }

    const draggingStarted = () => {
        setIsDragging(true);
    }

    const draggingHovering = (data: HoverData) => {
        if (data && navItemCanBeDropped(data))
        {
            setDraggingHoverData({...data});
        }
        else
        {
            setDraggingHoverData(null);
        }
    }
    const draggingFinished = (data: HoverData | null) => {
        if (data && data.parentIndex && data.hoveredParentIndex !== null)
        {
            moveItem(
                data.parentIndex,
                data.childIndex || null,
                data.hoveredParentIndex,
                data.hoveredChildIndex || null,
                data.isBefore,
                data.isInto,
                data.isAfter
            );
        }

        setIsDragging(false);
        setDraggingHoverData(null);
    }

    const dragDrop: DragDropData = {
        started: draggingStarted,
        hovering: draggingHovering,
        finished: draggingFinished,
        hoverData: draggingHoverData,
        isDragging
    }

    let dragPreviewStyle: CSSProperties | undefined = {};

    if (dragDrop?.hoverData)
    {
        let globalIndex = 0;
        let childOffset = 0;

        for (let i = 0; i < (dragDrop.hoverData.hoveredParentIndex ?? 0); i++)
        {
            globalIndex += 1 + (navItems[i].c?.length ?? 0);
        }

        if (dragDrop.hoverData.hoveredChildIndex)
        {
            globalIndex += 1 + dragDrop.hoverData.hoveredChildIndex;
            childOffset = 30;
        }

        let top = 54 * globalIndex;

        dragPreviewStyle = {
            position: 'absolute',
            pointerEvents: 'none',
            height: dragDrop?.hoverData.rect.height - 20,
            left: childOffset,
            width: dragDrop?.hoverData.rect.width - 20 - childOffset,
            zIndex: 99,
        };

        if (dragDrop?.hoverData.isAfter || dragDrop?.hoverData.isBefore)
        {
            dragPreviewStyle.height = "10px";
            dragPreviewStyle.width = dragDrop?.hoverData.rect.width - childOffset;
            dragPreviewStyle.backgroundColor = "blue";
            dragPreviewStyle.borderRadius = "25px";
        }

        if (dragDrop?.hoverData.isAfter)
        {
            top += 44;
        }

        dragPreviewStyle.top = top;

        if (dragDrop?.hoverData.isInto)
        {
            dragPreviewStyle.border = "10px solid blue";
        }
    }

    return (<>
            { dragDrop?.hoverData &&
                <div style={{
                    position: "absolute",
                    pointerEvents: "none",
                    width: "426px",
                    height: "100%"
                }}>
                <span style={dragPreviewStyle} ></span>
            </div>
            }
            { !isGlobalNavigation &&
                <>
                <div style={{marginTop: '20px', fontSize: '14px'}}>
                    <label>
                        Sync with Global Navigation
                    </label>
                </div>
                <div>
                    <Switch
                        checked={syncedToGlobalNav}
                        onChange={toggleSync}
                        data-testid="SyncToggleSwitch"
                    />
                </div>
                </>
            }
            { !syncedToGlobalNav &&
                <>
                <DndProvider backend={HTML5Backend} context={window}>
                    <div>
                        {navItems.map((item: any, index: number) =>
                            <NavigationItem
                                key={index}
                                navItem={item}
                                parentIndex={index}
                                pageList={pageList ?? []}
                                childIndex={null}
                                openEditDialog={openEditDialog}
                                openDeleteDialog={openDeleteDialog}
                                dragDrop={dragDrop}
                                inDisabledFolder={false}
                            ></NavigationItem>
                        )}
                    </div>
                </DndProvider>

                <div style={{marginTop: '16px'}}>
                    <Button
                        startIcon={<AddCircleOutlineIcon/>}
                        onClick={openAddDialog}
                        fullWidth
                        data-testid="AddNavItem">
                        ADD NAV ITEM
                    </Button>
                </div>
                </>
            }
            { syncedToGlobalNav &&
                <div style={{marginTop: '16px'}}>
                    <Button
                        startIcon={<ArrowCircleRightOutlinedIcon/>}
                        onClick={() => { if (navigateToProperty) { navigateToProperty(GLOBAL_NAVIGATION_PROPERTY_ID, 0) }}}
                        fullWidth
                        data-testid="GlobalNavButton">
                        GLOBAL NAVIGATION
                    </Button>
                </div>
            }
            <AddOrEditDialog
                addOrEditDialogOpen={addOrEditDialogOpen}
                cancelDialog={cancelDialog}
                editMode={editMode}
                iconProperty={iconProperty}
                setIconProperty={setIconProperty}
                labelValue={labelValue}
                setLabelValue={setLabelValue}
                appearanceValue={appearanceValue}
                setAppearanceValue={setAppearanceValue}
                anchorProperty={anchorProperty}
                setAnchorProperty={setAnchorProperty}
                page={page}
                behaviorValue={behaviorValue}
                setBehaviorValue={setBehaviorValue}
                saveClicked={saveClicked}
                pageList={pageList}
            ></AddOrEditDialog>

            <BuilderDialog
                title="Delete Navigation Entry?"
                message="Are you sure you wish to delete this navigation entry?"
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false) }
                actionName="Delete"
                action={confirmDelete}
                danger={true}
                showCancel={true}
            />
        </>)
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {

    if (property?.RenderedValue?.toLowerCase().includes(value)) {
        return true;
    }

    const navItems = JSON.parse(property.RenderedValue || '[]');

    let flatNavItems: Array<NavigationItemValue> = [];

    for(let i = 0; i < navItems.length; i++) {
        if (navItems[i].d === 'f') {
            if (navItems[i].c && navItems[i].c.length > 0) {
                flatNavItems = flatNavItems.concat(navItems[i].c);
            }
        } else {
            flatNavItems.push(navItems[i])
        }
    }

    for(let j = 0; j < flatNavItems.length; j++) {

        const propVal = JSON.stringify({
            t: flatNavItems[j].t,
            p: flatNavItems[j].p,
            v: flatNavItems[j].v,
            s: flatNavItems[j].s
        });
        const anchorProp = {
            ...BaseProperty,
            RenderedValue: propVal,
            Value: propVal
        };

        if (anchorContainsValue(anchorProp, value, filterData)) {
            return true
        }
    }

    return false;
}