import Editor from "@monaco-editor/react";
import CustomBlock from '../../types/CustomBlock';

interface CustomBlockEditorHtmlProps {
    block: CustomBlock,
    updateBlockHtml: (html: string) => void
}
export default function CustomBlockEditorHtml({block, updateBlockHtml}: CustomBlockEditorHtmlProps) {
    return (
        <Editor
            defaultLanguage="html"
            value={block?.HTML ?? ""}
            onChange={(v: string | undefined) => updateBlockHtml(v ?? "") }
        />
    )
}