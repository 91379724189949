import { useState, useEffect } from "react";
import { TextField } from '@mui/material';
import useApiPutCallbackAsync from "../../../hooks/useApiPutCallbackAsync";
import ErrorMessage from "../../common/ErrorMessage";
import SiteEditorStore from "../SiteEditorStore";

export default function PageRouteEditor() {
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const pageInfo = pageList.find((p) => p.Id === page?.PageId);
    const [pageRoute, setPageRoute] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const refreshPageList = SiteEditorStore.useStoreActions((actions) => actions.refreshPageList);
    const updateLastPropertyUpdateDate = SiteEditorStore.useStoreActions((actions) => actions.updateLastPropertyUpdateDate);
    const [{errorResponse}, updateCallback] = useApiPutCallbackAsync(`/site/${pageInfo?.SiteId}/page/${pageInfo?.Id}/route`) as any;

    useEffect(() => {
        let timeOutId: NodeJS.Timeout;

        if (pageInfo && pageRoute !== pageInfo.Route) {
            let pageRouteWithoutTrailing = pageRoute.length > 1 ? pageRoute.replace(/\/+$/, '') : pageRoute;
            timeOutId = setTimeout(() => {
                setIsUpdating(true);
                updateLastPropertyUpdateDate();
                updateCallback({ Route: pageRouteWithoutTrailing}, () => {
                    refreshPageList();
                }, () =>
                {
                    setIsUpdating(false);
                    setPageRoute(pageInfo.Route);
                });
            }, 2000);
        }

        return () => timeOutId && clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageRoute]);

    useEffect(() => {
        if (page && pageList) {
            const info = pageList.find((p) => p.Id === page.PageId);
            setPageRoute(info?.Route ?? '');
            setIsUpdating(false);
        }
    }, [page, pageList]);

    if (!page || !pageInfo) {
        return null;
    }

    const updatePageRoute = (route: string) => {
        if (!isUpdating)
        {
            if (route) {
                route = route.replace(' ', '-');
            }

            if (!route || route[0] !== "/") {
                route = "/" + route;
            }

            setPageRoute(route);
        }
    }

    return (<>
        <label className="body1" htmlFor="page-route-prop">Page Slug</label>
        <ErrorMessage
            message={"Error updating page slug"}
            dismissable={true}
            visible={errorResponse != null}
            apiError={errorResponse}
        />
        <TextField
            id="page-route-prop"
            variant="outlined"
            fullWidth
            value={pageRoute}
            onChange={(e) => { updatePageRoute(e.target.value); }}
            inputProps={{
                maxLength: 2000,
                onKeyDown: (event) => {
                    if (isUpdating) {
                        event.preventDefault();
                    }
                }
            }}
        />
    </>);
}