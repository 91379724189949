import { IconButton } from "@mui/material"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React from "react"

export interface DeleteButtonProps {
    onClick: () => void,
    ariaLabel: string,
    dataTestId?: string,
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, ariaLabel, dataTestId }) => {
    return (
        <IconButton
            color="error"
            data-testid={dataTestId}
            aria-label={ariaLabel}
            onClick={onClick}>
            <DeleteOutlineIcon />
        </IconButton>
    )
}
