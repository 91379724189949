import { useState, useRef, useEffect } from "react";
import { Button, Box, IconButton, Popover, OutlinedInput, InputAdornment, FormControlLabel, Switch, TextField, MenuList, MenuItem, Divider } from '@mui/material';
import Site  from '../../../types/Site';
import ErrorMessage from '../../common/ErrorMessage';
import SiteStatus from '../../../types/enum/SiteStatus';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { addDays, format } from 'date-fns';
import FormValidationError from "../../common/FormValidationError";
import BuilderDialog from "../../common/BuilderDialog";
import { isValidHttpUrl } from "../../../utils/urlHelpers";
import FormHelperText from "../../common/FormHelperText";
import NavBarButton from "./NavBarButton";
import SiteEditorStore from "../SiteEditorStore";

export interface PublishMenuProps {
    viewSite: () => void,
    setQueueMessage: React.Dispatch<React.SetStateAction<string>>,
    setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
    setShowPublishQueuedToast: React.Dispatch<React.SetStateAction<boolean>>,
    setShowPublishError: React.Dispatch<React.SetStateAction<boolean>>,
    setPollingSite: React.Dispatch<React.SetStateAction<boolean>>,
    currentLastPublishedDate: string | null,
    setCurrentLastPublishedDate: React.Dispatch<React.SetStateAction<string | null>>,
    publishCallback: (requestBody: any, onSuccess: any, onFailure: any) => void,
    resetPublishMenuState: () => void,
    updateStatus: (data: { Status: number, PublishedVersionId: string | null, RedirectUrl: string | null }
        , queueMessage: string, successMessage: string, pollSite?: boolean) => void,
    pollingSite: boolean
}

export default function PublishMenu({
    viewSite,
    setQueueMessage,
    setSuccessMessage,
    setShowPublishQueuedToast,
    setShowPublishError,
    setPollingSite,
    currentLastPublishedDate,
    setCurrentLastPublishedDate,
    publishCallback,
    resetPublishMenuState,
    updateStatus,
    pollingSite
}: PublishMenuProps) {
    const [showUnpublishModal, setShowUnpublishModal] = useState(false);
    const [showRedirectMenu, setShowRedirectMenu] = useState(false);
    const [showRedirectModal, setShowRedirectModal] = useState(false);
    const [showCancelRedirectModal, setShowCancelRedirectModal] = useState(false);
    const [redirectValid, setRedirectValid] = useState(false);
    const [showRedirectValidationNotification, setShowRedirectValidationNotification] = useState(false);
    const [redirectErrorMsg, setRedirectErrorMsg] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [action, setAction] = useState("");
    const [icon, setIcon] = useState<JSX.Element | null>(null);
    const [status, setStatus] = useState("");

    const [showMenu, setShowMenu] = useState(false);
    const [saveVersion, setSaveVersion] = useState(true);
    const [versionName, setVersionName] = useState('');
    const navBarButtonActive = SiteEditorStore.useStoreState((state) => state.navBarButtonActive);
    const updateNavBarButtonActive = SiteEditorStore.useStoreActions((actions) => actions.updateNavBarButtonActive);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const refreshSite = SiteEditorStore.useStoreActions((actions) => actions.refreshSite);

    const menuRef = useRef();
    const showPublishMenu = navBarButtonActive === "publish";

    useEffect(() => {
        if (!showPublishMenu) {
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPublishMenu])

    useEffect(() => {
        if (showMenu) {
            setVersionNameDefault();
            setSaveVersion(true);
        }
    }, [showMenu]);

    useEffect(() => {
        setStatusActionAndIcon();

        const intervalId = setInterval(() => {
            setStatusActionAndIcon();
        }, 60 * 1000)
        
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site, currentLastPublishedDate, pollingSite]);

    const getPublishedDateDiffs = () => {
        let minutes = 0;
        let hours = 0;
        let days = 0;

        if (site?.LastPublishedDate) {
            const dateFuture = Date.parse(site.LastPublishedDate);
            const dateNow = new Date().getTime();
    
            var seconds = Math.floor((dateNow - (dateFuture))/1000);
            minutes = Math.floor(seconds/60);
            hours = Math.floor(minutes/60);
            days = Math.floor(hours/24);
        }

        return [minutes, hours, days];
    }

    const setStatusActionAndIcon = () => {
        const iconStyling = { fontSize: "18px", marginRight: "5px" };
        
        if (site?.StatusTypeId === SiteStatus.Redirected) {
            setAction("Update");
            setStatus("site is redirected");
            setIcon(<ArrowCircleRightOutlinedIcon sx={iconStyling} />);
        } 
        if (site?.StatusTypeId === SiteStatus.PendingProvisioning 
            || site?.StatusTypeId === SiteStatus.Unpublished) {
            setAction("Publish");
            setStatus("site is unpublished");
            setIcon(<ChangeHistoryTwoToneIcon sx={iconStyling} />);
        }
        if (site?.StatusTypeId === SiteStatus.Published) {
            setAction("Update")
            setIcon(null);

            if (pollingSite) {
                setStatus("...publish queued")
            }
            else if (site?.LastPublishedDate) {
                setIcon(<TaskAltIcon sx={iconStyling} />);
                const [minutes, hours, days] = getPublishedDateDiffs();
                let status = "";

                if (days > 0) {
                    status = `published ${days} day${days > 1 ? 's' : ''} ago`;
                } else if (hours > 0) {
                    status = `published ${hours} hour${hours > 1 ? 's' : ''} ago`;
                } else if (minutes > 0) {
                    status = `published ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
                } else {
                    status = `published just now`;
                }

                setStatus(status);
            } else {
                setStatus("")
            }
        }
    };

    const startPublishRequest = (site: Site) => {
        setCurrentLastPublishedDate(site.LastPublishedDate);
        publishCallback(
            { 
                Name: saveVersion ? versionName : null, 
                AutoPublish: true,
                VersionType: saveVersion ? 1901 : 1902,
                PreviewExpiration: saveVersion ? format(addDays(new Date(), 90), "yyyy-MM-dd") : null
            }
            , () => { 
                setShowPublishQueuedToast(true);
                setPollingSite(true);
            }
            , () => { setShowPublishError(true) });
    };

    const dispatchPublishButton = (isActive: boolean = false) => {
        updateNavBarButtonActive(isActive ? "publish" : null);
    }

    const resetState = () => {
        setShowMenu(false);
        setShowUnpublishModal(false);
        setShowRedirectMenu(false);
        setShowRedirectModal(false);
        setShowCancelRedirectModal(false);

        resetPublishMenuState();
    };

    const publish = async (queueMessage: string, successMessage: string) => {
        setQueueMessage(queueMessage);
        setSuccessMessage(successMessage);
        resetState();
        // Retrieve latest site record first so we can compare against updates
        // to know when the publish has succeeded
        const site = await refreshSite();
        startPublishRequest(site);
    };

    const unpublishSite = () => {
        // TODO: we currently can't have a queued and success message as we can't determine when the
        // publish has finished, so for now show the queued message as success.
        // TODO: We will implement a way to know when the publish has succeeded regardless of status
        // TODO: Once we can detect a publish success for unpublish, change sucess message to "Site unpublished!"
        updateStatus({ Status: SiteStatus.Unpublished, PublishedVersionId: null, RedirectUrl: null }
            , "Site unpublish has been queued.", "Site unpublish has been queued!");
        resetState();
    };

    const redirectSite = () => {
        // TODO: we currently can't have a queued and success message as we can't determine when the
        // publish has finished, so for now show the queued message as success.
        // TODO: We will implement a way to know when the publish has succeeded regardless of status
        // TODO: Once we can detect a publish success for unpublish, change sucess message to "Site redirected!"
        updateStatus({ Status: SiteStatus.Redirected, PublishedVersionId: null, RedirectUrl: redirectUrl }
            , "Site redirect has been queued.", "Site redirect has been queued!");
        resetState();
    };

    const republishRedirectedSite = () => {
        updateStatus({ Status: SiteStatus.Published, PublishedVersionId: site?.PublishedVersionId ?? null, RedirectUrl: null }
            , "Site redirect cancellation has been queued.", "Site redirect cancelled!", true);
        resetState();
    };

    const setVersionNameDefault = () => {
        setVersionName(format(new Date(), "M/d/yyyy h:mm:ss aa") + " Publish")
    };

    const saveVersionChanged = (e: any) => {
        if (saveVersion) {
            setSaveVersion(false);
        } else {
            setVersionNameDefault();
            setSaveVersion(true);
        }
    };

    const showRedirect = () => {
        setShowMenu(false);
        setRedirectUrl(''); 
        setRedirectValid(false); 
        setShowRedirectMenu(true); 
        setShowRedirectValidationNotification(false);
    };

    const cancelRedirect = () => {
        onRedirectUrlChange('');
        setShowRedirectMenu(false);
        setShowRedirectValidationNotification(false);
        setShowMenu(true);
    };

    const onRedirectUrlChange = (url: string) => {
        url = url.replace(' ', '');
        setRedirectUrl(url);

        if (url) {
            if (url.match(/^(.*)(?<!https:\/\/|http:\/\/|https:\/|http:\/|https:|http:|https|http|htt|ht|h)$/)) {
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    url = "https://" + url;
                    setRedirectUrl(url);
                }
            }

            if (isValidHttpUrl(url)) {
                setShowRedirectValidationNotification(false);
                setRedirectValid(true);
            } else {
                setRedirectErrorMsg("Redirect URL is not valid.");
                setRedirectValid(false);
            }
        } else {
            setRedirectErrorMsg("Redirect URL is required.");
            setRedirectValid(false);
        }
    }

    const continueToRedirectModal = () => {
        if (!redirectValid) {
            setShowRedirectValidationNotification(true);
        } else {
            setShowRedirectModal(true);
        }
    };

    const togglePublishMenu = (e: any) => {
        if (showMenu) {
            setShowMenu(false);
            dispatchPublishButton();
        } else if (showRedirectMenu) {
            setShowRedirectMenu(false);
        } else {
            setShowMenu(true);
            dispatchPublishButton(true);
        }
        e.stopPropagation(); 
    }

    const publishHeader = site?.StatusTypeId === SiteStatus.Published ? "Update Site" : "Publish Site";
    const publishButtonText = site?.StatusTypeId === SiteStatus.Published ? "Update Site" : "Publish";
    const showPublishForm = site?.StatusTypeId === SiteStatus.Unpublished 
        || site?.StatusTypeId === SiteStatus.Published 
        || site?.StatusTypeId === SiteStatus.PendingProvisioning;
    const publishQueuedMessage = site?.StatusTypeId === SiteStatus.Published ? "Site update has been queued." : "Site publish has been queued.";
    const publishSuccessMessage = site?.StatusTypeId === SiteStatus.Published ? "Site updated!" : "Site published!";
    const publishValid = !showMenu || !saveVersion || versionName !== '';
    const showMenuList = site?.StatusTypeId === SiteStatus.Published || site?.StatusTypeId === SiteStatus.Redirected;
    const menuItemStyle = { paddingLeft: "5px", marginLeft: "-5px", marginRight: "-5px" };

    return (
        <>
            <NavBarButton buttonClick={togglePublishMenu} label={action} icon={icon} status={status} menuRef={menuRef}></NavBarButton>
            <Popover
                id="publish-menu"
                open={showPublishMenu && showMenu}
                anchorEl={menuRef.current}
                onClose={() => { if (!showRedirectMenu) {setShowMenu(false); dispatchPublishButton();}
                }}
                classes={{
                    root: "below-nav",
                    paper: "below-nav"
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: "320px", padding: "16px", boxSizing: "border-box" }}>
                    <>
                    { showMenuList &&
                        <>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            value={site.Domain}
                            onChange={() => {}}
                            fullWidth
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={viewSite}
                                    edge="end"
                                >
                                <OpenInNewIcon />
                                </IconButton>
                            </InputAdornment>
                            }
                            label={undefined}
                        />
                        { site.StatusTypeId === SiteStatus.Redirected &&
                            <FormHelperText message={`Redirected to: ${site.RedirectUrl}`} />
                        }
                        <MenuList
                            id="publish-menu-list"
                            sx={{ width: "100%", marginTop: "32px" }}
                        >
                            <MenuItem
                                onClick={() => 
                                    { 
                                        setShowUnpublishModal(true); 
                                    }}
                                sx={{ color: "#CF1F2E", ...menuItemStyle }}
                            >
                                Unpublish site
                            </MenuItem>
                            <Divider />
                            { site.StatusTypeId === SiteStatus.Published && 
                                <PublishedStatusMenuItem />
                            }
                            { site.StatusTypeId === SiteStatus.Redirected && 
                                <MenuItem onClick={() => { setShowCancelRedirectModal(true) }} sx={menuItemStyle}>
                                    Cancel redirect
                                </MenuItem>
                            }
                        </MenuList>
                        </>
                    }
                    { showPublishForm &&
                        <>
                        <Box className="headline6" sx={{ marginTop: showMenuList ? "32px" : "0px" }}>{publishHeader}</Box>
                        <Box className="body1" sx={{ marginTop: "10px", marginBottom: "32px" }}>This will make the current version of the website publically available.</Box>
                        <FormControlLabel control={<Switch color="secondary" checked={saveVersion} onChange={saveVersionChanged} />} label="Save a version?" />
                        { saveVersion &&
                            <>
                            <TextField id="publish-version-name" value={versionName} onChange={(e) => setVersionName(e.target.value) } label="Version name" variant="outlined" fullWidth sx={{ marginTop: "32px" }} color="secondary" />
                            <FormValidationError valid={publishValid} message="Version name is required." />
                            </>
                        }
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            fullWidth 
                            sx={{ marginTop: "32px" }} 
                            disabled={!publishValid} 
                            onClick={() => { publish(publishQueuedMessage, publishSuccessMessage) }}>
                                {publishButtonText}
                        </Button>
                        </>
                    }
                    </>
                </Box>
            </Popover>
            <Popover
                id="redirect-menu"
                open={showPublishMenu && showRedirectMenu}
                anchorEl={menuRef.current}
                onClose={() => {}}
                classes={{
                    root: "below-nav",
                    paper: "below-nav"
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: "480px", padding: "16px", boxSizing: "border-box" }}>
                    <>
                    <Box className="headline6">Redirect Site</Box>
                    <Box className="body1" sx={{ marginTop: "10px", marginBottom: "32px" }}>Redirecting allows you to send traffic to an external resource using a 301 site redirect.</Box>
                    <TextField id="redirect-url" value={redirectUrl} onChange={(e) => onRedirectUrlChange(e.target.value) } label="Redirect URL" variant="outlined" fullWidth color="secondary" />
                    <FormValidationError valid={redirectValid} message={redirectErrorMsg} />
                    <Box sx={{ marginTop: "32px", display: "flex", justifyContent: "space-between" }}>
                        <Button 
                            color="secondary" 
                            onClick={cancelRedirect}>
                                Cancel
                        </Button>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={continueToRedirectModal}>
                                Save
                        </Button>
                    </Box>
                    </>
                </Box>
            </Popover>

            <BuilderDialog
                title="Unpublish Site" 
                message="Are you sure you wish to unpublish this site? All traffic will be sent to a 404 splash page until the site is published again."
                open={showPublishMenu && showUnpublishModal} 
                onClose={() => setShowUnpublishModal(false) }
                actionName="Unpublish"
                action={unpublishSite} 
                danger={true}
                showCancel={true}
            />

            <BuilderDialog
                title="Redirect Site" 
                message="Are you sure you wish to redirect this domain to an external site? This action will have SEO implications and is meant to be a permanent redirection."
                open={showPublishMenu && showRedirectModal} 
                onClose={() => setShowRedirectModal(false) }
                actionName="Redirect"
                action={redirectSite} 
                showCancel={true}
            />

            <BuilderDialog
                title="Cancel Redirect" 
                message="Are you sure you wish to cancel the site redirect? This action will have SEO implications and will publish the site in its current state."
                open={showPublishMenu && showCancelRedirectModal} 
                onClose={() => setShowCancelRedirectModal(false) }
                actionName="Confirm"
                action={republishRedirectedSite} 
                danger={true}
                showCancel={true}
            />

            <ErrorMessage
                message="Now hold on! Please correct the following errors before saving this version."
                visible={!publishValid}
            />
            <ErrorMessage
                message="Now hold on! Please correct the following errors before redirecting this site."
                visible={showRedirectValidationNotification}
            />
        </>
    );

    function PublishedStatusMenuItem() {
        return(
            <>
                <MenuItem 
                    onClick={showRedirect} 
                    sx={menuItemStyle}
                >
                    Redirect site
                </MenuItem>
                <Divider />
            </>
        );
    }
}