import { useState, useEffect } from 'react';
import { Alert, AlertColor } from "@mui/material";
import { alertLevels } from "../../utils/constants";
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorMessageProps from '../../types/ErrorMessageProps';

function AlertMessage({ severity, message, actionName, action, filled, visible, duration, dismissable = false }: ErrorMessageProps) {
    if (visible === undefined) {
        visible = true;
    }

    const [isVisible, setIsVisible] = useState<boolean | undefined>(visible);

    let actionValue = null;

    if (dismissable) {
        actionName = "Dismiss";
        action = () => setIsVisible(false);
    }

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    useEffect(() => {
        let closeTimeout: NodeJS.Timeout;

        if (isVisible && duration) {
            closeTimeout = setTimeout(() => {
                setIsVisible(false);
            }, duration);

        }

        return () => closeTimeout && clearTimeout(closeTimeout);
    }, [isVisible, duration]);

    if (!isVisible) {
        return null;
    }

    if (action && actionName) {
        actionValue = (
            <Button onClick={action} color={severity} size="small">
                {actionName}
            </Button> 
        );
    }
    if (filled) {
        if (severity === alertLevels.success) {
            return (
                <Alert 
                    sx={{ borderRadius: 0 }}
                    severity={severity} 
                    iconMapping={{
                        error: <ErrorIcon color="error" />,
                    }}
                    action={actionValue}>
                        {message}
                </Alert>
            );
        } else {
            return (
                <Alert 
                    sx={{ borderRadius: 0 }}
                    severity={severity} 
                    iconMapping={{
                        error: <ErrorIcon />,
                    }}
                    action={actionValue}>
                        {message}
                </Alert>
            );
        }
    } else {
        return (
            <Alert 
                sx={{ backgroundColor: '#FFFFFF' }}
                severity={severity} 
                iconMapping={{
                    error: <ErrorIcon color="error" />,
                }}
                action={actionValue}>
                    {message}
            </Alert>
        );
    }
}

export function ErrorAlertMessage(props: ErrorMessageProps) {
    return <AlertMessage {...props} severity={alertLevels.error as AlertColor} />;
}
export function SuccessAlertMessage(props: ErrorMessageProps) {
    return <AlertMessage {...props} severity={alertLevels.success as AlertColor} />;
}
export function InfoAlertMessage(props: ErrorMessageProps) {
    return <AlertMessage {...props} severity={alertLevels.info as AlertColor} />;
}

export default AlertMessage;