import { Switch } from '@mui/material';
import SiteEditorPropertyList from './SiteEditorPropertyList';
import PropertySideBarContent from '../../../types/PropertySideBarContent';
import TabPanel from "../../common/TabPanel";
import BuilderActionButton from "./builder_actions/BuilderActionButton";
import { blocksAreSiblings } from "../../../utils/blockHelpers";
import TemplateTypes from "../../../types/enum/TemplateTypes";
import CreateTemplate from "./builder_actions/create_template/CreateTemplate";
import SiteEditorStore from "../SiteEditorStore";
import NoResultsFound from "../../common/NoResultsFound";
import { filterProperties } from "../../../utils/propertyHelpers";
import property_renderers from '../../../utils/propertyRenderers';

export default function SiteEditorPropertiesBlockProperties() {
    const propertyTab = SiteEditorStore.useStoreState((state) => state.propertyTab);
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);
    const allSelectedBlocksDisabled = SiteEditorStore.useStoreState((state) => state.allSelectedBlocksDisabled);
    const cloneBlock = SiteEditorStore.useStoreActions((actions) => actions.cloneBlock);
    const deleteSelectedBlocks = SiteEditorStore.useStoreActions((actions) => actions.deleteSelectedBlocks);
    const toggleSelectedBlocksStatus = SiteEditorStore.useStoreActions((actions) => actions.toggleSelectedBlocksStatus);
    const propertySearchCriteria = SiteEditorStore.useStoreState((state) => state.propertySearchCriteria);

    if (!selectedBlocks || selectedBlocks.length === 0) {
      return null;
    }

    const selectionContent: PropertySideBarContent[] = [];

    if (selectedBlocks.length > 0) {
      if (!propertySearchCriteria || 
        ("enable blocks".includes(propertySearchCriteria) || "disable blocks".includes(propertySearchCriteria))) {
        const disableLabel = selectedBlocks.length === 1 ? "Disable Block" : "Disable Blocks";
        selectionContent.push({
          CategoryTypeId: 999,
          Content: 
            <div>
              <div>{disableLabel}</div>
              <Switch 
                id={"blockproperties-disableblocks-toggle"}
                checked={allSelectedBlocksDisabled} 
                onChange={() => { toggleSelectedBlocksStatus(); }} 
              />
            </div>
        });
      }
      if ((selectedBlocks.length === 1 || blocksAreSiblings(selectedBlocks))
          && (!propertySearchCriteria || "create section".includes(propertySearchCriteria))) {
        selectionContent.push({
          CategoryTypeId: 999,
          Content: 
            <CreateTemplate
              id={"blockproperties-createsectiontemplate-button"}
              templateType={TemplateTypes.Section}
            />
        });
      }
      if (selectedBlocks.length === 1
        && (!propertySearchCriteria || "duplicate block".includes(propertySearchCriteria))) {
        selectionContent.push({
          CategoryTypeId: 999,
          Content: 
            <BuilderActionButton 
              id={"blockproperties-duplicateblock-button"}
              text={"Duplicate Block"}
              action={() => {cloneBlock(selectedBlocks[0].Id)}}
            />
        });
      }
      if ((!propertySearchCriteria || "delete block".includes(propertySearchCriteria))) {
        selectionContent.push({
          CategoryTypeId: 999,
          Content: <BuilderActionButton 
            id={"blockproperties-deleteblock-button"}
            text={selectedBlocks.length === 1 ? "Delete Block" : "Delete Blocks"}
            fontColor={'#CF1F2E'}
            action={() => {deleteBlocks()}}
          />
        });
      }
    }

    let filteredBlockProperties = selectedBlocks.length === 1 ? selectedBlocks[0].Properties : [];
    let noResultsFound = false;

    if (propertySearchCriteria) {
      filteredBlockProperties = filterProperties(filteredBlockProperties, propertySearchCriteria, { pageList }
        , property_renderers);
      noResultsFound = filteredBlockProperties.length === 0 && selectionContent.length === 0;
    }

    const deleteBlocks = () => {
      if (window.confirm(selectedBlocks && selectedBlocks.length > 1
          ? 'Are you sure you wish to delete these blocks?'
          : 'Are you sure you wish to delete this block?') === true) {
          deleteSelectedBlocks();
      }
    }

    return (
      <TabPanel value={propertyTab} name="block-tab" index={2} height="calc(100% - 270px)"> 
        {selectedBlocks.length >= 1 && !noResultsFound &&
          <SiteEditorPropertyList
            properties={filteredBlockProperties}
            blockMapping={selectedBlocks[0]}
            extraContent={selectionContent}
          />
        }
        <NoResultsFound display={noResultsFound} />
      </TabPanel>
    );
}