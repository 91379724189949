import { useState } from "react";
import useApiPostCallbackAsync from "../../../../../hooks/useApiPostCallbackAsync";
import Template from "../../../../../types/Template";
import { ResponseError } from "../../../../../utils/apiHelpers";
import ErrorMessage from "../../../../common/ErrorMessage";
import Loading from "../../../../common/Loading";
import SuccessMessage from "../../../../common/SuccessMessage";
import TemplateTypes from "../../../../../types/enum/TemplateTypes";
import BuilderActionButton from "../BuilderActionButton";
import SwapTemplateStepOneModal from "./SwapTemplateStepOneModal";
import SwapTemplateStepThreeModal from "./SwapTemplateStepThreeModal";
import SwapTemplateStepTwoModal from "./SwapTemplateStepTwoModal";
import SiteEditorStore from "../../../SiteEditorStore";

interface SwapTemplateProps {
    id?: string,
    templateType: TemplateTypes
}

export enum SwapTemplateSteps {
    StepOne,
    StepTwo,
    StepThree
}

export default function SwapTemplate({
    id,
    templateType
}: SwapTemplateProps){

    const refreshPageList = SiteEditorStore.useStoreActions((actions) => actions.refreshPageList);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const refreshPage = SiteEditorStore.useStoreActions((actions) => actions.refreshPage);
    const refreshBlocksAndSections = SiteEditorStore.useStoreActions((actions) => actions.refreshBlocksAndSections);

    const [currentStep, setCurrentStep] = useState<SwapTemplateSteps | null>(null);

    const [versionName, setVersionName] = useState(
        templateType === TemplateTypes.Page
        ? "Backup before page swap"
        : "Backup before site swap"
    );

    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
    const [selectedPageIds, setSelectedPageIds] = useState<string[]>([]);
    const [includeNewPagesInGlobalNav, setIncludeNewPagesInGlobalNav] = useState(true);

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [apiError, setApiError] = useState<ResponseError | null>(null);


    const [{ isLoading: submitting }, swapTemplateCallback] = useApiPostCallbackAsync("", []) as any;

    const begin = () => {

        switch (templateType) {
            case TemplateTypes.Site:
                setVersionName("Backup before site swap");
                break;
            case TemplateTypes.Page:
                setVersionName("Backup before page swap");
                break;
            default:
                setVersionName("");
                break;
        }
        setCurrentStep(SwapTemplateSteps.StepOne);
        setIncludeNewPagesInGlobalNav(true);
        setSelectedTemplate(null);
        setShowSuccessToast(false);
        setApiError(null);
    }

    const cancel = () => {
        setCurrentStep(null);
    }

    const submit = () => {
        setApiError(null);
        setCurrentStep(null);
        switch(templateType) {
            case TemplateTypes.Site:
                swapTemplateCallback(
                    {
                        versionName: versionName,
                        pageIds: selectedPageIds,
                        includeNewPagesInGlobalNav: includeNewPagesInGlobalNav
                    },

                    (res: string) => {
                        setShowSuccessToast(true);
                        refreshPageList();
                        refreshBlocksAndSections();
                    },

                    (err: ResponseError) => {
                        setApiError(err);
                    },

                    `/sites/${site?.Id}/template/${selectedTemplate?.Id}`
                );
                break;
            case TemplateTypes.Page:
                swapTemplateCallback(
                    {
                        versionName: versionName
                    },

                    (res: string) => {
                        setShowSuccessToast(true);
                        refreshPage();
                        refreshBlocksAndSections();
                    },

                    (err: ResponseError) => {
                        setApiError(err);
                    },

                    `/site/${site?.Id}/page/${page?.PageId}/template/${selectedTemplate?.Id}`
                );
                break;
        }
    }

    const buttonText = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Swap Site Template";
            case TemplateTypes.Page:
                return "Swap Page Template";
            default:
                return "";
        }
    }

    const successMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Site template swapped!";
            case TemplateTypes.Page:
                return "Page template swapped!";
            default:
                return "";
        }
    }

    const errorMessage = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Error swapping site templates";
            case TemplateTypes.Page:
                return "Error swapping page templates";
            default:
                return "";
        }
    }

    return (
        <>
            <BuilderActionButton
                id={id}
                text={buttonText()}
                action={begin}
            />
            <SwapTemplateStepOneModal
                showModal={currentStep === SwapTemplateSteps.StepOne}
                setCurrentStep={setCurrentStep}
                templateType={templateType}
                versionName={versionName}
                setVersionName={setVersionName}
                cancel={cancel}
            />
            <SwapTemplateStepTwoModal
                showModal={currentStep === SwapTemplateSteps.StepTwo}
                setCurrentStep={setCurrentStep}
                templateType={templateType}
                site={site}
                setSelectedTemplate={setSelectedTemplate}
                cancel={cancel}
            />
            <SwapTemplateStepThreeModal
                showModal={currentStep === SwapTemplateSteps.StepThree}
                setCurrentStep={setCurrentStep}
                templateType={templateType}
                selectedTemplate={selectedTemplate}
                cancel={cancel}
                submit={submit}
                selectedPageIds={selectedPageIds}
                setSelectedPageIds={setSelectedPageIds}
                includeNewPagesInGlobalNav={includeNewPagesInGlobalNav}
                setIncludeNewPagesInGlobalNav={setIncludeNewPagesInGlobalNav}
            />
            { submitting && <Loading message="" /> }

            <SuccessMessage
                message={successMessage()}
                dismissable={true}
                duration={3000}
                visible={showSuccessToast}
            />
            <ErrorMessage
              message={errorMessage()}
              dismissable={true}
              visible={apiError != null}
              apiError={apiError}
            />

        </>
    )
}