import PropertyId from '../../../types/enum/PropertyId';
import { getDynamicAttributes, getPropertyValue, doesValueMatchString, getPropertyBooleanValue, propertyHasValue } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import BlockProps from '../../../types/BlockProps';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const address1 = getPropertyValue(block.Properties, PropertyId.AddressLine1);
    const address2 = getPropertyValue(block.Properties, PropertyId.AddressLine2);
    const city = getPropertyValue(block.Properties, PropertyId.City);
    const state = getPropertyValue(block.Properties, PropertyId.State);
    const zip = getPropertyValue(block.Properties, PropertyId.Zip);

    const hideAddressLine = getPropertyBooleanValue(block.Properties, PropertyId.HideAddressLine);
    const isMulti = doesValueMatchString(block.Properties, PropertyId.AddressDisplay, "2");

    return (
        <>
            {isMulti &&
                <div {...getDynamicAttributes({block, builderProps, className: "adr", blockWrapperData})}>
                    {!hideAddressLine && (address1 || address2) &&
                        <>
                        <span className="street-address">{address1}{address2 && <>{address1 && <>, </>}{address2}</>}</span>
                        {(city || state || zip) && <br/>}
                        </>
                    }
                    {city && <span className="locality">{city}{state && <>, </>}{!state && zip && <> </>}</span>}
                    {state && <span className="region">{state}{zip && <> </>}</span>}
                    {zip && <span className="postal-code">{zip}</span>}
                </div>
            } 
            {!isMulti &&
                <span {...getDynamicAttributes({block, builderProps, className: "adr", blockWrapperData})}>
                    {!hideAddressLine && (address1 || address2) &&
                        <span className="street-address">{address1}{address2 && <>{address1 && <>, </>}{address2}
                        {(city || state || zip) && <>, </>}</>}</span>
                    }
                    {city && <span className="locality">{city}{state && <>, </>}{!state && zip && <> </>}</span>}
                    {state && <span className="region">{state}{zip && <> </>}</span>}
                    {zip && <span className="postal-code">{zip}</span>}
                </span>
            }
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender?: boolean) {
    const hideAddressLine 
        = getPropertyBooleanValue(block.Properties, PropertyId.HideAddressLine);
    return (!hideAddressLine && propertyHasValue(block.Properties, PropertyId.AddressLine1))
        || (!hideAddressLine && propertyHasValue(block.Properties, PropertyId.AddressLine2))
        || propertyHasValue(block.Properties, PropertyId.City)
        || propertyHasValue(block.Properties, PropertyId.State)
        || propertyHasValue(block.Properties, PropertyId.Zip);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender?: boolean) {
    return !hasContentToRender(block, page, !!childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const content = getPropertyValue(block.Properties, PropertyId.AddressLine1);

    return content ? <>{content}</> : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const address1 = getPropertyValue(block.Properties, PropertyId.AddressLine1);
    const address2 = getPropertyValue(block.Properties, PropertyId.AddressLine2);
    const city = getPropertyValue(block.Properties, PropertyId.City);
    const state = getPropertyValue(block.Properties, PropertyId.State);
    const zip = getPropertyValue(block.Properties, PropertyId.Zip);
    
    return (!!address1 && (address1.toLowerCase().includes(filterText)))
        || (!!address2 && (address2.toLowerCase().includes(filterText)))
        || (!!city && (city.toLowerCase().includes(filterText)))
        || (!!state && (state.toLowerCase().includes(filterText)))
        || (!!zip && (zip.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}