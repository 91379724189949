import Property from '../../../types/Property';
import PropertyEditor from '../../property_editors/PropertyEditor';
import property_renderers from "../../../utils/propertyRenderers";
import PropertySideBarContent from '../../../types/PropertySideBarContent';
import CategorizedList from "../../common/categorized_list/CategorizedList";
import BlockMapping from '../../../types/BlockMapping';
import SiteEditorStore from '../SiteEditorStore';
import Page from '../../../types/Page';
import CategorizedItem from '../../../types/CategorizedItem';
import { useFlags } from 'flagsmith/react';
import { FeatureToggleEnum } from '../../config/FeatureToggleDefaults';

export interface SiteEditorPropertyListProps {
    properties: Property[], 
    blockMapping?: BlockMapping,
    extraContent?: PropertySideBarContent[]
}

export default function SiteEditorPropertyList({properties, blockMapping, extraContent = []}: SiteEditorPropertyListProps) {
  const items: CategorizedItem[] = [];
  const page = SiteEditorStore.useStoreState((state) => state.page);
  const categories = SiteEditorStore.useStoreState((state) => state.propertyCategories);
  const populatedDataSources = SiteEditorStore.useStoreState((state) => state.populatedDataSources);
  const siteProperties = SiteEditorStore.useStoreState((state) => state.siteProperties);
  const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
  const refreshPage = SiteEditorStore.useStoreActions((actions) => actions.refreshPage);
  const updatePage = SiteEditorStore.useStoreActions((actions) => actions.updatePage);
  const expandCategories = SiteEditorStore.useStoreState((state) => state.expandPropertyCategories);
  const scrollToProperty = SiteEditorStore.useStoreActions((actions) => actions.scrollToProperty);
  const updateLastPropertyUpdateDate = SiteEditorStore.useStoreActions((actions) => actions.updateLastPropertyUpdateDate);
  const flags = useFlags([FeatureToggleEnum.hiddenPropertyIds]);
  var hiddenPropertyIds: string[] = flags.hidden_property_ids.enabled
    ? JSON.parse(flags.hidden_property_ids.value?.toString() || "[]").map((id: string) => id.toLowerCase())
    : [];

  const navigateToProperty = (propertyId: string, propertyTab: number) => {
    scrollToProperty({ propertyId, propertyTab });
  };

  const updateLastPropertyUpdatedDate = () => {
    updateLastPropertyUpdateDate();
  }

  const propertyUpdated = () => {
    if (page) {
      updatePage({...page} as Page);
    }
  };
  if (properties && properties.length > 0) {
    for (const property of properties) {
      if (hiddenPropertyIds.includes(property.PropertyId.toLowerCase())) {
        continue;
      }
      if (property.ValueTypeId in property_renderers) {
        const categorizedItem: CategorizedItem = {
          Id: `property-${property.PropertyId}`,
          CategoryTypeId: property.CategoryTypeId || null,
          Content: <PropertyEditor siteProperties={siteProperties} property={property} 
            updatePage={refreshPage} propertyUpdated={propertyUpdated} updateLastPropertyUpdatedDate={updateLastPropertyUpdatedDate} page={page} 
            blockMapping={blockMapping} populatedDataSources={populatedDataSources}
            isPageEditor={true} navigateToProperty={navigateToProperty} pageList={pageList} />
        }
        items.push(categorizedItem);
      }
    }
  }

  let i = 0;
  for (const extra of extraContent) {
    const categorizedItem: CategorizedItem = {
      Id: `$extraContent-${i}}`,
      CategoryTypeId: extra.CategoryTypeId,
      Content: extra.Content
    }
    items.push(categorizedItem);
    i++;
  }

  return (
    <CategorizedList
      items={items}
      categories={categories}
      expandCategories={expandCategories}
    />
  );
}