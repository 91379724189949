import CMSIds from "../../../types/enum/CMSIds";
import Preview from "../../common/Preview";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./ReviewStep.scss";
import { ErrorAlertMessage } from "../../common/AlertMessage";
import Site from "../../../types/Site";
import Page from "../../../types/Page";

export interface ReviewStepProps {
    site: Site;
    templateData: Page | null;
    PreviewBlockId: string | null;
}

function ReviewStep({ site, templateData, PreviewBlockId }: ReviewStepProps) {
    const usesSalesForce = site.CmsIds.includes(CMSIds.RevLocal)
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))
    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Review</h1>
                <p className="body1">
                    Use the preview {isMobile ? "below" : "on the right"} to
                    review the website.
                </p>
            </div>
            {usesSalesForce && <div className="wizard-step-section">
                <ErrorAlertMessage filled={false}
                    message={"Some content in the preview is driven from"
                        + " Strategy Center and can be updated there."}/>
            </div>}
            {isMobile && templateData && <div className="wizard-review-step-preview">
                <Preview page={templateData} PreviewBlockId={PreviewBlockId} />
            </div>}
        </>
    );
}

export default ReviewStep;
