import { useEffect } from 'react';
import { Editor as AnchorConfigProperty } from '../../property_editors/AnchorConfigProperty'
import { Editor as ImageProperty } from '../../property_editors/ImageProperty';
import { Editor as ScheduleProperty } from '../../property_editors/ScheduleProperty';
import PropertyId from '../../../types/enum/PropertyId';
import BlockId from '../../../types/enum/BlockId';
import Box from '@mui/material/Box';
import ScheduleProps from '../../../types/ScheduleProps';
import WizardStepProps from '../../../types/WizardStepProps';
import Property from '../../../types/Property';
import { Editor as TextEditorProperty } from '../../property_editors/TextEditorProperty';
import { getImageUrlWithPlaceholder } from '../../../utils/blockHelpers';

function PromotionStep({ blockmapping, site, templateData, setTemplateData, setError, updatePreviewBlockId }: WizardStepProps) {
    
    const imageBlock = blockmapping?.BlockMappings.find(b => b.EnumId === BlockId.Image);
    const imageProperty = imageBlock?.Properties.find(p => p.EnumId === PropertyId.ImageSource);

    const titleProperty = blockmapping?.Properties.find(p => p.EnumId === PropertyId.Title);
    const bodyProperty = blockmapping?.Properties.find(p => p.EnumId === PropertyId.ContentTextArea);
    
    const anchorBlock = blockmapping?.BlockMappings.find(b => b.EnumId === BlockId.Anchor);
    const btnContent = anchorBlock?.Properties.find(p => p.EnumId === PropertyId.Content);
    const btnConfig = anchorBlock?.Properties.find(p => p.EnumId === PropertyId.AnchorConfig);
    
    const scheduleProperty = blockmapping?.Properties.find(p => p.EnumId === PropertyId.Schedule);

    const imageLayoutProperty = blockmapping?.Properties.find(p => p.EnumId === PropertyId.ImageLayout);
    const buttonAlignmentProperty = blockmapping?.Properties.find(p => p.EnumId === PropertyId.ButtonAlignment);
    const textAlignmentProperty = blockmapping?.Properties.find(p => p.EnumId === PropertyId.TextAlignment);

    const scheduleProps = {
        startLabel: 'Start date',
        endLabel: 'End date',
        startHint: 'The card will appear at 12:01 AM EST on this day.',
        endHint: 'The card will hide at 12:01 AM EST on this day.'
    } as ScheduleProps;

    useEffect(() => {
        if (updatePreviewBlockId) {
            updatePreviewBlockId(blockmapping?.Id);
        }
    });

    const propertyUpdatedInWizard = (property: Property) => {
        if (setTemplateData) {
            property.ValueUpdatedInWizard = true;
            setTemplateData({...templateData});
        }
    };

    const imagePropertyUpdatedInWizard = (property?:Property, image?: any) => {
        if (setTemplateData) {
            if (property) {
                property.ValueUpdatedInWizard = true;
            }

            if (image && imageLayoutProperty) {
                let imageLayout = (image?.ImageWidth > image?.ImageHeight ? 'l' : null);
                imageLayoutProperty.ValueUpdatedInWizard = true;
                imageLayoutProperty.Value = imageLayout;
                imageLayoutProperty.RenderedValue = imageLayout;
            }
            
            const imageObject = JSON.parse(property?.Value || '{}');
            const imageUrl = getImageUrlWithPlaceholder(imageObject);

            if (buttonAlignmentProperty) {
                const btnAlignValue = imageUrl ? null : 'c';
                buttonAlignmentProperty.ValueUpdatedInWizard = true;
                buttonAlignmentProperty.Value = btnAlignValue;
                buttonAlignmentProperty.RenderedValue = btnAlignValue;
            }
            
            if (textAlignmentProperty) {
                const txtAlignValue = imageUrl ? null : 'c';
                textAlignmentProperty.ValueUpdatedInWizard = true;
                textAlignmentProperty.Value = txtAlignValue;
                textAlignmentProperty.RenderedValue = txtAlignValue;
            }
            
            setTemplateData({...templateData});
        }
    }

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Promotion Card</h1>
                <p className="body1">Use the promotion card to bring attention to a sale, product, or callout important information.</p>
            </div>

            <div className="wizard-step-section">
                {imageProperty && 
                    <Box sx={{ marginBottom: "32px" }}>
                        <ImageProperty siteId={site.Id} property={imageProperty}
                            propertyUpdated={imagePropertyUpdatedInWizard} 
                            setError={setError} renderOptions={{}}
                            isPageEditor={false} pageList={[]}
                        />
                    </Box>
                }
                
                {titleProperty && <TextEditorProperty property={titleProperty}
                    propertyUpdated={propertyUpdatedInWizard} label="Title" isPageEditor={false} pageList={[]} />}
                    
                {bodyProperty && <Box sx={{ marginTop: '40px' }} >
                    <TextEditorProperty
                        property={bodyProperty} propertyUpdated={propertyUpdatedInWizard}
                        isPageEditor={false} label="Body text" pageList={[]} />
                </Box>}

                {anchorBlock && btnContent && btnConfig && <>
                    <Box sx={{ marginTop: '40px' }} >
                        <TextEditorProperty
                            property={btnContent} isPageEditor={false}
                            propertyUpdated={propertyUpdatedInWizard} label="Button text" pageList={[]} />
                    </Box>
                    <Box sx={{ marginTop: '40px' }}>
                        <AnchorConfigProperty property={btnConfig} isPageEditor={false}
                            propertyUpdated={propertyUpdatedInWizard} page={templateData} pageList={[]} />
                    </Box>
                </>}
                
                {scheduleProperty && <Box sx={{ marginTop: '40px' }}>
                    <h4>Display schedule</h4>
                    <p className="body2">Specifying a start and end date will display this card only within that timeframe.</p>
                    <ScheduleProperty property={scheduleProperty} isPageEditor={false}
                        propertyUpdated={propertyUpdatedInWizard}
                        scheduleProps={scheduleProps} pageList={[]} />
                </Box>}
            </div>
        </>
    );
}

export default PromotionStep;