import { useState, MouseEvent } from "react";
import { Backdrop, Divider, Paper, Popper } from "@mui/material";
import SiteEditorStore from "../../SiteEditorStore";
import EditComponentNameModal from "../hierarchy/EditComponentNameModal";
import BlockTemplate from "../../../../types/BlockTemplate";
import useApiPutCallbackAsync from "../../../../hooks/useApiPutCallbackAsync";
import Loading from "../../../common/Loading";
import ErrorMessage from "../../../common/ErrorMessage";
import DeleteComponentModal from "../hierarchy/DeleteComponentModal";

interface ComponentContextMenuProps {
    component: BlockTemplate,
    contextMenuRef: React.RefObject<HTMLDivElement | null>,
    closeMenu: () => void
}

export default function ComponentContextMenu({
    component,
    contextMenuRef,
    closeMenu
}: ComponentContextMenuProps) {
    const [showRename, setShowRename] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const refreshBlocksAndSections = SiteEditorStore.useStoreActions((actions) => actions.refreshBlocksAndSections);
    const refreshPage = SiteEditorStore.useStoreActions((actions) => actions.refreshPage);

    const [{ isLoading: renameLoading, isError: renameError, errorResponse: renameErrorResponse }, renameCallback]
        = useApiPutCallbackAsync('') as any;

    const rename = (componentName: string) => {
        renameCallback({
                Name: componentName
            }
            , () => {
                setShowRename(false);
                refreshBlocksAndSections();
                refreshPage();
                closeMenu();
            }
            , null
            , `/sites/${site?.Id}/components/${component.ci}`
        );
    };

    if (!component?.ci) {
        return null;
    }

    return <>
        <Backdrop
            open={true}
            onClick={(e) => {
                e.stopPropagation();
                closeMenu();
            }}
            style={{
                opacity: 0,
                zIndex: 1200,
                cursor: "initial"
            }}
            >
            <Popper
                id={"component-context-popper"}
                open={ true }
                anchorEl={contextMenuRef?.current}
                placement={'bottom-start'}
                style={{ zIndex: 1300 }}
                onClick={ (e) => { e.stopPropagation(); } }
            >
                <Paper
                    elevation={4}
                    sx={{
                        padding: 2,
                        lineHeight: 1.5,
                        width: 250
                    }}
                >
                    <p
                        id={`component-context-rename-button-${component.ci}`}
                        data-testid="component-context-rename-button"
                        onClick={() => { setShowRename(true); }}
                        style={{ cursor:"pointer" }}
                    >
                        Rename
                    </p>
                    <Divider />

                    <p
                        id={`component-context-delete-button-${component.ci}`}
                        data-testid="component-context-delete-button"
                        onClick={() => { setShowDelete(true); }}
                        style={{ cursor:"pointer", color: "rgb(207, 31, 46)" }}
                    >
                        Delete Across Site
                    </p>
                </Paper>
            </Popper>
        </Backdrop>
        { showRename &&
            <EditComponentNameModal
                title="Rename Component"
                actionName="Rename"
                currentName={component.n}
                action={(e: MouseEvent<HTMLButtonElement>, componentName: string) => { e.stopPropagation(); rename(componentName); }}
                onClose={(e: MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); setShowRename(false); } }
            >
                { renameLoading && <Loading /> }
                <ErrorMessage message="Error renaming component" apiError={renameErrorResponse} visible={renameError} />
            </EditComponentNameModal>
        }
        { showDelete &&
            <DeleteComponentModal componentId={component.ci} onClose={() => { setShowDelete(false); closeMenu(); }} />
        }
    </>
}