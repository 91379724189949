import React, { useState, useEffect } from 'react';
import { CustomPicker } from 'react-color';
import { Box, TextField, OutlinedInput, FormControl, InputLabel, InputAdornment, ButtonGroup, Button } from "@mui/material";
import './ColorPicker.css';
const { Saturation, Hue } = require('react-color/lib/components/common');
const tinycolor = require("tinycolor2");

const inlineStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: "644px",
  },
  pointer: {
    width: '11px',
    height: '11px',
    borderRadius: '50%',
    transform: 'translate(-9px, -1px)',
    border: "3px solid black"
  },
  slider: {
    marginTop: '1px',
    borderRadius: '50px',
    height: '26px',
    width: '26px',
    transform: 'translate(-12px, -2px)',
    border: "3px solid white"
  },
  saturation: {
    width: '100%',
    height: "215px",
    marginTop: "32px",
    position: 'relative' as 'relative',
    overflow: 'hidden',
  }
}

const CustomSlider = () => {
    return (
        <div style={ inlineStyles.slider } />
    );
}

const CustomPointer = () => {
    return (
        <div style={ inlineStyles.pointer } />
    );
}

const ColorThemeBox = (name: string, theme: any, onClick: any) => {
    const tinyColorObj = tinycolor(theme.hex);
    const rgbValues = tinyColorObj.toRgb();

    return (
        <Box 
            sx={{ 
                display: "flex", 
                padding: "8px", 
                alignItems: "center"
            }}
            onClick={() => onClick(theme.property)}
        >
            <Box
                sx={{ 
                    display: "flex", 
                    padding: "8px", 
                    alignItems: "center", 
                    cursor: "pointer",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px"
                }}
            >
                <Box sx={{ 
                    width: "24px", 
                    height: "24px", 
                    border: "1px solid #292929", 
                    backgroundColor: `rgba(${rgbValues.r}, ${rgbValues.g}, ${rgbValues.b}, ${theme.alpha || 1})` 
                }}>
                </Box>
                <Box className="button-typography" sx={{ marginLeft: '8px', color: "#292929" }}>
                    {name}
                </Box>
            </Box>
        </Box>
    );
}

const getPickerStateFromTinyColor = (color: any, hsl: any) => {
    return {
        hsv: hsl ? { h: hsl.h, s: hsl.s, v: hsl.l } : color.toHsv(),
        hsl: hsl ? hsl : color.toHsl(),
        hex: color.toHex(),
        rgb: color.toRgb()
    };
};

const getPickerState = (hexCode: string, hsl: any) => {
    const color = tinycolor(hexCode);
    return getPickerStateFromTinyColor(color, hsl);
};

function ColorPicker({ hexCode, hslObj, alpha, type, colorChanged, colorTheme, themeSelected }: any) {
    const [pickerState, setPickerState] = useState(getPickerState(hexCode, hslObj));
    const [hexInputColor, setHexInputColor] = useState(hexCode?.toUpperCase());
    const [hslValues, setHslValues] = useState<{
        h: string;
        s: string;
        l: string;
    }>({ h: hslObj.h.toString(), s: (hslObj.s * 100).toString(), l: (hslObj.l * 100).toString() });
    const [inputType, setInputType] = useState(type ?? 'hex');
    const [alphaValue, setAlphaValue] = useState<string>(alpha ? (alpha * 100).toString() : '100');
    const [valuesUpdated, setValuesUpdated] = useState(false);

    const reportOnChange = (pickerState: any, updatedInputType: string, updatedAlpha: string) => {
        const alphaDecimal = parseFloat(updatedAlpha) > 0 ? parseFloat(updatedAlpha) / 100 : updatedAlpha;
        colorChanged(pickerState, updatedInputType, alphaDecimal);
    };

    useEffect(() => {
        setHexInputColor('#' + pickerState.hex.toUpperCase());
        const updatedHslValues = {
            h: Math.round(pickerState.hsl.h).toString(),
            s: Math.round(pickerState.hsl.s * 100).toString(),
            l: Math.round(pickerState.hsl.l * 100).toString()
        };
        setHslValues(updatedHslValues);

        let timeOutId: NodeJS.Timer;
        // Check values updated so we don't do this on the first set/change of pickerState and alphaValue
        if (pickerState && valuesUpdated) {
            timeOutId = setTimeout(() => {
                setValuesUpdated(false);
                reportOnChange(pickerState, inputType, alphaValue);
            }, 500);
        }
        return () => timeOutId && clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickerState, alphaValue]);

    const changeInputType = (type: string) => {
        setValuesUpdated(true);
        setInputType(type);
        reportOnChange(pickerState, type, alphaValue);
    };

    const handleHueChange = (hue: any) => {
        setValuesUpdated(true);
        const color = tinycolor(hue);
        setPickerState(getPickerStateFromTinyColor(color, hue));
    }

    const handleSaturationChange = (hsv: any) => {
        setValuesUpdated(true);
        const color = tinycolor(hsv);
        setPickerState(getPickerStateFromTinyColor(color, { h: hsv.h, s: hsv.s, l: hsv.v }));
    }

    const updateHexValue = (hexCode: string) => {
        setValuesUpdated(true);
        const color = tinycolor(hexCode);
        const hslValue = color.toHsl();
        const newPickerState = getPickerStateFromTinyColor(color
            , { h: hslValue.h === 0 ? pickerState.hsl.h : hslValue.h, s: hslValue.s, l: hslValue.l });
        setPickerState(newPickerState);
    };

    const rgbChanged = (r: string, g: string, b: string) => {
        pickerState.rgb.r = r;
        pickerState.rgb.g = g;
        pickerState.rgb.b = b;

        if (pickerState.rgb.r !== '' && pickerState.rgb.g !== '' && pickerState.rgb.b !== '') {
            setValuesUpdated(true);
            const color = tinycolor({r, g, b});
            const hslValue = color.toHsl();
            setPickerState(getPickerStateFromTinyColor(color
                , { h: hslValue.h === 0 ? pickerState.hsl.h : hslValue.h, s: hslValue.s, l: hslValue.l }));
        } else {
            setPickerState({...pickerState});
        }
    };

    const onBlur = () => {
        if (!valuesUpdated) {
            setPickerState(getPickerState(hexCode, hslObj));
        }
    };

    const hslChanged = (h: string, s: string, l: string) => {
        if (h === '360') {
            h = '0';
        } 
        else if (parseInt(h) > 360) {
            h = '359';
        }
        else if (h === '-1') {
            h = '359';
        } else if (parseInt(h) < 0) {
            h = '0';
        }

        if (parseInt(s) > 100) {
            s = '100';
        } else if (parseInt(s) < 0) {
            s = '0';
        }

        if (parseInt(l) > 100) {
            l = '100';
        } else if (parseInt(l) < 0) {
            l = '0';
        }

        const updatedHslValues = {h, s, l};
        setHslValues(updatedHslValues);

        if (h !== '' && s !== '' && l !== '') {
            setValuesUpdated(true);
            const color = tinycolor({
                h: parseInt(h), 
                s: parseInt(s) / 100, 
                l: parseInt(l) / 100
            });
            setPickerState(getPickerStateFromTinyColor(color, {
                h: Number(updatedHslValues.h),
                s: Number(updatedHslValues.s) / 100,
                l: Number(updatedHslValues.l) / 100
            }));
        }
    };

    const hslOnBlur = () => {
        if (hslValues.h === '' || hslValues.s === '' || hslValues.l === '') {
            hslChanged(hslValues.h === '' ? '0' : hslValues.h
                , hslValues.s === '' ? '0' : hslValues.s
                , hslValues.l === '' ? '0' : hslValues.l);
        } else {
            onBlur();
        }
    };

    const hexInputColorChanged = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLTextAreaElement;
        let value = target.value?.toUpperCase();
        setHexInputColor(value);

        let valid = /^#?([0-9A-F]{6})$/i.test(value);
        if (valid) {
            if (!value.startsWith('#')) {
                value = '#' + value;
            }

            updateHexValue(value);
        }
    };
    const resetHexInputColor = () => {
        setHexInputColor('#' + pickerState.hex?.toUpperCase());
    };

    const onAlphaChange = (value: string) => {
        if (value !== '') {
            let numberValue = Math.round(Number(value));
            if (numberValue > 100) {
                numberValue = 100;
            } else if (numberValue < 0) {
                numberValue = 0;
            }
            setAlphaValue(numberValue.toString());
            setValuesUpdated(true);
        } else {
            setAlphaValue(value);
        }
    };

    const onAlphaBlur = () => {
        if (alphaValue === '') 
        { 
            setAlphaValue('0');
            setValuesUpdated(true);
        }
    };

    return (
        <div style={ inlineStyles.container }>
            <div style={ inlineStyles.saturation }>
              <Saturation
                hsl={ pickerState.hsl }
                hsv={ pickerState.hsv }
                pointer={ CustomPointer }
                onChange={ handleSaturationChange }
              />
            </div>
            <div style={{ height: "30px", borderRadius: '10px', position: 'relative', margin: "32px 64px 32px 64px" }}>
              <Hue
                hsl={pickerState.hsl}
                pointer={ CustomSlider }
                onChange={ handleHueChange }
                direction={'horizontal'}
              />
            </div>
            { colorTheme && 
                <Box sx={{ marginBottom: "32px", display: "flex", flexDirection: "row", width: "100%" }}>
                    <Box sx={{ flex: 1 }}>
                        {ColorThemeBox(colorTheme.primary.color.property.Name, colorTheme.primary.color, themeSelected)}
                        {ColorThemeBox(colorTheme.primary.content.property.Name, colorTheme.primary.content, themeSelected)}
                        {ColorThemeBox(colorTheme.primary.focus.property.Name, colorTheme.primary.focus, themeSelected)}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        {ColorThemeBox(colorTheme.secondary.color.property.Name, colorTheme.secondary.color, themeSelected)}
                        {ColorThemeBox(colorTheme.secondary.content.property.Name, colorTheme.secondary.content, themeSelected)}
                        {ColorThemeBox(colorTheme.secondary.focus.property.Name, colorTheme.secondary.focus, themeSelected)}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        {ColorThemeBox(colorTheme.accent.color.property.Name, colorTheme.accent.color, themeSelected)}
                        {ColorThemeBox(colorTheme.accent.content.property.Name, colorTheme.accent.content, themeSelected)}
                        {ColorThemeBox(colorTheme.accent.focus.property.Name, colorTheme.accent.focus, themeSelected)}
                    </Box>
                </Box>
            }
            <div style={{ display: 'flex', alignItems: 'center', margin: '2px 0', userSelect: 'none' }}>
                { inputType === 'hex' &&
                    <TextField
                        type="text"
                        label="HEX VALUE"
                        value={hexInputColor}
                        fullWidth
                        onChange={hexInputColorChanged}
                        onBlur={resetHexInputColor}
                    />
                }
                { inputType === 'rgb' &&
                    <Box sx={{ width: "100%", display: 'flex', userSelect: 'none' }}>
                        <TextField
                            type="number"
                            label="RED"
                            sx={{ flex: 1 }}
                            value={pickerState.rgb.r}
                            onChange={(e: React.SyntheticEvent) => {
                                rgbChanged((e.target as HTMLInputElement).value, pickerState.rgb.g, pickerState.rgb.b)
                            }}
                            inputProps={{ 
                                min: 0, 
                                max: 255
                            }}
                            onBlur={onBlur}
                        />
                        <TextField
                            type="number"
                            label="GREEN"
                            sx={{ marginLeft: '16px', flex: 1, userSelect: 'none' }}
                            value={pickerState.rgb.g}
                            onChange={(e: React.SyntheticEvent) => {
                                rgbChanged(pickerState.rgb.r, (e.target as HTMLInputElement).value, pickerState.rgb.b)
                            }}
                            inputProps={{ 
                                min: 0, 
                                max: 255
                            }}
                            onBlur={onBlur}
                        />
                        <TextField
                            type="number"
                            label="BLUE"
                            sx={{ marginLeft: '16px', flex: 1, userSelect: 'none' }}
                            value={pickerState.rgb.b}
                            onChange={(e: React.SyntheticEvent) => {
                                rgbChanged(pickerState.rgb.r, pickerState.rgb.g, (e.target as HTMLInputElement).value)
                            }}
                            inputProps={{ 
                                min: 0, 
                                max: 255
                            }}
                            onBlur={onBlur}
                        />
                    </Box>
                }
                { inputType === 'hsl' &&
                    <Box sx={{ width: "100%", display: 'flex' }}>
                        <TextField
                            type="number"
                            label="HUE"
                            sx={{ flex: 1, userSelect: 'none' }}
                            value={hslValues.h}
                            onChange={(e: React.SyntheticEvent) => {
                                hslChanged((e.target as HTMLInputElement).value, hslValues.s, hslValues.l)
                            }}
                            inputProps={{ 
                                min: -1, 
                                max: 360
                            }}
                            onBlur={hslOnBlur}
                        />
                        <FormControl sx={{ marginLeft: '16px', flex: 1, userSelect: 'none' }}>
                            <InputLabel htmlFor="color-picker-saturation">SATURATION</InputLabel>
                            <OutlinedInput
                                type="number"
                                id="color-picker-saturation"
                                label="SATURATION"
                                value={hslValues.s}
                                onChange={(e: React.SyntheticEvent) => {
                                    hslChanged(hslValues.h, (e.target as HTMLInputElement).value, hslValues.l)
                                }}
                                inputProps={{ 
                                    min: 0, 
                                    max: 100
                                }}
                                onBlur={hslOnBlur}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        </FormControl>
                        <FormControl sx={{ marginLeft: '16px', flex: 1, userSelect: 'none' }}>
                            <InputLabel htmlFor="color-picker-lightness">LIGHTNESS</InputLabel>
                            <OutlinedInput
                                id="color-picker-lightness"
                                type="number"
                                label="LIGHTNESS"
                                value={hslValues.l}
                                onChange={(e: React.SyntheticEvent) => {
                                    hslChanged(hslValues.h, hslValues.s, (e.target as HTMLInputElement).value)
                                }}
                                inputProps={{ 
                                    min: 0, 
                                    max: 100
                                }}
                                onBlur={hslOnBlur}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        </FormControl>
                    </Box>
                }
                <Box sx={{ width: "94px", minWidth: "94px", marginLeft: "16px", userSelect: 'none' }}>
                    <FormControl>
                        <InputLabel htmlFor="color-picker-opacity">OPACITY</InputLabel>
                        <OutlinedInput
                            id="color-picker-opacity"
                            label="OPACITY"
                            inputProps={{ 
                                min: 0, 
                                max: 100
                            }}
                            type="number"
                            value={alphaValue}
                            fullWidth
                            onChange={(e: React.SyntheticEvent) => { onAlphaChange((e.target as HTMLInputElement).value)}}
                            onBlur={onAlphaBlur}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                          />
                    </FormControl>
                </Box>
                <ButtonGroup sx={{ marginLeft: "16px" }}>
                    <Button 
                        onClick={() => changeInputType('rgb')}
                        variant="text" 
                        className="subtitle1"
                        sx={{ 
                            height: "56px", 
                            width: "61px",
                            border: "1px solid #E0E0E0", 
                            borderRadius: "4px 0px 0px 4px", 
                            color: "#000000",
                            backgroundColor: inputType === 'rgb' ? 'rgba(98, 0, 238, 0.04)' : undefined,
                            '&:hover': {
                                border: "1px solid #E0E0E0 !important", 
                            }
                        }}
                    >
                            RGB
                    </Button>
                    <Button 
                        onClick={() => changeInputType('hex')}
                        variant="text" 
                        className="subtitle1"
                        sx={{ 
                            height: "56px", 
                            width: "61px",
                            border: "1px solid #E0E0E0", 
                            color: "#000000",
                            backgroundColor: inputType === 'hex' ? 'rgba(98, 0, 238, 0.04)' : undefined,
                            '&:hover': {
                                border: "1px solid #E0E0E0 !important", 
                            }
                        }}
                    >
                        HEX
                    </Button>
                    <Button 
                        onClick={() => changeInputType('hsl')}
                        variant="text" 
                        className="subtitle1"
                        sx={{ 
                            height: "56px", 
                            width: "61px",
                            border: "1px solid #E0E0E0", 
                            borderRadius: "0px 4px 4px 0px", 
                            color: "#000000",
                            backgroundColor: inputType === 'hsl' ? 'rgba(98, 0, 238, 0.04)' : undefined,
                            '&:hover': {
                                border: "1px solid #E0E0E0 !important", 
                            }
                        }}
                    >
                        HSL
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default CustomPicker(ColorPicker);
