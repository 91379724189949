import React, { useState, useEffect, useRef } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import './FontProperty.css';
import Property from "../../types/Property";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import Menu from "@mui/material/Menu";
import PropertyId from '../../types/enum/PropertyId';
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import BuilderDialog from "../common/BuilderDialog";
import BuilderStore from "../../BuilderStore";
import { PropertyNameLabel } from "../common/PropertyNameLabel";

function formatWeightName(weight: number) {
    return getWeightName(weight) + " (" + weight + ")";
}

function getWeightName(weight: number) {
    switch (weight) {
        case 100: return 'Thin';
        case 200: return 'Extra Light';
        case 300: return 'Light';
        case 400: return 'Normal';
        case 500: return 'Medium';
        case 600: return 'Semi Bold';
        case 700: return 'Bold';
        case 800: return 'Extra Bold';
        case 900: return 'Black';
        case 950: return 'Extra Black';
        default: return weight;
    }
};

export interface Font {
    family: string;
    category: string;
    weight: number;
}

export interface FontSelectionProps {
    previewText: string,
    font: Font,
    isLast: boolean,
    onClick: (family: string, weight: number) => void
}

function FontSelection({ previewText, font, onClick, isLast }: FontSelectionProps) {
    return (
        <>
            <link key={font.family} href={`https://fonts.googleapis.com/css2?family=${encodeURI(font.family)}:wght@${font.weight}&display=swap`} rel="stylesheet"></link>
            <Box
                sx={{
                    height: '50px',
                    cursor: 'pointer',
                    padding: '16px 0 16px 0',
                    '&:hover': {
                        background: 'hsla(0, 0%, 13%, 0.08)',
                        backgroundOpacity: 0.2
                    }
                }}
                onClick={() => onClick(font.family, font.weight)}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div>
                        <div className="caption">{font.family} - {formatWeightName(font.weight)}</div>
                        <div style={{
                            fontFamily: font.family,
                            fontWeight: font.weight,
                            fontSize: '22px',
                            marginTop: '8px'
                        }}>
                            {previewText}
                        </div>
                    </div>
                    <ArrowRightIcon color="secondary"/>
                </div>
            </Box>
            {isLast || <Divider />}
        </>
    );
}

export function Editor({
    siteProperties, property, propertyUpdated, text, helpText, isPageEditor
}: PropertyEditorProps) {

    const font = property?.RenderedValue
        ? JSON.parse(property.RenderedValue)
        : null;
    const configuration = JSON.parse(property?.Configuration || '{}');

    const setFont = (fontConfig: { f: string, w: number} | null) => {
        const fontValue = fontConfig ? JSON.stringify(fontConfig) : null;
        if (property) {
            property.RenderedValue = fontValue;
            property.Value = fontValue;
            propertyUpdated(property);
        }
    };

    const family = font?.f;
    const weight = font?.w;

    const primaryFontProperty = siteProperties?.find(p => p.EnumId === PropertyId.PrimaryFont);
    const secondaryFontProperty = siteProperties?.find(p => p.EnumId === PropertyId.SecondaryFont);
    const primaryFont = JSON.parse(primaryFontProperty?.RenderedValue || '{}');
    const secondaryFont = JSON.parse(secondaryFontProperty?.RenderedValue || '{}');

    const [open, setOpen] = useState(false);
    const [selectedFamily, setSelectedFamily] = useState(font?.f);
    const [selectedWeight, setSelectedWeight] = useState(font?.w);
    const [families, setFamilies] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [weights, setWeights] = useState<any[]>([]);
    const [previewText, setPreviewText] = useState('The brown fox jumped over the lazy pig');
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [selectedFilterWeight, setSelectedFilterWeight] = useState("all");
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [allFontVariants, setAllFontVariants] = useState<any[]>([]);
    const [scrollFonts, setScrollFonts] = useState<any[] | null>([]);

    const googleFonts = BuilderStore.useStoreState((state) => state.googleFonts);

    const handleSearchCategory = (category: string) => {
        setSelectedCategory(category);
        const searchResults = getSearchResults(searchText, category, selectedFilterWeight);
        setScrollFonts(null);
        setSearchResults(searchResults);
    }

    const handleSearchText = (text: string) => {
        setSearchText(text);
        const searchResults = getSearchResults(text, selectedCategory, selectedFilterWeight);
        setScrollFonts(null);
        setSearchResults(searchResults);
    }

    const handleSearchWeight = (weight: string) => {
        setSelectedFilterWeight(weight);
        const searchResults = getSearchResults(searchText, selectedCategory, weight);
        setScrollFonts(null);
        setSearchResults(searchResults);
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const textInputField = useRef();

    useEffect(() => {
        if (families) {
            const allVariants: Font[] = [];
            const allWeights: number[] = [];
            for (let family of families) {
                for (let weight of family.weights) {
                    if (!allWeights.includes(weight)) {
                        allWeights.push(weight);
                    }
                    allVariants.push({
                        family: family.value,
                        category: family.category,
                        weight
                    });
                }
            }
            setAllFontVariants(allVariants);
            setSearchResults(allVariants);
            setWeights(allWeights.sort());
        }
    }, [families]);

    useEffect(() => {
        if (searchResults) {
            setScrollFonts(searchResults.slice(0, 20));
        }
    }, [searchResults])

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenu(true);
    }

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const handleClickOpen = () => {
        setSelectedFamily(family);
        setSelectedWeight(weight);
        setSelectedFilterWeight("all");
        setSelectedCategory("all");
        setSearchText("");
        setPreviewText('The brown fox jumped over the lazy pig');
        setScrollFonts(null);
        setSearchResults(getSearchResults("", "all", "all"));
        setOpen(true);
        handleCloseMenu();
    };

    const setFamily = (event: React.SyntheticEvent, value:
        { label: string, value: string }) => {
        setSelectedFamily(value.value);
    };

    const setWeight = (event: React.SyntheticEvent, value:
        { label: string, value: number }) => {
        setSelectedWeight(value.value);
    };

    const handleConfirm = (fam: string, wght: number) => {
        setFont({ f: fam, w: wght });
        setOpen(false);
    };

    const loadMoreScrollFonts = () => {
        if (scrollFonts) {
            const additionalFonts = searchResults.slice(scrollFonts.length, scrollFonts.length + 20);
            const newScrollFonts = [...scrollFonts, ...additionalFonts];
            setScrollFonts(newScrollFonts);
        }
    };

    const changePreviewText = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLTextAreaElement
        setPreviewText(target.value);
    };

    const getSearchResults = (searchText: string, category: string, weight: string): any[] => {
        return allFontVariants && allFontVariants.filter((font : { category: string, family: string, weight: number }) => {
                return (category === "all" || font.category === category)
                    && (weight === "all" || font.weight.toString() === weight)
                    && (!searchText || font.family.toLowerCase().match("^" + searchText?.toLowerCase()));
        });
    }

    if (googleFonts && googleFonts.length > 0 && families.length === 0) {
        const categories: string[] = [];
        for (const font of googleFonts) {
            if (!categories.includes(font.category)) {
                categories.push(font.category);
            }
        }
        setCategories(categories);

        const newFamilies = [];
        for (let font of googleFonts) {
            const familyWeights = [];

            for (let weight of font.variants) {
                if (weight === 'regular') {
                    familyWeights.push(400);
                }
                else {
                    const parsedweight = parseInt(weight);
                    if (!isNaN(parsedweight)) {
                        familyWeights.push(parsedweight);
                    }
                }
            }

            newFamilies.push({
                label: font.family,
                value: font.family,
                category: font.category,
                weights: familyWeights
            });
        }
        setFamilies(newFamilies);
    }

    const selectedFamilyWeights = [];
    const selectedFamilyConfig = families.find(f => f.value === selectedFamily);

    if (selectedFamilyConfig) {
        for (let weight of selectedFamilyConfig.weights) {
            selectedFamilyWeights.push({
                label: `${getWeightName(weight)} - ${weight}`,
                value: weight
            })
        }
    }

    const familyValue = families.find(f => f.value === selectedFamily);
    let weightValue = selectedFamilyWeights.find(w => w.value === selectedWeight);

    if (!weightValue && selectedFamilyWeights.length > 0) {
        let weightValue = selectedFamilyWeights.find(w => w.value === 400);

        if (!weightValue) {
            weightValue = selectedFamilyWeights[0];
        }

        setSelectedWeight(weightValue.value);
    }

    const addFont = () => {
        setFont({ f: "Open Sans", w: 400 });
        setOpenMenu(true);
    };

    const deleteFont = () => {
        setFont(null);
    };

    const selectTheme = (themeProperty?: Property) => {
        if (property) {
            property.RenderedValue = themeProperty?.RenderedValue;
            property.Value = `[P:${themeProperty?.PropertyId}]`;
            propertyUpdated(property);
            handleCloseMenu();
        }
    };

    const linkedToThemeProperty = property?.Value?.startsWith('[P:');
    let themeName = '';

    if (linkedToThemeProperty) {
        const linkedPropertyId = property?.Value?.replace('[P:', '').replace(']', '');
        const linkedProperty = siteProperties?.find(p => p.PropertyId === linkedPropertyId);
        themeName = linkedProperty?.Name || '';
    }

    if (!isPageEditor) {
        return (<>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            {familyValue && weightValue &&
                <link href={`https://fonts.googleapis.com/css2?family=${encodeURI(familyValue.value)}:wght@${weightValue.value}&display=swap`} rel="stylesheet"></link>
            }
            <div style={{ position: 'relative', maxWidth: '581px', marginBottom: '32px' }}>
                <div style={{ height: '56px', width: '56px', display: 'inline-block', fontFamily: family, fontWeight: weight, lineHeight: '56px', fontSize: '40px', overflow: 'hidden' }}>Aa</div>
                <span style={{ position: 'absolute', left: '80px', top: '4px'}}>
                    <span className="caption">{text}</span>
                    <Box className="caption" sx={{ display: { xs: 'none', sm: 'none', md: 'inline'}}} > - {helpText}</Box>
                    <br />
                    {family && weight && <span className="body1">{family} - {formatWeightName(weight)}</span>}
                </span>
                <span>
                    <Button className="font-edit-button" sx={{ top: fullScreen ? '-8px' : '0px', marginTop: '4px', float: 'right' }} onClick={handleClickOpen}>
                        <EditIcon /> Edit
                    </Button>
                </span>
                <BuilderDialog
                    title={"Customize font"}
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="responsive-dialog-title"
                    actionName="Save"
                    action={() => handleConfirm(selectedFamily, selectedWeight)}
                    showCancel={true}
                >
                    <DialogContent>
                        {googleFonts &&
                            <Box>
                                <Box sx={{ margin: '0 auto', marginBottom: '30px', maxWidth: '350px' }}>
                                    <TextField
                                        autoComplete='off'
                                        variant="standard"
                                        value={previewText}
                                        onChange={changePreviewText}
                                        InputProps={{ inputProps: { style: { textAlign: "center", fontFamily: selectedFamily, fontWeight: selectedWeight, fontSize: '34px' }} }}
                                        sx={{ width: '100%' }} />
                                </Box>
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-demo"
                                    options={families}
                                    sx={{ width: '100%' }}
                                    value={familyValue}
                                    onChange={setFamily}
                                    renderInput={(params) => <TextField {...params} label="Font Family" />}
                                />
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-demo"
                                    options={selectedFamilyWeights}
                                    sx={{ width: '100%', marginTop: '50px' }}
                                    value={weightValue}
                                    onChange={setWeight}
                                    renderInput={(params) => <TextField {...params} label="Font Weight" />}
                                />
                            </Box>
                        }
                    </DialogContent>
                </BuilderDialog>
            </div>
        </>);
    }
    else {
        return (
            <>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PropertyNameLabel 
                        property={property}
                    ></PropertyNameLabel>
                    { !family &&
                        <Box>
                            <AddCircleOutlineIcon sx={{
                                cursor: "pointer",
                                fontSize: "26.6px",
                                marginLeft: "16px",
                                float: "right"
                            }}
                                color="secondary"
                                onClick={addFont}
                            />
                        </Box>
                    }
                </Box>
                { family &&
                    <Box sx={{ display: 'flex', marginTop: '8px' }}>
                        <Box style={{ display: 'inline-block' }}>
                            <link key={family} href={`https://fonts.googleapis.com/css2?family=${encodeURI(family)}:wght@${weight}&display=swap`} rel="stylesheet"></link>
                            <Box sx={{
                                cursor: 'pointer', color: "white", backgroundColor: "secondary.main", borderWidth: '1px', borderColor: '#292929', borderStyle: 'solid', borderRadius: '3.5px',
                                alignItems: "center", justifyContent: "center", userSelect: 'none'
                            }}
                                style={{ height: '56px', width: '56px', display: 'flex', fontFamily: family, fontWeight: weight, fontSize: '20px', overflow: 'hidden' }}

                                onClick={(e: any) => {
                                    if (configuration.EnableThemes) {
                                        handleOpenMenu(e);
                                    } else {
                                        handleClickOpen();
                                    }
                                }}
                            >
                                Aa
                            </Box>
                        </Box>
                        {configuration.EnableThemes && <Box
                            style={{ width: '100%', marginLeft: '10px' }}
                        >
                            <TextField
                                style={{ height: '100%', width: '100%' }}
                                onClick={handleOpenMenu}
                                inputRef={textInputField}
                                InputProps={{
                                    readOnly: true,
                                    sx: { height: '100%' },
                                }}
                                inputProps={{
                                    style: { cursor: 'pointer' }
                                }}
                                value={linkedToThemeProperty ? themeName : family + " - " + formatWeightName(weight)}
                            />
                            <Menu
                                open={openMenu}
                                anchorEl={textInputField.current}
                                onClose={handleCloseMenu}
                            >
                                <Box>
                                    { primaryFont?.f &&
                                        <>
                                        <MenuItem value="Primary" onClick={() => selectTheme(primaryFontProperty)}>Primary Font ({primaryFont.f})</MenuItem>
                                        <Divider sx={{ margin: '0 16px 0 16px' }}/>
                                        </>
                                    }
                                    { secondaryFont?.f &&
                                        <>
                                        <MenuItem value="Secondary" onClick={() => selectTheme(secondaryFontProperty)}>Secondary Font ({secondaryFont.f})</MenuItem>
                                        <Divider sx={{ margin: '0 16px 0 16px' }}/>
                                        </>
                                    }
                                    <MenuItem value="Custom" onClick={handleClickOpen}>Custom Font...</MenuItem>
                                </Box>
                            </Menu>
                        </Box>}
                        {!configuration.EnableThemes && <Box
                            sx={{ display: 'inline-block', marginLeft: '10px', width: '100%'}}
                        >
                            <TextField
                                style={{ height: '100%', width: '100%' }}
                                onClick={handleClickOpen}
                                InputProps={{
                                    readOnly: true,
                                    sx: { height: '100%' },
                                }}
                                inputProps={{
                                    style: { cursor: 'pointer' }
                                }}
                                value={family + " - " + formatWeightName(weight)}
                            />
                        </Box>}
                        {configuration.EnableDelete && <Box>
                            <DeleteOutlineIcon
                                sx={{ color: '#CF1F2E', width: '34px', height: '100%', marginLeft: '10px', cursor: 'pointer'}}
                                onClick={deleteFont}
                            />
                        </Box>}
                    </Box>
                }
                <BuilderDialog
                    title={"Font Selection"}
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth={true}
                    maxWidth={"md"}
                    actionName={"Cancel"}
                    action={() => setOpen(false)}
                >
                    <Box sx={{ marginBottom: "4em" }}>
                        <Box sx={{display: "flex", gap: "32px"}}>
                            <FormControl sx={{ width: "50%" }} >
                                <label htmlFor="selected-category" style={{lineHeight: "1.4375em"}}>Font category</label>
                                <Select sx={{marginTop: "8px"}} inputProps={{ id: "selected-category" }}
                                    value={selectedCategory}
                                    onChange={(e) => handleSearchCategory(e.target.value)}>
                                    <MenuItem value={"all"} key={"all"}>All</MenuItem>
                                    {categories.map(category =>
                                        <MenuItem value={category} key={category}>
                                            {category[0].toUpperCase() + category.slice(1)}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: "50%" }} >
                                <label htmlFor="selected-weight" style={{lineHeight: "1.4375em"}}>Font weight</label>
                                <Select sx={{marginTop: "8px"}} inputProps={{ id: "selected-weight" }}
                                    value={selectedFilterWeight}
                                    onChange={(e) => handleSearchWeight(e.target.value)}>
                                    <MenuItem value={"all"} key={"all"}>All</MenuItem>
                                    {weights.map(weight =>
                                        <MenuItem value={weight.toString()} key={weight}>
                                            {`${getWeightName(weight)} (${weight})`}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <Box sx={{ flexDirection: "column", width: "100%" }} >
                                <InputLabel htmlFor="preview-text" sx={{color: "black"}}>Preview text</InputLabel>
                                <TextField id="preview-text" sx={{ marginTop: "8px", width: "100%" }}
                                    value={previewText}
                                    onChange={(e) => setPreviewText(e.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", gap: "32px", marginTop: "16px"}}>
                            <Box sx={{ width: "100%" }} >
                                <InputLabel htmlFor="search-text" sx={{color: "black"}}>Search</InputLabel>
                                <TextField id="input-text" sx={{ marginTop: "8px", width: "100%" }}
                                    onChange={(e) => handleSearchText(e.target.value)}
                                    placeholder="Search font families..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{color: "black"}} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <label style={{ fontWeight: "bold" }}>Search Results</label>
                    { scrollFonts &&
                    <Box
                        id="scrollableDiv"
                        className="hideScrollbars"
                        sx={{
                            height: "350px",
                            overflowY: "auto",
                            marginTop: '8px'
                        }}>
                        <InfiniteScroll
                            dataLength={scrollFonts? scrollFonts.length : 0}
                            next={loadMoreScrollFonts}
                            hasMore={scrollFonts.length < searchResults.length}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="scrollableDiv"
                        >
                            {scrollFonts.map((i, index) => (
                                <FontSelection previewText={previewText} font={i} onClick={handleConfirm} key={index} isLast={index >= (scrollFonts.length-1)} />
                            ))}
                        </InfiniteScroll>
                    </Box>
                    }
                </BuilderDialog>
            </>
        );
    }
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const obj = JSON.parse(property.RenderedValue);
        return obj.f?.toLowerCase().includes(value)
            || obj.w?.toString().includes(value);
    }
}