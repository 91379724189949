import { Button } from '@mui/material';

interface CreateSectionTemplateButtonProps {
    id?: string,
    text: string,
    fontColor?: string,
    action: () => void
}

export default function BuilderActionButton({
    id,
    text,
    fontColor,
    action
}: CreateSectionTemplateButtonProps){

    return (
        <>
            <Button 
                id={id}
                variant="outlined" 
                fullWidth={true} 
                sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", color: fontColor ?? ''}}
                onClick={action}
            >
                {text}
            </Button>
        </>
    )
}