export interface DangerousTextProps {
    html?: any | undefined;
}

export default function DangerousText({html} : DangerousTextProps) {
    if (html) {
        return <span dangerouslySetInnerHTML={createMarkup(html)} />;
    }
    return <></>;
}

function createMarkup(value: any) {
    return {__html: value};
}