import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import PageStatus from "../../../../types/enum/PageStatus";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import "./PageListItem.css";
import SidebarState from "../../../../types/enum/SidebarState";
import useApiPutCallbackAsync from "../../../../hooks/useApiPutCallbackAsync";
import { ResponseError } from "../../../../utils/apiHelpers";
import ErrorMessage from "../../../common/ErrorMessage";
import PageListItemContextMenu from "./PageListItemContextMenu";
import PageInfo from "../../../../types/PageInfo";
import SuccessMessage from "../../../common/SuccessMessage";
import { getFirstNonCurrentPage } from "../../../../utils/pageListHelpers";
import DisabledLabel from "../../DisabledLabel";
import SiteEditorStore from "../../SiteEditorStore";
import { useNavigate } from "react-router-dom";

interface PageListItemProps {
  pageInfo: PageInfo,
  isSelected: boolean,
  hasLeftPadding: boolean,
  anyPageContextMenuOpen: boolean,
  setAnyPageContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PageListItem({
  pageInfo,
  isSelected,
  hasLeftPadding,
  anyPageContextMenuOpen,
  setAnyPageContextMenuOpen
}: PageListItemProps) {
  const [pageContextMenuOpen, setPageContextMenuOpen] = useState(false);
  const pageContextMenuRef = useRef<HTMLDivElement>(null);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [disableOpen, setDisableOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [enablePageError, setEnablePageError] = useState<ResponseError | null>(null);
  const [disablePageError, setDisablePageError] = useState<ResponseError | null>(null);
  const [deletePageError, setDeletePageError] = useState<ResponseError | null>(null);
  const [showEnablePageSuccess, setShowEnablePageSuccess] = useState(false);
  const [showDisablePageSuccess, setShowDisablePageSuccess] = useState(false);
  const [showDeletePageSuccess, setShowDeletePageSuccess] = useState(false);
  const refreshPageList = SiteEditorStore.useStoreActions((actions) => actions.refreshPageList);
  const closeSidebars = SiteEditorStore.useStoreActions((actions) => actions.closeSidebars);
  const updateSidebarState = SiteEditorStore.useStoreActions((actions) => actions.updateSidebarState);
  const updatePropertyTab = SiteEditorStore.useStoreActions((actions) => actions.updatePropertyTab);
  const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
  const site = SiteEditorStore.useStoreState((state) => state.site);

  const navigate = useNavigate();

  const [{ isLoading: enablePageLoading }, enablePageCallback]
      = useApiPutCallbackAsync("", []) as any;
  const [, disablePageCallback] = useApiPutCallbackAsync("", []) as any;
  const [, deletePageCallback] = useApiPutCallbackAsync("", []) as any;

  const closeModal = () => {
    setDuplicateOpen(false);
    setDisableOpen(false);
    setDeleteOpen(false);
    updateSidebarState(SidebarState.PagesOpen);
  }

  const closePageContextMenu = () => {
    setPageContextMenuOpen(false);
    setAnyPageContextMenuOpen(false);
  }

  const closeMenus = () => {
    closeModal();
    closePageContextMenu();
  }

  const changePage = (pageId: string) => {
    if (site) {
      navigate(`/site/${site.Id}/page/${pageId}`);
    }
  }

  const openProperties = () => {
    updatePropertyTab(1);
    closeMenus();
    if (!isSelected) {
      changePage(pageInfo.Id)
    }
  }

  const completeDelete = () => {
    const newPageId = getFirstNonCurrentPage(pageList, pageInfo.Id);
    if (newPageId) {
      changePage(newPageId);
    }
  }

  const submitEnablePage = () => {
    setEnablePageError(null);
    setShowEnablePageSuccess(false);
    enablePageCallback({
      }, (res: string) => {
        setShowEnablePageSuccess(true);
        refreshPageList();
      }, (err: ResponseError) => {
        setEnablePageError(err);
      },
      `/site/${site?.Id}/page/${pageInfo.Id}/status/501`
    );
    closeMenus();
  }

  const submitDisablePage = () => {
    setDisablePageError(null);
    setShowDisablePageSuccess(false);
    disablePageCallback({
      }, (res: string) => {
        setShowDisablePageSuccess(true);
        refreshPageList();
      }, (err: ResponseError) => {
        setDisablePageError(err);
      },
      `/site/${site?.Id}/page/${pageInfo.Id}/status/502`
    );
    closeMenus();
  }

  const submitDeletePage = () => {
    setDeletePageError(null);
    setShowDeletePageSuccess(false);
    deletePageCallback({
      }, (res: string) => {
        setShowDeletePageSuccess(true);
        refreshPageList();
        completeDelete();
      }, (err: ResponseError) => {
        setDeletePageError(err);
      },
      `/site/${site?.Id}/page/${pageInfo.Id}/status/503`
    );
    closeMenus();
  }

  return (
    <>
      <Box
        data-testid="pageListItem"
        sx={{
          height: "72px",
          display: "flex",
          cursor: isSelected ? "" : "pointer",
          color: isSelected ? 'white' : '#292929',
          backgroundColor: isSelected ? 'secondary.main' : 'white',
          paddingLeft: hasLeftPadding ? "48px" : "16px",
          "&:hover": {
            backgroundColor: (isSelected || anyPageContextMenuOpen) ? undefined : '#EEEEEE',
          }
        }}
        onClick={isSelected ? undefined : (e: any) => {
          changePage(pageInfo.Id);
          closeSidebars();
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", flex: 1, borderBottom: "1px solid rgba(33, 33, 33, 0.08)" }}>
          { pageInfo.StatusTypeId === PageStatus.Inactive &&
            <DisabledLabel label={"Page Disabled"} isSelected={isSelected} selectedColor={"white"} />
          }
          <div
            style={{
              opacity: !isSelected && pageInfo.StatusTypeId === PageStatus.Inactive ? '.6' : '1',
            }}
          >
            <div className="subtitle1" style={{ textAlign: "left", whiteSpace: "nowrap", overflow: "hidden" }}>{pageInfo.Name}</div>
            <div className="body2" style={{ whiteSpace: "nowrap", overflow: "hidden" }}>{pageInfo.Route}</div>
          </div>
        </div>
        <div style={{ width: "64px", display: "flex", justifyContent: "center", alignItems: "center", flex: "0 0 64px" }}>
          <Box
            sx={{ width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer",
              "color" : !pageContextMenuOpen ? undefined : isSelected ? '#EEEEEE' : 'secondary.main',
              "&:hover": anyPageContextMenuOpen ? {} : { color: isSelected ? '#EEEEEE': 'secondary.main' }
            }}
            onClick={ (e: any) => {
              e.stopPropagation();
              if (pageContextMenuOpen) {
                closePageContextMenu();
              } else if (!anyPageContextMenuOpen) {
                setPageContextMenuOpen(true);
                setAnyPageContextMenuOpen(true);
              }
            } }
            ref={pageContextMenuRef}
          >
            <MoreVertOutlinedIcon />
            <PageListItemContextMenu
              pageContextMenuOpen={pageContextMenuOpen}
              pageContextMenuRef={pageContextMenuRef}
              openProperties={openProperties}
              setDuplicateOpen={setDuplicateOpen}
              changePage={changePage}
              pageInfo={pageInfo}
              duplicateOpen={duplicateOpen}
              closeModal={closeModal}
              closeMenus={closeMenus}
              setDisableOpen={setDisableOpen}
              disableOpen={disableOpen}
              enablePageLoading={enablePageLoading}
              submitEnablePage={submitEnablePage}
              setDeleteOpen={setDeleteOpen}
              deleteOpen={deleteOpen}
              completeDelete={completeDelete}
              submitDisablePage={submitDisablePage}
              submitDeletePage={submitDeletePage}
            />
          </Box>
        </div>
      </Box>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ErrorMessage
          message={"Error enabling page."}
          dismissable={true}
          visible={enablePageError != null}
          apiError={enablePageError}
        />
        <ErrorMessage
          message={"Error disabling page."}
          dismissable={true}
          visible={disablePageError != null}
          apiError={disablePageError}
        />
        <ErrorMessage
          message={"Error deleting page."}
          dismissable={true}
          visible={deletePageError != null}
          apiError={deletePageError}
        />
        <SuccessMessage
          message={"Page enabled!"}
          dismissable={true}
          duration={3000}
          visible={showEnablePageSuccess}
        />
        <SuccessMessage
          message={"Page disabled!"}
          dismissable={true}
          duration={3000}
          visible={showDisablePageSuccess}
        />
        <SuccessMessage
          message={"Page deleted!"}
          dismissable={true}
          duration={3000}
          visible={showDeletePageSuccess}
        />
      </div>
    </>
  )
}