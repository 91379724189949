import { useState, MouseEvent } from "react";
import { Backdrop, Divider, Paper, Popper } from "@mui/material";
import BlockMapping from "../../../../types/BlockMapping";
import BlockMappingStatusId from '../../../../types/enum/BlockMappingStatusId';
import SiteEditorStore from "../../SiteEditorStore";
import EditComponentNameModal from "./EditComponentNameModal";
import DeleteComponentModal from "./DeleteComponentModal";

interface HierarchyContextMenuProps {
    blockMapping: BlockMapping,
    parentBlockMapping: BlockMapping | null,
    contextMenuOpen: boolean
    contextMenuRef: React.RefObject<HTMLDivElement | null>,
    closeMenus: () => void
}

export default function HierarchyContextMenu({
    blockMapping,
    parentBlockMapping,
    contextMenuOpen,
    contextMenuRef,
    closeMenus
}: HierarchyContextMenuProps) {
    const [showConvertModal, setShowConvertModal] = useState(false);
    const [showDeleteComponent, setShowDeleteComponent] = useState(false);
    const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);
    const allSelectedBlocksDisabled = SiteEditorStore.useStoreState((state) => state.allSelectedBlocksDisabled);
    const cloneBlock = SiteEditorStore.useStoreActions((actions) => actions.cloneBlock);
    const deleteBlock = SiteEditorStore.useStoreActions((actions) => actions.deleteBlock);
    const deleteSelectedBlocks = SiteEditorStore.useStoreActions((actions) => actions.deleteSelectedBlocks);
    const toggleSelectedBlocksStatus = SiteEditorStore.useStoreActions((actions) => actions.toggleSelectedBlocksStatus);
    const toggleBlockStatus = SiteEditorStore.useStoreActions((actions) => actions.toggleBlockStatus);
    const convertBlockMappingToComponent = SiteEditorStore.useStoreActions((actions) => actions.convertBlockMappingToComponent);
    const unlinkBlockMappingFromComponent = SiteEditorStore.useStoreActions((actions) => actions.unlinkBlockMappingFromComponent);

    const selectedBlocksIncludesItem = 
        selectedBlocks.map(b => b.Id).includes(blockMapping.Id);

    const duplicateClicked = () => {
        cloneBlock(blockMapping.Id);
        closeMenus();
    }

    const deleteClicked = () => {
        if (selectedBlocksIncludesItem) {
            if (window.confirm(selectedBlocks && selectedBlocks.length > 1
                ? 'Are you sure you wish to delete these blocks?'
                : 'Are you sure you wish to delete this block?') === true) {
                deleteSelectedBlocks();
                closeMenus();
            }
        } else {
            if (window.confirm(`Are you sure you wish to delete this block?`) === true) {
                deleteBlock(blockMapping.Id);
                closeMenus();
            }
        }
    }

    const enableDisableClicked = () => {
        selectedBlocksIncludesItem
            ? toggleSelectedBlocksStatus()
            : toggleBlockStatus({ blockId: blockMapping.Id, currentBlockStatus: blockMapping.StatusTypeId });
        closeMenus();
    }

    const convertToLinkedComponent = (componentName: string) => {
        setShowConvertModal(false);
        closeMenus();
        convertBlockMappingToComponent({ blockMappingId: blockMapping.Id, name: componentName });
    }

    const openConvertToLinkedComponentModal = () => {
        setShowConvertModal(true);
    }

    let enableDisableLabel = "";
    if (selectedBlocksIncludesItem) {
        enableDisableLabel = allSelectedBlocksDisabled 
            ? "Enable Block" : "Disable Block";
    } 
    else {
        enableDisableLabel = blockMapping.StatusTypeId !== BlockMappingStatusId.Active 
            ? "Enable Block" : "Disable Block";
    }
    enableDisableLabel += selectedBlocks && selectedBlocks.length > 1 && selectedBlocksIncludesItem 
        ? "s" : "";

    const deleteLabel = `Delete${selectedBlocks && 
        selectedBlocks.length > 1 && 
        selectedBlocksIncludesItem ? " Selected" : ""}`;

    const parentBelongsToComponent = (parentBlockMapping?.ComponentId !== undefined && parentBlockMapping.ComponentId !== null
        && parentBlockMapping?.ComponentId !== '');

    return <>
        <Backdrop
        open={contextMenuOpen}
        onClick={(e) => {
            e.stopPropagation();
            closeMenus();
        }}
        style={{
            opacity: 0,
            zIndex: 1200,
            cursor: "initial"
        }}
        >
            <Popper
                id={"hierarchy-context-popper"}
                open={ contextMenuRef?.current ? contextMenuOpen : false }
                anchorEl={contextMenuRef?.current}
                placement={'bottom-start'}
                style={{ zIndex: 1300 }}
                onClick={ (e) => { e.stopPropagation(); } }
            >
                <Paper
                    elevation={4}
                    sx={{
                        padding: 2,
                        lineHeight: 1.5,
                        width: 250
                    }}
                >
                    { (!selectedBlocks || selectedBlocks.length <= 1
                        || !selectedBlocksIncludesItem) &&
                    <>
                        <p
                            id={`hierarchy-context-duplicate-button-${blockMapping.Id}`}
                            onClick={() => duplicateClicked()}
                            style={{cursor:"pointer"}}
                        >Duplicate</p>
                        <Divider />
                    </>
                    }

                    <p
                        id={`hierarchy-context-disable-button-${blockMapping.Id}`}
                        onClick={() => enableDisableClicked()}
                        style={{cursor:"pointer"}}
                    >{enableDisableLabel}{selectedBlocks.length <= 1 && blockMapping.ComponentBlockMappingId 
                        && parentBelongsToComponent === false ? ' On Page' : ''}</p>
                    <Divider />

                    
                    {blockMapping.ComponentBlockMappingId && selectedBlocks.length <= 1 && parentBelongsToComponent === false &&
                        <>
                        <p
                            id={`hierarchy-context-disable-site-button-${blockMapping.Id}`}
                            onClick={() => { 
                                if (blockMapping.ComponentBlockMappingId) {
                                    toggleBlockStatus({ blockId: blockMapping.ComponentBlockMappingId, currentBlockStatus: blockMapping.StatusTypeId }); 
                                    closeMenus(); 
                                }
                            }}
                            style={{cursor:"pointer"}}
                        >{enableDisableLabel} Across Site</p>
                        <Divider />
                        </>
                    }

                    {!blockMapping.ComponentBlockMappingId &&
                        <>
                        <p
                            id={`hierarchy-context-convert-${blockMapping.Id}`}
                            onClick={openConvertToLinkedComponentModal}
                            style={{cursor:"pointer"}}
                        >Convert To Linked Component</p>
                        <Divider />
                        </>
                    }
                    {blockMapping.ComponentBlockMappingId &&
                        <>
                        <p
                            id={`hierarchy-context-unlink-${blockMapping.Id}`}
                            onClick={() => { closeMenus(); unlinkBlockMappingFromComponent(blockMapping.Id); } }
                            style={{cursor:"pointer"}}
                        >Unlink From Component</p>
                        <Divider />
                        </>
                    }
                    
                    <p
                        id={`hierarchy-context-delete-button-${blockMapping.Id}`}
                        onClick={() => deleteClicked()}
                        style={{cursor:"pointer",color: '#CF1F2E'}}
                    >
                        {deleteLabel}{selectedBlocks.length <= 1 && blockMapping.ComponentBlockMappingId && !parentBlockMapping?.ComponentId ? ' On Page' : ''}
                    </p>

                    {blockMapping.ComponentBlockMappingId && blockMapping.ComponentId &&
                        <>
                        <Divider />
                        <p
                            id={`hierarchy-context-delete-across-site-button-${blockMapping.Id}`}
                            onClick={() => { setShowDeleteComponent(true); } }
                            style={{cursor:"pointer",color: '#CF1F2E'}}
                        >
                            Delete Across Site
                        </p>
                        </>
                    }
                </Paper>
            </Popper>
            {showConvertModal &&
                <EditComponentNameModal
                    title="Convert To Linked Component"
                    actionName="Create"
                    action={(e: MouseEvent<HTMLButtonElement>, componentName: string) => { e.stopPropagation(); convertToLinkedComponent(componentName); }}
                    onClose={(e: MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); setShowConvertModal(false); } }
                />
            }
            { showDeleteComponent && blockMapping.ComponentId &&
                <DeleteComponentModal componentId={blockMapping.ComponentId} onClose={() => { setShowDeleteComponent(false); closeMenus(); }} />
            }
        </Backdrop>
    </>
}