import { useEffect, useContext } from 'react';
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';
import { useAuth0 } from '@auth0/auth0-react';

export default function useGetAndSet(getCallUrl: string, setFunction: (data: any) => void, enabled?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);
    enabled = enabled === undefined || enabled;

    useEffect(() => {
        const callFetch = async () => {
            try {
                // I would push getting the token down into the makeApiCall function, but makeApiCall is not a hook or a component
                // so we can't call the useAuth0() hook from makeApiCall.

                // We can't convert makeApiCall into a hook because we can't call hooks from inside useEffect hook callbacks.
                // When we sidestep useEffect, state and behavior get really unstable.

                // Setting the getAccessTokenSilently function into the UserContext actually stores the promise, not the func.

                // A static function for getAccessTokenSilently seems possible per
                // https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
                // but I gave it a couple hours and kept running into obstacles, such as how we're
                // composing our index.js file and how we have the Auth0 provider being generated.

                // So for now, everything but the token is happening in the UserContext and the token happens here
                // which appears to be a pretty common pattern
                
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                const data = await makeApiCall(getCallUrl, httpMethods.get, identityHeaders);
                
                setFunction(data);
            } catch (error) {
                console.log(error);
                throw error;
            }
        };

        if (enabled && currentUser) {
            callFetch();
                    
        }
    },[getCallUrl, setFunction, getAccessTokenSilently, currentUser, sessionId, correlationId, enabled])
} 
