import BlockFilterData from '../../../types/BlockFilterData';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { getPropertyValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import PropertyId from '../../../types/enum/PropertyId';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {

    const getColumnClass = (
            current: string | null | undefined,
            size: string,
            previous: string | null | undefined,
            isXSmall?: boolean) => {
        if (!current) {
            if (!previous && !isXSmall)
            {
                return "";
            }

            return ` ${size}grid-flow-col ${size}auto-cols-fr`;
        }
        else
        {
            let classValue = "";

            if (!previous && !isXSmall)
            {
                classValue += ` ${size}grid-flow-unset`;
            }

            // If the previous number of columns is different, set the new number
            if (current !== previous)
            {
                classValue += ` ${size}grid-cols-${current}`;
            }

            return classValue;
        }
    };

    const getDynamicClasses = () => {
        const xsmall = getPropertyValue(block.Properties, PropertyId.RowXSmallColumns);
        const small = getPropertyValue(block.Properties, PropertyId.RowSmallColumns);
        const medium = getPropertyValue(block.Properties, PropertyId.RowMediumColumns);
        const large = getPropertyValue(block.Properties, PropertyId.RowLargeColumns);
        const xlarge = getPropertyValue(block.Properties, PropertyId.RowXLargeColumns);
        const x2large = getPropertyValue(block.Properties, PropertyId.Row2XLargeColumns);
        const gap = getPropertyValue(block.Properties, PropertyId.RowGap);

        let classes = "";
        classes += getColumnClass(xsmall, "", null, true);
        classes += getColumnClass(small, "sm:", xsmall);
        classes += getColumnClass(medium, "md:", small);
        classes += getColumnClass(large, "lg:", medium);
        classes += getColumnClass(xlarge, "xl:", large);
        classes += getColumnClass(x2large, "2xl:", xlarge);
        if (gap) {
            classes += gap === '0.25' ? " gap-px" : ` gap-${gap}`
        }

        return classes;
    };

    const classes = `row grid${getDynamicClasses()}`;

    return <div {...getDynamicAttributes({block, builderProps, className: classes, blockWrapperData})}>{children}</div>;
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}