import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ErrorMessage from '../common/ErrorMessage';
import Loading from '../common/Loading';
import { Box, Tabs, Tab, Paper }  from '@mui/material';
import Divider from '@mui/material/Divider';
import useApiPutCallbackAsync from '../../hooks/useApiPutCallbackAsync';
import SuccessMessage from '../common/SuccessMessage';
import Property from '../../types/Property';
import CustomBlock from '../../types/CustomBlock';
import useApiGetCallbackAsync from '../../hooks/useApiGetCallbackAsync';
import CustomBlockEditorProperties from './CustomBlockEditorProperties';
import CustomBlockEditorSettings from './CustomBlockEditorSettings';
import CustomBlockEditorHtml from './CustomBlockEditorHtml';
import CustomBlockEditorNavBar from './CustomBlockEditorNavBar';
import TabPanel from '../common/TabPanel';
import Preview from '../common/Preview';
import blockMappingStatusId from '../../types/enum/BlockMappingStatusId';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const htmlSection = 'html';
const settingsSection = 'settings';

const codeTab = 0;
const previewTab = 1;

export default function CustomBlockEditor() {
    const [section, setSection] = useState(htmlSection);
    const [showSaveError, setShowSaveError] = useState(false);
    const [showSaveSuccess, setShowSaveSuccess] = useState(false);
    const [originalBlock, setOriginalBlock] = useState<null | CustomBlock>(null);
    const [block, setBlock] = useState<null | CustomBlock>(null);
    const [previewProperties, setPreviewProperties] = useState<Property[]>([]);
    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();

    const { id } = useParams<{ id: string }>();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const replaceSwapValuesIdsWithNames = (block: CustomBlock): void => {
        if (block?.HTML == null || !block?.Properties) {
            return ;
        }

        for (const prop of block.Properties) {
            block.HTML = block.HTML.replaceAll(`[P:${prop.PropertyId}]`,`[P:${prop.Name}]`);
        }
    };

    const replaceSwapValueNamesWithIds = (block: CustomBlock): string => {
        if (block?.HTML == null || !block?.Properties) {
            return "";
        }

        let replacedHtml = block.HTML;

        for (const prop of block.Properties) {
            replacedHtml = replacedHtml.replaceAll(`[P:${prop.Name}]`,`[P:${prop.PropertyId}]`);
        }

        return replacedHtml;
    };

    const [{ isLoading: customBlockLoading, isError: customBlockError, errorResponse: customBlockApiError }, loadBlockCallback]
        = useApiGetCallbackAsync(id ? `/block/custom/${id}` : '', null) as any;
    const [{ isLoading: saveLoading, errorResponse: saveApiError }, saveCallback]
        = useApiPutCallbackAsync(`/block/custom/${block?.Id}`, []) as any;

    const loadBlock = () => {
        loadBlockCallback((data: CustomBlock) => {
            replaceSwapValuesIdsWithNames(data);
            setBlock(data);
            setOriginalBlock(data);
        });
    }

    const loadBlockProperties = () => {
        // Only load the properties, leave other block properties alone
        loadBlockCallback((data: CustomBlock) => {
            if (block) {
                // Leave shared properties in their current state
                const sharedProperties = block.Properties.filter(p => p.Shared);
                block.Properties = data.Properties.filter(p => !p.Shared);
                block.Properties = [...block.Properties, ...sharedProperties];
                setBlock({...block});
            }
        });
    }

    // Do initial load of block
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { loadBlock() }, []);

    useEffect(() => {
        if (block) {
            // Create duplicate properties for use in preview mode
            const newPreviewProps: Property[] = [];

            for (const prop of block.Properties) {
                // If we already have a preview prop for this, retain its values
                const existingPreviewProp = previewProperties?.find(p => p.PropertyId === prop.PropertyId);

                newPreviewProps.push({
                    ...prop,
                    Value: existingPreviewProp?.Value ?? (prop.Value ?? null),
                    RenderedValue: existingPreviewProp?.RenderedValue ?? (prop.RenderedValue ?? null)
                });
            }

            setPreviewProperties(newPreviewProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block]);

    const menuStyle = { padding: "18px", '&:hover': { backgroundColor: "#EEEEEE" }, cursor: "pointer" };
    const activeStyle = { padding: "16px", backgroundColor: 'secondary.main', color: 'white' };

    const saveBlock = (onSaveCallback?: () => void | undefined) => {
        setShowSaveError(false);
        setShowSaveSuccess(false);

        if (block) {
            const html = replaceSwapValueNamesWithIds(block);
            saveCallback({
                Name: block.Name,
                CategoryTypeId: block.CategoryTypeId,
                Description: block.Description,
                Html: html,
                PropertyIds: block.Properties.map((p: Property) => p.PropertyId)
            }
            , () => {
                setShowSaveSuccess(true);
                setOriginalBlock(block);
                if (onSaveCallback) {
                    onSaveCallback();
                }
            }
            , () => { setShowSaveError(true) });
        }
    };

    const goToCustomBlocks = () => {
        navigate("/sharedcontentmanager/customblocks")
    };

    const updateBlockName = (name: string) => {
        setBlock((currentBlock: any) => { return { ...currentBlock, Name: name }});
    };

    const updateBlockDescription = (description: string) => {
        setBlock((currentBlock: any) => { return { ...currentBlock, Description: description }});
    };

    const updateBlockCategory = (categoryTypeId: number) => {
        setBlock((currentBlock: any) => { return { ...currentBlock, CategoryTypeId: categoryTypeId }});
    };

    const updateBlockHtml = (html: string) => {
        if (html !== block?.HTML) {
            setBlock((currentBlock: any) => { return { ...currentBlock, HTML: html }});
        }
    };

    return (
        <>
        <CustomBlockEditorNavBar
            block={block}
            originalBlock={originalBlock}
            goToCustomBlocks={goToCustomBlocks}
            saveBlock={saveBlock}
        />
        { block &&
            <div style={{ position: "relative", display: "flex", height: "calc(100% - 48px)" }}>
                <div style={{ flex: "0 0 336px", padding: "16px" }}>
                    <h5 className="headline5" style={{ marginTop: "16px", marginBottom: "32px" }}>{block?.Name}</h5>
                    <div>
                        <Box
                            onClick={() => setSection(htmlSection)}
                            sx={section === htmlSection ? activeStyle : menuStyle }
                            className="subtitle2"
                            data-testid="custom-block-html-menu-item"
                        >
                            HTML
                        </Box>
                        <Divider sx={{ marginTop: "32px", marginBottom: "32px" }} />
                        <Box
                            onClick={() => setSection(settingsSection)}
                            sx={section === settingsSection ? activeStyle : menuStyle }
                            className="subtitle2"
                            data-testid="custom-block-settings-menu-item"
                        >
                            Block Settings
                        </Box>
                    </div>
                </div>
                <div style={{ backgroundColor: "#FAFAFA", width: "100%", padding: "32px" }}>
                    { section === htmlSection &&
                        <div style={{ height: "100%", flexDirection: "column", display: "flex"}}>
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: "32px"}}>
                                <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="editor tabs" TabIndicatorProps={{style: { background:'#cf1f2e' }}} >
                                        <Tab label="Code" id="code-tab" aria-controls="code-tab-pane" sx={{ width: "167px" }} />
                                        <Tab label="Preview" id="preview-tab" aria-controls="preview-tab-pane" sx={{ width: "167px" }} />
                                    </Tabs>
                                </div>
                            </div>
                            <TabPanel value={tabValue} name="code-tab" index={codeTab}>
                                <Paper sx={{ height: "100%" }}>
                                    <CustomBlockEditorHtml
                                        block={block}
                                        updateBlockHtml={updateBlockHtml}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={tabValue} name="preview-tab" index={1}>
                                <Paper sx={{ height: "100%" }}>
                                    <DndProvider backend={HTML5Backend} context={window}>
                                        <Preview
                                            page={{
                                                PageId: "",
                                                SiteId: "",
                                                BlockContent: [{
                                                    Id: block.BlockContentId,
                                                    Html: replaceSwapValueNamesWithIds(block) ?? ""
                                                }],
                                                Url: "",
                                                Domain: "",
                                                Route: "",
                                                SiteStyleId: 1,
                                                SiteProperties: [],
                                                PageProperties: [],
                                                BlockMappings: [{
                                                    Id: "",
                                                    EnumId: null,
                                                    BlockId: block.Id,
                                                    Properties: previewProperties,
                                                    StatusTypeId: blockMappingStatusId.Active,
                                                    WizardLabel: null,
                                                    BlockContentId: block.BlockContentId,
                                                    BlockMappings: [],
                                                }],
                                                Fonts: [],
                                                StyleSheetUrl: block.StyleSheetUrl,
                                                AssetsUrl: ""
                                            }}
                                            PreviewBlockId={null}
                                            blockSelected={() => {}}
                                            isEditPage={false}
                                            cloneBlock={() => {}}
                                            deleteBlock={() => {}}
                                            dragDrop={null}
                                            hoverBlock={null}
                                        />
                                    </DndProvider>
                                </Paper>
                            </TabPanel>
                        </div>
                    }
                    { section === settingsSection &&
                        <CustomBlockEditorSettings
                            block={block}
                            goToCustomBlocks={goToCustomBlocks}
                            updateBlockName={updateBlockName}
                            updateBlockDescription={updateBlockDescription}
                            updateBlockCategory={updateBlockCategory}
                        />
                    }
                </div>
                { section === htmlSection &&
                    <CustomBlockEditorProperties
                        block={block}
                        loadBlockProperties={loadBlockProperties}
                        setBlock={setBlock}
                        previewProperties={previewProperties}
                        setPreviewProperties={setPreviewProperties}
                        previewMode={tabValue === previewTab}
                    />
                }
            </div>
        }
        { (customBlockLoading || saveLoading) && <Loading />}
        <ErrorMessage
            message="Error loading custom block. Please try again later."
            duration={15000}
            dismissable={true}
            visible={customBlockError}
            apiError={customBlockApiError}
        />
        <ErrorMessage
            message="Error Saving Block."
            duration={15000}
            visible={showSaveError}
            apiError={saveApiError}
            dismissable={true}
        />
        <SuccessMessage
            message={"Block Saved!"}
            dismissable={true}
            duration={3000}
            visible={showSaveSuccess}
        />
        </>
    )
}