import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ImageCarouselSlide from '../../types/ImageCarouselSlide';


export interface SlideProps {
    id: number;
    slide: ImageCarouselSlide;
    index: number;
    moveSlide: (dragIndex: number, hoverIndex: number) => void;
    deleteSlide: (index: number) => void;
    editSlide: (index: number) => void;
}

export const Slide = ({ id, slide, index, moveSlide, deleteSlide, editSlide }: SlideProps) => {
    const ref = useRef<HTMLElement>(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'Slide',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset() || {x: 0, y: 0};
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveSlide(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [, drag] = useDrag({
        type: 'Slide',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));
    return (
        <Box ref={ref} data-handler-id={handlerId} key={index} sx={{ marginTop: "32px", display: "flex", height: "60px", cursor: "move" }}  data-testid={`slide-list-item-${index}`}>
            <Box sx={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                <DragIndicatorIcon sx={{ color: "#646464", height: "22px" }} />
            </Box>
            <Box sx={{ width: "60px", height: "60px", flex: "0 0 60px" }}>
                <img src={slide.t} alt={slide.a} style={{ width: "100%", height: "100%" }} data-testid="image" />
            </Box>
            <Box sx={{ paddingLeft: "16px", display: "flex", alignItems: "center", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} 
                className="body1"
                data-testid="caption">
                {slide.c}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right", flex: "auto" }}>
                <Button className="button-edit-button" onClick={() => editSlide(index)}>
                    <EditIcon /> Edit
                </Button>
                <DeleteOutlineIcon 
                    sx={{ height: '18px', color: '#B00020', marginLeft: '32px', cursor: 'pointer'}} 
                    onClick={() => deleteSlide(index)} 
                />
            </Box>
        </Box>
    );
};