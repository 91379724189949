import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import Property from "../../types/Property";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import { PropertyNameLabel } from "../common/PropertyNameLabel";

let selectedDropdownItemText = '';

export function Editor({ property, propertyUpdated, populatedDataSources = [] }: PropertyEditorProps) {
    const updateValue = (event: SelectChangeEvent) => {
        if (property) {
            property.Value = event.target.value;
            property.RenderedValue = event.target.value;
            propertyUpdated(property);
        }

    };

    const dataSourceOptions =
        populatedDataSources[0]?.Records.map(record => ({ name: record.name, key: record.id })) || [];
    const options: Record<string, string>[] = [
        {
            name: 'Not Set',
            key: ''
        },
        ...dataSourceOptions
    ];

    const selectedOption = options.find(option => option.key === property?.Value);
    selectedDropdownItemText = selectedOption?.name ?? '';

    if (dataSourceOptions.length === 1) {
        return <></>
    }
    return (
        <>
            <PropertyNameLabel
                property={property}
            ></PropertyNameLabel>
            <FormControl fullWidth>
                <Select
                    value={!property?.Value ? "" : property.Value.toString()}
                    onChange={updateValue}
                    labelId={`label-${property?.PropertyId}`}
                    variant="outlined"
                    displayEmpty
                    notched={true}
                    className="mt1"
                    data-testid="data-source-property-select"
                >
                    {options.map(({ name, key }, index) => (
                        <MenuItem
                            value={key}
                            key={index}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

export function setSelectedDropdownItemText(value: string) {
    selectedDropdownItemText = value;
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    selectedDropdownItemText = property?.Value ? selectedDropdownItemText : 'Not Set';
    return selectedDropdownItemText.toLowerCase().includes(value);
}