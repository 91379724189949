import { MenuItem, Select, Box, FormControl } from '@mui/material';
import FeatureType from '../../../types/FeatureType';
import IndustryType from '../../../types/IndustryType';

export interface TemplateFilterProps {
    siteIndustry: IndustryType,
    industryFilters: IndustryType[],
    featureFilters: FeatureType[],
    selectedFeatureIds: string[],
    setSiteIndustryId: (industryId: number) => void,
    setSelectedFeatureIds: (featureIds: string[]) => void,
    stacked: boolean
}

function TemplateFilters({ siteIndustry, industryFilters, featureFilters, selectedFeatureIds, setSiteIndustryId, setSelectedFeatureIds, stacked}: TemplateFilterProps) {

    return (
        <Box data-testid="container" sx={{display: "flex", flexDirection: stacked ? "column" : "row" }} >
            <Box sx={{width: '285px', marginRight: "32px"}}>
                <label id="industry-label" className="body1">Industry</label>
                <FormControl fullWidth sx={{marginTop: "8px"}}>
                    <Select
                        data-testid="industrySelect"
                        value={industryFilters && industryFilters.length > 0 && siteIndustry?.Id ? `${siteIndustry?.Id}` : ''}
                        labelId="industry-label"
                        onChange={(e) => { setSiteIndustryId(parseInt(e.target.value as string))}}
                        >
                        {industryFilters.map(ind =>
                            <MenuItem key={ind.Id} value={ind.Id}>{ind.Name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ width: '285px', marginTop: stacked ? "16px" : "0px" }}>
                <label id="industry-label" className="body1">Filters</label>
                <FormControl fullWidth sx={{marginTop: "8px"}}>
                    <Select
                        data-testid="filtersSelect"
                        value={selectedFeatureIds}
                        labelId="filters-label"
                        multiple
                        onChange={(e) => { setSelectedFeatureIds(e.target.value as string[]) }}
                        displayEmpty={true}
                        renderValue={(v) => { return v.length + " selected"}}
                        >
                        {featureFilters.map(feat =>
                            <MenuItem key={feat.Id} value={feat.Id}>{feat.Name}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default TemplateFilters;