import { useEffect } from "react";
import TemplateSelector from "../../common/template_selector/TemplateSelector";
import { Box } from '@mui/material';
import WizardStepProps from "../../../types/WizardStepProps";
import Template from "../../../types/Template";
import TemplateTypes from "../../../types/enum/TemplateTypes";

function TemplateStep({ handleNext, setSelectedTemplate, site, drawerOpen, setShowFilterIcon, setDrawerOpen}: WizardStepProps) {
    useEffect(() => {
        if (setShowFilterIcon) {
            setShowFilterIcon(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function selectTemplate(template: Template) {
        if (setSelectedTemplate && handleNext) {
            setSelectedTemplate(template);
            handleNext();
        }
    }

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Pick a template to start building a new website</h1>
            </div>
            <Box className="wizard-step-body-container">
                <TemplateSelector templateSelected={selectTemplate} site={site} drawerOpen={!!drawerOpen} setDrawerOpen={setDrawerOpen} templateTypeId={TemplateTypes.Site} />
            </Box>
        </>
    );
}

export default TemplateStep;