import { useState, useRef } from "react";
import Preview from "../common/Preview";
import SiteEditorStore from "./SiteEditorStore";
import DragDropData from "../../types/DragDropData";

function SiteEditorPreview() {
    const [scrollTop, setScrollTop] = useState(0);
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const shiftHeld = SiteEditorStore.useStoreState((state) => state.shiftHeld);
    const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);
    const blockSelected = SiteEditorStore.useStoreActions((actions) => actions.blockSelected);
    const hoverBlock = SiteEditorStore.useStoreState((state) => state.hoverBlock);
    const updateHoverBlock = SiteEditorStore.useStoreActions((actions) => actions.updateHoverBlock);
    const cloneBlock = SiteEditorStore.useStoreActions((actions) => actions.cloneBlock);
    const deleteBlock = SiteEditorStore.useStoreActions((actions) => actions.deleteBlock);
    const toggleSelectedBlocksStatus = SiteEditorStore.useStoreActions((actions) => actions.toggleSelectedBlocksStatus);
    const draggingStarted = SiteEditorStore.useStoreActions((actions) => actions.draggingStarted);
    const draggingOnHover = SiteEditorStore.useStoreActions((actions) => actions.draggingOnHover);
    const draggingFinished = SiteEditorStore.useStoreActions((actions) => actions.draggingFinished);
    const draggingHoverData = SiteEditorStore.useStoreState((state) => state.draggingHoverData);
    const isDragging = SiteEditorStore.useStoreState((state) => state.isDragging);

    const doDeleteBlock = (blockId: string) => {
        if (window.confirm(`Are you sure you wish to delete this block?`) === true) {
            deleteBlock(blockId);
        }
    };

    const ref = useRef<HTMLDivElement>(null);

    const onScroll = () => {
        if (ref?.current) {
            const scrollTop = ref.current.scrollTop;
            setScrollTop(scrollTop);
        }
    }

    const dragDrop: DragDropData = {
        started: draggingStarted,
        hovering: draggingOnHover,
        finished: draggingFinished,
        hoverData: draggingHoverData,
        isDragging: isDragging
    };

    return (
        <div className="hideScrollbars" style={{ boxShadow: "inset 0px 0px 4px rgb(0 0 0 / 25%)", overflowY: "auto", flex: "1", height: "100%" }} ref={ref} onScroll={onScroll}>
            { page && 
                <Preview page={page} PreviewBlockId={null} blockSelected={blockSelected} isEditPage={true} 
                    cloneBlock={cloneBlock} deleteBlock={doDeleteBlock} 
                    dragDrop={dragDrop} selectedBlocks={selectedBlocks} hoverBlock={hoverBlock} blockHovered={updateHoverBlock}
                    shiftHeld={shiftHeld} toggleBlocksDisabled={toggleSelectedBlocksStatus} scrollOffset={scrollTop} pageList={pageList} />
            }
        </div>
    );
}

export default SiteEditorPreview;