import { useState, useEffect } from 'react';
import property_renderers from "../../utils/propertyRenderers";
import Property from '../../types/Property';
import useApiPutCallbackAsync from "../../hooks/useApiPutCallbackAsync";
import ErrorMessage from '../common/ErrorMessage';
import PropertyEditorProps from '../../types/PropertyEditorProps';

export default function PropertyEditor({blockMapping, siteProperties, property, propertyUpdated, updateLastPropertyUpdatedDate, updatePage, page, pageList, populatedDataSources, navigateToProperty}: PropertyEditorProps) {
    const [mostRecentValue, setMostRecentValue] = useState<string | null | undefined>(null);
    const [editedProperty, setEditedProperty] = useState<Property | null>(null);
    const [originalProperty, setOriginalProperty] = useState(JSON.parse(JSON.stringify(property)));
    const [error, setError] = useState<string | null>(null);
    const [{isLoading, isError}, upsertCallback] = useApiPutCallbackAsync(`/properties/site/${property?.SiteId}/upsert`, {}) as any;

    useEffect(() => {
        if (editedProperty) {
            let upsertBlockMappingId = editedProperty.BlockMappingId;
            let upsertPageId = editedProperty.PageId;

            // Property upserts for linked block mappings use the component's block mapping ID instead
            // of the linked block mapping ID
            if (blockMapping?.ComponentBlockMappingId) {
                upsertBlockMappingId = blockMapping.ComponentBlockMappingId;
            }
            // Property upserts for component block mappings don't have a page ID associated with them
            if (blockMapping?.ComponentId) {
                upsertPageId = null;
            }
            upsertCallback({...editedProperty, BlockMappingId: upsertBlockMappingId, PageId: upsertPageId }
                , propertySuccessfullyUpdated, propertyUpdateFailed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedProperty]);

    useEffect(() => {
        if (updateLastPropertyUpdatedDate) {
            updateLastPropertyUpdatedDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mostRecentValue])

    if (!property) {
        return null;
    }

    const PropertyRenderer = property_renderers[property.ValueTypeId]?.Editor;

    if (!PropertyRenderer) {
        return null;
    }

    const propertySuccessfullyUpdated = () => {
        // Reset property values in the event this is being set from a retry of previous edited values
        if (property) {
            property.Value = editedProperty?.Value;
            property.RenderedValue = editedProperty?.RenderedValue;
            propertyUpdated(property);
        }
        if (updatePage) {
            updatePage();
        }

        // Keep the latest saved state for rollbacks
        setEditedProperty(null);
        setOriginalProperty(JSON.parse(JSON.stringify(property)));
    };

    const propertyUpdateFailed = () => {
        // Reset the property values to prior state
        if (property) {
            property.Value = originalProperty.Value;
            property.RenderedValue = originalProperty.RenderedValue;
            propertyUpdated(property);
        }
        if (updatePage) {
            updatePage();
        }
    };

    const retryUpdate = () => {
        upsertCallback(editedProperty, propertySuccessfullyUpdated, propertyUpdateFailed);
    };

    const updated = (updatedProperty: Property) => { 
        setEditedProperty({...updatedProperty});
    };

    const propertyEditorProps: PropertyEditorProps = {
        blockMapping: blockMapping,
        siteProperties: siteProperties,
        property: property,
        propertyUpdated: updated,
        updateLastPropertyUpdatedDate: updateLastPropertyUpdatedDate,
        siteId: property?.SiteId ?? undefined,
        setError: setError,
        isPageEditor: true,
        page: page,
        pageList: pageList,
        populatedDataSources: populatedDataSources,
        navigateToProperty: navigateToProperty,
        setMostRecentValue: setMostRecentValue,
    };

    return (
        <>
            <div data-testid={`property-${property?.Name?.replace(/ /g,"_")}`}>
                <PropertyRenderer {...propertyEditorProps} />
            </div>
            { !isLoading && isError && 
                <ErrorMessage 
                    message="Error updating property" 
                    action={retryUpdate} 
                    actionName="Retry" 
                    visible={true}
                />
            }
            { error && 
                <ErrorMessage 
                    message={error} 
                    action={() => setError(null)}
                    actionName={'Dismiss'} 
                    visible={true}
                />
            }
        </>
    );
}