import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import { addDays, endOfMonth, format, parse, startOfDay  } from 'date-fns'
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

const isProperlyConfigured = (block: BlockMapping) : boolean => {
    let hasOpen = false;
    let hasHolidays = false;
    const hours = getPropertyValue(block.Properties, PropertyId.Hours);
    const holidays = getPropertyValue(block.Properties, PropertyId.HolidayHours);
    if (hours) {
        let hoursConfig = JSON.parse(hours);
        hasOpen = 
        (hoursConfig.Monday && hoursConfig.Monday.Type !== _closedType)
        || (hoursConfig.Tuesday && hoursConfig.Tuesday.Type !== _closedType)
        || (hoursConfig.Wednesday && hoursConfig.Wednesday.Type !== _closedType)
        || (hoursConfig.Thursday && hoursConfig.Thursday.Type !== _closedType)
        || (hoursConfig.Friday && hoursConfig.Friday.Type !== _closedType)
        || (hoursConfig.Saturday && hoursConfig.Saturday.Type !== _closedType)
        || (hoursConfig.Sunday && hoursConfig.Sunday.Type !== _closedType)
    }
    if (holidays) {
        let holidaysParsed = JSON.parse(holidays);
        hasHolidays = holidaysParsed != null && holidaysParsed.length > 0;
    }
    return hasOpen || hasHolidays || getPropertyValue(block.Properties, PropertyId.AdditionalHoursText)
        ? true : false;
};

const _closedType = 'Closed';
const _24HoursType = '24 Hours';
const _regularHoursType = 'Regular Hours';
const _open = 'Open';

const _months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]


const getHoursDisplay = (hours: number, minutes: number) => {
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString();
    return `${hours}:${minutesStr}`;
};

const getHoursAMPMDisplay = (hours: number) => {
    return hours >= 12 ? 'PM' : 'AM';
};

const parseAndFormat = (holidayDateString: string) => {
    const holidayDate = parse(holidayDateString, 'yyyy-MM-dd', new Date());
    return _months[holidayDate.getMonth()] + " "
        + format(holidayDate, 'do')
        .replace( /(\d)(st|nd|rd|th)/g,'$1<sup>$2</sup>');
}


export function Block(props: BlockProps) {
    if (!isProperlyConfigured(props.block)) {
        return <></>;
    }
    const hoursStr = getPropertyValue(props.block.Properties, PropertyId.Hours);
    const holidayHoursStr = getPropertyValue(props.block.Properties, PropertyId.HolidayHours);
    const additionalHoursText = getPropertyValue(props.block.Properties, PropertyId.AdditionalHoursText);
    const holidayHoursTitle = getPropertyValue(props.block.Properties, PropertyId.HolidayHoursTitle);
    
    let hoursConfig = null;
    let holidays = null;

    if (hoursStr) {
        hoursConfig = JSON.parse(hoursStr);
    }
    
    if(holidayHoursStr) {
        holidays = JSON.parse(holidayHoursStr);

        holidays = holidays?.filter((h: any) => {
            const result = parse(h.HolidayDate, 'yyyy-MM-dd', new Date());
            const today = startOfDay(new Date());
            const threeWeeksOut = addDays(today, 21);
            const endMonth = endOfMonth(today);

            return result >= today
                && (result <= threeWeeksOut || result <= endMonth);
        });
    }

    const addRow = (dayName: string, day: any, showClosed: boolean = false) => {
        if (!day || day.Type === _closedType) {
            if (!showClosed) {
                return null;
            }
            return (
                <tr key={dayName}>
                    <td className="hours-label" dangerouslySetInnerHTML={{ __html: dayName}}></td>
                    <td colSpan={5}>Closed</td>
                </tr>
            );
        }

        if (day.Type === _regularHoursType) {
            return (
                <tr key={dayName}>
                    <td className="hours-label" dangerouslySetInnerHTML={{ __html: dayName}}></td>
                    <td colSpan={5}>Regular Hours</td>
                </tr>
            );
        }
        else if (day.Type === _24HoursType) {
            return (
                <tr key={dayName}>
                    <td className="hours-label" dangerouslySetInnerHTML={{ __html: dayName}}></td>
                    <td colSpan={5}>24 Hours</td>
                </tr>
            );
        }
        else {
            var returnRows = [];

            for (let i = 0; i < day.Hours.length; i++) {
                returnRows.push((
                    <tr key={`${dayName}-${i}`}>
                        <td className="hours-label" dangerouslySetInnerHTML={{ __html: i === 0 ? dayName : ""}}></td>
                        <td className="text-right">{getHoursDisplay(day.Hours[i].StartTimeHour, day.Hours[i].StartTimeMinute)}</td>
                        <td>{getHoursAMPMDisplay(day.Hours[i].StartTimeHour)}</td>
                        <td className="px-1">-</td>
                        <td className="text-right">{getHoursDisplay(day.Hours[i].EndTimeHour, day.Hours[i].EndTimeMinute)}</td>
                        <td>{getHoursAMPMDisplay(day.Hours[i].EndTimeHour)}</td>
                    </tr>
                ));
            }

            return returnRows;
        }
    };


    return (
        <>
            <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: "hours", blockWrapperData: props.blockWrapperData})}>
                {hoursConfig &&
                    <table>
                        <tbody>
                        {hoursConfig && <>
                            {addRow('Sunday', hoursConfig.Sunday)}
                            {addRow('Monday', hoursConfig.Monday)}
                            {addRow('Tuesday', hoursConfig.Tuesday)}
                            {addRow('Wednesday', hoursConfig.Wednesday)}
                            {addRow('Thursday', hoursConfig.Thursday)}
                            {addRow('Friday', hoursConfig.Friday)}
                            {addRow('Saturday', hoursConfig.Saturday)}
                        </>}
                        </tbody>
                    </table>
                }
                {holidays?.length > 0 && <>
                    { holidayHoursTitle && <h3>{holidayHoursTitle}</h3> }
                    <table>
                        <tbody>
                            {holidays.map((h: any) => addRow(parseAndFormat(h.HolidayDate), h, true))}
                        </tbody>
                    </table>
                </>}
                {additionalHoursText &&
                    <div className="font-bold">{additionalHoursText}</div>
                }
            </div>
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isProperlyConfigured(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const hoursStr = getPropertyValue(block.Properties, PropertyId.Hours);
    const holidayHoursStr = getPropertyValue(block.Properties, PropertyId.HolidayHours);
    const additionalHoursText = getPropertyValue(block.Properties, PropertyId.AdditionalHoursText);
    const holidayHoursTitle = getPropertyValue(block.Properties, PropertyId.HolidayHoursTitle);
        
    let hoursConfig = null;
    let holidays = null;

    if (hoursStr) {
        hoursConfig = JSON.parse(hoursStr);
    }
    
    if(holidayHoursStr) {
        holidays = JSON.parse(holidayHoursStr);

        holidays = holidays?.filter((h: any) => {
            const result = parse(h.HolidayDate, 'yyyy-MM-dd', new Date());
            const today = startOfDay(new Date());
            const threeWeeksOut = addDays(today, 21);
            const endMonth = endOfMonth(today);


            return result >= today
                && (result <= threeWeeksOut || result <= endMonth);
        });
    }

    
    const appendSearchableDay = (dayName: string, day: any, isHoliday: boolean = false) : string => {
        if (!day) {
            return ' ';
        }

        if (isHoliday && day.Type === _closedType) {
            return dayName + ' Closed';
        }

        if (day.Type === _regularHoursType) {
            return dayName + ' Regular Hours';
        }
        else if (isHoliday && day.Type === _open) {
            return dayName + ' Open'
        }
        else if (day.Type === _24HoursType) {
            return dayName + ' 24 Hours';
        }
        else {
            var returnStr = ' ';

            for (let i = 0; i < day.Hours.length; i++) {
                returnStr +=
                        (i === 0 ? dayName + ' ' : '')
                        + getHoursDisplay(day.Hours[i].StartTimeHour, day.Hours[i].StartTimeMinute)
                        + ' '
                        + getHoursAMPMDisplay(day.Hours[i].StartTimeHour)
                        + ' - '
                        + getHoursDisplay(day.Hours[i].EndTimeHour, day.Hours[i].EndTimeMinute)
                        + ' '
                        + getHoursAMPMDisplay(day.Hours[i].EndTimeHour)
                        + ' ';
            }
            return returnStr;
        }
    };

    let searchStr = 
        appendSearchableDay('Sunday', hoursConfig?.Sunday)
        + appendSearchableDay('Monday', hoursConfig?.Monday)
        + appendSearchableDay('Tuesday', hoursConfig?.Tuesday)
        + appendSearchableDay('Wednesday', hoursConfig?.Wednesday)
        + appendSearchableDay('Thursday', hoursConfig?.Thursday)
        + appendSearchableDay('Friday', hoursConfig?.Friday)
        + appendSearchableDay('Saturday', hoursConfig?.Saturday);

    holidays?.forEach((h: any) => {
        searchStr += appendSearchableDay(parseAndFormat(h.HolidayDate), h, true) + ' ';
    });
    
    return (!!additionalHoursText && (additionalHoursText.toLowerCase().includes(filterText)))
        || (!!holidayHoursTitle && (holidayHoursTitle.toLowerCase().includes(filterText)))
        || (!!searchStr && (searchStr.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}