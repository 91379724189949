import Property from "../types/Property";
import PropertyEditorFilterData from "../types/PropertyEditorFilterData";
import PropertyId from '../types/enum/PropertyId';
import PropertyValueType from '../types/enum/PropertyValueType';

export const filterProperties = (properties: Property[], filter: string, filterData: PropertyEditorFilterData
    , propertyRenderers: any[]) => {
  return properties.filter(p => 
    p?.Name?.toLowerCase().includes(filter) 
    || (propertyRenderers[p.ValueTypeId]?.containsValue 
        && propertyRenderers[p.ValueTypeId].containsValue(p, filter, filterData)));
}

export function GenerateProperty(enumId: PropertyId, value: string | null) : Property {
    let valueTypeId = enumId === PropertyId.AnchorConfig ? PropertyValueType.AnchorConfig : PropertyValueType.Text
    return {
        EnumId: enumId,
        Value: value,
        RenderedValue: value,
        ValueTypeId: valueTypeId,
        Configuration: "",
        ValueUpdatedInWizard: false,
        PropertyId: "",
        CategoryTypeId: 701,
        SiteId: null,
        PageId: null,
        Name: "",
        PropertyOrder: 0,
        BlockMappingId: null,
    };
}

export const BaseProperty: Property = {
    PropertyId: "",
    SiteId: null,
    PageId: null,
    Value: null,
    RenderedValue: null,
    ValueTypeId: PropertyValueType.Text,
    Configuration: null,
    ValueUpdatedInWizard: false,
    Name: "",
    PropertyOrder: 0,
    BlockMappingId: null,
};