import SearchFilterInput from "../../common/SearchFilterInput";
import SiteEditorStore from "../SiteEditorStore";

export default function SiteEditorPropertiesSearchFilter() {
    const updatePropertySearchCriteria = SiteEditorStore.useStoreActions((actions) => actions.updatePropertySearchCriteria);
    const updateExpandPropertyCategories = SiteEditorStore.useStoreActions((actions) => actions.updateExpandPropertyCategories);
    const propertySearchCriteria = SiteEditorStore.useStoreState((state) => state.propertySearchCriteria);

    return (
      <div style={{ marginBottom: "20px" }}>
        <SearchFilterInput 
          id="properties-search-filter" 
          placeholder="Search properties" 
          value={propertySearchCriteria} 
          onChange={(newValue, originalCase) => { 
            updatePropertySearchCriteria(newValue); 
            updateExpandPropertyCategories(true) 
          }}
        />
      </div>
    );
}