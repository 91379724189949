import { Button, CircularProgress, FormControlLabel, Popover, Switch, TextField } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import useApiPostCallbackAsync from "../../../../hooks/useApiPostCallbackAsync";
import InfiniteScroll from "react-infinite-scroll-component";
import VersionListItem from "./VersionListItem";
import Version from "../../../../types/Version";
import { addMinutes, addDays, format } from "date-fns";
import FormValidationError from "../../../common/FormValidationError";
import ErrorMessage from "../../../common/ErrorMessage";
import SuccessMessage from "../../../common/SuccessMessage";
import { ResponseError } from "../../../../utils/apiHelpers";
import NavBarButton from "../NavBarButton";
import VersionActionMenu from "./VersionActionMenu";
import SearchFilterInput from "../../../common/SearchFilterInput";
import SiteEditorStore from "../../SiteEditorStore";
import PageStatus from "../../../../types/enum/PageStatus";
import { getFirstNonCurrentPage } from "../../../../utils/pageListHelpers";
import { useNavigate } from "react-router-dom";

const statusTypeActive: number = 1801;
const versionTypeUser: number = 1901;

export interface VersionMenuProps {
    setQueueMessage: React.Dispatch<React.SetStateAction<string>>,
    setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
    setShowPublishQueued: React.Dispatch<React.SetStateAction<boolean>>,
    setShowPublishError: React.Dispatch<React.SetStateAction<boolean>>,
    setPollingSite: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentLastPublishedDate: React.Dispatch<React.SetStateAction<string | null>>,
    resetPublishMenuState: () => void
}

export default function VersionMenu({
    setQueueMessage,
    setSuccessMessage,
    setShowPublishQueued,
    setShowPublishError,
    setPollingSite,
    setCurrentLastPublishedDate,
    resetPublishMenuState
}: VersionMenuProps) {
    const [siteVersionCount, setSiteVersionCount] = useState<number>(0);
    const [showVersions, setShowVersions] = useState(false);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [showActivePreviews, setShowActivePreviews] = useState<boolean>(false);
    const [endDate, setEndDate] = useState(addMinutes(new Date(), 1));
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [versionsList, setVersionsList] = useState<Version[]>([]);
    const [versionSelected, setVersionSelected] = useState<Version | null>(null);
    const [addVersionMode, setAddVersionMode] = useState<boolean>(false);
    const [versionName, setVersionName] = useState<string>("");
    const [versionNameValid, setVersionNameValid] = useState<boolean | null>(null);
    const [addVersionsError, setAddVersionsError] = useState<ResponseError | null>(null);
    const [showAddVersionSuccess, setShowAddVersionSuccess] = useState<boolean>(false);
    const [actionSuccessMessage, setActionSuccessMessage] = useState<string>("");
    const [showActionSuccess, setShowActionSuccess] = useState<boolean>(false);
    const refreshPageList = SiteEditorStore.useStoreActions((actions) => actions.refreshPageList);
    const navBarButtonActive = SiteEditorStore.useStoreState((state) => state.navBarButtonActive);
    const updateNavBarButtonActive = SiteEditorStore.useStoreActions((actions) => actions.updateNavBarButtonActive);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const refreshBlocksAndSections = SiteEditorStore.useStoreActions((actions) => actions.refreshBlocksAndSections);
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const page = SiteEditorStore.useStoreState((state) => state.page);
    const refreshPage = SiteEditorStore.useStoreActions((actions) => actions.refreshPage);
    const navigate = useNavigate();

    const menuRef = useRef();
    const showSearchVersions = navBarButtonActive === "versions";

    useEffect(() => {
        if (!showSearchVersions) {
            resetComponent(null);
            setShowVersions(false);
            resetSearch();
            resetAddVersion();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navBarButtonActive])

    const [, versionCountCallback]
        = useApiPostCallbackAsync(`/site/${site?.Id}/version/count`, null) as any;
    const [{ isLoading: versionsIsLoading, errorResponse: versionsError}, versionsSearchCallback]
        = useApiPostCallbackAsync(`/site/${site?.Id}/version/search`, null) as any;
    const [, addVersionCallback]
        = useApiPostCallbackAsync(`/site/${site?.Id}/version`, null) as any;

    const resetVersionsCountAndList = () => {
        if (site?.Id) {
            versionCountCallback(
                { VersionTypeIds: [versionTypeUser], StatusTypeIds: [statusTypeActive] }
                , (data: number) => {
                    setSiteVersionCount(data);
                });
            loadVersions();
        }
    }

    useEffect(() => {
        if (!addVersionMode) {
            resetVersionsCountAndList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site, addVersionMode]);

    const loadVersions = () => {
        setPageNumber(1);
        setTotalPages(0);
        setEndDate(addMinutes(new Date(), 1));
        versionsSearchCallback({
            Filter: searchFilter,
            VersionTypeIds: [versionTypeUser],
            StatusTypeIds: [statusTypeActive],
            EndDate: endDate,
            ActivePreviewOnly: showActivePreviews,
            Page: 1,
            PageSize: 20
        }, (result: any) => {
            if (result?.Results) {
                setTotalPages(result.TotalPages);
                setVersionsList(result.Results as Version[]);
            }
        });
    }

    useEffect(() => {
        if (showSearchVersions && !addVersionMode) {
            loadVersions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSearchVersions, searchFilter, showActivePreviews]);

    const changePage = (pageId: string) => {
      if (site) {
        navigate(`/site/${site.Id}/page/${pageId}`);
      }
    }

    useEffect(() => {
        if (showVersions) {
            if (pageList.findIndex(p => p.Id === page?.PageId
                && p.StatusTypeId !== PageStatus.Deleted) === -1) {
                const newPageId = getFirstNonCurrentPage(pageList, page?.PageId ?? "");

                if (newPageId) {
                    changePage(newPageId);
                }
            }
            else {
                refreshPage();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageList]);

    const loadMoreVersions = () => {
        versionsSearchCallback({
            Filter: searchFilter,
            VersionTypeIds: [versionTypeUser],
            StatusTypeIds: [statusTypeActive],
            EndDate: endDate,
            ActivePreviewOnly: showActivePreviews,
            Page: pageNumber + 1,
            PageSize: 20
        }, (result: any) => {
            if (result?.Results) {
                setTotalPages(result.TotalPages);
                // If the new page of versions has a duplicate because a new
                // version is created within the minute of the initial page of
                // versions being retrieved then remove this duplicate
                setVersionsList([...versionsList, ...result.Results]
                    .filter((v,i,a) => a.findIndex(v2 => (v2.Id === v.Id))===i));
            }
        });
        setPageNumber(pageNumber + 1);
    };

    function selectVersion(versionId: string) {
        const selectedVersion = versionsList.find((i: Version) => {
            return i.Id === versionId
        });

        if (selectedVersion) {
            setVersionSelected(selectedVersion);
        }
        else {
            setVersionSelected(null);
        }
    }

    const dispatchVersionsButton = (isActive: boolean = false) => {
        updateNavBarButtonActive(isActive ? "versions" : null);
    }

    const toggleVersionsMenu = (e: any) => {
        if (showVersions) {
            setShowVersions(false);
            setVersionSelected(null);
            dispatchVersionsButton();
        } else {
            setShowVersions(true);
            dispatchVersionsButton(true);
        }
        e.stopPropagation();
    }

    const showActivePreviewsChange = (e:any) => {
        setShowActivePreviews(e.target.checked);
        e.stopPropagation();
    }

    const onVersionNameChange = (name:string) => {
        setVersionName(name);

        if (name) {
            setVersionNameValid(true);
        } else {
            setVersionNameValid(false);
        }
    }

    const resetSearch = () => {
        setSearchFilter("");
        setShowActivePreviews(false);
        setEndDate(addMinutes(new Date(), 1));
    }

    const resetAddVersion = () => {
        setVersionName("");
        setVersionNameValid(null);
        setAddVersionMode(false);
        setAddVersionsError(null);
    }

    const saveAddVersion = () => {
        setShowAddVersionSuccess(false);
        if (versionNameValid) {
            addVersionCallback({
                Name:versionName,
                AutoPublish:false,
                VersionType:versionTypeUser,
                PreviewExpiration:format(addDays(new Date(), 90), "yyyy-MM-dd")
            }, (result:any) => {
                setShowAddVersionSuccess(true);
                resetAddVersion();
                resetSearch();
            }, (err: ResponseError) => {
                setAddVersionsError(err);
            });
        }
        else {
            setVersionNameValid(false);
        }
    }

    const resetComponent = (message:string | null) => {
        setActionSuccessMessage("");
        setShowActionSuccess(false);
        if (message) {
            setActionSuccessMessage(message);
            setShowActionSuccess(true);
        }
        setVersionSelected(null);
        setVersionsList([]);
        setSiteVersionCount(0);
        resetVersionsCountAndList();
    }

    const versionUpdated = (message: string | null) => {
        refreshPageList();
        refreshBlocksAndSections();
        resetComponent(message);
    }

    const status = siteVersionCount === 0 ? "no past versions" : `${siteVersionCount} past version${siteVersionCount > 1 ? "s": ""}`;

    return (
    <>
        <NavBarButton
            buttonClick={toggleVersionsMenu}
            label={"Versions"}
            icon={null}
            status={status}
            menuRef={menuRef}
        ></NavBarButton>
        <Popover
            id="versions-list"
            open={showVersions && showSearchVersions}
            anchorEl={menuRef.current}
            onClose={toggleVersionsMenu}
            classes={{
                root: "below-nav",
                paper: "below-nav"
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {!versionSelected && !addVersionMode &&
                <div style={{ width: "480px", padding: "16px", boxSizing: "border-box", maxHeight:"calc(100vh - 80px)" }}>
                    <div style={{marginBottom:"16px"}}>
                        <SearchFilterInput
                            id="versions-search-filter"
                            placeholder="Search versions"
                            value={searchFilter}
                            onChange={(newValue, originalCase) => { setSearchFilter(newValue); }}
                            noResults={false}
                        />
                    </div>
                    <div style={{marginBottom:"24px"}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showActivePreviews}
                                    onChange={showActivePreviewsChange}
                                />}
                            label="Show active previews"
                        />
                    </div>
                    {versionsList.length === 0 && versionsIsLoading &&
                        <div style={{textAlign:"center", marginBottom:"49px"}}>
                            <CircularProgress color="secondary" />
                        </div>
                    }
                    {versionsList.length === 0 && !versionsIsLoading &&
                        <div style={{fontWeight:"bold", marginBottom:"49px"}}>No versions saved</div>
                    }
                    {versionsList.length > 0 &&
                        <div style={{maxHeight:"calc(100vh - 282px)"}}>
                            <div
                                id="versionsScrollDiv"
                                className="hideScrollbars"
                                style={{
                                    width: "100%",
                                    overflowY: "auto",
                                    maxHeight: "calc(100vh - 354px)",
                                    marginBottom: "24px",
                                    height: '100%'
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={versionsList.length}
                                    next={loadMoreVersions}
                                    hasMore={totalPages > pageNumber}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="versionsScrollDiv"
                                    style={{ display: "flex", flexWrap: "wrap", gap: "32px", justifyContent: "space-around", overflow:"initial" }}
                                >
                                    {versionsList.map((item: Version) =>
                                        <VersionListItem
                                            key={item.Id}
                                            version={item}
                                            handleSelect={selectVersion}
                                        />
                                    )}
                                </InfiniteScroll>
                            </div>
                        </div>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        fullWidth={true}
                        onClick={() => {setAddVersionMode(true);}}
                    >Save A new Version</Button>
                </div>
            }
            {versionSelected &&
                <VersionActionMenu
                    version={versionSelected}
                    clearVersionSelected={() => setVersionSelected(null)}
                    versionUpdated={versionUpdated}
                    setQueueMessage={setQueueMessage}
                    setSuccessMessage={setSuccessMessage}
                    setShowPublishQueued={setShowPublishQueued}
                    setShowPublishError={setShowPublishError}
                    setPollingSite={setPollingSite}
                    setCurrentLastPublishedDate={setCurrentLastPublishedDate}
                    resetPublishMenuState={resetPublishMenuState}
                ></VersionActionMenu>
            }
            {addVersionMode &&
                <div style={{ width: "480px", padding: "16px", boxSizing: "border-box" }}>
                    <>
                        <div className="headline6">Save Version</div>
                        <div className="body1" style={{ marginTop: "10px", marginBottom: "32px" }}>Saving a version allows you to revert the site back to how it currently is.</div>
                        <TextField id="versionName" value={versionName} onChange={(e) => onVersionNameChange(e.target.value) } label="Version name" variant="outlined" fullWidth color="secondary" />
                        <FormValidationError valid={versionNameValid === null || versionNameValid === true} message={"Version name is required."} />
                        <div style={{ marginTop: "32px", display: "flex", justifyContent: "space-between" }}>
                            <Button
                                color="secondary"
                                onClick={resetAddVersion}>
                                    Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={saveAddVersion}>
                                    Save
                            </Button>
                        </div>
                    </>
                </div>
            }
        </Popover>
        <ErrorMessage
            message="Now hold on! Please correct the following errors before saving this version."
            duration={15000}
            visible={versionNameValid === false}
        />
        <ErrorMessage
            message="We've run into an issue while loading site versions. Please try again later."
            duration={15000}
            apiError={versionsError}
            visible={versionsError}
        />
        <ErrorMessage
            message="We've run into an issue while saving a site version. Please try again later."
            duration={15000}
            apiError={addVersionsError}
            visible={addVersionsError !== null}
        />
        <SuccessMessage
            message={"Version saved!"}
            dismissable={true}
            duration={3000}
            visible={showAddVersionSuccess}
        />
        <SuccessMessage
            message={actionSuccessMessage}
            dismissable={true}
            duration={3000}
            visible={showActionSuccess}
        />

    </>
);
}