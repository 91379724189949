import Property from "../../types/Property";
import { HelpTooltip } from "./HelpTooltip";

export interface PropertyNameLabelProps {
    property: Property | null,
    labelId?: string | undefined,
    labelOverride?: string | undefined
}

export function PropertyNameLabel({property, labelId, labelOverride}: PropertyNameLabelProps) {

    const labelText = labelOverride || property?.Name;
    const propertyConfig = property?.Configuration ? JSON.parse(property?.Configuration) : {};

    return (
        <>
            {labelText &&
                <div 
                    style={{position:'relative', width:'100%'}} 
                    data-testid={`property-name-label-${labelText.replaceAll(' ', '-').replaceAll(/["']+/g, '').toLowerCase()}`}
                >
                    <label
                        id={labelId}
                        className="body1"
                    >
                        {labelText}
                    </label>
                    {propertyConfig?.Tooltip &&
                        <HelpTooltip tooltipText={propertyConfig?.Tooltip}></HelpTooltip>
                    }
                </div>
            }
        </>
    );
}