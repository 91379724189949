import { ErrorAlertMessage } from "./AlertMessage";
import { Box } from "@mui/material";
import ErrorMessageProps from "../../types/ErrorMessageProps";

export default function ErrorMessage({message, actionName, action, visible, duration, apiError, dismissable = false}: ErrorMessageProps) {

    if (!visible) {
        return null;
    }

    if (apiError && apiError.body?.Errors?.length > 0) {
        message = 
            <Box>
                {message}
                <br />
                <ul style={{ paddingLeft: "20px"}}>
                    {apiError.body.Errors.map((e: any, i: number) => <li key={i}>{e.Message}</li>)}
                </ul>
            </Box>;
    }
    
    return (
        <Box sx={{ maxWidth: "400px", margin: "0 auto", padding: "1rem", position: "fixed", top: "50px", left: 0, right: 0, zIndex: 9999 }}>
            <ErrorAlertMessage filled={true} message={message} actionName={actionName} action={action} visible={visible} duration={duration} dismissable={dismissable} />
        </Box>
    );
}

