import { getDynamicAttributes, getPropertyValue } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import PropertyId from '../../../types/enum/PropertyId';

export function isConfiguredProperly(block: BlockMapping, page: Page) {
    const siteKey = getPropertyValue(page?.SiteProperties, PropertyId.GoogleRecaptchaSiteKey);
    const secretKey = getPropertyValue(page?.SiteProperties, PropertyId.GoogleRecaptchaSecretKey);
    const reviewsId = getPropertyValue(block.Properties, PropertyId.ReviewsId);

    return siteKey && secretKey && reviewsId;
};

export function Block(props: BlockProps) {
    const businessName = getPropertyValue(props.page?.SiteProperties, PropertyId.BusinessName);

    if (!isConfiguredProperly(props.block, props.page)) {
        return <></>;
    }

    return (
        <form 
            {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: "container max-w-2xl mx-auto p-8 text-center", blockWrapperData: props.blockWrapperData})} 
        >
            <h2 className="mb-8">Check in with {businessName}</h2>
            <div className="form-control mb-4">
                <label htmlFor="checkin-form-name" className="label">
                    <span className="label-text">Full Name</span>
                </label>
                <input id="checkin-form-name"  name="name" className="input input-bordered" placeholder="Thomas Smith" required />
            </div>
            <div className="form-control mb-4">
                <label htmlFor="checkin-form-phone" className="label">
                    <span className="label-text">Phone Number</span>
                </label>
                <input id="checkin-form-phone" name="phone" type="tel" className="input input-bordered" placeholder="(740) 123-4556" required />
            </div>
            <div className="form-control mb-4">
                <label htmlFor="checkin-form-email" className="label">
                    <span className="label-text">Email Address</span>
                </label>
                <input id="checkin-form-email" name="email" type="email" className="input input-bordered" placeholder="thomas@gmail.com" required />
            </div>
            <div className="form-control flex flex-row mt-6">
                <input id="checkin-form-sms" name="smsenabled" type="checkbox" className="checkbox checkbox-primary" checked required onChange={() => {}} />
                <label htmlFor="checkin-form-sms" className="label-text ml-4 text-left">I agree with the <label htmlFor="checkinformprivacy" className="underline cursor-pointer primary-bg-ascolor" >privacy policy</label> of {props.page.Domain} and to receive SMS from {businessName}. Message and data rates may apply. Text STOP to unsubscribe.</label>
            </div>

            <div className="grecaptcha">
                <img src="/images/recaptcha.png" alt="Google Recaptcha" style={{ width: '300px', maxWidth: '300px' }} className="g-recaptcha" />
            </div>

            <button className="btn btn-wide btn-primary mt-12" type="button" disabled>SUBMIT</button>
        </form>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    const reviewsId = getPropertyValue(block.Properties, PropertyId.ReviewsId);
    const siteKey = getPropertyValue(page ? page.SiteProperties : [], PropertyId.GoogleRecaptchaSiteKey);
    const secretKey = getPropertyValue(page ? page.SiteProperties : [], PropertyId.GoogleRecaptchaSecretKey);

    return (reviewsId && siteKey && secretKey) ? true : false;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string): boolean {
    return ('checkin form'.includes(filterText))
        || ('check in form'.includes(filterText))
        || defaultFilter(block, filterText);
}