const enum SidebarState {
  Closed,
  BlocksOpen,
  PagesOpen,
  HierarchyOpen,
  PageTemplatesOpen,
  AddPageFormOpen
}

export default SidebarState;
