import { Button, Dialog, DialogActions, DialogContent, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useApiPostCallbackAsync from "../../../../hooks/useApiPostCallbackAsync";
import PageInfo from "../../../../types/PageInfo";
import Property from "../../../../types/Property";
import { ResponseError } from "../../../../utils/apiHelpers";
import { GLOBAL_NAVIGATION_PROPERTY_ID } from "../../../../utils/globalNavHelpers";
import FormValidationError from "../../../common/FormValidationError";
import ErrorMessage from "../../../common/ErrorMessage";
import Loading from "../../../common/Loading";
import PageStatus from "../../../../types/enum/PageStatus";
import { Editor as TextEditorProperty } from "../../../property_editors/TextEditorProperty";
import SiteEditorStore from "../../SiteEditorStore";
import { useNavigate } from "react-router-dom";

interface SiteEditorSideBarAddPagePropertiesProps {
  addPage: PageInfo | null,
  open: boolean,
  cancel: () => void,
  complete: () => void,
  errorMessage: string,
  callbackUri: string
}

export default function SiteEditorSideBarAddPageProperties({
  addPage,
  open,
  cancel,
  complete,
  errorMessage,
  callbackUri
} : SiteEditorSideBarAddPagePropertiesProps) {
  const [pageName, setPageName] = useState<string | null>(addPage?.Name || null);
  const [pageRoute, setPageRoute] = useState<string | null>(addPage?.Route || null);
  const [pageTitle, setPageTitle] = useState<Property | null>(addPage?.TitleProperty || null);
  const [metaDescription, setMetaDescription] = useState<Property | null>(addPage?.MetaDescriptionProperty || null);
  const [addToGlobalNav, setAddToGlobalNav] = useState<boolean>(true);
  const [addPageTitleUpdated, setAddPageTitleUpdated] = useState<boolean>(false);
  const [addPageMetaDescriptionUpdated, setAddPageMetaDescriptionUpdated] = useState<boolean>(false);
  const [addPageFormValid, setAddPageFormValid] = useState<boolean>(true);
  const [addPageError, setAddPageError] = useState<ResponseError | null>(null);
  const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
  const refreshPageList = SiteEditorStore.useStoreActions((actions) => actions.refreshPageList);
  const refreshBlocksAndSections = SiteEditorStore.useStoreActions((actions) => actions.refreshBlocksAndSections);
  const populatedDataSources = SiteEditorStore.useStoreState((state) => state.populatedDataSources);
  const siteproperties = SiteEditorStore.useStoreState((state) => state.siteProperties);
  const scrollToProperty = SiteEditorStore.useStoreActions((actions) => actions.scrollToProperty);
  const siteId = SiteEditorStore.useStoreState((state) => state.siteId);

  const navigate = useNavigate();

  const [{ isLoading: addPageLoading }, addPageCallback]
      = useApiPostCallbackAsync("", []) as any;

  const validateAddPageForm = (): boolean => {
    let valid = true;

    const routeList = pageList
      .filter(p => p.StatusTypeId !== PageStatus.Deleted)
      .map(p => p.Route);

    for (let route of routeList) {
      if (route === pageRoute) {
        valid = false;
      }
    }

    valid = valid
      && pageName !== "" && pageName !== null
      && pageRoute !== "" && pageRoute !== null;
    setAddPageFormValid(valid);

    return valid;
  };

  useEffect(() => {
    validateAddPageForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRoute, pageName]);

  const pageTitleUpdated = (p: Property) => {
    setPageTitle(p);
    setAddPageTitleUpdated(true);
  };

  const metaDescriptionUpdated = (p: Property) => {
    setMetaDescription(p);
    setAddPageMetaDescriptionUpdated(true);
  }

  const submitAddPage = () => {
    setAddPageError(null);

    if (validateAddPageForm()) {
      addPageCallback({
          Title: addPageTitleUpdated && pageTitle?.Value ? pageTitle : null,
          MetaDescription: addPageMetaDescriptionUpdated && metaDescription?.Value ? metaDescription : null,
          Route: pageRoute,
          Name: pageName,
          IncludeInGlobalNavigation: addToGlobalNav
        }, (res: string) => {
          complete();
          refreshPageList();
          refreshBlocksAndSections();
          if (addToGlobalNav) {
            scrollToProperty({ propertyTab: 0, propertyId: GLOBAL_NAVIGATION_PROPERTY_ID });
          }
          navigate(`/site/${siteId}/page/${res}`);
        }, (err: ResponseError) => {
          setAddPageError(err);
        }
        ,  callbackUri
        );
    }
  }

  const cancelAddPage = () => {
    setPageName(addPage?.Name || null);
    setPageRoute(addPage?.Route || null);
    setPageTitle(addPage?.TitleProperty || null);
    setMetaDescription(addPage?.MetaDescriptionProperty || null);
    setAddPageTitleUpdated(false);
    setAddPageMetaDescriptionUpdated(false);
    setAddPageFormValid(true);
    setAddPageError(null);
    cancel();
  }

  return (
    <Dialog
    fullScreen={false}
        open={open}
        onClose={() => {}}
    >
      <ErrorMessage
        message={errorMessage}
        dismissable={true}
        visible={addPageError != null}
        apiError={addPageError}
      />
      { addPageLoading && <Loading message="" /> }
      <DialogContent sx={{ padding: "15px", width: "500px" }}>
        <div className="headline6" style={{ textAlign: "center", marginBottom: "32px" }}>Page Properties</div>
        <div className="body1" style={{ marginBottom: "8px" }}>
          <label htmlFor="page-name">Page Name</label>
        </div>
        <TextField
          id="page-name"
          variant="outlined"
          fullWidth
          value={pageName}
          onChange={(e) => { setPageName(e.target.value); }}
          inputProps={{ maxLength: 200 }}
        />
        <div className="body1" style={{ marginBottom: "8px", marginTop: "32px" }}>
          <label htmlFor="page-route">Page Slug</label>
        </div>
        <TextField
          id="page-route"
          variant="outlined"
          fullWidth
          value={pageRoute}
          onChange={(e) => { setPageRoute(e.target.value); }}
          inputProps={{ maxLength: 2000 }}
        />
        <FormValidationError
          valid={addPageFormValid}
          message={"Path is in use."}
        />
        <div style={{ marginTop: "32px", marginBottom: "32px" }}>
          { pageTitle &&
            <TextEditorProperty property={pageTitle} label="Page Title"
              propertyUpdated={pageTitleUpdated}
              isPageEditor={true}
              populatedDataSources={populatedDataSources}
              siteProperties={siteproperties}
              pageList={pageList}
            />
          }
        </div>
        <div style={{ marginTop: "32px", marginBottom: "32px" }}>
          { metaDescription &&
            <TextEditorProperty property={metaDescription} label="Meta Description"
              propertyUpdated={metaDescriptionUpdated}
              isPageEditor={true}
              populatedDataSources={populatedDataSources}
              siteProperties={siteproperties}
              pageList={pageList}
            />
          }
        </div>
        <div className="body1" style={{ marginBottom: "8px", marginTop: "32px" }}>
          <label htmlFor="add-to-global-nav">Add to Global Navigation</label>
        </div>
        <div>
          <Switch
              id="add-to-global-nav"
              checked={addToGlobalNav}
              onChange={() => {setAddToGlobalNav(!addToGlobalNav)}}
              data-testid="IncludeInGlobalNavToggleSwitch"
          />
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between", padding:'16px' }}>
        <Button variant="text" onClick={cancelAddPage}>Cancel</Button>
        <Button variant="outlined" onClick={submitAddPage} autoFocus disabled={!addPageFormValid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}