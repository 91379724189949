import React, { useState } from "react";
import { Backdrop, Dialog, Divider, Paper, Popper, Typography } from "@mui/material";
import Loading from "../../../common/Loading";
import PageInfo from "../../../../types/PageInfo";
import SiteEditorSideBarAddPageProperties from "./SiteEditorSideBarAddPageProperties";
import PageStatus from "../../../../types/enum/PageStatus";
import BuilderDialog from "../../../common/BuilderDialog";
import { ResponseError } from "../../../../utils/apiHelpers";
import useApiPutCallbackAsync from "../../../../hooks/useApiPutCallbackAsync";
import ErrorMessage from "../../../common/ErrorMessage";
import { GLOBAL_NAVIGATION_PROPERTY_ID, pageExistsInGlobalNav } from "../../../../utils/globalNavHelpers";
import SiteEditorStore from "../../SiteEditorStore";

interface PageListItemContextMenuProps {
  pageContextMenuOpen: boolean
  pageContextMenuRef: React.RefObject<HTMLDivElement | null>,
  openProperties: () => void,
  setDuplicateOpen: React.Dispatch<React.SetStateAction<boolean>>,
  changePage: (pageId: string) => void,
  pageInfo: PageInfo,
  duplicateOpen: boolean,
  closeModal: () => void,
  closeMenus: () => void,
  setDisableOpen: React.Dispatch<React.SetStateAction<boolean>>,
  disableOpen: boolean,
  enablePageLoading: boolean,
  submitEnablePage: () => void,
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>,
  deleteOpen: boolean,
  completeDelete: () => void,
  submitDisablePage: any,
  submitDeletePage: any
}

export default function PageListItemContextMenu({
  pageContextMenuOpen,
  pageContextMenuRef,
  openProperties,
  setDuplicateOpen,
  changePage,
  pageInfo,
  duplicateOpen,
  closeModal,
  closeMenus,
  setDisableOpen,
  disableOpen,
  enablePageLoading,
  submitEnablePage,
  setDeleteOpen,
  deleteOpen,
  submitDisablePage,
  submitDeletePage
}: PageListItemContextMenuProps) {
  const siteProperties = SiteEditorStore.useStoreState((state) => state.siteProperties);
  const existsInGlobalNav = pageExistsInGlobalNav(pageInfo.Id, siteProperties);
  const site = SiteEditorStore.useStoreState((state) => state.site);
  const scrollToProperty = SiteEditorStore.useStoreActions((actions) => actions.scrollToProperty);
  const refreshPage = SiteEditorStore.useStoreActions((actions) => actions.refreshPage);

  const pageDisabled = pageInfo?.StatusTypeId === PageStatus.Inactive;

  const [updateGlobalNavError, setUpdateGlobalNav] = useState<ResponseError | null>(null);

  const [, updateGlobalNavCallback] =
    useApiPutCallbackAsync(
      `/site/${pageInfo.SiteId}/page/${pageInfo.Id}/globalnavigation/${!existsInGlobalNav}`,
      null
    ) as any;

  const updateGlobalNav = () => {
    setUpdateGlobalNav(null);
    updateGlobalNavCallback({}, (res: string) => {
      closeMenus();
      refreshPage();
      if (!existsInGlobalNav) {
        scrollToProperty({ propertyTab: 0, propertyId: GLOBAL_NAVIGATION_PROPERTY_ID });
      }
    }, (err: ResponseError) => {
      setUpdateGlobalNav(err);
    })
  }

  return (<>
    <ErrorMessage
      message={"We ran into a problem updating the global navigation. Please try again later."}
      dismissable={true}
      visible={updateGlobalNavError != null}
      apiError={updateGlobalNavError}
    />
    <Backdrop
      open={pageContextMenuOpen}
      onClick={(e) => {
        e.stopPropagation();
        closeMenus();
      }}
      style={{
        opacity: 0,
        zIndex: 1200,
        cursor: "initial"
      }}
    >
      <Popper
        id="popper"
        open={ pageContextMenuRef?.current ? pageContextMenuOpen : false }
        anchorEl={pageContextMenuRef?.current}
        placement={'bottom-start'}
        style={{ zIndex: 1300 }}
        onClick={ (e) => { e.stopPropagation(); } }
      >
        <Paper
          elevation={4}
          sx={{
            padding: 2,
            lineHeight: 1.5,
            width: 304
          }}
        >
          <p
            onClick={openProperties}
          >Page properties</p>
          <Divider />
          <p
            onClick={ () => { setDuplicateOpen(true); } }
          >Duplicate</p>
          <SiteEditorSideBarAddPageProperties
            addPage={pageInfo}
            open={duplicateOpen}
            cancel={closeModal}
            complete={closeMenus}
            errorMessage={"Error cloning page"}
            callbackUri={`/site/${site?.Id}/page/${pageInfo.Id}/clone`}
          />
          <Divider />
          { !pageDisabled && <>
            { !existsInGlobalNav &&
              <p onClick={updateGlobalNav}>
                Add to Global Navigation</p>
            }
            { existsInGlobalNav &&
              <p onClick={updateGlobalNav}>
                Remove from Global Navigation</p>
            }
            <Divider />
          </>}
          { pageInfo.StatusTypeId === PageStatus.Inactive &&
          <>
            <Dialog
              open={enablePageLoading}
            >
              <Loading message="" />
            </Dialog>
            <p
              onClick={submitEnablePage}
            >Enable page</p>
          </>
          }
          { pageInfo.StatusTypeId === PageStatus.Active &&
          <>
            <p
              onClick={ () => { setDisableOpen(true); } }
            >Disable page</p>
            <BuilderDialog
              title={"Disable Page"}
              message={"Are you sure you wish to disable this page? This page will be hidden from all navigtion blocks and all traffic to this page will be sent to a 404 splash page until this page is enabled again."}
              action={submitDisablePage}
              actionName={"Disable"}
              open={disableOpen}
              onClose={closeModal}
              showCancel={true}
              danger={true}
            />
          </>
          }
          <Divider />
          <Typography sx={{ marginY: 2, color: '#CF1F2E' }}
            onClick={ () => { setDeleteOpen(true); } }
          >Delete</Typography>
          <BuilderDialog
            title={"Delete Page"}
            message={"Are you sure you wish to delete this Page? This is a permanent action and cannot be undone. All links to this page in the code will break."}
            cancelTestId="delete-page-cancel"
            actionTestId="delete-page-confirm"
            action={submitDeletePage}
            actionName={"Delete"}
            open={deleteOpen}
            onClose={closeModal}
            showCancel={true}
            danger={true}
          />
        </Paper>
      </Popper>
    </Backdrop>
    </>
  )
}