import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Version from "../../../../types/Version";
import { addDays, format } from "date-fns";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useState } from "react";
import { ResponseError } from "../../../../utils/apiHelpers";
import { Button, DialogContent, FormControlLabel, IconButton, InputAdornment, OutlinedInput, Switch, TextField } from "@mui/material";
import useApiPutCallbackAsync from "../../../../hooks/useApiPutCallbackAsync";
import useApiPostCallbackAsync from "../../../../hooks/useApiPostCallbackAsync";
import ErrorMessage from "../../../common/ErrorMessage";
import BuilderDialog from "../../../common/BuilderDialog";
import FormValidationError from "../../../common/FormValidationError";
import Loading from "../../../common/Loading";
import Site from "../../../../types/Site";
import SiteEditorStore from "../../SiteEditorStore";

export interface VersionActionMenuProps {
    version: Version, 
    clearVersionSelected: () => void, 
    versionUpdated: (successMessage: string | null) => void,
    setQueueMessage: React.Dispatch<React.SetStateAction<string>>,
    setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
    setShowPublishQueued: React.Dispatch<React.SetStateAction<boolean>>,
    setShowPublishError: React.Dispatch<React.SetStateAction<boolean>>,
    setPollingSite: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentLastPublishedDate: React.Dispatch<React.SetStateAction<string | null>>,
    resetPublishMenuState: () => void,
}

export default function VersionActionMenu({
    version,
    clearVersionSelected,
    versionUpdated,
    setQueueMessage,
    setSuccessMessage,
    setShowPublishQueued,
    setShowPublishError,
    setPollingSite,
    setCurrentLastPublishedDate,
    resetPublishMenuState
}: VersionActionMenuProps) {
    const [versionsError, setVersionsError] = useState<ResponseError | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showPublishDialog, setShowPublishDialog] = useState<boolean>(false);
    const [showRevertDialog, setShowRevertDialog] = useState<boolean>(false);
    const [saveVersion, setSaveVersion] = useState<boolean>(false);
    const [versionName, setVersionName] = useState<string | null>(null);
    const [showRevertVersionError, setShowRevertVersionError] = useState<boolean>(false);
    const [runningApiAction, setRunningApiAction] = useState(false);
    const [versionUrlCopied, setVersionUrlCopied] = useState(false);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const refreshSite = SiteEditorStore.useStoreActions((actions) => actions.refreshSite);
    const siteId = site?.Id;
    
    const [, deleteVersionCallback] 
        = useApiPutCallbackAsync(`/site/${siteId}/version/${version.Id}/status/1802`, null) as any;
    const [, publishVersionCallback]
        = useApiPostCallbackAsync(`/sites/${siteId}/status`, null) as any;
    const [, updateVersionPreviewExpirationCallback] 
        = useApiPutCallbackAsync(`/site/${siteId}/version/${version.Id}/previewexpiration`, null) as any;
    const [, revertVersionCallback] 
        = useApiPostCallbackAsync(`/site/${siteId}/version/${version.Id}/revert`, null) as any;
    const [, createVersionCallback] 
        = useApiPostCallbackAsync(`/site/${siteId}/version`, null) as any;

    const resetMessages = () => {
        setVersionsError(null);
        setShowRevertVersionError(false);
        resetPublishMenuState();
    }

    const copyVersionUrl = () => {
        setVersionUrlCopied(true);
        navigator.clipboard.writeText(version.PreviewUrl ?? '')
    }

    const deleteVersion = () => {
        setVersionsError(null);
        setShowDeleteDialog(false);
        setRunningApiAction(true);

        deleteVersionCallback(null, (result:any) => {
            versionUpdated("Version deleted!");
            setRunningApiAction(false);
        }, (err: ResponseError) => {
            setVersionsError(err);
            setRunningApiAction(false);
        })
    }

    const startPublishRequest = (site: Site) => {
        setCurrentLastPublishedDate(site.LastPublishedDate);
        publishVersionCallback(
            {
                Status:3,
                PublishedVersionId:version.Id,
                RedirectUrl: null
            }
            , () => { 
                setShowPublishQueued(true);
                setPollingSite(true);
                setRunningApiAction(false);
            }
            , () => {
                setShowPublishError(true);
                setRunningApiAction(false);
            });
    };

    const publishVersion = async () => {
        resetMessages();
        setShowPublishDialog(false);
        setRunningApiAction(true);
        setQueueMessage("Site update has been queued.");
        setSuccessMessage("Site updated!");
        const site = await refreshSite();
        startPublishRequest(site);
    }

    const enablePreview = () => {
        resetMessages();
        setRunningApiAction(true);

        updateVersionPreviewExpirationCallback(
            format(addDays(new Date(), 90), "yyyy-MM-dd")
            , (result:any) => {
                versionUpdated("Version preview enabled");
                setRunningApiAction(false);
            }, (err: ResponseError) => {
                setVersionsError(err);
                setRunningApiAction(false);
        })
    }

    const disablePreview = () => {
        resetMessages();
        setRunningApiAction(true);

        updateVersionPreviewExpirationCallback(null
            , (result:any) => {
                versionUpdated("Preview version disabled");
                setRunningApiAction(false);
            }, (err: ResponseError) => {
                setVersionsError(err);
                setRunningApiAction(false);
            })
    }

    const callRevertVersion = () => {
        setRunningApiAction(true);
        setShowRevertDialog(false);

        revertVersionCallback(null
            , (result:any) => {
                versionUpdated("Site reverted to selected version");
                setRunningApiAction(false);
            }, (err: ResponseError) => {
                setVersionsError(err);
                setRunningApiAction(false);
                setShowRevertDialog(true);
            })
    }

    const revertVersion = () => {
        resetMessages();

        if (saveVersion && !versionName) {
            if (versionName === null) {
                setVersionName("");
            }
            setShowRevertVersionError(true);
            return;
        }

        if (saveVersion) {
            setRunningApiAction(true);
            setShowRevertDialog(false);

            createVersionCallback({
                Name:versionName,
                AutoPublish:false,
                VersionType:1901,
                PreviewExpiration:format(addDays(new Date(), 90), "yyyy-MM-dd")
            }, (result:any) => {
                callRevertVersion();
            }, (err: ResponseError) => {
                setVersionsError(err);
                setRunningApiAction(false);
                setShowRevertDialog(true);
            });
        } 
        else {
            callRevertVersion();
        }
    }

    const saveVersionChanged = () => {
        if (saveVersion) {
            setSaveVersion(false);
            setVersionName(null);
        } else {
            setSaveVersion(true);
        }
    };

    const resetVersionDialog = () => {
        setShowRevertDialog(false);
        setVersionName(null);
        setSaveVersion(false);
        setShowRevertVersionError(false);
    }

    const updateVersionName = (value: string) => {
        setVersionName(value);
        setShowRevertVersionError(false);
    }

    const menuItemStyle = { paddingLeft: "5px", marginLeft: "-5px", marginRight: "-5px" };
    const copyIcon = versionUrlCopied ? <CheckIcon /> : <ContentCopyIcon />;
    const publishDialogMessage = version.PreviewDaysRemaining !== null 
        ? "Confirm this version is what you expect to publish by reviewing the preview link below:"
        : "If you would like to review this version before publishing, please enable the preview for this version.";

    return (
        <>
            { runningApiAction && <Loading message="" /> }
            <div style={{ width: "480px", padding: "16px", boxSizing: "border-box" }}>
                <div
                    style={{ 
                        display: "flex", 
                        alignItems: "center", 
                        marginBottom:"32px"
                    }}
                >
                    <div 
                        data-testid="versions-return-btn"
                        style={{ marginRight: "8px", cursor: "pointer" }}
                        onClick={clearVersionSelected}
                    >
                        <ChevronLeftIcon />
                    </div>
                    <div style={{flexGrow:"1"}}>
                        <div style={{
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "24px",
                            marginBottom: "8px"
                            }}
                        >{version.Name}</div>
                        <div data-testid="versions-date-user" className="caption" style={{ wordBreak:"break-all" }}>
                            {format(new Date(version.VersionDate), "M/d/yyyy h:mm aa")}<br />
                            {version.CreatedByUser}
                        </div>
                    </div>
                    {version.PreviewDaysRemaining && version.PreviewUrl &&
                        <Button 
                            variant="outlined" 
                            onClick={copyVersionUrl}
                            color="secondary" 
                            startIcon={copyIcon}
                            sx={{minWidth:"130px", marginLeft:"16px"}}
                        >
                            Copy Link
                        </Button>
                    }
                </div>
                <MenuList
                    id="version-menu-list"
                    style={{ width: "100%" }}
                >
                    <MenuItem 
                        onClick={() => {setShowDeleteDialog(true)}} 
                        sx={{ color: "#CF1F2E", ...menuItemStyle }}>
                        Delete this version
                    </MenuItem>
                    <Divider />
                    <MenuItem 
                        onClick={() => setShowPublishDialog(true)} 
                        sx={menuItemStyle}
                    >
                        Publish this version
                    </MenuItem>
                    <Divider />
                    {version.PreviewDaysRemaining && version.PreviewUrl &&
                        <HasPreviewMenuItem />
                    }
                    {!version.PreviewDaysRemaining &&
                        <NoPreviewMenuItem />
                    }
                    <MenuItem 
                        onClick={() => {setShowRevertDialog(true)}} 
                        sx={menuItemStyle}>
                        Revert builder to this version
                    </MenuItem>
                </MenuList>
            </div>
            <BuilderDialog 
                title="Delete Version" 
                message="Are you sure you wish to delete this version? References and preview links to this version will no longer work and you will not be able to revert back to this version."
                action={deleteVersion}
                actionName="Delete"
                open={showDeleteDialog}
                actionButtonType="contained"
                onClose={() => setShowDeleteDialog(false) }
                danger={true}
                showCancel={true}
            />
            <BuilderDialog 
                title="Publish Version" 
                message={publishDialogMessage}
                action={publishVersion}
                actionName="Publish"
                open={showPublishDialog}
                actionButtonType="contained"
                onClose={() => setShowPublishDialog(false) }
                showCancel={true}
                boldenMessage={true}
            >
                {version.PreviewDaysRemaining !== null &&
                    <DialogContent>
                        <OutlinedInput
                            id="outlined-adornment-preview"
                            type="text"
                            value={version.PreviewUrl}
                            onChange={() => {}}
                            fullWidth
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {window.open(version.PreviewUrl ?? '', "_blank")}}
                                    edge="end"
                                >
                                    <OpenInNewIcon />
                                </IconButton>
                            </InputAdornment>
                            }
                            label={undefined}
                        />
                    </DialogContent>
                }
            </BuilderDialog>
            <BuilderDialog 
                title="Revert to Version" 
                message="Are you sure you wish to revert to this version? This will overwrite any current changes and publish this version as the live site."
                action={revertVersion}
                actionName="Revert"
                open={showRevertDialog}
                actionButtonType="contained"
                onClose={resetVersionDialog}
                showCancel={true}
            >
                <DialogContent>
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={saveVersion} 
                                onChange={saveVersionChanged} 
                            />} 
                        label="Save a version of the current site before reverting?" 
                    />
                    {saveVersion &&
                        <>
                            <TextField 
                                id="publish-version-name" 
                                value={versionName} 
                                onChange={(e) => {updateVersionName(e.target.value)} } 
                                label="Version name" 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: "32px" }} 
                                color="secondary" />
                            <FormValidationError valid={versionName !== ''} message="Version name is required." />
                            <ErrorMessage 
                                message="Now hold on! Please correct the following errors before saving this version." 
                                duration={15000}
                                visible={showRevertVersionError}
                            />
                        </>
                    }
                </DialogContent>
            </BuilderDialog>
            <ErrorMessage 
                message="We've run into an issue while saving a site version. Please try again later." 
                duration={15000}
                apiError={versionsError}
                visible={versionsError !== null}
            />
        </>
    );

    function HasPreviewMenuItem() {
        return(
            <>
                <MenuItem 
                    onClick={() => {window.open(version.PreviewUrl ?? '', "_blank")}} 
                    sx={menuItemStyle}>
                    Preview this version
                </MenuItem>
                <Divider />
                <MenuItem 
                    onClick={disablePreview} 
                    sx={menuItemStyle}>
                    Disable preview
                </MenuItem>
                <Divider />
            </>
        );
    }

    function NoPreviewMenuItem() {
        return(
            <>
                <MenuItem 
                    onClick={enablePreview} 
                    sx={menuItemStyle}>
                    Enable preview
                </MenuItem>
                <Divider />
            </>
        );
    }
}