import { Pagination, Button, Divider } from '@mui/material';
import Block from '../../types/Block';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Template from '../../types/Template';

export interface SharedContentListProps {
    list: any,
    listLoading: boolean,
    setSearchRequest: (request: any) => void,
    goToEditor?: (id: string) => void,
    handleDelete: (id: string) => void,
    page: number,
    setPage: (newValue: number) => void
}

export default function SharedContentList({
        list,
        listLoading,
        setSearchRequest,
        goToEditor,
        handleDelete,
        page,
        setPage

    }: SharedContentListProps) {

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (page !== value) {
            setPage(value);
            setSearchRequest((r: any) => {
                return { 
                    ...r, 
                    Page: value
                }
            });
        }
    };

    return (
        <>
            { listLoading && 
                <div style={{ display: "flex", flex: "1", height: 'calc(100% - 64px)', justifyContent: "center" }} data-testid="loading-section">
                    <CircularProgress color="secondary" /> 
                </div>
            }
            { list?.Results?.length > 0 && !listLoading &&
                <div style={{ flex: "1", height: 'calc(100% - 64px)', overflow: "auto" }} data-testid="results-section">
                    {list.Results.map((item: Block | Template) => {
                        const category = (item as Block).CategoryName;
                        const features = (item as Template).Features;
                        const imageUrl = (item as Template).ScreenshotUrl;
                        return (
                            <div key={item.Id}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {imageUrl && <div style={{
                                        width: '184px',
                                        height: '100px',
                                        marginRight: '16px',
                                        backgroundImage: `url('${imageUrl}')`,
                                        backgroundSize: 'cover'
                                    }}></div>}

                                    <div style={{ display: "flex", width: '100%', flexDirection: "column", paddingRight: "32px" }} key={item.Id}>
                                        <div className="body1">{item.Name}</div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <p className="caption" style={{ maxWidth: "500px", wordBreak: "break-all" }}>{item.Description?.substring(0, 150) + (item.Description?.length > 150 ? '...' : '')}</p>
                                            <div style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
                                                {goToEditor && <Button color="secondary" onClick={() => goToEditor(item.Id)} startIcon={<EditIcon />}>Edit</Button>}
                                                <Button color="error" onClick={() => handleDelete(item.Id)} startIcon={<DeleteOutlineOutlinedIcon />}>Delete</Button>
                                            </div>
                                        </div>
                                        {category && <div className="body2">
                                            <span style={{ color: "#9CA3AF" }}>Category:</span> <Chip label={category} />
                                        </div>}
                                        {features && features.length > 0 && <div className="body2">
                                            <span style={{ color: "#9CA3AF"}}>Tags:</span>
                                            {features.map(feature => 
                                                <Chip style={{ margin: '4px' }} label={feature.Name} key={feature.Id} />
                                            )}
                                        </div>}
                                    </div>
                                </div>
                                <Divider sx={{ marginTop: "16px", marginBottom: "16px", marginRight:"32px" }} />
                            </div>
                        )
                    })}
                </div>
            }
            { list?.Results?.length > 0 && 
                <div style={{ display: "flex", justifyContent: "center", marginTop: "32px" }} data-testid="pagination-section">
                    <Pagination count={list.TotalPages} page={page} onChange={handlePageChange} />
                </div>
            }
        </>
    )
}