import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore, propertyHasValue, stripHtml } from '../../../utils/blockHelpers';
import DangerousText from '../DangerousText';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);
    const className = getPropertyValue(block.Properties, PropertyId.ClassName);
    const dynamicAttributes = getDynamicAttributes({block, builderProps, className, blockWrapperData});

    return (
        <span {...dynamicAttributes}>
            {isChildrenPositionBefore(block) && children}
            <DangerousText html={content}/>
            {!isChildrenPositionBefore(block) && children}
        </span>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return propertyHasValue(block.Properties, PropertyId.ContentHTML)
        || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    return content ? <>{stripHtml(content)}</> : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const content = getPropertyValue(block.Properties, PropertyId.ContentHTML);

    return (!!content && (content.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}