import { useEffect} from 'react';
import PropertyId from '../../../types/enum/PropertyId';
import Property from '../../../types/Property';
import { Box } from '@mui/material';
import { Editor as ImageCarouselSlidesProperty, MAX_SLIDES } from '../../property_editors/ImageCarouselSlidesProperty';

function ImageCarouselStep(props: any) {
    useEffect(() => {
        props.updatePreviewBlockId(props.blockmapping?.Id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const slidesProp = props.blockmapping?.Properties?.find((p: Property) => p.EnumId === PropertyId.ImageCarouselSlides);

    const propertyUpdated = (property: Property) => {
        property.ValueUpdatedInWizard = true;
        props.setTemplateData({...props.templateData});
    };

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Image Carousel</h1>
                <p className="body1">Add an image carousel to highlight up to {MAX_SLIDES} different images in one section.</p>
            </div>
            <Box sx={{ maxWidth: "550px" }}>
                <ImageCarouselSlidesProperty siteId={props.site?.Id} property={slidesProp} propertyUpdated={propertyUpdated} isPageEditor={false} pageList={[]} />
            </Box>
        </>
    );
}

export default ImageCarouselStep;
