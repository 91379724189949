import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Dialog, DialogActions, DialogContent, Button, Box, Select, MenuItem, InputLabel } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import InputAdornment from '@mui/material/InputAdornment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import DataSwap from '../../types/DataSwap';
import DataSwapCategory from '../../types/DataSwapCategory';
import Property from '../../types/Property';
import PopulatedDataSource from '../../types/PopulatedDataSource';
import { getValueFromToken } from '../../utils/valueSwapHelpers';
import './DataSwapModal.css';

function SwapValueCategory({dataSwapCategory, selectedToken, dataSwapSelected, defaultExpanded}: {dataSwapCategory: DataSwapCategory, selectedToken: string | null | undefined, dataSwapSelected: (ds:DataSwap) => void, defaultExpanded: boolean}) {
    return (
        <Box className="swap-value-category-accordion">
            <Accordion
                key={dataSwapCategory.name ?? ''}
                defaultExpanded={defaultExpanded}
                aria-label={`Accordion ${dataSwapCategory.category}`}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <SubdirectoryArrowRightIcon></SubdirectoryArrowRightIcon>
                    <Box sx={{
                            display: "flex",
                            marginLeft: "33px",
                            marginRight: "33px",
                            width: "calc(100% - 81px)",
                            overflow: "hidden"
                        }}
                    >
                        <Box className="subtitle1"
                            sx={{ whiteSpace: "nowrap", marginRight: "8px", textAlign: "left" }}>
                            <Box sx={{fontSize: "12px", lineHeight: "16px", marginBottom: "8px" }}>{dataSwapCategory.category}</Box>
                            <Box>{dataSwapCategory.name}</Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {dataSwapCategory.childCategories &&
                        dataSwapCategory.childCategories.map((c, i) =>
                            {
                                if (!c.hasChildValues) {
                                    return <div key={i}></div>;
                                }

                                return (<SwapValueCategory
                                    key={i}
                                    dataSwapCategory={c}
                                    selectedToken={selectedToken}
                                    dataSwapSelected={dataSwapSelected}
                                    defaultExpanded={defaultExpanded}
                                ></SwapValueCategory>);
                            }
                        )
                    }
                    {dataSwapCategory.values &&
                        dataSwapCategory.values.sort(function(a, b) {
                            if (a.name && b.name) {
                                const nameA = a.name.toUpperCase();
                                const nameB = b.name.toUpperCase();
                                if (nameA < nameB) return -1
                                if (nameA > nameB) return 1
                            }
                            return 0
                        }).map((v: DataSwap, i) =>
                            <SwapValueItem
                                key={i}
                                dataSwap={v}
                                selectedToken={selectedToken}
                                dataSwapSelected={dataSwapSelected}
                            ></SwapValueItem>
                        )
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

function SwapValueItem({dataSwap, selectedToken, dataSwapSelected}:{dataSwap: DataSwap, selectedToken: string | null | undefined, dataSwapSelected: (ds:DataSwap) => void}) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                height: '50px',
                cursor: 'pointer',
                padding: '16px 0 16px 0',
                '&:hover': {
                    background: 'hsla(0, 0%, 13%, 0.08)',
                    backgroundOpacity: 0.2
                }

            }}
            data-testid={`swap-value-item-${dataSwap.name}`}
            className={selectedToken && dataSwap.swapValue?.toLowerCase() === selectedToken.toLowerCase() ? "active" : ""}
            onClick={() => dataSwapSelected(dataSwap)}
        >
            <SubdirectoryArrowRightIcon></SubdirectoryArrowRightIcon>
            <Box sx={{
                    display: "flex",
                    marginLeft: "33px",
                    marginRight: "33px",
                    width: "calc(100% - 81px)",
                    overflow: "hidden"
                }}
            >
                <Box className="subtitle1"
                    sx={{ whiteSpace: "nowrap", marginRight: "8px", textAlign: "left" }}
                >
                    <Box sx={{fontSize: "12px", lineHeight: "16px", marginBottom: "8px" }}>
                        {dataSwap.name}:
                    </Box>
                    <Box>
                        {dataSwap.value || "VALUE NOT SET"}
                    </Box>
                </Box>
            </Box>
            <ArrowRightIcon color="secondary"/>
        </Box>
    );
}

interface DataSwapModalProps {
    siteProperties: Property[],
    pageProperties: Property[],
    populatedDataSources: PopulatedDataSource[],
    dataSwapSelected: (ds: DataSwap) => void,
    showModal: boolean,
    onClose: () => void,
    selectedToken: string | null | undefined,
    propertyValueTypeId?: number | number[],
    allowedDataSourceValueTypes: number[]
}

export default function DataSwapModal({populatedDataSources, siteProperties, pageProperties, dataSwapSelected, showModal, onClose, selectedToken, propertyValueTypeId, allowedDataSourceValueTypes }: DataSwapModalProps) {
    const [selectedDataSource, setSelectedDataSource] = useState("all");
    const [searchFilter, setSearchFilter] = useState("");

    const swapSelected = (ds: DataSwap) => {
        // Get value from the token so that data source scope overrides can be applied
        ds.value = getValueFromToken(populatedDataSources, siteProperties, pageProperties, ds.swapValue);
        dataSwapSelected(ds);
    };

    const dataSwapValueIsFilteredOut = (name: string | null, value: string): boolean => {
        if (!searchFilter) {
            return false;
        }

        const nameContainsFilter = name
            && name.toLowerCase().includes(searchFilter.toLowerCase());
        const valueContainsFilter = value
            && value.toLowerCase().includes(searchFilter.toLowerCase());

        return !nameContainsFilter && !valueContainsFilter;
    };

    const filterCategories = (category: DataSwapCategory) => {
        if (category.childCategories.length > 0) {
            const filteredChildCategories: DataSwapCategory[] = category.childCategories.filter(c => filterCategories(c));
            return filteredChildCategories.length > 0;
        }
        else {
            return category.category === selectedDataSource;
        }
    }

    const filterCategoryValues = (category: DataSwapCategory) => {
        if (category.childCategories.length === 0 && category.values) {
            category.values = category.values.filter(v => !dataSwapValueIsFilteredOut(v.name || null, v.value ?? ''));
            category.hasChildValues = category.values.length > 0;
        }
        else {
            for (const childCategory of category.childCategories) {
                filterCategoryValues(childCategory);
            }
            category.hasChildValues = category.childCategories.filter(c => c.hasChildValues).length > 0;
        }
    }

    const getFilteredCategories = () => {
        const filteredCategories = [];

        if (siteProperties) {
            const sitePropertiesCategory: DataSwapCategory = {
                name: "Site properties",
                category: "Site properties",
                values: [],
                childCategories: [],
                hasChildValues: false
            };

            for (const siteProperty of siteProperties) {
                if ((Array.isArray(propertyValueTypeId) && propertyValueTypeId.includes(siteProperty.ValueTypeId))
                    || siteProperty.ValueTypeId === propertyValueTypeId) {
                    sitePropertiesCategory.values.push({
                        name: siteProperty.Name,
                        value: siteProperty.RenderedValue || null,
                        swapValue: `[P:${siteProperty.PropertyId}]`
                    });
                    sitePropertiesCategory.hasChildValues = true;
                }
            }

            filteredCategories.push(sitePropertiesCategory);
        }

        for (const datasource of populatedDataSources) {
            const dataSourceCategory: DataSwapCategory = {
                name: datasource.Name,
                category: 'Data Source',
                childCategories: [],
                values: [],
                hasChildValues: false
            };

            for (const record of datasource.Records) {
                const displayMapping = datasource.Mappings.find(m => m.IsDisplayValue);
                const keyMapping = datasource.Mappings.find(m => m.IsPrimaryKey);

                // Verify we have a proper display and primary key mapping
                if (displayMapping && keyMapping) {
                    const recordCategory: DataSwapCategory = {
                        name: record[displayMapping.FieldName],
                        category: datasource.Name,
                        childCategories: [],
                        values: [],
                        hasChildValues: false
                    };

                    for (const mapping of datasource.Mappings) {
                        // Filter out data swaps for objects/arrays
                        if (mapping.ValueTypeId && allowedDataSourceValueTypes?.includes(mapping.ValueTypeId)) {
                            recordCategory.values.push({
                                name: mapping.DisplayName,
                                value: record[mapping.FieldName]?.toString(),
                                // If we have more than one record, we will use keys, otherwise we can leverage
                                // the default functionality of selecting the first record and exclude the key
                                swapValue: datasource.Records.length === 1
                                    ? `[D:${mapping.Id}]` : `[D:${mapping.Id}:${record[keyMapping.FieldName]}]`
                            });
                            recordCategory.hasChildValues = true;
                        }
                    }

                    if (recordCategory.values.length === 0) {
                        recordCategory.hasChildValues = false;
                    }
                    dataSourceCategory.childCategories.push(recordCategory);
                    dataSourceCategory.hasChildValues = recordCategory.hasChildValues;
                }
            }
            filteredCategories.push(dataSourceCategory);
        };

        return filteredCategories;
    }

    let filteredCategories = getFilteredCategories();
    
    // Apply filters
    if (selectedDataSource && selectedDataSource !== "all") {
        filteredCategories = filteredCategories.filter(category => filterCategories(category));
    }

    if (searchFilter) {
        for (const category of filteredCategories) {
            filterCategoryValues(category);
        }
    }

    const closeValueSwapModal = () => {
        setSelectedDataSource("all");
        setSearchFilter("");
        onClose();
    };

    return (
        <Dialog
            fullScreen={false}
            open={showModal}
            onClose={closeValueSwapModal}
            maxWidth='md'
            fullWidth={true}
            PaperProps={{
                style: { borderRadius: '3.5px' }
            }}
                >
            <DialogContent style={{ padding: '16px', display: "flex", flexDirection: "column" }}>
                <h6 className="headline6" style={{ margin: 0, textAlign: "center" }}>Data Swaps</h6>
                <Box sx={{ marginBottom: "32px", display: "flex", gap: "32px", marginTop: "32px" }}>
                    <FormControl sx={{ width: "50%" }} >
                        <label htmlFor="selected-datasource" style={{ lineHeight: "1.4375em" }}>Data Source</label>
                        <Select sx={{marginTop: "8px"}} inputProps={{ id: "selected-datasource" }}
                            value={selectedDataSource}
                            onChange={(e) => setSelectedDataSource(e.target.value)}>
                            <MenuItem value={"all"} key={"all"}>All</MenuItem>
                            <MenuItem value={"Site properties"} key={"Site properties"}>Site properties</MenuItem>
                            {populatedDataSources.map((d) =>
                                <MenuItem value={d.Name} key={d.Name}>{d.Name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <Box sx={{ width: "100%" }} >
                        <InputLabel htmlFor="search-text" sx={{color: "black"}}>Search</InputLabel>
                        <TextField id="input-text" sx={{ marginTop: "8px", width: "100%" }}
                            onChange={(e) => setSearchFilter(e.target.value)}
                            value={searchFilter}
                            placeholder="Search fields and values..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{color: "black"}} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ height: '350px', overflowY: 'auto'}} className="hideScrollbars">
                    {filteredCategories.filter(c => c.hasChildValues).map((c, i) =>
                            <SwapValueCategory
                                key={i}
                                dataSwapCategory={c}
                                selectedToken={selectedToken}
                                dataSwapSelected={swapSelected}
                                defaultExpanded={true}
                            ></SwapValueCategory>
                        )
                    }
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between", padding:'16px' }}>
                <Button onClick={closeValueSwapModal} variant="text">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}