import { useState, useEffect } from 'react';
import Loading from './Loading';
import { Box, Button, Tab, Tabs, Chip, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UndoIcon from '@mui/icons-material/Undo';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { getFileSizeDisplay, isImageFile } from '../../utils/fileHelpers';
import Resizer from "react-image-file-resizer";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NavBar from './NavBar';
import TabPanel from '../common/TabPanel';

const radioStyle = { 
    color: "secondary.main",
    paddingTop: "0px"
};
const radioBoxStyle = { 
    fontSize: "14px", 
    lineHeight: "20px", 
    maxWidth: "150px", 
    marginBottom: "16px"
};
interface PresetSettings {
    maxWidth: number,
    minWidth: number,
    currentWidth: number,
    maxSizeKb: number,
    minQuality: number,
    currentQuality: number,
    resizeIncrement: number,
    compressFormat: string
};

export interface ImageEditorProps {
    files: File[],
    cancelEditing: () => void,
    editingFinished: (files: File[], saveAsNew?: boolean) => void,
    showSaveModifyDialog?: boolean
}

export default function ImageEditor({ files, cancelEditing, editingFinished, showSaveModifyDialog }: ImageEditorProps) {
    const [originalImage, setOriginalImage] = useState<any>();
    const [imageBeingEdited, setImageBeingEdited] = useState<any>();
    const [editSteps, setEditSteps] = useState<any[]>([]);
    const [tabValue, setTabValue] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [showSaveAsDialog, setShowSaveAsDialog] = useState(false);
    const [imageCount, setImageCount] = useState(0);
    const [autoOptimizationValue, setAutoOptimizationValue] = useState('');
    const [editedFileList, setEditedFileList] = useState<File[]>([]);
    //const [width, setWidth] = useState(0);
    const [quality, setQuality] = useState(100);

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        let imageFile = null;
        let numberOfImages = 0;
        for (let file of files) {
            if (isImageFile(file)) {
                numberOfImages = numberOfImages + 1;
                // set the first image in the event we are only uploading a single image
                if (imageFile === null) {
                    imageFile = file;
                }
            }
        }

        if (numberOfImages === 0) {
            // Skip image editing and continue with upload
            editingFinished(files, false);
        } else {
            setImageCount(numberOfImages);
            // If we only have 1 image, set the image to edit
            if (numberOfImages === 1) {
                //setWidth(imageFile.originalWidth);
                setOriginalImage(imageFile);
                setImageBeingEdited(imageFile);
            }
            // TODO: Implement multiple image editing
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    };

    const saveAsNewFile = () => {
        setShowSaveAsDialog(false);
        editingFinished(editedFileList, true);
    };

    const modifyOriginalFile = () => {
        setShowSaveAsDialog(false);
        editingFinished(editedFileList, false);
    };

    const fileNameWithoutExtension = (filename: string) => {
        return filename.substring(0, filename.lastIndexOf("."));
    }

    const finishEditingFiles = (editedFiles: File[]) => {
        if (showSaveModifyDialog) {
            setEditedFileList(editedFiles);
            setShowSaveAsDialog(true);
        } else {
            editingFinished(editedFiles, true);
        }
    };

    const saveEditedFile = () => {
        const editedFiles = [];
        for (let file of files) {
            if (fileNameWithoutExtension(file.name) !== fileNameWithoutExtension(imageBeingEdited.name)) {
                editedFiles.push(file);
            }
        }
        editedFiles.push(imageBeingEdited);
        finishEditingFiles(editedFiles);
    }

    const dataURLtoFile = (dataurl: string, filename: string, onLoad: any, settings: PresetSettings) => {
        var arr = dataurl.split(',');
        var mime = "";
        var bstr = null; 
        var n = 0;
        var u8arr = new Uint8Array(n);
        if (arr.length > 1) {
            var matches = arr[0].match(/:(.*?);/);
            if (matches && matches.length > 1) {
                mime = matches[1];
            }
            bstr = atob(arr[1]);
            if (bstr) {
                n = bstr.length; 
            }
            u8arr = new Uint8Array(n);
        }
            
        if (bstr) {
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
        }
        
        const newFileName = fileNameWithoutExtension(filename) 
            + (settings.compressFormat === "PNG" ? ".png" : ".jpg");
        const newFile = new File([u8arr], newFileName, {type:mime}) as any;

        var reader = new FileReader();
        reader.onload = (event) => {
            var i = new Image() as any;
            i.onload = () => {
                newFile.originalHeight = i.height;
                newFile.originalWidth = i.width;
                newFile.isLoaded = true;
                newFile.encodedData = reader.result;
                onLoad(newFile);
            };
            i.src = event?.target?.result;
        }
        reader.readAsDataURL(newFile);

        return newFile;
    };

    const imageResized = (uri: string, callback: any, settings: PresetSettings) => {
        const onLoad = (newFile: any) => {
            callback(newFile, settings);
        };
        dataURLtoFile(uri, originalImage?.name, onLoad, settings);
    }

    const getPresetSettings = (size: string): PresetSettings => {
        if (size === 'large') {
            return {
                maxWidth: 1800,
                minWidth: 1000,
                currentWidth: 1800,
                maxSizeKb: 300,
                minQuality: 50,
                currentQuality: 90,
                resizeIncrement: 50,
                compressFormat: "JPEG"
            };
        }
        else if (size === 'medium') {
            return {
                maxWidth: 800,
                minWidth: 500,
                currentWidth: 800,
                maxSizeKb: 150,
                minQuality: 75,
                currentQuality: 90,
                resizeIncrement: 50,
                compressFormat: "JPEG"
            };
        }
        else { // small
            return {
                maxWidth: 300,
                minWidth: 100,
                currentWidth: 300,
                maxSizeKb: 75,
                minQuality: 100,
                currentQuality: 100,
                resizeIncrement: 25,
                compressFormat: originalImage.type === 'image/png' ? "PNG" : "JPEG"
            };
        }
    };

    const applyPreset = (size: string) => {
        const settings = getPresetSettings(size);

        if (settings !== null) {
            setProcessing(true);
            setEditSteps([{action: "preset", settings }]);
            const callback = (newFile: any, quality: number) => {
                setImageBeingEdited(newFile);
                setQuality(quality);
                //setWidth(newFile.originalWidth);
                setProcessing(false);
            };
            forceImageToSize(originalImage, settings, callback);
        }
    };

    const forceImageToSize = (image: any, settings: PresetSettings, callback: any) => {
        if ((image.size / 1024) <= settings.maxSizeKb
            && image.originalWidth <= settings.maxWidth) {
            callback(image, quality);
            return;
        }

        const retryCallback = (newFile: any, settings: PresetSettings) => {
            // At this point check the size
            const newFileSizeKb = newFile.size / 1024;
            let finished = false;

            if (newFile.size > originalImage.size) {
                callback(originalImage, 100);
                return;
            }

            // Meets the constraints, so finish
            if (newFileSizeKb <= settings.maxSizeKb
                && newFile.originalWidth <= settings.maxWidth) {
                finished = true;
            }

            // Can't go any lower, so finish
            if (settings.currentWidth === settings.minWidth
                && settings.currentQuality === settings.minQuality) {
                finished = true;
            }

            if (finished) {
                callback(newFile, settings.currentQuality);
                return;
            }

            if (settings.currentQuality > settings.minQuality) {
                settings.currentQuality = settings.currentQuality - 10;
            } else {
                settings.currentWidth = settings.currentWidth - settings.resizeIncrement;
            }
            settings.currentWidth = settings.currentWidth < settings.minWidth
                ? settings.minWidth : settings.currentWidth;
            settings.currentQuality = settings.currentQuality < settings.minQuality
                ? settings.minQuality : settings.currentQuality;

            forceImageToSize(image, settings, callback);
        };

        doResizeImage(image, settings, retryCallback); 
    };

    const doResizeImage = (image: any, settings: PresetSettings, callback: any) => {
        const uriToFileCallback = (uri: string | File | Blob | ProgressEvent<FileReader>) => {
            imageResized(uri.toString(), callback, settings);
        };
        Resizer.imageFileResizer(
            image,
            settings.currentWidth, // max width
            99999, // max height
            settings.compressFormat, // compress format
            settings.currentQuality, // quality
            0, // rotation
            uriToFileCallback,
            "base64",
            0, // min width
            0 // min height
        );
    };

    const reset = () => {
        setImageBeingEdited(originalImage);
        setEditSteps([]);
        //setWidth(originalImage.originalWidth);
        setQuality(100);
    };

    const reapplyStep = (index: number, image: any) => {
        if (editSteps.length >= index + 1) {
            //const step = editSteps[index];
        } else {
            setImageBeingEdited(image);
            setProcessing(false);
        }
    };

    const undo = () => {
        setProcessing(true);
        editSteps.pop();
        setEditSteps(editSteps);
        reapplyStep(0, originalImage);
    };

    const doAutoOptimization = (index: number, fileList: any) => {
        if (index === files.length) {
            setProcessing(false);
            finishEditingFiles(fileList);
        } else if (isImageFile(files[index])) {
            const resizeCallback = (newFile: any, quality: number) => {
                fileList.push(newFile);
                doAutoOptimization(index + 1, fileList);
            };

            const settings = getPresetSettings(autoOptimizationValue);
            if (settings != null) {
                forceImageToSize(files[index], settings, resizeCallback);
            }
        } else {
            fileList.push(files[index]);
        }
    };

    const doMobileUpload = () => {
        if (autoOptimizationValue === '') {
            finishEditingFiles(Array.from(files || []));
        } else {
            setProcessing(true);
            doAutoOptimization(0, []);
        }
    };

    return (
        <>
            { imageBeingEdited &&
                <Dialog
                    fullScreen={isMobileView}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='lg'
                    fullWidth={true}
                    data-testid="image-editor-dialog"
                >
                    { processing &&
                        <Loading message="Processing..." />
                    }
                    { isMobileView && 
                        <NavBar title='File Handler' />
                    }
                    { !isMobileView &&
                        <div style={{ display: 'flex', flexFlow: 'column wrap' }}>
                            <Box sx={{ display: "flex", alignItems: "center", margin: "16px" }}>
                                <span className="headline5">{imageBeingEdited.name}</span>
                                <Chip sx={{ marginLeft: "16px" }} label={getFileSizeDisplay(imageBeingEdited.size)} />
                                <Chip sx={{ marginLeft: "16px" }} label={`${imageBeingEdited.originalWidth}x${imageBeingEdited.originalHeight}`} />
                                <Box sx={{ marginLeft: "auto" }}>
                                    { editSteps.length > 0 && 
                                        <>
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                startIcon={<UndoIcon />}
                                                onClick={undo}
                                                sx={{ marginLeft: "16px" }}
                                            >
                                                Undo
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                startIcon={<RestartAltIcon />}
                                                onClick={reset}
                                                sx={{ marginLeft: "16px" }}
                                            >
                                                Reset
                                            </Button>
                                        </>
                                    }
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        onClick={saveEditedFile}
                                        sx={{ marginLeft: "16px" }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                            <TabPanel value={tabValue} name="presets-tab" index={0} hideScrollbars={false}>
                                <Box sx={{ position: "relative", background: "rgba(33, 33, 33, 0.04)", padding: "16px", display: "flex", justifyContent: "center", height: "60vh" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={imageBeingEdited.encodedData} alt={imageBeingEdited.name}  style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                    </Box>
                                    <Box sx={{ 
                                        background: "rgba(255, 255, 255, 0.9)", 
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        height: "50px",
                                        width: "100%",
                                        overflowX: "auto",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <Button sx={{ marginLeft: "16px" }} variant="text" onClick={() => applyPreset('small')}>Small (Logos)</Button>
                                        <Button sx={{ marginLeft: "8px" }} variant="text" onClick={() => applyPreset('medium')}>Medium (Coupons)</Button>
                                        <Button sx={{ marginLeft: "8px" }} variant="text" onClick={() => applyPreset('large')}>Large (CTAs, Backgrounds)</Button>

                                    </Box>
                                </Box>
                            </TabPanel>
                            <Box>
                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="Editor tabs" TabIndicatorProps={{style: { background:'#cf1f2e' }}} >
                                    <Tab label={<Box sx={{ color: "#292929", display: "flex", alignItems: "center", justifyContent: "center"}}><AutoAwesomeOutlinedIcon /><span style={{ marginLeft: "8px" }}>Presets</span></Box>} id="presets-tab" aria-controls="presets-tab-pane" />
                                </Tabs>
                            </Box>
                        </div>
                    }
                    {isMobileView && 
                        <>
                        <Box sx={{ display: "flex", overflowY: "auto", justifyContent: "flex-end", flexDirection: "column", height: "calc(100% - 101px)" }}>
                            <Box sx={{ display: "flex", height: "100%", justifyContent: "start", flexDirection: "column" }}>
                                <Box sx={{ width: "150px", height: "150px", background: "#1B1AFF", borderRadius: "20px", color: "white", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "32px", marginBottom: "32px", marginLeft: "auto", marginRight: "auto" }}>
                                    <Box className="headline6">{files.length} file{files.length > 1 ? 's' : ''}</Box>
                                </Box>
                                <Box className="headline5" sx={{ textAlign: "center" }}>{files.length} file{files.length > 1 ? 's' : ''} will be uploaded.</Box>
                                { imageCount > 0 &&
                                    <>
                                    <Box className="body1" sx={{ margin: "8px", marginTop: "8px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <AutoAwesomeOutlinedIcon /> 
                                        <Box sx={{ marginLeft: "8px" }}>
                                            Apply an optimization preset to the <strong>{imageCount} image{imageCount > 1 ? 's' : ''}</strong>?
                                        </Box>
                                    </Box>
                                    <Box sx={{ margin: "16px", display: "flex", justifyContent: "start" }}>
                                      <RadioGroup
                                        aria-labelledby="optimization-radio-buttons-group-label"
                                        value={autoOptimizationValue}
                                        onChange={(event: any) => setAutoOptimizationValue(event?.target?.value)}
                                        name="radio-buttons-group"
                                      >
                                          <FormControlLabel 
                                              value={'small'} 
                                              control={<Radio sx={radioStyle} color="secondary" />} 
                                              label={<Box sx={radioBoxStyle}><strong>Logos</strong><Box>Resized to a maximum file size of 75kb.</Box></Box>} 
                                              sx={{ alignItems: "start" }}
                                          />
                                          <FormControlLabel 
                                              value={'medium'} 
                                              control={<Radio sx={radioStyle} color="secondary" />} 
                                              label={<Box sx={radioBoxStyle}><strong>Coupons</strong><Box>Resized to a maximum file size of 150kb.</Box></Box>} 
                                              sx={{ alignItems: "start" }}
                                          />
                                          <FormControlLabel 
                                              value={'large'} 
                                              control={<Radio sx={radioStyle} color="secondary" />} 
                                              label={<Box sx={radioBoxStyle}><strong>CTAs/Backgrounds</strong><Box>Resized to a maximum file size of 300kb.</Box></Box>} 
                                              sx={{ alignItems: "start" }}
                                          />
                                          <FormControlLabel 
                                              value={''} 
                                              control={<Radio sx={radioStyle} color="secondary" />} 
                                              label={<Box sx={radioBoxStyle}><strong>Original</strong><Box>Current sizes will be maintained.</Box></Box>} 
                                              sx={{ alignItems: "start" }}
                                          />
                                      </RadioGroup>
                                    </Box>
                                    </>
                                }
                                
                            </Box>
                        </Box>
                        <Box sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: "flex", borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Button variant="outlined" onClick={cancelEditing} sx={{ flex: 1, margin: "8px 6px 8px 16px" }}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={doMobileUpload} sx={{ flex: 1, margin: "8px 16px 8px 6px" }}>
                                Upload
                            </Button>
                        </Box>
                        </>
                    }
                </Dialog>
            }
            { showSaveAsDialog && 
                <Dialog
                    open={true}
                    onClose={() => {}}
                  >
                    <Box sx={{ margin: "24px" }} className="body2">
                      {"Modify original file, or save as new file?"}
                    </Box>
                    <DialogActions>
                      <Button variant="text" onClick={saveAsNewFile}>New File</Button>
                      <Button variant="contained" onClick={modifyOriginalFile} autoFocus>
                        Modify Original File
                      </Button>
                    </DialogActions>
                 </Dialog>
             }
        </>
    )
}

