import { useEffect } from "react";
import "./SiteEditorProperties.css";
import SiteEditorStore from "../SiteEditorStore";
import SiteEditorPropertiesTabs from "./SiteEditorPropertiesTabs";
import SiteEditorPropertiesSearchFilter from "./SiteEditorPropertiesSearchFilter";
import SiteEditorPropertiesBlockProperties from "./SiteEditorPropertiesBlockProperties";
import SiteEditorPropertiesPageProperties from "./SiteEditorPropertiesPageProperties";
import SiteEditorPropertiesBlockTitle from "./SiteEditorPropertiesBlockTitle";
import SiteEditorPropertiesSiteProperties from "./SiteEditorPropertiesSiteProperties";

export default function SiteEditorProperties() {
    const scrollToPropertyId = SiteEditorStore.useStoreState((state) => state.scrollToPropertyId);
    const clearScrollToPropertyId = SiteEditorStore.useStoreActions((actions) => actions.clearScrollToPropertyId);
    const setExpandCategories = SiteEditorStore.useStoreActions((actions) => actions.updateExpandPropertyCategories);

    useEffect(() => {
      setExpandCategories(false);
      let scrollTimer: NodeJS.Timeout;

      // If we are attempting to scroll to a specific property when
      // the tab is changed, waiting 750ms to give time for animations of accordions
      // to fully open before attempting to scroll and then remove the scroll ID
      if (scrollToPropertyId) {
        setExpandCategories(true);
        scrollTimer = setTimeout(() => {
          const element = document.getElementById(`property-${scrollToPropertyId}`);
          element?.scrollIntoView({
            block: 'center',
            inline: 'end'
          });
          clearScrollToPropertyId();
        }
        , 750);
      }

      return () => {
        clearTimeout(scrollTimer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToPropertyId]);

    return (
        <div style={{ padding: "20px 20px 0px 20px", flex: "0 0 438px", height: "100%", maxWidth: "438px", boxSizing: "border-box" }}>
            <h6 className="headline6" style={{ margin: "0px", padding: "0px 20px 20px 20px" }}>Properties</h6>
              <SiteEditorPropertiesTabs />
              <SiteEditorPropertiesBlockTitle />
              <SiteEditorPropertiesSearchFilter />
              <SiteEditorPropertiesSiteProperties />
              <SiteEditorPropertiesPageProperties />
              <SiteEditorPropertiesBlockProperties />
        </div>
    );
}