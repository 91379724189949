import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, getPropertyBooleanValue } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

function isConfiguredProperly(block: BlockMapping) {
    const isServiceArea = getPropertyBooleanValue(block.Properties, PropertyId.IsServiceArea);
    const serviceAreas = getPropertyValue(block.Properties, PropertyId.ServiceAreas);
    const serviceAreasList = serviceAreas ? serviceAreas.split(',') : [];

    return isServiceArea 
        && serviceAreasList.length > 0;
}

function getServiceAreasInnerText(serviceAreasList: string[]): string {
    let areas = "";

    for (let i = 0; i < serviceAreasList.length; i++)
    {
        if (serviceAreasList.length > 2 && i > 0 && i === serviceAreasList.length - 1) {
            areas += ", & ";
        }
        else if (serviceAreasList.length > 2 && i > 0) {
            areas += ", ";
        }
        else if (serviceAreasList.length === 2 && i === serviceAreasList.length - 1) {
            areas += " & ";
        }

        areas += serviceAreasList[i].trim();
    }
    return `Serving the ${areas} area${serviceAreasList.length > 1 ? "s" : ""}.`
}

function getServiceAreasContent(serviceAreasList: string[]): JSX.Element {
    return <>{getServiceAreasInnerText(serviceAreasList)}</>
}

export function Block({block, builderProps, blockWrapperData}: BlockProps) {
    if (!isConfiguredProperly(block)) {
        return <></>;
    }

    const serviceAreas = getPropertyValue(block.Properties, PropertyId.ServiceAreas);
    const serviceAreasList = serviceAreas?.split(',') ?? [];

    return (
        <div {...getDynamicAttributes({block, builderProps, className: "mt-4 service-areas-display", blockWrapperData})}>
            {getServiceAreasContent(serviceAreasList)}
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const isServiceArea = getPropertyBooleanValue(block.Properties, PropertyId.IsServiceArea);
    const serviceAreas = getPropertyValue(block.Properties, PropertyId.ServiceAreas);
    const serviceAreasList = serviceAreas?.split(',') ?? [];

    if (isServiceArea && serviceAreasList.length > 0) {
        return getServiceAreasContent(serviceAreasList);
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const isServiceArea = getPropertyBooleanValue(block.Properties, PropertyId.IsServiceArea);
    const serviceAreas = getPropertyValue(block.Properties, PropertyId.ServiceAreas);
    const serviceAreasList = serviceAreas?.split(',') ?? [];

    if (isServiceArea && serviceAreasList.length > 0) {
        const searchStr = getServiceAreasInnerText(serviceAreasList);

        return (!!searchStr && (searchStr.toLowerCase().includes(filterText)))
            || defaultFilter(block, filterText);
    }

    return defaultFilter(block, filterText);
}