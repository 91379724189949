import React, { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import TemplateStep from "./template_step/TemplateStep";
import ColorStep from "./color_step/ColorStep";
import FontStep from "./font_step/FontStep";
import LogoStep from "./logo_step/LogoStep";
import SocialImagesStep from "./social_images_step/SocialImagesStep";
import PromotionStep from "./promotion_step/PromotionStep";
import ReviewStep from "./review_step/ReviewStep";
import Loading from "../common/Loading";
import CTABackgroundStep from "./cta_background_step/CTABackgroundStep";
import CTAStep from "./cta_step/CTAStep";
import ExternalLinksStep from "./external_links_step/ExternalLinksStep";
import ImageCarouselStep from "./image_carousel_step/ImageCarouselStep";
import NavBar from "../common/NavBar";
import WizardErrorMessage from "./wizard_error_message/WizardErrorMessage";
import useApiPostCallbackAsync from "../../hooks/useApiPostCallbackAsync";
import useApiGetCallbackAsync from "../../hooks/useApiGetCallbackAsync";
import "./SiteWizard.scss";
import { useUserContext } from "../../UserContext";
import useApiGet from "../../hooks/useApiGet";
import SiteStatus from "../../types/enum/SiteStatus";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import useGetWizardPage from "../../hooks/endpoints/useGetWizardPage";
import { convertNormalizedPageToPage, convertPageToNormalizedPage } from "../../utils/pageHelpers";
import Site from "../../types/Site";
import WizardState from "../../types/WizardState";
import Page from "../../types/Page";
import Template from "../../types/Template";
import BlockMapping from "../../types/BlockMapping";
import { useParams, useNavigate } from "react-router-dom";
import useGetAndSet from "../../hooks/useGetAndSet";
import BuilderStore from "../../BuilderStore";
import SiteWizardPreview from "./site_wizard_preview/SiteWizardPreview";
import SiteWizardActions from "./site_wizard_actions/SiteWizardActions";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function SiteWizard() {
    const { siteId } = useParams<{siteId: string; pageId: string}>();
    const navigate = useNavigate();

    const [site, setSite] = useState<Site | null>();
    const setSiteId = BuilderStore.useStoreActions((actions) => actions.setSiteId);

    useGetAndSet(
        `/sites/${siteId}`,
        setSite);

    const reloadSite = () => {
        navigate(`/site/${siteId}`);
    };

    const loadEditor = () => {
        navigate(`/site/${siteId}`);
    };

    // Disable wizard if site is provisioned
    useEffect(() => {
        if (site && site.StatusTypeId !== SiteStatus.PendingProvisioning) {
            reloadSite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSiteId(siteId || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

    const getDefaultRightNavContent = (): React.ReactNode => {
        return (
            <>
                {!isMobileView && (
                    <Button sx={{ color: "white" }} onClick={loadEditor} variant="text" startIcon={<ExitToAppIcon />}>
                        Exit To Editor
                    </Button>
                )}
                {isMobileView && <ExitToAppIcon sx={{ cursor: "pointer", marginLeft: "8px" }} onClick={loadEditor} />}
            </>
        );
    };

    const [activeStep, setActiveStep] = useState(0);
    const [template, setTemplate] = useState<Template | null>(null);
    const [templateData, setTemplateData] = useState<Page | null>(null);
    const [originalTemplateData, setOriginalTemplateData] = useState<Page | BlockMapping | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [failedToSubmit, setFailedToSubmit] = useState<boolean | null>(null);
    const [rightNavContent, setRightNavContent] = useState<React.ReactNode | null>(getDefaultRightNavContent());
    const [showFilterIcon, setShowFilterIcon] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [favicon, setFavicon] = useState<string | null>(null);
    const [shareImage, setShareImage] = useState<string | null>(null);
    const [simulateShareImageFormatting, setSimulateShareImageFormatting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [previewBlockId, setPreviewBlockId] = useState<string | null>(null);
    const [initialStatePending, setInitialStatePending] = useState(true);
    const [doFinalUpdate, setDoFinalUpdate] = useState(false);
    const { currentUser } = useUserContext();
    const [templateId, setTemplateId] = useState<string | null>(null);

    const [{ isLoading: stateIsLoading }, postWizardStateCallback] = useApiPostCallbackAsync(
        `/wizard/state/${site?.Id}/normalized`,
        null
    ) as any; // update url to new /normalized
    const [{ data: initialStateData, isLoading: loadingInitialState }] = useApiGet(`/wizard/state/${site?.Id}/normalized`, null);
    const [, getWizardStateCallback] =
        useApiGetCallbackAsync(`/wizard/state/${site?.Id}/normalized?lastChangedBy=${encodeURIComponent(currentUser?.name || '')}`, null) as any;

    // Updates wizard based on initial state loaded
    if (!loadingInitialState && initialStatePending) {
        setInitialStatePending(false);
        if (initialStateData != null && initialStateData.TemplateData != null) {
            setTemplateData(convertNormalizedPageToPage(initialStateData.TemplateData));
            setTemplateId(initialStateData.TemplateId);
            setActiveStep(initialStateData.StepNumber);
        }
    }

    // Updates wizard state whenever templateData or activeStep changes
    // Skips update if an update is already in progress
    useEffect(() => {
        if (stateIsLoading && !doFinalUpdate) {
            setDoFinalUpdate(true);
        }

        if (!stateIsLoading && templateData != null && templateId != null) {
            postWizardStateCallback({
                TemplateData: convertPageToNormalizedPage(templateData),
                LastChangedBy: currentUser?.name,
                StepNumber: activeStep,
                TemplateId: templateId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData, activeStep, doFinalUpdate]);

    useEffect(() => {
        if (!stateIsLoading && doFinalUpdate) {
            setDoFinalUpdate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIsLoading]);

    useEffect(() => {
        const wizardStepContainer = document.getElementById("wizard-step-container");
        if (wizardStepContainer) {
            wizardStepContainer.scrollTop = 0;
        }
    }, [activeStep]);

    // If the template changes such as triggered from the TemplateStep we
    // want to set the templateId
    useEffect(() => {
        if (template) {
            setTemplateId(template.Id);
        }
    }, [template]);

    // Checks wizard state every 30 seconds and sets templateData and activeStep if changed by
    // someone other than current user.
    useEffect(() => {
        const processWizardState = (returnState: WizardState) => {
            if (returnState?.LastChangedBy && returnState.LastChangedBy !== currentUser?.name) {
                setTemplateId(returnState.TemplateId);
                setTemplateData(convertNormalizedPageToPage(returnState.TemplateData));
                setErrorMessage(`Values have been changed by ${returnState.LastChangedBy}.`);
            }
        };

        let wizardInterval: any;

        if (!isSubmitting) {
            wizardInterval = setInterval(() => {
                getWizardStateCallback(processWizardState);
            }, 30000);
        } else {
            clearInterval(wizardInterval);
        }
        return () => clearInterval(wizardInterval);
    }, [currentUser?.name, getWizardStateCallback, isSubmitting]);

    // Store the original template data
    if (templateData != null && originalTemplateData == null) {
        setOriginalTemplateData(JSON.parse(JSON.stringify(templateData)));
    }

    const toggleDrawer = () => {
        setDrawerOpen((prevVal) => !prevVal);
    };

    useEffect(() => {
        setRightNavContent(
            <>
                {showFilterIcon && isMobileView && (
                    <FilterListIcon sx={{ display: { xs: "flex", sm: "flex", md: "none" } }} onClick={toggleDrawer} />
                )}
                {getDefaultRightNavContent()}
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobileView, showFilterIcon]);

    const getMobileProgressPercentage = () => {
        const step = activeStep + 1;
        const stepperValue = (step / steps.length) * 100;
        return stepperValue;
    };

    const resetView = () => {
        setRightNavContent(null);
        setFavicon(null);
        setErrorMessage(null);
        setPreviewBlockId(null);
        setFailedToSubmit(false);
    };

    const changeStep = (step: number) => {
        // If we are back on the template page, remove the loaded template
        if (
            step === 0 &&
            window.confirm("This action will discard any changes you have made.\nDo you wish to continue?")
        ) {
            resetView();
            setTemplate(null);
            setTemplateData(null);
            setTemplateId(null);
            setOriginalTemplateData(null);
            setActiveStep(0);
        } else if (step !== 0) {
            setActiveStep(step);
        }
    };

    const handleNext = () => {
        changeStep(activeStep + 1);
    };

    const handleBack = () => {
        changeStep(activeStep - 1);
    };

    const handleReset = () => {
        changeStep(0);
    };

    const handleChange = (step: number) => {
        if (activeStep !== 0) changeStep(step);
    };

    const [{ isLoading, isError }, submitCallback] = useApiPostCallbackAsync(
        `/sites/provision/${site?.Id}/template/${templateId}/normalized`,
        null
    ) as any;

    const sendRequest = () => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        submitCallback(convertPageToNormalizedPage(templateData!));
    };

    if (isSubmitting && !isLoading && !isError) {
        setFailedToSubmit(false);
        setIsSubmitting(false);
        reloadSite();
    } else if (isSubmitting && !isLoading && isError) {
        setFailedToSubmit(true);
        setIsSubmitting(false);
    }

    useGetWizardPage(template?.Id, site?.Id ?? '', setTemplateData);

    const showPreview = activeStep > 0;
    const loadingTemplate = template !== null && templateData === null;

    const steps = [];

    if (site) {
        steps.push({
            label: "Template",
            component: (
                <TemplateStep
                    setSelectedTemplate={setTemplate}
                    handleNext={handleNext}
                    setShowFilterIcon={setShowFilterIcon}
                    setDrawerOpen={setDrawerOpen}
                    drawerOpen={drawerOpen}
                    site={site}
                />
            ),
        });
        steps.push({
            label: "Color",
            component: <ColorStep siteId={site.Id} templateData={templateData} setTemplateData={setTemplateData} />,
        });
        steps.push({
            label: "Font",
            component: <FontStep siteId={site.Id} templateData={templateData} setTemplateData={setTemplateData} />,
        });
        steps.push({
            label: "Logo",
            component: (
                <LogoStep
                    site={site}
                    templateData={templateData}
                    setTemplateData={setTemplateData}
                    setError={setErrorMessage}
                    updatePreviewBlockId={setPreviewBlockId}
                />
            ),
        });
        steps.push({
            label: "Social Images",
            component: (
                <SocialImagesStep
                    site={site}
                    setFavicon={setFavicon}
                    setShareImage={setShareImage}
                    setSimulateShareImageFormatting={setSimulateShareImageFormatting}
                    templateData={templateData}
                    setTemplateData={setTemplateData}
                    setError={setErrorMessage}
                    updatePreviewBlockId={setPreviewBlockId}
                />
            ),
        });

        // Add additional steps based on Wizard
        if (activeStep > 0 && templateData) {
            const getWizardMappings = (mappings: BlockMapping[], mappingsWithWizard: BlockMapping[]) => {
                if (mappings) {
                    for (let mapping of mappings) {
                        if (mapping.WizardLabel != null) {
                            mappingsWithWizard.push(mapping);
                        }
                        getWizardMappings(mapping.BlockMappings, mappingsWithWizard);
                    }
                }
            };
            const mappingsWithWizard: BlockMapping[] = [];
            getWizardMappings(templateData.BlockMappings, mappingsWithWizard);

            let promoCardFound = false;
            for (let mapping of mappingsWithWizard) {
                if (mapping.WizardLabel === "CTA") {
                    steps.push({
                        label: "CTA Background",
                        component: (
                            <CTABackgroundStep
                                blockmapping={mapping}
                                site={site}
                                templateData={templateData}
                                setTemplateData={setTemplateData}
                                setError={setErrorMessage}
                                updatePreviewBlockId={setPreviewBlockId}
                            />
                        ),
                    });
                    steps.push({
                        label: "CTA",
                        component: (
                            <CTAStep
                                templateData={templateData}
                                setTemplateData={setTemplateData}
                                originalTemplateData={originalTemplateData}
                                blockmapping={mapping}
                                updatePreviewBlockId={setPreviewBlockId}
                            />
                        ),
                    });
                } else if (mapping.WizardLabel === "Card") {
                    if (!promoCardFound) {
                        promoCardFound = true;
                        steps.push({
                            label: "Promotion",
                            component: (
                                <PromotionStep
                                    site={site}
                                    templateData={templateData}
                                    setTemplateData={setTemplateData}
                                    blockmapping={mapping}
                                    updatePreviewBlockId={setPreviewBlockId}
                                    setError={setErrorMessage}
                                />
                            ),
                        });
                    }
                } else if (mapping.WizardLabel === "Carousel") {
                    steps.push({
                        label: "Carousel",
                        component: (
                            <ImageCarouselStep
                                site={site}
                                templateData={templateData}
                                setTemplateData={setTemplateData}
                                originalTemplateData={originalTemplateData}
                                blockmapping={mapping}
                                updatePreviewBlockId={setPreviewBlockId}
                            />
                        ),
                    });
                } else if (mapping.WizardLabel === "External Links") {
                    steps.push({
                        label: "External Links",
                        component: (
                            <ExternalLinksStep
                                templateData={templateData}
                                setTemplateData={setTemplateData}
                                originalTemplateData={originalTemplateData}
                                blockmapping={mapping}
                                updatePreviewBlockId={setPreviewBlockId}
                            />
                        ),
                    });
                }
            }
        }

        steps.push({
            label: "Review",
            component: <ReviewStep site={site} templateData={templateData} PreviewBlockId={previewBlockId} />,
        });
    }

    if (
        (templateData && activeStep === 999 && site?.StatusTypeId === SiteStatus.Published) ||
        (templateData && activeStep > steps.length)
    ) {
        // For provisioned site, set activeStep to last wizard (review) step.
        // Or when a template Changes and the number of steps is less than the
        // current step reset the activeStep to the last step.
        setActiveStep(steps.length - 1);
    }

    return (
        <div className="site-wizard">
            <NavBar title="Website Setup Wizard" leftContent={null} rightContent={rightNavContent} zIndex={9999} />
            <DndProvider backend={HTML5Backend} context={window}>
                <div style={{ width: "100%", overflowX: "auto", whiteSpace: "nowrap" }} className="hideScrollbars">
                    <Stepper
                        activeStep={activeStep}
                        sx={{ margin: "20px", display: { xs: "none", sm: "none", md: "flex" } }}
                        className={activeStep === 0 ? "template-step" : ""}
                    >
                        {steps.map((step, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={index} {...stepProps} sx={{ color: "black" }}>
                                    <StepLabel
                                        {...labelProps}
                                        onClick={() => {
                                            handleChange(index);
                                        }}
                                    >
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <LinearProgress
                        variant="determinate"
                        value={getMobileProgressPercentage()}
                        sx={{ margin: "30px", display: { xs: "flex", sm: "flex", md: "none" } }}
                    />
                    <Divider sx={{ display: { xs: "none", sm: "none", md: "flex" } }} />
                </div>
                <div id="wizard-body" className="wizard-body">
                    <div
                        className={classNames({
                            "wizard-col-1": true,
                            "has-preview": showPreview,
                            "review": activeStep === steps.length - 1
                        })}
                    >
                        <div
                            id="wizard-step-container"
                            className={classNames({
                                "wizard-step-container": true,
                                "noactions": activeStep === 0
                            })}
                        >
                            {(loadingTemplate || isSubmitting || initialStatePending) && <Loading />}
                            {!loadingTemplate &&
                                !initialStatePending &&
                                activeStep < steps.length &&
                                steps[activeStep].component}
                        </div>
                        {activeStep > 0 && (
                            <SiteWizardActions
                                activeStep={activeStep}
                                steps={steps}
                                handleReset={handleReset}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                sendRequest={sendRequest}
                                isSubmitting={isSubmitting}
                            />
                        )}
                    </div>
                    {showPreview && (
                        <SiteWizardPreview
                            activeStep={activeStep}
                            steps={steps}
                            favicon={favicon}
                            simulateShareImageFormatting={simulateShareImageFormatting}
                            shareImage={shareImage}
                            templateData={templateData}
                            previewBlockId={previewBlockId}/>
                    )}
                </div>
                {failedToSubmit && (
                    <WizardErrorMessage
                        message={"Failed to submit, please try again."}
                        actionName={"Retry"}
                        action={sendRequest}
                    />
                )}
                {errorMessage && (
                    <WizardErrorMessage
                        message={errorMessage}
                        actionName={"Dismiss"}
                        action={() => setErrorMessage("")}
                    />
                )}
            </DndProvider>
        </div>
    );
}

export default SiteWizard;
