import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { KeyValuePairWithId } from "./KeyValuePairProperty";
import { IconButton, TextField } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export interface KeyValuePairItemProps {
    keyValueItem: KeyValuePairWithId,
    showValue: boolean,
    index: number,
    enableDelete?: boolean,
    keyValueChanged: (index: number, key: string, value: string) => void,
    moveItem: (dragIndex: number, hoverIndex: number) => void,
    deleteItem: (index: number) => void,
    setMostRecentValue?: (mostRecentValue: string | null | undefined) => void,
  }

export default function KeyValuePairItem({
    keyValueItem,
    showValue,
    index,
    enableDelete,
    keyValueChanged,
    moveItem,
    deleteItem,
    setMostRecentValue,
}: KeyValuePairItemProps) {
    const [key, setKey] = useState<string>(keyValueItem.k);
    const [value, setValue] = useState<string>(keyValueItem.v);

    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);

    const handleKeyChanged = (newKey: string) => {
        setKey(newKey);

        if (setMostRecentValue) {
            setMostRecentValue(newKey);
        }

        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            keyValueChanged(index, newKey, value);
        }, 500));
    };

    const handleValueChanged = (newValue: string) => {
        setValue(newValue);

        if (setMostRecentValue) {
            setMostRecentValue(newValue);
        }

        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            keyValueChanged(index, key, newValue);
        }, 500));
    };

    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: 'KeyValuePairItem',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset() || {x: 0, y: 0};
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [, drag] = useDrag({
        type: 'KeyValuePairItem',
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
    <div
        ref={ref}
        data-testid="KeyValuePairItem"
        style={{
            height: "72px",
            lineHeight: "72px",
            display: "flex",
            alignItems: "center"
        }} >
        <DragIndicatorIcon
            sx={{
              cursor: 'move',
            }} />
        <TextField
            label="Label"
            sx={{
              marginLeft: '15px',
              marginRight: '15px',
              width: '100%'
            }}
            value={key}
            onChange={(e: any) => handleKeyChanged(e.target.value)}></TextField>

        {showValue && <TextField
            label="Value"
            sx={{
              marginRight: '15px',
              width: '100%'
            }}
            value={value}
            onChange={(e: any) => handleValueChanged(e.target.value)}></TextField>}

        <IconButton
            aria-label="delete"
            data-testid="delete-icon"
            onClick={() => {
                if (enableDelete) {
                    deleteItem(index)
                }
            }}
        >
            <DeleteOutlineIcon
                sx={{
                    color: enableDelete ? "#CF1F2E" : "#9e9e9e",
                    fontSize: "25px",
                }}
            />
        </IconButton>
    </div>)
};