import { Box } from '@mui/material';

interface FormValidationErrorProps {
    valid: boolean,
    message: string
}

export default function FormValidationError({ valid, message }: FormValidationErrorProps) {
    if (valid) {
        return null;
    }

    return <Box sx={{color: '#CF1F2E', marginTop: "8px" }} className="body1">{message}</Box>;
}
