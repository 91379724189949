import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {

    const labelText = getPropertyValue(block.Properties, PropertyId.LabelText) ?? "Submit";

    return (
        <button 
            {...getDynamicAttributes({block, builderProps, className: "btn btn-wide btn-primary mt-12", blockWrapperData})} 
            type="button" 
        >
            {labelText}
        </button>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return true;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const labelText = getPropertyValue(block.Properties, PropertyId.LabelText) ?? "Submit";
    return <>{labelText}</>;
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const labelText = getPropertyValue(block.Properties, PropertyId.LabelText) ?? "Submit";

    return (!!labelText && (labelText.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}