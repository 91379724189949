import { useCallback, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';

export default function useApiPostCallbackAsync(uri: string, initialData?: any) {
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);

    const callback = useCallback((
        requestBody: Record<string, any>,
        onSuccess: Function,
        onFailure: Function,
        overrideUri?: string,
        ignoreApiPrefix?: boolean
    ) => {
        setIsError(false);
        setIsLoading(true);
        setErrorResponse(null);

        const postUri = overrideUri ?? uri;

        const fetchData = async () => {

            try {
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                const data = await makeApiCall(postUri, httpMethods.post, identityHeaders, requestBody, ignoreApiPrefix ? "" : undefined);

                setData(data);

                if (onSuccess) {
                    onSuccess(data);
                }
            } catch (error: any) {
                if (onFailure) {
                    onFailure(error);
                }
                console.log(error);
                setIsError(true);
                setErrorResponse((error.body && error.body.Errors && error.body.Errors.length && error.body.Errors.map((err: any) => `${err.Description} (${err.Code}): ${err.Message}`)) || error.message || error);
            }

            setIsLoading(false);
        };

        if (postUri) {
            fetchData();
        }
    }, [uri, getAccessTokenSilently, currentUser, sessionId, correlationId]);

    return [{ data, isLoading, isError, errorResponse }, callback];
}
