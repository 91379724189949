import PropertyId from '../../../types/enum/PropertyId';
import { getDynamicAttributes, getPropertyValue, getImageUrlWithPlaceholder } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import { getFileNameFromUrl } from '../../../utils/urlHelpers';
import BlockFilterData from '../../../types/BlockFilterData';

export function isConfiguredProperly(block: BlockMapping) {
    const imageObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.ImageSource) || '{}');
    return getImageUrlWithPlaceholder(imageObject);
};

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    if (!isConfiguredProperly(block)) {
        return null;
    }
    const imageObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.ImageSource) || '{}');

    const source = getImageUrlWithPlaceholder(imageObject);
    const altText = imageObject.a;

    return (
        <img 
            {...getDynamicAttributes({block, builderProps, blockWrapperData})} 
            src={source} 
            alt={altText} 
        />
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const imageObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.ImageSource) || '{}');

    const source = imageObject.u;
    const altText = imageObject.a;

    if (altText) {
        return <>{altText}</>;
    }

    if (source) {
        const fileName = getFileNameFromUrl(source);
        if (fileName) {
            return <>{fileName}</>;
        }
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const imageObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.ImageSource) || '{}');

    const source = imageObject.u;
    const altText = imageObject.a;
    
    let fileName: string | null = '';
    if (source) {
        fileName = getFileNameFromUrl(source);
    }

    return (!!altText && (altText.toLowerCase().includes(filterText)))
        || (!!fileName && (fileName.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}