export function linkHasProtocol(link: string) {
    return link.indexOf('//') === 5 || link.indexOf('//') === 6;
}

export function addHttpsToUrl(link: string) {
    return link.indexOf('://') === -1 ? 'https://' + link.replace(/^\/+/g, '') : link;
}

export function isValidHttpUrl(urlStr: string) {
    let url;
    try {
      url = new URL(urlStr);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function isValidUrl(urlStr: string) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~\\/+=-]*)?'+ // query string
  '(\\#[-a-z\\/\\d_]*)?$','i'); // fragment locator
  
  return pattern.test(urlStr);
}

export function getFileNameFromUrl(url: string) {
  const parts = url?.split('/');
  return parts && parts.length > 0 ? parts[parts.length - 1] : null;
}