import { Tab, Tabs } from '@mui/material';
import SiteEditorStore from "../SiteEditorStore";

export default function SiteEditorPropertiesTabs() {
    const updatePropertyTab = SiteEditorStore.useStoreActions((actions) => actions.updatePropertyTab);
    const propertyTab = SiteEditorStore.useStoreState((state) => state.propertyTab);
    const selectedBlocks = SiteEditorStore.useStoreState((state) => state.selectedBlocks);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      updatePropertyTab(newValue);
    };

    const hasSelectedBlock = selectedBlocks && selectedBlocks.length > 0;

    return (
      <div id="properties-tabs-wrapper" style={{ borderBottom: 1, borderColor: 'divider', margin: "0px 20px 38px 20px" }}>
          <Tabs value={propertyTab} onChange={handleChange} aria-label="Property tabs" TabIndicatorProps={{style: { background:'#cf1f2e' }}} >
              <Tab label="Site" id="site-tab" aria-controls="site-tab-pane" />
              <Tab label="Page" id="page-tab" aria-controls="page-tab-pane" />
              {hasSelectedBlock && <Tab label="Selection" id="block-tab" aria-controls="block-tab-pane" /> }
          </Tabs>
      </div>
    );
}