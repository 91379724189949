import { getPropertyValue, getDynamicAttributes } from "../../../utils/blockHelpers"
import PropertyId from '../../../types/enum/PropertyId';
import BlockProps from "../../../types/BlockProps";
import BlockMapping from "../../../types/BlockMapping";
import Page from "../../../types/Page";
import { isConfiguredProperly as isAnchorConfiguredProperly } from "./AnchorBlock";
import { isConfiguredProperly as isImageConfiguredProperly } from "./ImageBlock";
import BlockWrapper from "../BlockWrapper";
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from "../../../utils/blockRendererDefaults";

export function Block(props: BlockProps) {
    const title = getPropertyValue(props.block.Properties, PropertyId.Title);
    const content = getPropertyValue(props.block.Properties, PropertyId.ContentTextArea);
    let imageLayout = getPropertyValue(props.block.Properties, PropertyId.ImageLayout);
    imageLayout = imageLayout || "p";
    const buttonAlignment = getPropertyValue(props.block.Properties, PropertyId.ButtonAlignment);
    const textAlignment = getPropertyValue(props.block.Properties, PropertyId.TextAlignment);
    const cardWidth = getPropertyValue(props.block.Properties, PropertyId.CardWidth);
    const imageScaling = getPropertyValue(props.block.Properties, PropertyId.ImageScaling);
    
    const anchorBlocks : BlockMapping[] = [];
    const remainingBlockMappings : BlockMapping[] = [];

    let figureImage = null;

    for (let i = 0; i < props.block.BlockMappings.length; i++)
    {
        if (props.block.BlockMappings[i].EnumId === 1 
            && getPropertyValue(props.block.BlockMappings[i].Properties, PropertyId.DisplayType) === "Button") 
        {
            let anchorRenders = isAnchorConfiguredProperly(props.block.BlockMappings[i], false);
            if (anchorRenders)
            {
                anchorBlocks.push(props.block.BlockMappings[i]);
            } 

            continue;
        }

        
        if (props.block.BlockMappings[i].EnumId === 24 ) {
            if (isImageConfiguredProperly(props.block.BlockMappings[i])) {
                if (figureImage === null) {
                    figureImage = props.block.BlockMappings[i];
                }
                else {
                    remainingBlockMappings.push(props.block.BlockMappings[i]);
                }
            }
            
            continue;
        }

        remainingBlockMappings.push(props.block.BlockMappings[i]);
    }

    if (!title && !content && remainingBlockMappings.length === 0 && !figureImage) {
        return null;
    }

    let hasContent = (title || content || remainingBlockMappings.length > 0 || anchorBlocks.length > 0)

    const containerClass = "card-container" + (cardWidth === "w" ? " card-wide" : cardWidth === "f" ? " card-full" : "");
    const cardClass = "card my-16 mx-auto" + 
        (imageLayout === "b" ? " image-full" : (imageLayout === "p" && hasContent) ? " md:card-side" : "") 
        + " bg-base-100 text-black shadow-xl" + (imageLayout !== "b" && !hasContent && figureImage !== null ? " table" : "");
    let cardBodyClass = "card-body" + (textAlignment === "r" ? " text-right" : textAlignment === "c" ? " text-center" : " text-left");
    if (imageLayout === "b") {
        cardBodyClass += " max-w-md";
        switch (textAlignment) {
            case "c":
                cardBodyClass += " items-center justify-self-center";
                break;
            case "r":
                cardBodyClass += " items-end justify-self-end";
                break;
            default:
                cardBodyClass += " items-start justify-self-start";
                break;
        }
    }
    const anchorClass = "card-actions" + (buttonAlignment === "l" ? " justify-start" : buttonAlignment === "c" ? " justify-center" : " justify-end");

    const figureClass = imageScaling === "f" ? "image-cover" : ""
    return (
        <>
            <div className={containerClass}>
                <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: cardClass, blockWrapperData: props.blockWrapperData})}>
            {figureImage &&
                <figure className={figureClass}><BlockWrapper 
                key={`${figureImage.Id}-${0}`} 
                page={props.page} 
                blockmapping={figureImage}
                builderProps={props.builderProps}
                renderData={null}
                /></figure>
            }
            {hasContent &&
                <div className={cardBodyClass}>
                {title && <h2 className="card-title mb-0">{title}</h2>}    
                {(content || remainingBlockMappings.length > 0) && <>
                    <div className="card-content">
                        {content && <p>{content}</p>}
                        {remainingBlockMappings.length > 0 && 
                        remainingBlockMappings.map((b, index) => 
                        <BlockWrapper 
                            key={`${b.Id}-${index}`} 
                            page={props.page} 
                            blockmapping={b}
                            builderProps={props.builderProps}
                            renderData={null}
                            />)
                        }
                    </div>
                </>}
                {anchorBlocks.length > 0 && 
                    <div className={anchorClass}>
                        {anchorBlocks.map((b, index) =>
                            <BlockWrapper 
                            key={`${b.Id}-${index}`} 
                            page={props.page} 
                            blockmapping={b}
                            builderProps={props.builderProps}
                            renderData={null}
                            />
                        )}
                    </div>}
                </div>
            }
            </div></div>
        </>
    )
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return getPropertyValue(block.Properties, PropertyId.Title)
    || getPropertyValue(block.Properties, PropertyId.Content)
    || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent({block}: {block: BlockMapping}): JSX.Element | null {

    const title = block && getPropertyValue(block.Properties, PropertyId.Title);
    const content = block && getPropertyValue(block.Properties, PropertyId.ContentTextArea);

    if (title && content) {
        return  <>{`${title}: ${content}`}</>;
    }
    
    if (title) {
        return <>{title}</>;
    }

    if (content) {
        return <>{content}</>;
    }

    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string): boolean {
    const title = getPropertyValue(block.Properties, PropertyId.Title);
    const content = getPropertyValue(block.Properties, PropertyId.ContentTextArea);

    return (!!title && (title.toLowerCase().includes(filterText)))
        || (!!content && (content.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}