import PropertyEditorProps from "../../types/PropertyEditorProps";

import Property from '../../types/Property';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import BlockMapping from "../../types/BlockMapping";
import PropertyId from "../../types/enum/PropertyId";
import { PropertyNameLabel } from "../common/PropertyNameLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export function Editor({blockMapping, property, propertyUpdated, isPageEditor, validationChanged}: PropertyEditorProps) {
    
    const [ids, setIds] = useState<string[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const buildIdList = () => {
      let selectedIds: string[] = JSON.parse(property?.RenderedValue || '[]');
      let childrenIds: string[] = [];
      blockMapping?.BlockMappings.forEach((bm: BlockMapping) => {
          const idProp = bm.Properties.find(p => p.EnumId === PropertyId.ID);
          if (idProp && idProp.RenderedValue) {
              childrenIds = childrenIds.concat(idProp.RenderedValue);
          }
      });

      setSelectedIds(selectedIds);
      setIds(selectedIds.concat(childrenIds).filter((v: string, i: number, a: string[]) => a.indexOf(v) === i).sort());
    }

    useEffect(() => {
      buildIdList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockMapping, property]);
    
    const handleChange = (event: SelectChangeEvent<typeof selectedIds>) => {
      if (property) {
        const {
          target: { value },
        } = event;
        const propVal = typeof value === 'string' ? value.split(',') : value;
        setSelectedIds(propVal);
        
        const serializedValue = JSON.stringify(propVal);
        property.Value = serializedValue;
        property.RenderedValue = serializedValue;
        buildIdList();
        propertyUpdated(property);
      }
    };

    return (
      <>
      <PropertyNameLabel 
          labelId={`label-${property?.PropertyId}`}
          property={property}
      ></PropertyNameLabel>
      <div>
        <FormControl sx={{ mt: 1, width: '398px' }}>
          <Select
            id="demo-multiple-checkbox"
            multiple
            value={selectedIds}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {ids.map((id) => (
              <MenuItem key={id} value={id}>
                <Checkbox checked={selectedIds.indexOf(id) > -1} />
                <ListItemText primary={id} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      </>
    );
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    const selectedBlocks: string[]
        = JSON.parse(property.RenderedValue || "[]");

    for (const block of selectedBlocks) {
        if (block.toLowerCase().includes(value)) {
            return true;
        }
    }

    return false;
}