import { useState, useRef } from 'react';
import { Box, TextField, InputAdornment, Accordion
    , AccordionSummary, AccordionDetails } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BlockTemplate from '../../../../types/BlockTemplate';
import CategoryType from '../../../../types/CategoryType';
import { organizeCategories, isBlockTemplateCategoryUsed } from '../../../../utils/categoryHelpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDrag } from 'react-dnd';
import SiteEditorStore from '../../SiteEditorStore';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ComponentContextMenu from './ComponentContextMenu';
import TemplateBoxDragHandle from './TemplateBoxDragHandle';

function TemplateBox({template}: {template: BlockTemplate }) {
    const draggingStarted = SiteEditorStore.useStoreActions((actions) => actions.draggingStarted);
    const draggingFinished = SiteEditorStore.useStoreActions((actions) => actions.draggingFinished);
    const closeSidebars = SiteEditorStore.useStoreActions((actions) => actions.closeSidebars);
    const [, drag] = useDrag({
        type: 'BlockMapping',
        item: () => {
            draggingStarted({ type: 'BlockMapping', showCustomDragPreview: true });
            return {
              blockId: template.b,
              name: template.n,
              templateId: template.t,
              componentId: template.ci
            };
        },
        end: (item, monitor) => {
          draggingFinished(null);
          closeSidebars();
        },
    });
    const [menuOpened, setMenuOpened] = useState(false);
    const contextMenuRef = useRef<HTMLDivElement>(null);

    return (
      <div ref={drag} >
        <TemplateBoxDragHandle name={template.n}>
          { template.ci &&
            <Box
                  sx={{
                    width: "42px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 'auto',
                    '&:hover': {
                      color: '#1B1AFF'
                    }
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (menuOpened) {
                      setMenuOpened(false);
                    } else {
                      setMenuOpened(true);
                    }
                  }}
                  ref={contextMenuRef}
                >
                <MoreVertOutlinedIcon/>
                { menuOpened &&
                  <ComponentContextMenu
                    component={template}
                    contextMenuRef={contextMenuRef}
                    closeMenu={() => { setMenuOpened(false); } }
                  />
                }
            </Box>
          }
        </TemplateBoxDragHandle>
      </div>
    )
}


interface CategoryProps {
  category: CategoryType,
  blockTemplates: BlockTemplate[],
  isParent: boolean
}

function Category({category, blockTemplates, isParent }: CategoryProps) {

  const getTemplates = () => {
    const templates = [];
    for (const template of blockTemplates.filter(t => t.c === category.Id)) {
      templates.push(template);
    }
    return templates;
  };

  const getSubCategories = () => {
    return category.Children.filter((c) => isBlockTemplateCategoryUsed(c, blockTemplates))
      .map((c: CategoryType, i: number) => <Category category={c} key={i} blockTemplates={blockTemplates} isParent={false} />)
  };

  const templates = getTemplates();
  const subCategories = getSubCategories();

  return (
    <>
      { (templates.length > 0 || subCategories.length > 0) &&
        <Accordion
          defaultExpanded={true}
          sx={{
            padding: "0px",
            '&:before': {
              display: 'none',
            }
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${category.Name}-content`}
            id={`${category.Name}-header`}
          >
            <span className={isParent ? 'headline6' : ''} style={{ marginLeft: '10px' }}>{category.Name}</span>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }}>
            {templates.sort((a, b) => (a?.n && b?.n && a.n > b.n) ? 1 : -1).map((t, index) => (
                <TemplateBox key={`${t.b}-${t.t}-${index}`} template={t} />
              ))}
            {subCategories}
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
}

export default function SiteEditorSideBarBlocks() {
  const blockCategories = SiteEditorStore.useStoreState((state) => state.blockCategories);
  const blocksFilter = SiteEditorStore.useStoreState((state) => state.blocksFilter);
  const updateBlocksFilter = SiteEditorStore.useStoreActions((actions) => actions.updateBlocksFilter);
  const blocksAndSections = SiteEditorStore.useStoreState((state) => state.blocksAndSections);

  const categoryList = organizeCategories(blockCategories);

  let filteredBlocksAndSections = blocksAndSections;

  if (blocksFilter) {
    filteredBlocksAndSections = blocksAndSections
      .filter((b) => b?.n && b.n.toLowerCase().indexOf(blocksFilter.toLowerCase()) >= 0);
  }

  const usedCategories = categoryList.filter((c: any) => isBlockTemplateCategoryUsed(c, filteredBlocksAndSections));

  return (
    <Box sx={{ padding: "20px" }}>
      <Box className="headline6">Add a Block or Section</Box>
      <TextField
        id="input-text"
        sx={{ marginTop: "20px", width: "100%", marginBottom: "24px" }}
        onChange={(e) => { updateBlocksFilter(e.target.value); }}
        value={blocksFilter}
        placeholder="Search blocks and sections"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{color: "black"}} />
            </InputAdornment>
          ),
        }}
      />
      <Box className="hideScrollbars" sx={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}>
        {usedCategories.map((c: CategoryType) => <Category category={c} key={c.Id} blockTemplates={filteredBlocksAndSections} isParent={true} />)}
      </Box>
    </Box>
  );
}