import DataSourceFieldMapping from "../types/DataSourceFieldMapping";
import PopulatedDataSource from "../types/PopulatedDataSource";
import Property from "../types/Property";
import PropertyValueType from "../types/enum/PropertyValueType";

export function isSwapValue(value: string | null | undefined): boolean {
    return !!(value && (value.startsWith("[D:") || value.startsWith("[P:")));
}

export function getPopulatedDataSourceByKey(populatedDataSources: PopulatedDataSource[], pageProperties: Property[]
    , dataSourceFieldMappingId: string, key: string) {
    let dataSource: PopulatedDataSource | null = null;

    for (const source of populatedDataSources) {
        if (source.Mappings.find(m => m.Id.toLowerCase() === dataSourceFieldMappingId.toLowerCase()))
        {
            dataSource = source;
            break;
        }
    }

    if (!dataSource) {
        return undefined;
    }

    let dataSourceScope = pageProperties.find(p => p.ValueTypeId === PropertyValueType.DataSourceScope 
        && (JSON.parse(p.Configuration || "{}")).DataSourceId?.toLowerCase() === dataSource?.Id.toLowerCase());

    key = dataSourceScope?.Value ?? key;
    
    if (!key) {
        return {Datasource: dataSource, RecordIndex: 0};
    }
    
    let recordIndex = 0;
    const keyMapping = dataSource.Mappings.find((m: DataSourceFieldMapping) => m.IsPrimaryKey);
    if (keyMapping) {
        for (const record of dataSource.Records) {
            if (String(record[keyMapping.FieldName]).toLowerCase() === String(key).toLowerCase()) {
                return {Datasource: dataSource, RecordIndex: recordIndex};
            }
            recordIndex++;
        }
    }

    return undefined;
}

export function getValueFromToken(populatedDataSources: PopulatedDataSource[], siteProperties: Property[]
    , pageProperties: Property[], token:string) {
    
    const reg = new RegExp(/\[(p|d):([a-f0-9-]+):?([^\]]*)\]/, 'gmi');

    const result = reg.exec(token) ?? [];
    const value = lookupTokenValue(populatedDataSources, siteProperties, pageProperties, result[1], result[2], result[3]);

    return value;
}

export function lookupTokenValue(populatedDataSources: PopulatedDataSource[], siteProperties: Property[]
    , pageProperties: Property[], source:string, dataSourceFieldMappingIdOrPropertyId:string, key:string) {
    if (!populatedDataSources)
    {
        return '';
    }
    switch (source) {
        case 'D':
        case 'd':
            if (!populatedDataSources || populatedDataSources.length === 0) return '';

            const foundRecordSource = getPopulatedDataSourceByKey(populatedDataSources, pageProperties, dataSourceFieldMappingIdOrPropertyId, key);
            if (!foundRecordSource) {
                return '';
            }

            const mapping = foundRecordSource.Datasource?.Mappings.find(m => m.Id.toLowerCase() === dataSourceFieldMappingIdOrPropertyId.toLowerCase());
            const value = mapping && foundRecordSource.Datasource?.Records.length > foundRecordSource.RecordIndex ? foundRecordSource.Datasource?.Records[foundRecordSource.RecordIndex][mapping.FieldName] : '';
            return value;

        case 'P':
        case 'p':
            if (!siteProperties || siteProperties.length === 0) return '';
            const property = siteProperties.find(p => p.PropertyId.toLowerCase() === dataSourceFieldMappingIdOrPropertyId.toLowerCase());
            return property?.RenderedValue;
    }
}

