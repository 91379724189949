import { differenceInDays, differenceInMonths, differenceInWeeks, differenceInYears } from "date-fns";

const formatTimeSinceDate = (date: Date): string => {
    const pastDate = new Date(date);    
    const currentDate = new Date();
    
    const days = differenceInDays(currentDate, pastDate);
    const weeks = differenceInWeeks(currentDate, pastDate);
    const months = differenceInMonths(currentDate, pastDate);
    const years = differenceInYears(currentDate, pastDate);

    if (years > 0) {
        return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (weeks > 0) {
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (days > 1) {
        return `${days} days ago`;
    } else if (days === 1) {
        return 'Yesterday';
    } else {
        return 'Today';
    }
}

export { formatTimeSinceDate }

