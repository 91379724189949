import { useState } from "react";
import TemplateTypes from "../../../../../types/enum/TemplateTypes";
import BuilderActionButton from "../BuilderActionButton";
import CreateTemplateModal from "./CreateTemplateModal";

interface CreateTemplateProps {
    id?: string,
    templateType: TemplateTypes,
    siteId?: string,
    pageId?: string
}

export default function CreateTemplate({
    id,
    templateType,
    siteId,
    pageId
}: CreateTemplateProps){

    const [showModal, setShowModal] = useState(false);
    
    const getButtonText = () => {
        switch (templateType) {
            case TemplateTypes.Site:
                return "Create Site Template";
            case TemplateTypes.Page:
                return "Create Page Template";
            case TemplateTypes.Section:
                return "Create Section";
            default:
                return "";
        }
    }
    
    return (
        <>
            <BuilderActionButton
                id={id}
                text={getButtonText()}
                action={() => setShowModal(true)}
            />
            <CreateTemplateModal
                showModal={showModal}
                setShowModal={setShowModal}
                templateType={templateType}
                siteId={siteId}
                pageId={pageId}
            />
        </>
    )
}