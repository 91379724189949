import PropertyId from "../../../types/enum/PropertyId";
import { getPropertyValue, getDynamicAttributes } from "../../../utils/blockHelpers";
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from "../../../utils/blockRendererDefaults";
import BlockFilterData from "../../../types/BlockFilterData";

const isConfiguredProperly = (block: BlockMapping) => {
    return getPropertyValue(block.Properties, PropertyId.FontAwesomeIconClass)
        ? true : false;
};

export function Block(props: BlockProps) {
    const className = 'font-awesome-icon';

    if (!isConfiguredProperly(props.block)) {
        return <></>;
    }

    const iconClass = getPropertyValue(props.block.Properties, PropertyId.FontAwesomeIconClass);
    const iconTitle = getPropertyValue(props.block.Properties, PropertyId.Title)
        || iconClass?.replace(/^fa[brs] fa-/, '').replace('-', ' ')

    return <span {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: `${className} ${iconClass}`, blockWrapperData: props.blockWrapperData})} title={iconTitle} aria-hidden="true">{props.children}</span>;
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const icon = getPropertyValue(block.Properties, PropertyId.FontAwesomeIconClass);

    return icon ? <span data-testid='faicon' className={icon}></span>  : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const icon = getPropertyValue(block.Properties, PropertyId.FontAwesomeIconClass);

    return (!!icon && (icon.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}