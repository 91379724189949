import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropertyEditorProps from "../../types/PropertyEditorProps";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Property from '../../types/Property';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import { PropertyNameLabel } from '../common/PropertyNameLabel';


export function Editor({property, propertyUpdated, isPageEditor}: PropertyEditorProps) {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [search, setSearch] = useState<string | null>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSearch(null);
        setAnchorEl(null);
    };

    const setClassName = (className: string | null) => {
        if (property) {
            property.Value = className;
            property.RenderedValue = className;
            propertyUpdated(property);
            handleClose();
        }
    };

    const configObj = property?.Configuration ? JSON.parse(property?.Configuration) : {};
    let iconList = configObj.Icons;

    if (search) {
        iconList = iconList.filter((c: string) => c.includes(search));
    }

    const clearIcon = () => {
        setClassName(null);
    };

    return (
        <>
            { isPageEditor &&
                <Box sx={{
                    display: "flex"
                }}>
                    <PropertyNameLabel 
                        property={property}
                    ></PropertyNameLabel>
                    { !property?.Value &&
                        <Box sx={{ width: "100%" }}>
                                <AddCircleOutlineIcon sx={{
                                        cursor: "pointer",
                                        fontSize: "26.6px",
                                        marginLeft: "16px",
                                        float: "right"
                                    }}
                                    color="secondary"
                                    onClick={handleClick}
                                />
                            </Box>
                    }
                </Box>
            }
            { (!isPageEditor || property?.Value) &&
                <Box sx={ !isPageEditor ? {} : {
                    marginTop: "8px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Box
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={!isPageEditor ? { 
                            cursor: 'pointer',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            width: '55px',
                            height: '55px',
                            fontSize: '12px',
                            lineHeight: '16px',
                            textAlign: 'center',
                            letterSpacing: '0.4px',
                            padding: '8px'
                        } : {
                            cursor: 'pointer',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            width: '44px',
                            height: '44px',
                            minWidth: "44px",
                            minHeight: "44px",
                            fontSize: '32px',
                            lineHeight: '32px',
                            textAlign: 'center',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "rgba(0, 0, 0, 0.6)"

                        }}
                    >
                        { !isPageEditor &&
                            <Box sx={{ opacity: '.6' }}>{property?.Value ? "Icon" : "No Icon"}</Box>
                        }
                        {property?.Value &&
                            <i className={property?.Value} style={{marginTop: isPageEditor ? undefined : '8px'}}></i>
                        }
                    </Box>
                    { isPageEditor &&
                        <Box sx={{ width: "100%" }}>
                            <DeleteOutlineIcon sx={{
                                    cursor: "pointer",
                                    color: "#CF1F2E",
                                    fontSize: "25px",
                                    marginLeft: "16px",
                                    visibility: property?.Value ? undefined : "hidden",
                                    float: "right"
                                }} 
                                onClick={clearIcon}
                            />
                        </Box>
                    }
                </Box>
            }
            <Popover
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                aria-labelledby="basic-button"
            >
                <Box sx={{ margin: '8px', width: '325px', position: 'relative' }}>
                    <TextField variant="outlined" placeholder='Search icons...' value={search} onChange={(e) => setSearch((e.target as HTMLInputElement).value)} fullWidth />
                </Box>
                <Box sx={{ maxHeight: '260px', maxWidth: '340px', overflowY: 'auto', marginTop: '16px' }}>
                    {iconList.map((iconClass: string, index: number) => (
                        <i 
                            className={iconClass} 
                            key={index}
                            style={{
                                width: '32px',
                                height: '32px',
                                fontSize: '32px',
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                                margin: '16px',
                                cursor: 'pointer',
                                color: isPageEditor ? "rgba(0, 0, 0, 0.6)" : undefined
                            }}
                            onClick={(e) => setClassName(iconClass)}
                        >
                        </i>
                    ))}
                </Box>
                { !isPageEditor && 
                    <Button sx={{ margin: '8px', width: '325px' }} variant="outlined" onClick={clearIcon}>Clear Icon</Button>
                }
            </Popover>
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        return property?.RenderedValue.toLowerCase().includes(value);
    }
}