import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, getPropertyBooleanValue } from '../../../utils/blockHelpers';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const content = getPropertyValue(block.Properties, PropertyId.Content);
    const isVertical = getPropertyBooleanValue(block.Properties, PropertyId.Vertical);
    const className = isVertical ? "divider divider-vertical" : "divider";

    return (
        <div {...getDynamicAttributes({block, builderProps, className, blockWrapperData})}>
            {content}
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return true;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return false;
}

export function Placeholder(props: BlockProps) {
    return null;
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}