import SiteEditorPropertyList from './SiteEditorPropertyList';
import PropertySideBarContent from '../../../types/PropertySideBarContent';
import TabPanel from "../../common/TabPanel";
import TemplateTypes from "../../../types/enum/TemplateTypes";
import SwapTemplate from "./builder_actions/swap_template/SwapTemplate";
import CreateTemplate from "./builder_actions/create_template/CreateTemplate";
import SiteEditorStore from "../SiteEditorStore";
import { filterProperties } from "../../../utils/propertyHelpers";
import NoResultsFound from "../../common/NoResultsFound";
import property_renderers from '../../../utils/propertyRenderers';

export default function SiteEditorPropertiesSiteProperties() {
    const propertyTab = SiteEditorStore.useStoreState((state) => state.propertyTab);
    const pageList = SiteEditorStore.useStoreState((state) => state.pageList);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const siteProperties = SiteEditorStore.useStoreState((state) => state.siteProperties);
    const searchFilter = SiteEditorStore.useStoreState((state) => state.propertySearchCriteria);

    const siteContent: PropertySideBarContent[] = [];

    if (!searchFilter || "create site template".includes(searchFilter)) {
      siteContent.push({
        CategoryTypeId: 999,
        Content: 
          <CreateTemplate
            id={"sitetemplate-createsitetemplate-button"}
            templateType={TemplateTypes.Site}
            siteId={site?.Id}
          />
      });
    }
    
    if(!searchFilter || "swap site template".includes(searchFilter)) {
      siteContent.push({
        CategoryTypeId: 999,
        Content: 
          <SwapTemplate
            id={"siteproperties-swapsitetemplate-button"}
            templateType={TemplateTypes.Site}
          />
      });
    }

    let filteredSiteProperties = siteProperties;
    let noResultsFound = false;

    if (searchFilter) {
      filteredSiteProperties = filterProperties(filteredSiteProperties, searchFilter, { pageList }
        , property_renderers);
      noResultsFound = filteredSiteProperties.length === 0 && siteContent.length === 0;
    }

    return (
      <TabPanel value={propertyTab} name="site-tab" index={0} height="calc(100% - 227px)">
        { !noResultsFound &&
          <SiteEditorPropertyList
            properties={filteredSiteProperties}
            extraContent={siteContent}
          />
        }
        <NoResultsFound display={noResultsFound} />
      </TabPanel>
    );
}