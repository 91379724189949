import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PropertyEditorProps from '../../types/PropertyEditorProps';
import './ScheduleProperty.css';
import Property from '../../types/Property';
import PropertyEditorFilterData from '../../types/PropertyEditorFilterData';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export function Editor({property, propertyUpdated, scheduleProps, isPageEditor}: PropertyEditorProps) {
    const [startValue, setStartValue] = useState<Date | null>(null)
    const [endValue, setEndValue] = useState<Date | null>(null)
    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);

    const endDatePicker = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const parsed = JSON.parse(property?.RenderedValue || '{}');
        setStartValue(parsed?.s ? parseISO(parsed?.s) : null);
        setEndValue(parsed?.e ? parseISO(parsed.e) : null);
    }, [property]);

    const saveDates = (start: Date | null, end: Date | null) => {
        if (property) {
            let propValue = JSON.stringify({
                s: start && isValid(start) ? format(start, 'yyyy-MM-dd') : null,
                e: end && isValid(end) ? format(end, 'yyyy-MM-dd') : null
            });

            property.Value = propValue;
            property.RenderedValue = propValue;
            propertyUpdated(property);
        }
    };

    const startLabel = scheduleProps?.startLabel;
    const startHint = scheduleProps?.startHint ?? 'The block will appear at 12:01 AM on this day.';
    const endLabel = scheduleProps?.endLabel;
    const endHint = scheduleProps?.endHint ?? 'The block will hide at 12:01 AM on this day.';

    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);
    const updateStartValue = (value: Date | null) => {
        setStartValue(value);
        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            saveDates(value,endValue);
        }, 300));
    };
    const updateEndValue = (value: Date | null) => {
        setEndValue(value);
        if(updateTimeout) {
            clearTimeout(updateTimeout);
            setUpdateTimeout(null);
        }
        setUpdateTimeout(setTimeout(() => {
            saveDates(startValue, value);
        }, 300));
    };

    // Since we are leveraging the date picker behind the scenes when on the edit page
    // to set the date, and then using another input to actually display a value, we
    // are going to check the date pickers input to see if its in an error state
    let hasEndDateError =
        isPageEditor
        && endDatePicker?.current?.children
        && endDatePicker.current.children.length > 0
        && endDatePicker.current.children[0].classList.contains('Mui-error');


    const datepickerMarkup = () => {
        return (
            <>
            { isPageEditor &&
                <Box sx={{ marginBottom: '8px', display: "flex" }}>
                    <label className="body1" style={{ width: '100%' }}>
                        Start Date
                    </label>
                    { !startValue &&
                        <Box sx={{ width: "100%" }}>
                            <AddCircleOutlineIcon sx={{
                                    cursor: "pointer",
                                    fontSize: "26.6px",
                                    marginLeft: "16px",
                                    float: "right"
                                }}
                                color="secondary"
                                onClick={() => {
                                    updateStartValue(new Date());
                                    setStartDateOpen(true);
                                }}
                            />
                        </Box>
                    }
                </Box>
            }
            { (!isPageEditor || startValue) &&
                <Box>
                    {!isPageEditor &&
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={startLabel}
                                    value={startValue}
                                    onChange={(newValue: any) => updateStartValue(newValue) }
                                />
                            </LocalizationProvider>
                        </Box>
                    }
                    { isPageEditor &&
                        <>
                        <Box sx={{visibility: 'hidden', height: '0px'}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={startLabel}
                                    value={startValue}
                                    open={startDateOpen}
                                    onClose={() => setStartDateOpen(false)}
                                    onChange={(newValue: any) => updateStartValue(newValue) }
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: "100%" }}>
                                <TextField
                                    value={format(startValue!, 'MMMM dd, yyyy')}
                                    onClick={() => setStartDateOpen(true)}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                                              <CalendarTodayIcon />
                                          </InputAdornment>
                                        )
                                    }}
                                    fullWidth />
                            </Box>
                            { startValue &&
                                <Box>
                                    <DeleteOutlineIcon sx={{
                                            cursor: "pointer",
                                            color: "#CF1F2E",
                                            fontSize: "25px",
                                            marginLeft: "16px",
                                            visibility: property?.Value ? undefined : "hidden",
                                            float: "right"
                                        }}
                                        onClick={() => updateStartValue(null)}
                                    />
                                </Box>
                            }
                        </Box>
                        </>
                    }
                    <p className="hint">{startHint}</p>
                </Box>
            }

            { isPageEditor &&
                <Box sx={{ marginBottom: '8px', marginTop: '16px', display: "flex" }}>
                    <label className="body1" style={{ width: '100%' }}>
                        End Date
                    </label>
                    { !endValue &&
                        <Box sx={{ width: "100%" }}>
                            <AddCircleOutlineIcon sx={{
                                    cursor: "pointer",
                                    fontSize: "26.6px",
                                    marginLeft: "16px",
                                    float: "right"
                                }}
                                color="secondary"
                                onClick={() => {
                                    const tomorrow = new Date();
                                    tomorrow.setDate(tomorrow.getDate() + 1);
                                    updateEndValue(tomorrow);
                                    setEndDateOpen(true);
                                }}
                            />
                        </Box>
                    }
                </Box>
            }
            { (!isPageEditor || endValue) &&
                <>
                <Box>
                    {!isPageEditor &&
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={endLabel}
                                    value={endValue}
                                    minDate={startValue}
                                    onChange={(newValue: any) => updateEndValue(newValue)}
                                />
                            </LocalizationProvider>
                        </Box>
                    }
                    { isPageEditor &&
                        <>
                        <Box sx={{visibility: 'hidden', height: '0px'}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={endLabel}
                                    value={endValue}
                                    open={endDateOpen}
                                    ref={endDatePicker || null}
                                    onClose={() => setEndDateOpen(false)}
                                    minDate={startValue}
                                    onChange={(newValue: any) => updateEndValue(newValue)}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: "100%" }}>
                                <TextField
                                    value={format(endValue!, 'MMMM dd, yyyy')}
                                    onClick={() => setEndDateOpen(true)}
                                    InputProps={{
                                        readOnly: true,
                                        className: hasEndDateError ? 'Mui-error' : '',
                                        endAdornment: (
                                          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                                              <CalendarTodayIcon />
                                          </InputAdornment>
                                        )
                                    }}
                                    fullWidth />
                            </Box>
                            { endValue &&
                                <Box>
                                    <DeleteOutlineIcon sx={{
                                            cursor: "pointer",
                                            color: "#CF1F2E",
                                            fontSize: "25px",
                                            marginLeft: "16px",
                                            visibility: property?.Value ? undefined : "hidden",
                                            float: "right"
                                        }}
                                        onClick={() => updateEndValue(null)}
                                    />
                                </Box>
                            }
                        </Box>
                        </>
                    }
                    <p className="hint">{endHint}</p>
                </Box>
                </>
            }
            </>
        );
    }

    return (<>
        { !isPageEditor &&
            <Box sx={{
                width: '100%',
                display: { xs: 'none', sm: 'grid', md: 'grid'},
                gap: 1,
                gridTemplateColumns: 'repeat(2, 1fr)'
            }}>
                {datepickerMarkup()}
            </Box>
        }

        <Box sx={{ width: '100%',
            display: isPageEditor ? 'block' : { xs: 'block', sm: 'none', md: 'none'}}}>
            {datepickerMarkup()}
        </Box>
    </>)
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const obj = JSON.parse(property.RenderedValue);
        const formatStr = "M/d/yyyy MM/dd/yyyy MM/d/yyyy M/d/yy MM/d/yy MM/dd/y M/dd/yy M-d-yyyy yyyy-M-d MMMM do yyyy";
        if (obj.s) {
            const startDate = format(Date.parse(obj.s + 'T00:00:00'), formatStr);
            if (startDate.toLowerCase().includes(value)) {
                return true;
            }
        }
        if (obj.e) {
            const endDate = format(Date.parse(obj.e + 'T00:00:00'), formatStr);
            if (endDate.toLowerCase().includes(value)) {
                return true;
            }
        }
        return false;
    }
}
