import { useState } from 'react';
import useApiGet from '../../hooks/useApiGet';
import ErrorMessage from '../common/ErrorMessage';
import Loading from '../common/Loading';
import { TextField, Select, MenuItem, Button }  from '@mui/material';
import Divider from '@mui/material/Divider';
import { Paper } from '@mui/material';
import FormValidationError from '../common/FormValidationError';
import { SelectChangeEvent } from '@mui/material/Select';
import Type from '../../types/Type';
import useApiPutCallbackAsync from '../../hooks/useApiPutCallbackAsync';
import BuilderDialog from '../common/BuilderDialog';
import CustomBlock from '../../types/CustomBlock';

interface CustomBlockEditorSettingsProps {
    block: CustomBlock,
    goToCustomBlocks: () => void,
    updateBlockName: (name: string) => void,
    updateBlockDescription: (description: string) => void,
    updateBlockCategory: (categoryTypeId: number) => void
}

export default function CustomBlockEditorSettings({block, goToCustomBlocks, updateBlockName, updateBlockDescription, updateBlockCategory}: CustomBlockEditorSettingsProps) {
    const [showDeleteError, setShowDeleteError] = useState(false);
    const [showDeleteBlock, setShowDeleteBlock] = useState(false);

    const [{ isLoading: deleteLoading, errorResponse: deleteApiError }, deleteBlockCallback] 
        = useApiPutCallbackAsync(`/block/custom/${block?.Id}/status/303`, []) as any;
    const [{ data: blockCategories }] = useApiGet("/type/200", []);

    const deleteBlock = () => {
        setShowDeleteError(false);

        deleteBlockCallback({}
            , () => {
                goToCustomBlocks();
            }, () => {
                setShowDeleteError(true);
            }
        );
    };

    return (
        <>
        <Paper sx={{ height: "100%", padding: "16px" }}>
            <div style={{ width: "500px" }}>
                <div style={{ marginBottom: "32px" }}>
                    <label htmlFor="block-name" className="subtitle2">Block Name</label>
                    <TextField 
                        id="block-name" 
                        value={block?.Name} 
                        onChange={(e) => updateBlockName(e.target.value) } 
                        label="" 
                        variant="outlined" 
                        fullWidth 
                        sx={{ marginTop: "8px" }}
                        inputProps={{ maxLength: 200 }}
                        color="secondary" />
                    <FormValidationError valid={!!block?.Name} message="Block name is required." />
                </div>
                <div style={{ marginBottom: "32px" }}>
                    <label htmlFor="block-description">Block Summary</label>
                    <TextField 
                        id="block-description" 
                        value={block?.Description} 
                        multiline={true} 
                        minRows={3} 
                        onChange={(e) => updateBlockDescription(e.target.value) } 
                        label="" 
                        variant="outlined" 
                        inputProps={{ maxLength: 500 }}
                        fullWidth 
                        sx={{ marginTop: "8px" }} 
                        color="secondary" />
                </div>
                <div style={{ marginBottom: "32px" }}>
                    <label htmlFor="block-category">Category</label>
                    <Select
                        id="block-category"
                        value={blockCategories && blockCategories.length > 0 ? block?.CategoryTypeId.toString() : ''}
                        label=""
                        fullWidth
                        onChange={(e: SelectChangeEvent) => updateBlockCategory(parseInt(e.target.value)) }
                        sx={{ marginTop: "8px" }}
                    >
                        { blockCategories && blockCategories.filter((c: Type) => c.ParentTypeId === 201).map((c: Type) => <MenuItem value={c.Id} key={c.Id}>{c.Name}</MenuItem> )}
                    </Select>
                </div>
            </div>
            <Divider sx={{ marginTop: "32px", marginBottom: "32px" }} />
            <div className="subtitle2">Delete This Block</div>
            <div className="body2" style={{ marginTop: "8px", marginBottom: "16px"}}>This action is permanent and cannot be undone!</div>
            <Button variant="contained" color="error" onClick={() => setShowDeleteBlock(true)}>DELETE THIS BLOCK</Button>
        </Paper>
        { (deleteLoading) && <Loading />}
        <BuilderDialog 
            title="Delete Block" 
            message="Are you sure you wish to delete this block? This is a permanent action and cannot be undone."
            action={deleteBlock}
            actionName="Delete"
            open={showDeleteBlock}
            onClose={() => setShowDeleteBlock(false) }
            danger={true}
            showCancel={true}
        />
        <ErrorMessage 
            message="Error Deleting Block." 
            duration={15000}
            visible={showDeleteError}
            apiError={deleteApiError}
            dismissable={true}
        />
        </>
    )
}