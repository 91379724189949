import { useEffect, useState, useCallback, useRef } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { Add } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BlockId from '../../../types/enum/BlockId';
import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue } from '../../../utils/blockHelpers';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Editor as TextEditorProperty } from '../../property_editors/TextEditorProperty';
import { Editor as FontAwesomeIconProperty } from '../../property_editors/FontAwesomeIconProperty';
import { Editor as AnchorConfigProperty } from '../../property_editors/AnchorConfigProperty';
import './ExternalLinksStep.css';
import { getFontAwesomeAnchor } from "../../../utils/blockTemplates";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import BuilderDialog from '../../common/BuilderDialog';
import Page from '../../../types/Page';
import BlockMapping from '../../../types/BlockMapping';
import Property from '../../../types/Property';
import { HTML5Backend } from 'react-dnd-html5-backend';

export interface AnchorSectionProps {
    id: number;
    btn: any;
    index: number;
    moveButton: (dragIndex: any, hoverIndex: any) => void;
    removeButton: (index: any) => void;
    editButton: (index: any) => void;
}

export const AnchorSection = ({ id, btn, index, moveButton, removeButton, editButton }: AnchorSectionProps) => {
    const ref = useRef<Element>(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'AnchorSection',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset ? clientOffset.y : 0) - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveButton(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [, drag] = useDrag({
        type: 'AnchorSection',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Box
            ref={ref}
            data-handler-id={handlerId}
            key={index}
            style={{
                position: 'relative',
                maxWidth: '600px',
                marginTop: '16px',
                marginBottom: '16px',
                display: 'grid',
                gridAutoColumns: 'auto',
                gridAutoFlow: 'column'
            }}
        >
            <Box sx={{ whiteSpace: 'nowrap', cursor: 'move', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
                <Box sx={{ height: '100%', float: 'left', display: "flex", alignItems: "center", paddingRight: "10px" }}>
                    <DragIndicatorIcon sx={{ color: "#646464", height: "22px" }} />
                </Box>
                <Box sx={{ float: 'left', position: 'relative', top: '12px', width: '38px' }}>
                    {btn.IconClass && <i style={{ fontSize: '22px', lineHeight: '16px' }} className={btn.IconClass}></i>}
                </Box>
                <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
                    <span className="caption">{btn.Url}</span>
                    <br />
                    <span className="body1">{btn.Display}</span>
                </Box>
            </Box>
            <Box sx={{textAlign: 'right', position: 'relative', top: '5px' }}>
                <Button className="button-edit-button" onClick={() => editButton(index)}>
                    <EditIcon /> Edit
                </Button>
                <DeleteOutlineIcon sx={{ height: '18px', color: '#B00020', position: 'relative', top: '3px', marginLeft: '32px', cursor: 'pointer'}} onClick={() => removeButton(index)} />
            </Box>
        </Box>
    );
};

export interface ExternalLinksStepProps {
    templateData: Page;
    originalTemplateData: Page | BlockMapping | null;
    blockmapping: BlockMapping;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
    updatePreviewBlockId: React.Dispatch<React.SetStateAction<string | null>>;
}

function ExternalLinksStep({ templateData, originalTemplateData, blockmapping, setTemplateData, updatePreviewBlockId }: ExternalLinksStepProps) {
    const [editButtonAnchorConfigProperty, setEditButtonAnchorConfigProperty] = useState<Property | null>(null);
    const [editButtonContentProperty, setEditButtonContentProperty] = useState<Property | null>(null);
    const [editButtonIconProperty, setEditButtonIconProperty] = useState<Property | null>(null);
    const [openEditButtonDialog, setOpenEditButtonDialog] = useState(false);
    const [buttonBeingEdited, setButtonBeingEdited] = useState<number | null>(null);
    const [openAddButtonDialog, setOpenAddButtonDialog] = useState(false);
    const [buttonBeingAdded, setButtonBeingAdded] = useState<BlockMapping | null>(null);
    const [addButtonAnchorConfigProperty, setAddButtonAnchorConfigProperty] = useState<Property | undefined | null>(null);
    const [addButtonContentProperty, setAddButtonContentProperty] = useState<Property | undefined | null>(null);
    const [addButtonIconProperty, setAddButtonIconProperty] = useState<Property | null>(null);

    const googleAnalyticsEvent = "mobile_button";

    const cancelAdd = () => {
        setButtonBeingAdded(null);
        setAddButtonAnchorConfigProperty(null);
        setAddButtonContentProperty(null);
        setOpenAddButtonDialog(false);
    };

    const addButtonToTemplate = (newButton: BlockMapping) => {
        for (let block of templateData.BlockMappings) {
            if (block.Id === blockmapping?.Id) {
                block.BlockMappings.push(newButton);
                return;
            }
        }
    };

    const saveAddButton = (btn: BlockMapping, content: Property | null | undefined, anchorConfig: Property | null | undefined, icon: Property | null | undefined) => {
        let contentProperty = btn.Properties?.find(p => p.EnumId === PropertyId.Content);
        let anchorConfigProperty = btn.Properties?.find(p => p.EnumId === PropertyId.AnchorConfig);
        let childPositionProperty = btn.Properties?.find(p => p.EnumId === PropertyId.ChildrenPosition);
        let googleAnalyticsEventProperty = btn.Properties?.find(p => p.EnumId === PropertyId.GoogleAnalyticsEvent);
        let googleAnalyticsLabelProperty = btn.Properties?.find(p => p.EnumId === PropertyId.GoogleAnalyticsLabel);
        const iconBlock = findIcon(btn);
        const iconProperty = iconBlock?.Properties?.find(p => p.EnumId === PropertyId.FontAwesomeIconClass);
        if (contentProperty) {
            contentProperty.RenderedValue = content?.RenderedValue;
            contentProperty.Value = content?.Value;
            contentProperty.ValueUpdatedInWizard = true;
        }
        if (anchorConfigProperty) {
            anchorConfigProperty.RenderedValue = anchorConfig?.RenderedValue;
            anchorConfigProperty.Value = anchorConfig?.Value;
            anchorConfigProperty.ValueUpdatedInWizard = true;
        }
        if (childPositionProperty) {
            childPositionProperty.RenderedValue = "Before";
            childPositionProperty.Value = "Before";
            childPositionProperty.ValueUpdatedInWizard = true;
        }
        if (googleAnalyticsLabelProperty) {
            googleAnalyticsLabelProperty.RenderedValue = content?.RenderedValue;
            googleAnalyticsLabelProperty.Value = content?.Value;
            googleAnalyticsLabelProperty.ValueUpdatedInWizard = true;
        }
        if (googleAnalyticsEventProperty) {
            googleAnalyticsEventProperty.Value = googleAnalyticsEvent;
            googleAnalyticsEventProperty.RenderedValue = googleAnalyticsEvent;
            googleAnalyticsEventProperty.ValueUpdatedInWizard = true;
        }
        if (iconProperty) {
            iconProperty.Value = icon?.Value;
            iconProperty.RenderedValue = icon?.RenderedValue;
            iconProperty.ValueUpdatedInWizard = true;
        }
        addButtonToTemplate(btn);
        setTemplateData({...templateData});
        cancelAdd();
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const findAnchors = (template: Page) => {
        const anchors = [];

        if (template?.BlockMappings) {
            for (let block of template.BlockMappings) {
                if (block.Id === blockmapping?.Id && block.BlockMappings) {
                    for (let child of block.BlockMappings) {
                        if (child.EnumId === BlockId.Anchor) {
                            anchors.push(child);
                        }
                    }
                }
            }
        }
        return anchors;
    };

    const findIcon = (anchor: BlockMapping) => {
        if (anchor?.BlockMappings) {
            for (let block of anchor.BlockMappings) {
                if (block.EnumId === BlockId.FontAwesomeIcon) {
                    return block;
                }
            }
        }
        return null;
    };

    // Find the buttons/icons in the current and original template
    const templateAnchors = findAnchors(templateData);
    const anchorList = [];

    for (let link of templateAnchors) {
        const config = getPropertyValue(link.Properties, PropertyId.AnchorConfig);
        const configObj = config != null ? JSON.parse(config) : null;
        const content = getPropertyValue(link.Properties, PropertyId.Content);
        let iconClass = null;
        const icon = findIcon(link);
        if (icon) {
            iconClass = getPropertyValue(icon.Properties, PropertyId.FontAwesomeIconClass);
        }
        anchorList.push({
            Id: link.Id,
            Url: configObj.Value ?? configObj.v,
            Display: content,
            IconClass: iconClass
        });
    }

    const cancelEdit = () => {
        setOpenEditButtonDialog(false);
        setEditButtonAnchorConfigProperty(null);
        setEditButtonContentProperty(null);
        setEditButtonIconProperty(null);
        setButtonBeingEdited(null);
    };

    const saveEditButton = (btn: BlockMapping, content: Property | null, anchorConfig: Property | null, icon: Property | null) => {
        let contentProperty = btn.Properties?.find(p => p.EnumId === PropertyId.Content);
        let anchorConfigProperty = btn.Properties?.find(p => p.EnumId === PropertyId.AnchorConfig);
        let googleAnalyticsEventProperty = btn.Properties?.find(p => p.EnumId === PropertyId.GoogleAnalyticsEvent);
        let googleAnalyticsLabelProperty = btn.Properties?.find(p => p.EnumId === PropertyId.GoogleAnalyticsLabel);
        const iconBlock = findIcon(btn);
        const iconProperty = iconBlock?.Properties?.find((p: Property) => p.EnumId === PropertyId.FontAwesomeIconClass);
        if (contentProperty) {
            contentProperty.RenderedValue = content?.RenderedValue;
            contentProperty.Value = content?.Value;
            contentProperty.ValueUpdatedInWizard = true;
        }
        if (anchorConfigProperty) {
            anchorConfigProperty.RenderedValue = anchorConfig?.RenderedValue;
            anchorConfigProperty.Value = anchorConfig?.Value;
            anchorConfigProperty.ValueUpdatedInWizard = true;
        }
        if (iconProperty) {
            iconProperty.RenderedValue = icon?.RenderedValue;
            iconProperty.Value = icon?.Value;
            iconProperty.ValueUpdatedInWizard = true;
        }
        if (googleAnalyticsLabelProperty) {
            googleAnalyticsLabelProperty.RenderedValue = content?.RenderedValue;
            googleAnalyticsLabelProperty.Value = content?.Value;
            googleAnalyticsLabelProperty.ValueUpdatedInWizard = true;
        }
        if (googleAnalyticsEventProperty) {
            googleAnalyticsEventProperty.RenderedValue = googleAnalyticsEvent;
            googleAnalyticsEventProperty.Value = googleAnalyticsEvent;
            googleAnalyticsEventProperty.ValueUpdatedInWizard = true;
        }
        setTemplateData({...templateData});
        cancelEdit();
    };

    useEffect(() => {
        updatePreviewBlockId(blockmapping?.Id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cloneObj = (obj: Property | undefined) => {
        return JSON.parse(JSON.stringify(obj));
    };

    const removeButton = (index: number) => {
        let btnIndex = 0;
        for (let block of templateData.BlockMappings) {
            if (block.Id === blockmapping?.Id) {
                for (let i = 0; i < block.BlockMappings.length; i++) {
                    if (block.BlockMappings[i].EnumId === BlockId.Anchor) {
                        if (btnIndex === index) {
                            block.BlockMappings.splice(i, 1);
                            setTemplateData({...templateData});
                            return;
                        }

                        btnIndex++;
                    }
                }
            }
        }
    };

    const editButton = (index: number) => {
        const anchorConfigProperty = cloneObj(templateAnchors[index].Properties?.find((p: Property) => p.EnumId === PropertyId.AnchorConfig));
        const contentProperty = cloneObj(templateAnchors[index].Properties?.find((p: Property) => p.EnumId === PropertyId.Content));
        const iconBlock = findIcon(templateAnchors[index]);
        const iconProperty = cloneObj(iconBlock?.Properties?.find((p: Property) => p.EnumId === PropertyId.FontAwesomeIconClass));
        setEditButtonAnchorConfigProperty(anchorConfigProperty);
        setEditButtonContentProperty(contentProperty);
        setEditButtonIconProperty(iconProperty);
        setButtonBeingEdited(index);
        setOpenEditButtonDialog(true);
    };

    const editButtonContentPropertyUpdated = (property: Property) => {
        setEditButtonContentProperty({...property});
    };

    const editButtonAnchorConfigPropertyUpdated = (property: Property) => {
        setEditButtonAnchorConfigProperty({...property});
    };

    const editButtonIconPropertyUpdated = (property: Property) => {
        setEditButtonIconProperty({...property});
    };

    const addButton = () => {
        const addBtn = getFontAwesomeAnchor();
        const config = addBtn.Properties?.find(p => p.EnumId === PropertyId.AnchorConfig);
        const configObj = config && config.RenderedValue ? JSON.parse(config.RenderedValue) : {};
        configObj.t = 'ex';
        configObj.v = '';
        const newConfigObj = JSON.stringify(configObj);
        if (config) {
            config.RenderedValue = newConfigObj;
            config.Value = newConfigObj;
            config.ValueUpdatedInWizard = true;
        }
        const content = addBtn.Properties?.find(p => p.EnumId === PropertyId.Content);
        if (content) {
            content.RenderedValue = '';
            content.Value = '';
            content.ValueUpdatedInWizard = true;
        }
        const displayType = addBtn.Properties?.find(p => p.EnumId === PropertyId.DisplayType);
        if (displayType) {
            displayType.RenderedValue = "";
            displayType.Value = "";
            displayType.ValueUpdatedInWizard = true;
        }
        const iconBlock = findIcon(addBtn);
        const iconProperty = iconBlock?.Properties?.find((p: Property) => p.EnumId === PropertyId.FontAwesomeIconClass);
        if (iconProperty) {
            iconProperty.RenderedValue = null;
            iconProperty.Value = null;
            iconProperty.ValueUpdatedInWizard = true;
        }
        setAddButtonAnchorConfigProperty(config);
        setAddButtonContentProperty(content);
        setAddButtonIconProperty(iconProperty || null);
        setButtonBeingAdded(addBtn);
        setOpenAddButtonDialog(true);
    };

    const addButtonContentPropertyUpdated = (property: Property) => {
        setAddButtonContentProperty(Object.assign({}, addButtonContentProperty));
    };

    const addButtonAnchorConfigPropertyUpdated = (property: Property) => {
        setAddButtonAnchorConfigProperty(Object.assign({}, addButtonAnchorConfigProperty));
    };

    const addButtonIconPropertyUpdated = (property: Property) => {
        setAddButtonIconProperty({...property});
    };

    const moveButton = useCallback((dragIndex: number, hoverIndex: number) => {
        let btnIndex = 0;
        let btnToMove = null;
        for (let block of templateData.BlockMappings) {
            if (block.Id === blockmapping?.Id) {
                for (let i = 0; i < block.BlockMappings.length; i++) {
                    if (block.BlockMappings[i].EnumId === BlockId.Anchor) {
                        if (btnIndex === dragIndex) {
                            btnToMove = block.BlockMappings.splice(i, 1)[0];
                            break;
                        }

                        btnIndex++;
                    }
                }
            }
        }

        if (btnToMove) {
            for (let block of templateData.BlockMappings) {
                if (block.Id === blockmapping?.Id) {
                    block.BlockMappings.splice(hoverIndex, 0, btnToMove);
                }
            }

            setTemplateData({...templateData});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData, blockmapping]);

    const renderAnchorSection = (btn: any, index: number) => {
        return (<AnchorSection key={index} index={index} id={index} btn={btn} moveButton={moveButton} editButton={editButton} removeButton={removeButton} />);
    };

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Add external links</h1>
                <p className="body1">Create links to the client's existing website, menu, contact form or other destinations.</p>
            </div>

            <Button
                variant="outlined"
                sx={{ marginBottom: '16px', width: fullScreen ? '100%' : 'inherit' }}
                startIcon={<Add/>}
                onClick={addButton}
            >
                ADD LINK
            </Button>

            <Box sx={{ marginTop: "40px" }}>
                <DndProvider backend={HTML5Backend} context={window}>
                    {anchorList.map((btn: any, i: number) => renderAnchorSection(btn, i))}
                </DndProvider>
            </Box>

            {buttonBeingEdited !== null &&
                <BuilderDialog
                    title={"Edit link"}
                    open={openEditButtonDialog}
                    onClose={cancelEdit}
                    aria-labelledby="responsive-dialog-title"
                    actionName="Save"
                    action={() => saveEditButton(templateAnchors[buttonBeingEdited], editButtonContentProperty, editButtonAnchorConfigProperty, editButtonIconProperty)}
                    showCancel={true}
                >
                    <DialogContent
                        sx={{
                            overflow: 'visible'
                        }}>
                        <Box>
                            <Box sx={{marginTop: '40px' }}>
                                <FontAwesomeIconProperty property={editButtonIconProperty} propertyUpdated={editButtonIconPropertyUpdated} pageList={[]} />
                            </Box>
                            <Box sx={{marginTop: '40px' }}>
                                <TextEditorProperty property={editButtonContentProperty} propertyUpdated={editButtonContentPropertyUpdated} label="Link text" isPageEditor={false} pageList={[]} />
                            </Box>
                            <Box sx={{marginTop: '40px' }}>
                                <AnchorConfigProperty property={editButtonAnchorConfigProperty} propertyUpdated={editButtonAnchorConfigPropertyUpdated} page={templateData} pageList={[]} />
                            </Box>
                        </Box>
                    </DialogContent>
                </BuilderDialog>
            }

            {buttonBeingAdded !== null &&
                <BuilderDialog
                    title={"Add a new link"}
                    open={openAddButtonDialog}
                    onClose={cancelAdd}
                    aria-labelledby="responsive-dialog-title"
                    actionName="Create"
                    action={() => saveAddButton(buttonBeingAdded, addButtonContentProperty, addButtonAnchorConfigProperty, addButtonIconProperty)}
                    showCancel={true}
                >
                    <DialogContent
                        sx={{
                            overflow: 'visible'
                        }}>
                        <Box>
                            <Box sx={{marginTop: '40px' }}>
                                <FontAwesomeIconProperty property={addButtonIconProperty} propertyUpdated={addButtonIconPropertyUpdated} pageList={[]} />
                            </Box>
                            <Box sx={{marginTop: '40px' }}>
                                <TextEditorProperty property={addButtonContentProperty || null} propertyUpdated={addButtonContentPropertyUpdated} label="Link text" isPageEditor={false} pageList={[]} />
                            </Box>
                            <Box sx={{marginTop: '40px' }}>
                                <AnchorConfigProperty property={addButtonAnchorConfigProperty || null} propertyUpdated={addButtonAnchorConfigPropertyUpdated} page={templateData} pageList={[]} />
                            </Box>
                        </Box>
                    </DialogContent>
                </BuilderDialog>
            }
        </>
    );
}

export default ExternalLinksStep;
