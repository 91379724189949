import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockMapping from '../../../types/BlockMapping';
import BlockProps from '../../../types/BlockProps';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const content = getPropertyValue(block.Properties, PropertyId.Content);
    const colSpan = getPropertyValue(block.Properties, PropertyId.ColSpan);
    const rowSpan = getPropertyValue(block.Properties, PropertyId.RowSpan);

    return (
        <td {...getDynamicAttributes({block, builderProps, blockWrapperData})} colSpan={colSpan} rowSpan={rowSpan}>
            {isChildrenPositionBefore(block) && children}
            {content}
            {!isChildrenPositionBefore(block) && children}
        </td>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return getPropertyValue(block.Properties, PropertyId.Content)
        || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const content = getPropertyValue(block.Properties, PropertyId.Content)
    
    return content ? <>{content}</> : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const content = getPropertyValue(block.Properties, PropertyId.Content)

    return (!!content && (content.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}