// @ts-ignore
import hexToHsl from 'hex-to-hsl';
import hsl from 'hsl-to-hex';

export function hexToRgb(hex: string): number[] {
    if (!hex) {
        return [];
    }
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    return [r, g, b];
}

export function generateRgbaValue(colorProperty: any): string {
    if (!colorProperty?.hex) {
        return '';
    }

    const colorRgb = hexToRgb(colorProperty.hex);
    return `rgba(${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}, ${colorProperty.a ?? 1})`;
}

export function rgbToHex(r: number, g: number, b: number) {
    return "#" + r.toString(16) + g.toString(16) + b.toString(16);
}

export function invertColor(hex: string) {
    let black;
    let [r, g, b] = hexToRgb(hex);
    if (r && g && b) {
        var decimalRgb = [r, g, b].map(c => c / 255);
        // https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
        const [R, G, B] = decimalRgb.map(c => {
            return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
        })

        // https://stackoverflow.com/a/3943023/112731
        // edited Jan 16, 2019 at 5:01
        const L = 0.2126 * R + 0.7152 * G + 0.0722 * B;
        black = (L + 0.05) / (0.0 + 0.05) > (1.0 + 0.05) / (L + 0.05);
    }
    return black
        ? { hex: '#000000', hsl: '0 0% 0%' }
        : { hex: '#ffffff', hsl: '0 0% 100%' };
};

export function shadeTintColor(hslColor: string) {
    let [h, s, l] = /(\d+) (\d+)% (\d+)%/.exec(hslColor)!.slice(1)
        .map(n => parseInt(n));
    l = l > 50 ? l - 20 : l + 20;
    return {
        hex: hsl(h, s, l),
        hsl: h + ' ' +  s + '% ' + l + '%'
    };
};

export function hexToHslString(hex: string) {
    const [hue, saturation, luminosity] = hexToHsl(hex);
    return `${hue} ${saturation}% ${luminosity}%`;
}