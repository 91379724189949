import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import IIdentityHeaders from "../types/IdentityHeaders";
import UserSessionData from "../types/UserSessionData";

export const httpMethods = {
    get: 'GET',
    post: 'POST',
    delete: 'DELETE',
    put: 'PUT'
}

//TODO: Update to functional component
export class ResponseError extends Error {
    response;
    body;

    constructor(message: string, response: any, body: any) { 
        super(message); 
        this.response = response; 
        this.body = body;
    } 
}

export async function makeApiCall(route: string, method: string, identityHeaders: IIdentityHeaders, requestData?: any, apiPrefix?: string) {
    apiPrefix = apiPrefix === null || apiPrefix === undefined ? (window as any).CONFIG.RevSiteApiPrefix : apiPrefix;
    var initObj = {
        method: method,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${identityHeaders.token}`,
            "Content-Type": "application/json",
            "X-REVLOCAL-SESSIONID": identityHeaders.sessionId,
            "X-REVLOCAL-ORIGINATINGUSER": identityHeaders.user,
            "X-REVLOCAL-CORRELATIONID": identityHeaders.correlationId
        }
    } as any;
    
    if (method === httpMethods.post || method === httpMethods.put) {
        initObj.body = JSON.stringify(requestData);
        return await makeFetchCall(route, initObj, apiPrefix);
    } else if (method === httpMethods.get ||
        method === httpMethods.delete) {
        return await makeFetchCall(route, initObj, apiPrefix);
    }
}

export async function callApi(route: string, method: string, userSession: UserSessionData | null
    , getAccessTokenSilently: ((options?: GetTokenSilentlyOptions | undefined) => Promise<string>) | null, requestData?: any, apiPrefix?: string): Promise<any> {
    apiPrefix = apiPrefix === null || apiPrefix === undefined ? (window as any).CONFIG.RevSiteApiPrefix : apiPrefix;

    if (userSession && getAccessTokenSilently) {
        const token = await getAccessTokenSilently();
        const identityHeaders = {
        token,
        user: userSession.currentUser?.name ?? '',
        sessionId: userSession.sessionId,
        correlationId: userSession.correlationId
        };

        return await makeApiCall(route, method, identityHeaders, requestData, apiPrefix);
    } else {
        throw new Error("Invalid API state");
    }
}

async function getBody(response: any) {
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1) {
        return await response.json();
    } else {
        return await response.text();
    } 
}

async function makeFetchCall(route: string, initObject: any, apiPrefix: string = "") {
    const response = await fetch(
        (window as any).CONFIG.ApiBaseUrl + apiPrefix + route,
        initObject
    );

    const body = await getBody(response);

    if (response.ok) {
        return body;
    } else {
        throw new ResponseError(`Problem calling API: ${response.statusText}`, response, body);
    }
}