import { Box } from "@mui/material";
import React from "react";
import Preview from "../../common/Preview";
import Page from "../../../types/Page";
import classNames from "classnames";

export interface SiteWizardPreviewProps {
    readonly activeStep: number;
    readonly steps: any[];
    readonly favicon: string | null;
    readonly simulateShareImageFormatting: boolean;
    readonly shareImage: string | null;
    readonly templateData: Page | null;
    readonly previewBlockId: string | null;
}

const SiteWizardPreview: React.FC<SiteWizardPreviewProps> = ({
    activeStep,
    steps,
    favicon,
    simulateShareImageFormatting,
    shareImage,
    templateData,
    previewBlockId,
}) => (
    <div className={classNames({
        "wizard-col-2": true,
        "review" : activeStep === steps.length - 1
    })}>
        <Box
            sx={{
                borderLeft: "thin solid rgba(0, 0, 0, 0.12)",
                pl: 2,
                height: "100%",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {activeStep === 4 && (
                <>
                    <h2 style={{ marginTop: "10px" }}>Favicon Preview</h2>
                    <div
                        style={{
                            border: "4px grey dashed",
                            height: "40px",
                            width: "40px",
                            marginBottom: "24px",
                        }}
                    >
                        {favicon && (
                            <img
                                style={{ height: "100%", width: "100%" }}
                                alt="favicon"
                                src={favicon}
                            />
                        )}
                    </div>
                    <h2 style={{ marginTop: "10px" }}>Share image Preview</h2>
                    <div
                        style={(simulateShareImageFormatting || !shareImage) ? {
                            border: "4px grey dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "157.5px",
                            width: "300px",
                            marginBottom: "24px",
                        } : {
                            border: "4px grey dashed",
                            marginBottom: "24px",
                            width: "fit-content",
                            height: "fit-content",
                        }}
                    >
                        {shareImage && (
                            <img
                                style={simulateShareImageFormatting ? {
                                    height: "100%",
                                    display: "block",
                                    width: "100%",
                                    maxHeight: "100px",
                                    maxWidth: "150px",
                                    objectFit: "contain"
                                } : {
                                    display: "block",
                                    maxHeight: "157.5px",
                                    maxWidth: "300px",
                                    objectFit: "contain"
                                }}
                                alt="share"
                                src={shareImage}
                            />
                        )}
                    </div>
                </>
            )}
            {activeStep !== 4 && (
                <>
                    <h2 style={{ marginTop: "10px" }}>Website Preview</h2>
                    <div
                        style={{
                            overflowX: "hidden",
                            overflowY: "auto",
                            position: "relative",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            height: "calc(100% - 60px)",
                        }}
                        className="hideScrollbars"
                    >
                        {templateData && (
                            <Preview
                                page={templateData}
                                PreviewBlockId={previewBlockId}
                                isEditPage={false}
                            />
                        )}
                    </div>
                </>
            )}
        </Box>
    </div>
)

export default SiteWizardPreview;
