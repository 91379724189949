import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, isChildrenPositionBefore, propertyHasValue } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from '../MisconfiguredBlock';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function isConfiguredProperly(block: BlockMapping, childrenHaveContentToRender?: boolean) {
    if (!propertyHasValue(block.Properties, PropertyId.AnchorConfig)) {
        return false;
    }
    
    const config = getPropertyValue(block.Properties, PropertyId.AnchorConfig);
    const configObj = config ? JSON.parse(config) : null;
    const hasValue = configObj?.Value || configObj?.v ? true : false;
    const hasContent = propertyHasValue(block.Properties, PropertyId.Content) || childrenHaveContentToRender;

    return hasValue && hasContent;
};

export function Block(props: BlockProps) {
    if (!isConfiguredProperly(props.block, props.childrenHaveContentToRender)) {
        return <></>;
    }

    const config = getPropertyValue(props.block.Properties, PropertyId.AnchorConfig);
    const configObj = config ? JSON.parse(config) : null;
    const content = getPropertyValue(props.block.Properties, PropertyId.Content);
    const displayType = getPropertyValue(props.block.Properties, PropertyId.DisplayType);
    const displayClass = displayType === "Button" ? "btn btn-primary" : "";
    const value = configObj?.Value || configObj?.v;

    return (
        <>
            {value &&
                <a {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: displayClass, blockWrapperData: props.blockWrapperData})}>
                    {isChildrenPositionBefore(props.block) && props.children}
                    {content}
                    {!isChildrenPositionBefore(props.block) && props.children}
                </a>
            }
        </>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender?: boolean) {
    return isConfiguredProperly(block, childrenHaveContentToRender);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    const content = getPropertyValue(block.Properties, PropertyId.Content);

    return content ? <>{content}</> : DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    const content = getPropertyValue(block.Properties, PropertyId.Content);

    return (!!content && (content.toLowerCase().includes(filterText)))
        || defaultFilter(block, filterText);
}