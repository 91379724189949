import { useCallback, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiCall, httpMethods } from '../utils/apiHelpers';
import { UserContext } from '../UserContext';

export default function useApiPutCallbackAsync(uri: string, initialData?: any) {
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const { currentUser, sessionId, correlationId } = useContext(UserContext);

    const callback = useCallback((
        requestBody: Record<string, any>,
        successCallback: Function,
        failureCallback: Function,
        overrideUri?: string
    ) => {
        setIsError(false);
        setIsLoading(true);
        setErrorResponse(null);

        const postUri = overrideUri ?? uri;

        const fetchData = async () => {

            try {
                const token = await getAccessTokenSilently();

                const identityHeaders = {
                    token: token,
                    user: currentUser?.name || '',
                    sessionId: sessionId,
                    correlationId: correlationId
                };

                const data = await makeApiCall(postUri, httpMethods.put, identityHeaders, requestBody);

                setData(data);
                if (successCallback) {
                    successCallback();
                }
            } catch (error: any) {
                console.log(error);
                setIsError(true);
                setErrorResponse(error);
                if (failureCallback) {
                    failureCallback(error);
                }
            }

            setIsLoading(false);
        };

        if (postUri) {
            fetchData();
        }
    }, [uri, getAccessTokenSilently, currentUser, sessionId, correlationId]);

    return [{ data, isLoading, isError, errorResponse }, callback];
}
