import SiteEditorStore from './SiteEditorStore';
import ErrorMessage from '../common/ErrorMessage';

export default function SiteEditorError() {
    const errorMessage = SiteEditorStore.useStoreState((state) => state.errorMessage);
    const updateErrorMessage = SiteEditorStore.useStoreActions((actions) => actions.updateErrorMessage);

    if (!errorMessage) {
      return null;
    }

    return <ErrorMessage message={errorMessage} action={() => { updateErrorMessage(null) }} actionName="Dismiss" visible={true} duration={3000} />;
}