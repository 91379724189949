import { CSSProperties, useRef } from 'react';
import BlockWrapper from './BlockWrapper';
import { useDrop } from 'react-dnd';
import Page from '../../types/Page';
import BuilderProps from '../../types/BuilderProps';
import HoverData from '../../types/HoverData';

export interface RendererProps {
    page: Page;
    builderProps: BuilderProps;
    renderData: any;
    setRenderData: React.Dispatch<React.SetStateAction<{}>>;
}

export default function Renderer({ page, builderProps, renderData, setRenderData }: RendererProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: 'BlockMapping',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            if (monitor.isOver({ shallow: true })) {
                const rect = ref.current?.getBoundingClientRect();
                const mouse = monitor.getClientOffset();
                const hoverData: HoverData = {
                    rect,
                    mouse,
                    isBefore: false,
                    isAfter: false,
                    isInto: true,
                    hoveredId: null,
                    blockMappingId: item.blockMappingId,
                    templateId: item.templateId,
                    blockId: item.blockId,
                    hoveredParentIndex: null,
                    componentId: item.ComponentId
                };
                builderProps?.dragDrop?.hovering(hoverData);
            }
        },
        drop(item, monitor) {
            builderProps?.dragDrop?.finished(builderProps.dragDrop.hoverData);
        }
    });

    drop(ref);

    let dragPreviewStyle: CSSProperties = {};

    if (builderProps?.dragDrop?.hoverData && (builderProps?.dragDrop?.hoverData.type !== 'HierarchyListItem')) {
        dragPreviewStyle = {
            position: 'absolute',
            pointerEvents: 'none',
            height: builderProps.dragDrop?.hoverData.rect.height,
            top: builderProps.dragDrop?.hoverData.rect.top  - 48 + (builderProps.scrollOffset || 0),
            left: builderProps.dragDrop?.hoverData.rect.left - 75,
            width: builderProps.dragDrop?.hoverData.rect.width,
            zIndex: 99,
        };

        if (builderProps.dragDrop?.hoverData.isAfter || builderProps.dragDrop?.hoverData.isBefore) {
            dragPreviewStyle.height = "10px"
            dragPreviewStyle.backgroundColor = "blue";
            dragPreviewStyle.borderRadius = "25px";
        }

        if (builderProps.dragDrop?.hoverData.isAfter) {
            dragPreviewStyle.top = builderProps.dragDrop?.hoverData.rect.bottom - 58 + (builderProps.scrollOffset || 0);
        }

        if (builderProps.dragDrop?.hoverData.isInto) {
            dragPreviewStyle.border = "10px solid blue";
        }
    }

    return (
        <>
            { builderProps?.dragDrop?.hoverData && 
                <span style={dragPreviewStyle} ></span>
            }
            <div ref={ref} style={{ position:"relative" }}>
            {
                page?.BlockMappings?.map((b, index) => 
                    <BlockWrapper 
                        key={`${b.Id}-${index}`} 
                        page={page} 
                        blockmapping={b}
                        builderProps={builderProps}
                        renderData={renderData}
                        setRenderData={setRenderData}
                    />
                )
            }
            </div>
        </>
    );
}
