import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import { Typography } from '@mui/material';

interface DisabledLabelProps {
    label?: string,
    isSelected?: boolean,
    selectedColor?: string
  }
  
  export default function DisabledLabel({label, isSelected, selectedColor}: DisabledLabelProps) {
    if (!label) {
        label = "Disabled"
    }
    if (!selectedColor) {
        selectedColor = "#CF1F2E"
    }

    return (
        <Typography color={isSelected ? selectedColor : '#CF1F2E'} style={{fontSize: '10px', textTransform: 'uppercase', lineHeight: 1, letterSpacing: 1.5, fontWeight: 500}}>
            <ChangeHistoryTwoToneIcon sx={{height: '9px', width: 'initial', verticalAlign: 'bottom', paddingRight: 0.5}}/>
            {label}
        </Typography>
    );
}