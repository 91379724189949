import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';

export interface LoadingProps {
    message?: string | null,
    cancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}
export default function Loading({message = null, cancel}: LoadingProps) {
    return (
        <Backdrop data-testid="loading-indicator" sx={{ color: '#fff', zIndex: 9999999}} open={true}>
            {message && <div className="headline4" style={{position: 'absolute', top: 'calc(50% - 125px)'}}>{message}</div>}
            <CircularProgress style={{ color: '#FFFFFF' }} size={128} />
            {cancel && 
                <div style={{position: 'absolute', top: 'calc(50% + 125px)'}}>
                    <Button onClick={cancel} variant="contained">Cancel</Button>
                </div>
            }
        </Backdrop>
    );
}