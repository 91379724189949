import { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import NoResultsFound from './NoResultsFound';

export interface SearchFilterInputProps {
    id: string, 
    placeholder: string, 
    value: string, 
    onChange: (newValue: string, originalCase: string) => void, 
    noResults?: boolean, 
    inputMaxWidth?: number | null
}

export default function SearchFilterInput({ id, placeholder, value, onChange, noResults, inputMaxWidth }: SearchFilterInputProps) {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        let timeOutId: NodeJS.Timer = setTimeout(() => {
            onChange(inputValue.toLowerCase(), inputValue);
        }, 200);
        return () => timeOutId && clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        if (inputValue.toLowerCase() !== value?.toLowerCase()) {
            setInputValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
        <div style={{ maxWidth: inputMaxWidth ?? undefined }}>
            <TextField 
                data-testid="search-filter-input"
                id={id} 
                onChange={(e) => { setInputValue(e.target.value) }}
                value={inputValue}
                placeholder={placeholder}
                fullWidth
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon sx={{color: "black"}} />
                    </InputAdornment>
                    ),
                    endAdornment: !value ? null : (
                    <InputAdornment position="end">
                        <ClearIcon sx={{ cursor: "pointer", '&:hover': { color: "secondary.main" } }} onClick={() => setInputValue('') } />
                    </InputAdornment>
                    )
                }}
            />
        </div>
        <NoResultsFound display={!!noResults} />
        </>
    );
}