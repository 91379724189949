import { useState } from "react";
import SiteStatus from '../../types/enum/SiteStatus';
import { Box, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SiteEditorStore from "./SiteEditorStore";
import { useNavigate } from "react-router-dom";

function SiteEditorPendingDialog() {
    const [provisionModalOpen, setProvisionModalOpen] = useState(true);
    const site = SiteEditorStore.useStoreState((state) => state.site);
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();

    if (!site || site.StatusTypeId !== SiteStatus.PendingProvisioning) {
        return null;
    }

    const openAdminPage = () => {
        if (site) {
            navigate(`/site/${site.Id}/admin`);
        }
    }

    const loadWizard = () => {
        if (site) {
            navigate(`/site/${site.Id}/wizard`);
        }
    };

    return (
        <Dialog
            fullScreen={false}
            open={provisionModalOpen}
            onClose={() => {}}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <div className="heading6" style={{ textAlign: 'left', width: '100%' }}>Let's start your new website</div>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ minWidth: isMobileView ? 0 : 350 }}>
                    <Box sx={{ textAlign: "center", marginBottom: "16px", marginTop: "16px" }}>
                        <Button onClick={loadWizard} sx={{ width: "230px" }} variant="contained">Start Website Wizard</Button>
                    </Box>
                    <Box sx={{ textAlign: "center", marginBottom: "64px" }}>
                        <Button onClick={openAdminPage} sx={{ width: "230px" }} variant="outlined" startIcon={<SettingsIcon />}>Administrative Config</Button>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                        <Button onClick={() => setProvisionModalOpen(false)} variant="text">Close</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default SiteEditorPendingDialog;