import BlockMapping from '../types/BlockMapping';
import NormalizedBlockMapping from '../types/NormalizedBlockMapping';
import NormalizedPage from '../types/NormalizedPage';
import NormalizedProperty from '../types/NormalizedProperty';
import NormalizedPropertyOverride from '../types/NormalizedPropertyOverride';
import Page from '../types/Page';
import Property from '../types/Property';

export function convertPageToNormalizedPage(page: Page): NormalizedPage {

  const addNormalizedPropertyOverrides = (
    normalizedProperties: { [key: string]: NormalizedProperty },
    normalizedEnumProperties: { [key: number]: NormalizedProperty },
    linkedEnumProperties: number[],
    linkedProperties: string[],
    overrides: NormalizedPropertyOverride[],
    properties?: Property[]
  ) => {
    if (properties)
    {
        for (const property of properties)
        {
            // If we don't have an enum id (custom/user create properties)
            // then add this to the list of enum properties, else add it to the
            // list of properies by enum ID. Remove corresponding property ID or enum ID
            // from the added objects so we don't duplicate the key in the object
            if (!property.EnumId && !normalizedProperties[property.PropertyId])
            {
                normalizedProperties[property.PropertyId] =
                {
                    ct: property.CategoryTypeId,
                    c: property.Configuration,
                    e: property.EnumId,
                    n: property.Name,
                    o: property.PropertyOrder,
                    r: property.Required,
                    v: property.ValueTypeId
                };
            }
            else if (property.EnumId && !normalizedEnumProperties[property.EnumId])
            {
                normalizedEnumProperties[property.EnumId] =
                {
                    i: property.PropertyId,
                    ct: property.CategoryTypeId,
                    c: property.Configuration,
                    n: property.Name,
                    o: property.PropertyOrder,
                    r: property.Required,
                    v: property.ValueTypeId
                };
            }

            // If we have an override (value or rendered value), add a property override
            // object, otherwise link the property to the block/page by either enum ID
            // for system generated properties, or property ID for custom/user created
            // properties
            if (property.Value || property.RenderedValue)
            {
                overrides.push(
                {
                    p: !property.EnumId ? property.PropertyId : undefined,
                    e: property.EnumId ?? undefined,
                    r: property.RenderedValue ?? undefined,
                    v: property.Value ?? undefined,
                    u: property.ValueUpdatedInWizard
                });
            }
            else if (property.EnumId && linkedEnumProperties)
            {
                linkedEnumProperties.push(property.EnumId);
            }
            else if (linkedProperties)
            {
                linkedProperties.push(property.PropertyId);
            }
        }
    }
  };

  const addNormalizedBlockMappings = (
    normalizedProperties: { [key: string]: NormalizedProperty },
    normalizedEnumProperties: { [key: number]: NormalizedProperty },
    blockMappings: NormalizedBlockMapping[],
    blockMapping: BlockMapping,
    componentNames: { [key: string]: string }
    ) => {
      if (blockMapping)
      {
          const normalizedBlockMapping =
          {
              i: blockMapping.Id,
              ei: blockMapping.EnumId,
              b: blockMapping.BlockId,
              st: blockMapping.StatusTypeId,
              s: blockMapping.Style,
              w: blockMapping.WizardLabel,
              d: !!blockMapping.Duplicate,
              bc: blockMapping.BlockContentId,
              o: [],
              e: [],
              p: [],
              bm: [],
              ci: blockMapping.ComponentId,
              cbi: blockMapping.ComponentBlockMappingId
          } as NormalizedBlockMapping;

          if (blockMapping.ComponentId && blockMapping.ComponentName && componentNames
            && !Object.keys(componentNames).includes(blockMapping.ComponentId)) {
            componentNames[blockMapping.ComponentId] = blockMapping.ComponentName
          }

          addNormalizedPropertyOverrides(normalizedProperties, normalizedEnumProperties
              , normalizedBlockMapping.e!, normalizedBlockMapping.p!, normalizedBlockMapping.o!,
              blockMapping.Properties);

          if (blockMapping.BlockMappings)
          {
              for (const child of blockMapping.BlockMappings)
              {
                  addNormalizedBlockMappings(normalizedProperties, normalizedEnumProperties
                      , normalizedBlockMapping.bm!, child, componentNames);
              }
          }

          if (!normalizedBlockMapping.o?.length)
          {
              normalizedBlockMapping.o = null;
          }
          if (!normalizedBlockMapping.e?.length)
          {
              normalizedBlockMapping.e = null;
          }
          if (!normalizedBlockMapping.p?.length)
          {
              normalizedBlockMapping.p = null;
          }
          if (!normalizedBlockMapping.bm?.length)
          {
              normalizedBlockMapping.bm = null;
          }

          blockMappings.push(normalizedBlockMapping);
      }
  };

  const nullOutEmptyLists = (normalizedPage: NormalizedPage) => {
    if (!normalizedPage.f?.length)
    {
        normalizedPage.f = null;
    }
    if (!normalizedPage.bc?.length)
    {
        normalizedPage.bc = null;
    }
    if (!normalizedPage.rs?.length)
    {
        normalizedPage.rs = null;
    }
    if ( !Object.keys(normalizedPage.p ?? {}).length)
    {
        normalizedPage.p = null;
    }
    if ( !Object.keys(normalizedPage.ep ?? {}).length)
    {
        normalizedPage.ep = null;
    }
    if (!normalizedPage.se?.length)
    {
        normalizedPage.se = null;
    }
    if (!normalizedPage.sp?.length)
    {
        normalizedPage.sp = null;
    }
    if (!normalizedPage.so?.length)
    {
        normalizedPage.so = null;
    }
    if (!normalizedPage.pe?.length)
    {
        normalizedPage.pe = null;
    }
    if (!normalizedPage.pp?.length)
    {
        normalizedPage.pp = null;
    }
    if (!normalizedPage.po?.length)
    {
        normalizedPage.po = null;
    }
    if (!normalizedPage.bm?.length)
    {
        normalizedPage.bm = null;
    }
    if (!Object.keys(normalizedPage.c ?? {}).length)
    {
        normalizedPage.c = null;
    }
  };

  let normalizedPage = {
      si: page.SiteId,
      pi: page.PageId,
      u: page.Url,
      d: page.Domain,
      r: page.Route,
      ssu: page.StyleSheetUrl,
      au: page.AssetsUrl,
      ssi: page.SiteStyleId,
      f: page.Fonts,
      rs: page.ReviewSummaries,
      bc: page.BlockContent,
      p: {},
      ep: {},
      so: [],
      se: [],
      sp: [],
      po: [],
      pe: [],
      pp: [],
      bm: [],
      c: {}
  };

  // add Site Props
  addNormalizedPropertyOverrides(
    normalizedPage.p,
    normalizedPage.ep,
    normalizedPage.se,
    normalizedPage.sp,
    normalizedPage.so,
    page.SiteProperties
  );

  // add Page Props
  addNormalizedPropertyOverrides(
    normalizedPage.p,
    normalizedPage.ep,
    normalizedPage.pe,
    normalizedPage.pp,
    normalizedPage.po,
    page.PageProperties
  );

  // add block mappings and block props
  if (page.BlockMappings != null)
  {
      for(const blockMapping of page.BlockMappings)
      {
          addNormalizedBlockMappings(normalizedPage.p, normalizedPage.ep
              , normalizedPage.bm, blockMapping, normalizedPage.c);
      }
  }

  // Null out any empty lists/dictionaries
  nullOutEmptyLists(normalizedPage);

  return normalizedPage;
}

// This will take our condensed down NormalizedPage DTO and convert it back into a Page DTO
export function convertNormalizedPageToPage(normalizedPage: NormalizedPage): Page {
  const createProperty = (
    property: NormalizedProperty,
    override: NormalizedPropertyOverride | null,
    enumId?: number | null,
    propertyId?: string | null,
    blockMappingId?: string | null,
    pageId: string | null = null
  ): Property => {
    return {
      Value: override?.v ?? null,
      RenderedValue: override?.r ?? null,
      ValueTypeId: property.v,
      Configuration: property.c ?? null,
      EnumId: property.e ?? (enumId ?? undefined),
      BlockMappingId: blockMappingId ?? null,
      ValueUpdatedInWizard: override?.u ?? false,
      PropertyId: property.i ?? (propertyId ?? ""),
      CategoryTypeId: property.ct ?? undefined,
      SiteId: normalizedPage.si,
      PageId: pageId,
      Name: property.n ?? "",
      Required: property.r ?? false,
      PropertyOrder: property.o,
      Shared: false // Currently hardcoding as normalized properties don't contain this field
    }
  };

  const createPropertiesFromOverrides = (
    list: Property[],
    overrides?: NormalizedPropertyOverride[] | null,
    blockMappingId: string | null = null,
    pageId: string | null = null
  ) => {
    if (overrides) {
      for (const normalizedOverride of overrides) {
        let property = null;
        let enumId = null;
        let propertyId = null;
        if (normalizedOverride.e && normalizedPage.ep) {
          property = normalizedPage.ep[normalizedOverride.e];
          enumId = normalizedOverride.e;

          if (property) {
            propertyId = property.i;
          }
        }
        else if (normalizedOverride.p && normalizedPage.p) {
          property = normalizedPage.p[normalizedOverride.p];
          if (property) {
            enumId = property.e;
          }
          propertyId = normalizedOverride.p;
        }

        if (property) {
          list.push(createProperty(property, normalizedOverride, enumId, propertyId, blockMappingId, pageId));
        }
      }
    }
  };

  const createPropertiesFromLinkedEnumProperties = (
    list: Property[],
    linkedProperties?: number[] | null,
    blockMappingId: string | null = null,
    pageId: string | null = null
  ) => {
    if (linkedProperties && normalizedPage.ep) {
      for (const enumId of linkedProperties) {
        list.push(createProperty(normalizedPage.ep[enumId], null, enumId, null, blockMappingId, pageId));
      }
    }
  };

  const createPropertiesFromLinkedProperties = (
    list: Property[],
    linkedProperties?: string[] | null,
    blockMappingId: string | null = null,
    pageId: string | null = null
  ) => {
    if (linkedProperties && normalizedPage.p) {
      for (const propertyId of linkedProperties) {
        list.push(createProperty(normalizedPage.p[propertyId], null, null, propertyId, blockMappingId, pageId));
      }
    }
  };

  const resortProperties = (properties: Property[]) => {
    properties.sort((a,b) => (a.PropertyOrder || 0) - (b.PropertyOrder || 0));
  };

  const createBlockMappings = (
    list: BlockMapping[],
    mappings?: NormalizedBlockMapping[] | null,
    parentId?: string | null,
    componentNames?: { [key: string]: string } | null
  ) => {
    if (mappings) {
      for (const mapping of mappings) {
        const blockProperties: Property[] = [];
        const blockMappings: BlockMapping[] = [];

        createPropertiesFromOverrides(blockProperties, mapping.o, mapping.i, normalizedPage.pi);
        createPropertiesFromLinkedEnumProperties(blockProperties, mapping.e, mapping.i, normalizedPage.pi);
        createPropertiesFromLinkedProperties(blockProperties, mapping.p, mapping.i, normalizedPage.pi);
        resortProperties(blockProperties);
        createBlockMappings(blockMappings, mapping.bm, mapping.i, componentNames);

        let componentName;

        if (mapping.ci && componentNames && Object.keys(componentNames).includes(mapping.ci)) {
          componentName = componentNames[mapping.ci];
        }

        list.push({
          Id: mapping.i,
          ParentId: parentId,
          EnumId: mapping.ei,
          BlockId: mapping.b,
          Properties: blockProperties,
          WizardLabel: mapping.w,
          StatusTypeId: mapping.st,
          Duplicate: mapping.d ?? false,
          BlockMappings: blockMappings,
          BlockContentId: mapping.bc,
          Style: mapping.s,
          ComponentId: mapping.ci,
          ComponentBlockMappingId: mapping.cbi,
          ComponentName: componentName
        });
      }
    }
  };

  const siteProperties: Property[] = [];
  const pageProperties: Property[] = [];
  const blockMappings: BlockMapping[] = [];

  // Create site properties from so (SitePropertyOverrides), se (LinkedEnumSiteProperties), and sp (LinkedSiteProperties)
  createPropertiesFromOverrides(siteProperties, normalizedPage.so);
  createPropertiesFromLinkedEnumProperties(siteProperties, normalizedPage.se);
  createPropertiesFromLinkedProperties(siteProperties, normalizedPage.sp);
  resortProperties(siteProperties);

  // Create page properties from po (PagePropertyOverrides), pe (LinkedEnumPageProperties), and pp (LinkedPageProperties)
  createPropertiesFromOverrides(pageProperties, normalizedPage.po, null, normalizedPage.pi);
  createPropertiesFromLinkedEnumProperties(pageProperties, normalizedPage.pe, null, normalizedPage.pi);
  createPropertiesFromLinkedProperties(pageProperties, normalizedPage.pp, null, normalizedPage.pi);
  resortProperties(pageProperties);

  // Create block mappings
  createBlockMappings(blockMappings, normalizedPage.bm, null, normalizedPage.c);

  return {
    SiteId: normalizedPage.si,
    PageId: normalizedPage.pi,
    Url: normalizedPage.u,
    Domain: normalizedPage.d,
    Route: normalizedPage.r,
    SiteStyleId: normalizedPage.ssi,
    StyleSheetUrl: normalizedPage.ssu,
    AssetsUrl: normalizedPage.au,
    Fonts: normalizedPage.f ?? [],
    SiteProperties: siteProperties,
    PageProperties: pageProperties,
    BlockMappings: blockMappings,
    ReviewSummaries: normalizedPage.rs ?? [],
    BlockContent: normalizedPage.bc ?? []
  };
}