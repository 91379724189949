import NavigationItemValue from "../types/NavigationItemValue";
import PageInfo from "../types/PageInfo";
import PageStatus from "../types/enum/PageStatus";

export function hasValidItems(navItems: NavigationItemValue[], pageList: PageInfo[]) {
    // Item is not a folder and has an icon or label and a destination
    return navItems?.filter(
        (navItem: NavigationItemValue) =>                   // Nav item...
            navItem.d !== "f" &&                            // is not a folder
            (navItem.i || navItem.l) &&                     // AND has an icon or label
            (navItem.v ||                                   // AND has a either a value
                (navItem.p && pageList?.find(
                    (page: PageInfo) => 
                        page.Id === navItem.p
                )?.StatusTypeId === PageStatus.Active)      //  OR an active page ID
            )
    ).length > 0;
};
