import { Box, Button, Dialog, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Editor as FontAwesomeIconProperty } from './FontAwesomeIconProperty';
import { Editor as AnchorConfigProperty } from './AnchorConfigProperty';
import FormValidationError from '../common/FormValidationError';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Property from "../../types/Property";
import { useEffect, useState } from "react";
import Page from "../../types/Page";
import PageInfo from "../../types/PageInfo";

export interface AddOrEditDialogProps {
    addOrEditDialogOpen: boolean,
    cancelDialog: () => void,
    editMode: boolean,
    iconProperty: Property,
    setIconProperty: React.Dispatch<React.SetStateAction<Property>>,
    labelValue: string | undefined,
    setLabelValue: React.Dispatch<React.SetStateAction<string | undefined>>,
    appearanceValue: string,
    setAppearanceValue: React.Dispatch<React.SetStateAction<string>>,
    page?: Page | null,
    anchorProperty: Property,
    setAnchorProperty: React.Dispatch<React.SetStateAction<Property>>,
    behaviorValue: string,
    setBehaviorValue: React.Dispatch<React.SetStateAction<string>>,
    saveClicked: () => void,
    pageList: PageInfo[]
}

export default function AddOrEditDialog({
    addOrEditDialogOpen,
    cancelDialog,
    editMode,
    iconProperty,
    setIconProperty,
    labelValue,
    setLabelValue,
    appearanceValue,
    setAppearanceValue,
    page,
    anchorProperty,
    setAnchorProperty,
    behaviorValue,
    setBehaviorValue,
    saveClicked,
    pageList
}: AddOrEditDialogProps) {

    const [iconOrLabelValid, setIconOrLabelValid] = useState(true);
    const [anchorIsValid, setAnchorIsValid] = useState(true);
    const [showBehavior, setShowBehavior] = useState(true);

    useEffect(() => {
        if(addOrEditDialogOpen) {
            setIconOrLabelValid(true);
        }
    }, [addOrEditDialogOpen])

    const checkValid = () => {
        const valid1 = !!iconProperty.Value || !!labelValue;
        setIconOrLabelValid(valid1);

        return valid1 && (appearanceValue === 'f' || anchorIsValid);
    }

    return (<Dialog
                open={addOrEditDialogOpen}
                onClose={cancelDialog}
                data-testid="add-or-edit-dialog"
                aria-labelledby="responsive-dialog-title"
                maxWidth='lg'
                fullWidth={true}
                PaperProps={{
                    style: {
                        borderRadius: '4px',
                        marginTop: '96px',
                        maxHeight: 'calc(100% - 96px)',
                        width: '638px',
                        padding: '16px'
                    }
                }}
            >
                <div style={{display:'flex', flexFlow:'row nowrap'}}>
                    <div style={{
                        display: 'flex',
                        flexFlow: 'column wrap',
                        width:'100%',
                        justifyContent:'space-between' }}
                    >
                        <h2 style={{textAlign:'center'}}>{editMode ? 'Edit Navigation Entry' : 'Add Navigation Entry' }</h2>

                        <label style={{ marginBottom: '12px' }}>Icon</label>
                        <FontAwesomeIconProperty
                            property={iconProperty}
                            propertyUpdated={(p: Property) => {
                                setIconOrLabelValid((!!p.Value || !!labelValue));
                                setIconProperty(p)
                            }}
                            isPageEditor={false}
                            pageList={pageList}
                        />

                        <label style={{ marginTop: '32px', marginBottom: '12px' }}>Label</label>
                        <TextField
                            placeholder="Enter a label"
                            value={labelValue}
                            onChange={(e: any) => {
                                setIconOrLabelValid((!!iconProperty.Value || !!e?.target?.value));
                                setLabelValue(e?.target?.value);
                             }}
                        ></TextField>

                        <FormValidationError valid={iconOrLabelValid} message="Icon or label is required." />

                        <label style={{ marginTop: '32px', marginBottom: '12px' }}>Appearance</label>
                        <RadioGroup
                            row
                            aria-labelledby="appearance-radio-buttons-group-label"
                            value={appearanceValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setAppearanceValue(e.target.value);
                            }}
                            name="radio-buttons-group"
                            >
                                <FormControlLabel
                                    value={'l'}
                                    control={<Radio sx={{ paddingTop: "12px"}} color="secondary" />}
                                    label={<Box sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        marginBottom: "16px",
                                        marginTop: "14px",
                                        marginRight: "82px"
                                    }}><strong>LINK</strong></Box>}
                                    sx={{ alignItems: "start" }}
                                />
                                <FormControlLabel
                                    value={'b'}
                                    control={<Radio sx={{ paddingTop: "12px"}} color="secondary" />}
                                    label={<Box sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        marginBottom: "16px",
                                        marginTop: "0px",
                                        marginRight: "82px"
                                    }}><strong>
                                        <div
                                            style={{
                                                backgroundColor:"#E1BB80",
                                                borderRadius:"8px",
                                                padding:"14px 18px"
                                            }}
                                        >BUTTON</div></strong></Box>}
                                    sx={{ alignItems: "start" }}
                                />
                                <FormControlLabel
                                    value={'f'}
                                    control={<Radio sx={{ paddingTop: "12px"}} color="secondary" />}
                                    label={<Box sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        marginBottom: "16px",
                                        marginTop: "14px",
                                        marginRight: "82px",
                                        display: "flex"
                                    }}><strong style={{marginRight: "4px" }}>FOLDER</strong><ExpandMoreIcon sx={{width: '20px', height: '20px'}}/></Box>}
                                    sx={{ alignItems: "start" }}
                                />
                            </RadioGroup>

                        { appearanceValue !== 'f' &&
                        <>
                            <div style={{ marginTop: '32px'}}>
                                <AnchorConfigProperty
                                    property={anchorProperty}
                                    propertyUpdated={setAnchorProperty}
                                    isPageEditor={true}
                                    page={page}
                                    validationChanged={(isValid) => setAnchorIsValid(isValid)}
                                    setShowBehavior={setShowBehavior}
                                    pageList={pageList}
                                />
                            </div>

                            { showBehavior &&
                                <>
                                    <label
                                        style={{ marginTop: '32px', marginBottom: '12px' }}
                                        data-testid="BehaviorSelectLabel">
                                        Behavior
                                    </label>
                                    <Select
                                        displayEmpty={true}
                                        value={behaviorValue}
                                        onChange={(e) => setBehaviorValue(e.target.value)}
                                        data-testid="BehaviorSelect"
                                    >
                                        <MenuItem value={""}>Open in same tab</MenuItem>
                                        <MenuItem value={"n"}>Open in new tab</MenuItem>
                                    </Select>
                                </>
                            }
                        </>
                        }
                        <div style={{ marginTop: '32px'}}>
                            <Button variant="text" color="secondary"
                                onClick={() => cancelDialog()}>
                                Cancel
                            </Button>
                            <Button variant={'outlined'} color={'secondary'} sx={{ float: "right"}}
                                onClick={() => {
                                    if (checkValid()) {
                                        saveClicked();
                                    }
                                }}
                                autoFocus>
                                {editMode ? 'UPDATE' : 'CREATE' }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>);
}