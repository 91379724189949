import { Button, DialogActions, Stack } from "@mui/material";
//import { useRef } from "react";
import FileRecord from '../../types/FileRecord';
import { getFileSizeDisplay } from '../../utils/fileHelpers';
//import FileUpdater from "./FileUpdater";

export interface FileInfoPanelProps { 
    infoFile?: FileRecord | null,
    siteId?: string,
    isMobileView: boolean,
    clearSelection: () => void,
    saveSelection: () => void,
    updateImages: () => void,
    deleteFile: (props: any) => void, 
    setError?: (props: any) => void,
    hideControls: boolean,
    name?: string
}

export default function FileInfoPanel({infoFile, siteId, isMobileView, clearSelection, saveSelection, updateImages, deleteFile, setError, hideControls, name}: FileInfoPanelProps ) {
    const filename = name ?? `${infoFile?.FileName ?? ""}${infoFile?.FileExtension ?? ""}`;
    const imageUrl = infoFile?.FileTypeId === 1301 ? infoFile?.URL : infoFile?.PreviewURL;

    //const updaterInput = useRef(null);
    
    /*
    const browseFiles = () => {
        setError(null);
        updaterInput.current.click();
    }
    */

    // TODO: waiting for implementation of API endpoint to Get File
    /*const editImage = (fileId: string) => {
        console.log('editingImage', fileId);
    }

    const replaceFile = (fileId: string) => {
        browseFiles();
    }*/


    return (
        <>
            {infoFile &&
            <>
                <div 
                    style={{ 
                        width: isMobileView ? '100%' : '332px', 
                        minWidth: isMobileView ? 'unset' : '332px', 
                        height: isMobileView ? '100vh' : 'unset',
                        display: 'flex', 
                        flexFlow: 'column nowrap', 
                        backgroundColor: isMobileView ? 'unset' : '#2121210A', 
                        justifyContent:'space-between', 
                        borderRadius: isMobileView ? 'unset' : '20px 10px 10px 20px',
                        overflowY: 'auto'
                    }}
                >
                    <div style={{
                            padding:'16px', 
                            textAlign: 'center'
                        }}
                    >
                        <div style={{display:'flex', alignItems:'center', height:'200px'}}>
                            <div style={{display:'inline-block', borderRadius:'10px', margin:'0 auto', overflow:'hidden' }}>
                                <img src={imageUrl} alt={infoFile.FileName} style={{maxWidth:'200px', maxHeight:'200px', display:'block' }} />
                            </div>
                        </div>
                        <h1 className="headline5" style={{marginTop:'32px', marginBottom:'24px', wordBreak: 'break-word',
                        textAlign: 'center',
                        lineHeight: '1.43em'}}>
                            {filename}
                        </h1>
                        <Stack spacing={2} sx={{textAlign:'left'}}>
                            <div>
                                <label className="caption">File Size</label>
                                <div className="body2">{getFileSizeDisplay(infoFile.FileSize)}</div>
                            </div>
                            { !!infoFile.ImageWidth && !!infoFile.ImageHeight &&
                                <div>
                                    <label className="caption">Resolution</label>
                                    <div className="body2">{`${infoFile.ImageWidth}x${infoFile.ImageHeight}`}</div>
                                </div>
                            }
                        </Stack>
                    </div>
                    { !hideControls && 
                        <div style={{padding:'16px'}}>
                            <Stack spacing={1}>
                                {/* <Button sx={{ color: '#292929', border: '1px solid rgba(0,0,0,0.12)' }} 
                                    onClick={() => editImage(infoFile.Id)} autoFocus variant="outlined">
                                    EDIT IMAGE
                                </Button> */}
                                {/* <Button sx={{ color: '#292929', border: '1px solid rgba(0,0,0,0.12)' }} 
                                    onClick={() => replaceFile(infoFile.Id)} autoFocus variant="outlined">
                                    REPLACE FILE
                                </Button> */}
                                <Button sx={{ color: '#CF1F2E', border: '1px solid rgba(0,0,0,0.12)' }} 
                                    onClick={() => deleteFile(infoFile)} autoFocus variant="outlined">
                                    DELETE FILE
                                </Button> 
                            </Stack>
                            {/* <FileUpdater
                                fileInput={updaterInput}
                                siteId={siteId}
                                fileId={infoFile?.Id}
                                setError={setError}
                                updateFinished={updateImages}
                            /> */}
                        </div>
                    }
                </div>
                {isMobileView && 
                    <DialogActions 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: "space-between", 
                            borderTop: 1, 
                            borderColor: 'divider',
                            p: 2 
                        }}
                    >
                        <Button 
                            sx={{ width: '100%' }} 
                            autoFocus 
                            onClick={clearSelection}
                            variant="outlined"
                            color="primary" 
                        >
                            Cancel
                        </Button>
                        <Button 
                            sx={{ width: '100%' }} 
                            disabled={!infoFile} 
                            color="primary" 
                            onClick={saveSelection} 
                            autoFocus 
                            variant="contained"
                        >
                            Select File
                        </Button>
                    </DialogActions>
                }
            </>}
        </>
    );
}