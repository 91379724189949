import { FormControl, FormControlLabel, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import Property from "../../types/Property";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import { PropertyNameLabel } from "../common/PropertyNameLabel";

export function Editor({property, propertyUpdated}: PropertyEditorProps) {
    const updateValue = (event: SelectChangeEvent) => {
        if (property) {
            property.Value = event.target.value;
            property.RenderedValue = event.target.value;
            propertyUpdated(property);
        }
    };

    const configObj = property?.Configuration ? JSON.parse(property?.Configuration) : {};
    const options: [string,string][] = configObj.KeyValuePairs ? Object.entries(configObj.KeyValuePairs) : [];
    const radioGroupTestId = `${property?.Name.toLowerCase().replace(' ', '-')}-radio-group`;

    return (
        <>
            <PropertyNameLabel
                property={property}
            ></PropertyNameLabel>
            <FormControl fullWidth>
                <RadioGroup
                    data-testid={radioGroupTestId}
                    value={!property?.RenderedValue ? "" : property.RenderedValue.toString()}
                    onChange={updateValue}
                >
                    {options.map(([name, key], index) => (
                        <FormControlLabel
                            key={key}
                            value={key}
                            label={name}
                            control={
                                <Radio data-testid={`${radioGroupTestId}-option-${index + 1}`} color="secondary" />
                            }
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </>
    )
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    const configuration: [string, string][]
        = Object.entries(JSON.parse(property.Configuration || "{}").KeyValuePairs || []);
    let configVal: [string, string] | null = null;

    if (property?.RenderedValue == null) {
        for (const obj of configuration) {
            if (obj[1] === '') {
                configVal = obj;
                break;
            }
        }
    } else {
        for (const obj of configuration) {
            if (obj[1] === property.RenderedValue) {
                configVal = obj;
                break;
            }
        }
    }

    if (configVal != null) {
        return configVal[0].toLowerCase().includes(value);
    } else {
        return false;
    }
}
