import { Button, Divider, Select, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchFilterInput from '../common/SearchFilterInput';
import BuilderDialog from '../common/BuilderDialog';
import useApiPost from '../../hooks/useApiPost';
import ErrorMessage from '../common/ErrorMessage';
import SuccessMessage from "../common/SuccessMessage";
import { useNavigate } from "react-router-dom";
import useApiPutCallbackAsync from "../../hooks/useApiPutCallbackAsync";
import { ResponseError } from "../../utils/apiHelpers";
import FormValidationError from '../common/FormValidationError';
import useApiGet from '../../hooks/useApiGet';
import Type from '../../types/Type';
import { SelectChangeEvent } from '@mui/material/Select';
import useApiPostCallbackAsync from '../../hooks/useApiPostCallbackAsync';
import SharedContentList from './SharedContentList';

export default function CustomBlocks() {
    const [searchFilter, setSearchFilter] = useState('');
    const [page, setPage] = useState(1);
    const [showAddBlockModal, setShowAddBlockModal] = useState(false);
    const [deleteBlockId, setDeleteBlockId] = useState<string | null>(null);
    const [deleteBlockError, setDeleteBlockError] = useState<ResponseError | null>(null);
    const [showDeleteBlockSuccess, setShowDeleteBlockSuccess] = useState(false);
    const [submitBlockError, setSubmitBlockError] = useState<ResponseError | null>(null);
    const [newBlockName, setNewBlockName] = useState('');
    const [blockNameValid, setBlockNameValid] = useState(false);
    const [newBlockFormSubmitted, setNewBlockFormSubmitted] = useState(false);
    const [newBlockDescription, setNewBlockDescription] = useState('');
    const [newBlockCategory, setNewBlockCategory] = useState(210);
    const [searchRequest, setSearchRequest] = useState({
        "Filter": "",
        "StatusTypeIds": [301, 302],
        "EndDate": new Date(),
        "CreatedByUser": null,
        "Page": 1,
        "PageSize": 30
    });
    const navigate = useNavigate();

    const [{ data: blocks, isLoading: blocksLoading, isError: blocksError }]
        = useApiPost("/block/custom/search", null, searchRequest);
    const [, deleteBlockCallback] = useApiPutCallbackAsync("", []) as any;
    const [, submitNewBlockCallback] = useApiPostCallbackAsync("/block/custom", []) as any;
    const [{ data: blockCategories }] = useApiGet("/type/200", []);

    const refreshSearch = () => {
        setSearchRequest(r => {
            return {
              ...r,
              Page: 1,
              EndDate: new Date()
            }
        });
    };

    const submitDeleteBlock = () => {
        setShowDeleteBlockSuccess(false);
        if (deleteBlockId) {
            setPage(1);
            deleteBlockCallback({
                }, (res: string) => {
                    setShowDeleteBlockSuccess(true);
                    setDeleteBlockId(null);
                    refreshSearch();
                }, (err: ResponseError) => {
                    setDeleteBlockError(err);
                },
                `/block/custom/${deleteBlockId}/status/303`
            );
        }
    }

    const addNewBlock = () => {
        setNewBlockName('');
        setNewBlockDescription('');
        setNewBlockCategory(210);
        setSubmitBlockError(null);
        setNewBlockFormSubmitted(false);
        setBlockNameValid(false);
        setShowAddBlockModal(true);
    };

    const updateBlockName = (name: string) => {
        setNewBlockName(name);
        setBlockNameValid(!!name);
    };

    const submitNewBlock = () => {
        setNewBlockFormSubmitted(true);
        if (blockNameValid) {
            submitNewBlockCallback({
                Name: newBlockName,
                Description: newBlockDescription,
                CategoryTypeId: newBlockCategory
            }, (res: string) => {
                goToBlockEditor(res);
            }, (err: ResponseError) => {
                setSubmitBlockError(err);
            }
        );
        }
    };

    const goToBlockEditor = (id: string) => {
        navigate(`/customblock/${id}`)
    };

    return (
        <>
        <div style={{ display: "flex", flexDirection: "column", height: "calc(100% - 128px)", padding: "64px 0px 64px 32px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingRight: "32px" }}>
                <h5 className="headline5" style={{ margin: "16px 0px 16px 0px " }}>Custom Blocks</h5>
                <div style={{ display: "flex", flex: "1", justifyContent: "flex-end", marginBottom: "16px" }}>
                    <Button onClick={addNewBlock} variant="contained" color="secondary" startIcon={<AddIcon />}>Create New Block</Button>
                </div>
            </div>
            <Divider />
            <div style={{ marginTop: "32px", marginBottom: "32px", paddingRight: "32px" }}>
                <SearchFilterInput
                    placeholder='Search Custom Blocks'
                    value={searchFilter}
                    id={'CustomBlocksSearchFilter'}
                    inputMaxWidth={500}
                    onChange={function (newValue: string, originalCase: string): void {
                        setSearchFilter(newValue);
                        setPage(1);
                        setSearchRequest(r => {
                            return {
                              ...r,
                              Page: 1,
                              Filter: newValue,
                              EndDate: new Date()
                            }
                        });
                    }}
                    noResults={!blocksLoading && (blocks === null || blocks.Results.length === 0)}
                />
            </div>
            <SharedContentList
                list={blocks}
                listLoading={blocksLoading}
                setSearchRequest={setSearchRequest}
                handleDelete={setDeleteBlockId}
                goToEditor={goToBlockEditor}
                page={page}
                setPage={setPage}
            ></SharedContentList>
        </div>
        <BuilderDialog
            title="New Custom Block"
            message=""
            action={submitNewBlock}
            actionName="Confirm"
            open={showAddBlockModal}
            onClose={() => setShowAddBlockModal(false) }
            showCancel={true}
            apiError={submitBlockError}
            errorMessage={submitBlockError ? "Error creating custom block" : null }
        >
            <div style={{ marginBottom: "32px" }}>
                <label htmlFor="block-name">Block Name</label>
                <TextField
                    id="block-name"
                    value={newBlockName}
                    onChange={(e) => updateBlockName(e.target.value) }
                    label=""
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "8px" }}
                    inputProps={{ maxLength: 200 }}
                    color="secondary" />
                <FormValidationError valid={!newBlockFormSubmitted || blockNameValid} message="Block name is required." />
            </div>
            <div style={{ marginBottom: "32px" }}>
                <label htmlFor="block-description">Block Summary</label>
                <TextField
                    id="block-description"
                    value={newBlockDescription}
                    multiline={true}
                    minRows={3}
                    onChange={(e) => setNewBlockDescription(e.target.value) }
                    label=""
                    variant="outlined"
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                    sx={{ marginTop: "8px" }}
                    color="secondary" />
            </div>
            <div style={{ marginBottom: "32px" }}>
                <label htmlFor="block-category">Category</label>
                <Select
                    id="block-category"
                    value={newBlockCategory.toString()}
                    label=""
                    fullWidth
                    onChange={(e: SelectChangeEvent) => setNewBlockCategory(parseInt(e.target.value)) }
                    sx={{ marginTop: "8px" }}
                >
                    { blockCategories &&
                        blockCategories.filter((c: Type) => c.ParentTypeId === 201)
                        .map((c: Type) => (
                            <MenuItem value={c.Id} key={c.Id}>{c.Name}</MenuItem>
                        ))
                    }
                </Select>
            </div>
        </BuilderDialog>
        <BuilderDialog
            title="Delete Block"
            message="Are you sure you wish to delete this block? This is a permanent action and cannot be undone."
            action={submitDeleteBlock}
            actionName="Delete"
            open={deleteBlockId != null}
            onClose={() => setDeleteBlockId(null) }
            danger={true}
            showCancel={true}/>
        <ErrorMessage message={"Error searching custom blocks"} visible={blocksError} duration={3000} />
        <ErrorMessage
          message={"Error deleting custom block."}
          dismissable={true}
          visible={deleteBlockError != null}
          apiError={deleteBlockError}
        />
        <SuccessMessage
          message={"Custom Block Deleted!"}
          dismissable={true}
          duration={3000}
          visible={showDeleteBlockSuccess}
        />
        </>
    )
}