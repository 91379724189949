import { Box, Drawer, Backdrop, Paper } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import SiteEditorSideBarBlocks from './blocks_and_sections/SiteEditorSideBarBlocks';
import SiteEditorSideBarPages from './pages/SiteEditorSideBarPages';
import Hierarchy from './hierarchy/Hierarchy'
import SiteEditorSideBarAddPage from './pages/SiteEditorSideBarAddPage';
import SidebarState from '../../../types/enum/SidebarState';
import SiteEditorStore from '../SiteEditorStore';

export default function SiteEditorSideBar() {
  const sidebarState = SiteEditorStore.useStoreState((state) => state.sidebarState);
  const closeSidebars = SiteEditorStore.useStoreActions((actions) => actions.closeSidebars);
  const updateSidebarState = SiteEditorStore.useStoreActions((actions) => actions.updateSidebarState);
  const isDragging = SiteEditorStore.useStoreState((state) => state.isDragging);
  const dragType = SiteEditorStore.useStoreState((state) => state.dragType);

  const handleNavClick = (e: any, state: SidebarState) => {
    e.stopPropagation();
    if (sidebarState === state) {
      closeSidebars();
    } else {
      updateSidebarState(state);
    }
  };

  const drawerOpen = sidebarState === SidebarState.BlocksOpen
    || sidebarState === SidebarState.PagesOpen
    || sidebarState === SidebarState.HierarchyOpen;

  const hideDrawerForBackgroundDropZone = isDragging && dragType === 'BlockMapping';

  const iconStyles = {
    fontSize: "35px",
    marginBottom: "20px",
    color: '#292929',
    cursor: 'pointer',
    opacity: .7,
  };
  const iconStylesOpen = {
    ...iconStyles,
    color: '#1B1AFF',
    opacity: 1
  };

  return (
    <Drawer
      sx={{
        width: "75px",
      }}
      variant="permanent"
      open={drawerOpen}
      PaperProps={{
        sx: {
          flexDirection: "row"
        }
      }}
    >
      <Box
        sx={{ padding: "20px", display: "flex", flexDirection: "column" }}
      >
        <LayersOutlinedIcon
          role="button"
          tabIndex={0}
          aria-expanded={sidebarState === SidebarState.PagesOpen}
          data-testid="open-pages-icon"
          sx={sidebarState === SidebarState.PagesOpen ? iconStylesOpen : iconStyles}
          onClick={(e: any) => handleNavClick(e, SidebarState.PagesOpen)}
        />
        <AccountTreeOutlinedIcon
          role="button"
          tabIndex={0}
          aria-expanded={sidebarState === SidebarState.HierarchyOpen}
          data-testid="open-hierarchy-icon"
          sx={sidebarState === SidebarState.HierarchyOpen ? iconStylesOpen : iconStyles}
          onClick={(e: any) => handleNavClick(e, SidebarState.HierarchyOpen)}
        />
        <AddCircleOutlineOutlinedIcon
          role="button"
          tabIndex={0}
          aria-expanded={sidebarState === SidebarState.BlocksOpen}
          data-testid="open-blocks-icon"
          sx={sidebarState === SidebarState.BlocksOpen ? iconStylesOpen : iconStyles}
          onClick={(e: any) => handleNavClick(e, SidebarState.BlocksOpen)}
        />
      </Box>
      <Backdrop
        open={drawerOpen}
        onClick={() => {
          closeSidebars();
        }}
        sx={{
          background: "rgba(0, 0, 0, 0)",
          zIndex: 1200,
          display: "flex",
          alignItems: "initial",
          justifyContent: "left",
          pointerEvents: hideDrawerForBackgroundDropZone ? "none" : "auto",
          left: "75px"
        }}
        transitionDuration={0}
      >
        <Paper sx={{
          width: "440px",
          paddingBottom: 0,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          pointerEvents: "auto",
          opacity: hideDrawerForBackgroundDropZone ? "0": "1",
          "&:empty": { width: 0, padding: 0 }
        }} elevation={2} square onClick={(e: any) => {
          e.stopPropagation()
        }}>
          { sidebarState === SidebarState.BlocksOpen &&
            <SiteEditorSideBarBlocks />
          }
          { sidebarState === SidebarState.HierarchyOpen &&
            <Hierarchy />
          }
          { sidebarState === SidebarState.PagesOpen &&
            <SiteEditorSideBarPages />
          }
          <SiteEditorSideBarAddPage />
        </Paper>
      </Backdrop>
    </Drawer>
  );
}