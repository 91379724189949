import { useRef } from 'react';
import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes } from '../../../utils/blockHelpers';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

const isConfiguredProperly = (block: BlockMapping) : Boolean => {
    const slidesVal = getPropertyValue(block.Properties, PropertyId.ImageCarouselSlides);
    const slides = slidesVal ? JSON.parse(slidesVal) : null;

    return slides ? true : false;
};

export function Block(props: BlockProps) {
    const slidesVal = getPropertyValue(props.block.Properties, PropertyId.ImageCarouselSlides);
    const slides = slidesVal ? JSON.parse(slidesVal) : null;
    const paginationElement = useRef<HTMLDivElement>(null);
    const backBtn = useRef<HTMLDivElement>(null);
    const nextBtn = useRef<HTMLDivElement>(null);

    if (!slides || slides.length === 0) {
        return <></>;
    }

    return (
        <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: "imgcarousel swipercontainer", blockWrapperData: props.blockWrapperData})}>
            <Swiper
                loop={true}
                pagination={{
                    clickable: true,
                    el: paginationElement.current
                }}
                navigation={{
                    nextEl: nextBtn.current,
                    prevEl: backBtn.current
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {slides.map((slide: any, index: number) =>
                    <SwiperSlide key={index}>
                        {slide.u &&
                            <img src={slide.u} alt={slide.a} />
                        }
                        {slide.c &&
                            <div className="swiper-caption">{slide.c}</div>
                        }
                    </SwiperSlide>
                )}
                <div className="swiper-button-next" ref={nextBtn}>
                    <span className="material-icons-outlined">arrow_circle_right</span>
                    <div className="swiper-btn-text">Next</div>
                </div>
                <div className="swiper-button-prev" ref={backBtn}>
                    <span className="material-icons-outlined">arrow_circle_left</span>
                    <div className="swiper-btn-text">Back</div>
                </div>
            </Swiper>
            <div className="swiper-pagination" ref={paginationElement}></div>
        </div>
    );
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return isConfiguredProperly(block);
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}