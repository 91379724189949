import BlockProps from '../../../types/BlockProps';
import { getDynamicAttributes, getPropertyBooleanValue } from '../../../utils/blockHelpers';
import PropertyId from '../../../types/enum/PropertyId';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import MisconfiguredBlock from "../MisconfiguredBlock";
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';

export function Block(props: BlockProps) {
    const removePadding = getPropertyBooleanValue(props.block.Properties, PropertyId.RemovePadding);
    return <div {...getDynamicAttributes({block: props.block, builderProps: props.builderProps, className: removePadding ? "col" : "col p-6 md:p-2", blockWrapperData: props.blockWrapperData})}>{props.children}</div>;
}

export function hasContentToRender(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}