import { Button, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import Property from "../../types/Property";
import PropertyEditorFilterData from "../../types/PropertyEditorFilterData";
import PropertyEditorProps from "../../types/PropertyEditorProps";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyValuePairItem from "./KeyValuePairItem";
import { v4 as uuidv4 } from 'uuid';
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

export interface KeyValuePair {
    k: string,
    v: string
}

export interface KeyValuePairWithId {
    k: string,
    v: string,
    id: string
}

export function Editor({property, propertyUpdated, setMostRecentValue}: PropertyEditorProps) {

    const [adjustItems, setAdjustItems] = useState<boolean>(false);
    const [keyValuePairs, setKeyValuePairs] = useState<Array<KeyValuePairWithId>>([]);

    useEffect(() => {
        const propValue = JSON.parse(property?.RenderedValue || '{"t":false,"i":[]}');
        setAdjustItems(propValue.t);
        setKeyValuePairs(propValue.i?.map((item: KeyValuePair | KeyValuePairWithId) => {
            return {
                k: item.k,
                v: item.v,
                id: 'id' in item ? item.id : uuidv4()
            }
        }));
    }, [property]);

    const addNewPair = () => {
        let pairs = keyValuePairs ?? [];
        pairs.push({ k: '', v: '', id: uuidv4()});
        setKeyValuePairs(pairs);
        updateValue(adjustItems, pairs);
    };

    const updateValue = (t: boolean, i: Array<KeyValuePairWithId>) => {
        if (property) {
            const value = JSON.stringify({
                t: t,
                i: i.map((item: any) => {
                    return {
                        ...item
                    };
                })
            });


            property.Value = value;
            property.RenderedValue = value;
            propertyUpdated(property);
        }
    };

    const handleSwitchOnChange = (event: React.ChangeEvent, checked: boolean) => {
        setAdjustItems(checked);
        updateValue(checked, keyValuePairs);
    };

    const keyValueChanged = (index: number, key: string, value: string) => {
        keyValuePairs[index].k = key;
        keyValuePairs[index].v = value;
        setKeyValuePairs(keyValuePairs);
        updateValue(adjustItems, keyValuePairs);
    };

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        const item = keyValuePairs[dragIndex];
        keyValuePairs.splice(dragIndex, 1);
        keyValuePairs.splice(hoverIndex, 0, item);
        setKeyValuePairs(keyValuePairs);
        updateValue(adjustItems, keyValuePairs);
    };

    const deleteItem = (index: number) => {
        keyValuePairs.splice(index, 1);

        setKeyValuePairs(keyValuePairs);
        updateValue(adjustItems, keyValuePairs);
    };

    const enableDelete = keyValuePairs && keyValuePairs.length > 1;

    return <>
        <label id={`label-${property?.PropertyId}`} className="body1">
            {property?.Name}
        </label>
        <div style={{marginTop: '20px', fontSize: '14px'}}>
            <label>
                Adjust Item Values
            </label>
        </div>
        <div>
            <Switch
                checked={adjustItems}
                onChange={handleSwitchOnChange}
                data-testid="KeyValuePairToggleSwitch"
            />
        </div>
        <DndProvider backend={HTML5Backend} context={window}>
            {keyValuePairs && keyValuePairs.map((i: any, index: number) => <KeyValuePairItem
                key={i.id}
                index={index}
                keyValueItem={i}
                showValue={adjustItems}
                enableDelete={enableDelete}
                keyValueChanged={keyValueChanged}
                moveItem={moveItem}
                deleteItem={deleteItem}
                setMostRecentValue={setMostRecentValue}
            ></KeyValuePairItem>)}
        </DndProvider>
        <div style={{marginTop: '16px'}}>
            <Button
                startIcon={<AddCircleOutlineIcon/>}
                color='secondary'
                onClick={addNewPair}
                fullWidth
                data-testid="AddKeyValuePair">
                ADD ITEM
            </Button>
        </div>
    </>;
}

export function containsValue(property: Property, value: string, filterData?: PropertyEditorFilterData) {
    if (!property?.RenderedValue) {
        return false;
    }
    else {
        const propValue = JSON.parse(property.RenderedValue || '{}');

        for (const item of propValue.i) {
            if (item.k?.toLowerCase().includes(value)
                || (propValue.t && item.v?.toLowerCase().includes(value))) {
                return true;
            }
        }

        return false;
    }
}