import LinkIcon from '@mui/icons-material/Link';
import PropertyId from '../../../types/enum/PropertyId';
import PropertyValueType from '../../../types/enum/PropertyValueType';
import { Editor as ColorProperty } from '../../property_editors/ColorProperty';
import './ColorStep.css';
import { invertColor, shadeTintColor } from '../../../utils/colorHelpers';
import { useEffect } from 'react';
import Property from '../../../types/Property';
import Page from '../../../types/Page';

export interface GenerateColorPropertyProps {
    propertyId: string;
    siteId: string;
    properties: Property[];
    enumId: PropertyId | null | undefined;
    value: string;
}

const generateColorProperty = ({propertyId, siteId, properties, enumId, value}: GenerateColorPropertyProps) => {
    properties.push({
        PropertyId: propertyId,
        BlockMappingId: null,
        Value: value,
        RenderedValue: value,
        ValueTypeId: PropertyValueType.Color,
        Configuration: null,
        EnumId: enumId,
        ValueUpdatedInWizard: true,
        CategoryTypeId: 717,
        SiteId: siteId,
        PageId: null,
        Name: "",
        PropertyOrder: 1,
    });
}

export interface ColorStepProps {
    siteId: string;
    templateData: Page | null;
    setTemplateData: React.Dispatch<React.SetStateAction<Page | null>>;
}

function ColorStep({siteId, templateData, setTemplateData}: ColorStepProps) {
    const colorThemeProperty = templateData?.SiteProperties.find((p: Property) => p.EnumId === PropertyId.ColorTheme);

    let colorTheme = colorThemeProperty
        ? JSON.parse(colorThemeProperty?.RenderedValue || '')
        : {};

    useEffect(() => {
        if (templateData && !templateData.SiteProperties.find(p => p.EnumId === PropertyId.PrimaryColor)) {
            generateColorProperty({ propertyId: '86def1a7-8c40-4ad3-836b-d77c5cec85a0', siteId, properties: templateData.SiteProperties, enumId: PropertyId.PrimaryColor, value: JSON.stringify(colorTheme.primary.color)});
            generateColorProperty({ propertyId: '93184720-108b-47f1-a9b2-1e88e7ba3113', siteId, properties: templateData.SiteProperties, enumId: PropertyId.SecondaryColor, value: JSON.stringify(colorTheme.secondary.color)});
            generateColorProperty({ propertyId: '64d1f914-8feb-4556-96eb-673f39aff0b3', siteId, properties: templateData.SiteProperties, enumId: PropertyId.AccentColor, value: JSON.stringify(colorTheme.accent.color)});
            generateColorProperty({ propertyId: 'b8f43eb5-f12c-4bd8-a2c0-9afe61f235dc', siteId, properties: templateData.SiteProperties, enumId: PropertyId.PrimaryFocus, value: JSON.stringify(colorTheme.primary.focus)});
            generateColorProperty({ propertyId: 'ea5f0cf3-3646-4c93-8da4-954e937d6075', siteId, properties: templateData.SiteProperties, enumId: PropertyId.PrimaryContent, value: JSON.stringify(colorTheme.primary.content)});
            generateColorProperty({ propertyId: 'bdb2acd2-6c6e-4f36-bff2-e103cdff25ea', siteId, properties: templateData.SiteProperties, enumId: PropertyId.SecondaryFocus, value: JSON.stringify(colorTheme.secondary.focus)});
            generateColorProperty({ propertyId: 'ebba3d7a-ef1b-40cc-b131-862c94e04427', siteId, properties: templateData.SiteProperties, enumId: PropertyId.SecondaryContent, value: JSON.stringify(colorTheme.secondary.content)});
            generateColorProperty({ propertyId: 'af783765-4814-4462-acca-120fc93ddcc1', siteId, properties: templateData.SiteProperties, enumId: PropertyId.AccentFocus, value: JSON.stringify(colorTheme.accent.focus)});
            generateColorProperty({ propertyId: 'f38262dc-c8ab-4574-8a78-6984048be385', siteId, properties: templateData.SiteProperties, enumId: PropertyId.AccentContent, value: JSON.stringify(colorTheme.accent.content)});
            setTemplateData({...templateData });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData])

    if (!templateData) {
        return null;
    }

    const primaryColorProperty = templateData.SiteProperties.find(p => p.EnumId === PropertyId.PrimaryColor)
    const secondaryColorProperty = templateData.SiteProperties.find(p => p.EnumId === PropertyId.SecondaryColor)
    const accentColorProperty = templateData.SiteProperties.find(p => p.EnumId === PropertyId.AccentColor)

    if (!primaryColorProperty) {
        return null;
    }

    const primaryColor = JSON.parse(primaryColorProperty.RenderedValue || '');
    const secondaryColor = JSON.parse(secondaryColorProperty?.RenderedValue || '');
    const accentColor = JSON.parse(accentColorProperty?.RenderedValue || '');
    const coolorsUrl = `https://coolors.co/${primaryColor.hex.replace('#', '')}-${secondaryColor.hex.replace('#', '')}-${accentColor.hex.replace('#', '')}`;

    const setColorProperties = (focusEnumId: number, contentEnumId: number, property: Property, colorLevel: number) => {
        const color = JSON.parse(property.RenderedValue || '');

        let focusProperty = templateData.SiteProperties.find(p => p.EnumId === focusEnumId)
        let contentProperty = templateData.SiteProperties.find(p => p.EnumId === contentEnumId)

        const calculatedColors = calculateAndSetColors(color);

        switch (colorLevel) {
            case 0:
                colorTheme.primary.color = color;
                colorTheme.primary.focus = calculatedColors.focus;
                colorTheme.primary.content = calculatedColors.content;
                break;
            case 1:
                colorTheme.secondary.color = color;
                colorTheme.secondary.focus = calculatedColors.focus;
                colorTheme.secondary.content = calculatedColors.content;
                break;
            case 2:
                colorTheme.accent.color = color;
                colorTheme.accent.focus = calculatedColors.focus;
                colorTheme.accent.content = calculatedColors.content;
                break;
            default:
        }

        property.ValueUpdatedInWizard = true;

        if (focusProperty) {
            focusProperty.Value = focusProperty.RenderedValue
            = JSON.stringify(calculatedColors.focus);
            focusProperty.ValueUpdatedInWizard = true;
        }

        if (contentProperty) {
            contentProperty.Value = contentProperty.RenderedValue
                = JSON.stringify(calculatedColors.content);
            contentProperty.ValueUpdatedInWizard = true;
        }

        if (colorThemeProperty) {
            colorThemeProperty.Value = JSON.stringify(colorTheme);
            colorThemeProperty.RenderedValue = JSON.stringify(colorTheme);
            colorThemeProperty.ValueUpdatedInWizard = true;
        }

        setTemplateData({...templateData});
    };

    const primaryColorUpdated = (property: Property) => {
        setColorProperties(PropertyId.PrimaryFocus, PropertyId.PrimaryContent, primaryColorProperty, 0);
    };

    const secondaryColorUpdated = (property: Property) => {
        setColorProperties(PropertyId.SecondaryFocus, PropertyId.SecondaryContent, secondaryColorProperty!, 1);
    };

    const accentColorUpdated = (property: Property) => {
        setColorProperties(PropertyId.AccentFocus, PropertyId.AccentContent, accentColorProperty!, 2);
    };

    const calculateAndSetColors = (color: { hex: string, hsl: string }) => {
        return {
            content: invertColor(color.hex),
            focus: shadeTintColor(color.hsl)
        }
    };

    return (
        <>
            <div className="wizard-step-header-container">
                <h1 className="headline4">Set website colors</h1>
                <p className="body1">Click a color to set it based on the client's website and logo, or generate a new color palette.</p>
            </div>
            <a className="button color-step-button" target="_blank" href={coolorsUrl} rel="noreferrer">
                <LinkIcon /> Click here for color suggestions
            </a>
            <ColorProperty property={primaryColorProperty || null} propertyUpdated={primaryColorUpdated} text="Primary Color" helpText="Buttons, carousel indicators" pageList={[]} />
            <ColorProperty property={secondaryColorProperty || null} propertyUpdated={secondaryColorUpdated} text="Secondary Color" helpText="NAPU" pageList={[]} />
            <ColorProperty property={accentColorProperty || null} propertyUpdated={accentColorUpdated} text="Accent Color" helpText="External links bar" pageList={[]} />
        </>
    );
}

export default ColorStep;